import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import styled from "styled-components";
import styles from "./index.module.scss";
import globalStyles from "../../styles/_stylesParams.module.scss";
import pxToRem from "../../helpers/pxToRem";

const BaseTooltip = styled(Tooltip)({
  // background: globalStyles.neutrals500,
});

interface ICustomizedTooltip extends TooltipProps {}

const CustomizedTooltip = ({
  children,
  placement,
  className,
  ...rest
}: ICustomizedTooltip) => (
  <BaseTooltip
    className={`${className}`}
    {...rest}
    placement='bottom-start'
    arrow
    PopperProps={{
      sx: {
        "& .MuiTooltip-tooltip": {
          backgroundColor: globalStyles.neutrals500,
          color: globalStyles.shades0,
          padding: `${pxToRem(12)} ${pxToRem(16)}`,
          maxWidth: 389,
        },
      },
    }}
  >
    <div className={styles.tip}>{children}</div>
  </BaseTooltip>
);

export default CustomizedTooltip;
