/**
 * Merges two string arrays and returns a sorted array with unique values.
 * @param currentTypes Current engagement types already known or in state.
 * @param fetchedTypes Newly fetched types from the backend or external source.
 * @returns A sorted array of unique engagement types as strings.
 */
export function mergeAndSortTypes(currentTypes: string[], fetchedTypes: string[]): string[] {
    const typeSet: Set<string> = new Set([...currentTypes, ...fetchedTypes]);
    const sortedUniqueTypes: string[] = Array.from(typeSet).sort();
    return sortedUniqueTypes;
}