import {
    Autocomplete, Avatar, Box, IconButton, InputAdornment, SelectChangeEvent, Stack
} from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { NetworkTable } from "..";
import { AlertDialogComponent, AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import TableAutoComplete from "../../../components/AutoComplete/TableAutoComplete";
import TableDropdown from "../../../components/CustomizedDropdown/TableDropdown";
import MultilineTextField from "../../../components/Inputs/TextFields/MultilineInputTextField";
import { updateConnections } from "../../../firebase/networkApis";
import { Connection, Network } from "../../../firebase/types-network";
import { PermissionState } from "../../../firebase/types-organisations";
import { mergeAndSortTypes } from "../../../helpers/mergeTypes";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { useCombinedData } from "../../../redux/reselect";
import { stringToColor } from "../../OrganisationPlannerPage/components/colorHelper";
import StatusRoundCheckbox from "../../OrganisationPlannerPage/components/RoundCheckbox/StatusCheckbox";
import styles from "./index.module.scss";

interface NetworkSearchBarProps {
    linkedConnections: ConnectionData[];
    network: NetworkTable | null;
    orgId: string;
    networkId: string;
}
export type ConnectionTable = {
    objectId?: string,
    title?: string,
    firstName?: string,
    lastName?: string,
    name?: string,
    emailPreferred?: string,
    currentEmployment?: string;
    educationDegree?: string;
    networkTitle?: string;
    networkTitleDescription?: string;
    living?: string,
    peerYear?: number,
    lastUpdated?: string,
    lastUpdatedDate?: string,
    photoUrl?: string | undefined,
}

export interface ConnectionData extends ConnectionTable {
    type: string;
    relationship: string;
}


export type ConnectionList = {
    connectionID?: string,
    name?: string,
    emailPreferred?: string,
    type?: string,
    relationship?: string,
}


export const ConnectionDataInsideTable = (name: string, email: string, photoUrl?: string) => {
    // Determine the background color or image for the Avatar
    const avatarStyle = photoUrl === "grey"
        ? { width: pxToRem(48), height: pxToRem(48), backgroundColor: '#E5E5E5' }
        : { width: pxToRem(48), height: pxToRem(48), backgroundColor: stringToColor(name), backgroundImage: `url(${photoUrl})` };

    return (
        <Stack direction={"row"} spacing={2} style={{ paddingTop: pxToRem(5), paddingBottom: pxToRem(5), alignItems: 'center', minWidth: pxToRem(300) }}>
            <Stack>
                <Avatar src={photoUrl !== "grey" ? photoUrl : undefined} alt={name} style={avatarStyle}>
                    {
                        photoUrl === "grey" ? null : (
                            `${name.split(" ")[0].charAt(0)}${name.split(" ")[1] ? name.split(" ")[1].charAt(0) : ''}`
                        )
                    }
                </Avatar>
            </Stack>
            <Stack direction={"column"}>
                <small className='regular'>{name}</small>
                <small className='regular' style={{ color: "#909090", display: 'flex', alignItems: 'flex-start' }}>{email ? email : 'no email'}</small>
            </Stack>
        </Stack>
    );
}

function NetworkSearchBar({ linkedConnections, orgId, networkId, network }: NetworkSearchBarProps) {
    const connectionData = useCombinedData(linkedConnections);
    const [searchInput, setSearchInput] = useState("");
    const [selectedConnection, setSelectedConnection] = useState<ConnectionData[]>(linkedConnections);
    const columnHelper = createColumnHelper<ConnectionData>();
    const [selectedToDelete, setSelectedToDelete] = useState<ConnectionData | null>(null);
    const [isEmptyState, setIsEmptyState] = useState(true);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [organisationConnectionRelationship, setOrganisationConnectionRelationship] = useState<string[]>(["-", "Community", "Family", "Staff", "Parent"]);
    // Assuming `selectedConnection` and `setSelectedConnection` are already defined in your component
    const [originalStatuses, setOriginalStatuses] = useState<{ [key: string]: string }>({});
    const networkPermission = useSelector((state: RootState) => state.globalState.permissions?.network);
    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);
    const navigate = useNavigate();

    // Selector to get network types from Redux
    const connectionRelationships = useSelector((state: RootState) => state.organisation.organisation?.connectionRelationships);

    const isInitialMount = useRef(true);
    const prevConnectionRef = useRef<ConnectionData[] | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value.toLowerCase());
    };

    const handleRelationshipChange = (newValue: string, connection: ConnectionData) => {
        if (!connection.objectId) {
            console.error("Attempted to update a connection without an objectId.");
            return;
        }
    
        const updatedConnection = selectedConnection.map(connectionItem =>
            connectionItem.objectId === connection.objectId ? { ...connectionItem, relationship: newValue } : connectionItem
        );
    
        setSelectedConnection(updatedConnection);
    };
    
    
    const handleOpenConfirm = (connection: ConnectionData) => {
        setSelectedToDelete(connection);
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = () => {
        if (selectedToDelete) {
            const newSelectedConnection = selectedConnection.filter(
                (connection) => connection.objectId !== selectedToDelete.objectId
            );
            setSelectedConnection(newSelectedConnection);
            setIsConfirmOpen(false);
            setIsSuccessOpen(true); // Trigger success message
        }
    };

    const handleCancelConfirm = () => {
        setIsConfirmOpen(false);
        setSelectedToDelete(null);
    };

    const handleCloseSuccess = () => {
        setIsSuccessOpen(false);
        setSelectedToDelete(null);
    };


    // Filtered connection based on search input with simple fuzzy logic
    const filteredConnection = connectionData.filter(connection => {
        const normalizedSearchInput = searchInput.toLowerCase().split(/\s+/);

        const containsAllWords = (str: string | undefined) => {
            const normalizedStr = str?.toLowerCase() || "";
            const result = normalizedSearchInput.every(word => normalizedStr.includes(word));
            return result;
        };

        // Debug each field specifically
        const isMatch = containsAllWords(connection.title) ||
            containsAllWords(connection.firstName) ||
            containsAllWords(connection.lastName) ||
            containsAllWords(connection.name) ||
            containsAllWords(connection.type) ||
            containsAllWords(connection.emailPreferred);
        return isMatch;
    });

    const viewerColumns = [
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Connections" }),
        columnHelper.accessor('type', { cell: info => info.getValue(), header: "Type" }),
        columnHelper.accessor('relationship', { cell: info => info.getValue(), header: "Relationship" }),
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "" }),

    ];

    const emptyViewerColumns = [
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Connections" }),
    ];

    const table = useReactTable({
        data: selectedConnection,
        columns: selectedConnection.length > 0 ? viewerColumns : emptyViewerColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowId: (row, index) => row.objectId || `row-${index}`,
        state: {
            pagination: {
                pageSize: selectedConnection.length,
                pageIndex: 0,
            },
        },
    });

    // Initialize and respond to changes in linkedConnections
    useEffect(() => {
        setSelectedConnection(linkedConnections);
    }, [linkedConnections]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            prevConnectionRef.current = selectedConnection;
            return;
        }

        if (!isEqual(prevConnectionRef.current, selectedConnection)) {
            updateConnections(orgId, networkId, selectedConnection)
                .then(response => {
                    prevConnectionRef.current = selectedConnection; // Update the ref only after updating the connection
                })
                .catch(error => console.error('Failed to update linked connection:', error));
        }
    }, [selectedConnection, orgId, networkId]);  // Include other dependencies as necessary

    useEffect(() => {
        // Function to merge and sort network types
        function updateConnectionRelationship(types: string[]) {
            const allTypes = mergeAndSortTypes(organisationConnectionRelationship, types);
            setOrganisationConnectionRelationship(allTypes);
        }

        if (connectionRelationships) {
            updateConnectionRelationship(connectionRelationships);
        }
    }, [connectionRelationships]);

    return (
        <>
            <AlertDialogComponent
                title="Unlink Connection"
                subTitle="You can re-link this connection if you change your mind"
                severity="primary"
                message={`You are about to unlink the connection <b style='font-weight:'bold'>${selectedToDelete?.name}</b> from the network: <b style='font-weight:'bold'>${network?.name}</b>. Do you wish to continue?`}
                visible={isConfirmOpen}
                primaryButtonTitle="Yes, Continue"
                primaryButtonStyle={{ backgroundColor: "#549189", color: "white" }} // Modify as per your theme
                primaryButtonClose={handleDeleteConfirmed}
                secondaryButtonTitle="Cancel"
                secondaryButtonClose={handleCancelConfirm}
            />
            <AlertDialogSimpleComponent
                visible={isSuccessOpen}
                message={`<b style='font-weight:'bold'>${selectedToDelete?.name}</b>  has successfully been unlinked from the network: <b style='font-weight:'bold'>${network?.name}</b>.`}
                primaryButtonClose={() => {
                    handleCloseSuccess();
                }}
                primaryButtonTitle="Close"
            />
            <Box sx={{ width: '100%' }}>
                {(!networkPermission || networkPermission === "EDITOR") &&
                    <Autocomplete
                        multiple
                        id="connection-search-bar"
                        options={filteredConnection}
                        getOptionLabel={(option) => `${option.name} - ${option.educationDegree}, ${option.peerYear}, ${option.objectID},  ${option.currentEmployment}, ${option.title}`}
                        isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
                        noOptionsText="No matches found - please try again"
                        renderInput={(params) => (
                            <MultilineTextField
                                {...params}
                                placeholder="Search a name to make a new connection..."
                                onChange={handleSearchChange}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null,
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ marginLeft: pxToRem(16) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(26)} height={pxToRem(27)} viewBox="0 0 24 25" fill="none">
                                                <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </InputAdornment>
                                    ),
                                    onKeyDown: (event) => {
                                        // Block deletion of items when the input field is empty
                                        if (event.key === 'Backspace' && params.inputProps.value === '') {
                                            event.stopPropagation();
                                        }
                                    }
                                }}
                            />
                        )}
                        value={selectedConnection}
                        onChange={(event, newValue) => {
                            // Convert AlumniTable[] to LinkedAlumni[]
                            const updatedConnections = newValue.map(connect => {
                                // Check if the alumni is already in selectedAlumni to retain the status and involvement if already set
                                const existing = selectedConnection.find(a => a.objectId === connect.objectID);
                                return {
                                    ...connect,
                                    relationship: existing?.relationship || ''  // Default involvement if not already set
                                };
                            });

                            setSelectedConnection(updatedConnections);
                        }}

                        renderOption={(props, option) => {
                            const isSelected = selectedConnection.some(connection => connection.objectId === option.objectID);
                            return (
                                <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: pxToRem(12),
                                            marginBottom: pxToRem(12),
                                            height: pxToRem(40)
                                        }}>
                                            <small>{option.name}</small>
                                            <small style={{ color: "#737373" }}>{`${option.currentEmployment ? option.currentEmployment : "No Employment"} • ${option.type}`}</small>
                                        </Box>
                                    </Box>
                                    <small style={{ marginLeft: 'auto', color: "#737373" }}>
                                        {isSelected ?
                                            <IconButton aria-label="remove" size="small" style={{ color: '#737373' }}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.99999 13C10.4294 13.5741 10.9773 14.0491 11.6065 14.3929C12.2357 14.7367 12.9315 14.9411 13.6466 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9547 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.552 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5705 10.4258 13.0226 9.95078 12.3934 9.60703C11.7642 9.26327 11.0685 9.05885 10.3533 9.00763C9.63819 8.95641 8.9204 9.0596 8.24864 9.31018C7.57688 9.56077 6.96687 9.9529 6.45999 10.46L3.45999 13.46C2.5492 14.403 2.04522 15.666 2.05662 16.977C2.06801 18.288 2.59385 19.542 3.52089 20.4691C4.44793 21.3961 5.702 21.9219 7.01298 21.9333C8.32396 21.9447 9.58697 21.4408 10.53 20.53L12.24 18.82" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.99999 13C10.4294 13.5741 10.9773 14.0491 11.6065 14.3929C12.2357 14.7367 12.9315 14.9411 13.6466 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9547 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.552 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5705 10.4258 13.0226 9.95078 12.3934 9.60703C11.7642 9.26327 11.0685 9.05885 10.3533 9.00763C9.63819 8.95641 8.9204 9.0596 8.24864 9.31018C7.57688 9.56077 6.96687 9.9529 6.45999 10.46L3.45999 13.46C2.5492 14.403 2.04522 15.666 2.05662 16.977C2.06801 18.288 2.59385 19.542 3.52089 20.4691C4.44793 21.3961 5.702 21.9219 7.01298 21.9333C8.32396 21.9447 9.58697 21.4408 10.53 20.53L12.24 18.82" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M9.99999 13C10.4294 13.5741 10.9773 14.0491 11.6065 14.3929C12.2357 14.7367 12.9315 14.9411 13.6466 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9547 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.552 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5705 10.4258 13.0226 9.95078 12.3934 9.60703C11.7642 9.26327 11.0685 9.05885 10.3533 9.00763C9.63819 8.95641 8.9204 9.0596 8.24864 9.31018C7.57688 9.56077 6.96687 9.9529 6.45999 10.46L3.45999 13.46C2.5492 14.403 2.04522 15.666 2.05662 16.977C2.06801 18.288 2.59385 19.542 3.52089 20.4691C4.44793 21.3961 5.702 21.9219 7.01298 21.9333C8.32396 21.9447 9.58697 21.4408 10.53 20.53L12.24 18.82" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </IconButton>
                                            : "Select"
                                        }
                                    </small>                  </li>
                            )
                        }}
                    />}
                <Stack marginTop={pxToRem(24)}>

                    <div>
                        <Stack spacing={0} className={styles.tableWrapper}>
                            <table className={styles.table}>
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id} style={{ textAlign: 'left' }}>
                                                    <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                                        <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
                                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                        </Stack>
                                                        {(header.column.id === 'type' || header.column.id === 'name' || header.column.id === 'relationship') &&
                                                            (
                                                                <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                                    <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                                    <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                                </Stack>
                                                            )}
                                                    </Stack>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {selectedConnection.length > 0 ? (

                                        table.getRowModel().rows.map(row => (
                                            <tr key={row.id}
                                                onClick={() => {
                                                    const isAlumni = row.original.type === 'Alumni';
                                                    const hasPermission = isAlumni
                                                        ? !(alumniPermission && alumniPermission === PermissionState.NO_ACCESS)
                                                        : !(networkPermission && networkPermission === PermissionState.NO_ACCESS);

                                                    if (hasPermission) {
                                                        const linkType = isAlumni ? 'alumni' : 'network';
                                                        navigate(`/dashboard/organisation/${orgId}/${linkType}/${row.original.objectId}`);
                                                    } else {
                                                        alert('You do not have permission to view this item.');
                                                    }
                                                }}
                                                className={styles.normalRow}>                                                
                                                {
                                                    row.getVisibleCells().map(cell => {
                                                        let cellJSX: JSX.Element | undefined = undefined;
                                                        if (cell.column.id === "name") {
                                                            cellJSX = (
                                                                <Stack key={cell.id} width={pxToRem(500)}>
                                                                    {ConnectionDataInsideTable(row.original.name ?? "", row.original.emailPreferred ?? "", row.original.photoUrl ?? "")}
                                                                </Stack>
                                                            );
                                                        }
                                                        else if (cell.column.id === "type") {
                                                            cellJSX = (
                                                                <Stack width={pxToRem(120)}>
                                                                    <small>{row.original.type}</small>
                                                                </Stack>
                                                            );
                                                        }

                                                        else if (cell.column.id === "relationship") {
                                                            cellJSX = (
                                                                <Stack width={pxToRem(150)} onClick={(e) => e.stopPropagation()}>
                                                                  {(!networkPermission || networkPermission === "EDITOR") ? (
                                                                    <TableAutoComplete
                                                                      defaultValue={row.original.relationship || '-'}
                                                                      options={organisationConnectionRelationship}
                                                                      onValueChange={(newValue) => handleRelationshipChange(newValue, row.original)}
                                                                    />
                                                                  ) : (
                                                                    <small>{row.original.relationship || '-'}</small>
                                                                  )}
                                                                </Stack>
                                                              );
                                                        }

                                                        else if (cell.column.id === 'peerYear') {
                                                            cellJSX =
                                                            <div onClick={(e) => e.stopPropagation()}>
                                                                <IconButton onClick={() => handleOpenConfirm(row.original)} disabled={!(!networkPermission || networkPermission === "EDITOR")}>
                                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </IconButton>
                                                            </div>
                                                        }
                                                        return (<td key={cell.id}> {cellJSX} </td>)
                                                    })
                                                }
                                            </tr>
                                        ))


                                    ) : (
                                        // Render a single row with a message or a sample row when there are no connection
                                        <tr>
                                            <td colSpan={viewerColumns.length} style={{ textAlign: 'start', padding: '20px' }}>
                                                <Stack >
                                                    {ConnectionDataInsideTable("No connections have been linked to this network yet ", "Linked connections will appear here", "grey")}
                                                </Stack>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </Stack>
                    </div>
                </Stack>

            </Box>
        </>
    );
}

export default NetworkSearchBar;