import React, { useState } from 'react';
import { Dialog, DialogActions, DialogTitle, Button, Stack, Typography } from '@mui/material';
import { deleteGeneralUser } from '../../../firebase/userApis';
import styles from "./index.module.scss";
import CustomizedModal from '../../../components/CustomizedModal';
import pxToRem from '../../../helpers/pxToRem';
import { PrimaryButton, PrimaryDangerButton, SecondaryButton } from '../../../components/Buttons/ButtonUI';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { URL_PARAMS } from '../../../routers/shared';
import { useNavigate } from 'react-router-dom';

interface UserRemovalComponentProps {
    userId: string;
    orgId: string;
    userName: string;
}

const UserRemovalComponent: React.FC<UserRemovalComponentProps> = ({ userId, orgId, userName}) => {
    const [open, setOpen] = useState(false);
    const organisation = useSelector((state: RootState) => state.globalState.organisation) 
    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        const response = await deleteGeneralUser(userId, orgId);
        console.log(response); // Log or handle the response as needed
        handleClose(); // Close the dialog after the action
        navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.TEAM}`);
    };

    return (
        <>
            <label className={styles.removeText} onClick={handleOpen} color="error">
                Remove User
            </label>
            
            <CustomizedModal open={open} handleClose={()=>{}} width={600} type='ERROR'>
            <Stack direction={"column"} height={pxToRem(180)} >
                    <Typography marginTop={pxToRem(20)} marginBottom={pxToRem(20)}>
                        <span>
                        Are you sure you want to remove <span className={`${styles.highlightedUsername}`}>"{userName}"</span> from "{organisation?.name}"?
                        </span>
                    </Typography>
                    <div className={`${styles.divider} horizontalDivider`}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={`${styles.popupButton}`} onClick={handleClose}>Cancel</SecondaryButton>
                        <PrimaryDangerButton className={`${styles.popupButton}`} onClick={handleDelete}>Delete</PrimaryDangerButton>
                    </Stack>
                </Stack>
            </CustomizedModal>
        </>
    );
};

export default UserRemovalComponent;
