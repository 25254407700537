import { Backdrop, Button, CircularProgress, Divider, IconButton, Stack } from "@mui/material";
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { DocumentData, DocumentSnapshot, collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AlertComponent from "../../../../components/AlertComponent";
import { PrimaryButton, SecondaryButton } from "../../../../components/Buttons/ButtonUI";
import { db } from "../../../../firebase";
import { deleteForms, reactivateForm } from "../../../../firebase/registrationApis";
import { Form, getForm } from "../../../../firebase/types-registration";
import pxToRem from "../../../../helpers/pxToRem";
import { sleep } from "../../../../helpers/utils";
import { RootState } from "../../../../redux";
import { setDetailsChanged } from "../../../../redux/formsSlice";
import { FormDetails, FormsData, formatFormURL } from "../../helpers";
import styles from "../../index.module.scss";
import CustomModal from "../modals/CustomModal";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import ActiveFormsSkeleton from "./skeleton/ActiveFormsSkeleton";
import InactiveFormsSkeleton from "./skeleton/InactiveFormsSkeleton";
import { PermissionState } from "../../../../firebase/types-organisations";

const PAGINATION_PERPAGEROWS = 10;

interface IFormsTable {
    rowClick: (id:string, formDetails: FormDetails) => void;
    handleEditForm?: (id: string) => void;
    handleCloseForm?: (id: string, name: string) => void;
    handleDeleteForm: (id: string, name: string) => void;
    isActive: boolean;
    columns: ColumnDef<any>[];
    countCallback: (count: number) => void;
}

const FormsTable = ({rowClick, handleEditForm, handleCloseForm, handleDeleteForm, isActive, columns, countCallback}: IFormsTable) => {
    const dispatch = useDispatch();
    const params = useParams();
    const {orgId} = params;
    const [formsDataRows, setFormsDataRows] = useState<FormsData[]>([]);
    const [formsDataRowsLoading, setFormsDataRowsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState<DocumentSnapshot<DocumentData> | null>(null);

    const formsPermission = useSelector((state:RootState) => state.globalState.permissions?.forms);

    const detailsChanged = useSelector((state:RootState) => state.formsState.detailsChanged);

    const [totalRows, setTotalRows] = useState(0);
    const [rowSelection, setRowSelection] = useState({});
    
    const [alertMsg, setAlertMsg] = useState<string>();

    const [operationIsLoading, setOperationIsLoading] = useState(false);

    const table = useReactTable({
        initialState: { pagination: { pageIndex: 0, pageSize: PAGINATION_PERPAGEROWS }},
        data: formsDataRows, 
        columns: columns, 
        getCoreRowModel: getCoreRowModel(), 
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            rowSelection
        },
        onRowSelectionChange: setRowSelection,
        enableRowSelection: !isActive,
    });

    const fetchData = async (lastDocument:DocumentSnapshot<DocumentData> | null = null, callback:(code:number)=>void = () =>{}) => {
        if(orgId){
            let qry;
            setFormsDataRowsLoading(true);
            const collectionRef = collection(db, "registrations", orgId, "forms");
            const countQuery = query(collectionRef, where("isActive", "==", isActive));
            const snapshot = await getCountFromServer(countQuery);
            setTotalRows(snapshot.data().count);
            countCallback(snapshot.data().count);
            if(!lastDocument){
                setFormsDataRows([]);
                qry = query(collectionRef, orderBy("createdAt", "desc"), where("isActive", "==", isActive), limit(PAGINATION_PERPAGEROWS));
            }
            else{
                qry = query(collectionRef, orderBy("createdAt", "desc"), where("isActive", "==", isActive), startAfter(lastDocument), limit(PAGINATION_PERPAGEROWS));
            }
            // await
            const querySnapshot = await getDocs(qry);
            var arr:FormsData[] = []; 
            if(!querySnapshot.empty) {
                try {
                    for(const doc of querySnapshot.docs)  {
                        const formId = doc.id;
                        const form: Form = getForm(doc.data());
                        const createdAt = (form.createdAt && typeof form.createdAt === 'number') ? moment(form.createdAt).format("DD MMM YY") : "-"; // DATE DIFFERENCE 
                        const closedAt = (form.closedAt && typeof form.closedAt === 'number') ? moment(form.closedAt).format("DD MMM YY") : "-";
                        const lastEdited = (form.lastEdited && typeof form.lastEdited === 'number') ? moment(form.lastEdited).format("DD MMM YY") : "-";
                        const lastSubmission = (form.lastSubmission && typeof form.lastSubmission === 'number') ? moment(form.lastSubmission).format("DD MMM YY") : "-";

                        const formsTable: FormsData = {
                            id: formId, 
                            name: form.formName ?? "",
                            link: ""+orgId+"/"+formId,
                            qrCode: ""+orgId+"/"+formId,
                            totalCount: form.totalCount,
                            pendingApproval: form.pendingApproval,
                            createdAt: createdAt ?? '-',
                            lastEdited: lastEdited ?? '-',
                            lastSubmission: lastSubmission ?? '-',
                            closedAt: closedAt ?? '-',
                        };
                        //Add into table
                        arr.push(formsTable);
                    }
    
                    setFormsDataRowsLoading(false);
                    setFormsDataRows(arr);
    
                    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    callback(200);
                } catch (error) {
                    console.error("Error fetching documents: ", error);
                    callback(400);
                    setFormsDataRowsLoading(false);
                }
            } else {
                setFormsDataRowsLoading(false);
                setFormsDataRows([]);
            }
        }
    }
    useEffect(() => { 
        fetchData(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, detailsChanged]);

    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const confirmDelete = async () => {
        setIsLoading(true);
        const formIds = table.getSelectedRowModel().rows.map(row => {
            return row.original.id;
        })
        if(!orgId) return;
        const res = await deleteForms(orgId, formIds);
        if(res.code !== 200) {
            setAlertMsg("An error occurred while trying to delete forms");
        }
        const list = formsDataRows.filter(row => formIds.includes(row.id) === false)
        setFormsDataRows(list);
        setRowSelection({});
        dispatch(setDetailsChanged());
        setOpenDeleteModal(false);
        setIsLoading(false);
    }

    const handleDelete = () => {
        setOpenDeleteModal(true);
    }

    const handleReactivate = async () => {
        setOperationIsLoading(true);
        const formIds = table.getSelectedRowModel().rows.map(row => {
            return row.original.id;
        })
        if(!orgId) return;
        const res = await reactivateForm(orgId, formIds);
        if(res.code !== 200) {
            setAlertMsg("An error occurred while trying to reactivate forms");
        }
        const list = formsDataRows.filter(row => formIds.includes(row.id) === false)
        setFormsDataRows(list);
        setRowSelection({});
        dispatch(setDetailsChanged());
        setOperationIsLoading(false);
    }

    const CustomPaginationFooter = () => {
        const currentIndex = table.getState().pagination.pageIndex + 1;
        const pageSize = table.getState().pagination.pageSize;
        const currentRows = table.getRowModel().rows;
    
        const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
        const endItem = startItem + currentRows.length - 1;
    
        const goToLastPage = () => {
            const totalRows = formsDataRows.length;
            const pageSize = PAGINATION_PERPAGEROWS;
            const lastPageIndex = Math.max(0, Math.ceil(totalRows / pageSize));
            table.setPageIndex(lastPageIndex);
        };

        const checkFetchOrNot = () => {
            const localRowCount = formsDataRows.length;
            const localMaxIndex = Math.ceil(localRowCount / pageSize)
            return (localRowCount < totalRows && currentIndex >= localMaxIndex);
        }
        
        const nextPage = () => {
            if(checkFetchOrNot()){
                if(!formsDataRowsLoading && lastDoc){
                    fetchData(lastDoc, async(code) => { 
                        await sleep(10);
                        if(code === 200){ goToLastPage(); }
                    });
                }
            }
            else{
                if(table.getCanNextPage()){
                    table.nextPage();
                }
            }
        }
        
        return(
            <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
                <Stack direction={"row"} style={{ padding: 4 , alignItems: 'center' }}>
                    <Stack className={styles.datagrid_footer_text} alignItems={"center"}>  { `${startItem} - ${endItem} of ${totalRows}` } </Stack>
                    <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> </IconButton>
                    <IconButton disabled={false} onClick={() => nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></IconButton>
                </Stack>
            </Stack>
        );
    }

    return (
        <>
            <AlertComponent 
                message={alertMsg || ""} 
                visible={alertMsg !== undefined} 
                handleClose={()=>{setAlertMsg(undefined)}}
            />
            <Backdrop
                sx={{ color: "#ffffffa8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={operationIsLoading}
            >
                <CircularProgress style={{'color':styles.primary400}}/>
            </Backdrop>
            <div style={{position: 'relative'}}>
                { 
                    formsPermission && formsPermission === PermissionState.EDITOR ?
                        !isActive && 
                            <Stack className={styles.responseButtons} direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1.5}>
                                <Button onClick={handleDelete} className={styles.rejectBtn} disabled={Object.keys(rowSelection).length <= 0}>Delete</Button>
                                <SecondaryButton onClick={handleReactivate} className={styles.pendingApproveBtn} disabled={Object.keys(rowSelection).length <= 0}
                                    sx={{
                                        "&.MuiButtonBase-root": {
                                            fontSize: pxToRem(14),
                                            fontWeight: '400',
                                            lineHeight: pxToRem(20),
                                        }
                                    }}
                                >Reactivate ({Object.keys(rowSelection).length})</SecondaryButton>
                            </Stack>
                    : null
                }
                <Stack spacing={4} className={styles.tableWrapper}>
                    <table className={`${styles.table} ${styles.formsTable} ${!isActive && styles.inactiveTable}`}>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => 
                                        <th key={header.id} style={{width:header.getSize(), textAlign:'left'}} >
                                            <Stack style={{justifyContent:'space-between'}} direction={"row"}>
                                                {
                                                    header.id !== 'select' && header.id !== 'link' && header.id !== 'qrCode'?
                                                        <Stack direction={"row"} style={{alignItems:'center', cursor:'pointer'}}>
                                                            { header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() )}
                                                        </Stack>
                                                    :
                                                        <Stack style={{alignItems:'center', justifyContent:'center', flexGrow:1, cursor:'pointer'}}>
                                                            { header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() )}
                                                        </Stack>
                                                }
                                            </Stack>
                                        </th>
                                    )}
                                </tr> 
                            ))}
                        </thead>
                        <tbody>
                            {
                                formsDataRowsLoading
                                ?
                                    isActive ?
                                        <ActiveFormsSkeleton/>
                                    :
                                        <InactiveFormsSkeleton/>
                                :
                                    table.getRowModel().rows.length > 0 ?
                                        table.getRowModel().rows.map(row => (
                                            <tr key={row.id} onClick={(e)=> {
                                                rowClick(row.original.id, {
                                                    name: row.original.name,
                                                    createdAt: row.original.createdAt,
                                                    pendingApproval: row.original.pendingApproval,
                                                    lastEdited: row.original.lastEdited,
                                                    lastSubmission: row.original.lastSubmission,
                                                    totalCount: row.original.totalCount,
                                                    link: formatFormURL(row.original.link),
                                                })
                                            }}>
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id}> 
                                                            {
                                                                cell.column.id !== 'select' && cell.column.id !== 'link' && cell.column.id !== 'qrCode' && cell.column.id !== 'options'?
                                                                    <Stack direction={"row"} style={{alignItems:'center', cursor:'pointer'}}>
                                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                    </Stack>
                                                                :
                                                                    <Stack style={{alignItems:'center', justifyContent:'center', flexGrow:1, cursor:'pointer'}}>
                                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                    </Stack>
                                                            }
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    :
                                        isActive ?
                                            <tr className={styles.trskeleton}>
                                                <td style={{width:'100%'}}>
                                                    <small className={styles['regular-400-14']} style={{color:styles.neutrals400, lineHeight:pxToRem(20)}}>
                                                        Your forms will appear here
                                                    </small>
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                            </tr>
                                        :
                                            <tr className={styles.trskeleton}>
                                                <td><IndeterminateCheckbox className={styles.round}/></td>
                                                <td style={{width:'100%'}}>
                                                    <small className={styles['regular-400-14']} style={{color:styles.neutrals400, lineHeight:pxToRem(20)}}>
                                                        Your archived forms will appear here
                                                    </small>
                                                </td>
                                                <td/>
                                                <td/>
                                                <td/>
                                                <td/>
                                            </tr>
                            }
                        </tbody>
                    </table>
                </Stack>
            </div>
            { formsDataRowsLoading ? null : CustomPaginationFooter()}
            <CustomModal
                open={openDeleteModal}
                handleClose={()=>{}}
                isLoading={isLoading}
                className={styles.operationModal}
                contentStyle={`${styles["insetBanner--red"]} ${styles["operationModalWrapper"]}`}
            >
                <Stack>
                    <h5>Permanently Delete Forms</h5>
                    <small>This action cannot be undone</small>
                    <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(12)}}/>
                    <small>Are you sure you want to delete {Object.keys(rowSelection).length} form(s)?</small>
                    <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(12)}}/>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <SecondaryButton onClick={closeDeleteModal} className={styles.cancelBtn}>Cancel</SecondaryButton>
                        <PrimaryButton onClick={confirmDelete} className={styles.destructiveBtn}>Yes, I'm Sure</PrimaryButton>
                    </Stack>
                </Stack>
            </CustomModal>
        </>
    );
}

export default FormsTable;