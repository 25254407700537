import React, { memo } from 'react';
import { Modal, Stack, styled } from "@mui/material";
import styles from "./index.module.scss";
import globalStyles from "../../../styles/_stylesParams.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import AlumniModalLoadingStatusBar from '../../../components/LoadingStatus/AlumniModalLoadingBar';
import LoadingComponent from '../../../components/LoadingStatus/Loading';

// LoadingOverlay styled component as defined in Step 1
const LoadingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 2000,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
}));

const ConstLoadingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 2000,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
}));

export type ModalType = "ERROR" | "DEFAULT";

const style = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", boxShadow: `0px ${pxToRem(4)} ${pxToRem(4)} 0px rgba(0, 0, 0, 0.25)`, };


export interface ICustomizedModal {
  title?: string;
  subTitle?: string;
  type?: ModalType;
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
  className?: string;
  isLoading?: boolean;
  loadingStr?: string;
  width?: number;
  isProcessing?: boolean; // New attribute
}

const AlumniModal = ({
  type,
  open,
  handleClose,
  children,
  className,
  isLoading = false,
  loadingStr,
  width,
  isProcessing = false, // Initialize with default value
}: ICustomizedModal) => (
  <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' className={className ?? ""}>
    <Stack sx={[style, { width: width ? pxToRem(width) : pxToRem(804) }]} className={styles.modalContentWrapper}>
      {isLoading && (isProcessing ? ( // Use isProcessing to determine which component to render
        <ConstLoadingOverlay>
          <LoadingComponent isLoading={isLoading} loadingStr={loadingStr} />
        </ConstLoadingOverlay>
      ) : (
        <LoadingOverlay>
          <AlumniModalLoadingStatusBar isLoading={isLoading} loadingStr={loadingStr} />
        </LoadingOverlay>
      ))}
      <div className={`${styles.colorBanner}`} style={{ background: type === "ERROR" ? globalStyles["secondaryPink600"] : globalStyles["primary500"] }}></div>
      <div>{children}</div>
    </Stack>
  </Modal>
);

export default memo(AlumniModal);