import { DocumentData, FieldValue, Timestamp } from 'firebase/firestore';

// Define a type for Linked Alumni details
export type Connection = {
    objectId: string;
    type: string;
    relationship: string;
};

export type Network<FV = never> = {
    orgId?: string;
    title?: string | FV; // Basic Information
    firstName?: string | FV;
    middleName?: string | FV;
    lastName?: string | FV;
    preferredName?: string | FV;
    maidenName?: string | FV;
    gender?: string | FV; // "Male" | "Female" | "Non-binary";
    pronouns?: string | FV; // "He/Him" | "She/Her" | "They/Them";
    ATSI?: boolean | FV; //
    emailPreferred?: string | FV;  // Contact Information
    emailPersonal?: string | FV;
    contactNumber?: string | FV;
    subscribeEvents?: boolean | FV;
    subscribeNewsLetter?: boolean | FV;

    networkTitle?: string | FV;
    networkTitleDescription?: string | FV;

    peerYear?:number | FV;

    suburb?: string | FV;
    country?: string | FV;
    city?:string | FV;

    socials?: {name: string; link: string}[]  | FV;
    personalInterests?: [] | FV;
    skills?: [] | FV;
    connectionList?: Connection[] | FV; // Add linked alumni
    

    currentEmployment?: Array<{id:string, jobTitle: string}> | FV;
    educationDegree?: Array<{id:string, degree: string}> | FV;

    photoUrlId?:string | FV;
    photoUrl?: string | FV;

    linkendIn?: string | FV;
    facebook?: string | FV;
    instagram?: string | FV;
    other?: string | FV;

    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;

    throughFormId?: string | FV;

    living?:string;
};
export const getNetwork = (data: DocumentData):Network => {
  return {
    orgId: data.orgId,
    title: data.title,
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    preferredName: data.preferredName,
    maidenName: data.maidenName,
    gender: data.gender,
    pronouns: data.pronouns,
    ATSI: data.ATSI,
    emailPreferred: data.emailPreferred,
    emailPersonal: data.emailPersonal,
    contactNumber: data.contactNumber,
    suburb: data.suburb,
    country: data.country,
    city: data.city,
    subscribeEvents: data.subscribeEvents,
    subscribeNewsLetter: data.subscribeNewsLetter,
    networkTitle: data.networkTitle,
    networkTitleDescription: data.networkTitleDescription,
    socials: data.socials,
    personalInterests: data.personalInterests,
    skills: data.skills,
    peerYear: data.peerYear,
    currentEmployment: data.currentEmployment,
    educationDegree: data.educationDegree,
    photoUrl: data.photoUrl,
    linkendIn: data.linkendIn,
    facebook: data.facebook,
    instagram: data.instagram,
    other: data.other,
    connectionList: data.connectionList,
    created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
    updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
  };
}

export type Schooling<FV = never>  = {
  schoolOption?: string | FV; //CURRENT, NEW
  type?:string | FV; //PRIMARY, SECONDARY, PRIMARY_SECONDARY

  peerYear?: number | FV; //2009
  yearStarted?: number | FV;
  yearLeft?: number | FV;

  schoolName?: string | FV;

  schoolCampus?: string | FV;
  schoolHouse?: string | FV;

  networkId?: string,
  orgId?: string,
  created_at?: FieldValue | number;
  updated_at?: FieldValue | number;
}
export const getSchooling = (data: DocumentData): Schooling => {
  return {
    schoolOption: data.schoolOption,
    type: data.type,
    peerYear: data.peerYear,
    yearStarted: data.yearStarted,
    yearLeft: data.yearLeft,
    schoolName: data.schoolName,
    schoolCampus: data.schoolCampus,
    schoolHouse: data.schoolHouse,
    networkId: data.networkId,
    orgId: data.orgId,
    created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
    updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
  };
}

export type Employment<FV = never> = {
  jobTitle?:string | FV;
  nameOfCompany?:string | FV;
  employmentType?:string | FV;
  industry?:string | FV;

  startedMonth?: number | FV;
  startedYear?: number | FV;
  endMonth?: number | FV;
  endYear?: number | FV;

  stillInRole?:boolean | FV;

  suburb?: string | FV;
  state?: string | FV;
  postCode?: string | FV;
  country?: string | FV;
  networkId?: string,
  orgId?: string,
  created_at?: FieldValue | number;
  updated_at?: FieldValue | number;
}

export type ImportNetwork<FV = never> = {
  id?: string | FV,
  fileName?: string | FV,
  date?: FieldValue | number,
  totalImported?: number | FV,
  totalRejected?:number | FV,
  networkList?: string[] | FV,
  error?: string | FV,
}

export const getImportNetwork = (data: DocumentData, id: string): ImportNetwork => {
  return {
      id: id,
      fileName: data.fileName ?? 'N/A', // Provide a default value if undefined
      date: data.date ? (data.date as Timestamp).toMillis() : undefined, // Handle potential undefined date
      totalImported: data.totalImported ?? 0, // Default to 0 if undefined
      totalRejected: data.totalRejected ?? 0, // Default to 0 if undefined
      networkList: data.networkList ?? [],
      error: data.error ?? null, // Handle potential undefined error
  };
}


export const getEmployment = (data: DocumentData): Employment => {
  return {
    jobTitle: data.jobTitle,
    nameOfCompany: data.nameOfCompany,
    employmentType: data.employmentType,
    industry: data.industry,
    startedMonth: data.startedMonth,
    startedYear: data.startedYear,
    endMonth: data.endMonth,
    endYear: data.endYear,
    stillInRole: data.stillInRole,
    suburb: data.suburb,
    state: data.state,
    postCode: data.postCode,
    country: data.country,
    networkId: data.networkId,
    orgId: data.orgId,
    created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
    updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
  };
};

export type Education<FV = never> = {
  type?: string | FV;
  degree?: string | FV;
  fieldOfStudy?: string[] | FV;
  institution?: string | FV;
  qualificationComplete?: boolean | FV;
  startedMonth?: number | FV;
  startedYear?: number | FV;
  endOrExpectedMonth?: number | FV;
  endOrExpectedYear?: number | FV;
  networkId?: string,
  orgId?: string,
  created_at?: FieldValue | number;
  updated_at?: FieldValue | number;
};

export const getEducation = (data: DocumentData): Education => {
  const fieldOfStudyArray = typeof data.fieldOfStudy === 'string' ? [data.fieldOfStudy] : data.fieldOfStudy;
  return {
    type: data.type,
    degree: data.degree,
    fieldOfStudy: fieldOfStudyArray,
    institution: data.institution,
    qualificationComplete: data.qualificationComplete,
    startedMonth: data.startedMonth,
    startedYear: data.startedYear,
    endOrExpectedMonth: data.endOrExpectedMonth,
    endOrExpectedYear: data.endOrExpectedYear,
    networkId: data.networkId,
    orgId: data.orgId,
    created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
    updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
  };
};

export type Note<FV = never> = {
  id?: string;
  title?: string | FV;
  note?: string | FV;
  created_at?: FieldValue | number;
  updated_at?: FieldValue | number;
};

export const getNote = (data: DocumentData): Note => {
  return {
    title: data.title,
    note: data.note,
    created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
    updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
  };
};

