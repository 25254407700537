import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, InputAdornment, IconButton, Stack } from '@mui/material';

interface NumberIconBarProps {
    onUpdate: (count: number) => void;
    initialCount: number;
    label: string;
    Icon: React.ReactNode;
    disabled?: boolean;
}

const NumberIconBar: React.FC<NumberIconBarProps> = ({ onUpdate, initialCount, label, Icon, disabled }) => {
    const [count, setCount] = useState(initialCount);
    const [tempCount, setTempCount] = useState(String(initialCount)); // Temporarily holds the count as a string while editing
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempCount(event.target.value); // Directly use the string value
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const newCount = parseInt(tempCount, 10) || 0;
            setCount(newCount);
            onUpdate(newCount);
            setIsFocused(false);
        }
    };

    const handleFocus = () => {
        setTempCount(count === 0 ? '' : String(count)); // Reset temp count to an empty string if count is zero when focused
        setIsFocused(true);
    };

    const handleBlur = () => {
        const newCount = parseInt(tempCount, 10) || 0;
        setCount(newCount);
        onUpdate(newCount);
        setIsFocused(false);
        setTempCount(String(newCount)); // Ensure the string version is updated too
    };

    useEffect(() => {
        // Update the local state when the initialCount prop changes
        setCount(initialCount);
        setTempCount(String(initialCount));
    }, [initialCount]);  // Dependencies array ensures this only runs when initialCount changes

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px',
                borderBottom: !isFocused ? 1 : 0,
                borderColor: isFocused ? '#76A8A1' : '#A3A3A3',
                '&:hover': {
                    borderBottom: !isFocused ? 2 : 0,
                    borderColor: '#76A8A1',
                },
                cursor: 'pointer'
            }}
            onClick={!isFocused && !disabled ? handleFocus : undefined}
        >
            {isFocused ? (
                <TextField
                    autoFocus
                    variant="outlined"
                    type="number"
                    label={label}
                    value={tempCount}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    InputProps={{
                        style: { textAlign: 'center', borderColor: '#76A8A1' }
                    }}
                    sx={{
                        flexGrow: 1,
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#76A8A1',
                            },
                            '&:hover fieldset': {
                                borderColor: '#76A8A1', // Ensure hover maintains the same border color
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#76A8A1', // Ensure focus maintains the same border color
                            }
                        },
                        '& .MuiInputLabel-root.Mui-focused': { // Ensures label color matches the border color when focused
                            color: '#76A8A1',
                        }
                    }}
                />
            ) : (
                <>
                <Stack direction={'row'} alignItems="center" justifyContent={'center'}>
                    <IconButton>
                        {Icon}
                    </IconButton>
                    <small>{label}</small>
                    
                </Stack>
                <small>
                        {count || '0'}
                    </small>
                </>
                
            )}
        </Box>
    );
};

export default NumberIconBar;
