import React from 'react';
import { IconButton, Typography, Stack, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { PhotoData } from '../../../firebase/type-media';
import pxToRem from '../../../helpers/pxToRem';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';

// Update the props interface to use PhotoData
interface IProfilePhotoProps {
    photo: PhotoData; // Use PhotoData type for photo
    isPermit: boolean;
    onDelete: (id: string) => void;
    onDownload: (id: string) => void;
    onPicClick: (id: string) => void;
}

const ProfilePhoto: React.FC<IProfilePhotoProps> = ({ photo, isPermit, onDelete, onDownload, onPicClick }) => {

    const formatDate = (timestamp: number) => {
        // Check if the timestamp is in seconds (usually a 10-digit number)
        const date = timestamp.toString().length === 10
            ? dayjs.unix(timestamp) // Convert from seconds to date
            : dayjs(timestamp); // Assume milliseconds if not in seconds

        const today = dayjs();

        return date.isSame(today, 'day')
            ? 'Today'
            : date.format('D MMM YY');
    };

    const getPhotoStyles = (photoName: string | undefined) => {
        switch (photoName) {
            case 'Profile Photo':
                return { color: '#8AB8B1', text: 'Upload a profile photo of this alumni. This photo will be displayed across Loop.' };
            case 'Recent Photo':
                return { color: '#76A8A1', text: 'Upload a recent photo of this alumni' };
            case 'School Photo':
                return { color: '#549189', text: 'Upload a school photo of this alumni' };
            default:
                return { color: '#8AB8B1', text: 'Upload a photo of this alumni. This photo will be used in the alumni profile.' };
        }
    };
    

    return (
        <Box sx={{ textAlign: 'center', margin: 'auto', overflow: 'hidden'}}>
            <Box sx={{ border: '1px dashed #A3A3A3', borderRadius: pxToRem(8), padding: pxToRem(2), display: 'flex', justifyContent: 'center', alignItems: 'center', height: pxToRem(325), width: pxToRem(325) }} onClick={() => onPicClick(photo.id!)}>
                {photo.link ? (
                    <img
                        src={photo.link}
                        alt={photo.name}
                        style={{
                            maxWidth: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            borderRadius: pxToRem(8)
                        }}
                    />
                ) : (
                    <Stack direction={'column'} alignItems={'center'} spacing={pxToRem(24)}>
                    <svg width={pxToRem(80)} height={pxToRem(80)} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="40" cy="40" r="40" fill={isPermit? '#d4d4d4': getPhotoStyles(photo?.name).color} />
                        <path d="M40.0003 21.6667V58.3334M21.667 40.0001H58.3337" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <small className={`${styles['text--neutrals400']} regular`} style={{maxWidth: pxToRem(273)}}>{getPhotoStyles(photo?.name).text}</small>
                </Stack>


                )}
            </Box>
            <Stack direction="column" spacing={1} paddingTop={pxToRem(6)} paddingBottom={pxToRem(6)}>
                <Stack direction='row' justifyContent={'space-between'}>

                    <Stack direction={'column'} maxWidth={'70%'}>

                        <Typography variant="h6" component="h3" className='light' noWrap sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {photo.name}
                        </Typography>
                        <Typography marginTop={0} variant="body2" color="#737373" textAlign={'start'} sx={{ maxWidth: '70%' }}>
                            {photo.uploaded_at && (formatDate(photo.uploaded_at as number))}
                        </Typography>
                    </Stack>
                    {photo.id &&
                        <Stack direction="row" spacing={1} justifyContent="center" alignItems={'center'}>
                            <IconButton onClick={() => onDownload(photo.id!)} aria-label="download">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 15.3301V16.5301C21 18.2102 21 19.0503 20.673 19.692C20.3854 20.2565 19.9265 20.7155 19.362 21.0031C18.7202 21.3301 17.8802 21.3301 16.2 21.3301H7.8C6.11984 21.3301 5.27976 21.3301 4.63803 21.0031C4.07354 20.7155 3.6146 20.2565 3.32698 19.692C3 19.0503 3 18.2102 3 16.5301V15.3301M17 10.3301L12 15.3301M12 15.3301L7 10.3301M12 15.3301V3.33008" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 15.3301V16.5301C21 18.2102 21 19.0503 20.673 19.692C20.3854 20.2565 19.9265 20.7155 19.362 21.0031C18.7202 21.3301 17.8802 21.3301 16.2 21.3301H7.8C6.11984 21.3301 5.27976 21.3301 4.63803 21.0031C4.07354 20.7155 3.6146 20.2565 3.32698 19.692C3 19.0503 3 18.2102 3 16.5301V15.3301M17 10.3301L12 15.3301M12 15.3301L7 10.3301M12 15.3301V3.33008" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 15.3301V16.5301C21 18.2102 21 19.0503 20.673 19.692C20.3854 20.2565 19.9265 20.7155 19.362 21.0031C18.7202 21.3301 17.8802 21.3301 16.2 21.3301H7.8C6.11984 21.3301 5.27976 21.3301 4.63803 21.0031C4.07354 20.7155 3.6146 20.2565 3.32698 19.692C3 19.0503 3 18.2102 3 16.5301V15.3301M17 10.3301L12 15.3301M12 15.3301L7 10.3301M12 15.3301V3.33008" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </IconButton>
                            {!isPermit &&
                            <IconButton onClick={() => onDelete(photo.id!)} aria-label="delete">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5557 6.88574V6.08574C16.5557 4.96564 16.5557 4.40558 16.3377 3.97776C16.1459 3.60144 15.84 3.29548 15.4636 3.10373C15.0358 2.88574 14.4758 2.88574 13.3557 2.88574H11.7557C10.6356 2.88574 10.0755 2.88574 9.64768 3.10373C9.27136 3.29548 8.9654 3.60144 8.77365 3.97776C8.55566 4.40558 8.55566 4.96564 8.55566 6.08574V6.88574M10.5557 12.3857V17.3857M14.5557 12.3857V17.3857M3.55566 6.88574H21.5557M19.5557 6.88574V18.0857C19.5557 19.7659 19.5557 20.606 19.2287 21.2477C18.9411 21.8122 18.4821 22.2711 17.9176 22.5588C17.2759 22.8857 16.4358 22.8857 14.7557 22.8857H10.3557C8.67551 22.8857 7.83543 22.8857 7.19369 22.5588C6.62921 22.2711 6.17026 21.8122 5.88264 21.2477C5.55566 20.606 5.55566 19.7659 5.55566 18.0857V6.88574" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.5557 6.88574V6.08574C16.5557 4.96564 16.5557 4.40558 16.3377 3.97776C16.1459 3.60144 15.84 3.29548 15.4636 3.10373C15.0358 2.88574 14.4758 2.88574 13.3557 2.88574H11.7557C10.6356 2.88574 10.0755 2.88574 9.64768 3.10373C9.27136 3.29548 8.9654 3.60144 8.77365 3.97776C8.55566 4.40558 8.55566 4.96564 8.55566 6.08574V6.88574M10.5557 12.3857V17.3857M14.5557 12.3857V17.3857M3.55566 6.88574H21.5557M19.5557 6.88574V18.0857C19.5557 19.7659 19.5557 20.606 19.2287 21.2477C18.9411 21.8122 18.4821 22.2711 17.9176 22.5588C17.2759 22.8857 16.4358 22.8857 14.7557 22.8857H10.3557C8.67551 22.8857 7.83543 22.8857 7.19369 22.5588C6.62921 22.2711 6.17026 21.8122 5.88264 21.2477C5.55566 20.606 5.55566 19.7659 5.55566 18.0857V6.88574" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.5557 6.88574V6.08574C16.5557 4.96564 16.5557 4.40558 16.3377 3.97776C16.1459 3.60144 15.84 3.29548 15.4636 3.10373C15.0358 2.88574 14.4758 2.88574 13.3557 2.88574H11.7557C10.6356 2.88574 10.0755 2.88574 9.64768 3.10373C9.27136 3.29548 8.9654 3.60144 8.77365 3.97776C8.55566 4.40558 8.55566 4.96564 8.55566 6.08574V6.88574M10.5557 12.3857V17.3857M14.5557 12.3857V17.3857M3.55566 6.88574H21.5557M19.5557 6.88574V18.0857C19.5557 19.7659 19.5557 20.606 19.2287 21.2477C18.9411 21.8122 18.4821 22.2711 17.9176 22.5588C17.2759 22.8857 16.4358 22.8857 14.7557 22.8857H10.3557C8.67551 22.8857 7.83543 22.8857 7.19369 22.5588C6.62921 22.2711 6.17026 21.8122 5.88264 21.2477C5.55566 20.606 5.55566 19.7659 5.55566 18.0857V6.88574" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </IconButton>}
                        </Stack>}
                </Stack>

            </Stack>
        </Box>
    );
};

export default ProfilePhoto;