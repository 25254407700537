import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { PrimaryOnboardingButton } from '../../../Buttons/OnboardingButtons'
import styles from './OrganisationCreated.module.scss'
import { useNavigate } from "react-router-dom";
import pxToRem from '../../../../helpers/pxToRem'
import loopLogo from "../../../../assets/images/shared/loopSquareLogo.png"
import { setUserOnboarding } from '../../../../redux/userSlice'
import globalStyles from "../../../../styles/_stylesParams.module.scss";
import { URL_PARAMS } from '../../../../routers/shared'
import { setIsOnBoardingFlowState } from '../../../../redux/sharedStateSlice'

// Add a prop type for the function to be passed
interface OrganisationCreatedDashboardProps {
    onGoToDashboard: () => void;  // Function to execute on button click
  }
  
  const OrganisationCreatedDashboard: React.FC<OrganisationCreatedDashboardProps> = ({ onGoToDashboard }) => {
    const organisation = useSelector((state: RootState)=>state.onBoarding.organisation);

      return (
          <Stack className={styles.container}>
              <div className={styles.betaLogoBorder}><img src={loopLogo} alt='logo-background' className={styles.betaLogo}/></div>
              <h4 className={"regular"} style={{marginBottom:pxToRem(16), textAlign:'center',alignSelf:'center'}}><b style={{fontWeight:600}}>{organisation.name}</b> has been created</h4>
              <PrimaryOnboardingButton style={{width: '100%'}} className={styles.onBoardingButton} onClick={onGoToDashboard}>
                  <p style={{color:globalStyles['shades0']}}>Close</p>
              </PrimaryOnboardingButton>
          </Stack>
      );
  };
  
  export default OrganisationCreatedDashboard;