import { IconButton, Menu, MenuItem, Stack, TextField } from "@mui/material";
import { memo, useState } from "react";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import Line from "../../../components/Line/Line";
import constants from "../../../helpers/constants";
import { getAlumniProfileError, getErrorMsgForInput, getHelpDeskMessageError } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import styles from "./../index.module.scss";
import globalStyles from "../../../styles/_stylesParams.module.scss"
import { ITabInterface } from "./TabInterfaces";
import { Note } from "../../../firebase/types-alumni";
import { deleteField, FieldValue, serverTimestamp } from "firebase/firestore";
import { createNote, deleteNote, updateNote } from "../../../firebase/alumniApis";
import { getTimeDiffDes2DatesMillsNow } from "../../../helpers/timeUtils";
import { useSelector } from "react-redux";
import { PermissionState } from "../../../firebase/types-organisations";
import { RootState } from "../../../redux";


const NotesTab = ({orgId, alumniId, noteList}:ITabInterface) => {
    const [isNoteModal, setIsNoteModal] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [ menuShowId, setMenuShowId] = useState(-1);
    const menuList = [ { label: "Edit", val: 0}, { label: "Delete", val: 1} ];
    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
    const menuClose = () => { setAnchorEl(null); }
    const [errorMsg, setErrorMsg] = useState("");

    const alumniPermission = useSelector((state:RootState) => state.globalState.permissions?.alumni);

    const title =  useTextInput({ inputValidator: (input) => getAlumniProfileError(input,2,100)});
    const note =  useTextInput({ inputValidator: (input) => getAlumniProfileError(input,5,1000, undefined, true)});
    const [currentEditId, setCurrentEditId] = useState<string | null>(null)
    const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null)
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const resetForm = () => {
        title.setValue("");  title.setHasTouched(false);
        note.setValue("");  note.setHasTouched(false);
        setCurrentDeleteId(null);
        setCurrentEditId(null);
        setMenuShowId(-1);
    }
    const addData = async () => {
        if(!orgId || !alumniId){return;}
        const mandatoryInputs =  [title];  // Current School Validation
        let validated = true;  
        mandatoryInputs.map((input) => input.setHasTouched(true)); 
        mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
        if (!validated) { return; } 
        let optionalValidated = true;   // Optional Fields
        const optionalInput = [note];
        optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; }); 
        if(!optionalValidated){ return; }

        setLoadingStatus(true);
        setLoadingStatusStr("Saving..");
        const noteData: Note = {
            ...(title.value && {title: title.value.trim()}),
            ...(note.value && {note: note.value.trim()}),
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
        }
        const res = await createNote(orgId, alumniId, noteData);
        if (res.code === 500) { setErrorMsg("Errors: note create."); } 
        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsNoteModal(false);
        resetForm();
    }

    const onEdit = (item: Note) => {
        if(!item.title || !item.id) {return}
        setCurrentEditId(item.id);
        title.setValue(item.title);
        note.setValue(item.note ?? "");
        setMenuShowId(0);
        setIsNoteModal(true);
    }

    const updateData = async () => {
        if(!orgId || !alumniId || !currentEditId){return;}
        const mandatoryInputs =  [title]; 
        let validated = true;  
        mandatoryInputs.map((input) => input.setHasTouched(true)); 
        mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
        if (!validated) { return; } 
        let optionalValidated = true;
        const optionalInput = [note];
        optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; }); 
        if(!optionalValidated){ return; }
        setLoadingStatus(true);
        setLoadingStatusStr("Updating..");
        const noteData: Note<FieldValue> = {
            ...(title.value && {title: title.value.trim()}),
            ...(note.value === "" ? {note: deleteField()} : {note: note.value.trim()}),
            updated_at: serverTimestamp()
        }
        const res = await updateNote(orgId, alumniId, currentEditId, noteData);
        if (res.code === 500) { setErrorMsg("Errors: note update."); } 
        setCurrentEditId(null);
        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsNoteModal(false);
        setMenuShowId(-1);
        resetForm();
    }

    const deleteData = async () => {
        if(!orgId || !alumniId || !currentDeleteId){return}
        setDeleteLoadingStatus(true);
        try{ await deleteNote(orgId, alumniId, currentDeleteId) }
        catch(ex){ setErrorMsg("Errors: note delete, please try again"); console.log(ex); }
        setDeleteLoadingStatus(false);
        setIsDeleteModal(false);
        setCurrentDeleteId(null);
        setMenuShowId(-1);
        resetForm();
    }
    
    return(
        <>
            <CustomizedModal open={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} type='ERROR' children={<p>{errorMsg}</p>} />
            <CustomizedModal width={660} open={isNoteModal} handleClose={() => {}} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                <Stack width={"100%"} alignItems={"end"}>
						    <IconButton onClick={() =>  {setIsNoteModal(false);resetForm();}}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
					    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.alumni_details_modalHeaderTitleWrapper}>
                        <div className={styles.alumni_details_iconWithCircleWrapper}> <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M14 17H8M16 13H8M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
                        <div style={{flexGrow: 1}}>
                        <label className={styles.alumni_details_modalHeaderTitle}> { (currentEditId!==null)?"Edit Note":"Add New Note" } </label> 
                        </div>
                    </Stack>
                    <Stack spacing={2}  style={{marginTop:pxToRem(40)}}>
                        <InputTextField type={"text"} shrinkLabelWidth={33} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={title.value} containerStyle={{flex:1}} label='Title*' value={title.value} onChange={(e) => title.setValue(e.target.value)} error={title.hasError && title.hasTouched} helperText={<small>{title.getErrorMessage()}</small>} />
                        <TextField
                            sx={{
                                    "& label.Mui-focused": { color: globalStyles.primary400, },
                                    "& .MuiInput-underline:after": { borderBottomColor: "#B2BAC2", },
                                    "& .MuiInputLabel-root": { fontSize: pxToRem(constants.addAlumniFontSize), },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: globalStyles.neutrals400, },
                                        "&:hover fieldset": { borderColor: globalStyles.primary400, },
                                        "&.Mui-focused fieldset": { borderColor: globalStyles.primary400, },
                                        "& .MuiOutlinedInput-input": { padding: 0, fontSize: pxToRem(constants.addAlumniFontSize) },
                                    },
                            }}
                            value={note.value} onChange={(e) => note.setValue(e.target.value)}
                            id="outlined-multiline-flexible" label="Add Note" multiline minRows={5} maxRows={5} style={{width:'100%'}}
                            error={note.hasError && note.hasTouched} helperText={<small>{note.getErrorMessage()}</small>} inputProps={{maxLength:1000}}
                        />
                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: pxToRem(12), marginTop:pxToRem(40)}}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                    {(currentEditId) ? 
                        <WarningButton className={styles.button} onClick={()=>{setIsNoteModal(false);setIsDeleteModal(true); setCurrentDeleteId(currentEditId)}}> Delete </WarningButton>
							: 
						<SecondaryButton className={styles.button} onClick={() => { setIsNoteModal(false); resetForm(); }}> Cancel </SecondaryButton>	
						} 
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} onClick={() => {
                                if(menuShowId === -1){
                                    addData();
                                }else if(menuShowId === 0){
                                    updateData();
                                }
                            }}>Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal open={isDeleteModal} handleClose={() => {}} type="ERROR" isLoading={deleteLoadingStatus} loadingStr={"Removing..."} >
                <Stack>
                    <Stack spacing={0.5} style={{ marginTop: 20, marginBottom: 10}} className={styles.modalHeaderTitleWrapper}>
                        <label className={styles.modalTitle}>Delete Note</label> 
                        <label className={styles.modalSubTitle}>This action cannot be undone</label> 
                    </Stack>
                    <Stack style={{ marginBottom: 20}} spacing={3} className={styles.modalHeaderTitleWrapper}>
                        <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: 20}}></div>
                        <label className={styles.modalBodyText}>Are you sure you want to delete this note?</label> 
                        <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: 20}}></div>
                    </Stack>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(null); }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <WarningButton className={styles.button} onClick={deleteData}> Yes, I'm Sure </WarningButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack>
                <Stack marginBottom={pxToRem(20)}>
                  {alumniPermission && alumniPermission === PermissionState.EDITOR && <PrimaryButton style={{ width: pxToRem(132), height: pxToRem(32) }}  onClick={() => {setIsNoteModal(true);setMenuShowId(-1)}}>New Note</PrimaryButton>}
                </Stack>
           
                <Menu id="schooling-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={menuClose} autoFocus={false}>
                    { menuList.map((menu, index)=> <MenuItem key={index} onClick={() => { if(noteList && noteList.length > 0) {setMenuShowId(menu.val); } menuClose(); }} style={{minWidth: 150}}>{menu.label}</MenuItem>) }
                </Menu>
                <Stack spacing={0} className={styles.tableWrapper}>
                <table className={styles.alumni_notes_table}>
                    <thead>
                        <tr>   
                         {noteList && noteList.length > 0 && <td style={{ flex: 1,backgroundColor: "#F5F5F5", width: pxToRem(40)}}></td>}   
                        <td style={{ flex: 2,  backgroundColor: "#F5F5F5", color: "#1F1F1F", fontWeight: 400}}>Notes</td>
                        <td style={{ flex: 2,  backgroundColor: "#F5F5F5", color: "#1F1F1F", fontWeight: 400}}></td>
                        </tr>
                    </thead>
                    {  noteList && noteList.length > 0 && 
                        <tbody>
                            {
                                noteList.map((item, index)=>{
                                    return  <tr key={item.id && item.id + index} className={styles.tableRowHover} onClick={()=>{if(alumniPermission && alumniPermission === PermissionState.EDITOR) onEdit(item)}}>
                                                <td style={{textAlign:"center", width:pxToRem(40), height:'100%', padding:0}}>
                                                    { menuShowId === 0? <svg onClick={()=>{onEdit(item)}} style={{cursor:'pointer'}} width={pxToRem(19)} height={pxToRem(19)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 2.62132H5.8C4.11984 2.62132 3.27976 2.62132 2.63803 2.9483C2.07354 3.23592 1.6146 3.69486 1.32698 4.25935C1 4.90109 1 5.74116 1 7.42132V15.8213C1 17.5015 1 18.3416 1.32698 18.9833C1.6146 19.5478 2.07354 20.0067 2.63803 20.2943C3.27976 20.6213 4.11984 20.6213 5.8 20.6213H14.2C15.8802 20.6213 16.7202 20.6213 17.362 20.2943C17.9265 20.0067 18.3854 19.5478 18.673 18.9833C19 18.3416 19 17.5015 19 15.8213V11.6213M6.99997 14.6213H8.67452C9.1637 14.6213 9.40829 14.6213 9.63846 14.5661C9.84254 14.5171 10.0376 14.4363 10.2166 14.3266C10.4184 14.2029 10.5914 14.03 10.9373 13.6841L20.5 4.12132C21.3284 3.29289 21.3284 1.94975 20.5 1.12132C19.6716 0.292894 18.3284 0.292893 17.5 1.12132L7.93723 10.6841C7.59133 11.03 7.41838 11.2029 7.29469 11.4048C7.18504 11.5837 7.10423 11.7788 7.05523 11.9829C6.99997 12.213 6.99997 12.4576 6.99997 12.9468V14.6213Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> : null }
                                                    { menuShowId === 1? <svg onClick={()=>{if(item.id){setIsDeleteModal(true); setCurrentDeleteId(item.id)}}} style={{cursor:'pointer'}} width={pxToRem(20)} height={pxToRem(22)} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.5 5.25V4.35C15.5 3.08988 15.5 2.45982 15.2548 1.97852C15.039 1.55516 14.6948 1.21095 14.2715 0.995235C13.7902 0.75 13.1601 0.75 11.9 0.75H10.1C8.83988 0.75 8.20982 0.75 7.72852 0.995235C7.30516 1.21095 6.96095 1.55516 6.74524 1.97852C6.5 2.45982 6.5 3.08988 6.5 4.35V5.25M8.75 11.4375V17.0625M13.25 11.4375V17.0625M0.875 5.25H21.125M18.875 5.25V17.85C18.875 19.7402 18.875 20.6853 18.5071 21.4072C18.1836 22.0423 17.6673 22.5586 17.0322 22.8821C16.3103 23.25 15.3652 23.25 13.475 23.25H8.525C6.63482 23.25 5.68973 23.25 4.96778 22.8821C4.33274 22.5586 3.81643 22.0423 3.49285 21.4072C3.125 20.6853 3.125 19.7402 3.125 17.85V5.25" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> : null }
                                                    { menuShowId === -1?  <svg width={pxToRem(8)} height={pxToRem(9)} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4.5" r="4" fill="#737373"/></svg> : null }   
                                                </td>
                                                <td style={{}}>
                                                    <Stack direction={"column"} spacing={0}>
                                                        <label className='regular-400-14' style={{color:globalStyles["shades100"], lineHeight:pxToRem(20)}}>{item.title}</label>
                                                        <label className='regular-400-12' style={{marginTop:pxToRem(2), textAlign:'justify', color:globalStyles["neutrals400"],lineHeight:pxToRem(18)}}>{item.note}</label>
                                                    </Stack>
                                                </td>
                                                <td style={{width: '24%', textAlign: 'right'}}>
                                                    <label className='regular-400-12' style={{ color:globalStyles["neutrals400"]}}>Last edited {item.updated_at && typeof item.updated_at === 'number' ? getTimeDiffDes2DatesMillsNow(item?.updated_at) : "-"}</label>
                                                </td>
                                            </tr>   
                                })
                            }
                        </tbody>
                    }
                </table>
                </Stack>
            </Stack>
            {
                noteList && noteList.length <= 0
                ?
                    alumniPermission && alumniPermission === PermissionState.EDITOR ?
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">Add New</label>
                        <IconButton onClick={() => setIsNoteModal(true)}>
                            <svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </IconButton>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                    : 
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">No Record</label>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                :
                null
            }
        </>
    );
}
export default memo(NotesTab);