import { Avatar, Divider, IconButton, Slider, Stack, styled, Tooltip } from "@mui/material";
import CustomizedModal from ".";
import pxToRem from "../../helpers/pxToRem";
import { PrimaryButton, SecondaryButton, WarningButton } from "../Buttons/ButtonUI";
import styles from './index.module.scss';
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import Cropper, { Area } from 'react-easy-crop'
import { mapValue } from "../../helpers/utils";
import { getCroppedImg } from "../../helpers/canvasUtils";
import AlertComponent from "../AlertComponent";
import { title } from "process";

interface IUploadModal {
    title?: string,
    subTitle?: string,
    uploadState: boolean;
    fileUrl: string;
    fileId?: string;
    open: boolean; close: () => void;
    upload: (file: File, name: string) => void;
    fileDelete?: () => void;
}

export const CustomSlider = styled(Slider)({
    color: '#37665F',
    width: '256px',
    '& .MuiSlider-thumb': {
      height: '12px',
      width: '12px',
      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        '@media (hover: none)': {
          boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)'
        },
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
      '& .MuiSlider-rail': {
             height: '6px',
  },
    }
  });

const UploadModal = (iUploadModal: IUploadModal) => {
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [errorStr, setErrorStr] = useState<string | undefined>(undefined);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [errorDialog, setErrorDialog] = useState(false);
    const [errorDialogStr, setErrorDialogStr] = useState("");

    const readFile = (file: any) => {
        if (file) {
            const source = file.files[0];
            const size = file.files[0].size;
            const fileSizeLimit = 2; //MB
            if ((size / 1024 / 1024) < fileSizeLimit) {
                if (source.type.includes("image/jpeg") || source.type.includes("image/png")) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        setImgSrc(reader.result?.toString() || undefined);
                        setFileName(source.name);
                        setIsUploadBtnDisabled(false);
                        setErrorStr(undefined);
                        setIsFileSelected(true);
                    });
                    reader.readAsDataURL(source);
                }
                else {
                    setErrorStr("Unsupported file type.");
                    setFileName(undefined);
                    setIsUploadBtnDisabled(true);
                }
            }
            else {
                // setErrorStr("Max file size limit.");
                setErrorStr("Max File Size Exceeded");
                setFileName(undefined);
                setIsUploadBtnDisabled(true);
            }
        }
        else {
            setErrorStr("File not found.");
            setFileName(undefined);
            setIsUploadBtnDisabled(true);
        }
    }
    const drag = (event: React.DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") { setDragActive(true); }
        else if (event.type === "dragleave") { setDragActive(false); }
    }
    const drop = (event: React.DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            readFile(event.dataTransfer);
        }
    }
    const pictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            readFile(event.target);
        }
    }
    const reset = () => {
        setDragActive(false);
        setErrorStr(undefined);
        setFileName(undefined);
        setIsUploadBtnDisabled(true);
        setZoom(1);
        setImgSrc(undefined);
        setIsFileSelected(false);
        setErrorDialog(false);
        setRotation(0);
    }
    const rotateRight = () => {
        setRotation(rotation + 90);
    }

    const rotateLeft = () => {
        setRotation(rotation - 90);
    }
    const uploadFile = async () => {
        try {
            const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels, rotation);
            if (croppedImage) {
                new Compressor(croppedImage,
                    {
                        quality: 0.6,
                        error: (error) => { setErrorDialog(true); setErrorDialogStr(error.message); },
                        success: (compressedResult) => {
                            iUploadModal.upload(compressedResult as File, fileName ?? "");
                        }
                    }
                );
            }
            else {
                setErrorDialog(true);
                setErrorDialogStr("Could not able to cropped image. Please try again.");
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => { setCroppedAreaPixels(croppedAreaPixels) }

    useEffect(() => {
        if (iUploadModal.fileUrl) {
            setRotation(0);
            setIsFileSelected(true);
            setImgSrc(iUploadModal.fileUrl);
            setFileName(iUploadModal.fileUrl);
            setIsUploadBtnDisabled(false);
        }
        else {
            reset();
        }
    }, [iUploadModal.open]);

    const isServerFile = () => {
        if (imgSrc?.startsWith("https://")) return true;
        return false;
    }

    return (
        <>
            <AlertComponent handleClose={() => setErrorDialog(false)} visible={errorDialog} severity={"error"} message={errorDialogStr}></AlertComponent>
            <CustomizedModal open={iUploadModal.open} handleClose={() => { }} width={700}>
                <Stack sx={{ marginTop: pxToRem(30), marginBottom: pxToRem(5) }} spacing={4}>
                    <Stack spacing={1}>
                        <label className={styles.uploadPictureTitle}>{iUploadModal.title ?? "Upload Profile Picture"}</label>
                        <label className={styles.uploadPictureSubTitle}>{iUploadModal.subTitle ?? "This will be displayed across Loop"}</label>
                    </Stack>
                    <Divider />
                    {
                        !isFileSelected ?
                            <Stack spacing={1} className={`${styles.uploadPictureBox} ${dragActive ? styles.uploadPictureBoxActive : ''}`} onClick={() => { inputRef.current?.click() }} onDragLeave={drag} onDragEnter={drag} onDragOver={drag} onDrop={drop}>
                                <input ref={inputRef} type='file' multiple={false} onChange={pictureChange} style={{ display: "none" }} />
                                <Stack className={styles.uploadIconWrapper}>
                                    <svg width={pxToRem(89)} height={pxToRem(66)} viewBox="0 0 89 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M61.754 22.3516C71.1516 23.2345 79.5179 25.1828 86.4986 29.1376C95.6748 34.3361 73.3238 36.3167 72.0982 43.937C70.7691 52.2014 93.4505 62.524 80.6813 65.3086C69.1493 67.8235 58.2634 62.8565 46.7337 60.3379C31.9826 57.1156 6.46676 57.4681 1.20746 48.4303C-4.2111 39.1187 15.738 33.6458 28.4501 27.5578C38.5531 22.7193 49.0789 21.1607 61.754 22.3516Z" fill="#F5F5F5" />
                                        <path d="M65.7001 10.8803V42.456C65.7001 43.2736 65.0375 43.9362 64.22 43.9362H32.6442C31.8267 43.9362 31.1641 43.2736 31.1641 42.456V10.8803C31.1641 10.0628 31.8267 9.40015 32.6442 9.40015H64.22C65.0375 9.40015 65.7001 10.0628 65.7001 10.8803Z" fill="white" stroke="#447D75" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M58.2958 2H25.2399C24.4224 2 23.7598 2.66267 23.7598 3.48011V36.536" stroke="#447D75" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M31.1641 33.5754L44.5946 29.135L65.7001 36.5356" stroke="#447D75" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M54.5929 24.2014C52.5493 24.2014 50.8926 22.5446 50.8926 20.5011C50.8926 18.4575 52.5493 16.8008 54.5929 16.8008C56.6364 16.8008 58.2931 18.4575 58.2931 20.5011C58.2931 22.5446 56.6364 24.2014 54.5929 24.2014Z" stroke="#447D75" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Stack>
                                {fileName ? <label className={styles.uploadMaxsizeTitle}>{fileName}</label> : null}
                                <Stack direction={"column"}>
                                    <label className={styles.uploadTitleBig}>Click to choose file or drag and drop</label>
                                    <label className={styles.uploadMaxsizeTitle}>Max file size 2 MB. JPG or PNG only.</label>
                                </Stack>
                                {errorStr ? <label className={styles.uploadError}>{errorStr}</label> : null}
                            </Stack>
                            :
                            <Stack spacing={1} className={`${styles.pictureCropBox}`}>
                                <Stack alignItems={'center'} justifyContent={'center'}>
                                    <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={pxToRem(24)}>
                                        <Tooltip title="Rotate Left" placement="left">
                                            <IconButton onClick={rotateLeft} size="small">
                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M26 26V24.6C26 21.2397 26 19.5595 25.346 18.2761C24.7708 17.1471 23.8529 16.2292 22.7239 15.654C21.4405 15 19.7603 15 16.4 15H10M10 15L15 20M10 15L15 10" stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M26 26V24.6C26 21.2397 26 19.5595 25.346 18.2761C24.7708 17.1471 23.8529 16.2292 22.7239 15.654C21.4405 15 19.7603 15 16.4 15H10M10 15L15 20M10 15L15 10" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M26 26V24.6C26 21.2397 26 19.5595 25.346 18.2761C24.7708 17.1471 23.8529 16.2292 22.7239 15.654C21.4405 15 19.7603 15 16.4 15H10M10 15L15 20M10 15L15 10" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <circle cx="18" cy="18" r="17.5" transform="matrix(-1 0 0 1 36 0)" stroke="#A3A3A3" />
                                                </svg>
                                            </IconButton>
                                        </Tooltip>
                                        <Stack className={styles.pictureCropBoxContainer}>
                                            <Cropper onCropComplete={onCropComplete} classes={{ containerClassName: styles.cropContainer }} image={imgSrc} cropShape="round" rotation={rotation} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} />
                                        </Stack>
                                        <Tooltip title="Rotate Right" placement="right">
                                            <IconButton onClick={rotateRight} size="small">
                                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 26V24.6C10 21.2397 10 19.5595 10.654 18.2761C11.2292 17.1471 12.1471 16.2292 13.2761 15.654C14.5595 15 16.2397 15 19.6 15H26M26 15L21 20M26 15L21 10" stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 26V24.6C10 21.2397 10 19.5595 10.654 18.2761C11.2292 17.1471 12.1471 16.2292 13.2761 15.654C14.5595 15 16.2397 15 19.6 15H26M26 15L21 20M26 15L21 10" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 26V24.6C10 21.2397 10 19.5595 10.654 18.2761C11.2292 17.1471 12.1471 16.2292 13.2761 15.654C14.5595 15 16.2397 15 19.6 15H26M26 15L21 20M26 15L21 10" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <circle cx="18" cy="18" r="17.5" stroke="#A3A3A3" />
                                                </svg>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Stack style={{ justifyContent: 'center', alignItems: 'center', marginTop: pxToRem(8), position: 'relative' }}>
                                        <Stack className={styles.button} direction={"row"} spacing={1} style={{ right: pxToRem(-50), position: 'absolute', top: 0 }}>
                                            {iUploadModal.fileUrl ? <IconButton onClick={iUploadModal.fileDelete} size="small" style={{padding: 0}}><svg width={pxToRem(36)} height={pxToRem(36)} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 9H21M9 12H27M25 12L24.2987 22.5193C24.1935 24.0975 24.1409 24.8867 23.8 25.485C23.4999 26.0118 23.0472 26.4353 22.5017 26.6997C21.882 27 21.0911 27 19.5093 27H16.4907C14.9089 27 14.118 27 13.4983 26.6997C12.9528 26.4353 12.5001 26.0118 12.2 25.485C11.8591 24.8867 11.8065 24.0975 11.7013 22.5193L11 12M16 16.5V21.5M20 16.5V21.5" stroke="#1F1F1F" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15 9H21M9 12H27M25 12L24.2987 22.5193C24.1935 24.0975 24.1409 24.8867 23.8 25.485C23.4999 26.0118 23.0472 26.4353 22.5017 26.6997C21.882 27 21.0911 27 19.5093 27H16.4907C14.9089 27 14.118 27 13.4983 26.6997C12.9528 26.4353 12.5001 26.0118 12.2 25.485C11.8591 24.8867 11.8065 24.0975 11.7013 22.5193L11 12M16 16.5V21.5M20 16.5V21.5" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15 9H21M9 12H27M25 12L24.2987 22.5193C24.1935 24.0975 24.1409 24.8867 23.8 25.485C23.4999 26.0118 23.0472 26.4353 22.5017 26.6997C21.882 27 21.0911 27 19.5093 27H16.4907C14.9089 27 14.118 27 13.4983 26.6997C12.9528 26.4353 12.5001 26.0118 12.2 25.485C11.8591 24.8867 11.8065 24.0975 11.7013 22.5193L11 12M16 16.5V21.5M20 16.5V21.5" stroke="black" stroke-opacity="0.2" stroke-linecap="round" stroke-linejoin="round" />
                                                <circle cx="18" cy="18" r="17.5" stroke="#A3A3A3" />
                                            </svg>
                                            </IconButton> : null}
                                        </Stack>
                                        <Stack direction={"row"} spacing={2}>
                                            <IconButton disabled={zoom <= 1} onClick={() => setZoom(zoom - 0.1)} size="small"><svg width={pxToRem(16)} height={pxToRem(2)} viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H15" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg></IconButton>
                                            <CustomSlider value={zoom} step={0.1} min={1} max={3} valueLabelDisplay="auto" valueLabelFormat={(v) => `${mapValue(v, 1, 3, 0, 100)}`} onChange={(e, v) => { if (typeof (v) === "number") setZoom(v) }} />
                                            <IconButton disabled={zoom >= 3} onClick={() => setZoom(zoom + 0.1)} size="small"><svg width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1V15M1 8H15" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg></IconButton>
                                        </Stack>
                                        <label className={styles.ZoomLabel}>Zoom</label>
                                    </Stack>
                                </Stack>
                            </Stack>
                    }
                    <Divider />
                    <Stack direction="row" justifyContent="space-between">
                        <SecondaryButton onClick={() => { reset(); iUploadModal.close(); }} className={`${styles.button} ${styles.uploadFooterButton}`}>Cancel</SecondaryButton>
                        <PrimaryButton onClick={uploadFile} loading={iUploadModal.uploadState} disabled={isUploadBtnDisabled || iUploadModal.uploadState} className={`${styles.button} ${styles.uploadFooterButton}`}>{isServerFile() ? "Save" : "Upload"}</PrimaryButton>
                    </Stack>
                </Stack>
            </CustomizedModal>
        </>
    );
}

export default UploadModal;