import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../redux";
import { Navigate } from "react-router-dom";
import { URL_PARAMS } from "./shared";
import { getAuth } from "firebase/auth";
import { memo, useEffect } from "react";
import { LoadingFallbackComponent } from "../components/FallbackComponent";

interface IRoute { children: JSX.Element; customizedFallbackRoute?: string; }
interface IBindOrgRouteWrapper { children: JSX.Element; }

export const DashboardRoute = ({ children }: IRoute) => {
    // const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    // const authDataLoaded = useSelector((state: RootState) => state.sharedStates.authDataLoaded);
    // const userDataLoaded = useSelector((state: RootState) => state.sharedStates.userDataLoaded);
    // const emailVerified = useSelector((state: RootState) => state.user.userInfos.emailVerified);
    // const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    // const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    // const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    const { uid, emailVerified, onBoarding:userOnBoarding, beta: isBetaUser, betaPasswordUpdate } = useSelector((state: RootState) => state.user.userInfos, shallowEqual); // Using shallowEqual for performance optimization
    const userDelete = useSelector((state: RootState) => state.user.userDelete, shallowEqual);
    const { authDataLoaded, userDataLoaded } = useSelector((state: RootState) => state.sharedStates, shallowEqual);
    if(userDelete) return children;
    console.log("Unified 0")
    // Check if authentication data is loaded
    if (!authDataLoaded) { return <LoadingFallbackComponent />; }
    console.log("Unified 1")
    // Redirect to login if user is not authenticated
    if (!uid) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; }
    console.log("Unified 2")
    // Check if user-specific data is loaded
    if (!userDataLoaded) { return <LoadingFallbackComponent />; }
    console.log("Unified 3")
    // Check if the user's email is verified, EMAIL VERIFICATION TURNED OFF
    // if (!emailVerified) { return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />; }
    console.log("Unified 4")
    // Beta user needs password update
    if (isBetaUser && !betaPasswordUpdate) {
        console.log("Unified 5")
        return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />;
    }
    // User needs to complete onboarding
    else if (!userOnBoarding) {
        console.log("Unified 6")
        return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />;
    }
    console.log("Unified 7")
    // Render children for other routes (like Dashboard)
    return children;
};


export function RestrictedRoute({ children, customizedFallbackRoute }: IRoute) {
    const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    const userLoginStatusLoaded = useSelector( (state: RootState) => state.sharedStates.authDataLoaded );
    const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    console.log("===> Login 111")
    if (userLoginStatusLoaded) {
        console.log("===> Login 222")
        if (!uid) { return children; } 
        else {
            console.log("===> Login 333")
            if(isBetaUser && !betaPasswordUpdate){
                console.log("===> Login 444")
                return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />
            }else{
                console.log("===> Login 555")
                if(!userOnBoarding){ return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />; }
                return <Navigate to={`/${URL_PARAMS.DASHBOARD}`} replace />;
            }
        }
    }
    console.log("===> Login 777")
    //return <div className='blankPage'></div>;
    return <LoadingFallbackComponent />;
}

const PublicRouteComponent = ({ children }: IRoute) => {
    // const { uid, emailVerified } = useSelector((state: RootState) => state.user.userInfos, shallowEqual); // Using shallowEqual for performance optimization
    // const { authDataLoaded, userDataLoaded } = useSelector((state: RootState) => state.sharedStates, shallowEqual);
    const token = Math.floor(Math.random() * 100);
    const uid = useSelector((state: RootState) => {
        //console.log(token,"uid render: ");
        return state.user.userInfos.uid
    },shallowEqual);
    const emailVerified = useSelector((state: RootState) => {
        //console.log(token,"emailVerified render: ");
        return state.user.userInfos.emailVerified
    },shallowEqual);
    const authDataLoaded = useSelector((state: RootState) => {
        //console.log(token,"authDataLoaded render: ");
        return state.sharedStates.authDataLoaded
    },shallowEqual);
    const userDataLoaded = useSelector((state: RootState) => {
        //console.log(token,"userDataLoaded render: ");
        return state.sharedStates.userDataLoaded
    },shallowEqual);
    const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    
    console.log(token,"PublicRoute render: ", { uid, emailVerified, authDataLoaded, userDataLoaded });
    console.log(token, "Public route 1")
    // Show loading indicator until authentication data is fully loaded
    if (!authDataLoaded) { return <LoadingFallbackComponent />; }
    console.log(token, "Public route 2")

    if(uid && authDataLoaded && !userOnBoarding) {
        console.log(token, "Public route 2.1"); // - Google authenticated (need onboarding)
        return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />; 
    }

    if (uid && authDataLoaded && userDataLoaded) {
        // Redirect to email verification if email is not verified, EMAIL VERIFICATION TURNED OFF
        // if (!emailVerified) {console.log(token, "Public route 4"); return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />; }
        // Wait for user data to load
        console.log(token, "Public route 3")
        if (!userDataLoaded) {console.log(token, "Public route 3.1"); return <LoadingFallbackComponent />; }
   
        // Redirect authenticated and verified users to a specific route
        console.log(token, "Public route 5")
        return <Navigate to={`/${URL_PARAMS.DASHBOARD}`} replace />;
    }
    console.log(token, "Public route 6")
    // Render the public route (like login page) for unauthenticated users
    return children;
};
export const PublicRoute = memo(PublicRouteComponent)

export const BetaPasswordRoute = ({ children }: IRoute) => {
    const token = Math.floor(Math.random() * 100);
    const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    const authDataLoaded = useSelector((state: RootState) => state.sharedStates.authDataLoaded);
    const userDataLoaded = useSelector((state: RootState) => state.sharedStates.userDataLoaded);
    const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    const emailVerified = useSelector((state: RootState) => state.user.userInfos.emailVerified);
    console.log(token, "BetaPasswordRoute 1", uid,authDataLoaded,userDataLoaded,isBetaUser,"betaPasswordUpdate", betaPasswordUpdate, emailVerified)
    // Check if data is loaded
    // if (!authDataLoaded || !userDataLoaded) {
    //     if (!uid && authDataLoaded && !userDataLoaded) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; }
    //     return <LoadingFallbackComponent />;
    // }
    if (!authDataLoaded || (authDataLoaded && !userDataLoaded && uid)) {
        return <LoadingFallbackComponent />;
    }
    console.log(token, "BetaPasswordRoute 2")
    // Redirect to login if user is not authenticated
    if (!uid) {
        console.log(token, "BetaPasswordRoute 3")
        return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />;
    }
    // EMAIL VERIFICATION TURNED OFF
    // if (!emailVerified) {
    //     console.log(token, "BetaPasswordRoute 3.1")
    //     return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />;
    // }
    console.log(token, "BetaPasswordRoute 4")
    // Redirect beta users who haven't updated their password to the onboarding page
    if (isBetaUser && betaPasswordUpdate) {
        console.log(token, "BetaPasswordRoute 5")
        return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />;
    }
    console.log(token, "BetaPasswordRoute 6")
    // Render children for other routes
    return children;






    
    // const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    // const userLoginStatusLoaded = useSelector( (state: RootState) => state.sharedStates.authDataLoaded );
    // const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    // const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    // const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    // console.log("------BetaPAsswordWrapper", uid, isBetaUser, betaPasswordUpdate)
    // console.log("SEQ 1")
    // if (userLoginStatusLoaded) {
    //     console.log("SEQ 2")
    //     if (!uid) {return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; } 
    //     else {
    //         console.log("SEQ 3")
    //         if (isBetaUser && betaPasswordUpdate) {
    //             console.log("SEQ 4")
    //             if(userOnBoarding){
    //                 console.log("SEQ 5")
    //                 return <Navigate to={`/${URL_PARAMS.DASHBOARD}`} replace />;
    //             }else{
    //                 console.log("SEQ 6")
    //                 return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />;
    //             }
    //         }else{
    //             console.log("SEQ 7")
    //             return children;
    //         }
    //     }
    // }
    // console.log("SEQ 8")
    // return <LoadingFallbackComponent />;
    // return <div className='blankPage'></div>;
};

export const OnboardingRoute = ({ children }: IRoute) => {
    const { uid, emailVerified, onBoarding:userOnBoarding, beta: isBetaUser, betaPasswordUpdate } = useSelector((state: RootState) => state.user.userInfos, shallowEqual); // Using shallowEqual for performance optimization
    const { authDataLoaded, userDataLoaded, isOnBoardingFlow } = useSelector((state: RootState) => state.sharedStates, shallowEqual);
    // const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    // const emailVerified = useSelector((state: RootState) => state.user.userInfos.emailVerified);
    // const authDataLoaded = useSelector((state: RootState) => state.sharedStates.authDataLoaded);
    // const userDataLoaded = useSelector((state: RootState) => state.sharedStates.userDataLoaded);
    // const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    // const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    // const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    // const isOnBoardingFlow = useSelector((state: RootState) => state.sharedStates.isOnBoardingFlow);
    if (!authDataLoaded || !userDataLoaded) { return <LoadingFallbackComponent />; }
    if (!uid) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; }
    // EMAIL VERIFICATION TURNED OFF
    // if (!emailVerified) { return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />; }
    if (isBetaUser && !betaPasswordUpdate) { return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />; }
    if (userOnBoarding) { 
        if(!isOnBoardingFlow){
            return <Navigate to={`/${URL_PARAMS.DASHBOARD}`} replace />; 
        }
    }
    return children;
};

export function PrivateRoute({ children, customizedFallbackRoute }: IRoute) {
    console.log("------>>PrivateRoute 1")
    const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    const emailVerified = useSelector( (state: RootState) => state.user.userInfos.emailVerified );
    const userLoginStatusLoaded = useSelector( (state: RootState) => state.sharedStates.authDataLoaded );
    const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    console.log("------>>PrivateRoute 1111", uid, emailVerified, userLoginStatusLoaded, userOnBoarding)
    if (userLoginStatusLoaded) {
        console.log("------>>PrivateRoute 2")
        if (uid) {
            console.log("------>>PrivateRoute 3")
            // EMAIL VERIFICATION TURNED OFF
            // if (!emailVerified) {
            //     console.log("------>>PrivateRoute 4")
            //     return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />;
            // }
            if(!userOnBoarding){ 
                console.log("------>>PrivateRoute 5")
                return <Navigate to={`/${URL_PARAMS.ONBOARDING}`} replace />;
            }
            return children;
        } 
        else {
            console.log("------>>PrivateRoute 6")
            return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />;
        }
    }
    return <LoadingFallbackComponent />;
    // return <div className='blankPage'></div>;
}
// export function VerifiedEmailProcterRoute({ children }: IRoute) {
//     const uid = useSelector((state: RootState) => state.user.userInfos.uid);
//     const emailVerified = useSelector( (state: RootState) => state.user.userInfos.emailVerified );
//     const userLoginStatusLoaded = useSelector( (state: RootState) => state.sharedStates.authDataLoaded );
//     const checkEmailVerify = () => {
//     const auth = getAuth();

//     if (auth.currentUser !== null) {
//         auth.currentUser.reload();
//         if (auth.currentUser.emailVerified) {
//         window.open(`/${URL_PARAMS.ONBOARDING}`, "_self");
//         } else {
//         setTimeout(checkEmailVerify, 5000);
//         }
//     } else {
//         setTimeout(checkEmailVerify, 5000);
//     }
//     };

//     useEffect(() => { checkEmailVerify(); }, []);
//     if (userLoginStatusLoaded) {
//         if (!uid) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; } 
//         else { if (emailVerified) { return <Navigate to={`/`} replace />; } return children; }
//     }
//     return <LoadingFallbackComponent />;
//     //return <div className='page--absolute'></div>;
// }

export function VerifiedEmailRoute({ children }: IRoute) {
    const { uid, emailVerified } = useSelector((state: RootState) => state.user.userInfos, shallowEqual);
    const { authDataLoaded } = useSelector((state: RootState) => state.sharedStates, shallowEqual);
    const checkEmailVerify = () => {
        const auth = getAuth();
        if (auth.currentUser !== null) {
            auth.currentUser.reload();
            if (auth.currentUser.emailVerified) { window.open(`/${URL_PARAMS.ONBOARDING}`, "_self"); } 
            else { setTimeout(checkEmailVerify, 5000); }
        } else { setTimeout(checkEmailVerify, 5000); }
    };
    useEffect(() => { checkEmailVerify(); }, []);
    if (!authDataLoaded) { return <LoadingFallbackComponent />; }
    if (!uid) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; }
    if (emailVerified) { return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />; }
    return children;
}

export function BindOrgUncompletedUsersOnlyRoute({ children, }: IBindOrgRouteWrapper) {
    const uid = useSelector((state: RootState) => state.user.userInfos.uid);
    const emailVerified = useSelector( (state: RootState) => state.user.userInfos.emailVerified );
    const userLoginStatusLoaded = useSelector( (state: RootState) => state.sharedStates.authDataLoaded );
    const userOnBoarding = useSelector((state: RootState) => state.user.userInfos.onBoarding);
    const isBetaUser = useSelector((state: RootState) => state.user.userInfos.beta);
    const betaPasswordUpdate = useSelector((state: RootState) => state.user.userInfos.betaPasswordUpdate);
    const entireState = useSelector((state: RootState) => state.user.userInfos);
    console.log("ONBOARIDNG 0", userLoginStatusLoaded)
    if (userLoginStatusLoaded) {
        console.log("ONBOARIDNG 1",uid)
        if (!uid) { return <Navigate to={`/${URL_PARAMS.LOGIN}`} replace />; }
        // EMAIL VERIFICATION TURNED OFF
        // console.log("ONBOARIDNG 2",emailVerified)
        // if (!emailVerified) { return <Navigate to={`/${URL_PARAMS.VERIFY_EMAIL}`} replace />; }
        console.log("ONBOARIDNG 3",isBetaUser)
        if(isBetaUser){
            
            console.log("ONBOARIDNG 4", entireState)
            console.log("ONBOARIDNG 4.1", betaPasswordUpdate)
            if(!betaPasswordUpdate){
                console.log("ONBOARIDNG 5",betaPasswordUpdate)
                return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />;
            }else{
                console.log("ONBOARIDNG 6")
                if (userOnBoarding){ return <Navigate to={`/${URL_PARAMS.DASHBOARD}`} replace />; }
            }
        }else{
            console.log("ONBOARIDNG 7")
            if (userOnBoarding){ return <Navigate to={`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`} replace />; }
        }
        console.log("ONBOARIDNG 8")
        return children;
    }
    console.log("ONBOARIDNG 9")
    return <LoadingFallbackComponent />;
    // return <div className='page--absolute'></div>;
}
export function DashboardRestriction({children}: IRoute){
    const isDashboard = process.env.REACT_APP_ISDASHBOARD;
    if(isDashboard && isDashboard === "false")return <Navigate to={`/${URL_PARAMS.ERROR404}`} />;
    return children;
}