import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import pxToRem from "../../../helpers/pxToRem";
import styles from "./GoalCount.module.scss";

interface IGoalCount {
    completed: number;
    total: number;
}

const GoalCount = ({completed, total}: IGoalCount) => {

    const parentRef = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
            setParentWidth(parentRef.current.offsetHeight);
            }
        };

        // Set initial width
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
        <Stack ref={parentRef} className={`${styles.mainContainer}`}>
            <CircularProgress variant="determinate" className={styles.progressBar} style={{width: parentWidth, height: parentWidth}} value={completed/total * 100} //size={'11rem'}
                sx={{
                    '&.MuiCircularProgress-root':{
                        color: styles.secondaryBlue50,
                        backgroundColor: completed===total ? '#8E60F2': completed !== 0? '#426099' : '#f9f9fa',
                        borderRadius: '8px',
                        padding: '8px'
                    }
                }}
            />
            <Stack>
                {
                    completed === total ?
                        <span className={`${styles['text--completed']}`}>
                            Weekly Goal Completed!
                        </span>
                    :
                        <>
                            <span className={`${styles['text--progress']} ${styles.textColor} ${completed === 0 ? styles['textColor--zero']:''}`}>
                                {completed}/{total}
                            </span>
                            <p className={`${styles.textColor} ${completed === 0 ? styles['textColor--zero']:''}`}>tasks completed</p>
                        </>
                }
            </Stack>
        </Stack>
        </>
    );
};

export default GoalCount;