import { addDoc, collection, doc, getDoc, getDocs, query, runTransaction, serverTimestamp, updateDoc, where, writeBatch } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { db } from ".";
import { Res } from "./shared";
import { Comment, getTask, NewComment, NewTask, Task } from "./types-organisationPlanners";


export const createNewTask = async (orgId: string, task: NewTask, userId: string): Promise<Res<string>> => {
  try {
      await runTransaction(db, async (transaction) => {
          const collRef = collection(db, "organisations", orgId, "organisationPlanner");
          const newPlannerRef = await addDoc(collRef, task);

          const docOrgPlanner = doc(db, "organisations", orgId);
          const docOrgPlannerData = await transaction.get(docOrgPlanner);
          let totalOrgPlanner = 0;
          if (docOrgPlannerData.exists() && docOrgPlannerData.data()) {
              totalOrgPlanner = (docOrgPlannerData.data().totalOrgPlanner ?? 0) + 1;
          }

          // Update the organisation document with the latest update info
          transaction.set(docOrgPlanner, { 
              totalOrgPlanner: totalOrgPlanner, 
              plannerUpdateAt: serverTimestamp(), // Record the update time
              plannerUpdateBy: userId // Record the user who made the update
          }, { merge: true });
      });
      return { code: 200, data: "New planner created." };
  } catch (error) {
      console.log(error);
      return { code: 500 };
  }
};


export const editSingleTask = async (orgId: string, taskId: string, updatedTask: Task, userId: string): Promise<Res<string>> => {
  try {
      await runTransaction(db, async (transaction) => {
          const taskDocRef = doc(db, "organisations", orgId, "organisationPlanner", taskId);

          // Update the task with the new data
          transaction.update(taskDocRef, updatedTask);

          // Update the organisation document with the latest update info
          const docOrgPlanner = doc(db, "organisations", orgId);
          transaction.set(docOrgPlanner, { 
            plannerUpdateAt: serverTimestamp(), // Record the update time
            plannerUpdateBy: userId // Record the user who made the update
          }, { merge: true });
      });

      return { code: 200, data: "Task successfully updated." };
  } catch (error) {
      console.error("Error updating task:", error);
      return { code: 500 };
  }
};

export const updateTaskDate = async (orgId: string, taskId: string, newDate: number, userId: string): Promise<Res<string>> => {
  try {
      await runTransaction(db, async (transaction) => {
          const taskDocRef = doc(db, "organisations", orgId, "organisationPlanner", taskId);

          // Update only the date field in the task document
          transaction.update(taskDocRef, { date: newDate });

          // Update the organisation document with the latest update info
          const docOrgPlanner = doc(db, "organisations", orgId);
          transaction.set(docOrgPlanner, {
              plannerUpdatedAt: serverTimestamp(), // Record the update time
              plannerUpdatedBy: userId // Record the user who made the update
          }, { merge: true });
      });

      return { code: 200, data: "Task date successfully updated." };
  } catch (error) {
      console.error("Error updating task date:", error);
      return { code: 500 };
  }
};


export const getSingleTask = async (orgId: string, taskId: string): Promise<Res<Task | string>> => {
    try {
        const taskDocRef = doc(db, "organisations", orgId, "organisationPlanner", taskId);
        const taskDoc = await getDoc(taskDocRef);

        if (taskDoc.exists()) {
            const taskData = getTask(taskDoc.data());
            return { code: 200, data: taskData };
        } else {
            return { code: 500, errorMsg: "Task not found" };
        }
    } catch (error) {
        console.error("Error fetching task:", error);
        return { code: 500, errorMsg: "Failed to fetch task" };
    }
};

export const getAlumniTasks = async (orgId: string, alumniId: string): Promise<Res<Task[] | string>> => {
  try {
      const tasksColRef = collection(db, "organisations", orgId, "organisationPlanner");
      const q = query(tasksColRef, where("alumniList", "array-contains", alumniId));

      const querySnapshot = await getDocs(q);
      const tasks: Task[] = [];

      querySnapshot.forEach((doc) => {
          const taskData = getTask(doc.data());

          // Check if the task is not marked as deleted
          if (!taskData.isDeleted && !taskData.isDone) {
              tasks.push({ ...taskData, id: doc.id });
          }
      });

      return  { code: 200, data: tasks };
  } catch (error) {
      console.error("Error fetching tasks for alumni:", error);
      return { code: 500, errorMsg: "Failed to fetch tasks for alumni" };
  }
};


// Function to add a new comment to the array in Firestore
export const createNewComment = async (
  orgId: string,
  plannerId: string,
  newComment: NewComment
): Promise<Res<string>> => {
  try {
    const commentId = uuidv4(); // Generate a new UUID
    const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);

    await runTransaction(db, async (transaction) => {
      const plannerDoc = await transaction.get(plannerRef);
      if (!plannerDoc.exists()) {
        throw new Error("Planner document does not exist!");
      }

      const plannerData = plannerDoc.data();
      const commentToAdd = {
        ...newComment,
        Id: commentId, // Add the generated UUID to the comment
        created_at: Date.now(),
        updated_at: Date.now(),
      };

      const comments = plannerData.comments ? [...plannerData.comments, commentToAdd] : [commentToAdd];
      transaction.update(plannerRef, { comments });
    });

    return { code: 200, data: "New comment added successfully." };
  } catch (error) {
    console.error("Error adding new comment: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};

  
  
  // Function to get all comments from the array in Firestore
  export const getAllComments = async (
    orgId: string,
    plannerId: string
  ): Promise<Res<Comment[]>> => {
    try {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      const docSnap = await getDoc(plannerRef);
      if (docSnap.exists()) {
        const plannerData = docSnap.data();
        return { code: 200, data: plannerData.comments || [] };
      } else {
        return { code: 500, errorMsg: "Planner not found" };
      }
    } catch (error) {
      console.error("Error getting comments: ", error);
      return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
    }
  };
  
  export const editComment = async (
    orgId: string,
    plannerId: string,
    commentId: string,
    updatedContent: string
  ): Promise<Res<string>> => {
    try {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      const plannerDoc = await getDoc(plannerRef);
  
      if (!plannerDoc.exists()) {
        throw new Error("Planner document does not exist!");
      }
  
      const plannerData = plannerDoc.data();
      const updatedComments = plannerData.comments.map((comment: Comment) =>
        comment.Id === commentId ? { ...comment, content: updatedContent, updated_at: Date.now() } : comment
      );
  
      await updateDoc(plannerRef, { comments: updatedComments });
      return { code: 200, data: "Comment edited successfully." };
    } catch (error) {
      console.error("Error editing comment: ", error);
      return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
    }
  };
  
  
  export const deleteComment = async (
    orgId: string,
    plannerId: string,
    commentId: string
  ): Promise<Res<string>> => {
    try {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      const plannerDoc = await getDoc(plannerRef);
  
      if (!plannerDoc.exists()) {
        throw new Error("Planner document does not exist!");
      }
  
      const plannerData = plannerDoc.data();
      const filteredComments = plannerData.comments.filter((comment: Comment) => comment.Id !== commentId);
  
      await updateDoc(plannerRef, { comments: filteredComments });
      return { code: 200, data: "Comment deleted successfully." };
    } catch (error) {
      console.error("Error deleting comment: ", error);
      return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
    }
  };
  
  // Function to mark a single planner as done
  export const markAsDone = async (orgId: string, plannerId: string, userId: string): Promise<Res<string>> => {
    const batch = writeBatch(db);
  
    try {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      const orgRef = doc(db, "organisations", orgId);
  
      // Set planner as done
      batch.update(plannerRef, { isDone: true });
  
      // Update the organisation document
      batch.set(orgRef, {
        plannerUpdateAt: serverTimestamp(), // Record the update time
        plannerUpdateBy: userId // Record the user who made the update
      }, { merge: true });
  
      // Commit the batch
      await batch.commit();
  
      return { code: 200, data: "Planner marked as done and organisation updated successfully." };
    } catch (error) {
      console.error("Error in markAsDone transaction: ", error);
      return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
    }
  };

// Function to mark a single planner as deleted
export const markAsDelete = async (orgId: string, plannerId: string, userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);
  
  try {
    const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
    const orgRef = doc(db, "organisations", orgId);
    
    // Mark planner as deleted
    batch.update(plannerRef, { isDeleted: true });
    
    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(), // Record the update time
      plannerUpdateBy: userId // Record the user who made the update
    }, { merge: true });
    
    // Commit the batch
    await batch.commit();
    
    return { code: 200, data: "Planner marked as deleted successfully." };
  } catch (error) {
    console.error("Error marking planner as deleted: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};


// Function to mark multiple planners as done
export const markAllDone = async (orgId: string, plannerIds: string[], userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);

  try {
    const orgRef = doc(db, "organisations", orgId);
    plannerIds.forEach(plannerId => {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      batch.update(plannerRef, { isDone: true });
    });

    // Update the organisation document for batch operation
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(),
      plannerUpdateBy: userId
    }, { merge: true });

    await batch.commit();
    return { code: 200, data: "All selected planners marked as done successfully." };
  } catch (error) {
    console.error("Error marking all planners as done: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};

// Function to mark multiple planners as deleted
export const markAllDelete = async (orgId: string, plannerIds: string[], userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);

  try {
    const orgRef = doc(db, "organisations", orgId);
    plannerIds.forEach(plannerId => {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      batch.update(plannerRef, { isDeleted: true });
    });

    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(),
      plannerUpdateBy: userId
    }, { merge: true });

    await batch.commit();
    return { code: 200, data: "All selected planners marked as deleted successfully." };
  } catch (error) {
    console.error("Error marking all planners as deleted: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};


// Function to mark a single planner as not deleted (restore)
export const markAsRestore = async (orgId: string, plannerId: string, userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);
  
  try {
    const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
    const orgRef = doc(db, "organisations", orgId);
    
    // Mark planner as not deleted
    batch.update(plannerRef, { isDeleted: false });
    
    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(),
      plannerUpdateBy: userId
    }, { merge: true });
    
    // Commit the batch
    await batch.commit();
    
    return { code: 200, data: "Planner restored successfully." };
  } catch (error) {
    console.error("Error restoring planner: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};


// Function to mark multiple planners as not deleted (restore)
export const markAllRestore = async (orgId: string, plannerIds: string[], userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);

  try {
    const orgRef = doc(db, "organisations", orgId);
    plannerIds.forEach(plannerId => {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      batch.update(plannerRef, { isDeleted: false });
    });

    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(),
      plannerUpdateBy: userId
    }, { merge: true });

    await batch.commit();
    return { code: 200, data: "All selected planners restored successfully." };
  } catch (error) {
    console.error("Error restoring all planners: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};

// Function to delete multiple planners from database
export const deleteAllPlanners = async (orgId: string, plannerIds: string[], userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);

  try {
    const orgRef = doc(db, "organisations", orgId);

    plannerIds.forEach(plannerId => {
      const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
      batch.delete(plannerRef);
    });

    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(), // Record the update time
      plannerUpdateBy: userId // Record the user who made the update
    }, { merge: true });

    await batch.commit();
    return { code: 200, data: "All selected planners deleted successfully." };
  } catch (error) {
    console.error("Error deleting all planners: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};

// Function to delete single planner from database
export const deleteSinglePlanner = async (orgId: string, plannerId: string, userId: string): Promise<Res<string>> => {
  const batch = writeBatch(db);

  try {
    const plannerRef = doc(db, "organisations", orgId, "organisationPlanner", plannerId);
    const orgRef = doc(db, "organisations", orgId);

    // Delete the planner
    batch.delete(plannerRef);

    // Update the organisation document
    batch.set(orgRef, {
      plannerUpdateAt: serverTimestamp(), // Record the update time
      plannerUpdateBy: userId // Record the user who made the update
    }, { merge: true });

    await batch.commit();
    return { code: 200, data: "Planner deleted successfully." };
  } catch (error) {
    console.error("Error deleting planner: ", error);
    return { code: 500, errorMsg: error instanceof Error ? error.message : "An error occurred" };
  }
};



  

