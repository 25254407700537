import { FormControl, FormGroup, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { RootState } from "../../../redux";
import { setCurrentIdentity, setCurrentStep } from "../../../redux/registrationSlice";
import { GENDER, PRONOUNS, REG_PAGES, TITLE } from "../constants";
import styles from "../index.module.scss";
import { QRFormsFormsControl } from "..";

interface IdentityProps {

}


const Identity = (props: IdentityProps) => {
    const dispatch = useDispatch();

    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const pronouns = useSelector((state:RootState) => state.alumniReg.identity.pronouns);
    const gender = useSelector((state:RootState) => state.alumniReg.identity.gender);
    const title = useSelector((state:RootState) => state.alumniReg.identity.title);

    const [selectedPronouns, setSelectedPronouns] = useState<string|null>(pronouns || null);
    const [selectedGender, setSelectedGender] = useState<string|null>(gender || null);
    const [selectedTitle, setSelectedTitle] = useState<string|null>(title || null);


    const handleData = () => {
        var isChanged = false;
        if(!selectedPronouns || selectedPronouns === "Select") {
            setSelectedPronouns(null);
        } else {
            isChanged = true;
        }
        if(!selectedGender || selectedGender === "Select") {
            setSelectedGender(null);
        } else {
            isChanged = true;
        }
        if(!selectedTitle || selectedTitle === "Select") {
            setSelectedTitle(null);
        } else {
            isChanged = true;
        }
        if(isChanged) {
            dispatch(setCurrentIdentity({pronouns:selectedPronouns, gender:selectedGender, title:selectedTitle}));
        }
        handleNext();
    }

    const handleNext = () => {
        // Go back to tell us more page
        dispatch(setCurrentStep(REG_PAGES.TELL_US_MORE));
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleData();
    }

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(()=>{
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Stack className={styles.formContainer} spacing={3} direction="column"> 
                <form id="identity-form" onSubmit={handleFormSubmit} style={{width: "auto"}}>
                    <FormGroup>
                        <QRFormsFormsControl variant="standard">
                            <InputLabel id="standard-label">Pronouns</InputLabel>
                            <Select
                                labelId="standard-label"
                                id="demo-simple-select-standard"
                                value = {selectedPronouns}
                                onChange={(e)=> setSelectedPronouns(e.target.value)}
                                label="Pronouns"
                                >
                                {PRONOUNS.map( item =>
                                    <MenuItem value={item}>
                                        {item}
                                    </MenuItem>
                                )}
                            </Select>
                        </QRFormsFormsControl>
                        <QRFormsFormsControl variant="standard">
                            <InputLabel id="standard-label">Gender</InputLabel>
                            <Select
                                labelId="standard-label"
                                id="demo-simple-select-standard"
                                value = {selectedGender}
                                onChange={(e)=> setSelectedGender(e.target.value)}
                                label="Gender"
                            >
                                {GENDER.map( item =>
                                    <MenuItem value={item}>
                                        {item}
                                    </MenuItem>
                                )}
                            </Select>
                        </QRFormsFormsControl>
                        <QRFormsFormsControl variant="standard">
                            <InputLabel id="standard-label">Title</InputLabel>
                            <Select
                                labelId="standard-label"
                                id="demo-simple-select-standard"
                                value = {selectedTitle}
                                onChange={(e)=> setSelectedTitle(e.target.value)}
                                label="Title"
                            >
                                {TITLE.map( item =>
                                    <MenuItem value={item}>
                                        {item}
                                    </MenuItem>
                                )}
                            </Select>
                        </QRFormsFormsControl>
                    </FormGroup>
                </form>
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} form="identity-form" type="submit">Next</PrimaryQRFormButton>
        </>
    );
}

export default Identity;