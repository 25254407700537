export type FAQ = {
    id: number;
    summary: string;
    details: string;
}

export const faqs:FAQ[] = [
    {id: 1, summary: "Does Loop offer a free trial?", details: "Yes, Loop provides a 14-day free trial for new users."},
    {id: 2, summary: "How does pricing work for Loop?", details: "Our pricing is determined by your subscription payment preference (monthly or annually) and the number of users in your organisation. There is no limit on the number of alumni profiles created."},
    {id: 3, summary: "Who is eligible for the discount for the annual plan?", details: "Everyone, regardless of your organisation’s chosen number of users, can enjoy up to a 16.6% discount when opting for the annual plan."},
    {id: 4, summary: "Can I switch to a monthly plan?", details: "Yes, you can switch to a monthly plan once your annual plan expires. Your next payment will be charged on a monthly basis."},
    {id: 5, summary: "Can I switch to an annual plan?", details: "Yes, you can switch to an annual plan once your monthly plan expires. Your next payment will be charged annually."},
    {id: 6, summary: "What happens if I add more users?", details: "If your organisation exceeds the user limit, you will be invoiced the pro-rata rate for the remaining billing cycle."},
    {id: 7, summary: "What happens if I reduce the number of users?", details: "If you're on a monthly plan, reducing the number of users will lower your fee starting from the next billing cycle. For an annual plan, reducing the number of users will result in a credit for the remaining duration of your paid plan."},
    {id: 8, summary: "What payment methods do you accept?", details: "We accept all major credit cards and PayPal. You can easily manage your payment details under “Billing” in your account at any time."},
    {id: 9, summary: "How do I cancel my paid plan?", details: "Your Loop plan, whether monthly or annual, will automatically renew until you choose to cancel. You can cancel your plan by accessing the “Billing” section in the platform."},
    {id: 10, summary: "Can I export my alumni data from Loop if needed?", details: "If you have an active account, you can export your data from Loop at any time."},
]