import { useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
interface IuseTextInput {
  inputValidator: (value: string) => string | undefined;
  defaultValue?: string;
  cancelHasTouchedAutoUpdate?: boolean;
}
const useTextInput = ({ inputValidator, defaultValue, cancelHasTouchedAutoUpdate, }: IuseTextInput) => {
    const [value, setValue] = useState(defaultValue ?? "");
    const [hasTouched, setHasTouched] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const firstTimeRender = useRef(true);
    const debouncedSetHasTouched = useDebouncedCallback(() => { setHasTouched(true); }, 1000);
    const hasError = useMemo(() => { if (errorMessage) { return true; } return false; }, [errorMessage != undefined]);
    const getErrorMessage = () => (hasTouched ? errorMessage : "");
    useEffect(() => {
        if (firstTimeRender.current) { firstTimeRender.current = false; } 
        else { if (!cancelHasTouchedAutoUpdate) { if (!hasTouched && value !== "") { debouncedSetHasTouched(); } } }
        setErrorMessage(inputValidator(value));
    }, [value]);
    const reset = () => { debouncedSetHasTouched.cancel(); setValue(""); setHasTouched(false); setErrorMessage(undefined); };
    return { value, setValue, hasTouched, getErrorMessage, hasError, setHasTouched, setErrorMessage, reset };
};
export type UseTextInput = ReturnType<typeof useTextInput>;
export default useTextInput;
