import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { ColumnDef, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Permission, PermissionFeature, PermissionState } from "../../../../firebase/types-organisations";
import pxToRem from "../../../../helpers/pxToRem";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { updateAllUserPermissions } from "../../../../firebase/userApis";
import { PrimaryButton } from "../../../../components/Buttons/ButtonUI";
import { getTimeDiffDes2DatesMillsNow } from "../../../../helpers/timeUtils";
import { PermissionTableData, rowsToPermission } from "../../helpers";

interface IPermissionTable {
    rows: PermissionTableData[];
}

export const PermissionsInsideTable = (feature: PermissionFeature) => {

    const permissionColumn = [
        {
            feature: PermissionFeature.ALUMNI,
            name: 'Alumni Database',
            description: 'Can access your organisation’s alumni and entered personal information',
        },
        {
            feature: PermissionFeature.BILLING,
            name: 'Billing & Payment',
            description: 'Can access invoices and update payment details',
        },
        {
            feature: PermissionFeature.PLANNER,
            name: "Organisation's Planner",
            description: "Can access all tasks created in your organisation's planner",
        },
        {
            feature: PermissionFeature.FORMS,
            name: "QR Forms",
            description: "Can access all forms created with Loop's QR Form generator",
        },
        {
            feature: PermissionFeature.ENGAGEMENT,
            name: "Engagement",
            description: "Can access all events in your organisation's engagement",
        },
        {
            feature: PermissionFeature.NETWORK,
            name: "Network",
            description: "Can access all data in your organisation's network",
        },
        {
            feature: PermissionFeature.PHILANTHROPY,
            name: "Philanthropy",
            description: "Can access all data in your organisation's philanthropy",
        },

    ]
    // Find the permission data for the given feature
    const permissionData = permissionColumn.find(p => p.feature === feature);

    // If no data is found, return null or some default UI
    if (!permissionData) {
        return null;
    }

    // Render the permission data
    return (
        <Stack direction="row" alignItems="center" width="100%">
            <Stack direction="column" spacing={1} sx={{ pt: pxToRem(5), pb: pxToRem(8), pr: pxToRem(12) }}>
                <Typography variant="body2" sx={{ color: styles.shades100 }}>
                    {permissionData.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#A3A3A3", fontSize: pxToRem(12) }}>
                    {permissionData.description}
                </Typography>
            </Stack>
        </Stack>
    );
};

const PermissionTable = ({ rows }: IPermissionTable) => {

    const params = useParams();
    const navigate = useNavigate();
    const { orgId, userId } = params;
    const [permissionRows, setPermissionRows] = useState<PermissionTableData[]>(
        rows.map(permission => ({
          feature: permission.feature,
          // If the state is undefined, set it to EDITOR, otherwise use the current state
          state: permission.state ?? PermissionState.EDITOR,
        }))
      );
      
    const [hasChanges, setHasChanges] = useState(false);
    const [saveTime, setSaveTime] = useState<number| undefined>(undefined);

    const handleChangeRequest = (feature: PermissionFeature, state: PermissionState) => {
        setPermissionRows(prevRows => {
            const newRows = prevRows.map(row => {
                if (row.feature === feature) {
                    return { ...row, state: state };
                }
                return row;
            });
            const changesDetected = !rows.every((row, index) => row.state === newRows[index].state);
            setHasChanges(changesDetected);
            return newRows;
        });
    };


    const handleSaveChanges = async () => {
        const result = await updateAllUserPermissions(userId as string, orgId as string, rowsToPermission(permissionRows));
        if (result.code === 200) {
            setHasChanges(false);
            setSaveTime(Date.now());
        } else {
            console.error(result.errorMsg);
        }
    };

    const columnHelper = createColumnHelper<PermissionTableData>();
    const columns: ColumnDef<any, any>[] = useMemo(() => [
        columnHelper.accessor('feature', {
            cell: info => <p style={{ color: styles.shades100 }}>{info.getValue()}</p>,
            header: `Permissions`,
        }),
        columnHelper.accessor('state', {
            cell: info => <p style={{ color: styles.shades100 }}>{info.getValue()}</p>,
            header: "",
        })

    ], []);

    const table = useReactTable({
        data: permissionRows,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div style={{ position: 'relative' }}>
            <Stack spacing={4} className={styles.tableWrapper}>
                <table className={`${styles.table} ${styles.formsTable}`}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header =>
                                    <th key={header.id} style={{ width: header.getSize(), textAlign: 'left' }} >
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', cursor: 'pointer' }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                        </Stack>
                                    </th>
                                )}
                            </tr>
                        ))}
                    </thead>
                    <tbody>

                        {
                            table.getRowModel().rows.length > 0 ?
                                table.getRowModel().rows.map(row => (
                                    <tr key={row.id} >
                                        {row.getVisibleCells().map(cell => {
                                            let cellJSX: JSX.Element | undefined = undefined;
                                            if (cell.column.id === "feature") {
                                                cellJSX = <Stack key={cell.id}>{PermissionsInsideTable(row.original.feature ?? "")}</Stack>;
                                            } else if (cell.column.id === "state") {
                                                cellJSX = (
                                                    <Stack key={cell.id} onClick={(e) => e.stopPropagation()} >

                                                        <FormControl variant="standard" sx={{ m: 1, border: 'none' }}>
                                                            <Select
                                                                labelId={`userType-select-label-${cell.id}`}
                                                                id={`userType-select-${cell.id}`}
                                                                value={row.original.state}
                                                                onChange={(event) => handleChangeRequest(row.original.feature, event.target.value as PermissionState)}
                                                                displayEmpty
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                sx={{
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    boxShadow: 'none',
                                                                    width: pxToRem(124),
                                                                    background: '#F5F5F5',
                                                                    borderRadius: pxToRem(8),
                                                                    // Adjust margin and font size here
                                                                    ".MuiSelect-select": {
                                                                        fontSize: '0.875rem', // Smaller text
                                                                        paddingLeft: '16px', // Left margin
                                                                        "&:focus": {
                                                                            backgroundColor: 'transparent'
                                                                        }
                                                                    },
                                                                    "&:after, &:before": {
                                                                        content: 'none' // Removes the underline
                                                                    },
                                                                    ".MuiOutlinedInput-notchedOutline": {
                                                                        border: 'none' // Additional check to remove borders if outlined variant leaks any styles
                                                                    },
                                                                    ".MuiInput-underline": {
                                                                        "&:before, &:after": {
                                                                            content: 'none' // Removes the underline
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem value={PermissionState.EDITOR}>Editor</MenuItem>
                                                                <MenuItem value={PermissionState.VIEWER}>Viewer</MenuItem>
                                                                <MenuItem value={PermissionState.NO_ACCESS}>No Access</MenuItem>
                                                            </Select>
                                                        </FormControl>


                                                    </Stack>)
                                            }
                                            return (<td key={cell.id}> {cellJSX} </td>)

                                        })
                                        }
                                    </tr>
                                )) : <></>
                        }
                    </tbody>
                </table>
            </Stack>
            <div className={`${styles.divider} horizontalDivider`}></div>
                <Stack direction={'row'}  justifyContent={'space-between'}>
                {saveTime ?  
                    (<Stack direction={'row'} spacing={2} >
                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#608BDE"/>
                        <path d="M17.3346 8L10.0013 15.3333L6.66797 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Saved {getTimeDiffDes2DatesMillsNow(saveTime)}</span>
                    </Stack>) : 
                    <Stack/>
                    }
                    <PrimaryButton className={`${styles.popupButton}`} disabled={!hasChanges} onClick={handleSaveChanges}> Save Changes</PrimaryButton>
                </Stack>
             </div>
    );
};

export default PermissionTable;