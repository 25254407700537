import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { useMemo } from "react";
import styled from "styled-components";
import constants from "../../../helpers/constants";
import pxToRem from "../../../helpers/pxToRem";
import globalStyles from "../../../styles/_stylesParams.module.scss";

const InputTextFieldStyle = styled(TextField)(({ theme }) => ({
    "& .MuiInput-underline:before": {
      // Default underline appearance
      borderBottomColor: globalStyles.neutrals400,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      // Underline color on hover - ensure your globalStyles define primary300 or replace it with a color code
      borderBottom: `1px solid ${globalStyles.primary400}`, // Adjust thickness and color as needed
    },
    "& .MuiInput-underline:after": {
      // Underline color when focused
      borderBottomColor: globalStyles.primary400, // Ensure your globalStyles define primary400 or replace it with a color code
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderBottomColor: globalStyles.neutrals400,
        },
        "&:hover fieldset": {
            borderBottomColor: globalStyles.primary400,
        },
        "&.Mui-focused fieldset": {
            borderBottomColor: globalStyles.primary400,
        },
      },
     "& .MuiInputBase-input": {
      padding: 0,
      paddingLeft: 16
     }
    // Add additional custom styles as needed
  }));
  
export enum TextFieldInputMode {
  Search = 'search',
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Url = 'url',
  None = 'none',
  Numeric = 'numeric',
  Decimal = 'decimal',
}

interface IInputTextField extends StandardTextFieldProps {
  customHeight?: number;
  fontSize?: number;
  title?: string;
  titleColor?: string;
  label?: string;
  containerStyle?: React.CSSProperties;
  adornment?: JSX.Element;
  inputMode?: TextFieldInputMode;
  isEndAdornment?: boolean;
  valueCheck?: any;
  shrinkLabelWidth?: number;
  multiline?: boolean;
  minRows?: number;
}

const StandardInputTextFeild = ({
  defaultValue,
  title,
  titleColor,
  label,
  adornment,
  isEndAdornment,
  shrinkLabelWidth,
  containerStyle,
  inputMode,
  fontSize = 16,
  valueCheck,
  customHeight = 44,
  multiline,
  minRows,
  ...rest
}: IInputTextField) => {
  const adornmentPosition = useMemo(() => isEndAdornment ? "endAdornment" : "startAdornment", [isEndAdornment]);
  const inputProps = useMemo(() => adornment ? { [adornmentPosition]: <InputAdornment position={isEndAdornment ? "end" : "start"}>{adornment}</InputAdornment> } : {}, [adornmentPosition, adornment]);
  
  return (
    <Stack style={containerStyle}>
      {title && <p className='smallMarginBottom' style={{ color: titleColor ? titleColor : globalStyles['shades100'] }}>{title}</p>}
      <InputTextFieldStyle
        variant="standard"
        autoComplete="off"
        sx={{
          '& .MuiInputBase-input': {
            height: pxToRem(customHeight ?? 0), // Adjust input height
            fontSize: pxToRem(fontSize), // Adjust font size
          },
          '& fieldset legend': {
            width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto',
          }
        }}
        label={label}
        inputMode={inputMode}
        InputProps={{
          ...inputProps,
        }}
        multiline={multiline}
        minRows={minRows || undefined}
        {...rest}
      />
    </Stack>
  );
};

export default StandardInputTextFeild;
