import { addDoc, arrayUnion, collection, deleteDoc, deleteField, doc, FieldValue, getCountFromServer, getDoc, getDocs, increment, runTransaction, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from ".";
import { TJobTitleUpdateStatus } from "../pages/AlumniPage/tabs/EmploymentTab";
import { ALUMNI_SUBCOLS } from "./helpers";
import { Res } from "./shared";
import { Alumni, Education, Employment, ImportAlumni, Note, Schooling } from "./types-alumni";
import { Connection } from "./types-network";

export const createNewAlumni = async (orgId: string, alumni: Alumni, schooling: Schooling, nextFillInProfile?: boolean, peerYear?: number): Promise<Res<string>> => {
  let alumniRefId = "";
  try {
    await runTransaction(db, async (transation) => {
      const collRef = collection(db, "organisations", orgId, "alumni");
      const newAlumniDocRef = await addDoc(collRef, alumni);
      if (nextFillInProfile) { alumniRefId = newAlumniDocRef.id }

      const schoolingBuffer: Schooling = {
        ...schooling,
        alumniId: newAlumniDocRef.id,
      }

      await addDoc(collection(db, "organisations", orgId, 'alumni', newAlumniDocRef.id, 'schooling'), schoolingBuffer);

      //Update Alumni Count
      const docAlumni = doc(db, "organisations", orgId);
      const docAlumniData = await transation.get(docAlumni);
      let totalAlumni = 0;
      if (docAlumniData.exists() && docAlumniData.data()) totalAlumni = (docAlumniData.data().totalAlumni ?? 0) + 1;
      transation.set(docAlumni, { totalAlumni: totalAlumni, alumniLastUpdated: serverTimestamp() }, { merge: true });
    })
    return { code: 200, data: nextFillInProfile ? alumniRefId : "New alumni created." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};
export const updateAlumni = async (orgId: string, alumniId: string, alumni: Alumni<FieldValue>): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(db, "organisations", orgId, "alumni", alumniId);
    //const { created_at, ...updatedData } = alumni;
    batch.update(docRef, alumni);
    const orgRef = doc(db, "organisations", orgId);
    batch.update(orgRef,{alumniLastUpdated: serverTimestamp()} );
    await batch.commit();
    return { code: 200, data: "Alumni updated successfully." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
}
export const updateAlumniPhoto = async (orgId: string, alumniId: string, photoUrlId: string, photoUrl: string): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(db, "organisations", orgId, "alumni", alumniId);
    const updatedData = { updated_at: serverTimestamp(), photoUrlId: photoUrlId, photoUrl: photoUrl };
    batch.update(docRef, updatedData);
    await batch.commit();
    return { code: 200, data: "Update photo successfully." };
  }
  catch {
    return { code: 500 };
  }
}

export const deleteAlumni = async (orgId: string, alumniId: string) => {
  try {
    await deleteAlumni2(orgId, alumniId, true);
    // await runTransaction(db, async(transation) => {
    //   //Delete all subcols of alumni
    //     ALUMNI_SUBCOLS.forEach(async(subcol) => {
    //       const ref = collection(db, "organisation", orgId, "alumni", alumniId, subcol);
    //       const subDocs = await getDocs(ref);
    //       for(let d of subDocs.docs) {
    //         transation.delete(d.ref);
    //       }
    //     })
    //     const docRef = doc(db, "organisations", orgId, "alumni", alumniId);
    //     await deleteDoc(docRef);

    //     //Update Alumni Count
    //     const docAlumni = doc(db, "organisations", orgId);
    //     const docAlumniData = await transation.get(docAlumni);
    //     let totalAlumni = 0;
    //     if(docAlumniData.exists() && docAlumniData.data()) totalAlumni = (docAlumniData.data().totalAlumni ?? 0 ) - 1;
    //     transation.set(docAlumni, { totalAlumni: totalAlumni }, {merge: true});
    // })
    return { code: 200, data: "Alumni deleted." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
}

export const createNewSchooling = async (orgId: string, alumniId: string, schooling: Schooling, peerYear?: number | undefined): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const collRef = collection(db, "organisations", orgId, "alumni", alumniId, "schooling");
    const docRef = doc(collRef);
    batch.set(docRef, schooling);

    if (peerYear) {
      const ref = doc(db, "organisations", orgId, "alumni", alumniId);
      batch.update(ref, { peerYear: peerYear })
    }

    await batch.commit();
    return { code: 200, data: "New schooling created." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};
export const updateSchooling = async (orgId: string, alumniId: string, schooling: Schooling<FieldValue>, schoolingId: string, peerYear?: number | undefined): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const schoolingDocRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "schooling"), schoolingId);
    batch.update(schoolingDocRef, schooling);
    if (peerYear) {
      const ref = doc(db, "organisations", orgId, "alumni", alumniId);
      batch.update(ref, { peerYear: peerYear })
    }
    await batch.commit();
    return { code: 200, data: "Schooling updated." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};

export const createNewEmployment = async (orgId: string, alumniId: string, employment: Employment, currentEmploymentSnippet?: string | undefined,
  jobTitle?: string | undefined, nameOfCompany?: string | undefined, industry?: string | undefined
): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const collRef = collection(db, "organisations", orgId, "alumni", alumniId, "employment");
    const docRef = doc(collRef);
    batch.set(docRef, employment);
    const orgDocRef = doc(db, 'organisations', orgId);
    if (currentEmploymentSnippet) { // FIXME: Need to think of new logic on how to handle this on while adding data to Algolia 
      const ref = doc(db, "organisations", orgId, "alumni", alumniId);
      //batch.update(ref,{currentEmployment: {id:docRef.id, title: currentEmploymentSnippet}})
      batch.set(ref, { currentEmployment: arrayUnion({ id: docRef.id, jobTitle: currentEmploymentSnippet }) }, { merge: true });
    }
    if (jobTitle) {
      batch.update(orgDocRef, { jobTitle: arrayUnion(jobTitle), alumniLastUpdated: serverTimestamp() });
    }
    if (nameOfCompany) {
      batch.update(orgDocRef, { nameOfCompany: arrayUnion(nameOfCompany), alumniLastUpdated: serverTimestamp() });
    }
    if (industry) {
      batch.update(orgDocRef, { industry: arrayUnion(industry), alumniLastUpdated: serverTimestamp() });
    }
    await batch.commit();
    return { code: 200, data: "New employment created." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};
export const updateEmployment = async (orgId: string, alumniId: string, employment: Employment<FieldValue>, employmentId: string,
  fetchedCurrentEmployment?: Array<{ id: string, jobTitle: string }>,
  currentEmploymentSnippet?: { jobTitleUpdateStatus: TJobTitleUpdateStatus, stillInRole: boolean, jobTitle: string | undefined },
  jobTitle?: string | undefined, nameOfCompany?: string | undefined, industry?: string | undefined): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "employment"), employmentId);
    const ref = doc(db, "organisations", orgId, "alumni", alumniId);
    const orgDocRef = doc(db, 'organisations', orgId);
    batch.update(docRef, employment);
    if (currentEmploymentSnippet?.jobTitleUpdateStatus) {
      if (currentEmploymentSnippet?.jobTitleUpdateStatus === 'TitleChangedStillInRole') {
        if (fetchedCurrentEmployment) {
          const employmentIndex = fetchedCurrentEmployment.findIndex(emp => emp.id === employmentId);
          if (employmentIndex !== -1 && currentEmploymentSnippet.jobTitle) {
            fetchedCurrentEmployment[employmentIndex].jobTitle = currentEmploymentSnippet.jobTitle;
            batch.set(ref, { currentEmployment: fetchedCurrentEmployment }, { merge: true });
          } else { batch.set(ref, { currentEmployment: [...fetchedCurrentEmployment, { id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
        } else { batch.set(ref, { currentEmployment: [{ id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
      } else if (currentEmploymentSnippet.jobTitleUpdateStatus === 'StillInRoleAdded') {
        if (fetchedCurrentEmployment) { batch.set(ref, { currentEmployment: [...fetchedCurrentEmployment, { id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
        else { batch.set(ref, { currentEmployment: [{ id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
      } else if (currentEmploymentSnippet.jobTitleUpdateStatus === 'StillInRoleRemoved') {
        if (fetchedCurrentEmployment) {
          const employmentIndex = fetchedCurrentEmployment.findIndex(emp => emp.id === employmentId);
          if (employmentIndex !== -1) {
            fetchedCurrentEmployment.splice(employmentIndex, 1);
            batch.set(ref, { currentEmployment: fetchedCurrentEmployment }, { merge: true });
          }
        }
      }
    }
    if (jobTitle) {
      batch.update(orgDocRef, { jobTitle: arrayUnion(jobTitle), alumniLastUpdated: serverTimestamp()});
    }
    if (nameOfCompany) {
      batch.update(orgDocRef, { nameOfCompany: arrayUnion(nameOfCompany), alumniLastUpdated: serverTimestamp() });
    }
    if (industry) {
      batch.update(orgDocRef, { industry: arrayUnion(industry), alumniLastUpdated: serverTimestamp() });
    }
    await batch.commit();
    return { code: 200, data: "Employment updated." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};

export const createNewEducation = async (orgId: string, alumniId: string, education: Education<FieldValue>, currentEducationDegree: string, updateDegree?: string, updateFieldOfStudy?: string[], updateInstitution?: string): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "education"));
    const alumniDocRef = doc(db, "organisations", orgId, "alumni", alumniId);
    batch.set(docRef, education);
    const orgDocRef = doc(db, 'organisations', orgId);
    batch.set(alumniDocRef, { educationDegree: arrayUnion({ id: docRef.id, degree: currentEducationDegree }) }, { merge: true });
    if (updateDegree) { batch.update(orgDocRef, { degree: arrayUnion(updateDegree), alumniLastUpdated: serverTimestamp() }) }
    // Convert updateFieldOfStudy string to an array and apply each as a separate arrayUnion update
    if (updateFieldOfStudy) {
      updateFieldOfStudy.forEach(studyField => {
        batch.update(orgDocRef, { fieldOfStudy: arrayUnion(studyField), alumniLastUpdated: serverTimestamp()});
      });
    }
    if (updateInstitution) { batch.update(orgDocRef, { institution: arrayUnion(updateInstitution), alumniLastUpdated: serverTimestamp() }) }
    await batch.commit();
    return { code: 200, data: "New education created." };
  }
  catch (error) {
    console.log(error);
    return { code: 500 };
  }
};

export const updateEducation = async (orgId: string, alumniId: string, education: Education<FieldValue>, educationId: string,
  fetchedEducationDegree?: Array<{ id: string, degree: string }>,
  currentEducationDegreeSnippet?: { degreeChangeStatus: 'updated' | 'unchanged', degree: string },
  updateDegree?: string, updateFieldOfStudy?: string[], updateInstitution?: string): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "education"), educationId);
    const alumniDocRef = doc(db, "organisations", orgId, "alumni", alumniId);
    const orgDocRef = doc(db, 'organisations', orgId);
    batch.update(docRef, education);
    if (fetchedEducationDegree && currentEducationDegreeSnippet?.degreeChangeStatus === 'updated') { // This is mandatory field so it will always be there
      const degreeIndex = fetchedEducationDegree.findIndex(educationDegree => educationDegree.id === educationId);
      if (degreeIndex !== -1 && currentEducationDegreeSnippet.degree) {
        fetchedEducationDegree[degreeIndex].degree = currentEducationDegreeSnippet.degree;
        batch.set(alumniDocRef, { educationDegree: fetchedEducationDegree }, { merge: true });
      }
    }
    if (updateDegree) { batch.update(orgDocRef, { degree: arrayUnion(updateDegree), alumniLastUpdated: serverTimestamp() }) }
    // Convert updateFieldOfStudy string to an array and apply each as a separate arrayUnion update
    if (updateFieldOfStudy) {
      updateFieldOfStudy.forEach(studyField => {
        batch.update(orgDocRef, { fieldOfStudy: arrayUnion(studyField), alumniLastUpdated: serverTimestamp() });
      });
    }
    if (updateInstitution) { batch.update(orgDocRef, { institution: arrayUnion(updateInstitution), alumniLastUpdated: serverTimestamp() }) }
    await batch.commit();
    return { code: 200, data: "Education updated." };
  }
  catch (error) {
    console.log("Errors: Update education.");
    console.log(error);
    return { code: 500 };
  }
};

export const createSkill = async (orgId: string, alumniId: string, skills: string[]): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRefAlumni = doc(db, "organisations", orgId, "alumni", alumniId);
    batch.set(docRefAlumni, { skills: arrayUnion(...skills) }, { merge: true });
    const docRefOrganisation = doc(db, "organisations", orgId, 'tags', 'personal');
    batch.set(docRefOrganisation, { skills: arrayUnion(...skills) }, { merge: true });
    await batch.commit();
    return { code: 200, data: "New skill created." };
  }
  catch (error) {
    console.log("Errors happened when trying to create new skill.", error);
    return { code: 500 };
  }
};

export const createPersonalInterest = async (orgId: string, alumniId: string, interests: string[]): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRefAlumni = doc(db, "organisations", orgId, "alumni", alumniId);
    batch.set(docRefAlumni, { personalInterests: arrayUnion(...interests) }, { merge: true });
    const docRefOrganisation = doc(db, "organisations", orgId, 'tags', 'personal');
    batch.set(docRefOrganisation, { interests: arrayUnion(...interests) }, { merge: true });
    await batch.commit();
    return { code: 200, data: "New interest created." };
  } catch (error) {
    console.log("Errors happened when trying to create new interest.", error);
    return { code: 500 };
  }
};

export const createNote = async (orgId: string, alumniId: string, note: Note<FieldValue>): Promise<Res<string>> => {
  try {
    const batch = writeBatch(db);
    const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "notes"));
    batch.set(docRef, note);
    await batch.commit();
    return { code: 200, data: "New note created." };
  }
  catch (error) {
    console.log("Errors happened when trying to create new note.", error);
    return { code: 500 };
  }
};

export const updateNote = async (orgId: string, alumniId: string, noteId: string, note: Note<FieldValue>): Promise<Res<string>> => {
  try {
    const docRef = doc(db, "organisations", orgId, "alumni", alumniId, "notes", noteId);
    await updateDoc(docRef, note)
    return { code: 200, data: "Note updated." };
  }
  catch (error) {
    console.log("Errors happened when trying to update note.", error);
    return { code: 500 };
  }
};

export const deleteNote = async (orgId: string, alumniId: string, noteId: string): Promise<Res<string>> => {
  try {
    await deleteDoc(doc(db, "organisations", orgId, "alumni", alumniId, "notes", noteId));
    return { code: 200, data: "Note updated." };
  }
  catch (error) {
    console.log("Errors happened when trying to update note.", error);
    return { code: 500 };
  }
};

export const getAlumniNameById = async (orgId: string, alumniId: string): Promise<Res<string | null>> => {
  try {
    let alumniName: string | null = null;
    const docRef = doc(db, "organisations", orgId, "alumni", alumniId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const alumniData = docSnap.data();
      alumniName = alumniData.firstName + " " + alumniData.lastName; // Assuming 'name' is the field for the alumni's name
    }
    return { code: 200, data: alumniName };
  } catch (error) {
    console.error("Error getting alumni name:", error);
    return { code: 500, errorMsg: "An error occurred" };
  }
};


const batch = (arr: { a: Alumni, notes?: string, schooling?: Schooling }[], batch_size = 100) => {
  let chunk: { a: Alumni, notes?: string, schooling?: Schooling }[][] = [];
  while (arr.length > 0) {
    chunk.push(arr.splice(0, batch_size))
  }
  return chunk;
}

const batch2 = <T>(arr: Array<T>, batch_size = 100) => {
  let chunk: T[][] = [];
  while (arr.length > 0) {
    chunk.push(arr.splice(0, batch_size))
  }
  return chunk;
}

export const updateImportFiles = async (orgId: string, filename: string) => {
  try {
    // Update import file names
    const orgFileRef = doc(db, "organisations", orgId, "importFiles", filename);
    const snap = await getDoc(orgFileRef);
    if (snap.exists()) return { code: 400, errorMsg: `${filename} has already been uploaded.` };
    await setDoc(orgFileRef, {});
    return { code: 200 };
  }
  catch (error: any) {
    return { code: 500, errorMsg: error };
  }
}


export const importAlumniData = async (orgId: string, alumni: { a: Alumni, notes?: string, schooling?: Schooling }[], importDetails: ImportAlumni) => {
  try {
    const res = await resetRecentlyAdded(orgId);
    if (res.code !== 200) throw new Error("Failed to reset recently added");

    let alumniIds: string[] = []; // To store all the newly added alumni IDs
    let successfulImports = 0; // Counter for successfully added alumni

    // Batch alumni to stay under transaction limit
    await runTransaction(db, async (transaction) => {
      const docAlumni = doc(db, "organisations", orgId);
      transaction.set(docAlumni, { totalAlumni: increment(alumni.length), alumniLastUpdated: serverTimestamp() }, { merge: true });

      for (let item of alumni) {
        const orgAlumniRef = doc(collection(db, "organisations", orgId, "alumni"));
        alumniIds.push(orgAlumniRef.id); // Collecting all newly created alumni IDs

        transaction.set(orgAlumniRef, {
          ...item.a,
          orgId: orgId,
          created_at: serverTimestamp()
        });
        successfulImports++; // Incrementing for each successfully prepared alumni record

        if (item.notes) {
          const notesRef = doc(collection(db, "organisations", orgId, "alumni", orgAlumniRef.id, "notes"));
          transaction.set(notesRef, {
            title: "Imported Notes",
            note: item.notes,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          });
        }

        if (item.schooling) {
          const schoolingRef = doc(collection(db, "organisations", orgId, "alumni", orgAlumniRef.id, "schooling"));
          transaction.set(schoolingRef, {
            ...item.schooling,
            schoolOption: "CURRENT",
            alumniId: orgAlumniRef.id,
            orgId: orgId,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
          });
        }
      }
    });

    // Create a single ImportHistory record after all alumni have been added
    const historyRef = doc(collection(db, "organisations", orgId, "ImportHistory"));
    await setDoc(historyRef, {
      fileName: importDetails.fileName,
      date: serverTimestamp(),
      totalImported: successfulImports,
      totalRejected: importDetails.totalRejected,
      alumniIds: alumniIds,
      error: importDetails.error,
    });

    return { code: 200, totalSuccessImports: successfulImports };

  } catch (error) {
    const errorMsg = `Error occurred whilst importing alumni data: ${error}`;
    console.error(errorMsg);
    return { code: 500, errorMsg: errorMsg };
  }
}



export const resetRecentlyAdded = async (orgId: string) => {
  try {
    // Reset alumni recently added
    const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
    const snap = await getDocs(addedRef);
    for (let i of batch2(snap.docs)) {
      await runTransaction(db, async (t) => {
        i.forEach((document) => {
          const ref = doc(db, "organisations", orgId, 'recentlyAdded', document.id);
          t.delete(ref);
        })
      })
    }
    return { code: 200 }
  } catch (error) {
    const errorMsg = `Error occurred whilst resetting recetnly added: ${error}`;
    console.error(errorMsg);
    return { code: 500, errorMsg: errorMsg };
  }
}

export const revertImportAlumni = async (orgId: string, importId: string) => {
  const batch = writeBatch(db);

  try {
    const importRef = doc(db, "organisations", orgId, "ImportHistory", importId);
    const importDoc = await getDoc(importRef);

    if (!importDoc.exists()) {
      throw new Error("Import history record not found");
    }

    const alumniList: string[] = importDoc.data()?.alumniIds || [];
    const fileName: string = importDoc.data()?.fileName;
    let failedDeletions = 0;

    // Delete each alumni using the existing deleteAlumni2 function
    for (const userId of alumniList) {
      try {
        const res = await deleteAlumni2(orgId, userId, false);
        if (res.code !== 200) {
          failedDeletions++;
          console.error(`Failed to delete alumni ID ${userId}`);
        }
      } catch (error) {
        failedDeletions++;
        console.error(`Failed to delete alumni ID ${userId}:`, error);
      }
    }

    // If fileName exists, delete the corresponding import file document
    if (fileName) {
      const fileRef = doc(db, "organisations", orgId, "importFiles", fileName);
      batch.delete(fileRef);
    }

    // Delete the ImportHistory document
    batch.delete(importRef);

    // Decrement the total alumni count in the organization by the number of successfully deleted alumni
    const totalAlumniToUpdate = alumniList.length - failedDeletions;
    if (totalAlumniToUpdate > 0) {
      const orgRef = doc(db, "organisations", orgId);
      batch.update(orgRef, { totalAlumni: increment(-totalAlumniToUpdate), alumniLastUpdated: serverTimestamp() });
    }

    // Commit the batch operation
    await batch.commit();
    return { code: 200, message: "Import successfully reverted." };
  } catch (error) {
    console.error("Failed to revert import:", error);
    return { code: 500 }; // Provide a more specific error message
  }
};


export const deleteRecentlyAdded = async (orgId: string) => {
  try {
    const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
    const snap = await getDocs(addedRef);
    const deletePromises = snap.docs.map(async (document) => {
      console.log(document.id);
      await deleteAlumni2(orgId, document.id);
      const ref = doc(db, "organisations", orgId, 'recentlyAdded', document.id);
      await deleteDoc(ref);
    });

    await Promise.all(deletePromises);

    // set 
    const docAlumni = doc(db, "organisations", orgId);
    await setDoc(docAlumni, { totalAlumni: increment(-snap.docs.length), alumniLastUpdated: serverTimestamp() }, { merge: true });
    return { code: 200 }
  } catch (error: any) {
    return { code: 500, error: error }
  }
}

export const deleteAlumni2 = async (orgId: string, userId: string, changeTotal?: boolean) => {
  try {
    const batch = writeBatch(db);

    const subcolPromises = ALUMNI_SUBCOLS.map(async (subcol) => {
      console.log(subcol);
      const colRef = collection(db, "organisations", orgId, "alumni", userId, subcol);
      const snap = await getDocs(colRef);
      if (snap.size === 0) return;

      console.log(snap.docs);

      snap.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
    });

    await Promise.all(subcolPromises);

    if (changeTotal) {
      //Update Alumni Count
      const docAlumni = doc(db, "organisations", orgId);
      batch.set(docAlumni, { totalAlumni: increment(-1), alumniLastUpdated: serverTimestamp() }, { merge: true });
    }

    const alumniRef = doc(db, "organisations", orgId, 'alumni', userId);
    batch.delete(alumniRef);
    await batch.commit();

    return { code: 200 }
  } catch (error: any) {
    return { code: 500 }
  }
}

export const isRecentlyAdded = async (orgId: string): Promise<Res<number>> => {
  try {
    const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
    const count = await getCountFromServer(addedRef);
    return { code: 200, data: count.data().count }
  } catch (error) {
    return { code: 500 }
  }
}

/**
* Updates the LinkedAlumnis list and engagement types in an engagement document.
* 
* @param {string} orgId The organization ID.
* @param {string} alumniId The engagement ID.
* @param {Array} connectionList Array of objects each containing `alumniId`, `type`, and `relationship`.
* @returns {Promise<Res<string>>} A promise resolving to the result of the operation.
*/
export async function updateConnections(
  orgId: string,
  alumniId: string,
  connectionList: Array<{ objectID?: string, type: string, relationship: string }>
): Promise<Res<string>> {
  const alumniRef = doc(db, "organisations", orgId, "alumni", alumniId);
  const orgRef = doc(db, "organisations", orgId);

  try {
    const batch = writeBatch(db);
    const processedAlumni = connectionList.filter(connection => connection.objectID !== undefined);

    const orgDoc = await getDoc(orgRef);
    let updatedConnectionRelationships = orgDoc.exists() ? orgDoc.data().connectionRelationships || [] : [];

    for (const connection of connectionList) {
      if (!updatedConnectionRelationships.includes(connection.relationship)) {
        updatedConnectionRelationships.push(connection.relationship);
      }

      const connectionRef = (connection.type === 'Network') ?
        doc(db, "organisations", orgId, "network", connection.objectID as string) :
        doc(db, "organisations", orgId, "alumni", connection.objectID as string);

      const connectionDoc = await getDoc(connectionRef);
      const existingConnections = connectionDoc.data()?.connectionList || [];
      let foundIndex = existingConnections.findIndex((conn: Connection) => conn.objectId === alumniId);
      const reciprocalConnection = {
        objectId: alumniId,
        type: 'Alumni', // Note: Ensure this type logic is correct for your use case
        relationship: connection.relationship
      };

      if (foundIndex !== -1) {
        // Update the existing connection if found
        existingConnections[foundIndex] = {
          ...existingConnections[foundIndex],
          relationship: connection.relationship // Update to new relationship
        };
      } else {
        // Push new connection if not found
        existingConnections.push(reciprocalConnection);
      }

      batch.update(connectionRef, { connectionList: existingConnections });
    }

    // Update the engagement document with new connection list
    batch.update(alumniRef, {
      connectionList: processedAlumni.map(connection => ({
        objectId: connection.objectID,
        type: connection.type,
        relationship: connection.relationship
      }))
    });

    // Update the organization document with new or existing involvements
    batch.update(orgRef, {
      connectionRelationships: updatedConnectionRelationships.sort(), // Optionally sort the list if needed
      alumniLastUpdated: serverTimestamp()
    });

    await batch.commit(); // Commit the batch write
    return { code: 200, data: "Connection updated successfully." };
  } catch (error) {
    console.error("Failed to update Connection list", error);
    return { code: 500, errorMsg: "Failed to update due to server error" };
  }
}

// Function to completely remove the photo URL field of an alumni in an organization
export const deleteAlumniPhotoUrl = async (orgId: string, alumniId: string): Promise<string> => {

  // Reference to the Firestore document where the photo URL is stored
  const photoDocRef = doc(db, "organisations", orgId, "alumni", alumniId);

  try {
    // Delete the photoUrl field entirely from the document
    await updateDoc(photoDocRef, {
      photoUrl: deleteField() // Use deleteField to remove the photoUrl field completely
    });
    console.log("Photo URL field successfully deleted from Firestore.");

    return "Photo URL field deleted successfully.";
  } catch (error) {
    console.error("Error deleting photo URL field:", error);
    throw new Error(`Failed to delete photo URL field: ${error instanceof Error ? error.message : error}`);
  }
};
