import { Button, Fade, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import QRform from "../../../assets/images/landingPage/newPage/LearnMore-Carousel-1-QR Forms.png";
import AlumniProfile from "../../../assets/images/landingPage/newPage/LearnMore-Carousel-2-Alumni_Profile.png";
import Planner from "../../../assets/images/landingPage/newPage/LearnMore-Carousel-3-Planner.png";
// import { getAboutLoop } from "../../../firebase/landingApis";
import { URL_PARAMS } from "../../../routers/shared";
import "./index.css";
import styles from './landingBeta.module.scss';
import pxToRem from "../../../helpers/pxToRem";


const LearnMorePage = () => {
    const navigate = useNavigate();
    // const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
    // const isMobile = useMediaQuery('(max-width:600px)');

    // const [loopDoc, setLoopDoc] = useState("");

    // var menuColors = ['rgba(155, 199, 193, 0.7)', 'rgba(138, 184, 177, 1)', 'rgba(84, 145, 137, 1)']

    // const fetchData = async () => {
    //     const link = await getAboutLoop();
    //     setLoopDoc(link);
    // }

    // useEffect(()=> {
    //     fetchData();
    // }, [])

    const ref = useRef<HTMLDivElement>(null);
    const {pathname} = useLocation();
    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        ref.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }, [pathname])

    const slideTexts = [
        {
            title: "Connect with many alumni cohorts",
            description: "Create a form with Loop, share it using a QR code or link, and allow alumni to input their essential details."
        },
        {
            title: "All alumni data in one place",
            description: "Maintain an updated database of alumni profiles where you can add details such as individual skills, interests, peer year, or profession."
        },
        {
            title: "Keep your outreach organised",
            description: "Assign tasks to specific alumni profiles and effectively organise and manage your engagement efforts."
        }
    ];

    return (
        <div ref={ref} style={{backgroundColor:styles.shades0}} className={styles['space-for-header']}>
            <Stack className={styles.beta}>
                <Fade in={true} timeout={1000}>
                    <Stack className={styles.betaLeft}>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            pagination={{ 
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 3000
                            }}
                            className={styles.swiper}
                            onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
                        >
                            <SwiperSlide className={styles.swiperSlide}>
                                <Stack>
                                    <img src={QRform} alt="All active QR forms in the Loop platform displaying the front cover of a form on a phone"></img>
                                    <h6> | QR Forms </h6>
                                </Stack>
                            </SwiperSlide>
                            <SwiperSlide className={styles.swiperSlide} >
                                <Stack>
                                    <img src={AlumniProfile} alt="View of an alumni profile in the Loop platform on the schooling tab"></img>
                                    <h6> | Alumni Planner </h6>
                                </Stack>
                            </SwiperSlide>
                            <SwiperSlide className={styles.swiperSlide}>
                                <Stack>
                                    <img src={Planner} alt="Planner in the Loop platform with a populated list of upcoming tasks assigned to various alumni profiles"></img>
                                    <h6> | Planner </h6>
                                </Stack>
                            </SwiperSlide>
                        </Swiper>
                    </Stack>
                </Fade>
                <Fade in={true} timeout={1000}>
                    <Stack className={styles.betaRight}>
                        <Fade in={true} key={activeSlide} timeout={{ enter: 500, exit: 0 }}>
                            <div>
                            <h2>{slideTexts[activeSlide].title}</h2>
                            <p>{slideTexts[activeSlide].description}</p>
                            </div>

                        </Fade>
                    </Stack>
                </Fade>
            </Stack>
        </div>

    )
}



export default LearnMorePage;