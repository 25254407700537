import React, { useEffect, useState } from 'react';
import { Select, MenuItem, SelectChangeEvent, FormControl, Stack, Avatar, Typography, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getUsersByOrgId } from '../../../firebase/userApi';
import { stringToColor } from './colorHelper';
import pxToRem from '../../../helpers/pxToRem';

interface UserNameType {
  key: string;
  name: string | null;
}

interface UserDropdownProps {
  orgId: string;
  userId: string;
  defaultUserId?: string;
  onSelectionChange?: (selectedUserId: string) => void;
  disable?: boolean;
}

// Create a new component that will render the Select input without an underline
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    padding: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

const UserDropdown: React.FC<UserDropdownProps> = ({ orgId, userId, defaultUserId, onSelectionChange, disable}) => {
  const [users, setUsers] = useState<UserNameType[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  

  useEffect(() => {
    const fetchUsers = async () => {
      const userData = await getUsersByOrgId(orgId);
      if (userData) {
        setUsers(userData);
        // Check if defaultUserId exists in userData before setting it
        if (defaultUserId && userData.some(user => user.key === defaultUserId)) {
          setSelectedUserId(defaultUserId);
        }
      }
    };

    fetchUsers();
  }, [orgId, defaultUserId]);

  const handleChange = (event: SelectChangeEvent) => {
    const newSelectedUserId = event.target.value as string;
    setSelectedUserId(newSelectedUserId);
    if (onSelectionChange) {
      onSelectionChange(newSelectedUserId);
    }
  }

  const renderUserItem = (user: UserNameType) => (
    <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
      <Avatar
        alt={user.name as string}
        sx={{ width: pxToRem(32), height: pxToRem(32), bgcolor: stringToColor(user.name as string), marginRight: pxToRem(16) }}
      >
        {(user.name as string).charAt(0)}
      </Avatar>
      <Typography variant="caption" style={{fontSize: pxToRem(14)}}>
        {user.name}{user.key === userId ? " (You)" : ""}
      </Typography>
    </Stack>
  );

  return (
    <FormControl variant="standard" sx={{ minWidth: 120, "& .MuiOutlinedInput-notchedOutline": { border: 'none' }, "&:after, &:before": { display: 'none' } }}>
      <Select
        displayEmpty
        value={selectedUserId}
        disabled={disable}
        onChange={handleChange}
        input={<StyledInputBase />}
        renderValue={(selected) => selected ? renderUserItem(users.find(user => user.key === selected) || users[0]) : <small>Select a User</small>}
        sx={{ border: 'none', "&:focus": { borderColor: 'transparent' }, "& .MuiOutlinedInput-notchedOutline": { border: 'none' } }}
      >
        {users.map((user) => (
          <MenuItem key={user.key} value={user.key}>
            {renderUserItem(user)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserDropdown;
