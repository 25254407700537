import { Breadcrumbs, IconButton, Stack } from "@mui/material";
import algoliasearch from 'algoliasearch/lite';
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { BetaButton } from '../../../components/Buttons/ButtonUI';
import CustomizedPaper from '../../../components/CustomizedPaper';
import { db } from '../../../firebase';
import { getOrgById } from '../../../firebase/organisationApis';
import { Alumni, getAlumni } from '../../../firebase/types-alumni';
import { getCurrentUserId } from '../../../firebase/userApi';
import pxToRem from '../../../helpers/pxToRem';
import { joinStringWithSpace } from '../../../helpers/utils';
import { RootState } from '../../../redux';
import { betaStateAction } from '../../../redux/betaSlice';
import { URL_PARAMS } from '../../../routers/shared';
import dashboardStyles from "../../DashboardContainerPage/index.module.scss";
import AlumniDetailsTabsHooks from '../alumniDetailsComponents/AlumniDetailsTabsHooks';
import NotesTab from '../tabs/NotesTab';
import styles from "./index.module.scss";


const searchClient = algoliasearch(`${process.env.REACT_APP_ALGOLIA_APP_ID}`, `${process.env.REACT_APP_ALGOLIA_ALUMNI_SEARCH_KEY}`);
export const UNLIMITED_SIZE = 1000;
export const PAGINATION_PERPAGEROWS = 10;

const AlumniNotePage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = getCurrentUserId();
    const organisation = useSelector((state: RootState) => state.organisation);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
    const [alumniId, setAlumniId] = useState<string | null | undefined>(undefined);
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");

    const [firstNameStr, setFirstNameStr] = useState("");
    const [lastNameStr, setLastNameStr] = useState("");







    interface TaskTabHandles {
        triggerUpdate: () => void;
    }
    const taskTabRef = useRef<TaskTabHandles>(null);

    const loadAlumniInfo = (oid: string, id: string | undefined) => {
        if (oid && id) {
            const documentRef = doc(db, "organisations", oid, "alumni", id);
            const unsubscribe = onSnapshot(documentRef, (querySnapshot) => {
                const data = querySnapshot.data();
                if (data) {
                    const alumni: Alumni = getAlumni(data);
                    setAlumniId(id);
                    setFirstNameStr(alumni.firstName ?? "");
                    setLastNameStr(alumni.lastName ?? "");

                }
                // else{
                //     if(!isDeleting) navigate(`/${URL_PARAMS.ERROR404}`);
                // }
            });
        }
        else {
            navigate(`/${URL_PARAMS.ERROR404}`);
        }
    }

    useEffect(() => {
        console.log("ISTENER TRIGGER", params.orgId, params.alumniId)
        const params_orgId = params.orgId;
        const params_alumniId = params.alumniId;
        if (params_orgId && params_alumniId) {
            console.log("ISTENER TRIGGER_Inisde ")

            if (!orgName) {
                const fetchOrgName = async () => {
                    const foundOrg = await getOrgById(params.orgId as string);
                    if (foundOrg.code === 200) {
                        setOrgName(foundOrg.data);
                    }
                }
                fetchOrgName()
            }
            loadAlumniInfo(params_orgId, params_alumniId);
        }
        else {
            navigate(`/${URL_PARAMS.ERROR404}`);
        }
    }, [params.orgId, params.alumniId]);

    const { notes } = AlumniDetailsTabsHooks({ oid: params?.orgId, alumniId: params?.alumniId, orgName: orgName ?? undefined })

    return (
        <div>
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/alumni`)}><span className={styles.breadcrumbsLink}>Alumni</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/alumni/${alumniId}`)}><span>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span></Stack>
                    <Stack><span>Notes</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => { if (orgState === 'REGULAR') { navigate('/pricing') } else { dispatch(betaStateAction.setBetaState(true)) } }} />
            </Stack>
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    <Stack className={styles.headerBar} direction={'row'} spacing={1}>
                        <Stack>
                            <IconButton onClick={() => navigate(`/dashboard/organisation/${orgId}/alumni/${alumniId}`)}>
                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </IconButton>
                        </Stack>
                        <Stack direction={'column'} spacing={1}>
                            <h6 className={styles.headerTitle}>
                                Notes
                            </h6>
                            <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                        </Stack>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`}></div>
                    <NotesTab noteList={notes} orgId={orgId} orgName={orgName} alumniId={alumniId} />
                </Stack>
            </CustomizedPaper>

        </div>
    );
}

export default AlumniNotePage;