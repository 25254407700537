import { Stack, Skeleton } from "@mui/material";
import pxToRem from "../../../../../helpers/pxToRem";
import styles from "../../../index.module.scss";


const InactiveFormsSkeleton = () => {
    return(
        <>
            <tr className={styles.trskeleton}>
                <td> 
                    <Stack alignItems={"center"} justifyContent={"center"}>
                        <Skeleton variant="circular" sx={{width: pxToRem(28), height: pxToRem(28)}}/>
                    </Stack>
                </td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(30)}/></td>
            </tr>
        </>
    );
}


export default InactiveFormsSkeleton;