import { Chip, InputAdornment, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QRFormTextField } from "..";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import { setCurrentSkills, setCurrentStep } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";

const Skills = () => {
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const skills = useSelector((state:RootState) => state.alumniReg.skills);
    const newSkill = useTextInput({inputValidator: (input) => { return undefined; }});

    const [isSkillAdding, setIsSkillAdding] = useState(false);

    const addSkill = (interest: string) => {
        setIsSkillAdding(true);
        var newSkills = Object.assign([], skills);
        newSkills.push(interest);
        dispatch(setCurrentSkills(newSkills));
        newSkill.setValue("");
        setIsSkillAdding(false);
    }

    const handleChipDelete = (index: number) => {
        var newSkills = Object.assign([], skills);
        newSkills.splice(index,1);
        dispatch(setCurrentSkills(newSkills));
    };

    const handleNext = () => {
        dispatch(setCurrentStep(REG_PAGES.TELL_US_MORE))
    }

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(()=>{
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            <Stack className={styles.formContainer} spacing={3}>
                <QRFormTextField
                    variant="standard"
                    label="Skills"
                    disabled={isSkillAdding} placeholder="Add New Skill" value={newSkill.value} 
                    onChange={(e) => newSkill.setValue(e.target.value)}
                    onKeyDown={(e) => { if(e.key === 'Enter'){ addSkill(newSkill.value.trim()) }}}
                    error={newSkill.hasError && newSkill.hasTouched} 
                    helperText={<small><i>HINT</i>: Hit enter to create a tag.</small>}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                            >
                                <label onClick={()=>addSkill(newSkill.value.trim())} style={{cursor: 'pointer', color: isSkillAdding ? styles.neutrals400 : styles.shades100}} className={styles["regular-400-14"]}>Add</label>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{maxLength: 64}}
                />
                <div>
                    {skills?.map((interest, index) => (
                        <Chip color='default' key={index} label={interest} 
                        onDelete={() => handleChipDelete(index)} 
                        style={{ margin: '0 12px 12px 0', backgroundColor: styles.neutrals100 }}
                    /> 
                    ))}
                </div>
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} onClick={handleNext}>Next</PrimaryQRFormButton>
        </>
    );
}

export default Skills;