import { doc, getDoc } from "firebase/firestore"
import { db } from "."
import { Res } from "./shared";


export const getImportDocuments = async (): Promise<Res<{importGuide: string, importTemplate: string}>> => {
    try {
        const docRef = doc(db, "publicDocuments", "guidesAndTemplates");
        const snap = await getDoc(docRef);
        if(snap.exists()) {
            return {
                code:200,
                data: {
                    importGuide: snap.data().importGuide,
                    importTemplate: snap.data().importTemplate
                }
            }
        }
        return {code: 500, errorMsg: "Templates not found"};
    } catch (error:any) {
        return {code:500, errorMsg: error};
    }
}