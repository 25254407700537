import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { CustomizedCheckBox } from "../../../components/Checkbox";
import { getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForProfileUrl } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import { setCurrentCommOpt, setCurrentContactNumber, setCurrentEmail, setCurrentLinkedIn, setCurrentNewsOpt, setCurrentStep } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";
import { QRFormTextField } from "..";


const ContactDetails = () => {
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const step = useSelector((state:RootState) => state.alumniReg.step);

    const [commIsChecked, setCommIsChecked] = useState(false);
    const [newsIsChecked, setNewsIsChecked] = useState(false);

    const handleData = async () => {
        const mandatoryInputs = [email];
        const optionalInputs = [contactNumber, linkedIn];
        mandatoryInputs.map((input) => input.setHasTouched(true));
        const validated = mandatoryInputs.every(input => !input.hasError);
        if (!validated) { return; }
        const optionalValidated = optionalInputs.every(optionalInput => !(optionalInput.hasError && optionalInput.value.trim() !== ""));
        if(!optionalValidated){ return; }

        dispatch(setCurrentEmail(email.value))
        dispatch(setCurrentContactNumber(contactNumber.value))
        dispatch(setCurrentLinkedIn(linkedIn.value))
        dispatch(setCurrentCommOpt(commIsChecked))
        dispatch(setCurrentNewsOpt(newsIsChecked))
        handleNext()
    }

    const handleNext = () => {
        if(step) {
            dispatch(setCurrentStep(step+1))
        }
    }

    const email = useTextInput({
        inputValidator: (input: string) => { return getErrorMsgForEmailInput(input, false) },
        defaultValue: alumniData && alumniData.email ? alumniData.email : "", 
    });
    
    const contactNumber = useTextInput({
        inputValidator: (input: string) => { return getErrorMsgForContactNumber(input, true) },
        defaultValue: alumniData && alumniData.contactNumber ? alumniData.contactNumber : "", 
    }); 

    const linkedIn = useTextInput({
        inputValidator: (input: string) => { return getErrorMsgForProfileUrl(input, true) },
        defaultValue: alumniData && alumniData.linkedIn ? alumniData.linkedIn : "", 
    });

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(()=>{
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <Stack className={styles.formContainer} spacing={1.5}>
                <QRFormTextField
                    value={email.value} onChange={(e) => email.setValue(e.target.value)}
                    label={"Email*"}
                    error={email.hasError && email.hasTouched}
                    helperText={<small>{email.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                /> 
                <QRFormTextField
                    value={contactNumber.value} onChange={(e) => contactNumber.setValue(e.target.value)}
                    label={"Contact Number"}
                    error={contactNumber.hasError && contactNumber.hasTouched}
                    helperText={<small>{contactNumber.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                /> 
                <QRFormTextField
                    sx={{
                      "& .MuiFormLabel-root": {
                        display: "flex",
                        alignItems: "center",
                        "& .linkedInIcon": {
                          marginRight: "8px",
                          order: -1
                        }
                      }}}
                    className={styles.inputComponent}
                    value={linkedIn.value} onChange={(e) => linkedIn.setValue(e.target.value)}
                    label={
                      <>
                        <svg className="linkedInIcon" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.0413 15.5597C17.0411 16.378 16.3777 17.0412 15.5594 17.0412C14.741 17.0412 14.0776 16.3778 14.0776 15.5594V12.4004C14.0776 11.2937 14.0578 9.86911 12.5363 9.86911C10.9929 9.86911 10.7567 11.0748 10.7567 12.3198V15.5593C10.7567 16.3775 10.0934 17.0409 9.27508 17.0409C8.45679 17.0409 7.79344 16.3775 7.79344 15.5593V8.91977C7.79344 8.13421 8.43026 7.49739 9.21582 7.49739H9.98609C10.3462 7.49739 10.6382 7.78935 10.6382 8.1495V8.77629C10.6382 8.79027 10.6495 8.80161 10.6635 8.80161C10.6725 8.80161 10.6808 8.79683 10.6854 8.78908C10.9698 8.30796 11.3784 7.91205 11.8684 7.64292C12.3627 7.37145 12.9214 7.23913 13.4849 7.26005C16.4884 7.26005 17.0422 9.23567 17.0422 11.8058L17.0413 15.5597ZM4.44969 6.19294C3.49992 6.19317 2.72984 5.42333 2.72969 4.47356C2.72953 3.5238 3.4993 2.75372 4.44906 2.75356C5.39883 2.75333 6.16891 3.5231 6.16906 4.47294C6.16912 4.92902 5.98802 5.36645 5.66558 5.68901C5.34313 6.01157 4.90577 6.19283 4.44969 6.19294ZM5.93138 15.5581C5.9314 16.3773 5.26731 17.0414 4.44811 17.0414C3.62893 17.0414 2.96484 16.3773 2.96484 15.5581V8.98053C2.96484 8.16137 3.6289 7.49731 4.44806 7.49731C5.26721 7.49731 5.93126 8.16136 5.93127 8.9805L5.93138 15.5581ZM18.5183 0.00137749H1.47586C0.670313 -0.00760688 0.00984375 0.637549 0 1.44302V18.5566C0.00953125 19.3625 0.67 20.0083 1.47578 19.9999H18.5183C19.3258 20.0099 19.9888 19.364 20 18.5566V1.44177C19.9885 0.634737 19.3254 -0.0104194 18.5183 0.000127492" fill="#0A66C2"/></svg>
                        LinkedIn
                      </>
                    }
                    error={linkedIn.hasError && linkedIn.hasTouched}
                    helperText={<small>{linkedIn.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                /> 
                <Stack className={styles.optIn} spacing={1}>
                    <small>Please Tick</small>
                    <Stack direction="row" alignItems="center">
                        <CustomizedCheckBox checked={commIsChecked} onChange={()=> setCommIsChecked(!commIsChecked)}/>
                        <small className={commIsChecked ? styles.optInChecked : styles.optInNotChecked}>I’m happy to receive invitations to be involved as a speaker, presenter, coach</small>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <CustomizedCheckBox checked={newsIsChecked} onChange={()=> setNewsIsChecked(!newsIsChecked)}/>
                        <small className={newsIsChecked ? styles.optInChecked : styles.optInNotChecked}>I’m happy to to receive communication and invites regarding school events</small>
                    </Stack>
                </Stack>
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} onClick={handleData}>Next: Schooling History</PrimaryQRFormButton>
        </>
    );
}

export default ContactDetails;