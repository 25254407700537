import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISharedStateSlice {
  //loginStatusLoaded: boolean;
  authDataLoaded: boolean;
  userDataLoaded: boolean;
  isOnBoardingFlow: boolean;
}

const INITIAL_STATE: ISharedStateSlice = {
  //loginStatusLoaded: false,
  authDataLoaded: false,
  userDataLoaded: false,
  isOnBoardingFlow: false,
};

export const sharedStateSlice = createSlice({
  name: "sharedStates",
  initialState: INITIAL_STATE,
  reducers: {
    setSharedStateDefault:(state) => {
        state = INITIAL_STATE;
    },
    // setLoginStatusLoaded: (state, action: PayloadAction<boolean>) => {
    //   state.loginStatusLoaded = action.payload;
    // },
    setAuthDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.authDataLoaded = action.payload;
    },
    setUserDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.userDataLoaded = action.payload;
    },
    setIsOnBoardingFlowState: (state, action: PayloadAction<boolean>) => {
      state.isOnBoardingFlow = action.payload;
    },
    resetSharedStates : (state) => {
      //state.authDataLoaded = INITIAL_STATE.authDataLoaded;
      state.userDataLoaded = INITIAL_STATE.userDataLoaded;
      state.isOnBoardingFlow = INITIAL_STATE.isOnBoardingFlow;
    }
  },
});

export const { setSharedStateDefault, setAuthDataLoaded, setUserDataLoaded, setIsOnBoardingFlowState,resetSharedStates } = sharedStateSlice.actions;

export default sharedStateSlice.reducer;