import { Button, IconButton, Modal, Stack } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import pxToRem from "../../../../helpers/pxToRem";
import { ResponseData, formatDetails, formatName } from "../../helpers";
import styles from "../PendingCard.module.scss";
import IndeterminateCheckbox from "../tableParts/IndeterminateCheckbox";
import ResponseTable from "../tableParts/ResponseTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { PermissionState } from "../../../../firebase/types-organisations";


interface IRejectedRespondents {
}

const RejectedRespondents = ({}: IRejectedRespondents) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const formsPermission = useSelector((state:RootState) => state.globalState.permissions?.forms);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columnHelper = createColumnHelper<ResponseData>();

    const rejectedColumns: ColumnDef<any, any>[] = useMemo(()=> [
        ...formsPermission && formsPermission === PermissionState.EDITOR ? [
            columnHelper.display({
                id: 'rejectSelect',
                cell: ({ row, cell }) => (
                    <div>
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                                id: cell.id,
                                className: styles.round
                            }}
                        />
                    </div>
                ),
                header: ({table}) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                            id: 'rejectHeader',
                            className: styles.round
                        }}
                />),
                size: 68,
            }),
        ]: [],
        columnHelper.accessor('name', { cell: info => {
            const details = info.getValue();
            return (
                <Stack >
                    <small>{formatName(details.firstName, details.lastName, details.title, details.pronouns)}</small>
                    <p>{formatDetails(info.getValue())}</p>
                </Stack>
            )
        }, header: "Respondent" }), 
        columnHelper.accessor('gender', { cell: info => info.getValue(), header: "Gender", size:100 }), 
        columnHelper.accessor('schooling', { cell: info => info.getValue(), header: "Schooling", size:93 }), 
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "Peer Year", size:99 }), 
        columnHelper.accessor('enrolled', { cell: info => info.getValue(), header: 'Enrolled', size:104 }),
        columnHelper.accessor('interests', { cell: info => info.getValue(), header: 'Interests', maxSize:154 }),
        columnHelper.accessor('skills', { cell: info => info.getValue(), header: 'Skills', maxSize:147 }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    return (
        <>
            <Button style={{color: styles.neutrals500}} onClick={openModal}><small>View</small></Button>
            <Modal open={isModalOpen}>
                <Stack className={styles.tableModal}>
                    <IconButton className={styles.rejectCloseButton} onClick={closeModal}>
                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                    <Stack mb={pxToRem(12)} gap={pxToRem(12)}>
                        <p className={styles.pendingTitle} style={{color:styles.secondaryPink600}}>Rejected Respondents</p>
                        <p className={styles.pendingSubtitle} style={{color:styles.neutrals500}}>Note: Rejected respondents will automatically be removed from this list after 14 days</p>
                    </Stack>
                    <ResponseTable columns={rejectedColumns} rejected={true}/>
                </Stack>
            </Modal>
        </>
    )
}

export default RejectedRespondents;