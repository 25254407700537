import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import styled from "styled-components";
import constants from "../../../helpers/constants";
import pxToRem from "../../../helpers/pxToRem";
import globalStyles from "../../../styles/_stylesParams.module.scss";
import styles from "./index.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP } } };
const GreenDropdown = styled(Select)({
  borderRadius: "8px",
  "&:hover": { "&& fieldset": { border: `1px solid ${globalStyles.primary400}`, },
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: `2px solid ${globalStyles.primary400}`},
});

interface ICustomizedDropdown<T> extends SelectProps {
  customHeight?: number,  // Always in px FIXME: Change it to mandatory
  fontSize?: number,  // Always in px FIXME: Change it to mandatory
  valueCheck?: any;
  helperText?: string;
  datas: { value: T; label: string }[];
  containerStyle?: React.CSSProperties;
  fullWidth?: boolean;
  placeHolder?: string; 
  noneEnable?: boolean;
  isNotOptionalNone?: boolean;  
  nonePlaceHolder?: string;   // Custom text for none which will be added inside the menu
  shrinkLabelWidth?: number;  // Controlling the widht of the shrink Input label Text
}

export const CustomizedDropdown = <T,>({ label, shrinkLabelWidth, isNotOptionalNone, noneEnable, nonePlaceHolder, datas, helperText, error, className, containerStyle, customHeight = 44, fontSize = 16, placeHolder, valueCheck, fullWidth, ...rest }: ICustomizedDropdown<T>) => {
  const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1;
  return (
    <FormControl style={containerStyle} fullWidth={fullWidth ? true : false} sx={{ '& fieldset legend': { width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto' }, }} >
      {placeHolder ? 
          <InputLabel id='dropdown-label' className={styles.label} style={{color: error ? globalStyles.destructive500 : undefined}}
            sx={{ 
              "&.Mui-focused": { color: globalStyles.primary400, visibility:'hidden'}, 
              "&:not(.Mui-focused)": { transform: valueCheck === undefined && valueCheck?.trim()?.length === 0 || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}},
              "&.MuiFormLabel-filled": {visibility:'hidden'},
            }}>
          Select
        </InputLabel> 
      :    
      noneEnable ? 
      <InputLabel id='dropdown-label' className={styles.label} style={{color: error ? globalStyles.destructive500 : undefined}}
            sx={{ 
              "&.Mui-focused": { color: globalStyles.primary400, fontSize: pxToRem(fontSize)}, 
              "&:not(.Mui-focused)": {fontSize: pxToRem(fontSize), transform: valueCheck === undefined && valueCheck?.trim()?.length === 0 || valueCheck === undefined || valueCheck === "" ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}},
            }}>
          {label ? label : "Select"}
        </InputLabel> 
      :
        <InputLabel id='dropdown-label' className={styles.label} style={{color: error ? globalStyles.destructive500 : undefined}}// size='small' 
          sx={{ 
            "&.Mui-focused": { color: globalStyles.primary400 }, 
            "&:not(.Mui-focused)": { transform: valueCheck === undefined && valueCheck?.trim()?.length === 0 || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}
          }}>
          {label}
        </InputLabel>}
      <GreenDropdown  style={{height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize)}} {...rest} label={label} className={className} MenuProps={MenuProps} error={error} >
        { noneEnable ? isNotOptionalNone ? null : <MenuItem style={{fontSize: pxToRem(fontSize)}} value=""><em>{nonePlaceHolder ? nonePlaceHolder : "None"}</em></MenuItem> : null}
        {datas.map((data, index) => ( <MenuItem style={{fontSize: pxToRem(fontSize)}} key={index} value={`${data.value}`}>{data.label}</MenuItem> ))}
      </GreenDropdown>
      {helperText &&<small style={{color:globalStyles.destructive500, marginTop: pxToRem(6)}}>{helperText}</small>}
    </FormControl>
  )
};

// NOTE: Green DropDown height is the height of the outline box
