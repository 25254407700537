import { Avatar, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import bgImage from "../../../assets/images/registration/registrationBg.png";
import loopLogo from "../../../assets/images/shared/loopSquareLogo.png";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { CustomizedCheckBox } from "../../../components/Checkbox";
import { getFormData, getOrgData } from "../../../firebase/registrationApis";
import { RootState } from "../../../redux";
import { setAlumniDefault } from "../../../redux/alumniSlice";
import { setCurrentStep, setCurrentTerms, setFormInfo, setOrgInfo, setProgress } from "../../../redux/registrationSlice";
import styles from "../index.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import usePublicDocuments from "../../../hooks/usePublicDocuments";

interface TitlePageProps {
    isTermsChecked: boolean;
    handleCheckbox: ()=>void;
}

const TitlePage = ({isTermsChecked, handleCheckbox}: TitlePageProps) => {
    const params = useParams();
    const {orgId, formId} = params;
    const dispatch = useDispatch();
    const step = useSelector((state:RootState) => state.alumniReg.step);
    const [isLoading, setIsLoading] = useState(true);

    const [header, setHeader] = useState<string>();
    const [desc, setDesc] = useState<string>();    
    const [logo, setLogo] = useState<string>();

    const [tlink, plink, clink] = usePublicDocuments();

    const handleNext = () => {
        // if(isTermsChecked && step) {
        if(step) {
            dispatch(setCurrentTerms(true));
            dispatch(setCurrentStep(step+1));
            dispatch(setProgress(true));
        }
    }

    const fetchFormInfo = async () => {
        setIsLoading(true);
        if(orgId && formId) {
            const res = await getFormData(orgId, formId)
            if(res.code === 200) {
                // Save to redux state
                dispatch(setFormInfo({
                    header: res.data.header,
                    description: res.data.description,
                    closeStatement: res.data.closeStatement,
                    defaultPeerYear: res.data.defaultPeerYear,
                }));
                setHeader(res.data.header || "");
                setDesc(res.data.description || "");

                // retrieve campus and house information
                const orgRes = await getOrgData(orgId);
                if(orgRes.code === 200) {
                    dispatch(setOrgInfo({
                        campuses: orgRes.data.campuses,
                        schoolHouses: orgRes.data.schoolHouses,
                        logo: orgRes.data.logo,
                    }))
                    setLogo(orgRes.data.logo);
                }
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        dispatch(setAlumniDefault());
        fetchFormInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Stack alignItems="center" style={{
                backgroundImage: `url("${bgImage}")`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center'
            }}>
                {
                    isLoading ?
                        <Stack alignItems={"center"} justifyContent={"center"} style={{height:'100vh', width:'100vw'}}>
                            <CircularProgress color="primary"/>
                        </Stack>
                    :
                        <Stack style={{height: '100vh'}}>
                            <Stack alignItems="center" justifyContent="space-evenly" className={styles.regContainer}>
                                <Stack alignItems="center" style={{maxWidth:"400px"}} spacing={2}>
                                    <h6 className={styles.formHeader}>{header}</h6>
                                    <Stack alignItems="center" className={styles.titlePageHolder} spacing={2} style={{width: '100%'}}>
                                        {
                                            logo ?
                                                <Avatar src={logo} className={styles.regLogoFrontPage}/>
                                            : 
                                                <Avatar sx={{backgroundColor: "white"}} className={styles.regLogoBg}>
                                                    <Avatar src={loopLogo} className={styles.regLogoFrontPage}/>
                                                </Avatar> 
                                        }
                                        <pre className={styles.titleText}>{desc}</pre>
                                    </Stack>
                                    <Stack alignItems="center" spacing={2}>
                                        <Stack direction="row" alignItems="center" justifyContent={"space-between"} style={{width:'fit-content', marginTop: pxToRem(22)}}>
                                            <CustomizedCheckBox checked={isTermsChecked} onClick={()=>{handleCheckbox()}}
                                                icon={
                                                    <svg width='24' height='24' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_2180_136230)"><rect x="2.5" y="2.5" width='19' height='19' rx="3.5" stroke="#fff"/></g><defs><clipPath id="clip0_2180_136230"><rect width='20' height='20' fill="white" transform='translate(2 2)'/></clipPath></defs></svg>
                                                }
                                                checkedIcon={
                                                    <svg width='24' height='24' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_2180_136220)"><rect x="2.5" y="2.5" width='19' height='19' rx="3.5" fill="#608BDE"/><rect x="2.5" y="2.5" width='19' height='19' rx="3.5" stroke="#608BDE"/><path d="M17.3327 8L9.99935 15.3333L6.66602 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/></g><defs><clipPath id="clip0_2180_136220"><rect width='19' height='19' fill="white" transform="translate(2 2)"/></clipPath></defs></svg>
                                                }
                                            />
                                            <p className={styles.tcs}>I agree to the <a href={plink} target="_blank">Privacy Policy</a></p> 
                                        </Stack>
                                        <PrimaryQRFormButton className={styles.nextButton} onClick={handleNext} disabled={!isTermsChecked} style={{minWidth: pxToRem(313)}}>Continue</PrimaryQRFormButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack className={styles.footerBox}>
                                <p className={styles.footerLinks}><a href={tlink} target="_blank">Terms of Use</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a href={clink} target="_blank">Cookies Notice</a></p>
                                <p className={styles.footerText} style={{}}>
                                    Powered by Loop
                                </p>
                            </Stack>
                        </Stack>
                }
                
                
            </Stack>
        </>
    );
}


export default TitlePage;