import { SetStateAction, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { PlannerTable, PAGINATION_PERPAGEROWS, plannerDataItemInsideTable } from "..";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import styles from "../index.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import PickerWithButtonField from "../components/dateRangePicker";
import { DateRange } from '@mui/x-date-pickers-pro';
import DeleteButtonMenu from "../components/deleteButtonMenu";
import OptionsMenu from "../components/optionMenu";
import { Dayjs } from 'dayjs';
import PlannerInputTextField from "../../../components/Inputs/TextFields/PlannerInputTextField";
import { CustomizedDropdown } from "../../../components/Inputs/Dropdown/CustomizedDropdown";
import constants from "../../../helpers/constants";
import { deleteAllPlanners, deleteSinglePlanner, markAllDelete, markAllDone, markAllRestore, markAsDelete, markAsDone, markAsRestore } from "../../../firebase/organisationPlannerApi";
import dayjs from 'dayjs';
import { getUserNameById } from "../../../firebase/userApis";
import BasicSearchTable from "./BasicSearchTable";
import ActionSnackbar from "../../../components/SnackBarComponent/CustomizedSnackBar";
import LoadingComponent from "../../../components/LoadingStatus/Loading";
import LoadingStatusBar from "../../../components/LoadingStatus/LoadingBar";
import { getCurrentUserId } from "../../../firebase/userApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { connectionDataSelecor } from "../../../redux/reselect";



interface ISearchTable {
  orgId: string;
  setOpenAddPlanner: (state: boolean) => void,
  rowClick: (id: string) => void;
  editPlanner: (id: string) => void;
  addNewRow: () => JSX.Element;
}
interface FilterType {
  value: string;
  hasTouched: boolean;
}



// export const SearchTable = ({ orgName, setOpenAddAlumni, setAdvanceSearchDialog, rowClick, addNewRow}: ISearchTable) => {
export const SearchTable = (({ orgId, setOpenAddPlanner, rowClick, addNewRow, editPlanner }: ISearchTable) => {
  const userId = getCurrentUserId();
  const [searchInput, setSearchInput] = useState("");
  const plannerData = useSelector((state: RootState) => state.dashboardState.tasks);
  const connectionData = useSelector(connectionDataSelecor);
  const [plannerDataRows, setPlannerDataRows] = useState<PlannerTable[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Dayjs>>([null, null]);
  const [clearFilter, setClearFilter] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => { setSearchInput(event.target.value); };
  const [totalAlumniCount, setTotalAlumniCount] = useState(plannerData.length);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const filterOptions = [{ label: "Upcoming", value: "ALL" }, { label: "Planned", value: "PLANNED" }, { label: "Overdue", value: "OVERDUE" }, { label: "Unplanned", value: "UNPLANNED" }, { label: "Done", value: "DONE" }];
  const deleteFilterOptions = [{ label: "All", value: "ALL" }, { label: "Planned", value: "PLANNED" }, { label: "Overdue", value: "OVERDUE" }, { label: "Unplanned", value: "UNPLANNED" }, { label: "Done", value: "DONE" }];
  const [filterPlannerType, setFilterPlannerType] = useState<FilterType>({ value: "ALL", hasTouched: false });
  // const [plannerDataRows, setPlannerDataRows] = useState<PlannerTable[]>([]);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isTextFieldVisible, setIsTextFieldVisible] = useState(false);

  const plannerPermission = useSelector((state: RootState) => state.globalState.permissions?.planner);


  const [userNames, setUserNames] = useState<{ [key: string]: string }>({});
  const [clearSelection, setClearSelection] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingStatusStr, setLoadingStatusStr] = useState("");
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState<{ open: boolean; message: string; undoAction?: () => void }>({ open: false, message: "" });

  // Flag to determine if filters are applied
  const isFilterApplied = searchInput !== '' || (selectedDate[0] !== null && selectedDate[1] !== null);

  // Function to show the Snackbar with an undo option
  const showUndoSnackbar = (message: string, undoAction: () => void) => {
    setSnackbarInfo({ open: true, message, undoAction });
  };

  // Function to close the Snackbar
  const closeSnackbar = () => {
    setSnackbarInfo(prev => ({ ...prev, open: false }));
  };

  const toggleTextFieldVisibility = () => {
    setIsTextFieldVisible((prevVisible) => !prevVisible);
  };

  const hideTextField = () => {
    setIsTextFieldVisible(false);
  };

  const getAlumniNamesByIds = (ids: string[]) => {
    return ids.map(id => {
      const alumni = connectionData.find(alumni => alumni.objectID === id);
      return alumni ? alumni.name : '';
    }).filter(name => name !== ''); // Filter out any undefined or empty names
  };

  useEffect(() => {
    const filterPlanners = () => {
      let filtered = plannerData.filter(planner => {
        if (deleteMode) {
          return planner.isDeleted;
        } else {
          return !planner.isDeleted;
        }
      });

      // Filter based on search input with simple fuzzy logic
      if (searchInput) {
        const normalizedSearchInput = searchInput.toLowerCase().split(/\s+/);
        // Improved containsAllWords to handle undefined inputs
        const containsAllWords = (str: string | undefined) => {
          const normalizedStr = (str || '').toLowerCase(); // Default to empty string if undefined
          return searchInput.toLowerCase().split(/\s+/).every(word => normalizedStr.includes(word));
        };



        filtered = filtered.filter(planner => {
          const alumniNames = getAlumniNamesByIds(planner.alumniList || []);
          return containsAllWords(planner.title || '') ||
            containsAllWords(planner.detail || '') ||
            containsAllWords(planner.alumniName || '') ||
            alumniNames.some(alumniName => containsAllWords(alumniName)); // Check each name in the list
        });
      }

      // Filter based on planner type and status
      if (filterPlannerType.value !== "ALL") {
        filtered = filtered.filter(planner => {
          switch (filterPlannerType.value) {
            case "PLANNED":
              return !planner.isDone && dayjs(planner.date).isSameOrAfter(dayjs().startOf('day').unix()) && planner.date !== 9999999999999;
            case "OVERDUE":
              return !planner.isDone && dayjs(planner.date).isBefore(dayjs().startOf('day').unix()) && planner.date !== 9999999999999;
            case "DONE":
              return planner.isDone;
            case "UNPLANNED":
              return !planner.isDone && planner.date === 9999999999999;
            default:
              return true;
          }
        });
      } else {
        if (!deleteMode) {
          filtered = filtered.filter(planner => {
            return !planner.isDone;
          });
        }
      }

      // Filter based on selected date range
      if (selectedDate[0] !== null && selectedDate[1] !== null) {
        filtered = filtered.filter(planner =>
          dayjs(planner.date).isSameOrAfter(selectedDate[0]!.startOf('day').unix()) &&
          dayjs(planner.date).isSameOrBefore(selectedDate[1]!.endOf('day').unix())
        );
      }

      // Transform the planner data to match the expected structure in plannerDataRows
      const transformedData = filtered.map(planner => ({
        ...planner,
        objectID: planner.id  // Rename 'id' to 'objectID'
      }));



      // Optionally sort planners by date
      transformedData.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

      setPlannerDataRows(transformedData);
      setTotalAlumniCount(transformedData.length)
    };

    filterPlanners();
  }, [plannerData, searchInput, filterPlannerType, selectedDate, deleteMode, setPlannerDataRows]);


  // Collect userIds and alumniIds
  const userIds = useMemo(() => new Set(plannerDataRows.map(row => row.assignee)), [plannerDataRows]);

  // Fetch user names
  useEffect(() => {
    const fetchUserNames = async () => {
      const names: { [key: string]: string } = {};
      for (let userId of Array.from(userIds)) {
        const result = await getUserNameById(userId as string);
        if (result.code === 200 && result.data) {
          names[userId as string] = result.data;
        }
      }
      setUserNames(names);
    };
    fetchUserNames();
  }, [userIds]);


  const handleRowSelectionChange = useCallback((newSelectedRows: SetStateAction<string[]>) => {
    setSelectedRows(newSelectedRows);
  }, [setSelectedRows]);

  const handleClearFilters = () => {
    // setFilterPlannerType({ value: "ALL", hasTouched: false });
    setSelectedDate([null, null]);
    setSearchInput('');
    setClearFilter(true);
  };


  const handleMarkAllTasksAsDone = async (taskIds: string[]) => {
    if (!taskIds.length) {
      alert("Please make a selection first");
      return;
    }

    // Generate the loading status message to show the whole process
    let loadingMessages = [];
    for (let i = 1; i <= taskIds.length; i++) {
      loadingMessages.push(`Updating task ${i}/${taskIds.length}`);
    }
    const loadingStatusStr = loadingMessages.join(" - ");

    setLoadingStatus(true);
    setLoadingStatusStr(loadingStatusStr);

    // Adjust the delay time based on the number of tasks
    const baseDelayTime = 1000; // Base time for the operation
    const additionalDelayPerTask = 500; // Additional delay per task beyond the first two
    const totalDelayTime = taskIds.length > 2
      ? baseDelayTime * 2 + (taskIds.length - 2) * additionalDelayPerTask
      : baseDelayTime;

    try {
      const response = await markAllDone(orgId as string, taskIds, userId as string);
      if (response.code === 200) {
        setTimeout(() => {
          // Direct client-side update for 2 or fewer tasks
          setClearSelection(prevFlag => !prevFlag);
          setLoadingStatus(false);
          setLoadingStatusStr("");

          showUndoSnackbar("All selected tasks marked as done.", closeSnackbar);

          // Close snackbar after 2 seconds
          setTimeout(closeSnackbar, 2000);
        }, totalDelayTime);
      } else {
        setLoadingStatus(false);
        setLoadingStatusStr("");
        alert("Failed to mark all tasks as done. Please try again.");
      }
    } catch (error) {
      setLoadingStatus(false);
      setLoadingStatusStr("");
      console.error("Error marking all tasks as done:", error);
      alert("Failed to mark all tasks as done. Please try again.");
    }
  };

  const handleMarkAllTasksAsDeleted = async (taskIds: string[]) => {
    if (!taskIds.length) {
      alert("Please make a selection first");
      return;
    }

    let loadingMessages = [];
    for (let i = 1; i <= taskIds.length; i++) {
      loadingMessages.push(`Moving task to Recycling Bin ${i}/${taskIds.length}`);
    }
    const loadingStatusStr = loadingMessages.join(" - ");

    setLoadingStatus(true);
    setLoadingStatusStr(loadingStatusStr);

    // Adjust the delay time based on the number of tasks
    const baseDelayTime = 500; // Base time for the operation
    const additionalDelayPerTask = 200; // Additional delay per task beyond the first two
    const totalDelayTime = taskIds.length > 2
      ? baseDelayTime * 2 + (taskIds.length - 2) * additionalDelayPerTask
      : baseDelayTime;

    try {
      const response = await markAllDelete(orgId as string, taskIds, userId as string);
      if (response.code === 200) {
        setTimeout(() => {
          // Directly update the UI for 2 or fewer tasks without refreshing
          setClearSelection(prevFlag => !prevFlag);
          setLoadingStatus(false);
          setLoadingStatusStr("");
        }, totalDelayTime);
      } else {
        setLoadingStatus(false);
        setLoadingStatusStr("");
        alert("Failed to mark all tasks as deleted. Please try again.");

      }
    } catch (error) {
      setLoadingStatus(false);
      setLoadingStatusStr("");
      console.error("Error marking all tasks as deleted:", error);
      alert("Failed to mark all tasks as deleted. Please try again.");
    }
  };



  const handleRestoreAllTasks = async (taskIds: string[]) => {
    if (!taskIds.length) {
      alert("Please make a selection first");
      return;
    }

    let loadingMessages = [];
    for (let i = 1; i <= taskIds.length; i++) {
      loadingMessages.push(`Restoring task ${i}/${taskIds.length}`);
    }
    const loadingStatusStr = loadingMessages.join(" - ");

    setLoadingStatus(true);
    setLoadingStatusStr(loadingStatusStr);

    const baseDelayTime = 500; // Base time for the operation
    const totalDelayTime = taskIds.length > 2
      ? baseDelayTime * 2 + (taskIds.length - 2) * 200
      : baseDelayTime;

    try {
      const response = await markAllRestore(orgId as string, taskIds, userId as string);
      if (response.code === 200) {
        setTimeout(() => {
          setClearSelection(prevFlag => !prevFlag);
          setLoadingStatus(false);
          setLoadingStatusStr("");
        }, totalDelayTime);
      } else {
        setLoadingStatus(false);
        setLoadingStatusStr("");
        alert("Failed to restore all tasks. Please try again.");
      }
    } catch (error) {
      setLoadingStatus(false);
      setLoadingStatusStr("");
      console.error("Error restoring all tasks:", error);
      alert("Failed to restore all tasks. Please try again.");
    }
  };


  const handleDeleteAllTasks = async (taskIds: string[]) => {
    if (!taskIds.length) {
      alert("Please make a selection first");
      return;
    }

    let loadingMessages = [];
    for (let i = 1; i <= taskIds.length; i++) {
      loadingMessages.push(`Deleting task ${i}/${taskIds.length}`);
    }
    const loadingStatusStr = loadingMessages.join(" - ");

    setLoadingStatus(true);
    setLoadingStatusStr(loadingStatusStr);

    const baseDelayTime = 500; // Base time for the operation
    const totalDelayTime = taskIds.length > 2
      ? baseDelayTime * 2 + (taskIds.length - 2) * 200
      : baseDelayTime;

    try {
      const response = await deleteAllPlanners(orgId as string, taskIds, userId as string);
      if (response.code === 200) {
        setTimeout(() => {
          setClearSelection(prevFlag => !prevFlag);
          setLoadingStatus(false);
          setLoadingStatusStr("");
        }, totalDelayTime);
      } else {
        setLoadingStatus(false);
        setLoadingStatusStr("");
        alert("Failed to delete all tasks. Please try again.");
      }
    } catch (error) {
      setLoadingStatus(false);
      setLoadingStatusStr("");
      console.error("Error deleting all tasks:", error);
      alert("Failed to delete all tasks. Please try again.");
    }
  };


  return (
    <Stack>
      <Stack style={{ marginBottom: pxToRem(20) }}>
        <Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>
          <PickerWithButtonField
            onDateRangeSelect={(dates) => {
              setSelectedDate(dates)
              setIsTextFieldVisible(true)
              setClearFilter(false)
            }}
            clearDate={clearFilter}
          />
          {
            !isTextFieldVisible && (
              <IconButton onClick={toggleTextFieldVisibility} style={{ marginLeft: 0 }}>
                <svg width={pxToRem(36)} height={pxToRem(36)} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27 27L21.0001 21M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </IconButton>
            )
          }
          {
            isTextFieldVisible && (
              <PlannerInputTextField
                containerStyle={{ flex: 1, marginLeft: 1 }}
                value={searchInput}
                placeholder="Start typing..."
                onChange={handleInputChange}
                startAdornment={
                  <IconButton onClick={hideTextField}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={pxToRem(24)}
                      height={pxToRem(25)}
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </IconButton>

                }
                endAdornment={
                  selectedDate && selectedDate[0] && selectedDate[1] ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <div>
                        {selectedDate[0].isSame(selectedDate[1], 'day')
                          ? selectedDate[0].format('DD MMMM YYYY')
                          : `${selectedDate[0].format('DD MMMM YYYY')} - ${selectedDate[1].format('DD MMMM YYYY')}`}
                      </div>
                      <Button style={{ color: "#447D75" }} onClick={() => { handleClearFilters(); hideTextField(); }}>
                        Clear Filters
                      </Button>
                    </Stack>
                  ) : undefined
                }
              />
            )
          }

          <CustomizedDropdown

            defaultValue={"ALL"}
            valueCheck={filterPlannerType.value}
            // label="Plan Status"
            // shrinkLabelWidth={70}
            containerStyle={{ width: pxToRem(154), marginLeft: 12, color: "#A3A3A3" }} // Set a fixed width here
            // customHeight={constants.addAlumniInputHeight}
            fontSize={constants.addAlumniFontSize}
            datas={deleteMode ? deleteFilterOptions : filterOptions}
            value={filterPlannerType.value}
            onChange={(e) => {
              const newValue = e.target.value as string; // Type assertion here
              setFilterPlannerType({ value: newValue, hasTouched: true });
            }}
            error={filterPlannerType.hasTouched && !filterPlannerType.value}
            helperText={filterPlannerType.hasTouched && !filterPlannerType.value ? "This field cannot be empty" : undefined}

          />
        </Stack>
        <Stack direction={"row"} className={styles.buttonBarWrapper} spacing={2}>
          {deleteMode ? (
            <h6 style={{ flexGrow: 1 }} className='light'>Recycling Bin</h6>
          ) : (
            <>
              {(plannerPermission && plannerPermission === "EDITOR") ? (<PrimaryButton className={styles.button} onClick={() => { setOpenAddPlanner(true) }}>New Task</PrimaryButton>) : <h6 style={{ flexGrow: 1 }} className='light'>Planner Table</h6>}
            </>
          )}

          <Stack direction="row" spacing={0}>

            {deleteMode ? (
              // If deleteMode is true, show the delete and exit buttons
              <>
                <Button style={{ color: "#447D75" }} onClick={() => { setDeleteMode(false); }} sx={{ marginRight: 1 }}>
                  Exit
                </Button>
                <IconButton sx={{ marginLeft: 0, width: pxToRem(38), height: pxToRem(38) }} onClick={() => { setDeleteMode(false); handleClearFilters(); hideTextField(); setClearSelection(prevFlag => !prevFlag); }}>
                  <svg width={pxToRem(30)} height={pxToRem(30)} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5" stroke="#549189" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </IconButton>
                {(plannerPermission && plannerPermission === "EDITOR") &&
                  <DeleteButtonMenu onMarkRestore={() => {
                    handleRestoreAllTasks(selectedRows);
                  }}
                    onDelete={() => {
                      handleDeleteAllTasks(selectedRows)
                    }}
                    isDisabled={selectedRows.length === 0} />}
              </>
            ) : (
              // If deleteMode is false, show the default button
              <>
                {selectedRows.length === 0 && (
                  <Tooltip title="Recycling Bin">
                    <IconButton style={{ width: pxToRem(38), height: pxToRem(38) }} onClick={() => { setDeleteMode(true); handleClearFilters(); hideTextField(); setFilterPlannerType({ value: "ALL", hasTouched: false }); }}>
                      <svg width={pxToRem(30)} height={pxToRem(30)} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 5.41406V4.61406C14 3.49396 14 2.93391 13.782 2.50608C13.5903 2.12976 13.2843 1.8238 12.908 1.63205C12.4802 1.41406 11.9201 1.41406 10.8 1.41406H9.2C8.07989 1.41406 7.51984 1.41406 7.09202 1.63205C6.71569 1.8238 6.40973 2.12976 6.21799 2.50608C6 2.93391 6 3.49396 6 4.61406V5.41406M8 10.9141V15.9141M12 10.9141V15.9141M1 5.41406H19M17 5.41406V16.6141C17 18.2942 17 19.1343 16.673 19.776C16.3854 20.3405 15.9265 20.7995 15.362 21.0871C14.7202 21.4141 13.8802 21.4141 12.2 21.4141H7.8C6.11984 21.4141 5.27976 21.4141 4.63803 21.0871C4.07354 20.7995 3.6146 20.3405 3.32698 19.776C3 19.1343 3 18.2942 3 16.6141V5.41406" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </IconButton>
                  </Tooltip>
                )}
                {(plannerPermission && plannerPermission === "EDITOR") &&
                  <OptionsMenu
                    onMarkComplete={() => {
                      handleMarkAllTasksAsDone(selectedRows)
                    }}
                    onDelete={() => {
                      handleMarkAllTasksAsDeleted(selectedRows)
                    }}
                    isDisabled={selectedRows.length === 0}
                    isDone={filterPlannerType.value === "DONE"}
                  />}
              </>
            )}

          </Stack>
        </Stack>
      </Stack>
      <BasicSearchTable
        orgId={orgId}
        plannerDataRows={plannerDataRows}
        userNames={userNames}
        totalRows={totalAlumniCount ?? 0}
        rowClick={rowClick}
        editPlanner={editPlanner}
        status={filterPlannerType.value}
        addNewRow={addNewRow}
        onRowSelectionChange={handleRowSelectionChange}
        isDeleteMode={deleteMode}
        clearSelectedRows={clearSelection}
        isEmpty={!isFilterApplied}
      />
      <ActionSnackbar
        open={snackbarInfo.open}
        message={snackbarInfo.message}
        onClose={closeSnackbar}
        noUndo={true}
      />
      <LoadingStatusBar isLoading={loadingStatus} loadingStr={loadingStatusStr} isDelete={isLoadingDelete} />
    </Stack >
  );
});

export default memo(SearchTable);