export const FORMS_TABS = {
    ACTIVE: '0',
    ARCHIVED: '1',
}

export const FORMS_PAGINATION_PERPAGEROWS = 20;

export const SUBTITLE = "Share a unique QR Code with your alumni and enable them to register their details directly to Loop"

export interface OperationModalText {
    operation: string;
    operationVerb: string;
    operationPast: string;
    title: string;
    subtitle: string;
}

export const closeModal: OperationModalText = {
    operation: "archive",
    operationVerb: "archiving",
    operationPast: "archived",
    title: "Archive Form",
    subtitle: "Archiving a form will also expire the QR Code",
}

export const deleteModal: OperationModalText = {
    operation: "permanently delete",
    operationVerb: "permanently deleting",
    operationPast: "deleted",
    title: "Permanently Delete Form",
    subtitle: "This action cannot be undone",
}