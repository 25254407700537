import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import MyAccountPanel from "./components/MyAccountPanel";
import styles from "./index.module.scss";
import CustomizedPaper from "../../components/CustomizedPaper";
import pxToRem from "../../helpers/pxToRem";
import { useDispatch } from "react-redux";
import { setPageStatus } from "../../redux/pageStatusSlice";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { CustomTabPanel } from "../../helpers/tabControl";
import HelpCentrePanel from "./components/HelpCentrePanel";
import { useLocation } from "react-router-dom";
import { signOutUser } from "../../firebase/userApis";
import { AlertDialogComponent, AlertDialogSimpleComponent } from "../../components/AlertComponent";
import UserActivityUtil from "../../helpers/localStorageUtils/userActivity";
import { getCurrentUserId } from "../../firebase/userApi";
const a11yProps = (index: number) => { return { id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`, }; }
const AccountSettingsPage = () => {
    const dispatch = useDispatch(); 
    useEffect(()=>{ dispatch(setPageStatus(false)); }, []); //Page Loading Off
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => { 
        if (newValue === 2) { // Assuming 2 is the index of the logout tab
            return;
          }
        setTabIndex(newValue); 
    };

    const location = useLocation();
    const userId = getCurrentUserId();
    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        if(queryParams.get('help') === 'true'){
            setTabIndex(1)
        } else {
            setTabIndex(0)
        }
        window.scrollTo(0, 0);
    },[location, location.pathname])
    const [isLogoutModal, setIsLogoutModal] = useState(false);

    const logoutJSX = () => {
        return  <Stack style={{ justifyContent:'start', alignItems:'center' }} direction={"row"}>
                    <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16 17L21 12M21 12L16 7M21 12H9M9 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                    <label style={{color:'black', cursor:'pointer', marginLeft:pxToRem(12)}} className='regular-400;14'>Logout</label>
                </Stack>;
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <label className={`${styles.headerTitle}`} style={{ marginTop: pxToRem(20), marginBottom: pxToRem(10) }}>Account Settings</label>
            <AlertDialogSimpleComponent  primaryButtonTitle="Yes, log out" secondaryButtonTitle="Cancel" message={`<h6>Do you wish to log out?</h6>`} messageStyle={{textAlign:'center', fontSize:'1.5rem'}}  visible={isLogoutModal} primaryButtonClose={() => {signOutUser(dispatch); UserActivityUtil.forceLogout("planner"); UserActivityUtil.forceLogout("alumni"); UserActivityUtil.clearUserActivityFromStorage(userId)}} secondaryButtonClose={() => setIsLogoutModal(false)} />
            <CustomizedPaper className={`${styles.contentDashboard}`} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                <Stack className={styles.AccountSettingsPage} style={{ display: 'flex', padding: `${pxToRem(40)} ${pxToRem(16)}`, flexGrow:1}}>
                    <Box sx={{ flexGrow: 1, display: 'flex'}} >
                        <Tabs TabIndicatorProps={{style:{backgroundColor:'transparent'}}} className={styles.tabs} orientation="vertical" variant="scrollable" value={tabIndex} onChange={handleTabChange} sx={{ borderRight: 1, borderColor: 'divider' }} >
                            <Tab className={`${styles.tab} ${tabIndex===0?styles.tabActive:""} regular-400-14`} style={{ alignItems:"start" }} label="My Account" {...a11yProps(0)} />
                            <Tab className={`${styles.tab} ${tabIndex===1?styles.tabActive:""} regular-400-14`} style={{ alignItems:"start" }} label="Help Centre" {...a11yProps(1)}/> 
                            <Tab className={`${styles.tab} ${tabIndex===2?styles.tabActive:""} regular-400-14`} onClick={() => { setIsLogoutModal(true); }} style={{ alignItems:"start", cursor:'pointer' }} label={logoutJSX()} {...a11yProps(2)}/> 
                        </Tabs>
                        <CustomTabPanel value={tabIndex} index={0}><MyAccountPanel/></CustomTabPanel>
                        <CustomTabPanel value={tabIndex} index={1}><HelpCentrePanel/></CustomTabPanel>
                    </Box>
                </Stack>
            </CustomizedPaper>
        </div>
    );
};

export default AccountSettingsPage;