import { collection, deleteDoc, doc, FieldValue, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { deleteObject, getStorage, ref as storageRef } from "firebase/storage";
import { db } from ".";
import { Res } from "./shared";
import { FileData, getFile, getPhoto, getUrl, PhotoData, PhotosResponse, Url } from "./type-media";


export const createUrl = async (orgId: string, alumniId: string, url: Url<FieldValue>): Promise<Res<string>> => {
    try {
        const batch = writeBatch(db);
        const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "urls"));
        batch.set(docRef, url);
        await batch.commit();
        return { code: 200, data: "New url created." };
    }
    catch (error) {
        console.log("Errors happened when trying to create new url.", error);
        return { code: 500 };
    }
};

export const updateUrl = async (orgId: string, alumniId: string, urlId: string, url: Url<FieldValue>): Promise<Res<string>> => {
    try {
        const docRef = doc(db, "organisations", orgId, "alumni", alumniId, "urls", urlId);
        await updateDoc(docRef, url)
        return { code: 200, data: "Url updated." };
    }
    catch (error) {
        console.log("Errors happened when trying to update url.", error);
        return { code: 500 };
    }
};

export const deleteUrl = async (orgId: string, alumniId: string, urlId: string): Promise<Res<string>> => {
    try {
        await deleteDoc(doc(db, "organisations", orgId, "alumni", alumniId, "urls", urlId));
        return { code: 200, data: "Url updated." };
    }
    catch (error) {
        console.log("Errors happened when trying to update url.", error);
        return { code: 500 };
    }
};

export const fetchUrlsForAlumni = async (orgId: string, alumniId: string): Promise<Url[]> => {
    try {
        const urlCollection = collection(db, "organisations", orgId, "alumni", alumniId, "urls");
        const querySnapshot = await getDocs(urlCollection);
        return querySnapshot.docs.map(doc => getUrl(doc.id, doc.data()));
    } catch (error) {
        console.error("Error fetching URLs:", error);
        return [];
    }
};

export const fetchUrlCount = async (orgId: string, alumniId: string): Promise<Res<number>>  => {
    const urlsCollection = collection(db, "organisations", orgId, "alumni", alumniId, "urls");
    const q = query(urlsCollection); // Create a query against the collection

    try {
        const querySnapshot = await getDocs(q);
        return { code: 200, data: querySnapshot.size }; // Return the count of documents
    } catch (error) {
        console.error("Error fetching URL count:", error);
        return { code: 500 };
    }
};



export const createFile = async (orgId: string, alumniId: string, fileData: FileData): Promise<Res<string>> => {
    try {
        const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "files"));
        await setDoc(docRef, fileData);
        return { code: 200, data: "New file created." };
    } catch (error) {
        console.error("Errors happened when trying to create new file in Firestore.", error);
        return { code: 500};
    }
};


export const updateFile = async (orgId: string, alumniId: string, fileId: string, file: FileData<FieldValue>): Promise<Res<string>> => {
    try {
        const docRef = doc(db, "organisations", orgId, "alumni", alumniId, "files", fileId);
        await updateDoc(docRef, file);
        return { code: 200, data: "File updated." };
    }
    catch (error) {
        console.log("Errors happened when trying to update file.", error);
        return { code: 500 };
    }
};


export const deleteFile = async (orgId: string, alumniId: string, fileId: string): Promise<Res<string>> => {
    const storage = getStorage();

    try {
        // Get the document reference
        const fileDocRef = doc(db, "organisations", orgId, "alumni", alumniId, "files", fileId);
        
        // Fetch the document to get the file URL
        const fileDoc = await getDoc(fileDocRef);
        if (!fileDoc.exists()) {
            return { code: 500, errorMsg: "File not found in Firestore." };
        }
        const fileUrl = fileDoc.data().link;

        // Create a reference to the file to delete in storage
        const fileRef = storageRef(storage, fileUrl);

        // Delete the file from Firebase Storage
        await deleteObject(fileRef);
        console.log("File successfully deleted from storage.");

        // Delete the document from Firestore
        await deleteDoc(fileDocRef);
        return { code: 200, data: "File deleted successfully from Firestore and Storage." };
    } catch (error) {
        console.error("Errors happened when trying to delete file.", error);
        return { code: 500, errorMsg: "Failed to delete file." };
    }
};


export const fetchFilesForAlumni = async (orgId: string, alumniId: string): Promise<FileData[]> => {
    try {
        const fileCollection = collection(db, "organisations", orgId, "alumni", alumniId, "files");
        const querySnapshot = await getDocs(fileCollection);
        return querySnapshot.docs.map(doc => getFile(doc.id, doc.data()));
    } catch (error) {
        console.error("Error fetching files:", error);
        return [];
    }
};

export const fetchFileCount = async (orgId: string, alumniId: string): Promise<Res<number>>  => {
    const urlsCollection = collection(db, "organisations", orgId, "alumni", alumniId, "files");
    const q = query(urlsCollection); // Create a query against the collection

    try {
        const querySnapshot = await getDocs(q);
        return { code: 200, data: querySnapshot.size }; // Return the count of documents
    } catch (error) {
        console.error("Error fetching URL count:", error);
        return { code: 500 };
    }
};

export const createPhoto = async (orgId: string, alumniId: string, photoData: PhotoData): Promise<Res<string>> => {
    try {
        const docRef = doc(collection(db, "organisations", orgId, "alumni", alumniId, "photos"));
        await setDoc(docRef, photoData);
        return { code: 200, data: "New photo created." };
    } catch (error) {
        console.error("Errors happened when trying to create new photo in Firestore.", error);
        return { code: 500};
    }
};

export const updatePhoto = async (orgId: string, alumniId: string, photoId: string, photo: PhotoData<FieldValue>): Promise<Res<string>> => {
    try {
        const docRef = doc(db, "organisations", orgId, "alumni", alumniId, "photos", photoId);
        await updateDoc(docRef, photo);
        return { code: 200, data: "Photo updated." };
    }
    catch (error) {
        console.log("Errors happened when trying to update photo.", error);
        return { code: 500 };
    }
};

export const deletePhoto = async (orgId: string, alumniId: string, photoId: string): Promise<Res<string>> => {
    const storage = getStorage();

    try {
        // Get the document reference
        const photoDocRef = doc(db, "organisations", orgId, "alumni", alumniId, "photos", photoId);
        
        // Fetch the document to get the photo URL
        const photoDoc = await getDoc(photoDocRef);
        if (!photoDoc.exists()) {
            return { code: 500, errorMsg: "Photo not found in Firestore." };
        }
        const photoUrl = photoDoc.data().link;
        const orgPhotoUrl = photoDoc.data().originalUrl;

        // Create a reference to the photo to delete in storage
        const photoRef = storageRef(storage, photoUrl);
        const orgPhotoRef = storageRef(storage, orgPhotoUrl);

        // Delete the photo from Firebase Storage
        await deleteObject(photoRef);
        await deleteObject(orgPhotoRef);
        console.log("Photo successfully deleted from storage.");

        // Delete the document from Firestore
        await deleteDoc(photoDocRef);
        return { code: 200, data: "Photo deleted successfully from Firestore and Storage." };
    } catch (error) {
        console.error("Errors happened when trying to delete photo.", error);
        return { code: 500, errorMsg: "Failed to delete photo." };
    }
};

export const updateMediaPhoto = async (orgId: string, alumniId: string, photoId: string, photo: PhotoData<FieldValue>): Promise<Res<string>> => {
    try {
        const mediaPhotoDocRef = doc(db, "organisations", orgId, "alumni", alumniId, "photos", photoId);
        const updatedData = {
            ...photo,
            uploaded_at: serverTimestamp()
        };

        await updateDoc(mediaPhotoDocRef, updatedData);
        return { code: 200, data: "Media photo updated successfully." };
    } catch (error) {
        console.error("Error updating media photo:", error);
        return { code: 500, errorMsg: "Failed to update media photo due to server error" };
    }
};


export const fetchPhotosForAlumni = async (orgId: string, alumniId: string): Promise<PhotosResponse> => {
    try {
        const fileCollection = collection(db, "organisations", orgId, "alumni", alumniId, "photos");
        const querySnapshot = await getDocs(fileCollection);
        const photos: PhotosResponse = {};
        querySnapshot.docs.forEach(doc => {
            const photoData = getPhoto(doc.id, doc.data());
            if (photoData.name === 'Profile Photo') photos.profile = photoData;
            else if (photoData.name === 'Recent Photo') photos.recent = photoData;
            else if (photoData.name === 'School Photo') photos.school = photoData;
        });
        return photos;
    } catch (error) {
        console.error("Error fetching photos:", error);
        return {};
    }
};
;

export const fetchCounts = async (orgId: string, alumniId: string): Promise<Res<{ urlsCount: number, photosCount: number, filesCount: number }>> => {
    try {
        const urlsCollection = collection(db, "organisations", orgId, "alumni", alumniId, "urls");
        const photosCollection = collection(db, "organisations", orgId, "alumni", alumniId, "photos");
        const filesCollection = collection(db, "organisations", orgId, "alumni", alumniId, "files");

        // Fetch all counts concurrently using Promise.all
        const [urlSnapshot, photoSnapshot, fileSnapshot] = await Promise.all([
            getDocs(urlsCollection),
            getDocs(photosCollection),
            getDocs(filesCollection)
        ]);

        return {
            code: 200,
            data: {
                urlsCount: urlSnapshot.size,
                photosCount: photoSnapshot.size,
                filesCount: fileSnapshot.size
            }
        };
    } catch (error) {
        console.error("Error fetching counts:", error);
        return { code: 500, errorMsg: "Failed to fetch counts" };
    }
};



