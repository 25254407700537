import { TaskState, deleteObject, getDownloadURL, getStorage, ref as stRef, uploadBytesResumable, } from "firebase/storage"; import { AsyncStatus, Res } from "./shared";
import { getError } from "../helpers/utils";
const metadatas = {
    pdf: { contentType: 'application/pdf' },
    xls: { contentType: 'application/vnd.ms-excel' },
    xlsx: { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    doc: { contentType: 'application/msword' },
    docx: { contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    jpg: { contentType: 'image/jpeg' },
    jpeg: { contentType: 'image/jpeg' },
    png: { contentType: 'image/png' },
    csv: { contentType: 'text/csv' }, // Add appropriate MIME type for CSV
  };
interface IuploadFileToStorage { path: string; file: File; }
interface IuploadFileToStorageWithProgress { path: string, file: Blob, onProgress: (taskState:TaskState, progress: number) => void;}

export const uploadFileToStorage = async ({ file, path, }: IuploadFileToStorage): Promise<Res<string>> => {
    const storage = getStorage();
    return new Promise((resolve, reject) => { 
        const storageRef = stRef(storage, path); 
        const uploadTask = uploadBytesResumable( storageRef, file, metadatas[file.name.split(".")[1] as "jpeg" | "jpg" | "png" | "pdf"] );
        uploadTask.on( "state_changed", 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case "paused": console.log("Upload is paused"); break;
                    case "running": console.log("Upload is running"); break;
                }
            },
            (error) => {
                switch (error.code) {
                    case "storage/unauthorized": reject({ error: 500, errorMsg: "User doesn't have permission to access the object when uploading general file", }); break;
                    case "storage/canceled": reject({ error: 500, errorMsg: "User canceled the upload when uploading general file", }); break;
                    case "storage/unknown": reject({ error: 500, errorMsg: "Unknown error occurred when uploading general file", }); break;
                }
            },
            () => { console.log("Upload successfully"); getDownloadURL(uploadTask.snapshot.ref).then((fileDownloadUrl) => { resolve({ code: 200, data: fileDownloadUrl }); }); }
        );
    });
};
export const uploadFileToStorageWithProgress = async ({ path, file, onProgress }:IuploadFileToStorageWithProgress): Promise<Res<string[]>> => {
    const storage = getStorage();
    return new Promise((resolve, reject) => { 
        const storageRef = stRef(storage, path);
        const uploadTask = uploadBytesResumable( storageRef, file, metadatas["png"] );
        uploadTask.on( "state_changed", 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress(snapshot.state, progress);
            },
            (error) => {
                switch (error.code) {
                    case "storage/unauthorized": reject({ error: 500, errorMsg: "User doesn't have permission to access the object when uploading general file", }); break;
                    case "storage/canceled": reject({ error: 500, errorMsg: "User canceled the upload when uploading general file", }); break;
                    case "storage/unknown": reject({ error: 500, errorMsg: "Unknown error occurred when uploading general file", }); break;
                }
            },
            () => { getDownloadURL(uploadTask.snapshot.ref).then((fileDownloadUrl) => { resolve({ code: 200, data: [ uploadTask.snapshot.ref.name, fileDownloadUrl] }); }); }
        );
    });
};
export const fileRemoveFromStorage = async(path:string):Promise<Res<string>> => {
    const storage = getStorage();
    return new Promise((resolve, reject) => { 
        const deleteRef = stRef(storage, path);
        deleteObject(deleteRef).then(() =>resolve({code:200, data:"Successful"})).catch((error) => resolve({code: 500, errorMsg: getError(error)}));
    });
}

export const uploadAllTypeFileToStorage = async ({ file, path, }: IuploadFileToStorage): Promise<Res<string>> => {
    const storage = getStorage();
    return new Promise((resolve, reject) => {
      const fileType = file.name.split(".").pop()?.toLowerCase() as keyof typeof metadatas;
  
      if (!metadatas[fileType]) {
        reject({ error: 400, errorMsg: "Invalid file type. Allowed types are PDF, Excel, CSV, Microsoft Word Docs, JPEG, PNG." });
        return;
      }
  
      const storageRef = stRef(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file, metadatas[fileType]);
  
      uploadTask.on("state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${snapshot.state}, ${progress}% done`);
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized": reject({ error: 500, errorMsg: "User doesn't have permission to access the object." }); break;
            case "storage/canceled": reject({ error: 500, errorMsg: "User canceled the upload." }); break;
            default: reject({ error: 500, errorMsg: "Unknown error occurred." }); break;
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((fileDownloadUrl) => {
            resolve({ code: 200, data: fileDownloadUrl });
          });
        }
      );
    });
  };