import { Stack } from "@mui/material";
import { ReactNode } from "react";
import Footer from "./component/Footer";
import Header from "./component/Header/header";
import styles from "./index.module.scss";

const LandingPage = ({ children }: { children: ReactNode}) => {
    return ( <Stack style={{height: '100vh', backgroundColor: styles.primary800}}> <Header/> {children} <Footer/> </Stack> );
}


export default LandingPage;