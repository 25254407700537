import { IconButton, SelectChangeEvent, Stack } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AlertDialogComponent, AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import TableAutoComplete from "../../../components/AutoComplete/TableAutoComplete";
import TableDropdown from "../../../components/CustomizedDropdown/TableDropdown";
import { CustomizedDropdown } from "../../../components/Inputs/Dropdown/CustomizedDropdown";
import { unlinkAlumni, updateSpecificEngagement } from "../../../firebase/engagementApis";
import { PermissionState } from "../../../firebase/types-organisations";
import constants from "../../../helpers/constants";
import { mergeAndSortTypes } from "../../../helpers/mergeTypes";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { FilterType } from "../EngagementTableComponents/SearchTable";
import styles from "./index.module.scss";

interface AlumniProfileBarProps {
    orgId: string;
    alumniId: string;
}
export interface AlumniEngagementDetails {
    id: string | undefined;
    title: string | undefined;
    date: number | undefined;
    status: string;
    involvement: string;
}
// Define the status options
export const AlumniEngagementStatusFilter = [
    { value: 'all', label: 'All' },
    { value: 'invited', label: 'Invited' },
    { value: 'accepted', label: 'Accepted Invitation' },
    { value: 'declined', label: 'Declined Invitation' },
    { value: 'attended', label: 'Attended' },
    { value: 'did_not_attend', label: 'Did Not Attend' },
    { value: 'none', label: '-' }
];

// Define the status options
export const AlumniEngagementStatusOptions = [
    { value: 'none', label: '-' },
    { value: 'invited', label: 'Invited' },
    { value: 'accepted', label: 'Accepted Invitation' },
    { value: 'declined', label: 'Declined Invitation' },
    { value: 'attended', label: 'Attended' },
    { value: 'did_not_attend', label: 'Did Not Attend' },
];

function AlumniProfileBar({ orgId, alumniId }: AlumniProfileBarProps) {
    const alumniData = useSelector((state: RootState) => {
        // First, try to find the alumni with the given ID in the alumniList
        const alumni = state.alumniDataState.alumniList.find(alumni => alumni.objectID === alumniId);
    
        // If not found in alumniList, search in networkList
        return alumni || state.networkDataState.networkList.find(network => network.objectID === alumniId);
    });
    const [searchInput, setSearchInput] = useState("");
    const columnHelper = createColumnHelper<AlumniEngagementDetails>();
    const [selectedToDelete, setSelectedToDelete] = useState<AlumniEngagementDetails | null>(null);
    const [isEmptyState, setIsEmptyState] = useState(true);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [organisationEngagementInvolvement, setOrganisationEngagementInvolvement] = useState<string[]>(['Coach', 'Placement Provider', 'Speaker', 'Volunteer']);
    // Assuming `filfilteredEngagements` and `setRelatedEngagement` are already defined in your component
    const [originalStatuses, setOriginalStatuses] = useState<{ [key: string]: string }>({});
    const [filterPlannerType, setFilterPlannerType] = useState<FilterType>({ value: "all", hasTouched: false });
    // Selector to get engagement types from Redux
    const engagementInvolvements = useSelector((state: RootState) => state.organisation.organisation?.engagementInvolvements);

    const engagements = useSelector((state: RootState) => state.engagementState.engagements);
    const engagementPermissions = useSelector((state: RootState) => state.globalState.permissions?.engagement);
    const [relatedEngagements, setRelatedEngagements] = useState<AlumniEngagementDetails[]>([]);
    const navigate = useNavigate();


    useEffect(() => {
        // Filter engagements based on the selected filter type and the alumni ID
        const filteredEngagements = engagements.filter(engagement =>
            engagement.linkedAlumnis?.some(alumni => alumni.alumniId === alumniId) &&
            (filterPlannerType.value === "all" || // If filter is set to 'none', show all
                engagement.linkedAlumnis?.find(alumni => alumni.alumniId === alumniId)?.status === filterPlannerType.value) // Or match the filter status
        ).map(engagement => {
            const linkedAlumni = engagement.linkedAlumnis?.find(alumni => alumni.alumniId === alumniId);
            return {
                id: engagement.id,
                title: engagement.title,
                date: engagement.start_time, // Assuming start_time is a timestamp
                status: linkedAlumni?.status || "-",
                involvement: linkedAlumni?.involvement || "-"
            };
        });

        setRelatedEngagements(filteredEngagements);
    }, [engagements, alumniId, filterPlannerType.value]); // Including filterPlannerType.value in the dependency array


    const handleStatusChange = async (event: SelectChangeEvent<string>, engagementId: string, currentInvolvement: string) => {
        const newStatus = event.target.value;
        try {
            const response = await updateSpecificEngagement(orgId, engagementId, alumniId, { status: newStatus, involvement: currentInvolvement });
            console.log("Status Update Success:", response);
            // Optionally trigger state updates or notifications here
        } catch (error) {
            console.error("Failed to update engagement status:", error);
            alert(`Failed to update engagement status`); // Display an alert message
        }
    };

    const handleInvolvementChange = async (newValue: string, engagementId: string, currentStatus: string) => {
        try {
            const response = await updateSpecificEngagement(orgId, engagementId, alumniId, { status: currentStatus, involvement: newValue });
            console.log("Involvement Update Success:", response);
            // Optionally trigger state updates or notifications here
        } catch (error) {
            console.error("Failed to update engagement involvement:", error);
            alert(`Failed to update engagement involvement`); // Display an alert message
        }
    };


    const handleOpenConfirm = (engagement: AlumniEngagementDetails) => {
        // Set the currently selected engagement for potential deletion
        setSelectedToDelete(engagement);
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = async () => {
        if (selectedToDelete) {
            try {
                const response = await unlinkAlumni(orgId, selectedToDelete.id as string, alumniId);
                console.log("Unlink Success:", response);
                setIsConfirmOpen(false);
                setIsSuccessOpen(true);
                // Optionally, refresh or update local state to reflect the change
            } catch (error) {
                console.error("Failed to unlink alumni:", error);
                // Handle errors, such as displaying an error message to the user
            }
        }
    };

    const handleCancelConfirm = () => {
        setIsConfirmOpen(false);
        setSelectedToDelete(null);
    };

    const handleCloseSuccess = () => {
        setIsSuccessOpen(false);
        setSelectedToDelete(null);
    };

    const formatDate = (dateString: number) => {

        const date = dayjs(dateString);  // Convert milliseconds directly
        const today = dayjs();
        if (date.isSame(today, 'day')) {
            return 'Today';
        } else {
            return date.format('D MMM YY');
        }
    };


    const viewerColumns = [
        columnHelper.accessor('title', { cell: info => info.getValue(), header: "Engagement" }),
        columnHelper.accessor('status', { cell: info => info.getValue(), header: "Status" }),
        columnHelper.accessor('involvement', { cell: info => info.getValue(), header: "Involvement" }),
        columnHelper.accessor('date', { cell: info => info.getValue(), header: "Date" }),
        columnHelper.accessor('id', { cell: info => info.getValue(), header: "" }),

    ];

    const table = useReactTable({
        data: relatedEngagements,
        columns: viewerColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowId: (row, index) => row.id || `row-${index}`,
        state: {
            pagination: {
                pageSize: relatedEngagements.length,
                pageIndex: 0,
            },
        },
    });


    useEffect(() => {
        // Function to merge and sort engagement types
        function updateEngagementInvolvements(types: string[]) {
            const allTypes = mergeAndSortTypes(organisationEngagementInvolvement, types);
            setOrganisationEngagementInvolvement(allTypes);
        }

        if (engagementInvolvements) {
            updateEngagementInvolvements(engagementInvolvements);
        }
    }, [engagementInvolvements]);

    return (
        <>
            <AlertDialogComponent
                title="Unlink Person"
                subTitle="You can re-link this person if you change your mind"
                severity="primary"
                message={`You are about to unlink the person <b style='font-weight:'bold'>${alumniData?.name}</b> from the engagement: <b style='font-weight:'bold'>${selectedToDelete?.title}</b>. Do you wish to continue?`}
                visible={isConfirmOpen}
                primaryButtonTitle="Yes, Continue"
                primaryButtonStyle={{ backgroundColor: "#549189", color: "white" }} // Modify as per your theme
                primaryButtonClose={handleDeleteConfirmed}
                secondaryButtonTitle="Cancel"
                secondaryButtonClose={handleCancelConfirm}
            />
            <AlertDialogSimpleComponent
                visible={isSuccessOpen}
                message={`<b style='font-weight:'bold'>${alumniData?.name}</b>  has successfully been unlinked from the engagement: <b style='font-weight:'bold'>${selectedToDelete?.title}</b>.`}
                primaryButtonClose={() => {
                    handleCloseSuccess();
                }}
                primaryButtonTitle="Close"
            />
            <Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>

                <Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>
                    <CustomizedDropdown
                        defaultValue={"all"}
                        valueCheck={filterPlannerType.value}
                        containerStyle={{ width: pxToRem(200), color: "#A3A3A3" }}
                        fontSize={constants.addAlumniFontSize}
                        datas={AlumniEngagementStatusFilter}
                        value={filterPlannerType.value}
                        onChange={(e) => {
                            const newValue = e.target.value as string;
                            setFilterPlannerType({ value: newValue, hasTouched: true });
                        }}
                    />
                </Stack>
            </Stack>
            <Stack direction={"row"} className={styles.buttonBarWrapper} spacing={2}>
                <small>Linked Engagements</small>
            </Stack>
            <Stack marginTop={pxToRem(24)}>


                <div>
                    <Stack spacing={0} className={styles.tableWrapper}>
                        <table className={styles.engagement_table}>
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id} style={{ textAlign: 'left' }}>
                                                <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                                    <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
                                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                                    </Stack>
                                                    {(header.column.id === 'status' || header.column.id === 'name' || header.column.id === 'involvement' || header.column.id === 'date' || header.column.id === 'title') &&
                                                        (
                                                            <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                                <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                                <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                            </Stack>
                                                        )}
                                                </Stack>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {relatedEngagements.length > 0 ? (

                                    table.getRowModel().rows.map(row => (
                                        <tr key={row.id} onClick={() => {
                                            if (engagementPermissions && engagementPermissions !== PermissionState.NO_ACCESS) {
                                                navigate(`/dashboard/organisation/${orgId}/engagement/${row.original.id}`);
                                            } else {
                                                alert('You do not have permission to view this item.');
                                            }
                                        }}className={styles.normalRow}>
                                            {
                                                row.getVisibleCells().map(cell => {
                                                    let cellJSX: JSX.Element | undefined = undefined;
                                                    if (cell.column.id === "title") {
                                                        cellJSX = (
                                                            <Stack key={cell.id} minWidth={pxToRem(300)}>
                                                                <small>{row.original.title}</small>
                                                            </Stack>
                                                        );
                                                    }
                                                    else if (cell.column.id === "status") {
                                                        cellJSX = (
                                                            <Stack width={pxToRem(180)} onClick={(e) => e.stopPropagation()}>
                                                                {(!engagementPermissions || engagementPermissions === "EDITOR") ?
                                                                    <TableDropdown
                                                                        defaultValue={row.original.status || '-'}
                                                                        options={AlumniEngagementStatusOptions}
                                                                        onChange={(event) => handleStatusChange(event, row.original.id as string, row.original.involvement)}
                                                                    /> :
                                                                    <small>{row.original.status || '-'}</small>
                                                                }
                                                            </Stack>
                                                        );
                                                    }

                                                    else if (cell.column.id === "involvement") {
                                                        cellJSX =
                                                            <Stack width={pxToRem(180)} onClick={(e) => e.stopPropagation()}>
                                                                {(!engagementPermissions || engagementPermissions === "EDITOR") ?
                                                                    <TableAutoComplete
                                                                        defaultValue={row.original.involvement || '-'}
                                                                        options={organisationEngagementInvolvement}
                                                                        onValueChange={(newValue) => handleInvolvementChange(newValue, row.original.id as string, row.original.status)}
                                                                    /> :
                                                                    <small>{row.original.involvement || '-'}</small>
                                                                }
                                                            </Stack>

                                                    }
                                                    else if (cell.column.id === 'date') {
                                                        cellJSX = <small >
                                                            {row.original.date ? formatDate(row.original.date as number) : ""}
                                                        </small>

                                                    }

                                                    else if (cell.column.id === 'id') {
                                                        cellJSX =
                                                        <Stack onClick={(e) => e.stopPropagation()} alignItems={'center'}>
                                                            <IconButton onClick={() => handleOpenConfirm(row.original)} disabled={!(!engagementPermissions || engagementPermissions === "EDITOR")}>
                                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </IconButton>
                                                        </Stack>
                                                    }
                                                    return (<td key={cell.id}> {cellJSX} </td>)
                                                })
                                            }
                                        </tr>
                                    ))


                                ) : (
                                    // Render a single row with a message or a sample row when there are no alumni
                                    <tr>
                                        <td colSpan={viewerColumns.length} style={{ textAlign: 'start', padding: '20px' }}>
                                            <small style={{ color: "#737373" }}>Engagements linked to this alumni will appear here</small>
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </Stack>
                </div>
            </Stack>
        </>
    );
}

export default AlumniProfileBar;
