import { memo, useState } from "react";
import { Chip, IconButton, Stack, styled } from "@mui/material";
import CustomizedModal from "../../../components/CustomizedModal";
import styles from "./../index.module.scss";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import TagInput from '../../../components/TagComponent/index';
import { createSkill } from "../../../firebase/networkApis";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import Line from "../../../components/Line/Line";
import { ITabInterface } from "./TabInterfaces";
import pxToRem from "../../../helpers/pxToRem";
import AlertComponent from "../../../components/AlertComponent";
import { useSelector } from "react-redux";
import { PermissionState } from "../../../firebase/types-organisations";
import { RootState } from "../../../redux";

export type TSkill = string;
const SkillTab = ({orgId, orgName, networkId, skillList}: ITabInterface) => {
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isSkillModal, setIsSkillModal] = useState(false);
    const ListItem = styled('li')(({ theme }) => ({ margin: theme.spacing(0.5) }));
    const [tags, setTags] = useState<string[]>([]);

    const networkPermission = useSelector((state:RootState) => state.globalState.permissions?.network);

    const SkillChips = ({tSkillArr}: { tSkillArr: TSkill[] }) => {
        const onDelete = (id:string) => { deleteData(id); }
        return (
            <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', listStyle: 'none', width:"100%" }}>
                {tSkillArr.map((skill, index) => {
                    return ( <ListItem style={{margin:0}} key={index}><Chip style={{borderRadius:pxToRem(100),  margin:`${pxToRem(12)} ${pxToRem(12)} ${pxToRem(12)} 0`, paddingLeft:pxToRem(4), paddingRight:pxToRem(4), height:pxToRem(36)}} label={skill} onDelete={(!networkPermission || networkPermission === PermissionState.EDITOR) ? () => onDelete(skill) : undefined}/> </ListItem> );
                })}
            </ul>
        );
    }

    const addData = async() => {
        if (!orgId || !networkId || tags.length === 0) return;
        const singleTag = tags.length === 1 ? tags[0] : null;
        if (singleTag && skillList && skillList.includes(singleTag)) {
            setErrorMsg("Item already exists in our database, please add new tags.");
            return;
        }
        setLoadingStatus(true);
        setLoadingStatusStr("Saving..");
        const res = await createSkill(orgId, networkId, tags);
        if (res.code === 500) { setErrorMsg("Errors: Skill create."); } 
        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsSkillModal(false);
    }

    const deleteData = async(currentDeleteId: string) => {
        if (!orgId || !networkId || currentDeleteId === undefined) return;
        try{ 
            const docRef = doc(db, "organisations", orgId, "network", networkId);
            await updateDoc(docRef, { skills: arrayRemove(currentDeleteId)});
        }
        catch(ex){ setErrorMsg("Errors: Skill delete."); console.log(ex); }
    }

    return(
        <Stack>
            <AlertComponent visible={errorMsg !== ""} title="Error" message={errorMsg} handleClose={()=>setErrorMsg("")}/>
            <CustomizedModal width={660} open={isSkillModal} handleClose={() => {}} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack direction={"row"} spacing={1.5} style={{ marginTop: 20}} className={styles.network_details_modalHeaderTitleWrapper}>
                        <div className={styles.network_details_iconWithCircleWrapper}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(40)} height={pxToRem(40)} viewBox="0 0 40 40" fill="none"> <path d="M20 29L19.8999 28.8499C19.2053 27.808 18.858 27.287 18.3991 26.9098C17.9929 26.5759 17.5248 26.3254 17.0216 26.1726C16.4533 26 15.8271 26 14.5748 26H13.2C12.0799 26 11.5198 26 11.092 25.782C10.7157 25.5903 10.4097 25.2843 10.218 24.908C10 24.4802 10 23.9201 10 22.8V14.2C10 13.0799 10 12.5198 10.218 12.092C10.4097 11.7157 10.7157 11.4097 11.092 11.218C11.5198 11 12.0799 11 13.2 11H13.6C15.8402 11 16.9603 11 17.816 11.436C18.5686 11.8195 19.1805 12.4314 19.564 13.184C20 14.0397 20 15.1598 20 17.4M20 29V17.4M20 29L20.1001 28.8499C20.7947 27.808 21.142 27.287 21.6009 26.9098C22.0071 26.5759 22.4752 26.3254 22.9784 26.1726C23.5467 26 24.1729 26 25.4252 26H26.8C27.9201 26 28.4802 26 28.908 25.782C29.2843 25.5903 29.5903 25.2843 29.782 24.908C30 24.4802 30 23.9201 30 22.8V14.2C30 13.0799 30 12.5198 29.782 12.092C29.5903 11.7157 29.2843 11.4097 28.908 11.218C28.4802 11 27.9201 11 26.8 11H26.4C24.1598 11 23.0397 11 22.184 11.436C21.4314 11.8195 20.8195 12.4314 20.436 13.184C20 14.0397 20 15.1598 20 17.4" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round"/> </svg> </div>
                        <div style={{flexGrow: 1}}>
                            <label className={styles.network_details_modalHeaderTitle}>Add New Skills</label> 
                        </div>
                    </Stack>
                    <Stack className={styles.popupContent} style={{ paddingTop: pxToRem(16)}}>
                        <Stack style={{marginTop:20}}> <TagInput title="Skills" tags={tags} setTags={setTags}/> </Stack>
                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: pxToRem(12), marginTop:pxToRem(40)}}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setTags([]); setIsSkillModal(false); }}>Cancel</SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} onClick={() => addData()}>Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack>
                <table className={styles.network_details_table}>
                    <thead>
                        <tr>
                        <td style={{ flex: 2, backgroundColor: "#F9F9FA", fontWeight: 400}}>Skills</td>
                            <td style={{ flex: 1, backgroundColor: "#F9F9FA" }}>
                                <Stack style={{ flex: 1 }} direction="row-reverse" spacing={2}>
                                    {/* <IconButton><svg width="4" height="20" viewBox="0 0 4 20" fill="none"><circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#737373" /> <circle cx="2" cy="10" r="2" transform="rotate(90 2 10)" fill="#737373" /> <circle cx="2" cy="18" r="2" transform="rotate(90 2 18)" fill="#737373" /></svg></IconButton> */}              
                                    {(!networkPermission || networkPermission === PermissionState.EDITOR) && <IconButton className={styles.buttonHover} onClick={() => { setTags([]); setIsSkillModal(true)} }><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>}
                                </Stack>
                            </td>
                        </tr>
                    </thead>
                    {  skillList && skillList.length > 0 && <tbody><tr><td colSpan={3} style={{ padding: `${pxToRem(12)} ${pxToRem(56)} ${pxToRem(12)} ${pxToRem(56)}`}}><SkillChips tSkillArr={skillList}/></td></tr></tbody> }
                </table>
            </Stack>
            {
                skillList && skillList.length <= 0
                ?
                    (!networkPermission || networkPermission === PermissionState.EDITOR) ?
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">Add New</label>
                        <IconButton onClick={() => setIsSkillModal(true)}>
                            <svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </IconButton>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                    : 
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">No Record</label>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                :
                null
            }
        </Stack>
    );
}
export default memo(SkillTab);