import Stack from "@mui/material/Stack";
import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import MainSideBar from "./components/MainSideBar";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { DashboardRouters } from "../../routers/routes";
import RouteStateInitializer from "../../middleware/RouteStateInitializer";
import { LoadingFallbackComponent } from "../../components/FallbackComponent";
import { betaStateAction } from "../../redux/betaSlice";
import { BetaComingModal, BetaModal } from "../../components/CustomizedModal";
import { setLoginRoute } from '../../redux/userSlice';
import { auth } from "../../firebase";
import { orgCount } from "../../firebase/organisationApis";
import { URL_PARAMS } from "../../routers/shared";
import ListenerComponent from "../../HOC/ListenerComponent";
import ListenerComponent2 from "../../HOC/ListenerComponent/ListenerComponent2";
import PermissionRoute from "../../HOC/PermissionRoute";

const DashboardContainerPage = () => {
    const isPageLoading = useSelector((rootState: RootState) => rootState.pageStatus.isLoading);
    const betaReducer = useSelector((rootState: RootState) => rootState.betaReducer);
    const orgState = useSelector((rootState: RootState) => rootState.organisation.orgState);
    const loginRoute = useSelector((state: RootState) => state.user.loginRoute);
    const organisationList = useSelector((rootState:RootState) => rootState.organisation.organisationList)


    const [loginLoading, setLoginLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        /// Redirect user to a organisation if after login and only 1 organisation
        console.log("Checking for num of orgs");
        checkFirstLogin();
    }, [auth.currentUser?.uid])

    const checkFirstLogin = async () => {
        setLoginLoading(true);
        if(loginRoute && auth.currentUser?.uid) {
            const res = await orgCount(auth.currentUser.uid);
            if(res.code === 200) {
                if (res.data.length=== 1) {
                    navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${res.data[0].id}`);
                } 
                dispatch(setLoginRoute(false));
            }
        } 
        setLoginLoading(false);
    }

    return (
        <ListenerComponent2>
            <ListenerComponent fallback={<LoadingFallbackComponent />}>
                {/* <RouteStateInitializer fallback={<LoadingFallbackComponent />}> */}
                    <Stack className={styles.dashboard}>
                        { loginRoute || isPageLoading ? <div className={styles.loadingOverlay}><LoadingSpinner/></div> : null }
                        <BetaModal open={betaReducer.isBetaOpen ?? false} handleClose={()=>{ dispatch(betaStateAction.setBetaState(false)) }} orgState={orgState} children={<></>}/>
                        <BetaComingModal title={betaReducer.title??""} subTitle={betaReducer.subTitle??""} open={betaReducer.isComingSoonOpen ?? true} handleClose={()=>{ dispatch(betaStateAction.closeComingSoonModal())}} children={<></>}/>
                        <MainSideBar />
                        <Stack className={styles.pannelWrapper}>
                            <Stack className={styles.panel}>   
                                <Routes> 
                                    { Object.entries(DashboardRouters).map(([key, router], index) => <Route key={index} path={router.path} element={router.element} />) }
                                </Routes>
                            </Stack>
                        </Stack>
                    </Stack>
                {/* </RouteStateInitializer> */}
            </ListenerComponent>
        </ListenerComponent2>
    );
};

export default DashboardContainerPage;