import { eachWeekOfInterval, startOfMonth, endOfMonth, getISOWeek, startOfISOWeek, getMonth, addWeeks, getISOWeeksInYear, getISOWeekYear } from 'date-fns';
import WeeklyGoals from './components/WeeklyGoals';

export const getCurrentWeekNumber = (): number => {
    const today = new Date();
    const currentWeekNumber = getISOWeek(today);
    return currentWeekNumber;
  }

function getCustomWeek(date: Date): number {
    const isoWeek = getISOWeek(date);
    const year = date.getFullYear();

    // Get the ISO week number of December 31st of the current year
    const dec31 = new Date(year, 11, 31);
    const dec31ISOWeek = getISOWeek(dec31);

    // Check if the date is in the first week of the next year according to ISO weeks
    if (isoWeek === 1 && date.getMonth() === 11) {
        return dec31ISOWeek + 52; // Return the last ISO week number of the current year plus 1
    }

    return isoWeek;
}

export const getWeekNumbersOfMonth = (year: number, month: number): number[] => {
    const start = startOfMonth(new Date(year, month - 1));
    const end = endOfMonth(new Date(year, month - 1));
    const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 1 }); // Week starts on Monday
    return weeks.filter(w => w.getMonth() === month-1).map(weekStart => getCustomWeek(weekStart));
}

export const getMonthFromWeekNumber = (year: number, weekNumber: number): number => {
    // Get the start date of the given ISO week
    const startOfWeekDate = startOfISOWeek(new Date(year, 0, 1 + (weekNumber - 1) * 7));

    // Get the month (0-indexed, so add 1 for 1-indexed month)
    const month = getMonth(startOfWeekDate) + 1;

    return month;
}

export const getStartDateOfWeek = (year: number, weekNumber: number): Date => {
    // Get the start of the first ISO week of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const firstISOWeekStart = startOfISOWeek(firstDayOfYear);

    // Add the required number of weeks to get the start date of the given week number
    const startDateOfWeek = addWeeks(firstISOWeekStart, weekNumber - 1);

    return startDateOfWeek;
}