import { Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPath from "../../assets/images/logo/logo512.png";
import pxToRem from '../../helpers/pxToRem';
import PaymentPlanPopup from '../../pages/BillingPage/components/paymentPlan/PaymentPlan';
import CustomModal from '../../pages/SelfRegistrationFormsPage/components/modals/CustomModal';
import { URL_PARAMS } from '../../routers/shared';
import styles from './index.module.scss';

interface FreeTrialPopupProps {
  organizationName: string;
  startTime: number; // Assuming startTime is a timestamp
  endTime: number;
  orgId: string;
  orgState: string;
  userId: string;
  open: boolean;
  onClose: () => void;
}

//Calculate the trail date left with the start date, not use anymore

// export const calculateDaysLeft = (startTime: number, orgState?: string) => {
//   const now = new Date().getTime();
//   const start = new Date(startTime).getTime(); // Convert startTime to Date object
//   let trialEnd;

//   if (orgState === "REGULAR") {
//     trialEnd = start + (14 * 24 * 60 * 60 * 1000); // 14 days in milliseconds
//   } else if (orgState === "EARLY ACCESS") {
//     const earlyAccessEnd = new Date('July 1, 2024').getTime();
//     trialEnd = earlyAccessEnd;
//   } else if (orgState === "BETA") {
//     const betaEnd = new Date('December 31, 2024').getTime();
//     trialEnd = betaEnd;
//   } else {
//     trialEnd = start + (14 * 24 * 60 * 60 * 1000); // Default to 14 days
//   }

//   const daysLeft = Math.ceil((trialEnd - now) / (24 * 60 * 60 * 1000));
//   return daysLeft >= 0 ? daysLeft : -1; // Ensure days left is not negative or expired
// };

export const calculateDaysLeftByEndDate = (EndDate: number) => {
  const now = new Date().getTime(); // Current time in milliseconds

  const daysLeft = Math.ceil((EndDate - now) / (24 * 60 * 60 * 1000)); // Calculate the difference in days
  return daysLeft >= 0 ? daysLeft : -1; // Ensure days left is not negative or expired
};

const FreeTrialPopup: React.FC<FreeTrialPopupProps> = ({ organizationName, startTime, endTime, orgId, orgState, userId, open, onClose }) => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    onClose();
  };
  // Calculate days left


  //const daysLeft = calculateDaysLeft(startTime, orgState);
  const daysLeft = calculateDaysLeftByEndDate(endTime);

  useEffect(() => {
    setIsVisible(false);
    const shownPopups = JSON.parse(localStorage.getItem('shownFreeTrialPopups') || '{}');
    const currentDate = new Date().toDateString(); // Get current date as a string
  
    // If the popup for this organization has not been shown today
    if (!shownPopups[orgId] || shownPopups[orgId] !== currentDate) {
      setIsVisible(true);
      shownPopups[orgId] = currentDate; // Store the current date when showing the popup
      localStorage.setItem('shownFreeTrialPopups', JSON.stringify(shownPopups));
    }
  }, [orgId, open]);  

  return (
    <>
      {isVisible && (
        <>
          {daysLeft === 14 ? (
            <CustomModal open={open} handleClose={() => { }} width={800}>
              <div className={styles.widePopup}>

                <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </IconButton>
                <div className={styles.widePopupTop}>
                  <div className={styles.logo}>
                    {/* <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
               <circle cx="64" cy="64" r="64" fill="white" />
             </svg> */}
                    <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <clipPath id="circleView">
                          <circle cx="64" cy="64" r="64" />
                        </clipPath>
                      </defs>
                      <circle cx="64" cy="64" r="64" fill="white" />
                      <image
                        clipPath="url(#circleView)"
                        href={LogoPath}
                        x='12' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                        y='12' // Same calculation for y
                        height='102.4'
                        width='102.4'
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.widePopupBottom}>
                  <div className={styles.titleContainer}>
                    <div className={styles.wideTitleText}>
                      Welcome to {organizationName}'s 14-Day Free Trial!
                    </div>

                  </div>
                  <div className={`${styles.divider} horizontalDivider`}></div>
                  <div className={styles.inlineText}>

                    We'll send you a reminder 7-days before your trial ends. To avoid being locked out from your data, speak to your organisation's business manager about subscribing to one of our payment plans today. You won't be charged until your free trial is over.
                  </div>
                  <Button className={styles.selectPlanButton} onClick={() => { onClose(); setIsPopupVisible(true); }}>Select a Payment Plan</Button>
                </div>
              </div>
            </CustomModal>
          ) : (
            <CustomModal open={open} handleClose={() => { }} width={600}>
              <div className={styles.popup}>
                {daysLeft !== -1 && (
                  <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  </IconButton>)}
                <div className={styles.popupTop}>
                  <div className={styles.logo}>
                    {/* <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="64" cy="64" r="64" fill="white" />
            </svg> */}
                    <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <clipPath id="circleView">
                          <circle cx="64" cy="64" r="64" />
                        </clipPath>
                      </defs>
                      <circle cx="64" cy="64" r="64" fill="white" />
                      <image
                        clipPath="url(#circleView)"
                        href={LogoPath}
                        x='12' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                        y='12' // Same calculation for y
                        height='102.4'
                        width='102.4'
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.popupBottom}>
                  {daysLeft === -1 ? (
                    <>
                      <div className={styles.titleText}>{organizationName}'s free trial has ended</div>
                      <div className={`${styles.divider} horizontalDivider`}></div>
                      <div className={styles.inlineText}>
                        Your data has securely been stored on Loop's platform. To continue managing your data on Loop, upgrade to a paid plan today.
                      </div>
                      <Button className={styles.selectPlanButton} onClick={() => setIsPopupVisible(true)}>
                        Select a Payment Plan
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className={styles.titleText}>
                        {orgState === 'BETA' && `${organizationName} is part of Loop's Beta Program.`}
                        {orgState === 'EARLY ACCESS' && `${organizationName} has Early Access privileges on Loop.`}
                        {orgState === 'REGULAR' && `${organizationName}'s free trial will end in ${daysLeft} days.`}
                      </div>
                      <div className={`${styles.divider} horizontalDivider`}></div>
                      <div className={styles.inlineText}>
                        {orgState === 'BETA' && 'Enjoy your free, unlimited access to Loop for the rest of 2024! If you have any comments or concerns please log it on our help desk.'}
                        {orgState === 'EARLY ACCESS' && 'Enjoy your free, unlimited access to Loop until 1 July 2024. If you have any comments or concerns please log it on our help desk.'}
                        {orgState === 'REGULAR' && 'Enjoying Loop? Upgrade to a paid plan today. You will not be charged until your trial ends.'}
                      </div>
                      {daysLeft <= 14 ? (
                        <Button className={styles.selectPlanButton} onClick={() => setIsPopupVisible(true)}>
                          Select a Payment Plan
                        </Button>) : (
                        <Button className={styles.selectPlanButton} onClick={() => window.open(`/${URL_PARAMS.PRICING}`, '_blank')}>
                          Explore Plans
                        </Button>
                      )}

                    </>
                  )}
                </div>
              </div>
            </CustomModal>
          )}

        </>
      )}
      <PaymentPlanPopup orgId={orgId} userId={userId} onClose={handleClosePopup} open={isPopupVisible} dayLeft={daysLeft} />
    </>
  );
};

export default FreeTrialPopup;