import {
  Autocomplete, Avatar, Box, IconButton, InputAdornment, SelectChangeEvent, Stack
} from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AlertDialogComponent, AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import TableAutoComplete from "../../../components/AutoComplete/TableAutoComplete";
import TableDropdown from "../../../components/CustomizedDropdown/TableDropdown";
import MultilineTextField from "../../../components/Inputs/TextFields/MultilineInputTextField";
import { updateLinkedAlumnis } from "../../../firebase/engagementApis";
import { Engagement } from "../../../firebase/types-engagement";
import { PermissionState } from "../../../firebase/types-organisations";
import { mergeAndSortTypes } from "../../../helpers/mergeTypes";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { combinedDataSelector } from "../../../redux/reselect";
import { AlumniTable } from "../../AlumniPage";
import { stringToColor } from "../../OrganisationPlannerPage/components/colorHelper";
import StatusRoundCheckbox from "../../OrganisationPlannerPage/components/RoundCheckbox/StatusCheckbox";
import { DisplayOptionDetails } from "../../OrganisationPlannerPage/components/searchAlumniField";
import styles from "./index.module.scss";

interface AlumniSearchBarProps {
  linkedAlumnis: LinkedAlumniData[];
  engagement: Engagement | null;
  orgId: string;
  engId: string;
}

export interface LinkedAlumniData extends AlumniTable {
  status: string;
  involvement: string;
}

export type LinkedAlumniList = {
  alumniID?: string,
  name?: string,
  emailPreferred?: string,
  status?: string,
  involvement?: string,
}

// Define the status options
export const AlumniEngagementStatusOptions = [
  { value: 'none', label: '-' },
  { value: 'invited', label: 'Invited' },
  { value: 'accepted', label: 'Accepted Invitation' },
  { value: 'declined', label: 'Declined Invitation' },
  { value: 'attended', label: 'Attended' },
  { value: 'did_not_attend', label: 'Did Not Attend' }
];

export const AlumniDataEngagementInsideTable = (name: string, email: string, photoUrl?: string, type?: string) => {
  // Determine the background color or image for the Avatar
  const avatarStyle = photoUrl === "grey"
    ? { width: pxToRem(48), height: pxToRem(48), backgroundColor: '#E5E5E5' }
    : { width: pxToRem(48), height: pxToRem(48), backgroundColor: stringToColor(name), backgroundImage: `url(${photoUrl})` };

  return (
    <Stack direction={"row"} spacing={2} style={{ paddingTop: pxToRem(5), paddingBottom: pxToRem(5), alignItems: 'center', minWidth: pxToRem(300) }}>
      <Stack>
        <Avatar src={photoUrl !== "grey" ? photoUrl : undefined} alt={name} style={avatarStyle}>
          {
            photoUrl === "grey" ? null : (
              `${name.split(" ")[0].charAt(0)}${name.split(" ")[1] ? name.split(" ")[1].charAt(0) : ''}`
            )
          }
        </Avatar>
      </Stack>
      <Stack direction={"column"}>
        <small className='regular'>{name}</small>
        <small className='regular' style={{ color: "#909090", display: 'flex', alignItems: 'flex-start' }}>
          {photoUrl === "grey" ? `${email ? email : 'no email'}` : `${type} - ${email ? email : 'no email'}`}
        </small>
      </Stack>
    </Stack>
  );
}

function AlumniSearchBar({ linkedAlumnis, orgId, engId, engagement }: AlumniSearchBarProps) {
  const combineData: AlumniTable[] = useSelector(combinedDataSelector);
  const [searchInput, setSearchInput] = useState("");
  const [selectedAlumni, setSelectedAlumni] = useState<LinkedAlumniData[]>(linkedAlumnis);
  const columnHelper = createColumnHelper<LinkedAlumniData>();
  const [selectedToDelete, setSelectedToDelete] = useState<LinkedAlumniData | null>(null);
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [organisationEngagementInvolvement, setOrganisationEngagementInvolvement] = useState<string[]>(['Coach', 'Placement Provider', 'Speaker', 'Volunteer']);
  // Assuming `selectedAlumni` and `setSelectedAlumni` are already defined in your component
  const [originalStatuses, setOriginalStatuses] = useState<{ [key: string]: string }>({});
  const engagementPermissions = useSelector((state: RootState) => state.globalState.permissions?.engagement);
  const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);
  const networkPermission = useSelector((state: RootState) => state.globalState.permissions?.network);

  // Selector to get engagement types from Redux
  const engagementInvolvements = useSelector((state: RootState) => state.organisation.organisation?.engagementInvolvements);


  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; alumniId: string } | null>(null);
  const isInitialMount = useRef(true);
  const prevAlumniRef = useRef<LinkedAlumniData[] | null>(null);
  const navigate = useNavigate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>, alumniId: string) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alumniId,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = (alumniToDelete: AlumniTable) => () => {
    const newSelectedAlumni = selectedAlumni.filter(
      (alumni) => alumni.objectID !== alumniToDelete.objectID
    );
    setSelectedAlumni(newSelectedAlumni);
  };

  // Adjust the function to accept both the alumniId and the newStatus directly
  const handleStatusChange = (event: SelectChangeEvent<string>, alumni: LinkedAlumniData) => {
    const newStatus = event.target.value;
    const updatedAlumni = selectedAlumni.map(alumniItem =>
      alumniItem.objectID === alumni.objectID ? { ...alumniItem, status: newStatus } : alumniItem
    );
    setSelectedAlumni(updatedAlumni);
  };

  // Modify to use a similar pattern as handleStatusChange
  const handleInvolvementChange = (newValue: string, alumni: LinkedAlumniData) => {
    const updatedAlumni = selectedAlumni.map(alumniItem =>
      alumniItem.objectID === alumni.objectID ? { ...alumniItem, involvement: newValue } : alumniItem
    );
    setSelectedAlumni(updatedAlumni);
  };

  const handleOpenConfirm = (alumni: LinkedAlumniData) => {
    setSelectedToDelete(alumni);
    setIsConfirmOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedToDelete) {
      const newSelectedAlumni = selectedAlumni.filter(
        (alumni) => alumni.objectID !== selectedToDelete.objectID
      );
      setSelectedAlumni(newSelectedAlumni);
      setIsConfirmOpen(false);
      setIsSuccessOpen(true); // Trigger success message
    }
  };

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
    setSelectedToDelete(null);
  };

  const handleCloseSuccess = () => {
    setIsSuccessOpen(false);
    setSelectedToDelete(null);
  };


  // Filtered alumni based on search input with simple fuzzy logic
  const filteredAlumni = combineData.filter(alumni => {
    const normalizedSearchInput = searchInput.toLowerCase().split(/\s+/);

    const containsAllWords = (str: string | undefined) => {
      const normalizedStr = str?.toLowerCase() || "";
      const result = normalizedSearchInput.every(word => normalizedStr.includes(word));
      return result;
    };

    // Debug each field specifically
    const isMatch = containsAllWords(alumni.title) ||
      containsAllWords(alumni.firstName) ||
      containsAllWords(alumni.lastName) ||
      containsAllWords(alumni.name) ||
      containsAllWords(alumni.emailPreferred) ||
      containsAllWords(alumni.currentEmployment) ||
      containsAllWords(alumni.educationDegree) ||
      containsAllWords(alumni.living) ||
      containsAllWords(alumni.peerYear?.toString());
    return isMatch;
  });

  const viewerColumns = [
    columnHelper.accessor('name', { cell: info => info.getValue(), header: "People" }),
    columnHelper.accessor('status', { cell: info => info.getValue(), header: "Status" }),
    columnHelper.accessor('involvement', { cell: info => info.getValue(), header: "Involvement" }),
    columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "" }),

  ];

  const emptyViewerColumns = [
    columnHelper.accessor('name', { cell: info => info.getValue(), header: "Linked People" }),
  ];

  const table = useReactTable({
    data: selectedAlumni,
    columns: selectedAlumni.length > 0 ? viewerColumns : emptyViewerColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row, index) => row.objectID || `row-${index}`,
    state: {
      pagination: {
        pageSize: selectedAlumni.length,
        pageIndex: 0,
      },
    },
  });


  const handleAllAttendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Save the current statuses only if marking all as attended
      const newOriginalStatuses = selectedAlumni.reduce((acc, alumni) => {
        if (alumni.objectID) { // Ensure that objectID is not undefined
          acc[alumni.objectID] = alumni.status;
        }
        return acc;
      }, {} as { [key: string]: string });

      setOriginalStatuses(newOriginalStatuses);
    }

    const updatedAlumni = selectedAlumni.map(alumni => ({
      ...alumni,
      status: isChecked ? 'attended' : (alumni.objectID && originalStatuses[alumni.objectID]) || 'none' // Use original status or 'none' if not set
    }));

    setSelectedAlumni(updatedAlumni);
  };
  // Initialize and respond to changes in linkedAlumnis
  useEffect(() => {
    setSelectedAlumni(linkedAlumnis);
  }, [linkedAlumnis]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      prevAlumniRef.current = selectedAlumni;
      return;
    }

    if (!isEqual(prevAlumniRef.current, selectedAlumni)) {
      updateLinkedAlumnis(orgId, engId, selectedAlumni)
        .then(response => {
          prevAlumniRef.current = selectedAlumni; // Update the ref only after updating the alumni
        })
        .catch(error => console.error('Failed to update linked alumni:', error));
    }
  }, [selectedAlumni, orgId, engId]);  // Include other dependencies as necessary

  useEffect(() => {
    // Function to merge and sort engagement types
    function updateEngagementInvolvements(types: string[]) {
      const allTypes = mergeAndSortTypes(organisationEngagementInvolvement, types);
      setOrganisationEngagementInvolvement(allTypes);
    }

    if (engagementInvolvements) {
      updateEngagementInvolvements(engagementInvolvements);
    }
  }, [engagementInvolvements]);

  return (
    <>
      <AlertDialogComponent
        title="Unlink Alumni"
        subTitle="You can re-link this alumni if you change your mind"
        severity="primary"
        message={`You are about to unlink the alumni <b style='font-weight:'bold'>${selectedToDelete?.name}</b> from the engagement: <b style='font-weight:'bold'>${engagement?.title}</b>. Do you wish to continue?`}
        visible={isConfirmOpen}
        primaryButtonTitle="Yes, Continue"
        primaryButtonStyle={{ backgroundColor: "#549189", color: "white" }} // Modify as per your theme
        primaryButtonClose={handleDeleteConfirmed}
        secondaryButtonTitle="Cancel"
        secondaryButtonClose={handleCancelConfirm}
      />
      <AlertDialogSimpleComponent
        visible={isSuccessOpen}
        message={`<b style='font-weight:'bold'>${selectedToDelete?.name}</b>  has successfully been unlinked from the engagement: <b style='font-weight:'bold'>${engagement?.title}</b>.`}
        primaryButtonClose={() => {
          handleCloseSuccess();
        }}
        primaryButtonTitle="Close"
      />
      <Box sx={{ width: '100%' }}>
        {(!engagementPermissions || engagementPermissions === "EDITOR") &&
          <Autocomplete
            multiple
            id="alumni-search-bar"
            options={filteredAlumni}
            getOptionLabel={(option) => `${option.name} - ${option.educationDegree}, ${option.peerYear}, ${option.objectID},  ${option.currentEmployment}, ${option.title}`}
            isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
            renderInput={(params) => (
              <MultilineTextField
                {...params}
                placeholder="Search alumni or network "
                onChange={handleSearchChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginLeft: pxToRem(16) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(26)} height={pxToRem(27)} viewBox="0 0 24 25" fill="none">
                        <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </InputAdornment>
                  ),
                  onKeyDown: (event) => {
                    // Block deletion of items when the input field is empty
                    if (event.key === 'Backspace' && params.inputProps.value === '') {
                      event.stopPropagation();
                    }
                  }
                }}
              />
            )}
            value={selectedAlumni}
            onChange={(event, newValue) => {
              // Convert AlumniTable[] to LinkedAlumni[]
              const updatedAlumni = newValue.map(alumni => {
                // Check if the alumni is already in selectedAlumni to retain the status and involvement if already set
                const existing = selectedAlumni.find(a => a.objectID === alumni.objectID);
                return {
                  ...alumni,
                  status: existing?.status || 'none',  // Default status if not already set
                  involvement: existing?.involvement || ''  // Default involvement if not already set
                };
              });

              setSelectedAlumni(updatedAlumni);
            }}
            renderOption={(props, option) => {
              const isSelected = selectedAlumni.some(alumni => alumni.objectID === option.objectID);
              return (
                <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: pxToRem(12),
                      marginBottom: pxToRem(12),
                      height: pxToRem(40)
                    }}>
                      <small>{option.name}</small>
                      <DisplayOptionDetails option={option} />
                    </Box>
                  </Box>
                  <small style={{ marginLeft: 'auto', color: "#737373" }}>{isSelected ? "Remove" : "Select"}</small>
                </li>
              )
            }}
          />}
        <Stack marginTop={(!engagementPermissions || engagementPermissions === "EDITOR") ? pxToRem(24) : 0} direction={'row'} spacing={2} alignItems={'center'}>
          <small>
            {`Linked People: ${selectedAlumni.length}`}
          </small>
          {(!engagementPermissions || engagementPermissions === "EDITOR") &&
            <Stack direction={'row'} alignItems={'center'}>
              <small>
                {'Mark all as attended '}
              </small>
              <StatusRoundCheckbox
                id="attended-check-box"
                checked={selectedAlumni.length > 0 && selectedAlumni.every(alumni => alumni.status === 'attended')}
                onChange={handleAllAttendChange}
              />
            </Stack>}
        </Stack>
        <Stack marginTop={pxToRem(24)}>

          <div>
            <Stack spacing={0} className={styles.tableWrapper}>
              <table className={styles.engagement_table}>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} style={{ textAlign: 'left' }}>
                          <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
                              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </Stack>
                            {(header.column.id === 'status' || header.column.id === 'name' || header.column.id === 'involvement') &&
                              (
                                <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                  <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                  <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                </Stack>
                              )}
                          </Stack>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {selectedAlumni.length > 0 ? (

                    table.getRowModel().rows.map(row => (
                      <tr key={row.id} onClick={() => {
                        if (row.original.type === 'Alumni' && alumniPermission && alumniPermission !== PermissionState.NO_ACCESS) {
                          navigate(`/dashboard/organisation/${orgId}/alumni/${row.original.objectID}`);
                        } else if (row.original.type === 'Network' && networkPermission && networkPermission !== PermissionState.NO_ACCESS) {
                          navigate(`/dashboard/organisation/${orgId}/network/${row.original.objectID}`);
                        } else {
                          alert('You do not have permission to view this item.');
                        }
                      }} className={styles.normalRow}>
                        {
                          row.getVisibleCells().map(cell => {
                            let cellJSX: JSX.Element | undefined = undefined;
                            if (cell.column.id === "name") {
                              cellJSX = (
                                <Stack key={cell.id}>
                                  {AlumniDataEngagementInsideTable(row.original.name ?? "", row.original.emailPreferred ?? "", row.original.photoUrl ?? "", row.original.type ?? "")}
                                </Stack>
                              );
                            }
                            else if (cell.column.id === "status") {
                              cellJSX = (
                                <Stack width={pxToRem(180)} onClick={(e) => e.stopPropagation()}>
                                  {(!engagementPermissions || engagementPermissions === "EDITOR") ?
                                    <TableDropdown
                                      defaultValue={row.original.status || '-'}
                                      options={AlumniEngagementStatusOptions}
                                      onChange={(event) => handleStatusChange(event, row.original)}
                                    /> :
                                    <small>{row.original.status || '-'}</small>
                                  }
                                </Stack>
                              );
                            }

                            else if (cell.column.id === "involvement") {
                              cellJSX =
                                <Stack width={pxToRem(180)} onClick={(e) => e.stopPropagation()}>
                                  {(!engagementPermissions || engagementPermissions === "EDITOR") ?
                                    <TableAutoComplete
                                      defaultValue={row.original.involvement || '-'}
                                      options={organisationEngagementInvolvement}
                                      onValueChange={(newValue) => handleInvolvementChange(newValue, row.original)}
                                    /> :
                                    <small>{row.original.involvement || '-'}</small>
                                  }
                                </Stack>
                            }

                            else if (cell.column.id === 'peerYear') {
                              cellJSX =
                                <Stack onClick={(e) => e.stopPropagation()} alignItems={'center'}>
                                  <IconButton onClick={() => handleOpenConfirm(row.original)} disabled={!(!engagementPermissions || engagementPermissions === "EDITOR")}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M12.9961 8V6M18.9961 24V26M7.99609 13H5.99609M23.9961 19H25.9961M8.91031 8.91421L7.49609 7.5M23.0819 23.0858L24.4961 24.5M15.9961 21.6569L13.8748 23.7782C12.3127 25.3403 9.78002 25.3403 8.21792 23.7782C6.65582 22.2161 6.65582 19.6834 8.21792 18.1213L10.3392 16M21.6529 16L23.7743 13.8787C25.3364 12.3166 25.3364 9.78392 23.7743 8.22183C22.2122 6.65973 19.6795 6.65973 18.1174 8.22183L15.9961 10.3431" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </IconButton>
                                </Stack>
                            }
                            return (<td key={cell.id}> {cellJSX} </td>)
                          })
                        }
                      </tr>
                    ))


                  ) : (
                    // Render a single row with a message or a sample row when there are no alumni
                    <tr>
                      <td colSpan={viewerColumns.length} style={{ textAlign: 'start', padding: '20px' }}>
                        <Stack >
                          {AlumniDataEngagementInsideTable("This engagement has not been linked to any peoplo ", "Linked alumni and network will appear here", "grey")}
                        </Stack>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </Stack>
          </div>
        </Stack>

      </Box>
    </>
  );
}

export default AlumniSearchBar;