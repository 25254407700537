import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { useMemo } from "react";
import styled from "styled-components";
import globalStyles from "../../../styles/_stylesParams.module.scss";
import styles from "./index.module.scss";
import pxToRem from "../../../helpers/pxToRem";

const OnboardingTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    // height: pxToRem(30),
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary400,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary400,
    },
  },
});

{
  /* <svg
  xmlns='http://www.w3.org/2000/svg'
  width='16'
  height='16'
  viewBox='0 0 16 16'
  fill='none'
>
  <path
    d='M13.3333 4L5.99996 11.3333L2.66663 8'
    stroke='white'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
</svg>; */
}

interface IOnboardingField extends StandardTextFieldProps {
  title?: string;
  adornment?: JSX.Element;
  isEndAdornment?: boolean;
  width?: string;
}

const OnboardingField = ({ title, adornment, isEndAdornment, width, ...rest }: IOnboardingField) => {
  const adornmentPosition = useMemo(() => {
    if (isEndAdornment) return "endAdornment";
    return "startAdornment";
  }, [isEndAdornment]);

  const inputProps = useMemo(() => {
    if (!adornment) return {};
    return {
      [adornmentPosition]: (
        <InputAdornment style={{cursor:'pointer'}} position={isEndAdornment ? "end" : "start"}>
          {adornment}
        </InputAdornment>
      ),
    };
  }, [adornmentPosition, adornment]);

  return (
    <Stack className={styles.stack} style={{width:width}}>
      {title && <p style={{color:styles.shades100, alignSelf:"flex-start"}} className='xsMarginBottom'>{title}</p>}
      {/* <OnboardingTextField size='small' InputProps={inputProps} {...rest} /> */}
      <OnboardingTextField InputProps={inputProps} inputProps={{
        // style: {
        //   fontSize: pxToRem(12),
        //   fontWeight: '400',
        //   lineHeight: pxToRem(18),
        //   letterSpacing: '-2%'
        // }
      }} style={{width:'100%'}} {...rest} />
    </Stack>
  );
};

export default OnboardingField;
