import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllEngagementsForOrg } from "../firebase/engagementApis";
import { Engagement } from "../firebase/types-engagement";

interface IEngagementStateSlice {
    engagements: Engagement[];
}

const INITIAL_STATE: IEngagementStateSlice = {
    engagements: []
}

export const engagementStateSlice = createSlice({
    name: "engagementState",
    initialState: INITIAL_STATE,
    reducers: {
        setEngagements: (state, action: PayloadAction<Engagement[]>) => {
            state.engagements = action.payload;
        },
        addEngagement: (state, action: PayloadAction<Engagement>) => {
            state.engagements.push(action.payload);
        },
        updateEngagement: (state, action: PayloadAction<Engagement>) => {
            const index = state.engagements.findIndex(eng => eng.id === action.payload.id);
            if (index !== -1) {
                state.engagements[index] = action.payload;
            }
        },
        removeEngagement: (state, action: PayloadAction<string>) => {
            state.engagements = state.engagements.filter(eng => eng.id !== action.payload);
        },
        resetEngagementState: (state) => {
            state.engagements = [];
        }
    }
});

export const {
    setEngagements,
    addEngagement,
    updateEngagement,
    removeEngagement,
    resetEngagementState
} = engagementStateSlice.actions;
export default engagementStateSlice.reducer;
