import React, { useEffect, useState } from 'react';
import styles from './PaymentPlan.module.scss'; // Your SCSS module file path
import { Button, Modal, Stack } from '@mui/material';
import pxToRem from '../../../../helpers/pxToRem';
import LogoPath from "../../../../assets/images/logo/logo512.png"
import dayjs from 'dayjs';
import { fetchInvitationsCount } from '../../../../firebase/userApis';
import { getAllAdminsAndUsers } from '../../../../firebase/organisationApis';


interface PaymentPlanPopupProps {
  trialData?: string;
  orgId: string;
  userId: string;
  open: boolean;
  dayLeft: number;
  onClose: () => void; // Function to call when closing the popup
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const PaymentPlanPopup: React.FC<PaymentPlanPopupProps> = ({ trialData, orgId, userId, open, onClose, dayLeft }) => {
  const nextBillingCycleStartDate = dayjs().add(dayLeft, 'day').format('DD MMMM YYYY');
  const [invitationCount, setInvitationCount] = useState<number>(0);
  const [admins, setAdmins] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (!orgId) return; // Ensure subscriptionId is present
    fetchAdminAndUsers(); // Function to fetch subscription details
    updateInvitationCount();
}, [orgId, open]); // Include reloadTrigger in the dependency array

  const fetchAdminAndUsers = async () => {
    setLoading(true);
    try {
        // Fetch admins and users
        const adminUserResponse = await getAllAdminsAndUsers(orgId);
        if (adminUserResponse.code === 200) {
            setAdmins(adminUserResponse.data.admins);
            setUsers(adminUserResponse.data.users);
        } else {
            throw new Error('Failed to fetch admins and users');
        }

        // Fetch invitation count
        const invitationResult = await fetchInvitationsCount(orgId);
        if (invitationResult.success) {
            setInvitationCount(invitationResult.count as number);
        } else {
            throw new Error('Failed to fetch invitation count');
        }
    } catch (err) {
        console.error("Error fetching data:", err);
    } finally {
        setLoading(false);
    }
};
  

  const updateInvitationCount = async () => {
    const result = await fetchInvitationsCount(orgId);
    if (result.success) {
        setInvitationCount(result.count as number);
    } else {
        // Handle the error scenario, maybe set some error state
        console.error("Failed to fetch invitation count");
    }
};

  const renderPricingTableScript = (dayLeft: number) => {
    switch(dayLeft) {
      case 14:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39OCRqU8nOaPNZJok1YL9V"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 13:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39PxRqU8nOaPNZWzITfkPj"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 12:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39SbRqU8nOaPNZRTfj7uDY"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 11:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39UARqU8nOaPNZ3daw1hVj"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 10: 
        return <stripe-pricing-table pricing-table-id="prctbl_1P39W2RqU8nOaPNZZdhs4GP5"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 9: 
        return <stripe-pricing-table pricing-table-id="prctbl_1P39XvRqU8nOaPNZag8jAmvN"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 8: 
        return <stripe-pricing-table pricing-table-id="prctbl_1P39aJRqU8nOaPNZxJ3AX0lp"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 7: 
        return <stripe-pricing-table pricing-table-id="prctbl_1P39bhRqU8nOaPNZiAE6PhKe"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 6: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1P39e4RqU8nOaPNZFGIeP2sH"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 5:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39feRqU8nOaPNZfNv72eps"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table> 
      case 4:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39hARqU8nOaPNZza0AHFvP"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 3: 
        return <stripe-pricing-table pricing-table-id="prctbl_1P39kqRqU8nOaPNZFKbKqJAS"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      case 2:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39miRqU8nOaPNZriZseC9l"
      publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
      client-reference-id={orgId}>
      </stripe-pricing-table>;
      case 1:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39oBRqU8nOaPNZfbvUuQWD"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      default:
        return <stripe-pricing-table pricing-table-id="prctbl_1P39M5RqU8nOaPNZW7AdpmNf"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
    }
  };


  const renderPricingTableScript_Live = (dayLeft: number) => {
    switch(dayLeft) {
      case 14:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLF1bRqU8nOaPNZH5xpOwwD"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 13:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLF45RqU8nOaPNZK8ITsH7S"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 12:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLF5yRqU8nOaPNZVgeuMwvG"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 11:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLF7gRqU8nOaPNZGRwhajOf"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 10: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLF9ARqU8nOaPNZI5s0DZEk"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 9: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFAzRqU8nOaPNZWOj7NyD5"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 8: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFCtRqU8nOaPNZIld4aaYn"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 7: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFE8RqU8nOaPNZ1smVaPUO"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 6: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1PLFFeRqU8nOaPNZ5UT5NT0N"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 5:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFHmRqU8nOaPNZlRewu5sP"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table> 
      case 4:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFJYRqU8nOaPNZbEwIkcV6"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 3: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFKzRqU8nOaPNZ3QtwIn5j"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      case 2:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFMORqU8nOaPNZGLNKylNN"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
      client-reference-id={orgId}>
      </stripe-pricing-table>;
      case 1:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFNiRqU8nOaPNZhK2OW17c"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      default:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLEyaRqU8nOaPNZJF8zSnwN"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
    }
  };

  const renderPremiumPricingTableScript = (dayLeft: number) => {
    switch(dayLeft) {
      case 14:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyMvRqU8nOaPNZNdzlumr3"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 13:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyLlRqU8nOaPNZen0PylRI"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 12:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyKlRqU8nOaPNZcuJHge5z"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 11:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyIyRqU8nOaPNZiL5McoMU"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 10: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyHlRqU8nOaPNZ4y6VM655"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 9: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1PDyGZRqU8nOaPNZ2jC3n3At"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 8: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyEsRqU8nOaPNZAFra8R9C"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 7: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1PDyDURqU8nOaPNZEgmjtG3t"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 6: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyBPRqU8nOaPNZ8VTBmhSi"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 5:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDy8sRqU8nOaPNZeR4P52jQ"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table> 
      case 4:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDy7WRqU8nOaPNZ7aXhR2Bo"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 3: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PDy52RqU8nOaPNZz8bmcJHy"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      case 2:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDy3QRqU8nOaPNZBOYQI2d0"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
      client-reference-id={orgId}>
      </stripe-pricing-table>;
      case 1:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDy1FRqU8nOaPNZAVtPBEwf"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      default:
        return <stripe-pricing-table pricing-table-id="prctbl_1PDyO5RqU8nOaPNZfXnQ7VyA"
        publishable-key="pk_test_51Ovul0RqU8nOaPNZAWn8HNAEg3UoDTew2SCUAoWoLk4L3WPEtmVOJc4PpfrBrYe5iCh2O23SGyec3iWR2mrZQBPj004dOyS6k5"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
    }
  };

  const renderPremiumPricingTableScript_Live = (dayLeft: number) => {
    switch(dayLeft) {
      case 14:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFWMRqU8nOaPNZcDqapfvj"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 13:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFXYRqU8nOaPNZgoHb3O6B"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 12:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFYfRqU8nOaPNZJlCPX7k7"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 11:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFZuRqU8nOaPNZJqZxIMPh"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 10: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFb7RqU8nOaPNZKKp7Ibwh"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 9: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1PLFc9RqU8nOaPNZsxuDk217"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 8: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFd5RqU8nOaPNZGEvaoiis"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 7: 
        return  <stripe-pricing-table pricing-table-id="prctbl_1PLFeBRqU8nOaPNZLJvLP3LP"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 6: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFf5RqU8nOaPNZn36i0oSR"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 5:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFgURqU8nOaPNZyog4n84P"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table> 
      case 4:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFheRqU8nOaPNZElkMHKJ9"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>
      case 3: 
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFiURqU8nOaPNZG8AolRmn"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      case 2:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFjoRqU8nOaPNZTo5LDT0g"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
      client-reference-id={orgId}>
      </stripe-pricing-table>;
      case 1:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFl8RqU8nOaPNZhevyOYJC"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
      default:
        return <stripe-pricing-table pricing-table-id="prctbl_1PLFV7RqU8nOaPNZ9WtNIHoC"
        publishable-key="pk_live_51Ovul0RqU8nOaPNZsrv57J5UamAVqcukOuw00G3nphCwGuYypzs1bdzw3Legu0XZUKG2bJitduwcpVUGn8wleb1H00N6sznvGZ"
        client-reference-id={orgId}>
        </stripe-pricing-table>;
    }
  };
  
  return (
    <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <div>
        <div className={styles.popupBackdrop}>
        </div>
        <div className={styles.popup}>
          <Button className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          </Button>
          <Stack className={styles.contentBox} direction={'column'}>
            <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
              <svg width={pxToRem(56)} height={pxToRem(56)} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="circleView">
                    <circle cx="28" cy="28" r="28" />
                  </clipPath>
                </defs>
                <circle cx="28.5" cy="28" r="27.5" fill="white" stroke="#76A8A1" />
                <image
                  clipPath="url(#circleView)"
                  href={LogoPath}
                  x='5' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                  y='5' // Same calculation for y
                  height='48'
                  width='48'
                />
              </svg>
              <h3 className={styles.head3}>Select a payment plan</h3>
            </Stack>
            <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
              <small className={styles.smallGray}>
                Learn more about our pricing
              </small>
              <a href="/pricing" target="_blank" rel="noopener noreferrer" className={styles.linkText}>here</a>
            </Stack>
            {trialData ? (
              <Stack className={styles.greenBox}>
                Speak to your Business Manager about subscribing to one of our payment plans today
              </Stack>
            ) : (
              <Stack className={styles.blackReminderBox}>
                Your plan will come into effect at the beginning of your next billing cycle, {nextBillingCycleStartDate}
              </Stack>
            )}
            {admins.includes(userId) ? (
              <Stack width={pxToRem(700)} style={{ borderRadius: pxToRem(8), height: pxToRem(300), marginTop: pxToRem(20) }}>
              <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
              {invitationCount + users.length > 3 ? renderPremiumPricingTableScript_Live(dayLeft) : renderPricingTableScript_Live(dayLeft)}
            </Stack>
            ): (
              <Stack className={styles.greenBox}>
              Speak to your Business Manager about subscribing to one of our payment plans today
            </Stack>
            )}
            
          </Stack>
        </div>
      </div>
    </Modal>

  );
};

export default PaymentPlanPopup;
