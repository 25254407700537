import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import LogoPath from "../../assets/images/logo/logo512.png";
import pxToRem from '../../helpers/pxToRem';
import PaymentPlanPopup from '../../pages/BillingPage/components/paymentPlan/PaymentPlan';
import CustomModal from '../../pages/SelfRegistrationFormsPage/components/modals/CustomModal';
import styles from './index.module.scss';

interface ExpiredPopupProps {
  organizationName: string;
  isTrialExpired: boolean; // true for trial expired, false for subscription expired
  orgId: string;
  userId: string;
  open: boolean;
  onClose: () => void;
}

const ExpiredPopup: React.FC<ExpiredPopupProps> = ({ organizationName, isTrialExpired, orgId, userId, open, onClose }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const popupContent = () => {
    if (isTrialExpired) {
      return (
        <>
          <div className={styles.titleText}>{organizationName}'s free trial has ended</div>
          <div className={`${styles.divider} horizontalDivider`}></div>
          <div className={styles.inlineText}>
          Your data has securely been stored on Loop's platform. To continue managing your data on Loop, upgrade to a paid plan today.
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.titleText}>{organizationName}'s subscription to Loop has expired</div>
          <div className={`${styles.divider} horizontalDivider`}></div>
          <div className={styles.inlineText}>
          Your data has securely been stored on Loop's platform. To continue managing your data on Loop, please renew your subscription.
          </div>
        </>
      );
    }
  };

  return (
    <>
      <CustomModal open={open} handleClose={onClose} width={600}>
        <div className={styles.popup}>
          <div className={styles.popupTop}>
          <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </IconButton>
          <div className={styles.logo}>
            {/* <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="64" cy="64" r="64" fill="white" />
            </svg> */}
            <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <clipPath id="circleView">
                  <circle cx="64" cy="64" r="64" />
                </clipPath>
              </defs>
              <circle cx="64" cy="64" r="64" fill="white" />
              <image
                clipPath="url(#circleView)"
                href={LogoPath}
                x='12' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                y='12' // Same calculation for y
                height='102.4'
                width='102.4'
              />
            </svg>
          </div>
          </div>
          <div className={styles.popupBottom}>
            {popupContent()}
            <Button className={styles.selectPlanButton} onClick={() => setIsPopupVisible(true)}>
              Select a Payment Plan
            </Button>
          </div>
        </div>
      </CustomModal>
      <PaymentPlanPopup orgId={orgId} userId={userId} onClose={handlePopupClose} open={isPopupVisible} dayLeft={0}/>
    </>
  );
};

export default ExpiredPopup;
