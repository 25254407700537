import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from '.';
import { ConnectionData } from '../pages/NetworkPage/NetworkConnectionPage/NetworkSearchTable';
import { useMemo } from 'react';
// Basic selectors to fetch parts of the state
const getNetworkList = (state: RootState) => state.networkDataState.networkList;
const getAlumniList = (state: RootState) => state.alumniDataState.alumniList;
const getEngagements = (state: RootState) => state.engagementState.engagements;
const getAlumniId = (state: RootState, alumniId: string) => alumniId;

export const getFilteredEngagements = createSelector(
    [getEngagements, getAlumniId],
    (engagements, alumniId) =>
        engagements.filter(engagement =>
            engagement.linkedAlumnis?.some(alumni => alumni.alumniId === alumniId)
        )
);

// Memoized selector for combined data
export const combinedDataSelector = createSelector(
    [getNetworkList, getAlumniList],
    (networkList, alumniList) => {
        const typedNetworks = networkList.map(network => ({
            ...network,
            type: 'Network'
        }));

        const typedAlumni = alumniList.map(alumni => ({
            ...alumni,
            type: 'Alumni'
        }));

        return [...typedAlumni, ...typedNetworks];
    }
);

// Memoized selector for combined data
export const connectionDataSelecor = createSelector(
    [getNetworkList, getAlumniList],
    (networkList, alumniList) => {
        return [...alumniList, ...networkList];
    }
);

// Memoized selectors to add type
const getNetworksWithType = createSelector([getNetworkList], (networkList) =>
    networkList.map(network => ({ ...network, type: 'Network' }))
  );
  
  const getAlumniWithType = createSelector([getAlumniList], (alumniList) =>
    alumniList.map(alumni => ({ ...alumni, type: 'Alumni' }))
  );
  
  // Combined and enriched data selector
  const getCombinedData = (linkedConnections: ConnectionData[]) => createSelector(
    [getNetworksWithType, getAlumniWithType],
    (networkList, alumniList) => {
      const getConnectionRelationship = (objectId: string) => {
        const linked = linkedConnections.find(link => link.objectId === objectId);
        return linked ? linked.relationship : '';
      };
  
      return [...alumniList, ...networkList].map(item => ({
        ...item,
        relationship: getConnectionRelationship(item.objectID as string)
      }));
    }
  );
  
  // Hook to use in components
  export const useCombinedData = (linkedConnections: ConnectionData[]) => {
    const combinedDataSelector = useMemo(() => getCombinedData(linkedConnections), [linkedConnections]);
    const combinedData = useSelector(combinedDataSelector);
    return combinedData;
  }

