import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface EngagementDetailsProps {
    type: string;
    location: string;
    date: string;
}

const EngagementDetailsCard: React.FC<EngagementDetailsProps> = ({ type, location, date }) => {
    return (
        <Box sx={{
            width: '100%', 
            backgroundColor: '#549189', 
            padding: '24px 40px', 
            borderRadius: '8px', 
            color: 'white',
            fontFamily: 'Arial',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        }}>
            <Typography variant="h6" sx={{
                fontSize: '18px',
                fontWeight: 'light'
            }}>
                Details of Engagement
            </Typography>
            <DetailItem label="Type:" value={type} />
            <DetailItem label="Where:" value={location} />
            <DetailItem label="When:" value={date} />
        </Box>
    );
};

const DetailItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
        <Typography component="span" sx={{ fontSize: '16px'}}>
            {label}
        </Typography>
        <Typography component="span" sx={{ fontSize: '16px' }}>
            {value}
        </Typography>
    </Stack>
);

export default EngagementDetailsCard;
