import React, { useEffect, useState } from 'react';
import { Avatar, Box, IconButton, Typography, Button, TextField, Dialog, Stack, TextareaAutosize } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUserDetails } from '../../../firebase/userApi';
import { getUserInfoById, getUserNameById } from '../../../firebase/userApis';
import pxToRem from '../../../helpers/pxToRem';
import styles from "../index.module.scss";
import { getTimeDiffDes2DatesMillsNow } from '../../../helpers/timeUtils';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PrimaryButton, PrimaryDangerButton, SecondaryButton } from '../../../components/Buttons/ButtonUI';
import CustomizedModal from '../../../components/CustomizedModal';
import { stringToColor } from './colorHelper';
import InputTextField from '../../../components/Inputs/TextFields/InputTextField';
import MultilineTextField from '../../../components/Inputs/TextFields/MultilineInputTextField';

interface CommentBoxProps {
  timestamp: number;
  content: string;
  userId: string;
  currentUserId: string
  //   userType: 'admin' | 'moderator' | 'user'; // Add more user types as needed
  onEdit: (newContent: string) => void;
  onDelete: () => void;
  isEditable: boolean;
}

const CommentBox: React.FC<CommentBoxProps> = ({ timestamp, content, userId, currentUserId, onEdit, onDelete, isEditable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(content);
  const [tempComment, setTempComment] = useState(content);
  const [username, setUsername] = useState("");
  const [timeDiffer, setTimeDiffer] = useState("")
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  useEffect(() => {
    const fetchUserName = async () => {
      const response = await getUserNameById(userId);
      if (response.code === 200 && response.data) {
        setUsername(response.data);
      }
    };

    fetchUserName();
    setTimeDiffer(getTimeDiffDes2DatesMillsNow(timestamp));
  }, [userId]);

  const handleEdit = () => {
    setComment(tempComment);
    onEdit(tempComment);
    setIsEditing(false);
  };

  const handleDeleteComment = () => {

    onDelete();
  };

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const canEditOrDelete = (currentUserId === userId && isEditable);


  return (
    <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', borderRadius: 2, paddingTop: 2 }}>

      <Box sx={{ flexGrow: 1 }}>


        <Stack direction={"row"} alignItems={"center"} justifyContent='space-between'>
          <Stack direction={"row"} alignItems={"center"}>
            <Avatar sx={{ bgcolor: stringToColor(username), width: pxToRem(32), height: pxToRem(32), marginRight: pxToRem(10) }} >{username.charAt(0)}</Avatar>

            <small style={{ color: "#737373", marginRight: pxToRem(20) }}>{username}</small>
            <small style={{ color: "#737373" }}>{timeDiffer}</small>
          </Stack>

          {canEditOrDelete && !isEditing && (
            <Stack direction={"row"} className={`${styles.commentButtonContainner}`}>
              <Button style={{ color: "#737373" }} onClick={() => { setIsEditing(true); setTempComment(content) }}> Edit </Button>
              <Button style={{ color: "#737373", marginLeft: pxToRem(10) }} onClick={handleConfirmOpen}> Delete </Button>
            </Stack>
          )}
        </Stack>
        {isEditing ? (
          <>
            <MultilineTextField
              className={`${styles.commentsInput}`}
              placeholder="Add Comment"
              value={tempComment}
              fontSize={14}
              customHeight={36}
              onChange={(e) => setTempComment(e.target.value)}
              multiline
              inputProps={{
              maxLength: 200,
              component: TextareaAutosize,
              style: { fontFamily: 'Open Sans', padding: pxToRem(9) }, 
              }}
              // onBlur={() => setIsNewCommentFocused(false)}
          />
            <Stack direction={"row"} className={`${styles.commentButtonContainner}`}>
              <Button style={{ color: "#447D75" }} onClick={handleEdit}> Save </Button>
              <Button style={{ color: "#737373", marginLeft: pxToRem(10) }} onClick={() => setIsEditing(false)}> Cancel </Button>
            </Stack>
          </>
        ) : (
          <small style={{ color: "#000", whiteSpace: 'pre-wrap' }}>{comment}</small>
        )}
      </Box>

      <CustomizedModal open={confirmOpen} handleClose={() => { }} width={600} type='ERROR' >
        <Stack direction={"column"}>
          <Stack className={`${styles.alertTextBox}`} direction={"column"}>
            <Typography className={`${styles.alertText}`}>
              Are you sure you want to delete this comment?

            </Typography>
            <Typography className={`${styles.alertText}`}>
              Once deleted, it cannot be retrieved.
            </Typography>
          </Stack>

          <div className={`${styles.narrowDivider} horizontalDivider`}></div>
          <Stack direction={"row"} justifyContent='space-between'>
            <SecondaryButton onClick={handleConfirmClose} className={`${styles.button} regular`}> Cancel </SecondaryButton>
            <Stack direction={"row"} className={`${styles.confirmButtonStack}`}>
              <PrimaryDangerButton className={styles.button} onClick={handleDeleteComment}>Delete</PrimaryDangerButton>
            </Stack>
          </Stack>

        </Stack>

      </CustomizedModal>


    </Box>
  );
};

export default CommentBox;
