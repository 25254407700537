import { Chip, InputAdornment, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import { setCurrentInterests, setCurrentStep } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";
import { QRFormTextField } from "..";

const PersonalInterests = () => {
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const interests = useSelector((state:RootState) => state.alumniReg.interests);
    const newInterest = useTextInput({inputValidator: (input) => { return undefined; }});

    const [isInterestAdding, setIsInterestAdding] = useState(false);

    const addInterest = (interest: string) => {
        setIsInterestAdding(true);
        var newInterests = Object.assign([], interests);
        newInterests.push(interest);
        dispatch(setCurrentInterests(newInterests));
        newInterest.setValue("");
        setIsInterestAdding(false);
    }

    const handleChipDelete = (index: number) => {
        var newInterests = Object.assign([], interests);
        newInterests.splice(index,1);
        dispatch(setCurrentInterests(newInterests));
    };

    const handleNext = () => {
        dispatch(setCurrentStep(REG_PAGES.TELL_US_MORE))
    }

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(() => {
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <Stack className={styles.formContainer} spacing={3}>
                <QRFormTextField
                    variant="standard"
                    label="Personal Interests"
                    disabled={isInterestAdding} placeholder="Add New Campus" value={newInterest.value} 
                    onChange={(e) => newInterest.setValue(e.target.value)}
                    onKeyDown={(e) => { if(e.key === 'Enter'){ addInterest(newInterest.value.trim()) }}}
                    error={newInterest.hasError && newInterest.hasTouched} 
                    helperText={<small><i>HINT</i>: Hit enter to create a tag.</small>}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                            >
                                <label onClick={()=>addInterest(newInterest.value.trim())} style={{cursor: 'pointer', color: isInterestAdding ? styles.neutrals400 : styles.shades100}} className={styles["regular-400-14"]}>Add</label>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{maxLength: 64}}
                />
                <div>
                    {interests?.map((interest, index) => (
                        <Chip color='default' key={index} label={interest} 
                        onDelete={() => handleChipDelete(index)} 
                        style={{ margin: '0 12px 12px 0', backgroundColor: styles.neutrals100 }}
                    /> 
                    ))}
                </div>
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} onClick={handleNext}>Next</PrimaryQRFormButton>
        </>
    );
}

export default PersonalInterests;