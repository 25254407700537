import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { deleteField, doc, FieldValue, serverTimestamp, writeBatch } from "firebase/firestore";
import moment, { Moment } from "moment";
import { Fragment, memo, useState } from "react";
import { useSelector } from "react-redux";
import AutoComplete from "../../../components/AutoComplete/AutoComplete";
import MultiAutoComplete from "../../../components/AutoComplete/MultiAutoComplete";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import CustomDatePicker from "../../../components/DatePicker";
import { CustomizedDropdown } from "../../../components/Inputs/Dropdown/CustomizedDropdown";
import Line from "../../../components/Line/Line";
import { db } from "../../../firebase";
import { createNewEducation, updateEducation } from "../../../firebase/alumniApis";
import { Education } from "../../../firebase/types-alumni";
import { PermissionState } from "../../../firebase/types-organisations";
import constants from "../../../helpers/constants";
import { addNewEducationValidator } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import { arrayToString, stringToArray } from "../../../helpers/transfer";
import { valueExistsInAutocomplete } from "../../../helpers/utils";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import styles from "./../index.module.scss";
import { ITabInterface } from "./TabInterfaces";

export type TEducation = { id: string; label?: string; value?: string; type: "DataInitial" | "Data" | "Space" | "DataEnd" | "DataOnly"; }
export type TEducationRow = { id: string; rows: TEducation[]; education?: Education }
const EducationTab = ({ orgId, orgName, fetchedAlumniProfile, alumniId, organisationDegree, organisationFieldOfStudy, organisationInstitution, educationRows }: ITabInterface) => {
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);

    // const [educationRows, setEducationRows] = useState<TEducationRow[]>([]);
    const [isNewEducation, setIsNewEducation] = useState(false);
    const type = useTextInput({ inputValidator: (input) => { if (input.length === 0) { return "Field can't be empty"; } }, cancelHasTouchedAutoUpdate: true, });
    const degree = useTextInput({ inputValidator: (input) => addNewEducationValidator(input, true, 'This field', 100) });
    const [prevDegree, setPrevDegree] = useState<null | string>(null);
    const fieldOfStudy = useTextInput({ inputValidator: (input) => addNewEducationValidator(input, false, undefined, 100) });
    const institution = useTextInput({ inputValidator: (input) => addNewEducationValidator(input, false, undefined, 100) });
    const [qualificationComplete, setQualificationComplete] = useState(false);
    const [startedMonth, setStartedMonth] = useState<Moment | null>(null);
    const [startedYear, setStartedYear] = useState<Moment | null>(null);
    const [endedExpectedMonth, setEndedExpectedMonth] = useState<Moment | null>(null);
    const [endedExpectedYear, setEndedExpectedYear] = useState<Moment | null>(null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [menuShowId, setMenuShowId] = useState(-1);
    const menuList = [{ label: "Edit", val: 0 }, { label: "Delete", val: 1 }, { label: "Exit", val: -1 }];
    const [editId, setEditId] = useState<string | undefined>(undefined);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | undefined>(undefined);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); }
    const menuClose = () => { setAnchorEl(null); }


    const educationRowItemGroup = (tEducationRows: TEducationRow, tEducation: TEducation, key: string, size: number) => {
        const onDelete = () => {
            setCurrentDeleteId(tEducationRows.id);
            setIsDeleteModal(true);
        }
        const onEdit = () => {
            const educationFB: Education | undefined = tEducationRows.education;
            console.log("- - -- >>Education on edit tEducationRows", tEducationRows)
            console.log("- - -- >>Education on edit educationFB", educationFB)
            if (educationFB !== undefined) {
                console.log("- - -- >>dATA", educationFB)
                setEditId(tEducationRows.id);
                setIsNewEducation(true); //Edit Mode

                type.setValue(educationFB.type ?? "");
                degree.setValue(educationFB.degree ?? "");
                setPrevDegree(educationFB.degree ?? null);
                fieldOfStudy.setValue(educationFB.fieldOfStudy ? arrayToString(educationFB.fieldOfStudy) : "");
                institution.setValue(educationFB.institution ?? "");
                setQualificationComplete(educationFB.qualificationComplete ?? false);
                setStartedMonth(educationFB.startedMonth ? moment(educationFB.startedMonth, "MM") : null)
                setStartedYear(educationFB.startedYear ? moment(educationFB.startedYear, "YYYY") : null)
                setEndedExpectedMonth(educationFB.endOrExpectedMonth ? moment(educationFB.endOrExpectedMonth, "MM") : null)
                setEndedExpectedYear(educationFB.endOrExpectedYear ? moment(educationFB.endOrExpectedYear, "YYYY") : null)
            }
        }
        return (
            <Fragment key={key}>
                {
                    tEducation.type === "DataInitial" ?
                        <tr style={{ backgroundColor: '#549189' }}>
                            <td className={styles.alumni_details_tableBottomTitleLeft}>{tEducation.value}</td>
                            <td className={styles.alumni_details_tableBottomTitleRight}>
                                {
                                    alumniPermission && alumniPermission === PermissionState.EDITOR &&
                                    <Stack direction="row-reverse">
                                        <Stack className={styles.table_btn} onClick={() => onEdit()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12132H5.8C4.11984 3.12132 3.27976 3.12132 2.63803 3.4483C2.07354 3.73592 1.6146 4.19486 1.32698 4.75935C1 5.40109 1 6.24116 1 7.92132V16.3213C1 18.0015 1 18.8416 1.32698 19.4833C1.6146 20.0478 2.07354 20.5067 2.63803 20.7943C3.27976 21.1213 4.11984 21.1213 5.8 21.1213H14.2C15.8802 21.1213 16.7202 21.1213 17.362 20.7943C17.9265 20.5067 18.3854 20.0478 18.673 19.4833C19 18.8416 19 18.0015 19 16.3213V12.1213M6.99997 15.1213H8.67452C9.1637 15.1213 9.40829 15.1213 9.63846 15.0661C9.84254 15.0171 10.0376 14.9363 10.2166 14.8266C10.4184 14.7029 10.5914 14.53 10.9373 14.1841L20.5 4.62132C21.3284 3.79289 21.3284 2.44975 20.5 1.62132C19.6716 0.792894 18.3284 0.792893 17.5 1.62132L7.93723 11.1841C7.59133 11.53 7.41838 11.7029 7.29469 11.9048C7.18504 12.0837 7.10423 12.2788 7.05523 12.4829C6.99997 12.713 6.99997 12.9576 6.99997 13.4468V15.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                        </Stack>
                                    </Stack>
                                }
                            </td>
                        </tr>
                        :
                        tEducation.type === "Data" ?
                            <tr>
                                <td className={styles.alumni_details_tableBottomGrayBorder}>{tEducation.label}</td>
                                <td className={styles.alumni_details_tableBottomBorder}>{tEducation.value}</td>
                            </tr>
                            : tEducation.type === "DataEnd" ?
                                <tr>
                                    <td className={styles.alumni_details_end_tableBottomGrayBorder}>{tEducation.label}</td>
                                    <td className={styles.alumni_details_end_tableBottomBorder}>{tEducation.value}</td>
                                </tr>
                                : tEducation.type === "DataOnly" ?
                                    <tr style={{ backgroundColor: '#549189' }}>
                                        <td className={styles.alumni_details_only_tableBottomTitleLeft}>{tEducation.value}</td>
                                        <td className={styles.alumni_details_only_tableBottomTitleRight}>
                                            {
                                                alumniPermission && alumniPermission === PermissionState.EDITOR &&
                                                <Stack direction="row-reverse">
                                                    <Stack className={styles.table_btn} onClick={() => onEdit()}>
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12132H5.8C4.11984 3.12132 3.27976 3.12132 2.63803 3.4483C2.07354 3.73592 1.6146 4.19486 1.32698 4.75935C1 5.40109 1 6.24116 1 7.92132V16.3213C1 18.0015 1 18.8416 1.32698 19.4833C1.6146 20.0478 2.07354 20.5067 2.63803 20.7943C3.27976 21.1213 4.11984 21.1213 5.8 21.1213H14.2C15.8802 21.1213 16.7202 21.1213 17.362 20.7943C17.9265 20.5067 18.3854 20.0478 18.673 19.4833C19 18.8416 19 18.0015 19 16.3213V12.1213M6.99997 15.1213H8.67452C9.1637 15.1213 9.40829 15.1213 9.63846 15.0661C9.84254 15.0171 10.0376 14.9363 10.2166 14.8266C10.4184 14.7029 10.5914 14.53 10.9373 14.1841L20.5 4.62132C21.3284 3.79289 21.3284 2.44975 20.5 1.62132C19.6716 0.792894 18.3284 0.792893 17.5 1.62132L7.93723 11.1841C7.59133 11.53 7.41838 11.7029 7.29469 11.9048C7.18504 12.0837 7.10423 12.2788 7.05523 12.4829C6.99997 12.713 6.99997 12.9576 6.99997 13.4468V15.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                                    </Stack>
                                                </Stack>
                                            }
                                        </td>
                                    </tr>

                                    :
                                    <tr className={styles.noBorder}>
                                        <td colSpan={3} className={styles.noBorder}>&nbsp;</td>
                                    </tr>
                }
            </Fragment>
        );
    }
    const educationRowCreate = (tEducationRow: TEducationRow, index: string) => {
        return (<Fragment key={index}>{tEducationRow.rows.map((tEducation, index) => educationRowItemGroup(tEducationRow, tEducation, tEducation.id, tEducationRow.rows.length - 1))}</Fragment>);
    }
    const EducationGroup = ({ tEducationRowArr }: { tEducationRowArr: TEducationRow[] }) => {
        return (<Fragment key={"1_educationrowarr_group"}>{tEducationRowArr.map((schoolingInfom, index) => educationRowCreate(schoolingInfom, index + "__tedu"))}</Fragment>);
    }
    const onDelete = (rowId: string) => {
        setCurrentDeleteId(rowId);
        setIsDeleteModal(true);
    }

    const [existingIds, setExistingIds] = useState<Set<string>>(new Set());
    const resetForm = () => {
        setEditId(undefined);
        setCurrentDeleteId(undefined);
        setMenuShowId(-1);
        type.setValue(""); type.setHasTouched(false);
        degree.setValue(""); degree.setHasTouched(false);
        fieldOfStudy.setValue("");
        institution.setValue("");
        setQualificationComplete(false);
        setStartedMonth(null);
        setStartedYear(null);
        setEndedExpectedMonth(null);
        setEndedExpectedYear(null);
    }

    const addData = async () => {
        if (orgId && alumniId) {
            console.log("1")
            const mandatoryInputs = [degree, type];
            let validated = true;
            mandatoryInputs.map((input) => input.setHasTouched(true));
            mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
            if (!validated) { console.log("2", degree.hasError, type.hasError); return; }
            let optionalValidated = true;   // Optional Fields
            const optionalInput = [fieldOfStudy, institution];
            optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
            if (!optionalValidated) { console.log("3"); return; }
            setLoadingStatus(true);
            setLoadingStatusStr("Saving...");
            const degreeValue = degree.value.trim();
            const fieldOfStudyValue = fieldOfStudy.value.trim();
            const institutionValue = institution.value.trim();
            const education: Education<FieldValue> = {
                ...(type.value && { type: type.value.trim() }),
                ...(degreeValue && { degree: degreeValue }),
                ...(fieldOfStudyValue && { fieldOfStudy: stringToArray(fieldOfStudyValue) }),
                ...(institutionValue && { institution: institutionValue }),
                ...(qualificationComplete && { qualificationComplete: qualificationComplete }),
                ...(startedMonth && { startedMonth: parseInt(moment(startedMonth, "MMM").format("MM")) }),
                ...(startedYear && { startedYear: parseInt(moment(startedYear, "YYYY").format("YYYY")) }),
                ...(endedExpectedMonth && { endOrExpectedMonth: parseInt(moment(endedExpectedMonth, "MMM").format("MM")) }),
                ...(endedExpectedYear && { endOrExpectedYear: parseInt(moment(endedExpectedYear, "YYYY").format("YYYY")) }),
                alumniId: alumniId,
                orgId: orgId,
                created_at: serverTimestamp(),
                updated_at: serverTimestamp()
            }
            let updateDegree; //For updating auto complete 
            if (organisationDegree) { updateDegree = valueExistsInAutocomplete(degreeValue, organisationDegree) };
            let updateFieldOfStudy; //For updating auto complete 
            if (organisationFieldOfStudy) { updateFieldOfStudy = valueExistsInAutocomplete(fieldOfStudyValue, organisationFieldOfStudy) };
            let updateInstitution; //For updating auto complete 
            if (organisationInstitution) { updateInstitution = valueExistsInAutocomplete(institutionValue, organisationInstitution) }
            const res = await createNewEducation(orgId, alumniId, education, degreeValue,
                !updateDegree ? degreeValue : undefined, !updateFieldOfStudy ? stringToArray(fieldOfStudyValue) : undefined, !updateInstitution ? institutionValue : undefined);
            if (res.code === 500) { setErrorMsg("Errors: Education create."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewEducation(false);
            resetForm();
        }
    }
    const updateData = async (educationId: string | undefined) => {
        if (orgId && alumniId) {
            if (educationId === undefined || educationId === "") { return; }
            const mandatoryInputs = [type, degree];
            let validated = true;
            mandatoryInputs.map((input) => input.setHasTouched(true));
            mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
            if (!validated) { return; }
            let optionalValidated = true;   // Optional Fields
            const optionalInput = [fieldOfStudy, institution];
            optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
            if (!optionalValidated) { return; }
            setLoadingStatus(true);
            setLoadingStatusStr("Updating...");
            const degreeValue = degree.value.trim();
            const fieldOfStudyValue = fieldOfStudy.value.trim();
            const institutionValue = institution.value.trim();
            const education: Education<FieldValue> = {
                ...(type.value === "" ? { type: deleteField() } : { type: type.value.trim() }),
                ...(degreeValue === "" ? { degree: deleteField() } : { degree: degreeValue }),
                ...(fieldOfStudyValue === "" ? { fieldOfStudy: deleteField() } : { fieldOfStudy: stringToArray(fieldOfStudyValue) }),
                ...(institutionValue === "" ? { institution: deleteField() } : { institution: institutionValue }),
                ...(qualificationComplete === false ? { qualificationComplete: deleteField() } : { qualificationComplete: qualificationComplete }),
                ...(startedMonth === null ? { startedMonth: deleteField() } : { startedMonth: parseInt(moment(startedMonth, "MMM").format("MM")) }),
                ...(startedYear === null ? { startedYear: deleteField() } : { startedYear: parseInt(moment(startedYear, "YYYY").format("YYYY")) }),
                ...(endedExpectedMonth === null ? { endOrExpectedMonth: deleteField() } : { endOrExpectedMonth: parseInt(moment(endedExpectedMonth, "MMM").format("MM")) }),
                ...(endedExpectedYear === null ? { endOrExpectedYear: deleteField() } : { endOrExpectedYear: parseInt(moment(endedExpectedYear, "YYYY").format("YYYY")) }),
                updated_at: serverTimestamp()
            }
            let updateDegree; //For updating auto complete 
            if (organisationDegree) { updateDegree = valueExistsInAutocomplete(degreeValue, organisationDegree) };
            let updateFieldOfStudy; //For updating auto complete 
            if (organisationFieldOfStudy) { updateFieldOfStudy = valueExistsInAutocomplete(fieldOfStudyValue, organisationFieldOfStudy) };
            let updateInstitution; //For updating auto complete 
            if (organisationInstitution) { updateInstitution = valueExistsInAutocomplete(institutionValue, organisationInstitution) }
            const res = await updateEducation(orgId, alumniId, education, educationId, fetchedAlumniProfile?.educationDegree,
                prevDegree !== degreeValue ? { degreeChangeStatus: 'updated', degree: degreeValue } : { degreeChangeStatus: 'unchanged', degree: degreeValue },
                !updateDegree ? degreeValue : undefined, !updateFieldOfStudy ? stringToArray(fieldOfStudyValue) : undefined, !updateInstitution ? institutionValue : undefined);
            if (res.code === 500) { setErrorMsg("Errors: Education update."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewEducation(false);
            resetForm();
        }
    }
    const deleteData = async () => {
        if (orgId && alumniId) {
            const batch = writeBatch(db);
            if (currentDeleteId === undefined) return;
            setDeleteLoadingStatus(true);
            try {
                const docEducationRef = doc(db, "organisations", orgId, "alumni", alumniId, "education", currentDeleteId);
                const docAlumniRef = doc(db, "organisations", orgId, "alumni", alumniId);
                if (fetchedAlumniProfile?.educationDegree) {
                    const degreeIndex = fetchedAlumniProfile.educationDegree.findIndex(educationDegree => educationDegree.id === currentDeleteId);
                    if (degreeIndex !== -1) {
                        fetchedAlumniProfile?.educationDegree.splice(degreeIndex, 1);
                        batch.set(docAlumniRef, { educationDegree: fetchedAlumniProfile.educationDegree }, { merge: true });
                    }
                }
                batch.delete(docEducationRef)
                batch.commit();
            }
            catch (ex) { setErrorMsg("Errors: Education delete, please try again"); console.log(ex); }
            setDeleteLoadingStatus(false);
            setIsDeleteModal(false);
            setCurrentDeleteId(undefined);
            resetForm();
        }
    }

    const educationTypeOptions = [{ label: "Associates Degree", value: "ASSOCIATES_DEGREE" }, { label: "Bachelor's Degree", value: "BACHELORS_DEGREE" }, { label: "Certificate", value: "CERTIFICATE" }, { label: "Diploma", value: "DIPLOMA" }, { label: "Doctor's Degree", value: "DOCTORS_DEGREE" }, { label: "Honours", value: "HONOURS" }, { label: "Masters", value: "MASTERS" }, { label: "PHD", value: "PHD" }, { label: "Other", value: "OTHER" }]
    return (
        <Stack>
            <CustomizedModal open={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} type='ERROR' children={<p>{errorMsg}</p>} />
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} type="ERROR" isLoading={deleteLoadingStatus} loadingStr={"Removing..."} >
                <Stack>
                    <Stack spacing={0.5} style={{ marginTop: 20, marginBottom: 10 }} className={styles.modalHeaderTitleWrapper}>
                        <label className={styles.modalTitle}>Delete Education</label>
                        <label className={styles.modalSubTitle}>This action cannot be undone</label>
                    </Stack>
                    <Stack style={{ marginBottom: 20 }} spacing={3} className={styles.modalHeaderTitleWrapper}>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                        <label className={styles.modalBodyText}>Are you sure you want to delete this education?</label>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    </Stack>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(undefined); resetForm() }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <WarningButton className={styles.button} onClick={() => deleteData()}> Yes, I'm Sure </WarningButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal width={660} open={isNewEducation} handleClose={() => { }} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack width={"100%"} alignItems={"end"}>
                        <IconButton onClick={() => { setIsNewEducation(false); resetForm(); }}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.alumni_details_modalHeaderTitleWrapper}>
                        <div className={styles.alumni_details_iconWithCircleWrapper}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M5 10.0001V16.0112C5 16.3702 5 16.5496 5.05465 16.7081C5.10299 16.8482 5.18187 16.9759 5.28558 17.0817C5.40287 17.2015 5.5634 17.2818 5.88446 17.4423L11.2845 20.1423C11.5468 20.2735 11.678 20.3391 11.8156 20.3649C11.9375 20.3877 12.0625 20.3877 12.1844 20.3649C12.322 20.3391 12.4532 20.2735 12.7155 20.1423L18.1155 17.4423C18.4366 17.2818 18.5971 17.2015 18.7144 17.0817C18.8181 16.9759 18.897 16.8482 18.9453 16.7081C19 16.5496 19 16.3702 19 16.0112V10.0001M2 8.50006L11.6422 3.67895C11.7734 3.61336 11.839 3.58056 11.9078 3.56766C11.9687 3.55622 12.0313 3.55622 12.0922 3.56766C12.161 3.58056 12.2266 3.61336 12.3578 3.67895L22 8.50006L12.3578 13.3212C12.2266 13.3868 12.161 13.4196 12.0922 13.4325C12.0313 13.4439 11.9687 13.4439 11.9078 13.4325C11.839 13.4196 11.7734 13.3868 11.6422 13.3212L2 8.50006Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round" /> </svg></div>
                        <div style={{ flexGrow: 1 }}>
                            <label className={styles.alumni_details_modalHeaderTitle}>{(editId) ? "Edit Education" : "Add New Education"}</label>
                        </div>
                    </Stack>
                    <Stack className={styles.popupContent} style={{ paddingRight: pxToRem(10), marginTop: pxToRem(30), paddingTop: pxToRem(10) }}>
                        <Stack>
                            <Stack spacing={2} >
                                <CustomizedDropdown
                                    label="Type" nonePlaceHolder="Select" noneEnable={true}
                                    isNotOptionalNone
                                    fullWidth
                                    valueCheck={type.value} customHeight={constants.addAlumniInputHeight}
                                    fontSize={constants.addAlumniFontSize}
                                    datas={educationTypeOptions} value={type.value ?? ""}
                                    onChange={(e) => { type.setValue(e.target.value as string) }}
                                    error={type.hasTouched && type.hasError}
                                    helperText={type.hasTouched && type.value.trim() === "" ? type.getErrorMessage() : undefined}
                                    shrinkLabelWidth={30}
                                />
                                <AutoComplete
                                    fontSize={constants.addAlumniFontSize}
                                    customHeight={constants.addAlumniInputHeight}
                                    valueCheck={degree.value}
                                    inputValue={degree.value} // Prefill the data when edit
                                    value={degree.value}
                                    shrinkLabelWidth={86}
                                    label='Degree/Course*'
                                    setValue={(value: string) => degree.setValue(value)}
                                    options={organisationDegree ?? []} freeSolo
                                    error={degree.hasError && degree.hasTouched}
                                    helperText={degree.hasError && degree.hasTouched ? <small className="regular-400-12">{degree.getErrorMessage()}</small> : undefined}
                                />
                                <MultiAutoComplete
                                    fontSize={constants.addAlumniFontSize}
                                    customHeight={constants.addAlumniInputHeight}
                                    value={fieldOfStudy.value}
                                    valueCheck={fieldOfStudy.value}
                                    setValue={(value: string) => fieldOfStudy.setValue(value)}
                                    options={organisationFieldOfStudy ?? []}
                                    freeSolo={true}
                                    shrinkLabelWidth={36}
                                    label='Major'
                                    error={fieldOfStudy.hasError && fieldOfStudy.hasTouched && fieldOfStudy.value.trim() !== ""}
                                    helperText={fieldOfStudy.hasError && fieldOfStudy.hasTouched && fieldOfStudy.value.trim() !== "" ? <small className="regular-400-12">{fieldOfStudy.getErrorMessage()}</small> : undefined}
                                />
                                <AutoComplete
                                    fontSize={constants.addAlumniFontSize}
                                    customHeight={constants.addAlumniInputHeight}
                                    valueCheck={institution.value}
                                    value={institution.value}
                                    inputValue={institution.value} // Prefill the data when edit
                                    shrinkLabelWidth={58}
                                    label='Institution'
                                    setValue={(value: string) => institution.setValue(value)}
                                    options={organisationInstitution ?? []} freeSolo
                                    error={institution.hasError && institution.hasTouched && institution.value.trim() !== ""}
                                    helperText={institution.hasError && institution.hasTouched && institution.value.trim() !== "" ? <small className="regular-400-12">{institution.getErrorMessage()}</small> : undefined}
                                />
                                <Stack style={{ marginTop: 20 }} spacing={1}>
                                    <FormControlLabel control={<Checkbox sx={{ padding: pxToRem(6), marginRight: pxToRem(6) }} checked={qualificationComplete} onChange={() => setQualificationComplete(!qualificationComplete)} />} label="Qualification Complete" />
                                </Stack>
                                <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    <label style={{ flex: 0.6 }}>Started</label>
                                    <Stack style={{ flex: 1, display: 'flex' }} direction={"row"} alignItems='center' spacing={2}>
                                        <CustomDatePicker label="Month" views={['month']}
                                            setCleared={() => setStartedMonth(null)}
                                            placeholder={""}
                                            format="MMM"
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            value={startedMonth ? moment(startedMonth, "MM") : null}
                                            valueCheck={startedMonth ? moment(startedMonth, "MM") : null}
                                            onChange={(value) => { if (value) { setStartedMonth(value); } }}
                                        />
                                        <CustomDatePicker label="Year" views={['year']}
                                            setCleared={() => setStartedYear(null)}
                                            placeholder={""}
                                            format="YYYY"
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            valueCheck={startedYear ? moment(startedYear, "YYYY") : null}
                                            value={startedYear ? moment(startedYear, "YYYY") : null}
                                            onChange={(value) => { if (value) { setStartedYear(value); } }}
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    <label style={{ flex: 0.6 }}>Ended (or expected)</label>
                                    <Stack style={{ flex: 1 }} direction={"row"} alignItems='center' spacing={2}>
                                        <CustomDatePicker label="Month" views={['month']}
                                            setCleared={() => setEndedExpectedMonth(null)}
                                            placeholder={""}
                                            format="MMM"
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            valueCheck={endedExpectedMonth ? moment(endedExpectedMonth, "MM") : null}
                                            value={endedExpectedMonth ? moment(endedExpectedMonth, "MM") : null}
                                            onChange={(value) => { if (value) { setEndedExpectedMonth(value); } }}
                                        />
                                        <CustomDatePicker label="Year" views={['year']}
                                            setCleared={() => setEndedExpectedYear(null)}
                                            placeholder={""}
                                            format="YYYY"
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            valueCheck={endedExpectedYear ? moment(endedExpectedYear, "YYYY") : null}
                                            value={endedExpectedYear ? moment(endedExpectedYear, "YYYY") : null}
                                            onChange={(value) => { if (value) { setEndedExpectedYear(value); } }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        {(editId) ?
                            <WarningButton className={styles.button} onClick={() => { onDelete(editId); setIsNewEducation(false); }}> Delete </WarningButton>
                            :
                            <SecondaryButton className={styles.button} onClick={() => { setIsNewEducation(false); resetForm(); }}> Cancel </SecondaryButton>
                        }
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button}
                                onClick={() => {
                                    if (editId === undefined) addData()
                                    else updateData(editId)
                                }}
                            > Save & Close </PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack>
                <Menu id="schooling-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={menuClose} autoFocus={false}>
                    {menuList.map((menu, index) => <MenuItem key={index} onClick={() => { setMenuShowId(menu.val); menuClose(); }} style={{ minWidth: 150 }}>{menu.label}</MenuItem>)}
                </Menu>
                <Stack style={{ borderRadius: pxToRem(8), backgroundColor: "#F9F9FA", alignItems: "center", justifyContent: "center", height: pxToRem(40), marginBottom: educationRows && educationRows.length == 0 ? 0 : pxToRem(24), padding: pxToRem(10) }} direction={"row"}>
                    <Stack style={{ fontWeight: 400, marginLeft: pxToRem(10), fontSize: pxToRem(14) }}>
                        Further Education
                    </Stack>
                    <Stack style={{ flex: 1, alignItems: 'center', marginRight: pxToRem(10) }} direction="row-reverse" spacing={1.5}>
                        {/* <IconButton sx={{ '&:hover': { backgroundColor: 'transparent', }, }} style={{padding:'0px 2px'}}  onClick={menuClick}> <svg width={pxToRem(12)} height={pxToRem(20)} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="6" cy="2" r="2" transform="rotate(90 6 2)" fill="#737373"/> <circle cx="6" cy="10" r="2" transform="rotate(90 6 10)" fill="#737373"/> <circle cx="6" cy="18" r="2" transform="rotate(90 6 18)" fill="#737373"/> </svg> </IconButton> */}
                        {alumniPermission && alumniPermission === PermissionState.EDITOR && <IconButton className={styles.buttonHover} onClick={() => setIsNewEducation(true)}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>}
                    </Stack>
                </Stack>
                <table className={styles.alumni_profile_page_table} >
                    {educationRows && educationRows.length > 0 ?
                        <tbody>
                            <EducationGroup tEducationRowArr={educationRows} />
                        </tbody> : null}
                </table>
            </Stack>
            {
                educationRows && educationRows.length <= 0
                    ?
                    alumniPermission && alumniPermission === PermissionState.EDITOR ?
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">Add New</label>
                            <IconButton onClick={() => setIsNewEducation(true)}>
                                <svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /></svg>
                            </IconButton>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                        :
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">No Record</label>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                    :
                    null
            }
        </Stack>
    );
}

export default memo(EducationTab);