import { Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { EMPTY_INPUT_HELPER_MSG } from "../../../helpers/shared";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import { setCurrentFirstName, setCurrentLastName, setCurrentPreferredName, setCurrentStep } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";
import { QRFormTextField } from "..";

const PersonalDetails = () => {
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const step = useSelector((state:RootState) => state.alumniReg.step);

    const firstName = useTextInput({
        inputValidator: (input: string) => { if (input.length === 0) { return EMPTY_INPUT_HELPER_MSG; } },
        defaultValue: alumniData && alumniData.firstName ? alumniData.firstName : "", 
    });
    const lastName = useTextInput({
        inputValidator: (input: string) => { if (input.length === 0) { return EMPTY_INPUT_HELPER_MSG; } },
        defaultValue: alumniData && alumniData.lastName ? alumniData.lastName : "", 
    });
    const preferredName = useTextInput({
        inputValidator: (input: string) => { return undefined; },
        defaultValue: alumniData && alumniData.preferredName ? alumniData.preferredName : "", 
    });

    const handleData = () => {
        const mandatoryInputs = [firstName, lastName];
        const optionalInputs = [preferredName];
        mandatoryInputs.map((input) => input.setHasTouched(true));
        const validated = mandatoryInputs.every(input => !input.hasError);
        if (!validated) { return; }
        const optionalValidated = optionalInputs.every(optionalInput => !(optionalInput.hasError && optionalInput.value.trim() !== ""));
        if(!optionalValidated){ return; }

        dispatch(setCurrentFirstName(firstName.value))
        dispatch(setCurrentLastName(lastName.value))
        dispatch(setCurrentPreferredName(preferredName.value))
        handleNext();
    }

    const handleNext = () => {
        if(step) {
            dispatch(setCurrentStep(step+1))
        }
    }

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(() => {
      if(!alumniData.inProgress) {
          handleNavigation(REG_PAGES.FRONT_PAGE);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return(
        <>
            <Stack className={styles.formContainer} spacing={3}>
                <QRFormTextField
                    className={styles.inputComponent}
                    value={firstName.value} onChange={(e) => firstName.setValue(e.target.value)}
                    label={"First Name*"}
                    // adornment={
                    //     firstName.hasError && firstName.hasTouched ? (
                    //     <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                    //         <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                    //     </svg>
                    //     ) : undefined
                    // }
                    error={firstName.hasError && firstName.hasTouched}
                    helperText={<small>{firstName.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                    /> 
                <QRFormTextField
                    className={styles.inputComponent}
                    value={lastName.value} onChange={(e) => lastName.setValue(e.target.value)}
                    label={"Last Name*"}
                    // adornment={
                    //     firstName.hasError && firstName.hasTouched ? (
                    //     <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                    //         <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                    //     </svg>
                    //     ) : undefined
                    // }
                    error={lastName.hasError && lastName.hasTouched}
                    helperText={<small>{lastName.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                /> 
                <QRFormTextField
                    className={styles.inputComponent}
                    value={preferredName.value} onChange={(e) => preferredName.setValue(e.target.value)}
                    label={"Preferred Name"}
                    error={preferredName.hasError && preferredName.hasTouched}
                    helperText={<small>{preferredName.getErrorMessage()}</small>}
                    id="standard-basic" variant="standard"
                    /> 
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} onClick={handleData}>Next: Contact Details</PrimaryQRFormButton>
        </>
    );
}

export default PersonalDetails;