import { DocumentData, Timestamp } from "firebase/firestore";
import moment, { Moment } from "moment";
import globalStyles from "../styles/_stylesParams.module.scss";
import { FilterFn } from "@tanstack/react-table";

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
export const joinStringWithSpace = (str1: string | null | undefined, str2: string | null | undefined): string => [str1, str2].filter(Boolean).join(" ");
export const joinStringWithComma = (str1: string | null, str2: string | null): string => [str1, str2].filter(Boolean).join(", ");
export const joinStringsWithComma = (...strings: (string | null | undefined)[]): string => {
  const validStrings = strings.filter((str) => str !== null && str !== undefined && str !== '');
  return validStrings.join(", ");
};
export const extractTwoFirstLetters = (str: string | undefined | null): string => {
    if (!str) return '';
    const [first, second] = str.split(' ').map(word => word.charAt(0).toUpperCase());
    return (second ? first + second : (str.length > 2 ? str[0]+str[1] : first)).toUpperCase();
};
export const extractFourFirstLetters = (str: string | undefined | null): string => {
  if (!str) return '';
  // const [first, second, third, fourth] = str.split(' ').map(word => word.charAt(0).toUpperCase());
  // return (second ? first + second : (str.length > 2 ? str[0]+str[1] : first)).toUpperCase();
  return str.split(' ') .slice(0, 4) .map(word => word.charAt(0)) .join('') .toUpperCase();
};
export const extractFirstLetter = (str: string | undefined | null): string => {
  if (!str) return '';
  if(str.length <= 0) return '';
  return str.charAt(0).toUpperCase();
};
export const convertUpdatedAt2Date = (date: DocumentData):Date => {
  try{
    const ts: Timestamp = date.updated_at as Timestamp;
    return ts.toDate();
  }
  catch(ex){
    return moment().toDate();
  }
}
export const openLink = (url:string) => {
  window.open(url, '_blank');
}
export const durationCalculation = (_ye: string|undefined, _py: string|undefined, _yl: string|undefined):number | undefined => {
  // const ye = parseInt(_ye??"0");
  // const py = parseInt(_py??"0");
  // const yl = parseInt(_yl??"0");

  // if(yl > py) return yl - ye;
  // return py - ye;
  if (_ye && _py && !_yl) {
    return moment((moment(_py,'YYYY'))).diff(moment(_ye,"YYYY"), 'years');
  } else if (_yl && _ye) {
    return moment(moment(_yl,"YYYY")).diff(moment(_ye, "YYYY"), 'years');
  } else {
    return undefined;
  }

}
export const durationCalculationStr = (_ye: string|undefined, _py: string|undefined, _yl: string|undefined):string | undefined => {
  const year = durationCalculation(_ye, _py, _yl);
  if(!year){return undefined}
  let yearStr = "";
  if(year > 1) yearStr = "Years";
  else if(year === 1) yearStr = "Year";
  else if (year === undefined || isNaN(year)) return "-";
  return `${year} ${yearStr}`;
}
export const schoolingDurationCalculation = ( yearEnrolled: Moment | null, peerYear: Moment | null, yearLeft: Moment | null ): string | number => {
  if (yearEnrolled && peerYear && !yearLeft) {
    return moment(peerYear).diff(yearEnrolled, 'years');
  } else if (yearLeft && yearEnrolled) {
    return moment(yearLeft).diff(yearEnrolled, 'years');
  } else {
    return "-";
  }
};
export const getIconColor = (active: boolean) => active ? globalStyles.shades100 : globalStyles.destructive100;
export const remToPx = (rem: number):number => rem * 16;
export const mapValue = ( value: number, fromLow: number, fromHigh: number, toLow: number, toHigh: number ): number => Math.round( value < fromLow ? toLow : value > fromHigh ? toHigh : ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow );
export const getError = (error: any):string => { if (error instanceof Error) return error.message; else return error; }
export const getNumberFormatted = (value: number | undefined | null) => value?value.toLocaleString("en-AU"):0;
export const extractTwoWordFromSentence = ( sentence: string | undefined | null ): { first: string; last: string } => {
    if (sentence && sentence !== "") {
        const words = sentence.split(' ');
        if (words.length === 1) return { first: words[0], last: '' };
        else {
            const concatenated = words.slice(1).join(' ');
            return { first: words[0], last: concatenated };
        }
    }
    return { first: "", last: "" };
};
export const getOrganisationTypeLabel = (type: string | undefined) => {
  if(type){
    switch(type){
      case "PRIMARY_SECONDARY":
        return "Primary & Secondary";
      case "PRIMARY":
        return "Primary";
      case "SECONDARY":
        return "Secondary"
    }
  }
  return ""
};

export const valueExistsInAutocomplete = (value: string, existingArray: string[]): boolean => {
  return existingArray.includes(value);
};

export const formatEducationLabel = (value: string | undefined) => {
  switch (value) {
    case 'ASSOCIATES_DEGREE':
      return 'Associate Degree of';
    case 'BACHELORS_DEGREE':
      return 'Bachelor of';
    case 'CERTIFICATE':
      return 'Certificate in';
    case 'DIPLOMA':
      return 'Diploma of';
    case 'DOCTORS_DEGREE':
      return 'Doctor of';
    case 'HONOURS':
      return 'Honours in';
    case 'MASTERS':
      return 'Master of';
    case 'PHD':
      return 'PhD in';
    default:
      return '';
  }
};

export const redirectToNewTab = (url: string) => {
  const newTab = window.open(url, '_blank');
  if (!newTab) { console.error('Failed to open new tab'); }
}