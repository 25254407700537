import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ErrorShownPic from "../../assets/images/error-fallback/errorImage.png";
import "./ErrorFallback.styles.scss";

type ErrorFallbackProps = { children: JSX.Element | JSX.Element[] };

type ErrorFallbackState = { hasError: boolean; };

export default class ErrorFallback extends React.Component< ErrorFallbackProps, ErrorFallbackState > {
    constructor(props: ErrorFallbackProps) {
        super(props);
        this.state = { hasError: false, };
    }
    static getDerivedStateFromError(error: any) { return { hasError: true, }; }

    componentDidCatch(error: any) {
        // console.log("The errors that catched by error boundary:");
        // console.log(error);
    }

    render() {
        if (this.state.hasError) {
            return (
            <Stack id='errorfallback__stack' alignItems='center' sx={{ width: "100%" }} >
                <img src={ErrorShownPic} alt='web site broke down img' id='errorfallback__ErrorShownPic' />
                <Box fontFamily='Lexend Deca' id='errorfallback__fallbackInfo'> Sorry, website is down temporarily. Looking forward to see you back soon. </Box>
            </Stack>
            );
        }
        return this.props.children;
    }
}
