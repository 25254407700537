import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPageStatusStateSlice { 
    isLoading: boolean; 
    errorMessage: string | undefined;
}
const INITIAL_STATE: IPageStatusStateSlice = { 
    isLoading: false, 
    errorMessage: undefined
}
export const PageStatusStateSlice = createSlice({
    name: "PageStatusStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setPageStatusDefault:(state) => {
            state = INITIAL_STATE;
        },
        setPageStatus: (state, action: PayloadAction<boolean>) => {
            state.isLoading= action.payload;
        },
        setErrorMessage: (state, action: PayloadAction<string|undefined>) => {
            state.errorMessage= action.payload;
        },
    }
});

export const { setPageStatusDefault, setPageStatus, setErrorMessage } = PageStatusStateSlice.actions;
export default PageStatusStateSlice.reducer;