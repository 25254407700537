import { doc, setDoc } from "firebase/firestore";
import { db } from ".";

export const checkTask = async (orgId: string, year: string, week: number, taskNo: number, checked: boolean) => {
    try {
        const code = `${week}`;
        var updateData:  { [key: string]: boolean } = {}
        updateData[`tasks.${taskNo}`] = checked;

        const orgProgressRef = doc(db, "organisations", orgId, "weeklyGoals", year, "goals", code);
        await setDoc(orgProgressRef, {
            tasks: {
                [`${taskNo}`]: checked
            }
        }, { merge: true})

        return {code: 200};
    } catch (error) {
        return {code: 500, error: error};
    }
}