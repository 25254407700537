import styles from "./GooglePlaceSearcher.module.scss";
import Stack from "@mui/material/Stack";
import { RootState } from "../../redux";
import { useSelector } from "react-redux";
import { OnboardingSteps } from "../../pages/OnboardingPage";
import AutoCompleteCustomized from "../AutoCompleteCustomized";
import pxToRem from "../../helpers/pxToRem";

interface IGooglePlaceSeacher {
  updateSelectedPlace: (place: any) => void;
  updateOnboardingStep: (step: OnboardingSteps) => void;
  isShown?: boolean;
}

const GooglePlaceSearcher = ({
  updateSelectedPlace,
  updateOnboardingStep,
  isShown
}: IGooglePlaceSeacher) => {
  const userName = useSelector((state: RootState) => {
    if(state.user.userInfos.displayName === "") {
      return `${state.user.userInfos.firstName} ${state.user.userInfos.lastName}` 
    }
    return state.user.userInfos.displayName
  });

  const updateSelectedPlaceAndStep = (place: any) => {
    updateSelectedPlace(place);
    if (place) {  // Ensure place has required data to proceed
      updateOnboardingStep({ type: "CONFIRM_ORG", count: 1 });
    }
  };
  

  return (
    <Stack className={styles.container} alignItems={"center"}>
      {isShown ?  (  
      <>
      <Stack className={`${styles.lockIconBg}`}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='52'
          height='52'
          viewBox='0 0 52 52'
          fill='none'
        >
          <path
            d='M16.2502 23.8333H9.96683C8.75338 23.8333 8.14666 23.8333 7.68318 24.0695C7.2755 24.2772 6.94404 24.6087 6.73632 25.0164C6.50016 25.4798 6.50016 26.0866 6.50016 27.3V45.5M35.7502 23.8333H42.0335C43.2469 23.8333 43.8537 23.8333 44.3171 24.0695C44.7248 24.2772 45.0563 24.6087 45.264 25.0164C45.5002 25.4798 45.5002 26.0866 45.5002 27.3V45.5M35.7502 45.5V13.4333C35.7502 11.0064 35.7502 9.79299 35.2779 8.86604C34.8624 8.05067 34.1995 7.38776 33.3841 6.97231C32.4572 6.5 31.2437 6.5 28.8168 6.5H23.1835C20.7566 6.5 19.5432 6.5 18.6162 6.97231C17.8008 7.38776 17.1379 8.05067 16.7225 8.86604C16.2502 9.79299 16.2502 11.0064 16.2502 13.4333V45.5M47.6668 45.5H4.3335M23.8335 15.1667H28.1668M23.8335 23.8333H28.1668M23.8335 32.5H28.1668'
            stroke='black'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </Stack>
     
        <h6 className='xsMarginBottom regular mediumMarginTop text-center'>{`Hello, ${userName}!`}</h6>
        <p className='regular largeMarginBottom' style={{color: styles['shades100']}}>
          Let’s search for your organisation
        </p>
        </>): (
          <Stack marginBottom={pxToRem(10)}>
          <h6 className='xsMarginBottom regular mediumMarginTop text-center'>{'Search for your organisation'}</h6>
          </Stack>
        )}
        <Stack className={styles.createNewOrgStack}>
          <div className={styles.autoCompleteWrapper}>
            <AutoCompleteCustomized
              updateSelectedPlace={updateSelectedPlaceAndStep}
              handleCreateNew={() => {
                updateOnboardingStep({ type: "CONFIRM_ORG", count: 1 });
              }}
            />
          </div>
        </Stack>
    </Stack>
  );
};

export default GooglePlaceSearcher;
