import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss'; // Your SCSS module file path
import { Button, Icon, IconButton, Modal, Stack, useMediaQuery } from '@mui/material';
import pxToRem from '../../../../helpers/pxToRem';
import LogoPath from "../../../../assets/images/logo/logo512.png"
import dayjs from 'dayjs';
import { OnboardingSteps } from '../../../OnboardingPage';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GooglePlaceSearcher from '../../../../components/components/GooglePlaceSearcher';
import OrganizationSelector from '../../../../components/components/OrganizationSelector';
import AlumniSelector from '../../../../components/components/onBoarding/alumniSelector/AlumniSelector';
import CampusSelector from '../../../../components/components/onBoarding/campusSelector/CampusSelector';
import HousesSelector from '../../../../components/components/onBoarding/housesSelector/HousesSelector';
import OrgansationCreated from '../../../../components/components/onBoarding/organisationCreated/OrgansationCreated';
import { setIsOnBoardingFlowState } from '../../../../redux/sharedStateSlice';
import loopLogo from "../../../../assets/images/shared/loopSquareLogo.png";
import { PrimaryButton } from '../../../../components/Buttons/ButtonUI';
import { StepButton } from '../../../../components/components/components';
import { resetOnBoardingState } from '../../../../redux/onBoardingSlice';
import OrganisationCreatedDashboard from '../../../../components/components/onBoarding/organisationCreated/OrganisationCreatedDashboard';


interface createNewOrgModalProps {
  userId: string;
  open: boolean;
  onClose: () => void; // Function to call when closing the popup
}

const CreateNewOrgModal: React.FC<createNewOrgModalProps> = ({ userId, open, onClose }) => {
    const [step, setStep] = useState<OnboardingSteps>({ type: "SEARCH_PLACE", count: 1 });
    const [selectedPlace, setSelectedPlace] = useState<any | null>();
    const [thirdStepOrgName, setThirdStepOrgName] = useState("");
    const newOrgUid = useRef<null | string>();
    const isMobile = useMediaQuery('(max-height:800px');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const resetOnboardingProccess = () => {
        setStep({ type: "SEARCH_PLACE", count: 1 });
        setSelectedPlace(null);
        setThirdStepOrgName("");
        newOrgUid.current = null;
    };

    const handleSetStep = (newStep: OnboardingSteps) => {
        if (newStep.type === "SEARCH_PLACE") { resetOnboardingProccess(); }
        setStep(newStep);
    };

    const handleGoToDashboard = () => {
        onClose();
        resetOnboardingProccess();
    };

    const rightContent = () => {
        switch (step.type) {
                case "SEARCH_PLACE": return ( <GooglePlaceSearcher isShown={false} updateSelectedPlace={function (place: any): void { if (place && place.geometry) { setSelectedPlace(place); setStep({ type: "CONFIRM_ORG", count: 1 }); } }} updateOnboardingStep={handleSetStep} /> );
                case "CONFIRM_ORG": return ( <OrganizationSelector selectedPlace={selectedPlace} updateOnboardingData={function ( orgUid: string, orgName: string ): void { setThirdStepOrgName(orgName); newOrgUid.current = orgUid; }} updateOnboardingStep={handleSetStep} /> );
                // case "SELECT_ALUMINI_TYPE": return ( <AlumniSelector updateOnboardingStep={handleSetStep}/> );
                // case "SELECT_CAMPUS": return (<CampusSelector updateOnboardingStep={handleSetStep}/>);
                // case "SELECT_HOUSES": return (<HousesSelector updateOnboardingStep={handleSetStep}/>);           
                case "CREATE_SUCCESS": return ( <Stack marginTop={pxToRem(100)}><OrganisationCreatedDashboard onGoToDashboard={()=>handleGoToDashboard()}/></Stack>);
        }
    };

    useEffect(() => {
      if (open) {
          // Reset the onboarding state whenever the modal is opened
          dispatch(resetOnBoardingState());
      }
  }, [open, dispatch]);

  
  return (
    <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <div>
        <div className={styles.popupBackdrop}>
        </div>
        <div className={styles.popup}>
        {step.type !== "CREATE_SUCCESS" && (
          <IconButton className={styles.closeButton} onClick={()=> {onClose(); resetOnboardingProccess()}}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          </IconButton>
        )}
          <Stack>
          <Stack direction="row" className={styles.mainStack}>
          <Stack className={styles.leftStackWrapper}>
                <Stack className={styles.leftContent} style={{ backgroundSize: 'cover', backgroundPosition: 'right'}}>
                    <StepButton stepIndex={1} title={"Search for your organisation"} des={ "Search for the name of the organisation you are managing alumni for or enter their details manually" } activeStep={step.count} setStepIndex={handleSetStep} clickable={step.count > 1} step={{ type: "SEARCH_PLACE", count: 1 }} />
                    <StepButton title={"Confirm the details of your organisation"} des={ "Tell us more about your organisation so we can provide you with a better experience" } stepIndex={2} activeStep={step.count} setStepIndex={handleSetStep} clickable={false} step={{ type: "CONFIRM_ORG", count: 1 }} />
                    <StepButton stepIndex={3} title={"Start managing your alumni "} des={"Let’s stay in the loop!"} activeStep={step.count} setStepIndex={handleSetStep} clickable={false} step={{ type: "CREATE_SUCCESS", count: 3}} />
                </Stack>
            </Stack>
          <Stack className={styles.rightStack}>
                <Stack justifyContent={isMobile && step.count !== 3 ? "start" : "start"} alignItems="center" style={{height:'100%', width: '100%', overflow:'auto'}}>
                    {rightContent()}
                </Stack>
            </Stack>
          </Stack>
          </Stack>
        </div>
      </div>
    </Modal>

  );
};

export default CreateNewOrgModal;