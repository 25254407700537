import { collection, doc, DocumentData, DocumentReference, getCountFromServer, getDoc, getDocs, increment, query, runTransaction, serverTimestamp, Timestamp, where, writeBatch } from "firebase/firestore";
import { db } from ".";
import { formToAlumni, formToSchooling } from "./helpers";
import { getOrganisation, Res } from "./shared";
import { Alumni, Schooling } from "./types-alumni";
import { FormInfo, FormResponse, getForm, getFormInfo, getResponse } from "./types-registration";


const REG_COLLECTION = "registrations";
const REG_RESPONSES_SUBCOLLECTION = "responses";
const REG_FORMS_SUBCOLLECTION = "forms";

const ORG_COLLECTION = "organisations";
const ORG_ALUMNI_SUBCOLLECTION = "alumni";
const ORG_ALUMNI_SCHOOLING_SUBCOL = "schooling";

// Screening Process

export const approveAlumni = async(orgId: string, formId: string, regIds: string[]): Promise<Res<{id: string, name: string}>> => {
    try {
        let approvedUser:{id: string, name: string} = {id: "", name: ""};

        // Get approved user data
        let approvedUsers: {id: string, data: Alumni, schooling: Schooling, ref: DocumentReference<DocumentData, DocumentData>}[] = [];
        await runTransaction(db, async (t) => {
            const orgType = await getOrgType(orgId);
            for(const regId of regIds) {
                const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, regId);
                const alumniSnap = await t.get(alumniRef);
                if(alumniSnap.exists()) {
                    const data = getResponse(alumniSnap.data());
                    const alumniData = formToAlumni(data, orgId, formId);
                    const alumniSchooling = formToSchooling(data, orgId, orgType, regId);
                    approvedUsers.push({id: regId, data: alumniData, schooling: alumniSchooling, ref: alumniRef});
                }
            }
        })


        const batch = writeBatch(db);
        // Read data            
        for(const user of approvedUsers) {
            const orgDb = doc(db, ORG_COLLECTION, orgId, ORG_ALUMNI_SUBCOLLECTION, user.id);
            const schoolingRef = doc(collection(db, ORG_COLLECTION, orgId, ORG_ALUMNI_SUBCOLLECTION, user.id, ORG_ALUMNI_SCHOOLING_SUBCOL));
            batch.set(orgDb, user.data);
            batch.set(schoolingRef, user.schooling);
            // Write the first alumni approved to show in banner
            if(approvedUser.id==="" && approvedUser.name==="") {
                const displayName = user.data.firstName + " " + user.data.lastName;
                approvedUser = {
                    id: user.id,
                    name: displayName
                }
            }
            // Remove form response
            batch.delete(user.ref); 
        }
        // Update Alumni count
        const orgRef = doc(db, ORG_COLLECTION, orgId);
        batch.update(orgRef, {
            totalAlumni: increment(regIds.length),
            alumniLastUpdated: serverTimestamp()
        })
        const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
        batch.update(formRef, {
            pendingApproval: increment(-regIds.length),
            approvedCount: increment(regIds.length)
        })
        const orgFormRef = doc(db, REG_COLLECTION, orgId);
        batch.update(orgFormRef, {
            pendingApproval: increment(-regIds.length)
        })
        await batch.commit();
            
        console.log("transaction complete")
        return {code: 200, data: approvedUser };
    } catch (error) {
        console.log("Error occured whilst approving alumni data");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const rejectAlumni = async (orgId: string, formId: string, regIds: string[]) => {
    try {
        await runTransaction(db, async (t) => {
            for(const regId of regIds) {
                const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, regId);

                t.update(alumniRef,{
                    rejected: true,
                    expiry: Timestamp.fromDate(new Date(Date.now() + 12096e5))
                })
            }
            const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            t.update(formRef, {
                pendingApproval: increment(-regIds.length),
                rejectCount: increment(regIds.length)
            })
            const orgFormRef = doc(db, REG_COLLECTION, orgId);
            t.update(orgFormRef, {
                pendingApproval: increment(-regIds.length)
            })
        })
        return {code:200};
    } catch (error) {
        console.log("Error occured whilst rejecting alumni");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const restoreAlumni = async (orgId: string, formId: string, regIds: string[]) => {
    try {
        await runTransaction(db, async (t) => {
            for(const regId of regIds){
                const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, regId);
                t.update(alumniRef,{
                    rejected: false,
                    expiry: null
                })
            }
            const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            t.update(formRef, {
                pendingApproval: increment(regIds.length),
                rejectCount: increment(-regIds.length)
            })
            const orgFormRef = doc(db, REG_COLLECTION, orgId);
            t.update(orgFormRef, {
                pendingApproval: increment(regIds.length)
            })
        })
        return {code:200};
    } catch (error) {
        console.log("Error occured whilst restoring alumni");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const checkDuplicate = async (orgId: string, formId: string, regId: string): Promise<Res<{result: boolean, name?: string|undefined}>> => {
    try {
        const orgRef = collection(db, ORG_COLLECTION, orgId, ORG_ALUMNI_SUBCOLLECTION);
        const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, regId);
        const docSnap = await getDoc(alumniRef)
        if(docSnap.exists()) {
            const data = docSnap.data();
            // Check if user exists - same name and email address
            const q = query(orgRef, 
                where('firstName', '==', data.firstName),
                where('lastName', '==', data.lastName),
                where('emailPreferred', '==', data.email)
            )
            const docsSnap = await getDocs(q);
            if(docsSnap.docs.length > 0) {
                // There is a duplicate
                return {code:200, data: {result:true, name:data.firstName+" "+data.lastName}};
            }
        }

        return {code: 200, data: {
            result: false
        }};
    } catch (error) {
        console.log("Error occured whilst checking user duplicate");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const approveAllAlumni = async (orgId: string, formId: string) => {
    try {
        const orgType = await getOrgType(orgId);

        const allUserIds = collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION);
        const q = query(allUserIds);
        const snap = await getDocs(q);
        if(snap.docs.length > 0) {
            const batch = writeBatch(db);
            for(const document of snap.docs) {
                const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, document.id);
                const orgDb = doc(db, ORG_COLLECTION, orgId, ORG_ALUMNI_SUBCOLLECTION, document.id);
                const schoolingRef = doc(collection(db, ORG_COLLECTION, orgId, ORG_ALUMNI_SUBCOLLECTION, document.id, ORG_ALUMNI_SCHOOLING_SUBCOL));
                const response = getResponse(document.data());
                const alumniData = formToAlumni(response, orgId);
                const alumniSchooling = formToSchooling(response, orgId, orgType, document.id);
                batch.set(orgDb, alumniData);
                batch.set(schoolingRef, alumniSchooling);
                batch.delete(alumniRef);
            }
            const orgRef = doc(db, ORG_COLLECTION, orgId);
            batch.update(orgRef, {
                totalAlumni: increment(snap.docs.length),
                alumniLastUpdated: serverTimestamp()
            })
            const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            batch.update(formRef, {
                pendingApproval: increment(-snap.docs.length),
                approveCount: increment(snap.docs.length)
            })
            const orgFormRef = doc(db, REG_COLLECTION, orgId);
            batch.update(orgFormRef, {
                pendingApproval: increment(-snap.docs.length),
            })
            await batch.commit();
        }
        return { code: 200 };
    } catch (error) {
        console.log("Error occured whilst approving all users");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const rejectAllAlumni = async (orgId: string, formId: string) => {
    try {
        const allUserIds = collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION);
        const q = query(allUserIds);
        const snap = await getDocs(q);
        if(snap.docs.length > 0) {
            await runTransaction(db, async(t)=> {
                for(const document of snap.docs) {
                    const alumniRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION, document.id);
                    // TODO: Could revise here and simply delete the responses as the form will be closed.
                    t.update(alumniRef,{
                        rejected: true,
                        expiry: Timestamp.fromDate(new Date(Date.now() + 12096e5))
                    })
                }
                const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
                t.update(formRef, {
                    pendingApproval: increment(-snap.docs.length),
                    rejectCount: increment(snap.docs.length)
                })
                const orgFormRef = doc(db, REG_COLLECTION, orgId);
                t.update(orgFormRef, {
                    pendingApproval: increment(-snap.docs.length),
                })
            })
        }
        return { code: 200 };
    } catch (error) {
        console.log("Error occured whilst rejecting all users");
        console.log(error);
        return {
            code:500,
        };
    }
}

// Form Management

export const validateForm = async (orgId: string, formId: string) => {
    try {
        const docRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
        const docSnap = await getDoc(docRef);
        if(!docSnap.exists()) {
            return { code:500 };
        } 
        const form = getForm(docSnap.data());
        if(!form.isActive) {
            return { code:500 };
        }
        return { code:200 };
    } catch (error) {
        console.log("Error occured whilst rejecting all users");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const createNewForm = async (orgId:string, formInfo:FormInfo, formName: string) => {
    try {
        let newDocId = "";
        await runTransaction(db, async(t) => {
            const orgFormRef = doc(db,REG_COLLECTION, orgId);
            const newFormRef = doc(collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION));
            const orgRef = doc(db, ORG_COLLECTION, orgId);
            const orgSnap = await t.get(orgRef);
            if(orgSnap.exists()) {
                const org = getOrganisation(orgSnap.data());
                // Update new campuses, school houses and logo
                t.set(orgFormRef, {
                    campuses: org.campus || [],
                    schoolHouses: org.house || [],
                    logo: org.photoUrl || "",
                    pendingApproval: increment(0)
                })
                // Create new form document
                t.set(newFormRef, {
                    formInfo: formInfo,
                    formName: formName,
                    createdAt: serverTimestamp(),
                    isActive: true,
                    totalCount: 0,
                    pendingApproval: 0,
                    approvedCount: 0,
                    rejectCount: 0,
                });
                newDocId = newFormRef.id;
            }
        })
        return { code:200, data: newDocId };
    } catch (error) {
        console.log("Error occured whilst fetching organisation info");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const editForm = async (orgId: string, formId: string, formName: string, updatedFormInfo: FormInfo) => {
    try {
        const orgRef = doc(db, ORG_COLLECTION, orgId);
        const orgFormRef = doc(db, REG_COLLECTION, orgId);
        const formDocRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
        await runTransaction(db, async (t) => {
            // Update new campuses, school houses and logo
            const orgSnap = await t.get(orgRef);
            if(orgSnap.exists()) {
                const orgData = getOrganisation(orgSnap.data());
                t.update(orgFormRef, {
                    campuses: orgData.campus || [],
                    schoolHouses: orgData.house || [],
                    logo: orgData.photoUrl || ""
                })
            }
            t.update(formDocRef, {
                formInfo: updatedFormInfo,
                formName: formName,
                lastEdited: serverTimestamp(),
            })
        })
        return { code:200 };
    } catch (error) {
        console.log("Error occured whilst editing form");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const closeForm = async (orgId: string, formId: string) => {
    try {
        await runTransaction(db, async (t) => {
            const formDocRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            const formSnap = await t.get(formDocRef);
            let formPendingCount;
            if(formSnap.exists()) {
                const formData = formSnap.data();
                formPendingCount = formData.pendingApproval;
            }
            t.update(formDocRef, {
                isActive: false,
                closedAt: serverTimestamp(),
            })
            const orgFormRef = doc(db, REG_COLLECTION, orgId);
            t.update(orgFormRef, {
                pendingApproval: increment(formPendingCount || 0),
            })
        })
        
        
        return { code: 200 };
    } catch (error) {
        console.log("Error occured whilst closing form");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const deleteForm = async (orgId: string, formId: string): Promise<Res<number>> => {
    try {
        // Check there are no respondents pending approval
        const formResRef = collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION);
        const countQ = await getCountFromServer(query(formResRef, where("rejected","==",false)));
        if(countQ.data().count > 0) {
            return { code:200, data: countQ.data().count};
        }
        
        const responses = await getDocs(formResRef); 
        const formDocRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
        const batch = writeBatch(db);
        // Delete respondents 
        for(const doc of responses.docs) {
            batch.delete(doc.ref);
        }
        // Delete form itself
        batch.delete(formDocRef);
        await batch.commit();
        return { code: 200, data: 0 };
    } catch (error) {
        console.log("Error occured whilst deleting form");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const deleteForms = async (orgId: string, formIds: string[]) => {
    try {
        let respondentsMap = new Map<string, DocumentReference<DocumentData, DocumentData>[]>();
        // Retrieve respondent IDs
        for(const formId of formIds) {
            const formResRef = collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION);
            const res = await getDocs(formResRef);
            respondentsMap.set(formId, res.docs.map(r => r.ref));
        }
        // Delete form and corresponding respondents
        for(const formId of formIds) {
            const batch = writeBatch(db);
            const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            const resRefs = respondentsMap.get(formId);
            if(resRefs) {
                for(const r of resRefs) {
                    batch.delete(r);
                }
            }
            batch.delete(formRef);
            await batch.commit();
        }
        return { code: 200 };
    } catch (error) {
        console.log("Error occured whilst deleting form");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const reactivateForm = async (orgId: string, formIds: string[]) => {
    try {
        await runTransaction(db, async (t) => {
            for(const formId of formIds) {
                const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
                t.update(formRef, {
                    isActive: true,
                    closedAt: null,
                });
            }
        })
        return { code: 200 };
    } catch (error) {
        console.log("Error occured whilst reactivating form");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const checkPendingCount = async(orgId: string, formId: string): Promise<Res<number>> => {
    try {
        const resRef = collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION);
        const q = query(resRef, where("rejected", "==", false));
        const snapshot = await getCountFromServer(q);   
        const resCount = snapshot.data().count;
        return { code: 200, data:resCount};
    } catch (error) {
        console.log("Error occured whilst checking count");
        console.log(error);
        return {
            code:500,
        };
    }
}

// User form submissions

export const getFormData = async (orgId: string, formId: string): Promise<Res<FormInfo>> => {
    try {
        const docRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            return {
                code: 200,
                data: getFormInfo(docSnap.data()),
            }
        } else {
            console.log("Document does not exist")
            return {
                code: 500
            }
        }
    } catch (error) {
        console.log("Error occured whilst fetching organisation info");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const getOrgData = async (orgId: string): Promise<Res<{campuses:string[], schoolHouses: string[], logo: string}>> => {
    try {
        const docRef = doc(db, REG_COLLECTION, orgId);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            const data = docSnap.data();
            return {
                code: 200,
                data: {
                    campuses: data.campuses,
                    schoolHouses: data.schoolHouses,
                    logo: data.logo,
                },
            }
        } else {
            console.log("Document does not exist")
            return {
                code: 500
            }
        }
    } catch (error) {
        console.log("Error occured whilst fetching organisation info");
        console.log(error);
        return {
            code:500,
        };
    }
}

export const submitRegistration = async (orgId: string, formId: string, formResponse: FormResponse) => {
    try {
        await runTransaction(db, async(transaction) => {
            const resRef = doc(collection(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId, REG_RESPONSES_SUBCOLLECTION));
            const formRef = doc(db, REG_COLLECTION, orgId, REG_FORMS_SUBCOLLECTION, formId);
            transaction.set(resRef, formResponse);
            transaction.update(formRef, {
                totalCount: increment(1),
                pendingApproval: increment(1),
                lastSubmission: serverTimestamp()
            })
            const formOrgRef = doc(db, REG_COLLECTION, orgId);
            transaction.update(formOrgRef, {
                pendingApproval: increment(1),
            })
        })
        return { code:200, data: "Form submitted successfully!"};
    } catch (error) {
        console.log("Error occured whilst submitting registration form");
        console.log(error);
        return {
            code:500,
        };
    }
}

const getOrgType = async (orgId: string) => {
    const orgRef = doc(db, ORG_COLLECTION, orgId);
    const orgSnap = await getDoc(orgRef);
    if(!orgSnap.exists()) {
        console.log("couldn't fetch organisation");
        return;
    }
    return getOrganisation(orgSnap.data()).type;
}