import { Button, Checkbox, DialogContent, DialogContentText, Divider, FormControlLabel, Modal, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import React, { CSSProperties, useEffect, useState } from "react";
import pxToRem from "../../helpers/pxToRem";
import globalStyles from "../../styles/_stylesParams.module.scss";
import { PrimaryButton, PrimaryDangerButton, SecondaryButton } from "../Buttons/ButtonUI";
import LoadingComponent from "../LoadingStatus/Loading";
import styles from "./index.module.scss";

const style = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: pxToRem(600), boxShadow: `0px ${pxToRem(4)} ${pxToRem(4)} 0px rgba(0, 0, 0, 0.25)`, };
export type AlertSeverity = "error" | "warning" | "info" | "success" | "primary";
interface IAlert { severity?: AlertSeverity; title?: string; message: string; visible: boolean; handleClose: (state: boolean) => void; handleRefuse?: () => void; }
interface IAlertDialog { severity?: AlertSeverity; title?: string; subTitle?: string, messageStyle?: React.CSSProperties, message: string; visible: boolean; primaryButtonState?: boolean; primaryButtonTitle?: string, primaryButtonClose?: () => void; primaryButtonStyle?: CSSProperties, secondaryButtonTitle?: string, secondaryButtonClose?: () => void; secondaryButtonStyle?: CSSProperties; }
const AlertComponent = ({ severity = "error", title, message, visible, handleClose, handleRefuse }: IAlert) => {
    return (
        <>
            {
                message ?
                    <Dialog open={visible} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                        <DialogTitle style={{ paddingTop: pxToRem(24) }} id="alert-dialog-title">{title ?? "Alert"}</DialogTitle>
                        <DialogContent> <DialogContentText id="alert-dialog-description">{message}</DialogContentText> </DialogContent>
                        <DialogActions style={{ justifyContent: 'space-between', paddingLeft: pxToRem(24), paddingRight: pxToRem(24), paddingBottom: pxToRem(22), direction: 'rtl' }}>
                            <Button variant="outlined" sx={{ width: pxToRem(100), height: pxToRem(32), '&:hover': { color: globalStyles["primary500"] }, }}
                                color={severity === 'error' ? 'success' : severity} onClick={() => { handleClose(false) }}>OK</Button>
                            {handleRefuse ? (<Button color={severity} style={{ paddingRight: pxToRem(8), paddingLeft: pxToRem(8) }} onClick={() => { handleRefuse(); }}>Cancel</Button>) : null}
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }
        </>
    );
};
export const AlertDialogComponent = ({ visible, severity = "error", title, subTitle, message, messageStyle = { textAlign: 'left' }, primaryButtonState, primaryButtonTitle, primaryButtonClose, primaryButtonStyle, secondaryButtonTitle, secondaryButtonClose, secondaryButtonStyle }: IAlertDialog) => {
    let backgroundColor = globalStyles["secondaryPink600"];
    if (severity === "warning") backgroundColor = globalStyles["secondaryYellow500"];
    else if (severity === "info") backgroundColor = globalStyles["secondaryBlue500"];
    else if (severity === "success") backgroundColor = globalStyles["secondaryGreen500"];
    else if (severity === "primary") backgroundColor = globalStyles["primary500"];
    return (
        <Modal open={visible} onClose={() => { }}>
            <Stack sx={style} className={styles.modalContentWrapper}>
                <div className={`${styles.colorBanner}`} style={{ backgroundColor: backgroundColor }} ></div>
                <Stack>
                    {(title || subTitle) ? <> <Stack direction={"column"} style={{ marginTop: pxToRem(20) }}> <label className={styles.modalTitle}>{title}</label> <label className={styles.modalSubtitle}>{subTitle}</label> </Stack> <Divider style={{ marginTop: 25, marginBottom: 25 }} /> </> : null}
                    <Stack className={styles.popupContent} style={{ paddingRight: pxToRem(10), paddingBottom: pxToRem(10) }}>
                        <Stack className={styles.form}>
                            <div style={messageStyle} dangerouslySetInnerHTML={{ __html: message }} />
                        </Stack>
                    </Stack>
                    <Divider style={{ marginTop: 25, marginBottom: 30 }} />
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton style={secondaryButtonStyle} className={styles.button} onClick={() => { if (secondaryButtonClose) secondaryButtonClose(); }}>{secondaryButtonTitle}</SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryDangerButton loading={primaryButtonState} disabled={primaryButtonState} style={primaryButtonStyle} className={styles.button} onClick={() => { if (primaryButtonClose) primaryButtonClose(); }}>{primaryButtonTitle}</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};

interface IAlertDialogWithCheckbox {
    visible: boolean;
    severity?: "error" | "warning" | "info" | "success" | "primary";
    title?: string;
    subTitle?: string;
    message: string;
    messageStyle?: React.CSSProperties;
    primaryButtonState?: boolean;
    primaryButtonTitle: string; // Now mandatory
    primaryButtonClose: () => void; // Now mandatory
    primaryButtonStyle?: React.CSSProperties;
    secondaryButtonTitle?: string;
    secondaryButtonClose?: () => void;
    secondaryButtonStyle?: React.CSSProperties;
}

export const AlertDialogComponentWithCheckbox = ({
    visible,
    title,
    subTitle,
    message,
    messageStyle = { textAlign: 'left' },
    primaryButtonState,
    primaryButtonTitle,
    primaryButtonClose,
    primaryButtonStyle,
    secondaryButtonTitle,
    secondaryButtonClose,
    secondaryButtonStyle
}: IAlertDialogWithCheckbox) => {
    const [showAlert, setShowAlert] = useState(visible);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    useEffect(() => {
        const alertPreference = localStorage.getItem('alertPreference') === 'true';
        setShowAlert(visible && !alertPreference);
        setDontShowAgain(alertPreference);
    }, [visible]);

    const handleDontShowAgainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDontShowAgain(event.target.checked);
    };

    const handleClose = () => {
        if (dontShowAgain) {
            localStorage.setItem('alertPreference', 'true');
        }
        primaryButtonClose(); // Execute the provided primaryButtonClose function
    };

    let backgroundColor = globalStyles["secondaryPink600"];

    return (
        <Modal open={showAlert} onClose={() => { }}>
            <Stack sx={style} className={styles.modalContentWrapper}>
            <div className={`${styles.colorBanner}`} style={{ backgroundColor: backgroundColor }} ></div>
                {(title || subTitle) && (
                    <>
                        <Stack direction="column" style={{ marginTop: 20 }}>
                            {title && <label className={styles.modalTitle}>{title}</label>}
                            {subTitle && <label className={styles.modalSubtitle}>{subTitle}</label>}
                        </Stack>
                        <Divider style={{ marginTop: 25, marginBottom: 25 }} />
                    </>
                )}
                <Stack style={{ paddingRight: 10, paddingBottom: 10 }}>
                    <div style={messageStyle} dangerouslySetInnerHTML={{ __html: message }} />
                   
                </Stack>
                <Stack alignItems={'center'}>
                <FormControlLabel
                        control={<Checkbox checked={dontShowAgain} onChange={handleDontShowAgainChange} />}
                        label="Do not show this message again"
                    />
                </Stack>
                
                <Divider style={{ marginTop: 25, marginBottom: 30 }} />
                <Stack direction={"row"} justifyContent={secondaryButtonTitle && secondaryButtonClose ? 'space-between' : 'flex-end'}>
                {secondaryButtonTitle && <SecondaryButton style={secondaryButtonStyle} onClick={secondaryButtonClose}>{secondaryButtonTitle}</SecondaryButton>}
                    <PrimaryButton variant="outlined" color={'error'} disabled={primaryButtonState} style={primaryButtonStyle} onClick={handleClose}>{primaryButtonTitle}</PrimaryButton>
                </Stack>
            </Stack>
        </Modal>
    );
};


export const AlertDialogSimpleComponent = ({ visible, severity = "success", message, primaryButtonTitle, primaryButtonState,primaryButtonStyle, primaryButtonClose, secondaryButtonTitle, secondaryButtonClose }: IAlertDialog) => {
    let backgroundColor = globalStyles["secondaryPink600"];
    if (severity === "warning") backgroundColor = globalStyles["secondaryYellow500"];
    else if (severity === "info") backgroundColor = globalStyles["secondaryBlue500"];
    else if (severity === "success") backgroundColor = globalStyles["primary400"];

    return (
        <Modal open={visible} onClose={() => { }}>
            <Stack sx={style} className={styles.modalContentWrapper}>
                <div className={`${styles.colorBanner}`} style={{ backgroundColor: backgroundColor }} ></div>
                <Stack>
                    <Stack className={styles.popupContent} style={{ paddingRight: pxToRem(10), paddingBottom: pxToRem(10), marginTop: pxToRem(60) }}>
                        <Stack className={styles.form} > <div dangerouslySetInnerHTML={{ __html: message }} /> </Stack>
                    </Stack>
                    <Divider style={{ marginTop: 25, marginBottom: 30 }} />
                    <Stack direction={"row"} justifyContent={secondaryButtonTitle && secondaryButtonClose ? 'space-between' : 'flex-end'}>
                        {secondaryButtonTitle && secondaryButtonClose ? <SecondaryButton className={styles.button} onClick={() => { if (secondaryButtonClose) secondaryButtonClose(); }}>{secondaryButtonTitle}</SecondaryButton> : null}
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton loading={primaryButtonState} disabled={primaryButtonState} className={styles.button} style={primaryButtonStyle} onClick={() => { if (primaryButtonClose) primaryButtonClose(); }}>{primaryButtonTitle}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};

export const AlertDialogLoadingComponent = ({ visible, severity = "error", title, subTitle, message, messageStyle = { textAlign: 'left' }, primaryButtonState, primaryButtonTitle, primaryButtonClose, primaryButtonStyle, secondaryButtonTitle, secondaryButtonClose, secondaryButtonStyle }: IAlertDialog) => {
    let backgroundColor = globalStyles["secondaryPink600"];
    if (severity === "warning") backgroundColor = globalStyles["secondaryYellow500"];
    else if (severity === "info") backgroundColor = globalStyles["secondaryBlue500"];
    else if (severity === "success") backgroundColor = globalStyles["secondaryGreen500"];
    else if (severity === "primary") backgroundColor = globalStyles["primary500"];
    return (
        <Modal open={visible} onClose={() => { }}>
            <Stack sx={style} className={styles.modalContentWrapper}>
                <div className={`${styles.colorBanner}`} style={{ backgroundColor: backgroundColor }} ></div>
                <Stack>
                    {(title || subTitle) ? <> <Stack direction={"column"} style={{ marginTop: pxToRem(20) }}> <label className={styles.modalTitle}>{title}</label> <label className={styles.modalSubtitle}>{subTitle}</label> </Stack> <Divider style={{ marginTop: 25, marginBottom: 25 }} /> </> : null}
                    {primaryButtonState ? (
                        <LoadingComponent isLoading={true} loadingStr={`Reverting import… <br/>please do not refresh or leave the page`} loadingColor={"#de625b"}/>
                    ) : (
                        <Stack className={styles.popupContent} style={{ paddingRight: pxToRem(10), paddingBottom: pxToRem(10) }}>
                            <Stack className={styles.form}>
                                <div style={messageStyle} dangerouslySetInnerHTML={{ __html: message }} />
                            </Stack>
                        </Stack>
                    )}

                    <Divider style={{ marginTop: 25, marginBottom: 30 }} />
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton style={secondaryButtonStyle}  disabled={primaryButtonState} className={styles.button} onClick={() => { if (secondaryButtonClose) secondaryButtonClose(); }}>{secondaryButtonTitle}</SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryDangerButton  disabled={primaryButtonState} style={primaryButtonStyle} className={styles.button} onClick={() => { if (primaryButtonClose) primaryButtonClose(); }}>{primaryButtonTitle}</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};

export const AlertDialogSidebarComponent = ({ title, subTitle, visible, severity = "success", message, primaryButtonTitle, primaryButtonState, primaryButtonClose, secondaryButtonTitle, secondaryButtonClose}: IAlertDialog) => {
    let backgroundColor = globalStyles["secondaryPink600"];
    if(severity === "warning") backgroundColor = globalStyles["secondaryYellow500"];
    else if(severity === "info") backgroundColor = globalStyles["secondaryBlue500"];
    else if(severity === "success") backgroundColor = globalStyles["primary400"];
  
    return (
        <Modal open={visible} onClose={()=>{}}>
           <Stack sx={style} className={styles.modalContentWrapper}>
                <div className={`${styles.colorBanner}`} style={{ backgroundColor: backgroundColor}} ></div>
                <Stack>
                { (title || subTitle)? <> <Stack direction={"column"} style={{ marginTop: pxToRem(20) }}> <label className={styles.modalTitle}>{title}</label> <label className={styles.modalSubtitle}>{subTitle}</label> </Stack> <Divider style={{ marginTop:12, marginBottom:12 }}/> </> : null }
                    <Stack className={styles.popupContent} style={{paddingRight: pxToRem(10), paddingBottom: pxToRem(10)}}> 
                        <Stack className={styles.form} > <div dangerouslySetInnerHTML={{ __html: message }} /> </Stack> 
                    </Stack>
                    <Divider style={{ marginTop:12, marginBottom:12 }}/>
                    <Stack direction={"row"} justifyContent={secondaryButtonTitle && secondaryButtonClose ? 'space-between' : 'flex-end'}>
                        { secondaryButtonTitle && secondaryButtonClose ? <SecondaryButton className={styles.button} onClick={() => { if(secondaryButtonClose) secondaryButtonClose(); }}>{secondaryButtonTitle}</SecondaryButton> :null }
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton loading={primaryButtonState} disabled={primaryButtonState} className={styles.button} onClick={() => { if(primaryButtonClose) primaryButtonClose(); }}>{primaryButtonTitle}</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    );
};

export default AlertComponent;