import { IconButton, Menu, MenuItem, Snackbar, Stack, TextField, Tooltip } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import Line from "../../../components/Line/Line";
import constants from "../../../helpers/constants";
import { getAlumniProfileError, getErrorMsgForInput, getHelpDeskMessageError } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import styles from "./index.module.scss";
import globalStyles from "../../../styles/_stylesParams.module.scss"
import { deleteField, FieldValue, serverTimestamp } from "firebase/firestore";
import { createFile, deleteFile, fetchFilesForNetwork, updateFile } from "../../../firebase/networkMediaApis";
import { getTimeDiffDes2DatesMillsNow } from "../../../helpers/timeUtils";
import { useSelector } from "react-redux";
import { PermissionState } from "../../../firebase/types-organisations";
import { RootState } from "../../../redux";
import { ITabInterface } from "../../AlumniPage/tabs/TabInterfaces";
import { FileData } from "../../../firebase/type-media";
import dayjs, { Dayjs } from 'dayjs';
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { handleLinkClick } from "../../SelfRegistrationFormsPage/helpers";
import AutoCloseSnackbar from "../../../components/SnackBarComponent/autoCloseSnackBar";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import AlertComponent, { AlertDialogComponent } from "../../../components/AlertComponent";

interface IFileTabInterface {
    orgId: string | undefined | null;
    orgName: string | undefined | null;
    networkId: string | undefined | null;
}

const storage = getStorage();


const FileTab = ({ orgId, networkId }: IFileTabInterface) => {
    const [isFileModal, setIsFileModal] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuShowId, setMenuShowId] = useState(-1);
    const [errorMsg, setErrorMsg] = useState("");
    const [openCopyBar, setOpenCopyBar] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [filename, setFilename] = useState<string | undefined>();
    const title = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });
    const [selectedFile, setSelectedFile] = useState<any>();
    const [errorStr, setErrorStr] = useState<string | undefined>();


    const networkPermission = useSelector((state: RootState) => state.globalState.permissions?.network);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null)
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false)

    const [filesList, setFilesList] = useState<FileData[]>([]);

    useEffect(() => {
        if (orgId && networkId) {
            fetchFilesForNetwork(orgId, networkId)
                .then(fetchedFiles => {
                    setFilesList(fetchedFiles);
                    console.log(filesList)
                })
                .catch(error => {
                    console.error("Failed to load Files:", error);
                });
        }
    }, [orgId, networkId, triggerUpdate]);

    const columnHelper = createColumnHelper<FileData>();
    const resetForm = () => {
        setFilename(undefined);
        title.setValue(""); title.setHasTouched(false);
        if (inputRef.current) { inputRef.current.value = ''; }
        setSelectedFile(null)
        setCurrentDeleteId(null);
        setMenuShowId(-1);
    }

    const formatDate = (timestamp: number) => {
        // Check if the timestamp is in seconds (usually a 10-digit number)
        const date = timestamp.toString().length === 10
            ? dayjs.unix(timestamp) // Convert from seconds to date
            : dayjs(timestamp); // Assume milliseconds if not in seconds

        const today = dayjs();

        return date.isSame(today, 'day')
            ? 'Today'
            : date.format('D MMM YY');
    };


    const addData = async () => {
        if (!orgId || !networkId) { return; }
        if (!selectedFile) {
            setErrorMsg("No file selected.");
            return;
        }
        const inputs = [title];   // Mandatory Fields
        let validated = true;
        inputs.map((input) => input.setHasTouched(true));
        inputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
        if (!validated) { return; }

        const file = selectedFile
        setLoadingStatus(true);
        setLoadingStatusStr("Uploading...");

        try {
            // Upload file to Firebase Storage and get the download URL
            const fileRef = ref(storage, `mediaFiles/${orgId}/${networkId}/${file.name}`);
            const uploadResult = await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(uploadResult.ref);

            const fileSizeInKB = (file.size / 1024).toFixed(2);

            const fileData = {
                name: file.name,
                title: title.value.trim(),
                link: downloadURL,
                size: `${fileSizeInKB} KB`,
                uploaded_at: serverTimestamp(),
            };

            // Save file metadata in Firestore
            const res = await createFile(orgId, networkId, fileData);
            if (res.code === 200) {
                setLoadingStatusStr("File uploaded successfully.");
                setIsFileModal(false);
                setTriggerUpdate(prev => !prev);
            } else {
                throw new Error('Failed to save file metadata.');
            }
        } catch (error) {
            console.error("Errors happened when trying to upload and save file.", error);
            setErrorMsg("Errors: file upload and save.");
        }

        setLoadingStatus(false);
        setLoadingStatusStr("");
        resetForm();
    };


    const pictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            readFile(event.target.files[0])
        }
        event.target.value = '';
    }


    const readFile = (file: File) => {
        if (!file) {
            setErrorStr("File not found.");
            setFilename(undefined);
            title.setValue(""); title.setHasTouched(false);
            return;
        }

        const maxFileSize = 5 * 1024 * 1024; // 1 MB
        const invalidTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        // Check file size
        if (file.size > maxFileSize) {
            setErrorStr("Max File Size Exceeded (5 MB limit)");
            setFilename(undefined);
            title.setValue(""); title.setHasTouched(false);
            return;
        }

        // Check file type
        if (invalidTypes.includes(file.type)) {
            setErrorStr("Unsupported file type. JPEG, PNG, and JPG files are not allowed.");
            setFilename(undefined);
            title.setValue(""); title.setHasTouched(false);
            return;
        }

        // If file is valid
        setFilename(file.name);
        setSelectedFile(file);
        setErrorStr(undefined); // Clear any previous errors
        // Further file processing logic goes here
    };

    const getFileTitleById = (fileId: string | null): string | null => {
        const foundFile = filesList.find(link => link.id === fileId);
        return foundFile ? foundFile.name || null : null;
    };



    const deleteData = async () => {
        if (!orgId || !networkId || !currentDeleteId) { return }
        setDeleteLoadingStatus(true);
        try { await deleteFile(orgId, networkId, currentDeleteId) }
        catch (ex) { setErrorMsg("Errors: link delete, please try again"); console.log(ex); }
        setDeleteLoadingStatus(false);
        setIsDeleteModal(false);
        setCurrentDeleteId(null);
        setMenuShowId(-1);
        setTriggerUpdate(prev => !prev)
        resetForm();
    }

    const columns = [
        columnHelper.accessor('title', { cell: info => info.getValue(), header: "Title" }),
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Type" }),
        columnHelper.accessor('size', { cell: info => info.getValue(), header: "File Size" }),
        columnHelper.accessor('uploaded_at', { cell: info => info.getValue(), header: "Uploaded" }),
        columnHelper.accessor('id', { cell: info => info.getValue(), header: "" }),
    ];

    const viewerColumns = [
        columnHelper.accessor('title', { cell: info => info.getValue(), header: "Title" }),
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Type" }),
        columnHelper.accessor('size', { cell: info => info.getValue(), header: "File Size" }),
        columnHelper.accessor('uploaded_at', { cell: info => info.getValue(), header: "Uploaded" }),

    ];

    const table = useReactTable({
        data: filesList,
        columns: (networkPermission && networkPermission === PermissionState.EDITOR) ? columns : viewerColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const handleLinkClick = (url: string) => {
        // Preventing default behavior is not necessary unless inside a form or similar.
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank'; // Open in a new tab
        anchor.rel = 'noopener noreferrer'; // Security measure for opening new tabs

        // This part forces the browser to download the resource if it's downloadable
        // anchor.download = 'filename.ext'; // Uncomment and edit if you know the file name and extension

        // Append the anchor to the body, click it, and remove it
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (
        <>
            <AlertComponent visible={errorMsg !== ""} handleClose={() => setErrorMsg("")} title={"Alert"} message={errorMsg} />
            <AutoCloseSnackbar
                open={openCopyBar}
                message="Copy to Clipboard"
                onClose={() => setOpenCopyBar(false)}
                duration={2000}  // Optional
            />
            <CustomizedModal width={660} open={isFileModal} handleClose={() => { }} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack width={"100%"} alignItems={"end"}>
                        <IconButton onClick={() => { setIsFileModal(false); resetForm(); }}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.media_details_modalHeaderTitleWrapper}>
                        <div className={styles.media_details_iconWithCircleWrapper}> <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M14 17H8M16 13H8M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round" /></svg></div>
                        <div style={{ flexGrow: 1 }}>
                            <label className={styles.media_details_modalHeaderTitle}> {"Upload New File"} </label>
                        </div>
                    </Stack>
                    <Stack spacing={pxToRem(24)} className={styles.importModal}>
                        <Stack spacing={1.5} className={styles.uploadPictureBox} onClick={() => inputRef.current?.click()}>
                            <input ref={inputRef} type='file' onChange={pictureChange} multiple={false} style={{ display: "none" }} />
                            <Stack className={styles.uploadIconWrapper}>
                                {filename ? (
                                    <svg width={pxToRem(77)} height={pxToRem(76)} viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1772 39.0615C61.1709 39.7912 68.2871 41.4015 74.2249 44.6699C82.0301 48.9664 63.0185 50.6033 61.976 56.9013C60.8454 63.7317 80.1382 72.263 69.2767 74.5645C59.4677 76.6429 50.2082 72.5378 40.401 70.4563C27.8539 67.7931 6.15014 68.0845 1.67659 60.6149C-2.93242 52.9191 14.0362 48.3959 24.849 43.3643C33.4426 39.3654 42.3959 38.0773 53.1772 39.0615Z" fill="#F5F5F5" />
                                        <path d="M28.6225 57.163H51.3761C55.9653 57.163 58.3345 54.751 58.3345 50.1406V31.0369H42.3259C39.6578 31.0369 38.3771 29.7562 38.3771 27.0881V10.9087H28.6225C24.0547 10.9087 21.6641 13.3207 21.6641 17.9525V50.1406C21.6641 54.751 24.0547 57.163 28.6225 57.163ZM42.7314 27.9205H58.1211C57.993 26.9387 57.2886 26.0209 56.1787 24.8896L44.5031 13.0645C43.4358 11.9759 42.4753 11.2502 41.4934 11.1221V26.6825C41.5148 27.515 41.9203 27.9205 42.7314 27.9205ZM39.9993 51.6347C39.0174 51.6347 38.2063 50.8876 38.2063 49.9271V42.8833L38.3771 39.7029L36.7549 41.3678L35.0046 43.1394C34.6844 43.4596 34.2362 43.6731 33.8093 43.6731C32.8701 43.6731 32.1657 43.0327 32.1657 42.1149C32.1657 41.6026 32.3792 41.2184 32.742 40.8769L38.6332 35.4766C39.1028 35.0497 39.5084 34.8576 39.9993 34.8576C40.5116 34.8576 40.8958 35.0497 41.3867 35.4766L47.2566 40.8769C47.6194 41.2184 47.8329 41.6026 47.8329 42.1149C47.8329 43.0327 47.1285 43.6731 46.1893 43.6731C45.7411 43.6731 45.3142 43.4596 44.994 43.1394L43.2437 41.3678L41.6429 39.7029L41.7923 42.8833V49.9271C41.7923 50.8876 40.9812 51.6347 39.9993 51.6347Z" fill="#447D75" />
                                    </svg>

                                ) : (
                                    <svg width={pxToRem(77)} height={pxToRem(76)} viewBox="0 0 77 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1772 39.1968C61.1709 39.9265 68.2871 41.5367 74.2249 44.8052C82.0301 49.1016 63.0185 50.7385 61.976 57.0366C60.8454 63.8669 80.1382 72.3983 69.2767 74.6997C59.4677 76.7782 50.2082 72.6731 40.401 70.5915C27.8539 67.9284 6.15014 68.2197 1.67659 60.7501C-2.93242 53.0544 14.0362 48.5311 24.849 43.4995C33.4426 39.5006 42.3959 38.2125 53.1772 39.1968Z" fill="#F5F5F5" />
                                        <path d="M21.8529 36.0453C22.5467 35.9385 23.2196 35.8745 23.9345 35.8745C24.6283 35.8745 25.3222 35.9385 26.0371 36.0666V18.0729C26.0371 16.1305 27.0464 15.0419 29.0859 15.0419H38.0011V26.6535C38.0011 29.7485 39.4939 31.2427 42.5217 31.2427H53.7918V49.7273C53.7918 51.6911 52.7825 52.7583 50.743 52.7583H37.3913C37.139 54.2951 36.5713 55.7466 35.7933 57.0273H51.1215C55.6421 57.0273 57.976 54.6153 57.976 50.0048V30.6237C57.976 27.6354 57.5975 26.2693 55.7683 24.3696L44.7715 13.0355C42.9843 11.1998 41.5124 10.7729 38.8631 10.7729H28.7074C24.2078 10.7729 21.8529 13.1849 21.8529 17.8168V36.0453ZM41.6596 26.248V15.5969L53.2241 27.5073H42.9212C42.0381 27.5073 41.6596 27.1231 41.6596 26.248ZM23.9555 60.912C29.8429 60.912 34.784 55.9173 34.784 49.8981C34.784 43.8788 29.8849 38.9055 23.9555 38.9055C18.0261 38.9055 13.127 43.8788 13.127 49.8981C13.127 55.9387 18.0261 60.912 23.9555 60.912ZM17.0799 49.8981C17.0799 49.0229 17.6686 48.4466 18.5307 48.4466H22.5047V44.4125C22.5047 43.5373 23.0724 42.9397 23.9555 42.9397C24.8386 42.9397 25.4063 43.5373 25.4063 44.4125V48.4466H29.3803C30.2424 48.4466 30.8101 49.0229 30.8101 49.8981C30.8101 50.7946 30.2424 51.3709 29.3803 51.3709H25.4063V55.4264C25.4063 56.3015 24.8386 56.8992 23.9555 56.8992C23.0724 56.8992 22.5047 56.3015 22.5047 55.4264V51.3709H18.5307C17.6686 51.3709 17.0799 50.7946 17.0799 49.8981Z" fill="#447D75" />
                                    </svg>)}
                            </Stack>
                            {filename ? (
                                <Stack>
                                    <div className={styles.filenameContainer} onClick={(e) => e.stopPropagation()}>
                                        <p>{filename}</p>
                                        <Tooltip title="Remove file">
                                            <IconButton onClick={() => { setFilename(undefined); title.setValue(""); title.setHasTouched(false); if (inputRef.current) { inputRef.current.value = ''; }; setSelectedFile(null) }} className={styles.removeFileButton}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Stack>
                            ) : (
                                <Stack direction={"row"} spacing={0.5}>
                                    <label className={styles.uploadTitleBig}>Click to choose file or drag and drop</label>
                                </Stack>
                            )}
                            {errorStr && <label className={styles.uploadError}>{errorStr}</label>}
                        </Stack>
                    </Stack>
                    {filename && (
                        <Stack direction={'row'} marginTop={pxToRem(24)} spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <small>File Title :</small>
                            <InputTextField type={"text"} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={title.value} containerStyle={{ flex: 1 }} label='Please enter a file title' value={title.value} onChange={(e) => title.setValue(e.target.value)} error={title.hasError && title.hasTouched} helperText={<small>{title.getErrorMessage()}</small>} />

                        </Stack>)}

                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: pxToRem(24), marginTop: pxToRem(24) }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>

                        <SecondaryButton className={styles.button} onClick={() => { setIsFileModal(false); resetForm(); }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} disabled={!selectedFile} onClick={() => { addData() }}>Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} isLoading={deleteLoadingStatus} loadingStr={"Removing..."} width={600}>
                <Stack>
                    <Stack style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24) }} spacing={3} alignItems={'center'}>
                        <label className={styles.alertBodyText}>{`Are you sure you want to delete ${getFileTitleById(currentDeleteId)}?`}</label>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(null); }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <PrimaryButton className={styles.button} onClick={deleteData}> Yes </PrimaryButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={pxToRem(24)}>
                {(networkPermission && networkPermission === "EDITOR") ?
                    (<PrimaryButton className={styles.button} onClick={() => setIsFileModal(true)}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5 12V16.2C21.5 17.8802 21.5 18.7202 21.173 19.362C20.8854 19.9265 20.4265 20.3854 19.862 20.673C19.2202 21 18.3802 21 16.7 21H8.3C6.61984 21 5.77976 21 5.13803 20.673C4.57354 20.3854 4.1146 19.9265 3.82698 19.362C3.5 18.7202 3.5 17.8802 3.5 16.2V12M16.5 7L12.5 3M12.5 3L8.5 7M12.5 3V15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Upload
                    </PrimaryButton>) : <h6 style={{ flexGrow: 1 }} className='light'>File Table</h6>}
                <Stack className={styles.numberBox} direction={'column'} spacing={1}>
                    <small>{filesList.length}</small>
                    <small>Files</small>
                </Stack>
            </Stack>

            <Stack>
                <table className={styles.media_table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }} >
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                            {(header.column.id === 'uploaded_at') &&
                                                <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                    <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                    <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            // status === "loading" || status === "stalled"?
                            //     <RowSkeleton />
                            // :
                            table.getRowModel().rows.map(row => (
                                <tr key={row.id} onClick={(e) => handleLinkClick(row.original.link as string)}>
                                    {
                                        row.getVisibleCells().map(cell => {
                                            let cellJSX: JSX.Element | undefined = undefined;
                                            if (cell.column.id === "title") {
                                                cellJSX = <Stack minWidth={pxToRem(400)} key={cell.id} className={styles.alumni}>{row.original.title}</Stack>;
                                            } else if (cell.column.id === "name") {
                                                // Default to "UNKNOWN" if the name is undefined
                                                const fileName = row.original.name ?? "UNKNOWN FILE";

                                                // Split the filename to get the extension, handling cases where there might be no extension
                                                const parts = fileName.split('.');
                                                const extension = parts.length > 1 ? parts.pop()?.toUpperCase() || "UNKNOWN" : "NO EXTENSION";

                                                cellJSX = (
                                                    <Stack key={cell.id} className={styles.alumni}>
                                                        <span>{extension}</span>
                                                    </Stack>
                                                );
                                            }
                                            else if (cell.column.id === "size") {
                                                cellJSX = <Stack key={cell.id} alignItems={'start'}>
                                                    {row.original.size ? row.original.size : "Unknown"}
                                                    {/* <IconButton onClick={(e) => handleLinkClick(e, row.original.link)}>
                                                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </IconButton> */}
                                                </Stack>;
                                            }
                                            else if (cell.column.id === "uploaded_at") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>
                                                    {row.original.uploaded_at ? formatDate(row.original.uploaded_at as number) : ""}
                                                </Stack>;
                                            }
                                            else if (cell.column.id === "id" && (networkPermission && networkPermission === PermissionState.EDITOR)) {
                                                cellJSX = <Stack key={cell.id} alignItems={'center'} onClick={(e) => e.stopPropagation()}>
                                                    <IconButton onClick={() => { if (row.original.id) { setIsDeleteModal(true); setCurrentDeleteId(row.original.id) } }} >
                                                        <svg style={{ cursor: 'pointer' }} width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.5 5.25V4.35C15.5 3.08988 15.5 2.45982 15.2548 1.97852C15.039 1.55516 14.6948 1.21095 14.2715 0.995235C13.7902 0.75 13.1601 0.75 11.9 0.75H10.1C8.83988 0.75 8.20982 0.75 7.72852 0.995235C7.30516 1.21095 6.96095 1.55516 6.74524 1.97852C6.5 2.45982 6.5 3.08988 6.5 4.35V5.25M8.75 11.4375V17.0625M13.25 11.4375V17.0625M0.875 5.25H21.125M18.875 5.25V17.85C18.875 19.7402 18.875 20.6853 18.5071 21.4072C18.1836 22.0423 17.6673 22.5586 17.0322 22.8821C16.3103 23.25 15.3652 23.25 13.475 23.25H8.525C6.63482 23.25 5.68973 23.25 4.96778 22.8821C4.33274 22.5586 3.81643 22.0423 3.49285 21.4072C3.125 20.6853 3.125 19.7402 3.125 17.85V5.25" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                                    </IconButton>
                                                </Stack>;
                                            }
                                            return (<td key={cell.id}> {cellJSX} </td>)

                                        })
                                    }
                                    {/* </Link> */}
                                </tr>
                            ))
                        }
                        {filesList.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'start', padding: '20px', height: pxToRem(52), color: '#A3A3A3' }}>

                                    Files of your media references will appear here

                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Stack>
        </>
    );
}
export default memo(FileTab);