import { Stack } from "@mui/system";
import moment from "moment";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { selectMonth, selectWeek, setWeeklyGoalsDefault } from "../../../redux/dashboardSlice";
import { getCurrentWeekNumber, getWeekNumbersOfMonth } from "../helper";
import styles from "./AllGoals.module.scss";

interface IAllGoals {

}

const GOALS_COLORS = [styles.primary800, styles.primary700, styles.primary600, styles.primary500, styles.primary400, styles.primary400, styles.primary300, styles.primary300, styles.primary200, styles.primary200, styles.primary100, styles.primary100]

const AllGoals = () => {
    const today = useMemo(()=>{
        return moment(new Date);
    }, []);

    const monthList = useMemo(()=>{
        const currentYear = today.year();
        let startOfMonths = [];
        for(let month=0; month < 12; month++) {
            const startOfMonth = moment({year: currentYear, month, day: 1})
            startOfMonths.push(startOfMonth);
        }
        return startOfMonths
    },[])

    const getColor = (index: number) => {
        const currentMonths = monthList.filter(m => m.isSameOrBefore(today))
        return GOALS_COLORS.slice(0,currentMonths.length).reverse()[index]
    }

    return (
        <Stack gap={pxToRem(24)}>
            <h5 className={styles.title}>All Goals</h5>
            <Stack gap={pxToRem(12)} >
                <MonthCard month={0} title="This Week" thisWeek/>
                {
                    monthList.map((m, index) => (
                        <MonthCard month={index+1} title={m.format("MMMM")} key={m.format("MMMM")} locked={m.isSameOrAfter(today)} bgColor={getColor(index)}/>
                    ))
                }
            </Stack>
        </Stack>
    );
}

interface IMonthCard {
    month: number;
    title: string;
    locked?: boolean;
    bgColor?: string;
    thisWeek?: boolean;
}
const MonthCard = ({month, title, locked, bgColor, thisWeek}:IMonthCard) => {
    const dispatch = useDispatch();
    const progress = useSelector((state:RootState) => state.dashboardState.progress);
    const goals = useSelector((state:RootState) => state.dashboardState.goals);

    const text = useMemo(() => {
        if(thisWeek) {
            const currentWeekNumber = getCurrentWeekNumber();
            const weekProgress = progress.filter(p => p.code && parseInt(p.code) === currentWeekNumber)[0];
            const weekTasks = goals.filter(g => g.code && parseInt(g.code) === currentWeekNumber)[0]

            // const currentDate = moment();
            // const currentDayOfWeek = currentDate.day();
            // const daysToSubtract = (currentDayOfWeek + 6) % 7;
            // const givenDate = currentDate.clone().subtract(daysToSubtract, 'days');
            // const startOfMonth = givenDate.clone().startOf('month');
            // const weekNumberOfMonth = givenDate.diff(startOfMonth, 'weeks') + 1;
            // const weekProgress = progress.filter(p => p.code && p.code === `${currentDate.month()+1}-${weekNumberOfMonth}`)[0];
            // const weekTasks = goals.filter(g => g.code && g.code === `${currentDate.month()+1}-${weekNumberOfMonth}`)[0]

            if(weekProgress) {
                const weekTasksCompleted = weekProgress.tasks.filter(t => t.checked === true).length
                return `${weekTasksCompleted}/${weekTasks?weekTasks.tasks.length:0} Tasks Completed`
            }
            return `${0}/${weekTasks?weekTasks.tasks.length:0} Tasks Completed`

        }
        const weekNumbersOfMonth = getWeekNumbersOfMonth(new Date().getFullYear(), month)
        const monthProgress = progress.filter(p => p.code && weekNumbersOfMonth.includes(parseInt(p.code)));
        const totalWeekCount = goals.filter(g => g.code && weekNumbersOfMonth.includes(parseInt(g.code))).length;

        // const monthProgress = progress.filter(p => p.code && p.code.split('-')[0] === month.toString());
        const completed = monthProgress.filter(p => {
            const numCompleted = p.tasks.filter(t => t.checked === true).length;
            const totalTasks = goals.filter(g => g.code && p.code && g.code === p.code)[0]
            if(totalTasks) {
                return numCompleted === totalTasks.tasks.length;
            }
            return [];
        }).length;
        return `${completed}/${totalWeekCount} Weeks Completed`;
    }, [progress, goals])

    return (
        <Stack onClick={() => {
            if(month === 0) dispatch(setWeeklyGoalsDefault())
            else {
                if(month > moment().month()+1) return;
                dispatch(selectMonth(month)); 
                dispatch(selectWeek(undefined));
            }
        }} className={`${styles.monthCard} ${locked?styles.locked:''}`} sx={{'&.MuiStack-root':{backgroundColor: bgColor?bgColor:''}}}>
            <Stack>
                <p>{title}</p>
                <small>{text}</small>
            </Stack>
            {locked && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17 11V8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8V11M8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V15.8C20 14.1198 20 13.2798 19.673 12.638C19.3854 12.0735 18.9265 11.6146 18.362 11.327C17.7202 11 16.8802 11 15.2 11H8.8C7.11984 11 6.27976 11 5.63803 11.327C5.07354 11.6146 4.6146 12.0735 4.32698 12.638C4 13.2798 4 14.1198 4 15.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21Z" stroke={"#737373"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>}
        </Stack>
    )
}

export default AllGoals;