import { Avatar, Divider, IconButton, Stack, Typography } from "@mui/material";
import styles from "./index.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardContainer from "../../DashboardContainerPage/DashboardContainer";
import dashboardStyles from "../../DashboardContainerPage/index.module.scss";
import { joinStringWithSpace } from "../../../helpers/utils";
import { URL_PARAMS } from "../../../routers/shared";
import globalStyles from "../../../styles/_stylesParams.module.scss";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import PermissionTable from "../components/PermissionsTable";
import { Permission, PermissionFeature, PermissionState } from "../../../firebase/types-organisations";
import { createSelector } from "@reduxjs/toolkit";
import UserRemovalComponent from "./deleteUserPopup";
import { PermissionTableData, permissionToRows } from "../helpers";
import { getCurrentUserId } from "../../../firebase/userApi";


const defaultPermissions: PermissionTableData[] = [
    {
        feature: PermissionFeature.ALUMNI,
        state: PermissionState.EDITOR,
    },
    {
        feature: PermissionFeature.BILLING,
        state: PermissionState.EDITOR,
    },
    {
        feature: PermissionFeature.PLANNER,
        state: PermissionState.EDITOR,
    },
    {
        feature: PermissionFeature.FORMS,
        state: PermissionState.EDITOR,
    },
    {
        feature: PermissionFeature.ENGAGEMENT,
        state: PermissionState.EDITOR,
    },
    {  
        feature: PermissionFeature.NETWORK,
        state: PermissionState.EDITOR 
    },
    {  
        feature: PermissionFeature.PHILANTHROPY, 
        state: PermissionState.EDITOR 
    },
];


const UserPermissionsPage = () => {

    const params = useParams();
    const { orgId, userId } = params
    const currentUserId = getCurrentUserId();


    const navigate = useNavigate();
    const [rows, setRows] = useState<Permission[]>([]);

    const user = useSelector((state: RootState) =>
        state.teamState.teamMembers.find(member => member.userId === userId)
    );
    const organisation = useSelector((state: RootState) => state.globalState.organisation)
    const currentUserType = organisation?.admins?.includes(userId as string) ?? false;
    const teamPermissions = useSelector((state: RootState) => state.teamState.teamPermissions)


  // Combine user permissions from Redux with default permissions
  const combinedPermissions : PermissionTableData[] = useMemo(() => {
    console.log("combining permissions")
    if (!teamPermissions || !teamPermissions.length) {
        console.log("no team permissions")
        return defaultPermissions;
    }
    const userPermissions = teamPermissions.filter(teamPermissions => teamPermissions.userId === userId)
    if(!userPermissions || !userPermissions.length) {
        console.log("no user permissions")
        return defaultPermissions;
    }
    return permissionToRows(userPermissions[0].permissions)

  }, [userId, teamPermissions]);

    const breadcrumbs = () => {
        return (
            [
                <Stack onClick={() => navigate(`/dashboard`)} key='org'><span className={dashboardStyles.breadcrumbsLink}>Organisation</span></Stack>,
                <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)} key='orgName'><span className={dashboardStyles.breadcrumbsLink}>{organisation?.name}</span></Stack>,
                <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/team`)} key='team'><span>Team</span></Stack>,
                <Stack><span>{(joinStringWithSpace(user?.firstName.trim(), user?.lastName.trim()))}</span></Stack>
            ]
        )
    }


    return (
        <DashboardContainer breadCrumbs={breadcrumbs()}>
            <Stack style={{ padding: `${pxToRem(24)} ${pxToRem(40)}` }}>
                <Stack alignItems={'start'}>
                    <IconButton onClick={() => navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.TEAM}/`)}>
                        <svg width={pxToRem(8)} height={pxToRem(14)} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7 13L1 7L7 1" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7 13L1 7L7 1" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </IconButton>
                </Stack>
                <Stack style={{ height: pxToRem(172), marginTop: pxToRem(75), padding: pxToRem(24), flex: 1, borderRadius: pxToRem(8), position: 'relative', border: `1px solid #F5F5F5`, background: "#447D75" }}>
                    <Avatar sx={{ cursor: "pointer", width: pxToRem(104), height: pxToRem(104), position: 'absolute', top: pxToRem(-52), backgroundColor: "#fff", border: `1px solid #F5F5F5` }}>
                        <Avatar src={user?.photoUrl} sx={{ width: pxToRem(94), height: pxToRem(94), backgroundColor: globalStyles['secondaryYellow50'] }}>
                            <h4 style={{ color: globalStyles['secondaryYellow500'] }}>{user?.firstName.trim().charAt(0)}</h4>
                        </Avatar>
                    </Avatar>
                    <Stack style={{ marginTop: pxToRem(60) }} spacing={1} alignItems={'start'}>
                        <label className={styles.user_name_text}>
                            {(joinStringWithSpace(user?.firstName.trim(), user?.lastName.trim()))}
                            {(currentUserId && (currentUserId === userId)) && ' (You)'}
                        </label>
                        {(user?.email.trim() !== undefined && user?.email.trim() !== "") ? <label className={styles.user_email_text}>{user?.email.trim()}</label> : <label style={{ color: globalStyles[''] }} className='regular-400-14'>Email Address</label>}

                    </Stack>
                    <Stack alignItems={'end'}>
                    {!currentUserType ? <UserRemovalComponent userId={userId as string} orgId={orgId as string} userName={(joinStringWithSpace(user?.firstName.trim(), user?.lastName.trim()))}/> : ""}
                    </Stack>
                </Stack>
                <PermissionTable rows={combinedPermissions}></PermissionTable>
            </Stack>
        </DashboardContainer>

    );
}

export default UserPermissionsPage;