export const REG_PAGES = {
    FRONT_PAGE: 1,
    PERSONAL_DETAILS: 2,
    CONTACT_DETAILS: 3,
    SCHOOLING_HISTORY: 4,
    TELL_US_MORE: 5,
    IDENTITY: 6,
    PERSONAL_INTERESTS: 7,
    SKILLS: 8,
    FINAL_PAGE: 9,
}

export const PRONOUNS = ["Select", "He/Him", "She/Her", "They/Them", "Prefer not to say"];
export const GENDER = ["Select", "Male", "Female", "Non-binary", "Prefer not to say"];
export const TITLE = ["Select", "Mr", "Mrs", "Miss", "Ms", "Dr"];

export const TITLE_TEXT_1 = "Graduation doesn’t have to mean goodbye."
export const title_text_2 = (orgName: string| null | undefined): string => {
    // TODO: Remove/replace dummy data
    return "Register your details to gain access to exclusive " + (orgName || "Test School") + " alumni events, reunions and opportunities to network with like-minded individuals."
}

export const final_text_1 = (orgName: string| null | undefined):string => {
    return "Thank you for registering to "+ (orgName || "Test School") + "'s Alumni Program."
}
export const FINAL_TEXT_2 = "We look forward to welcoming you into our Alumni community and seeing the amazing things you will accomplish as part of this network! "
