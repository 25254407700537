
import { IconButton, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import dayjs, { Dayjs } from 'dayjs';
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { plannerDataItemInsideTable, PlannerTable } from "..";
import LoadingComponent from "../../../components/LoadingStatus/Loading";
import ActionSnackbar from "../../../components/SnackBarComponent/CustomizedSnackBar";
import { deleteSinglePlanner, markAsDelete, markAsDone, markAsRestore, updateTaskDate } from "../../../firebase/organisationPlannerApi";
import { getCurrentUserId } from "../../../firebase/userApi";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import ButtonDatePicker from "../components/calendarButton";
import DeleteButtonMenu from "../components/deleteButtonMenu";
import StatusIcon from "../components/RenderIcon";
import RoundCheckbox from "../components/RoundCheckbox/RoundCheckbox";
import TableOptionsMenu from "../components/tableOptionMenu";
import styles from "../index.module.scss";

interface IBasicSearchTable {
	orgId: string;
	totalRows: number;
	plannerDataRows: PlannerTable[];
	rowClick: (id: string) => void;
	editPlanner: (id: string) => void;
	status: string;
	addNewRow: () => JSX.Element;
	onRowSelectionChange: (state: string[]) => void;
	userNames: { [key: string]: string };
	isDeleteMode: boolean;
	clearSelectedRows: boolean;
	isEmpty: boolean;
}

interface rowSelectionType {
	[key: string]: boolean;
}

const getStatusByDate = (date: Dayjs | null, isDone: boolean) => {
	const currentDate = dayjs().startOf('day');

	let status = 'Unplanned'; // Default status
	if (isDone) {
		status = 'Done';
	} else {
		if (date?.isValid()) {
			if (date.isAfter(currentDate)) {
				status = 'Planned';
			} else if (date.isBefore(currentDate)) {
				status = 'Overdue';
			} else {
				status = 'Planned';
			}
		}
	}

	return status;
}
export const BasicSearchTable = memo(({ orgId, plannerDataRows, totalRows, rowClick, status, addNewRow, editPlanner, onRowSelectionChange, userNames, isDeleteMode, clearSelectedRows, isEmpty }: IBasicSearchTable) => {

	const columnHelper = createColumnHelper<PlannerTable>();
	const [rows, setRows] = useState(totalRows);
	const userId = getCurrentUserId();

	const [rowSelection, setRowSelection] = useState<rowSelectionType>({})
	const [tempDataRows, setTempDataRows] = useState<PlannerTable[]>(plannerDataRows);
	const [snackbarInfo, setSnackbarInfo] = useState<{ open: boolean; message: string; undoAction?: () => void }>({ open: false, message: "" });

	const [loadingStatus, setLoadingStatus] = useState(false);
	const [loadingStatusStr, setLoadingStatusStr] = useState("");

	const [emptyStateFlag, setEmptyStateFlag] = useState(false);

	const plannerPermission = useSelector((state: RootState) => state.globalState.permissions?.planner);

	// Function to show the Snackbar with an undo option
	const showUndoSnackbar = (message: string, undoAction: () => void) => {
		setSnackbarInfo({ open: true, message, undoAction });
	};

	// Function to close the Snackbar
	const closeSnackbar = () => {
		setSnackbarInfo(prev => ({ ...prev, open: false }));
	};


	// Update the callback whenever selection changes
	useEffect(() => {
		const selectedIds = Object.keys(rowSelection).filter(key => rowSelection[key]);
		onRowSelectionChange(selectedIds);

	}, [rowSelection, onRowSelectionChange]);

	useEffect(() => {
		setRowSelection({}); // Clear selected rows
	}, [clearSelectedRows]);

	useEffect(() => {
		setTempDataRows(plannerDataRows); // Clear selected rows
		if (plannerDataRows.length > 0) {
			setEmptyStateFlag(false);
		}
		setRows(totalRows)
	}, [plannerDataRows, totalRows]);

	const columns = [
		columnHelper.accessor('isDelete', {
			cell: info => info.getValue(),
			header: () => {
				const isAllSelected = table.getIsAllPageRowsSelected();
				const isSomeSelected = Object.keys(rowSelection).length > 0 && !isAllSelected;

				return (
					<>
						{emptyStateFlag ? (
							<RoundCheckbox
								id="total_selection"
								checked={isAllSelected}
								indeterminate={isSomeSelected}
								onChange={() => { }}
							/>
						) : (
							<RoundCheckbox
								id="total_selection"
								checked={isAllSelected}
								indeterminate={isSomeSelected}
								onChange={toggleAllRowsSelected}
							/>
						)}

					</>

				);
			},
		}),

		columnHelper.accessor('title', { cell: info => info.getValue(), header: isDeleteMode ? "Deleted Task" : "Task", }),
		columnHelper.accessor('alumniList', { cell: info => info.getValue(), header: "Linked People" }),
		columnHelper.accessor('date', { cell: info => info.getValue(), header: "Planned" }),
		columnHelper.accessor('isDone', { cell: info => info.getValue(), header: "Status" }),
		columnHelper.accessor('assignee', { cell: info => info.getValue(), header: "Assigned to" }),
	];

	const viewerColumns = [


		columnHelper.accessor('title', { cell: info => info.getValue(), header: isDeleteMode ? "Deleted Task" : "Task", }),
		columnHelper.accessor('alumniList', { cell: info => info.getValue(), header: "Linked People" }),
		columnHelper.accessor('date', { cell: info => info.getValue(), header: "Planned" }),
		columnHelper.accessor('isDone', { cell: info => info.getValue(), header: "Status" }),
		columnHelper.accessor('assignee', { cell: info => info.getValue(), header: "Assigned to" }),
	];

	const table = useReactTable({
		data: tempDataRows,
		columns: (plannerPermission && plannerPermission === "EDITOR") ? columns : viewerColumns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		getRowId: (row, index) => row.objectID || `row-${index}`,
		onRowSelectionChange: setRowSelection, // Updated to use custom handler
		state: {
			rowSelection,
		},
		enableRowSelection: true,
		enableMultiRowSelection: true,
	});



	// Handler for the onEdit event
	const onEditClick = (id: string) => {
		// Call the editPlanner function with appropriate arguments
		editPlanner(id);
	};

	const toggleAllRowsSelected = () => {
		const newSelection: rowSelectionType = {};
		const isAllSelected = table.getIsAllPageRowsSelected();

		table.getRowModel().rows.forEach(row => {
			newSelection[row.id] = !isAllSelected;
		});

		setRowSelection(newSelection);
	};

	const handleUpdateTaskDate = async (taskId: string, editPlannedDate: Dayjs | null) => {
		let newDate = editPlannedDate?.isValid() ? editPlannedDate.unix() : 9999999999999;
	
		// Function to actually update the task date
		const performUpdateDate = async () => {
			try {
				const response = await updateTaskDate(orgId as string, taskId, newDate, userId as string);
				if (response.code === 200) {
					// Optionally update local state or trigger a re-fetch of tasks
				} else {
					alert("Failed to update task date. Please try again.");
				}
			} catch (error) {
				console.error("Error updating task date:", error);
				alert("Failed to update task date. Please try again.");
			}
		};
	
	
		performUpdateDate();

	};
	


	const handleMarkTaskAsDone = async (taskId: string) => {
		// Placeholder for a timeout ID
		let timeoutId: NodeJS.Timeout;

		// Function to actually mark the task as done
		const performMarkAsDone = async () => {
			try {
				const response = await markAsDone(orgId as string, taskId, userId as string);
				if (response.code === 200) {

				} else {
					alert("Failed to mark task as done. Please try again.");
				}
			} catch (error) {
				console.error("Error marking task as done:", error);
				alert("Failed to mark task as done. Please try again.");
			}
		};

		// Show the Snackbar with an undo option
		showUndoSnackbar("Task marked as done.", () => {
			// Clear the timeout to prevent the task from being marked as done if Undo is clicked
			clearTimeout(timeoutId);
			// Perform any additional undo logic here, if necessary
			closeSnackbar();
		});

		// Set a timeout to delay the task marking as done, allowing the user to undo
		timeoutId = setTimeout(() => {
			performMarkAsDone();
			closeSnackbar();
		}, 1000); // Delay of 3 seconds
	};


	const handleMarkTaskAsDeleted = async (taskId: string) => {
		let timeoutId: NodeJS.Timeout;

		const performMarkAsDeleted = async () => {
			try {
				const response = await markAsDelete(orgId as string, taskId, userId as string);
				if (response.code === 200) {

				} else {
					alert("Failed to mark task as deleted. Please try again.");
				}
			} catch (error) {
				console.error("Error marking task as deleted:", error);
				alert("Failed to mark task as deleted. Please try again.");
			}
		};

		showUndoSnackbar("Task moved to Recycling Bin.", () => {
			clearTimeout(timeoutId);
			closeSnackbar();
		});

		timeoutId = setTimeout(() => {
			performMarkAsDeleted();
			closeSnackbar();
		}, 1000);
	};


	const handleRestoreTask = async (taskId: string) => {
		let timeoutId: NodeJS.Timeout;

		const performRestoreTask = async () => {
			try {
				const response = await markAsRestore(orgId as string, taskId, userId as string);
				if (response.code === 200) {

				} else {
					alert("Failed to restore task. Please try again.");
				}
			} catch (error) {
				console.error("Error restoring task:", error);
				alert("Failed to restore task. Please try again.");
			}
		};

		showUndoSnackbar("Task restored.", () => {
			clearTimeout(timeoutId);
			closeSnackbar();
		});

		timeoutId = setTimeout(() => {
			performRestoreTask();
			closeSnackbar();
		}, 1000);
	};


	const handleDeleteTask = async (taskId: string) => {
		let timeoutId: NodeJS.Timeout;

		const performDeleteTask = async () => {
			try {
				const response = await deleteSinglePlanner(orgId as string, taskId, userId as string);
				if (response.code === 200) {
				} else {
					alert("Failed to delete task. Please try again.");
				}
			} catch (error) {
				console.error("Error deleting task:", error);
				alert("Failed to delete task. Please try again.");
			}
		};

		showUndoSnackbar("Task deleted permanently.", () => {
			clearTimeout(timeoutId);
			closeSnackbar();
		});

		timeoutId = setTimeout(() => {
			performDeleteTask();
			closeSnackbar();
		}, 500);
	};




	const formatDate = (dateString: number) => {

		const date = dayjs.unix(dateString);
		const today = dayjs();
		if (dateString == 9999999999999) {
			return "No Date"
		} else {
			if (date.format('YYYY-MM-DD') == today.format('YYYY-MM-DD')) {
				return 'Today';
			} else {
				return dayjs.unix(dateString).format('D MMM YY')
			}
		}

	};


	const CustomPaginationFooter = () => {
		const currentIndex = table.getState().pagination.pageIndex + 1;
		const totalPageCount = table.getPageCount();
		const pageSize = table.getState().pagination.pageSize;

		const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
		const endItem = currentIndex === totalPageCount ? totalRows : currentIndex * pageSize;

		return (
			<Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
				<Stack direction={"row"} style={{ padding: 4, alignItems: 'center' }}>
					<Stack className={styles.datagrid_footer_text} alignItems={"center"}> {`${startItem} - ${endItem} of ${totalRows}`} </Stack>
					<IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
					<IconButton disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>
				</Stack>
			</Stack>
		);
	}



	const emptyTableRow = (): JSX.Element => {
		setTempDataRows(emptyDataRow)
		setEmptyStateFlag(true)
		return (
			<div>
			</div>
		)
	}

	const noMatchTableRow = (): JSX.Element => {
		setTempDataRows(noMatchRow)
		setEmptyStateFlag(true)
		return (
			<div>
			</div>
		)
	}

	const OverdueEmptyTableRow = (): JSX.Element => {
		setTempDataRows(OverDueEmptyDataRow)
		setEmptyStateFlag(true)
		return (
			<div>
			</div>
		)
	}

	const DeletedEmptyTableRow = (): JSX.Element => {
		setTempDataRows(deletedDataRow)
		setEmptyStateFlag(true)
		return (
			<div>
			</div>
		)
	}


	const emptyDataRow = [
		{
			title: "Your tasks will appear here",
			shortDetail: "Details of your task will appear here",
		}
	]

	const noMatchRow = [
		{
			title: "No matches found",
			shortDetail: "Try something else",
		}
	]

	const OverDueEmptyDataRow = [
		{
			title: "No overdue tasks",
			shortDetail: "Great job!",
		}
	]

	const deletedDataRow = [
		{
			title: "Your deleted tasks will appear here",
			shortDetail: "Details of your task will appear here",
		}
	]

	// Inside table cell rendering:

	return (
		<>
			<Stack spacing={0} className={styles.tableWrapper}>
				<table className={styles.table}>
					<thead>
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<th key={header.id} style={{ textAlign: 'left' }}>
										<Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
											<Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
												{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
											</Stack>
											{header.column.id === 'date' && (
												<Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
													<Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
													<Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
												</Stack>
											)}
										</Stack>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{
							// status === "loading" || status === "stalled"?
							//     <RowSkeleton />
							// :
							table.getRowModel().rows.map(row => (
								<tr key={row.id} onClick={() => { if (totalRows !== 0 && !isDeleteMode) { rowClick(row.original.objectID ?? "") } }} className={rowSelection[row.id] ? styles.selectedRow : styles.normalRow}>
									{
										row.getVisibleCells().map(cell => {
											let cellJSX: JSX.Element | undefined = undefined;
											if (cell.column.id === "title") {
												cellJSX = <Stack key={cell.id} className={styles.alumni}>{plannerDataItemInsideTable(row.original.title ?? "", row.original.shortDetail ?? "", isDeleteMode)}</Stack>;
											}
											else if (cell.column.id === "alumniList" && totalRows !== 0) {
												const alumniCount = row.original.alumniList?.length ?? 0;

												if (totalRows !== 0 && alumniCount > 0) {
													cellJSX = (
														<Stack key={cell.id} className={styles.text}>
															<small style={{ color: isDeleteMode ? '#737373' : '#1F1F1F' }}>
																{row.original.alumniList?.length === 1
																	? row.original.alumniName as string
																	: `${alumniCount} People`}
															</small>
														</Stack>
													);
												} else {
													// Handle case where alumniList is empty or totalRows is 0
													cellJSX = (
														<Stack key={cell.id} className={styles.text}>
															<small style={{ color: isDeleteMode ? '#737373' : '#1F1F1F' }}>{"-"}</small>
														</Stack>
													);
												}
											} else if (cell.column.id === "isDone" && totalRows !== 0) {
												// const status = row.original.status;
												const status = getStatusByDate(row.original.date ? dayjs.unix(row.original.date as number) : null, row.original.isDone as boolean);
												cellJSX = <div onClick={(e) => e.stopPropagation()}> <StatusIcon disable={!(plannerPermission && plannerPermission === "EDITOR")} status={status} onMarkComplete={() => { handleMarkTaskAsDone(row.id) }}></StatusIcon></div>
											}
											else if (cell.column.id === "date" && totalRows !== 0) {
												cellJSX = <Stack key={cell.id}  onClick={(e) => e.stopPropagation()} className={styles.text} direction={"row"} style={{ alignItems: "center" }}>
													<Stack style={{ alignItems: "center", marginRight: pxToRem(5) }}>
														{isDeleteMode ? (
															<svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#737373" strokeLinecap="round" strokeLinejoin="round" />
															</svg>

														) : (
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<ButtonDatePicker
																	label={row.original.date == null ? null : formatDate(row.original.date as number)}
																	value={dayjs.unix(row.original.date as number)}
																	onChange={(newValue) => handleUpdateTaskDate(row.id, newValue)}
																	disabled={!(plannerPermission && plannerPermission === "EDITOR")}
																/>
															</LocalizationProvider>
														)}
													</Stack>
													<small style={{ color: isDeleteMode ? '#737373' : '#1F1F1F' }}>
														{formatDate(row.original.date as number) || "No Date"}
													</small>
												</Stack>;
											} else if (cell.column.id === "assignee" && totalRows !== 0) {
												cellJSX = <Stack key={cell.id} className={styles.buttonBarWrapper} direction={"row"} style={{ alignItems: "center" }} onClick={(e) => e.stopPropagation()}>
													<small style={{ color: isDeleteMode ? '#737373' : '#1F1F1F' }}>
														{userNames[row.original.assignee as string] || "-"}
													</small>
													{(plannerPermission && plannerPermission === "EDITOR") &&
														<Stack style={{ alignItems: "end" }}>
															{
																!isDeleteMode ? (
																	<TableOptionsMenu
																		onMarkComplete={() => {
																			handleMarkTaskAsDone(row.id)
																		}}
																		onEdit={() => {
																			onEditClick(row.id);
																		}}
																		onDelete={() => {
																			handleMarkTaskAsDeleted(row.id)
																		}}
																		isDone={status === "DONE"} />
																) : (
																	<DeleteButtonMenu
																		onMarkRestore={() => {
																			handleRestoreTask(row.id);
																		}}
																		onDelete={() => {
																			handleDeleteTask(row.id);
																		}} />
																)}

														</Stack>}
												</Stack>;
											} else if (cell.column.id === "isDelete") {
												cellJSX = (
													<div onClick={(e) => e.stopPropagation()} style={{ width: pxToRem(30) }}>
														{emptyStateFlag ? (<RoundCheckbox
															id={"checkbox" + row.id}
															checked={false}
															onChange={() => { }}
														/>) : (<RoundCheckbox
															id={"checkbox" + row.id}
															checked={row.getIsSelected()}
															onChange={row.getToggleSelectedHandler()}
														/>)}

													</div>
												);

											}
											return (<td key={cell.id}> {cellJSX} </td>)
										})
									}
								</tr>
							))
						}
					</tbody>
				</table>
				{
					tempDataRows.length <= 0 ? (
						isDeleteMode ?
							DeletedEmptyTableRow() : // Render this when in delete mode and no data
							(status === "OVERDUE" ? OverdueEmptyTableRow() : (isEmpty ? emptyTableRow() : noMatchTableRow()))
					) : null
				}
			</Stack>
			{tempDataRows.length <= 0 || emptyStateFlag ? null : CustomPaginationFooter()}
			<ActionSnackbar
				open={snackbarInfo.open}
				message={snackbarInfo.message}
				onClose={closeSnackbar}
				noUndo={true}
			/>
			<LoadingComponent isLoading={loadingStatus} loadingStr={loadingStatusStr} />
		</>
	);
});

export default BasicSearchTable;