import Stack from "@mui/material/Stack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { arrayRemove, arrayUnion, deleteField, doc, FieldValue, getDoc, onSnapshot, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { getOrganisation, Organisation } from "../../firebase/types-organisations";
import { clearOrgPhotoUrl, setCurrentOrgDetail } from "../../redux/organisationSlice";
import { URL_PARAMS } from "../../routers/shared";
import { RootState } from "../../redux";
import { setPageStatus } from "../../redux/pageStatusSlice";
import { Avatar, Breadcrumbs, Button, Divider, Link, Paper } from "@mui/material";
import { extractFourFirstLetters, getError, joinStringWithComma, joinStringWithSpace } from "../../helpers/utils";
import CustomizedPaper from "../../components/CustomizedPaper";
import { BetaButton, FlatButton, PrimaryButton, SecondaryButton, WarningButton } from "../../components/Buttons/ButtonUI";
import pxToRem from "../../helpers/pxToRem";
import InputTextField from "../../components/Inputs/TextFields/InputTextField";
import useTextInput from "../../hooks/useTextInput";
import { CustomizedDropdown } from "../../components/Inputs/Dropdown/CustomizedDropdown";
import constants from "../../helpers/constants";
import CustomizedModal from "../../components/CustomizedModal";
import { Country, State } from "country-state-city";
import { fileRemoveFromStorage, uploadFileToStorageWithProgress } from "../../firebase/storageApis";
import { getOrganisationStoragePath } from "../../firebase/types-sharedApis";
import { deleteOrganisationInfo, updateOrganisationPhoto, updateOrganisation, deleteOrganisationPhoto } from "../../firebase/organisationApis";
import UploadModal from "../../components/CustomizedModal/UploadModal";
import { AlertDialogComponent, AlertDialogSimpleComponent } from "../../components/AlertComponent";
import globalStyles from "../../styles/_stylesParams.module.scss";
import { getErrorMsgForAddress, getErrorMsgForInput, getErrorMsgForOrganizationName, getErrorMsgForPostalCode } from "../../helpers/inputValidators";
import { EMPTY_INPUT_HELPER_MSG } from "../../helpers/shared";
import { getCountryISOCodeFromPlace } from "../../helpers/googlePlaceHelpers";
import { betaStateAction } from "../../redux/betaSlice";
import { getFunctions, httpsCallable } from "firebase/functions";
import LoadingComponent from "../../components/LoadingStatus/Loading";
        
const OrganisationSettings = (props: any) => {
    const [ errorMsg, setErrorMsg] = useState<string|undefined>(undefined);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const cfirstName = useSelector((state: RootState) => state.user.userInfos.firstName);
    const clastName = useSelector((state: RootState) => state.user.userInfos.lastName);
    const cdisplayName = useSelector((state: RootState) => state.user.userInfos.displayName);
    const currentName = (cfirstName && clastName)?joinStringWithSpace(cfirstName, clastName) : cdisplayName;

    const organisation = useSelector( (state: RootState) => state.organisation );
    const subId = useSelector((state:RootState) => state.globalState.organisation?.subscriptionId);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const [ orgId, setOrgId ] = useState<string|null|undefined>(organisation.orgId);
    const [ orgName, setOrgName ] = useState<string|null|undefined>(organisation.organisation?.name);
    const [ orgAddress, setOrgAddress ] = useState<string|null|undefined>(joinStringWithComma(organisation.organisation?.streetAddress??"", joinStringWithComma(organisation.organisation?.suburb??"", organisation.organisation?.country ?? "")));
    const [campus, setCampus] = useState<{value:string, label: string}[]>([])
    const [house, setHouse] = useState<{value:string, label: string}[]>([])
    const [ adminList, setAdminList ] = useState<{id:string, name:string, email:string, photoUrl: string|undefined}[]>([]);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [photoUploadState, setPhotoUploadState] = useState(false);

    const [defaultCampus, setDefaultCampus] = useState("");
    const [isCampusSelection, setIsCampusSelection] = useState(false);
    const [isCampusAdding, setIsCampusAdding] = useState(false);
    const [isCampusDeleting, setIsCampusDeleting] = useState(false);
    const [isHouseAdding, setIsHouseAdding] = useState(false);
    const [isHouseDeleting, setIsHouseDeleting] = useState(false);
    const newCampus = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForInput(input, 2, "Campus", true) } });
    const newHouse = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForInput(input, 2, "House", true) } });

    const [isEdit, setIsEdit] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const organisationName = useTextInput({
        inputValidator: (input: string) => { return getErrorMsgForOrganizationName(input,5) },
        defaultValue: organisation && organisation.organisation?.name ? organisation.organisation?.name : orgName ?? "",
    });

    const [countryName, setCountryName] = useState<null | string>("");
    const [hasTouchedCountry, setHasTouchedCountry] = useState(false);
    const streetAddress = useTextInput({inputValidator: (input) => { return getErrorMsgForAddress(input,5) }});
    const suburb = useTextInput({inputValidator: (input) => { return undefined; }});
    const [state, setState] = useState<string | null>("");
    const [hasTouchedState, setHasTouchedState] = useState(false);
    const postcode = useTextInput({inputValidator: (input) => { return getErrorMsgForPostalCode(input, true) }});

    const [isDelete1, setIsDelete1] = useState(false);
    const [isDelete2, setIsDelete2] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [finishDelete, setFinishDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isRemovePhoto, setIsRemovePhoto] = useState(false);
    const [isRemovingPhoto, setIsRemovingPhoto] = useState(false);

    const allCountries = useMemo( () => Country.getAllCountries().map((country) => ({ value: country.isoCode, label: country.name, })), [] );
    const allStates = useMemo(() => { if (!countryName) return []; return State.getStatesOfCountry(countryName).map((state) => ({ label: state.name, value: state.isoCode })); }, [countryName]);
    const [fetchedOrganisation, setFetchedOrganisation] = useState<Organisation | undefined>(undefined);
    //Load organisation info
    useEffect(() => { 
        const {orgId} = params;
        if(!orgId){ return;};
        const docRef = doc(db, "organisations", orgId);
        const unsubscribeOrganisation = onSnapshot(docRef, (docSnap) => {
            if(docSnap.exists()){
                const organisation = getOrganisation(docSnap.data());
                setFetchedOrganisation(organisation);
                setOrgId(orgId);
                setOrgName(organisation.name);
                setOrgAddress(joinStringWithComma(organisation.streetAddress??"", joinStringWithComma(organisation.suburb??"", organisation.country ?? "")));
                dispatch(setCurrentOrgDetail({orgId: orgId, organisation: organisation})); //Set Organisation
                organisationName.setValue(organisation.name ?? "");
                setCountryName(organisation.country ?? "")
                streetAddress.setValue(organisation.streetAddress ?? "");
                suburb.setValue(organisation.suburb ?? "");
                setState(organisation.state ?? "")
                postcode.setValue(organisation.postCode ?? "")
                const fetchedCampus = (organisation.campus && Array.isArray(organisation.campus)) ? [...organisation.campus].sort().map(item => ({ value: item, label: item })) : [];
                setCampus(fetchedCampus);
                setDefaultCampus(organisation.defaultCampus ?? "")
                const fetchedHouse = (organisation.house && Array.isArray(organisation.house)) ? [...organisation.house].sort().map(item => ({ value: item, label: item })) : [];
                setHouse(fetchedHouse);
            }else{ 
                navigate(`/${URL_PARAMS.ERROR404}`); 
            }
        })
        dispatch(setPageStatus(false));

        return () => {unsubscribeOrganisation();}
    }, [params.orgId]);

    const resetOrganisationDetails = () => {
        organisationName.setValue(fetchedOrganisation?.name ?? "");
        setCountryName(fetchedOrganisation?.country ?? "");
        streetAddress.setValue(fetchedOrganisation?.streetAddress ?? "");
        suburb.setValue(fetchedOrganisation?.suburb ?? "");
        setState(fetchedOrganisation?.state ?? "");
        postcode.setValue(fetchedOrganisation?.postCode ?? "")

    }
    const updateCampusDefault = async(campusValue: string) => {
        if(!orgId){return}
        setIsCampusSelection(true);
        try{
            const docRef = doc(db, "organisations", orgId);
            await updateDoc(docRef, {defaultCampus: campusValue})
        }
        catch(error){ console.log(error); }
        setIsCampusSelection(false);
    }
    const deleteCampus = async (campusValue: string | undefined) => {
        if (!orgId || !campusValue) return;
        setIsCampusDeleting(true);
        try {
            const batch = writeBatch(db);
            const orgRef = doc(db, "organisations", orgId);
            const regRef = doc(db, "registrations", orgId);  // Reference to the registration document
    
            // Update campus array in both documents
            batch.update(orgRef, { campus: arrayRemove(campusValue) });
            const regSnap = await getDoc(regRef);
            if (regSnap.exists()) {
                batch.update(regRef, { campuses: arrayRemove(campusValue.trim()) });
            }
    
            await batch.commit();
        } catch (error) {
            console.error("Error deleting campus:", error);
        }
        setIsCampusDeleting(false);
    }
    
    const addCampus = async (campusName: string) => {
        if (!orgId || campusName.trim() === "") return;
        setIsCampusAdding(true);
        try {
            const batch = writeBatch(db);
            const orgRef = doc(db, "organisations", orgId);
            const regRef = doc(db, "registrations", orgId);  // Reference to the registration document
    
            // Add campus to both documents
            batch.update(orgRef, { campus: arrayUnion(campusName.trim()) });
            const regSnap = await getDoc(regRef);
            if (regSnap.exists()) {
                batch.update(regRef, { campuses: arrayUnion(campusName.trim()) });
            }
    
            await batch.commit();
            newCampus.setValue("");
        } catch (error) {
            console.error("Error adding campus:", error);
        }
        setIsCampusAdding(false);
    }
    
    const deleteHouse = async (houseValue: string | undefined) => {
        if (!orgId || !houseValue) return;
        setIsHouseDeleting(true);
        try {
            const batch = writeBatch(db);
            const orgRef = doc(db, "organisations", orgId);
            const regRef = doc(db, "registrations", orgId);  // Reference to the registration document
    
            // Remove house from both documents
            batch.update(orgRef, { house: arrayRemove(houseValue) });
            const regSnap = await getDoc(regRef);
            if (regSnap.exists()) {
                batch.update(regRef, {schoolHouses : arrayRemove(houseValue.trim()) });
            }
    
            await batch.commit();
        } catch (error) {
            console.error("Error deleting house:", error);
        }
        setIsHouseDeleting(false);
    }
    
    const addHouse = async (houseName: string) => {
        if (!orgId || houseName.trim() === "") return;
        setIsHouseAdding(true);
        try {
            const batch = writeBatch(db);
            const orgRef = doc(db, "organisations", orgId);
            const regRef = doc(db, "registrations", orgId);  // Reference to the registration document
    
            // Add house to both documents
            batch.update(orgRef, { house: arrayUnion(houseName.trim()) });
            const regSnap = await getDoc(regRef);
            if (regSnap.exists()) {
                batch.update(regRef, {schoolHouses : arrayUnion(houseName.trim()) });
            }
    
            await batch.commit();
            newHouse.setValue("");
        } catch (error) {
            console.error("Error adding house:", error);
        }
        setIsHouseAdding(false);
    }

    const handleSave = async () => {
        if(!orgId){return;}
        const mandatoryInputs =  [organisationName, streetAddress];
        let validated = true;  
        mandatoryInputs.map((input) => input.setHasTouched(true)); 
        mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
        if(countryName?.trim().length === 0){ validated = false; }  // For mandatory country
        if (!validated) { return; } 
        let optionalValidated = true;   // Optional Fields
        const optionalInput = [suburb, postcode];
        optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; }); 
        if(!optionalValidated){ return; }
        console.log("YUP CAN UPDATE")

        const organisationObject: Organisation<FieldValue> = {
            ...(organisationName.value && {name: organisationName.value.trim()}),
            ...(countryName && { country: countryName.trim() }),
            ...(streetAddress.value && {streetAddress: streetAddress.value.trim()}),
            ...(suburb.value.trim() === "" ? {suburb: deleteField()} : {suburb: suburb.value.trim()}),
            ...(state?.trim() === "" ? {state: deleteField()} :  state && {state: state.trim()}),
            ...(postcode.value.trim() === "" ? {postCode: deleteField()} : {postCode: postcode.value.trim()}),
            updated_at: serverTimestamp()
        }
        setIsEditLoading(true);
        const res = await updateOrganisation(orgId, organisationObject)
        if (res.code === 500) { setErrorMsg("Error: Unable to update organization details. Please try again later."); } 
        setIsEditLoading(false);
        setIsEdit(false);
    }
    const gotToDelete2 = () => {
        setIsDelete1(false);
        setIsDelete2(true);
    }
    // const deleteOrganisation = async() => {
    //     if(orgId){
    //         const status = await deleteOrganisationInfo(orgId);
    //         if(status.code === 200){ setFinishDelete(true); }
    //     }
    // }
    const navigateToOrganisationDashboard = () => {
        window.location.href = '/dashboard';
    };
    const cancelSubscription = async (subscriptionId: string) => {
        const functions = getFunctions(); // getFunctions(firebaseApp, 'australia-southeast1') if you have multiple apps
        functions.region = 'australia-southeast1'; // Set the region here
        const cancel = httpsCallable(functions, 'cancelSubscription');
        try {
            const result = await cancel({ subscriptionId });
            console.log('Result:', result.data);
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error cancelling subscription:', error.message);
                alert('Failed to cancel subscription. Please try again.');
            } else {
                console.error('Unknown error:', error);
                alert('An unexpected error occurred.');
            }
        }
      };
      const deleteOrganisation = async () => {
        setIsLoading(true);
    
        try {
            // Check if subscription ID is present
            if (subId) {
                // If subscription ID exists, cancel the subscription first
                await cancelSubscription(subId);
                console.log('Subscription cancellation successful, proceeding to delete organization.');
            } else {
                console.log('No subscription ID provided, skipping subscription cancellation.');
            }
    
            // Proceed to delete the organization whether or not the subscription was present
            const status = await deleteOrganisationInfo(orgId as string);
            if (status.code === 200) {
                console.log('Organization successfully deleted.');
                setIsLoading(false);
                setIsDelete2(false);
                setFinishDelete(true);
            } else {
                throw new Error('Failed to delete organization after subscription cancellation.');
            }
        } catch (error) {
            setIsLoading(false);
            if (error instanceof Error) {
                setIsDelete2(false);
                console.error('Error during organization deletion:', error.message);
                alert('Failed to delete organization. Please try again.');
            } else {
                setIsDelete2(false);
                console.error('Unknown error during operation:', error);
                alert('An unexpected error occurred during organization deletion.');
            }
        }
    }
    
    const uploadImageToOrganisation = async (file:File, name:string) => {
        if(file && orgId){
            setPhotoUploadState(true);
            try{
                const storageRef = await uploadFileToStorageWithProgress({path: getOrganisationStoragePath(orgId), file: file, onProgress: () => { }});
                if(storageRef.code == 200){
                    await updateOrganisationPhoto(orgId, storageRef.data[0], storageRef.data[1]);
                    setIsUploadModalOpen(false)
                }
                else{
                    setErrorMsg(storageRef.errorMsg);
                }
            }
            catch(error){
                setErrorMsg(getError(error));
            }
            setPhotoUploadState(false);
        }
    }
    const removeOrganisationPicture = async () => {
        setIsRemovingPhoto(true);
        try{
            if(orgId){
                await fileRemoveFromStorage(getOrganisationStoragePath(orgId));
                await deleteOrganisationPhoto(orgId);
                dispatch(clearOrgPhotoUrl());
                //setErrorMsg(" image removed successfully.");
                setIsUploadModalOpen(false);
            }
            else{
                setErrorMsg("User not found. Please try again.");
            }
        }
        catch(error){
            setErrorMsg(getError(error));
        }
        setIsRemovingPhoto(false);
        setIsRemovePhoto(false);
    }
    useEffect(() => { 
        const user = auth.currentUser; 
        setAdminList( [ { id: user?.uid ?? "", name: currentName ?? "", email: user?.email ?? "", photoUrl: user?.photoURL ?? undefined} ] ); 
    }, []);

    return ( 
        <>
            <Stack style={{marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent:'space-between'}} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Link key={1} href={`/dashboard`}>Dashboard</Link>
                    <Link key={2} href={`/dashboard/organisation/${orgId}`}>{orgName}</Link>
                    <Link key={3} href="#">Settings</Link>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
            </Stack>
            <UploadModal fileDelete={()=>setIsRemovePhoto(true)} title={'Upload Organisation Logo'} uploadState={photoUploadState} fileUrl={organisation.organisation?.photoUrl ?? ""} open={isUploadModalOpen} close={()=>{ setIsUploadModalOpen(false); }} upload={uploadImageToOrganisation}/>
            <AlertDialogSimpleComponent secondaryButtonTitle="Cancel" secondaryButtonClose={() => setIsRemovePhoto(false)} primaryButtonState={isRemovingPhoto} visible={isRemovePhoto} message={'Are you sure want to delete organisation picture?'} primaryButtonClose={removeOrganisationPicture} primaryButtonTitle="Yes, I'm Sure"/>
            <AlertDialogComponent severity="error" message={`You are about to delete the organisation <b style='color:#DE625B;font-weight:'bold'>${orgName}</b>. Deleting an organisation's profile will remove all associated data, including alumni, events, communications, and fundraising details. <br/><br/>Are you sure you want to continue?`} visible={isDelete1} primaryButtonTitle="Yes, Continue" primaryButtonStyle={{ backgroundColor:globalStyles["secondaryPink700"], color:"white" }} primaryButtonClose={gotToDelete2} secondaryButtonTitle="Cancel" secondaryButtonClose={()=>{ setIsDelete1(false); }}/>
            <AlertDialogComponent
                severity="error"
                message={ `Your subscription will be suspended and then permanently deleted after 30 days. Any users linked to this organisation will also lose access to this account. <br/><br/> Are you sure you want to continue?`}
                visible={isDelete2}
                primaryButtonTitle="Yes, Delete"
                primaryButtonClose={deleteOrganisation}
                primaryButtonState={isLoading}
                secondaryButtonTitle="Cancel"
                secondaryButtonClose={() => { setIsDelete2(false); }}
            />
            <AlertDialogSimpleComponent primaryButtonState={isDeleting} visible={finishDelete} message={`<b style='font-weight:'bold'>${orgName}</b> has successfully been deleted  <br/> <br/> Note: You can restore this organisation within 30 days, after which the organisation will be permanently deleted`} primaryButtonClose={navigateToOrganisationDashboard} primaryButtonTitle="Close"/>
            <CustomizedModal width={630} open={isEdit} handleClose={() => {}} isLoading={isEditLoading} loadingStr={"Updating..."}>
                <Stack>
                    <Stack direction={"row"} style={{marginTop: pxToRem(25)}} spacing={3}>
                        <Stack className={styles.newAlumniIcon}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(28)} height={pxToRem(28)} viewBox="0 0 28 28" fill="none"> <path d="M14.0001 15.1667C15.9331 15.1667 17.5001 13.5997 17.5001 11.6667C17.5001 9.73371 15.9331 8.16671 14.0001 8.16671C12.0671 8.16671 10.5001 9.73371 10.5001 11.6667C10.5001 13.5997 12.0671 15.1667 14.0001 15.1667Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M14.0001 25.6667C18.6667 21 23.3334 16.8214 23.3334 11.6667C23.3334 6.51205 19.1547 2.33337 14.0001 2.33337C8.84542 2.33337 4.66675 6.51205 4.66675 11.6667C4.66675 16.8214 9.33342 21 14.0001 25.6667Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></Stack>
                        <Stack className='smallMarginLeft'>
                            <h5 className='regular'>{orgName}</h5>
                            <small>Update your organisation's details here</small>
                        </Stack>
                    </Stack>
                    <Divider style={{ marginTop: pxToRem(30), marginBottom: pxToRem(30) }} />
                    <Stack className={styles.popupContent} style={{padding: pxToRem(10)}}>
                        <Stack className={styles.form}>
                            <InputTextField 
                                containerStyle={{flex: 1}} label='Name of Organisation*' 
                                valueCheck={organisationName.value} 
                                fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight}
                                value={organisationName.value} onChange={(e) => organisationName.setValue(e.target.value)} error={organisationName.hasError && organisationName.hasTouched} helperText={<small>{organisationName.getErrorMessage()}</small>} />
                            
                            <CustomizedDropdown
                                customHeight={constants.addAlumniInputHeight}
                                fontSize={constants.addAlumniFontSize}          
                                containerStyle={{flex:1}}         
                                datas={allCountries}
                                label='Country*'
                                value={countryName ?? ""}
                                valueCheck={countryName}
                                onChange={(e) => {
                                    setCountryName(e.target.value as string);
                                    setState("");
                                }}
                                error={hasTouchedCountry && !countryName}
                                helperText={hasTouchedCountry && !countryName ? "This field cannot be empty" : undefined}
                            />

                            <InputTextField containerStyle={{flex: 1}} label='Street Address*' 
                                valueCheck={streetAddress.value} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight}
                                value={streetAddress.value} onChange={(e) => streetAddress.setValue(e.target.value)} error={streetAddress.hasError && streetAddress.hasTouched} helperText={<small>{streetAddress.getErrorMessage()}</small>} />
                            
                            <InputTextField containerStyle={{flex: 1}} label='Suburb' 
                                valueCheck={suburb.value}  fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight}
                                value={suburb.value} onChange={(e) => suburb.setValue(e.target.value)} error={suburb.hasError && suburb.hasTouched} helperText={<small>{suburb.getErrorMessage()}</small>} />
                            
                            <Stack direction={"row"} spacing={2}> 
                                <CustomizedDropdown
                                    customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize} containerStyle={{flex:1}}
                                    datas={allStates}
                                    disabled={ allStates.length === 0 && !(hasTouchedState && !state) }
                                    value={state ?? ""} valueCheck={state ? state : undefined} onChange={(e) => setState(e.target.value as string)}
                                    label='State'
                                />
                                <InputTextField containerStyle={{flex: 1}} label='Postcode' 
                                    valueCheck={postcode.value}  fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight}
                                    value={postcode.value} onChange={(e) => postcode.setValue(e.target.value)} error={postcode.hasError && postcode.hasTouched} helperText={<small>{postcode.getErrorMessage()}</small>} /> 
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider style={{ marginTop: pxToRem(30), marginBottom: pxToRem(30) }} />
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => {setIsEdit(false); resetOrganisationDetails();}}>Cancel</SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} onClick={()=>{handleSave()}}>Update</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                <Stack className={`${styles.content} ${styles.organisationSettings}`}>
                    <Stack direction={"row"} style={{ justifyContent:'space-between' }}>
                        <Stack>
                            <h6 style={{ flexGrow: 1 }} className='regular'>{orgName}</h6>
                            <small className={`xssSpace ${styles["text--neutrals500"]}`}>Update your organisation's details here</small>
                        </Stack>
                        {/* <PrimaryButton><svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none"> <path d="M11 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M18 19V13M15 16H21M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> </svg> &nbsp; Invite New User</PrimaryButton> */}
                    </Stack>
                    <Divider style={{ marginTop: pxToRem(25), marginBottom: pxToRem(25)}}/>
                    <Paper elevation={0} className={styles.header}>
                        <Avatar onClick={() => setIsUploadModalOpen(true)} sx={{ cursor:'pointer', width: pxToRem(108), height: pxToRem(108), position: 'absolute', top: pxToRem(-52), left: pxToRem(15), backgroundColor: "#fff", border:'1px solid #F5F5F5' }}>
                            <Avatar src={organisation.organisation?.photoUrl ?? ""} sx={{ width: pxToRem(100), height: pxToRem(100),  backgroundColor: "#AFCAFF4D" }}>
                                <h4 style={{ color: "#608BDE"}}>{extractFourFirstLetters(orgName)}</h4>
                            </Avatar>
                        </Avatar>
                        <Stack className={styles.action}>
                            <Stack><FlatButton onClick={() => setIsEdit(true)} style={{ width:pxToRem(50), color:'white' }}>Edit</FlatButton></Stack>
                        </Stack>
                        <Stack className={styles.orgInfo}>
                            <small>{orgName}</small>
                            <small>{orgAddress}</small>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} className={styles.body}>
                        <Stack direction={"row"} spacing={4}>
                            <Stack style={{flex: 1.8}}>
                                <small className={styles.title}>Campuses</small>
                                <small className={styles.subtitle}>Add or delete your organisation's campuses here</small>
                            </Stack>
                            <Stack style={{flex: 1}}>
                                <Stack direction={"row"} style={{ justifyContent:'space-between', alignItems:'center'}}>
                                    <small className={styles.subtitle2}>Default Campus:</small>
                                    {campus.length > 0 ? 
                                    <CustomizedDropdown 
                                        valueCheck={defaultCampus} customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize} disabled={isCampusSelection} 
                                        containerStyle={{flex:1 , paddingLeft: pxToRem(16)}} datas={campus} value={defaultCampus ?? ""} 
                                        onChange={(e) =>{ const value = e.target.value as string; if(value){ updateCampusDefault(value); } }} />
                                        :
                                        <small className={styles.subtitle}>No Campus</small>
                                    }
                                </Stack>
                                <Stack style={{marginTop: pxToRem(10)}}>
                                {
                                    campus.map((campusItem, index) => {
                                        return(
                                            <Stack className={styles.row} key={campusItem.value + index} direction={"row"} style={{ justifyContent:'space-between', alignItems:'center'}}>
                                                <small className={`${styles.subtitle2}`}>{campusItem.label} { campusItem.value === defaultCampus ? " (default)":"" }</small>
                                                { (campusItem.value !== defaultCampus)? <label
                                                    onClick={() => { if(isCampusDeleting){return} deleteCampus(campusItem.value ?? undefined) }} 
                                                    className={`${styles.labelDelete} regular-400-14`}>Delete</label> : null }
                                            </Stack>
                                        );
                                    })
                                }
                                </Stack>
                                <Stack style={{marginTop: pxToRem(20)}}>
                                    <InputTextField 
                                        fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={newCampus.value}
                                        disabled={isCampusAdding} placeholder="Add New Campus" value={newCampus.value} 
                                        onChange={(e) => newCampus.setValue(e.target.value)}
                                        onKeyDown={(e) => { if(e.key === 'Enter'){ addCampus(newCampus.value.trim()) }}}
                                        error={newCampus.hasError && newCampus.hasTouched} 
                                        helperText={<small>{newCampus.getErrorMessage()}</small>} 
                                        isEndAdornment
                                        adornment={newCampus.value.length > 1 && !newCampus.hasError && newCampus.hasTouched ? 
                                            <label onClick={()=>{ addCampus(newCampus.value.trim()); }} 
                                                style={{cursor: 'pointer', color: isCampusAdding ? globalStyles['neutrals400'] : globalStyles['shades100']}} className="regular-600-14">Add</label> : undefined}
                                    />    
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} className={styles.body}>
                        <Stack direction={"row"} spacing={4}>
                            <Stack style={{flex: 1.8}}>
                                <small className={styles.title}>School Houses</small>
                                <small className={styles.subtitle}>Add or delete your organisation's houses here</small>
                            </Stack>
                            <Stack style={{flex: 1}}>
                                <Stack style={{marginTop: pxToRem(10)}}>
                                {
                                    house.map((houseValue, index) => {
                                        return(
                                            <Stack className={styles.row} key={index} direction={"row"} style={{ justifyContent:'space-between', alignItems:'center'}}>
                                                <small className={styles.subtitle2}>{houseValue.label}</small>
                                                <label onClick={() => { if(isHouseDeleting){return;} deleteHouse(houseValue.value ?? undefined) }} className={`${styles.labelDelete} regular-400-14`}>Delete</label>
                                            </Stack>
                                        );
                                    })
                                }
                                </Stack>
                                <Stack style={{marginTop: pxToRem(20)}}>
                                    <InputTextField 
                                        fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={newHouse.value}
                                        disabled={isHouseAdding}
                                        placeholder="Add New House" 
                                        value={newHouse.value}
                                        onChange={(e) => newHouse.setValue(e.target.value)} 
                                        error={newHouse.hasError && newHouse.hasTouched} 
                                        helperText={<small>{newHouse.getErrorMessage()}</small>} 
                                        onKeyDown={(e) => { if(e.key === 'Enter'){ addHouse(newHouse.value.trim()) }}}
                                        isEndAdornment
                                        adornment={newHouse.value.length > 1 && !newHouse.hasError && newHouse.hasTouched ? 
                                            <label onClick={()=>{ addHouse(newHouse.value.trim()); }} 
                                                style={{cursor: 'pointer', color: isHouseAdding ? globalStyles['neutrals400'] : globalStyles['shades100']}} className="regular-600-14">Add</label> : undefined}
                                    />    
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} className={styles.body}>
                        <Stack direction={"row"} spacing={4}>
                            <Stack style={{flex: 1.8}}>
                                <small className={styles.title}>Admins</small>
                                <small className={styles.subtitle}>Admin Users hold the reins of control and oversight. They have the ability to manage user permissions, define access levels, and configure an organisation's settings. They can also edit their own permissions. </small>
                            </Stack>
                            <Stack style={{flex: 1}}>
                                <Stack style={{marginTop: pxToRem(10)}} spacing={1}>
                                {
                                    adminList.map((admin, index) => {
                                        return(
                                            <Stack key={index} direction={"row"} style={{ alignItems:'center', justifyContent:'flex-end'}} spacing={5}>
                                                <Avatar style={{ width: pxToRem(48), height: pxToRem(48), backgroundColor:'rgba(175, 202, 255, 0.30)'}}><span style={{color:'#608BDE'}}>{ extractFourFirstLetters(admin.name) }</span></Avatar>
                                                <Stack style={{ justifyContent:'space-between', alignItems:'flex-start',flex: 1}}>
                                                    <small>{admin.name} { admin.id === auth.currentUser?.uid?" (You)":"" }</small>
                                                    <small>{admin.email}</small>
                                                </Stack>    
                                            </Stack>
                                        );
                                    })
                                }
                                </Stack>
                                {/* 
                                    <Stack style={{marginTop: pxToRem(20), direction:'rtl'}}>
                                        <SecondaryButton style={{ height: pxToRem(40), width: pxToRem(240)}}>Add New Admin</SecondaryButton> 
                                    </Stack> 
                                */}
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={0} className={styles.body}>
                        <Stack direction={"row"} spacing={4}>
                            <Stack style={{flex: 1.8}}>
                                <small className={styles.title}>Delete Organisation</small>
                                <small className={styles.subtitle}>Deleting an organisation's profile will remove all associated data from Loop's database, including contacts, events, communications, and fundraising details. Once deleted, the action cannot be undone.</small>
                            </Stack>
                            <Stack style={{flex: 1}}>
                            <Stack style={{marginTop: pxToRem(20), direction:'rtl'}}>
                                    <WarningButton onClick={() => setIsDelete1(true)} style={{fontSize: pxToRem(constants.addAlumniFontSize), height: pxToRem(constants.addAlumniInputHeight), width: pxToRem(240)}}>Delete Organisation</WarningButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </CustomizedPaper>
        </>
    );
};

export default OrganisationSettings;