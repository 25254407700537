import { DocumentData, FieldValue, Timestamp } from "firebase/firestore";

export type Task<FV = never> = {
    id?:string | FV;
    orgId?: string|FV;

    date?: number | FV;
    title?: string | FV;
    detail?: string | FV;
    status?: string | FV; // "Planned" "Overdue" "Unplanned" "Done" "Deleted"
    lastUpdated?: string | FV;
    shortDetail?: string | FV;
    isDone?: boolean| FV;
    isDeleted?: boolean| FV;


    createdByUserId?: string | FV;
    assignee?: string | FV;
    alumniList?: string[] | FV,
    alumniName?: string | FV;

    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;
   
}

export type AlumniEntry = {
    firstName: string;
    lastName: string;
    photoUrl: string;
};

export type NewTask<FV = never> = {
    orgId?: string|FV;
    date?: number | FV;
    title?: string | FV;
    detail?: string | FV;

    lastUpdated?: string | FV;
    shortDetail?: string | FV;

    createdByUserId?: string | FV;
    assignee?: string | FV;
    alumniList?: string[] | FV;
    alumniName?: string | FV;

    isDone?: boolean| FV;
    isDeleted?: boolean| FV;

    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;
}

export type NewComment = {
    userId: string;
    content: string;
    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;
  }

export type Comment = {
    Id: string;
    userId: string;
    content: string;
    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;
}

  

export const getTask = (data: DocumentData) : Task => {
    return{
        date: data.date,
        title: data.title,
        detail: data.detail,
        shortDetail: data.shortDetail,
        createdByUserId: data.createdByUserId,
        assignee: data.assignee,
        created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
        updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
        isDeleted:data.isDeleted,
        isDone:data.isDone,
        alumniList:data.alumniList,
        alumniName: data.alumniName,
    }
}