import { Breakpoint, Checkbox, Container, Divider, Grid, IconButton, MenuItem, Select, Stack } from '@mui/material';
import CustomizedPaper from '../../../components/CustomizedPaper';
import styles from './WeeklyGoals.module.scss';
import dashboardStyles from '../../DashboardContainerPage/index.module.scss';
import pxToRem from '../../../helpers/pxToRem';
import WeeklyGoal from './WeeklyGoal';
import sampleImage from "../../../assets/images/InternationalWomensDay.png";
import AllGoals from './AllGoals';
import MonthlyGoals from './MonthlyGoals';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';

interface IWeeklyGoals {
    handleBack: () => void;
}

const WeeklyGoals = ({handleBack} : IWeeklyGoals) => {
    const selectedMonth = useSelector((state: RootState) => state.dashboardState.weeklyGoalState.monthSelected);
    const selectedWeek = useSelector((state: RootState) => state.dashboardState.weeklyGoalState.weekSelected);

    return (
        <>
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.mainContainer}>
                    <Stack className={styles.titleContainer}>
                        <IconButton onClick={handleBack}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M20 24L12 16L20 8" stroke="#525252" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                        </IconButton>
                        <Stack alignItems={'flex-start'} gap={pxToRem(4)}>
                            <h6>Weekly Goals</h6>
                            <small>Find new goals here every week</small>
                        </Stack>
                    </Stack>
                    <Divider sx={{margin: `${pxToRem(24)} 0px`}}/>
                    <Container maxWidth={'xl'}>
                        <Grid container columnSpacing={pxToRem(24)}>
                            <Grid item xs={9}>
                                {
                                    selectedMonth ?
                                        selectedWeek ?
                                            <WeeklyGoal/>
                                        :
                                            <MonthlyGoals/>
                                    :
                                        <WeeklyGoal/>
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <AllGoals />
                            </Grid>
                        </Grid>
                    </Container>
                </Stack>
            </CustomizedPaper>
        </>
    );
};

export default WeeklyGoals;