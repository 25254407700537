import React, { useEffect } from "react";

export function useOutsideAlerter( ref: React.MutableRefObject<HTMLDivElement | null | undefined>, clickOutsideCallback: () => void ) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) { clickOutsideCallback(); }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside); };
    }, [ref]);
}