import { Box, Stack } from "@mui/material";
import styles from '../index.module.scss';
import pxToRem from "../../../helpers/pxToRem";

interface IMediaCard {
    name: string;
    icon: JSX.Element;
    className?: string;
    num?: number;
    backgroundColor?: string; // Optional background color prop
    onClick: () => void;  // Required onClick handler to trigger parent component event
}

const MediaCard = ({ name, icon, className, num, backgroundColor = '#primary400', onClick }: IMediaCard) => {
    // Determine the type of content based on the name
    const contentType = (name: string) => {
        switch (name) {
            case "Profile Photos":
                return "Photo";
            case "Files":
                return "File";
            case "URLS":
                return "Link";
            default:
                return "";
        }
    };

   // Pluralize the content type based on the number
   const formatContent = (num: number | undefined, type?: string) => {
    if (num === undefined || num <= 0 || !type) return null; // Returns null if num is 0 or undefined
    return `${num} ${type}${num > 1 ? 's' : ''}`;
};

return (
    <Box
        style={{ textDecoration: 'none', cursor: 'pointer', backgroundColor }}
        onClick={onClick}
        className={`${styles.mediaCard} ${className || ''}`}
    >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
            <div className={styles.mediaCardIcon}>{icon}</div>
            <Stack direction={'column'} minWidth={pxToRem(100)}>
                <h5 style={{ textAlign: 'start', width: '100%' }}>{name}</h5>
                {(num && num > 0) ? ( // Only render if num is greater than 0
                    <small style={{ color: 'white', marginTop: pxToRem(6), textAlign: 'start', width: '100%' }}>
                        {formatContent(num, contentType(name))}
                    </small> ): (
                        <small style={{ color: 'white', marginTop: pxToRem(6), textAlign: 'start', width: '100%' }}>
                        No record
                    </small>
                    )
                }
            </Stack>
        </Stack>
    </Box>
);
}

export default MediaCard;
