import { DatePicker, DatePickerProps, DateView } from '@mui/x-date-pickers'
import { Moment } from 'moment';
import React from 'react'
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from "../../styles/_stylesParams.module.scss";
  
interface CustomDatePickerProps extends DatePickerProps<Moment> {
    label?: React.ReactNode;
    views?: readonly DateView[];
    customHeight?: number;  // Always in px FIXME: Change it to mandatory
    fontSize?: number;  // Always in px FIXME: Change it to mandatory
    shrinkLabelWidth?: number;
    valueCheck?: any;
    pickerIconSize?: number;
    placeholder?: string;
    setCleared?: ()=>void;
    format?: string;
    disabled?: boolean;
}
export default function CustomDatePicker({label,onChange,setCleared, disabled, format, value, placeholder, views, pickerIconSize = 19,  fontSize = 16, valueCheck, customHeight = 44, shrinkLabelWidth}:CustomDatePickerProps) {
    const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1
    // const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: valueCheck !== undefined && valueCheck.trim().length === 0 || valueCheck !== undefined && valueCheck.trim() === "" || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
    const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: !valueCheck || valueCheck === null ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
  return (
    <DatePicker 
        label={label}
        views={views}
        onChange={onChange}
        value={value}
        format={format}
        disabled={disabled}
        
        sx={{
            
            '& .MuiInput-underline:after': { borderBottomColor: globalStyles.primary400, },
            '& .MuiOutlinedInput-root': { 
                '& fieldset': { borderColor: globalStyles.primary400 }, 
                '&:hover fieldset': { borderColor: globalStyles.primary400 }, 
                '&.Mui-focused fieldset': { borderColor: globalStyles.primary400 } 
            },
        }}
        slotProps={{ 
            field: { clearable: true, onClear: setCleared},
            textField:{ 
                style:{width: '100%',},
                placeholder: placeholder,
                sx:{ '& .MuiSvgIcon-root': { height: pxToRem(pickerIconSize), width:pxToRem(pickerIconSize)}},
                InputLabelProps: inputLabelProps, 
                InputProps:{ style:{ height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize) } }, 
            }          
        }}
    />
  )
}
// ADDING CUSTOM ENDADORNMENT Example
// InputProps:{
//     style:{ height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize) },
//     endAdornment:(
//         <span
//         style={{
//             display: 'flex',
//             alignItems: 'center', // You can adjust this value for vertical alignment
//             paddingRight: '8px', // Adjust the padding as needed
//             height: '40px', // Change the height as needed
//         }} >
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none"> <path d="M15 8.12132H10.8C9.11984 8.12132 8.27976 8.12132 7.63803 8.4483C7.07354 8.73592 6.6146 9.19486 6.32698 9.75935C6 10.4011 6 11.2412 6 12.9213V21.3213C6 23.0015 6 23.8416 6.32698 24.4833C6.6146 25.0478 7.07354 25.5067 7.63803 25.7943C8.27976 26.1213 9.11984 26.1213 10.8 26.1213H19.2C20.8802 26.1213 21.7202 26.1213 22.362 25.7943C22.9265 25.5067 23.3854 25.0478 23.673 24.4833C24 23.8416 24 23.0015 24 21.3213V17.1213M12 20.1213H13.6745C14.1637 20.1213 14.4083 20.1213 14.6385 20.0661C14.8425 20.0171 15.0376 19.9363 15.2166 19.8266C15.4184 19.7029 15.5914 19.53 15.9373 19.1841L25.5 9.62132C26.3284 8.79289 26.3284 7.44975 25.5 6.62132C24.6716 5.79289 23.3284 5.79289 22.5 6.62132L12.9372 16.1841C12.5913 16.53 12.4184 16.7029 12.2947 16.9048C12.185 17.0837 12.1042 17.2788 12.0552 17.4829C12 17.713 12 17.9576 12 18.4468V20.1213Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> 
//         </span>
//     ),
// }, 
