import { useEffect, useState } from "react";
import constants from "../helpers/constants";
import { isMobile } from "react-device-detect";

interface IScreenSizeWatcher { 
    children: JSX.Element; 
    fallback: JSX.Element;
}
export function ScreenSizeWatcher({children, fallback}: IScreenSizeWatcher){
    // const [isMobile, setIsMobile] = useState(false);

    // const windowResize = () => {
    //     if(window.innerWidth <= constants.mobileScreenSize.width) setIsMobile(true);
    //     else if(window.innerHeight <= constants.mobileScreenSize.width) setIsMobile(true);
    //     else setIsMobile(false);
    // }

    // useEffect(() => {
    //     windowResize();
    //     window.addEventListener('resize', windowResize);
    //     return () => { window.removeEventListener('resize', windowResize);}
    // }, []);

    return ( <> { isMobile?fallback:children } </> );
}