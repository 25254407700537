import { Avatar, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from './index.module.scss';

export const MobileFallbackComponent = () => {
    return(
        <Stack className={styles.wrapper}>
            <Stack spacing={4} className={styles.container}>
                <Link to="/">
                    <Avatar className={styles.circleOuter}>
                        <Avatar src={require('../../assets/images/logo/logo512.png')} className={styles.logo} />
                    </Avatar>
                </Link>
                <Stack className={styles.labelWrapper} spacing={1}>
                    <label className={styles.label}>We're currently only available on desktop screens</label>
                    <label className={styles.label}>Log in from your laptop or desktop to start building your alumni community today. </label>
                </Stack>
            </Stack>
        </Stack>
    );
}

export const LoadingFallbackComponent = () => {
    return(<Stack sx={{ height: "100vh", width:"100vw" }} justifyContent={"center"}> <LoadingSpinner /> </Stack>);
}