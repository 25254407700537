import { Stack } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WeeklyGoalType } from "../../../firebase/types-weeklyGoals";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { selectWeek } from "../../../redux/dashboardSlice";
import { getWeekNumbersOfMonth } from "../helper";
import styles from "./MonthlyGoals.module.scss";

interface IMonthlyGoals {

}

const MonthlyGoals = ({}:IMonthlyGoals) => {

    const goalState = useSelector((state:RootState) => state.dashboardState.goals);
    const selectedMonth = useSelector((state:RootState) => state.dashboardState.weeklyGoalState.monthSelected);

    const goals = useMemo(()=>{
        if(!selectedMonth) return [];
        const currentDate = new Date();
        const monthWeeks = getWeekNumbersOfMonth(currentDate.getFullYear(), selectedMonth);
        return goalState
                .filter(g => g.code && monthWeeks.includes(parseInt(g.code)))
                .sort((a,b) => {
                    // Sort by week number
                    if(a.code && b.code) {
                        return parseInt(a.code) - parseInt(b.code);
                    }
                    return 0;
                })
    }, [goalState, selectedMonth]);


    return (
        <Stack>
            <h5 className={styles.title}>{moment(selectedMonth, 'M').format("MMMM")} Goals</h5>
            {
                goals.length > 0 ?
                    <Stack gap={pxToRem(24)} marginTop={pxToRem(24)}>
                        {
                            goals.map((g, i) => (
                                <GoalCard key={i} week={i+1} goal={g}/>
                            ))
                        }
                    </Stack>
                :
                    <p style={{marginTop:pxToRem(20)}}>There are no weekly goals this month!</p>
            }
        </Stack>
    );
};

interface IGoalCard {
    week: number;
    goal: WeeklyGoalType;
}
const GoalCard = ({week, goal}:IGoalCard) => {
    const dispatch = useDispatch();
    const progress = useSelector((state: RootState) => state.dashboardState.progress);
    const goals = useSelector((state: RootState) => state.dashboardState.goals);
    const selectedMonth = useSelector((state:RootState) => state.dashboardState.weeklyGoalState.monthSelected);

    const complete = useMemo(() => {
        const orgTasksCompleted = progress.filter(p => {
            return p.code && goal.code && p.code === goal.code
        })[0];

        if(orgTasksCompleted) {
            const numTaskedCompleted = orgTasksCompleted.tasks.filter(t => t.checked === true).length
            const thisWeek = goals.filter(g => g.code && goal.code && g.code === goal.code)[0]
            if(thisWeek) {
                return thisWeek.tasks.length === numTaskedCompleted;
            }
        }

        return false;
    }, [progress, goals, selectedMonth])

    return (
        <Stack onClick={()=>{goal.code && dispatch(selectWeek(parseInt(goal.code)))}} className={styles.goalCard}>
            <Stack className={styles.weekBox} style={{backgroundColor: styles[`secondaryBlue${week}00`]}}>
                Week {week}
                {
                    complete && <svg className={styles.completeIcon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="16" cy="16" r="16" fill="#F7766F"/> <path d="M22.6663 11L13.4997 20.1667L9.33301 16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                }
            </Stack>
            <Stack className={styles.textBox}>
                <p>{goal.goalHeading}</p>
                <small>{goal.goalDescription}</small>
            </Stack>
        </Stack>
    );
};

export default MonthlyGoals;