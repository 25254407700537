import { signOut } from "@firebase/auth";
import { Dispatch } from "@reduxjs/toolkit";
import { createUserWithEmailAndPassword, deleteUser, EmailAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider, reauthenticateWithCredential, reauthenticateWithPopup, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, updateProfile, User } from "firebase/auth";
import { arrayUnion, FirestoreError, doc, getDoc, runTransaction, serverTimestamp, setDoc, updateDoc, writeBatch, collection, deleteDoc, arrayRemove, query, getDocs, where, deleteField, } from "firebase/firestore";
import { auth, db } from ".";
import { GENERAL_ERROR_PROMPT } from "../helpers/shared";
import { joinStringWithSpace } from "../helpers/utils";
import { setLoginRoute, setUserDelete, setUserInfoDefault, setUserLogout, updateUserUid } from "../redux/userSlice";
import { getLoginFailurePromptBasedOnErrorCode } from "../pages/LoginPage/helpers";
import { setAlumniDefault } from "../redux/alumniSlice";
import { setOnBoardingDefault } from "../redux/onBoardingSlice";
import { setOrganisationStateDefault } from "../redux/organisationSlice";
import { setPageStatusDefault } from "../redux/pageStatusSlice";
import { resetSharedStates, setSharedStateDefault } from "../redux/sharedStateSlice";
import { AccountType, getInvitation, getOrganisation, Invitation, Permission, Res, Users, UserType } from "./shared";
import { resetGlobalState } from "../redux/globalSlice";

export const signOutUser = async (dispatch: Dispatch): Promise<Res<null>> => {
    try {
        dispatch(setUserInfoDefault());
        dispatch(setAlumniDefault());
        dispatch(setOnBoardingDefault());
        dispatch(setOrganisationStateDefault());
        dispatch(setPageStatusDefault());
        dispatch(resetSharedStates());
        dispatch(setUserLogout());
        await signOut(auth);
        return { code: 200, data: null, };
    } 
    catch (error: any) {
        console.log(error);
        return { code: 500, };
    }
};
export const signinUser = async ( email: string, password: string ): Promise<Res<User>> => {
    try {
        const credential = await signInWithEmailAndPassword(auth, email, password);
        return { code: 200, data: credential.user, };
    } 
    catch (error: any) {
        const errorCode = error.code;
        console.log(error);
        return { code: 500, errorMsg: getLoginFailurePromptBasedOnErrorCode(errorCode), };
    }
};
export const googleSignUp = async (dispatch: Dispatch): Promise<Res<User>> => {
    try {
        const provider = new GoogleAuthProvider();
        const credential = await signInWithPopup(auth, provider);
        const user = credential.user;

        const firstName = user.displayName?.split(" ")[0] ?? "";
        const lastName = user.displayName?.split(" ")[1] ?? "";
        const email = user.email ?? "";
        const contactNumber = user.phoneNumber ?? undefined;

        const currentAuth = getAuth();
        await createNewUserInFb(user.uid, firstName, lastName, email, contactNumber).catch(async (error) => {
            console.error("Failed to create user in DB:", error);
            if (currentAuth.currentUser) {
                await currentAuth.currentUser.delete();
                await signOutUser(dispatch);
            }
            throw new Error("Failed to finalize user registration.");
        });

        dispatch(setLoginRoute(true));
        return { code: 200, data: user };
    } catch (error: any) {
        console.error("Authentication error:", error);
        return { code: 500, errorMsg: getLoginFailurePromptBasedOnErrorCode(error.code) };
    }
};

export const googleSignIn = async (dispatch: Dispatch): Promise<Res<User>> => {
    try {
        const provider = new GoogleAuthProvider();
        const credential = await signInWithPopup(auth, provider);
        const user = credential.user;

        dispatch(setLoginRoute(true));

        console.log("User signed in successfully:", user.displayName);
        return { code: 200, data: user };
    } catch (error: any) {
        console.error("Authentication error:", error);
        return { code: 500, errorMsg: getLoginFailurePromptBasedOnErrorCode(error.code) };
    }
};
export const sendResetPassword = async (email: string): Promise<Res<null>> => {
    try {
    await sendPasswordResetEmail(getAuth(), email);

    return {
        code: 200,
        data: null,
    };
    } 
    catch (error: any) {
    console.log("Errors happened when trying to send reset password email");
    console.log(error);
    let errorMsg = GENERAL_ERROR_PROMPT;
    if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/invalid-email" ||
        error.code === "auth/internal-error" ||
        error.code === "auth/missing-email"
    ) {
        errorMsg = "Email not found in database.";
    }

    return {
        code: 500,
        errorMsg,
    };
    }
};
export const createNewUserInFb = async ( userId: string, firstName: string, lastName: string, email: string, phone?: string ) => {
    //early access user check
    
    const newUser: UserType = { firstName:firstName, lastName:lastName, email: email, ...(phone && { contactNumber: phone }), termsconditions: serverTimestamp(), created_at:serverTimestamp(), step: 'SEARCH_PLACE', search_place_updated_at: serverTimestamp(), lastActivity: serverTimestamp()  };
    const usersDocumentRef = doc(db, "users", userId);
    const snap = await getDoc(usersDocumentRef);
    if(!snap.exists()) return setDoc(usersDocumentRef, newUser);
};
export const signupUser = async ( email: string, password: string, firstName: string, lastName: string, phone: string | undefined, dispatch:Dispatch ): Promise<Res<Users>> => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password );
        const uid = userCredential.user.uid;
        const currentAuth = getAuth();

        await createNewUserInFb(uid, firstName, lastName, email, phone).catch(async (e) => { if (currentAuth.currentUser) { await currentAuth.currentUser.delete(); await signOutUser(dispatch); } throw new Error(); });
        if (currentAuth.currentUser) { 
            await updateProfile(currentAuth.currentUser, { displayName: joinStringWithSpace(firstName, lastName)});
            // await sendEmailVerification(currentAuth.currentUser).catch((e) => { console.log("Fail to send verification email"); console.log(e); }); 
        }
        return { code: 200, data: { [uid]: { email, firstName, lastName }, }, };
    } 
    catch (error: any) {
        console.log("Errors happened when trying to sign up user");
        console.log(error);
        return { code: 500, errorMsg: error.code, };
    }
};

export const getUserInfoById = async ( uid: string ): Promise<Res<UserType | null>> => {
    try {
        let data: UserType | null = null;
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) { data = (await docSnap.data()) as UserType; }
        return { code: 200, data, };
    } 
    catch (error) {
        console.log( "Errors happened when trying to get user info based on login id" );
        console.log(error);
        return { code: 500, };
    }
};

export const getUsersById = async (uids: string[]):  Promise<Res<{id: string, user: UserType}[]>> => {
    try {
        let data: {id: string, user: UserType}[] = [];
        await runTransaction(db, async (t) => {
            for(const uid of uids) {
                const docRef = doc(db, "users", uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) { data.push({id: uid, user: (docSnap.data()) as UserType})}
            }
        })
        return { code: 200, data, };
    } catch(error) {
        console.log( "Errors happened when trying to get users info based on login id" );
        console.log(error);
        return { code: 500, };
    }
}

export const getUserNameById = async (uid: string): Promise<Res<string | null>> => {
    try {
      let firstName: string | null = null;
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData: UserType = docSnap.data() as UserType;
        firstName = userData.firstName;
      }
      return { code: 200, data: firstName };
    } 
    catch (error) {
      console.log("Errors happened when trying to get user info based on login id");
      console.error(error);
      return { code: 500, errorMsg: "An error occurred" };
    }
  };

//NEW
export const updateUserPhoto = async(userId:string, photoUrlId: string, photoUrl: string): Promise<Res<string>> => {
    try{
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);
        const updatedData = { updated_at: serverTimestamp(), photoUrlId: photoUrlId, photoUrl: photoUrl};
        batch.update(docRef, updatedData);
        await batch.commit();
        return { code: 200, data: "Update photo successfully." };
    }
    catch{
        return { code: 500 };
    }
}
export const updateUserFullName = async(userId:string, firstName: string, lastName: string): Promise<Res<string>> => {
    try{
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);
        const updatedData = { updated_at: serverTimestamp(), firstName: firstName, lastName: lastName};
        batch.update(docRef, updatedData);
        await batch.commit();
        return { code: 200, data: "User: Full name updated." };
    }
    catch{
        return { code: 500 };
    }
}

export const updateUserContactNumber = async (userId: string, contactNumber: string): Promise<Res<string>> => {
    try {
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);
        batch.update(docRef, { contactNumber: contactNumber, updated_at: serverTimestamp() });
        await batch.commit();
        return { code: 200, data: "Contact number updated successfully." };
    } catch {
        return { code: 500};
    }
}

export const updateUserRole = async (userId: string, role: string): Promise<Res<string>> => {
    try {
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);
        batch.update(docRef, { role: role, updated_at: serverTimestamp() });
        await batch.commit();
        return { code: 200, data: "Role updated successfully." };
    } catch {
        return { code: 500};
    }
}


export const updateUserType = async (userId: string, orgId: string, userType: 'ADMIN' | 'USER'): Promise<Res<string>> => {
    const orgRef = doc(db, "organisations", orgId);
    const userRef = doc(db, "organisations", orgId, "users", userId); // Reference to the user document in subcollection
    const batch = writeBatch(db);

    try {
        // Fetch the current organization document
        const orgDoc = await getDoc(orgRef);
        if (!orgDoc.exists()) {
            throw new Error("Organisation not found.");
        }
        const orgData = orgDoc.data();
        const currentAdmins: string[] = orgData.admins || [];
        let isAdminUpdateNeeded = false; // Flag to check if admin update is needed

        // Determine if modifications are necessary
        const isCurrentlyAdmin = currentAdmins.includes(userId);
        if (userType === 'ADMIN' && !isCurrentlyAdmin) {
            // Add userId to admins if not already present
            currentAdmins.push(userId);
            isAdminUpdateNeeded = true;
        } else if (userType === 'USER' && isCurrentlyAdmin) {
            // Remove userId from admins if present
            const index = currentAdmins.indexOf(userId);
            if (index > -1) {
                currentAdmins.splice(index, 1);
                isAdminUpdateNeeded = true;
            }
        } else {
            // Return early if no update is needed
            return { code: 200, data: `No update needed for ${userId} as ${userType}.` };
        }

        // Update the organisation document with the new list of admins
        batch.update(orgRef, { admins: currentAdmins, updated_at: serverTimestamp() });

        // Update or create the document in the users subcollection
        if (isAdminUpdateNeeded) {
            batch.set(userRef, { isAdmin: userType === 'ADMIN' }, { merge: true }); // Set isAdmin based on the userType
        }

        // Commit the batch operation
        await batch.commit();
        return { code: 200, data: `User type updated to ${userType} for user ${userId} in organization ${orgId}. Admin status updated: ${isAdminUpdateNeeded}.` };
    } catch (error) {
        console.error("Error updating user type:", error);
        const firestoreError = error as FirestoreError;
        return { code: 500, errorMsg: firestoreError.message };
    }
};

export const updateAllUserPermissions = async (userId: string, orgId: string, newPermissions: Permission): Promise<Res<string>> => {
    const usersSubcol = doc(db, "organisations", orgId, "users", userId);

    try {
        // Update or create the document in the users subcollection with new permissions
        await setDoc(usersSubcol, newPermissions, { merge: true });

        console.log("Permissions updated:", newPermissions);

        return { code: 200, data: `All permissions updated for user ${userId} in organization ${orgId}.` };
    } catch (error) {
        console.error("Error updating all user permissions:", error);
        const firestoreError = error as FirestoreError;
        return { code: 500, errorMsg: firestoreError.message };
    }
};


export const deleteUserPhoto = async(userId:string): Promise<Res<string>> => {
    try{
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);
        const updatedData = {
            updated_at: serverTimestamp(),
            photoUrlId: deleteField(),
            photoUrl: deleteField() 
          };        
        batch.update(docRef, updatedData);
        await batch.commit();
        return { code: 200, data: "Update photo successfully." };
    }
    catch{
        return { code: 500 };
    }
}

export const reauthenticateUser = async (password: string) => {
    try {
        const userId = auth.currentUser?.uid;
        if(auth.currentUser) {
            const provider = auth.currentUser.providerData[0].providerId;
            let credential = null;
            if(provider === 'password') {
                if(auth.currentUser.email) {
                    credential = EmailAuthProvider.credential(auth.currentUser.email, password);
                    if(!credential) throw new Error("Provider not found");
            
                    console.log(credential);

                    await reauthenticateWithCredential(auth.currentUser, credential)
                        .catch((error: any) => {
                            console.log("returning error")
                            throw new Error(error);
                        })
                }
            } else {
                if(auth.currentUser) {
                    await reauthenticateWithPopup(auth.currentUser, new OAuthProvider(provider))
                        .catch((error) => {
                            throw new Error(error);
                        })
                }
            }
            return { code: 200 }
        }
        throw new Error("User is not authenticated!");
    } catch (error: any) {
        const errorCode = error.code;
        if(errorCode) return { code: 500, errorMsg: getLoginFailurePromptBasedOnErrorCode(errorCode)}
        else return {code: 500, error}
    }
}

export const deleteUserAccount = async (dispatch: Dispatch) => {
    try {
        if(auth.currentUser) {
            await deleteUser(auth.currentUser);
            dispatch(setUserDelete(true));
            dispatch(resetGlobalState());
            dispatch(setUserInfoDefault());
            dispatch(setOnBoardingDefault());
            dispatch(setAlumniDefault());
            dispatch(setOrganisationStateDefault());
            dispatch(setPageStatusDefault());
            dispatch(resetSharedStates());
            dispatch(setUserLogout());
            return { code: 200 }
        }
        throw new Error ("User is not authenticated!");
    } catch(error: any) {
        const errorCode = error.code;
        if(errorCode) return {code:500, errorMsg: errorCode}
        console.log(error);
        return { code: 500, error }
    }
}

export const deleteGeneralUser = async (userId: string, orgId: string): Promise<Res<string>> => {
    const orgRef = doc(db, "organisations", orgId);

    try {
        const usersSubdoc = doc(db, "organisations", orgId, "users", userId);
        const userRef = doc(db, 'users', userId,); 
        await deleteDoc(usersSubdoc)

        // Update the organization document to remove the user and their permissions
        await updateDoc(orgRef, {
            users: arrayRemove(userId),
            // Remove admin if admin
            admins: arrayRemove(userId)
        });

        // Update the user document to remove the organisation id
        await updateDoc(userRef, {
            organisations: arrayRemove(orgId)
        });
                
        return { code: 200, data: "User and permissions successfully deleted." };
    } catch (error) {
        console.error("Error deleting user:", error);
        return { code: 500 };
    }
};

export const removeUserOrgs = async (orgIds: string[]) => {
    try {
        const userId = auth.currentUser?.uid;
        if(!userId) throw new Error ("User is not authenticated!");
        const batch = writeBatch(db);
        const userRef = doc(db, 'users', userId);
        for(const id of orgIds) {
            const orgRef = doc(db, "organisations", id);
            const usersSubdoc = doc(db, "organisations", id, "users", userId);
            batch.delete(usersSubdoc);
            batch.update(orgRef, {
                users: arrayRemove(userId),
                // Remove admin if admin
                admins: arrayRemove(userId)
            })
            // Update user document to remove the organisation from their list
            batch.update(userRef, {
                organisations: arrayRemove(id)
            });
        }
        batch.commit();
        return { code: 200, }
    } catch (error: any) {
        console.error("Error removing users from organisations:", error);
        return { code: 500, error };
    }
}

export const setBetaUserPasswordUpdateState = async(userId:string): Promise<Res<string>> => {
    try{
        const docRef = doc(db, "users", userId);
        await updateDoc(docRef,{ betaPasswordUpdate:true})
        return { code: 200, data: "Update Beta user password successfully." };
    }
    catch{ return { code: 500 }; }
}

export const updateLastActivity = async (userId: string, orgId: string): Promise<void> => {
    const localStorageKey = `lastActivityUpdate-${userId}`;
    const now = new Date().getTime();
    const lastUpdate = localStorage.getItem(localStorageKey);
    
    // Check if the last update was more than 10 minutes ago
    if (lastUpdate) {
        const lastUpdateTime = parseInt(lastUpdate, 10);
        const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds
        
        if (now - lastUpdateTime < tenMinutes) {
            console.log('Less than 10 minutes since last update. Skipping Firestore update.');
            return;
        }
    }

    // Update Firestore and localStorage
    try {
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
            lastActivity: serverTimestamp(),
        });

        const orgRef = doc(db, "organisations", orgId);
        await updateDoc(orgRef, {
            lastActivity: serverTimestamp(),
        });

        // Update the timestamp in localStorage after successful Firestore update
        localStorage.setItem(localStorageKey, now.toString());
        console.log('Last activity timestamp updated successfully');
    } catch (error) {
        console.error('Error updating last activity timestamp:', error);
    }
};

export const validateInviteId = async (inviteId: string): Promise<Res<Invitation>> => {
    try {
        const docRef = doc(db, "invitations", inviteId);
        const snap = await getDoc(docRef);
        if(snap.exists()) {
            return {code: 200, data: getInvitation(snap.id, snap.data())}
        } 
        return { code: 500 }
    } catch(error) {
        console.error('Error validating invite id: ', error);
        return { code: 500 }
    }
}

export const createInvitation = async (invite: { inviteId?: string; orgId: string; orgName?: string; host?: string; inviteeEmail: any; accountType?: AccountType; permissions?: Permission;}) => {
    try {
        await runTransaction(db, async (t) => {
            const orgRef = doc(db, "organisations", invite.orgId);
            const orgSnap = await t.get(orgRef);
            if(orgSnap.exists()) {
                const org = getOrganisation(orgSnap.data());
                // If createOrg does not exist/false then user has deleted the checklist from dashboard -> don't update checklist
                if(org.checklist && org.checklist.createOrg) {
                    t.update(orgRef, {
                        checklist: {
                            ...org.checklist,
                            inviteTeam: true
                        }
                    })
                }
            }
            

            const inviteRef = doc(collection(db, "invitations"));
            // Create the document with the initial data
            t.set(inviteRef, {
                ...invite,
                inviteId: inviteRef.id, // Use the ID generated by Firestore for the inviteId
                sentAt: serverTimestamp()
            });
            // TODO: Send email to the invitee's email
            // Optional: Send email to the invitee's email
            console.log(`Invitation sent to ${invite.inviteeEmail}`);
            return { success: true, id: inviteRef.id };
        });
    } catch (error) {
        console.error(`Error creating invitation for ${invite.inviteeEmail}:`, error);
        return { success: false, error };
    }
}

export const resendInvitation = async (inviteId: string, userType: AccountType, permissions: Permission) => {
    try {
        await runTransaction(db, async (t) => {
            const inviteRef = doc(db, "invitations", inviteId);
            const snap = await t.get(inviteRef);
            if(snap.exists()) {
                const reinviteRef = doc(collection(db, "invitations"))
                const reinvite = getInvitation(reinviteRef.id, snap.data());
                // Delete invite doc
                t.delete(inviteRef);
                // Create new invite doc
                t.set(reinviteRef, {
                    ...reinvite,
                    accountType: userType,
                    permissions: permissions,
                    inviteId: reinviteRef.id,
                    sentAt: serverTimestamp()
                });
               
            }
        })
        return {code:200}
    } catch (error: any) {
        console.error(`Error creating re-sending invitation for ${inviteId}:`, error);
        return { code:500, errorMsg: error.message };
    }
}

export const deleteInvitation = async (inviteId: string) => {
    try {
        const inviteRef = doc(db, "invitations", inviteId); // Reference to the specific invitation document
        await deleteDoc(inviteRef); // Delete the document
        console.log(`Invitation with ID ${inviteId} has been successfully deleted.`);
        return { success: true, id: inviteId };
    } catch (error) {
        console.error(`Error deleting invitation with ID ${inviteId}:`, error);
        return { success: false, error };
    }
}

export const updateInviteUserType = async (inviteId: string, userType: AccountType): Promise<Res<string>> => {
    const inviteRef = doc(db, "invitations", inviteId);
    try {
        await updateDoc(inviteRef, { accountType: userType });
        return { code: 200, data: "User type updated successfully." };
    } catch (error) {
        console.error(`Error updating user account type for ${inviteId}:`, error);
        const firestoreError = error as FirestoreError;
        return { code: 500, errorMsg: `Error updating user account type: ${firestoreError}` };
    }
};

export const updateInvitePermissions = async (inviteId: string, permissions: Permission): Promise<Res<string>> => {
    const inviteRef = doc(db, "invitations", inviteId);
    try {
        await updateDoc(inviteRef, { permissions });
        return { code: 200, data: "Permissions updated successfully." };
    } catch (error) {
        console.error(`Error updating permissions for ${inviteId}:`, error);
        const firestoreError = error as FirestoreError;
        return { code: 500, errorMsg: `Error updating permissions: ${firestoreError}` };
    }
};

export const acceptInvitation = async (userId: string, inviteId: string) => {
    try {
        // Remove onboarding process
        const inviteRef = doc(db, "invitations", inviteId);
        const userRef = doc(db, "users", userId);
        await runTransaction(db, async(t) => {
            console.log("accept route 1");
            const snap = await t.get(inviteRef);
            if(snap.exists()) {
                var invitation = getInvitation(snap.id, snap.data());

                const orgRef = doc(db, "organisations", invitation.orgId);

                t.update(orgRef, {
                    users: arrayUnion(userId),
                    ...(invitation.accountType === AccountType.ADMIN && {admins: arrayUnion(userId)})
                })

                // Create document in subcollection of organisation
                const usersSubdoc = doc(db, "organisations", invitation.orgId, "users", userId)
                t.set(usersSubdoc, {
                    ...invitation.permissions,
                    isAdmin: invitation.accountType === AccountType.ADMIN
                })

                // Update user document to add the organisation to their list and mark onboarding as complete
                t.update(userRef, {
                    organisations: arrayUnion(invitation.orgId),
                    onBoarding: true,
                });

                // TODO Delete the invitation document
                t.delete(inviteRef);

                console.log("accept route 6");
            }
        })
    } catch (error) {
        console.log("Error accepting invitation", error);
    }
}

export const fetchInvitationsCount = async (orgId: string) => {
    try {
        const invitationsRef = collection(db, "invitations");
        const q = query(invitationsRef, where("orgId", "==", orgId)); // Query filtering by orgId
        const querySnapshot = await getDocs(q);
        const count = querySnapshot.size; // Get the number of documents that match the query
        console.log(`Total invitations for orgId ${orgId}: ${count}`);
        return { success: true, count };
    } catch (error) {
        console.error(`Error fetching invitation count for orgId ${orgId}:`, error);
        return { success: false, error };
    }
}

// Function to update user step in Firebase with dynamic timestamp naming
export const updateUserStep = async (userId: string, step: string): Promise<Res<string>> => {
    try {
        const batch = writeBatch(db);
        const docRef = doc(db, "users", userId);

        // Dynamically set the step and timestamp field names
        const stepField = `step`;
        const timestampField = `${step.toLowerCase()}_updated_at`;

        // Prepare the update object
        const updateData = {
            [stepField]: step,
            [timestampField]: serverTimestamp()
        };

        batch.update(docRef, updateData);
        await batch.commit();
        return { code: 200, data: "Step updated successfully." };
    } catch (error) {
        console.error("Error updating user step:", error);
        return { code: 500, errorMsg: "Failed to update step" };
    }
};
