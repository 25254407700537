import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


interface ActionSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  onUndo?: () => void;
  actionDuration?: number; // Optional, defaults to 3000ms
  noUndo?: boolean; // Optional, controls the visibility of the UNDO button
}

const ActionSnackbar: React.FC<ActionSnackbarProps> = ({
  open,
  message,
  onClose,
  onUndo,
  actionDuration = 3000,
  noUndo = false // Default is false, showing the UNDO button by default
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={actionDuration}
      onClose={onClose}
      message={message}
      action={
        <>
          {!noUndo && ( // Only show UNDO button if noUndo is false
            <Button color="inherit" size="small" onClick={onUndo}>
              UNDO
            </Button>
          )}
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default ActionSnackbar;
