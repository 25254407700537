import React from 'react';
import './RoundCheckbox.css';

interface RoundCheckboxProps {
  id: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RoundCheckbox: React.FC<RoundCheckboxProps> = ({ id, checked, indeterminate, onChange }) => {

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  // Ensure label click updates the checkbox state
  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    event.preventDefault(); // Prevent default to avoid any double-trigger of the checkbox
    onChange(new Event('change') as unknown as React.ChangeEvent<HTMLInputElement>); // Manually trigger onChange
  };

  return (
    <div className="round">
      <input 
        type="checkbox" 
        id={id} 
        checked={checked} 
        onChange={onChange} 
        ref={inputRef}
        className="round__input"
      />
      <label htmlFor={id} className="round__label" onClick={handleLabelClick}></label>
    </div>
  );
};

export default RoundCheckbox;
