import { Stack, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import pxToRem from "../../../../helpers/pxToRem";

interface IFormMenu {
    isActive: boolean;
    handleEditForm?: ()=>void;
    handleCloseForm?: ()=>void;
    handleDeleteForm: ()=>void;
}

const FormMenu = ({isActive, handleEditForm, handleCloseForm, handleDeleteForm}: IFormMenu) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e:any) => {
        e.stopPropagation()
        setAnchorEl(null);
    };

    return (
        <>
            <Stack alignItems={"center"} justifyContent={"center"} style={{height: "fit-content"}}>
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="1.99609" cy="2" r="2" transform="rotate(90 1.99609 2)" fill="#737373"/><circle cx="1.99609" cy="10" r="2" transform="rotate(90 1.99609 10)" fill="#737373"/><circle cx="1.99609" cy="18" r="2" transform="rotate(90 1.99609 18)" fill="#737373"/></svg>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {/* TODO: change options the form is closed */}
                    {
                        isActive && handleEditForm && handleCloseForm && [   
                            <MenuItem key={"edit"} onClick={(e:any) => {handleEditForm(); handleClose(e);}}>Edit</MenuItem>,
                            <MenuItem key={"archive"} onClick={(e:any) => {handleCloseForm(); handleClose(e);}}>Archive Form</MenuItem>
                        ]
                    }
                    <MenuItem onClick={(e:any) => {handleDeleteForm(); handleClose(e);}}>Permanently Delete</MenuItem>
                </Menu>
            </Stack>
        </>
    );
}

export default FormMenu;