import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { LoadingFallbackComponent, MobileFallbackComponent } from "../components/FallbackComponent";
import { ScreenSizeWatcher } from "../hooks/screenSizeProvider";
import AccountSettingsPage from "../pages/AccountSettingsPage";
import AlumniPage from "../pages/AlumniPage";
import AlumniDetails from "../pages/AlumniPage/alumniDetails";
import DashboardContainerPage from "../pages/DashboardContainerPage";
import DashboardPage from "../pages/DashboardContainerPage/dashboard";
import Error404 from "../pages/ErrorPage/404";
import LandingPage from "../pages/LandingPages";
import AboutPage from "../pages/LandingPages/About";
import LearnMorePage from "../pages/LandingPages/LearnMore";
import ContactPage from "../pages/LandingPages/Contact";
import Home from "../pages/LandingPages/Home";
import PricingPage from "../pages/LandingPages/Pricing";
import OrganisationPage from "../pages/OrganisationPage";
import OrganisationSettings from "../pages/OrganisationPage/organisationSettings";
import PlannerPage from "../pages/OrganisationPlannerPage";
import RegistrationForm from "../pages/RegistrationForm";
import SelfRegistrationFormsPage from "../pages/SelfRegistrationFormsPage";
import FormPage from "../pages/SelfRegistrationFormsPage/components/FormPage";
import PermissionRoute from "../HOC/PermissionRoute";
import BillingPage from "../pages/BillingPage";
import { DashboardRoute, OnboardingRoute, PublicRoute } from "./helpers";
import { URL_PARAMS } from "./shared";
import AlumniTask from "../pages/AlumniPage/AlumniTaskPage/AlumniTask";
import AlumniNotePage from "../pages/AlumniPage/AlumniNotesPage/AlumniNotePage";
import UserPermissionsPage from "../pages/TeamPage/UserPermissionsPage";
import EngagementPage from "../pages/EngagementPage";
import EngagementDetailPage from "../pages/EngagementPage/EngagementDetail";
import AlumniEngagementPage from "../pages/AlumniPage/AlumniEngagementPage/AlumniEngagement";
import ImportPage from "../pages/ImportPage";
import NetworkPage from "../pages/NetworkPage";
import NetworkDetails from "../pages/NetworkPage/networkDetails";
import NetworkNotePage from "../pages/NetworkPage/NetworkNotesPage/NetworkNotePage";
import NetworkConnectionPage from "../pages/NetworkPage/NetworkConnectionPage/NetworkConnectionPage";
import AlumniConnectionPage from "../pages/AlumniPage/AlumniConnectionPage/AlumniConnectionPage";
import MediaPage from "../pages/MediaPage";
import NetworkMediaPage from "../pages/NetworkMediaPage";
import NetworkTask from "../pages/NetworkPage/NetworkTaskPage/NetworkTask";
import NetworkEngagementPage from "../pages/NetworkPage/NetworkEngagementPage/NetworkEngagement";

export type RouteItem = { path: string; element?: JSX.Element };

// const LoginPage = lazy(() => import("../pages/LoginPage"));
const LoginPage = lazy(() => import("../pages/LoginPage").then((module) => ({ default: module.default, })) );
const LinkLoginPage = lazy(() => import("../pages/LoginPage/LinkLoginPage").then((module) => ({ default: module.default, })) );
const ForgetpasswordPage = lazy(() => import("../pages/ForgetPasswordPage").then((module) => ({ default: module.default, })) );
// const BetaUpdatePassword = lazy(() => import("../pages/BetaCreateNewPassword").then((module) => ({ default: module.default, })) );
const SignupPage = lazy(() => import("../pages/SignupPage").then((module) => ({ default: module.default, })) );
const LinkSignUpPage = lazy(() => import("../pages/SignupPage/LinkSignUpPage").then((module) => ({ default: module.default, })) );
// const VerifyEmailPage = lazy(() => import("../pages/VerifyEmailPage").then((module) => ({ default: module.default, })) );
const OnboardingPage = lazy(() => import("../pages/OnboardingPage").then((module) => ({ default: module.default, })) );
const TeamPage = lazy(() => import("../pages/TeamPage").then((module) => ({ default: module.default, })) );

export const RootRouters: RouteItem[] = [
    //Landing Page
    { path: `/`, element: <LandingPage><Home /></LandingPage>},
    // { path: `/${URL_PARAMS.HOME}`, element: <LandingPage><Home /></LandingPage>},
    { path: `/${URL_PARAMS.LEARN_MORE}`, element: <LandingPage><LearnMorePage /></LandingPage>},
    { path: `/${URL_PARAMS.PRICING}`, element: <LandingPage><PricingPage /></LandingPage>},
    { path: `/${URL_PARAMS.ABOUT}`, element: <LandingPage><AboutPage /></LandingPage>},
    { path: `/${URL_PARAMS.CONTACT_US}`, element: <LandingPage><ContactPage /></LandingPage>},

    //Dashboard Auth
    { path:`/${URL_PARAMS.LOGIN}`, element: <PublicRoute><Suspense fallback={<LoadingFallbackComponent />}><LoginPage /></Suspense></PublicRoute>},
    { path:`/${URL_PARAMS.LOGIN}/:inviteId`, element: <Suspense fallback={<LoadingFallbackComponent />}><LinkLoginPage /></Suspense>},
    { path:`/${URL_PARAMS.FORGETPASSWORD}`, element: <PublicRoute><Suspense fallback={<LoadingFallbackComponent />}><ForgetpasswordPage /></Suspense></PublicRoute>},
    { path:`/${URL_PARAMS.SIGNUP}`, element: <Suspense fallback={<LoadingFallbackComponent />}><SignupPage /></Suspense>},
    { path:`/${URL_PARAMS.SIGNUP}/:inviteId`, element: <Suspense fallback={<LoadingFallbackComponent />}><LinkSignUpPage /></Suspense>},
    // { path:`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><BetaPasswordRoute><Suspense fallback={<LoadingFallbackComponent />}><BetaUpdatePassword /></Suspense></BetaPasswordRoute></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.VERIFY_EMAIL}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><VerifiedEmailRoute><VerifyEmailPage /></VerifiedEmailRoute></ScreenSizeWatcher>},
    { path:`/${URL_PARAMS.ONBOARDING}`, element: <OnboardingRoute><Suspense fallback={<LoadingFallbackComponent />}><OnboardingPage /></Suspense></OnboardingRoute>},
    { path:`/${URL_PARAMS.DASHBOARD}/*`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><DashboardRoute><Suspense fallback={<LoadingFallbackComponent />}><DashboardContainerPage/></Suspense></DashboardRoute></ScreenSizeWatcher>},
    { path:`/${URL_PARAMS.ERROR404}`, element: <Error404 />},
    { path: `/*`, element: <Navigate to='/'/>},

    // Self Registration Pages
    { path: `/${URL_PARAMS.REGISTER}/:orgId/:formId`, element: <Suspense fallback={<LoadingFallbackComponent />}><RegistrationForm /></Suspense>},

    // File Import Page
    // { path: `/${URL_PARAMS.IMPORT}`, element: <Suspense fallback={<LoadingFallbackComponent />}><ImportPage/></Suspense>},

    // //Dashboard Auth
    // { path:`/${URL_PARAMS.LOGIN}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PublicRoute><LoginPage /></PublicRoute></ScreenSizeWatcher> },
    // { path:`/${URL_PARAMS.FORGETPASSWORD}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><RestrictedRoute><ForgetpasswordPage /></RestrictedRoute></ScreenSizeWatcher>},
    // //{ path:`/${URL_PARAMS.SIGNUP}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><SignupPage /></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.BETA_CREATE_NEW_PASSWORD}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><BetaPasswordRoute><BetaUpdatePassword /></BetaPasswordRoute></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.VERIFY_EMAIL}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><><VerifiedEmailProcterRoute><VerifyEmailPage /></VerifiedEmailProcterRoute></></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.ONBOARDING}`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><BindOrgUncompletedUsersOnlyRoute><OnboardingPage /></BindOrgUncompletedUsersOnlyRoute></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.DASHBOARD}/*`, element: <ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PrivateRoute><DashboardContainerPage/></PrivateRoute></ScreenSizeWatcher>},
    // { path:`/${URL_PARAMS.ERROR404}`, element: <Error404 />},
];

export const DashboardRouters = {
    DASHBOARD : { path:`/`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><DashboardPage/></ScreenSizeWatcher>},
    ORGANISATION : { path:`/${URL_PARAMS.ORGANISATION}/:orgId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><OrganisationPage/></PermissionRoute></ScreenSizeWatcher>},
    ACCOUNT_SETTINGS: { path:`/${URL_PARAMS.ACCOUNT_SETTINGS}/*`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AccountSettingsPage /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNILIST: { path: `/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AlumniPage /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNI: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AlumniDetails /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNITASK: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId/task`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AlumniTask /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNINOTE: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId/note`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AlumniNotePage /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNIENGAGEMENT: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId/engagement`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><AlumniEngagementPage /></ScreenSizeWatcher>},
    ALUMNICONNECTION: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId/connection`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><AlumniConnectionPage /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNIIMPORT: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/${URL_PARAMS.IMPORT}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><ImportPage /></ScreenSizeWatcher>},
    ORGANISATION_SETTING: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ORGANISATION_SETTINGS}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><OrganisationSettings/></PermissionRoute></ScreenSizeWatcher>},
    SELF_REGISTRATION_FORMS: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.SELF_REGISTRATION_FORMS}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><SelfRegistrationFormsPage/></PermissionRoute></ScreenSizeWatcher>},
    FORM_PAGE: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.SELF_REGISTRATION_FORMS}/:formId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><FormPage/></PermissionRoute></ScreenSizeWatcher>},
    PLANNER: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.PLANNER}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><PlannerPage/></PermissionRoute></ScreenSizeWatcher>},
    PLANNER_ARGS: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.PLANNER}/:taskId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><PlannerPage/></PermissionRoute></ScreenSizeWatcher>},
    TEAM: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.TEAM}/`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><TeamPage/></PermissionRoute></ScreenSizeWatcher>},
    USERPERMISSIONS: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.TEAM}/:userId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><UserPermissionsPage/></PermissionRoute></ScreenSizeWatcher>},
    BILLING: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.BILLING}/`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><BillingPage/></PermissionRoute></ScreenSizeWatcher>},
    ENGAGEMENT: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ENGAGEMENT}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><EngagementPage/></PermissionRoute></ScreenSizeWatcher>},
    ENGAGEMENT_DETAIL: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ENGAGEMENT}/:engId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><EngagementDetailPage/></PermissionRoute></ScreenSizeWatcher>},
    NETWORK: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkPage/></PermissionRoute></ScreenSizeWatcher>},
    NETWORKDETAIL: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkDetails/></PermissionRoute></ScreenSizeWatcher>},
    NETWORKNOTE: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId/note`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkNotePage /></PermissionRoute></ScreenSizeWatcher>},
    NETWORKCONNECTION: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId/connection`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkConnectionPage /></PermissionRoute></ScreenSizeWatcher>},
    ALUMNIMEDIA: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.ALUMNI}/:alumniId/media`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><MediaPage /></PermissionRoute></ScreenSizeWatcher>},
    NETWORKMEDIA: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId/media`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkMediaPage /></PermissionRoute></ScreenSizeWatcher>},
    NETWORKTASK: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId/task`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><PermissionRoute fallback={<LoadingFallbackComponent />}><NetworkTask /></PermissionRoute></ScreenSizeWatcher>},
    NETWORKENGAGEMENT: { path:`/${URL_PARAMS.ORGANISATION}/:orgId/${URL_PARAMS.NETWORK}/:networkId/engagement`, element:<ScreenSizeWatcher fallback={<MobileFallbackComponent />}><NetworkEngagementPage /></ScreenSizeWatcher>},


};