import { CircularProgress, FormControl, Stack, TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import bgImage from "../../assets/images/registration/registrationBg.png";
import { PrimaryButton } from "../../components/Buttons/ButtonUI";
import { validateForm } from "../../firebase/registrationApis";
import pxToRem from "../../helpers/pxToRem";
import { RootState } from "../../redux";
import { setCurrentStep } from "../../redux/registrationSlice";
import ContactDetails from "./components/ContactDetails";
import FinishRegistration from "./components/FinishRegistration";
import FormPage from "./components/FormPage";
import Identity from "./components/Identity";
import PersonalDetails from "./components/PersonalDetails";
import PersonalInterests from "./components/PersonalInterests";
import SchoolingHistory from "./components/SchoolingHistory";
import Skills from "./components/Skills";
import TellUsMore from "./components/TellUsMore";
import TitlePage from "./components/TitlePage";
import { REG_PAGES } from "./constants";
import styles from "./index.module.scss";
import { DatePicker } from "@mui/x-date-pickers";

export type RegistrationSteps = { type: "FRONT_PAGE"; count: 1 } | { type: "PERSONAL_DETAILS"; count: 2 } | { type: "CONTACT_DETAILS"; count: 3 } | { type: "SCHOOLING_HISTORY"; count: 4 } | { type: "TELL_US_MORE"; count: 5 } | { type: "CREATE_SUCCESS"; count: 6 };

const RegistrationForm = (props: any) => {
    const dispatch = useDispatch();
    const { orgId, formId } = useParams();
    const step = useSelector((state:RootState) => state.alumniReg.step);
    const [termsChecked, setTermsChecked] = useState(false);

    const [isChecking, setIsChecking] = useState(true);
    const [formExists, setFormExists] = useState<boolean>();

    const checkData = async () => {
        setIsChecking(true);
        if(!orgId || !formId) {
            console.log("Invalid organisation or form!");
            setFormExists(false);
            return;
        }
        const res = await validateForm(orgId, formId);
        if(res.code === 200 ) {setFormExists(true);}
        else {setFormExists(false);}
        setIsChecking(false);
    }

    useEffect(()=> {
        checkData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, formId])

    const handleBackStep = (exactPage?:number) => {
        if(exactPage) {
            dispatch(setCurrentStep(exactPage));
            return;
        }
        if(step && step > 1) {
            dispatch(setCurrentStep(step-1));
        }
    }

    const handleCheckbox = () => {
        setTermsChecked(!termsChecked);
    }
    
    const render = () => { 
        switch(step) {
            case 1: return (<TitlePage isTermsChecked={termsChecked} handleCheckbox={handleCheckbox} />);
            case 2: return (<FormPage subtitle={"Enter your Personal Details"} handleBackStep={handleBackStep}><PersonalDetails/></FormPage>);
            case 3: return (<FormPage subtitle={"Enter your Contact Details"} handleBackStep={handleBackStep}><ContactDetails /></FormPage>);
            case 4: return (<FormPage subtitle={"Enter your Schooling History"} handleBackStep={handleBackStep}><SchoolingHistory /></FormPage>);
            case 5: return (<FormPage subtitle={"(Optional) Tell us more about you"} handleBackStep={handleBackStep}><TellUsMore /></FormPage>);
            case 6: return (<FormPage subtitle={"Identity"} handleBackStep={handleBackStep}><Identity /></FormPage>);
            case 7: return (<FormPage subtitle={"Tell us what you enjoyed most at school e.g. a subject or co-curricular activity."} handleBackStep={handleBackStep} backDestination={REG_PAGES.TELL_US_MORE}><PersonalInterests /></FormPage>);
            case 8: return (<FormPage subtitle={"Tell us about your skills e.g. excel, photoshop, leadership etc."} handleBackStep={handleBackStep} backDestination={REG_PAGES.TELL_US_MORE}><Skills /></FormPage>);
            case 9: return (<FinishRegistration/>);
        }
    }

    const errorPage = () => {
        return (
            <Stack alignItems="center" style={{
                backgroundImage: `url("${bgImage}")`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                height:'100vh', width:'100vw',
            }} justifyContent={'center'}
            >
                <Stack alignItems={"center"} justifyContent={"center"} className={styles.errorPage} gap={pxToRem(24)}>
                    <h5>Nothing to be found here!</h5>
                    <a href={
                        process.env.NODE_ENV === "development" ?
                            "http://localhost:3000"
                        :
                            "https://loopalumni.com"
                    }>
                        <PrimaryButton id="exitBtn" style={{padding:"6px 24px", height:'32px'}}>Go to Loop</PrimaryButton>
                    </a>
                </Stack>
            </Stack>
        )
    }

    const loadingPage = () => {
        return (
            <Stack alignItems="center" style={{
                backgroundImage: `url("${bgImage}")`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                height:'100vh', width:'100vw'
            }} justifyContent={'center'}
            >
                <CircularProgress color="primary"/>
            </Stack>
        )
    }

    return (
        <Stack sx={{
            overflowX:'hidden'
        }}>
            {
                isChecking ?
                    loadingPage()
                :
                    formExists ?
                        render()
                    :
                        errorPage()
            }
        </Stack>
    );
};

export const QRFormTextField = styled(TextField)({
    "& label.Mui-focused": {
        color:  styles.primary400,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `${pxToRem(2)} solid ${styles.primary300}`
        },
        "&::before": {
            borderBottom: `${pxToRem(1)} solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `${pxToRem(1)} solid ${styles.primary300}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primary300}`,
        },
    }
  });

export const QRFormsFormsControl = styled(FormControl)({
    "& label.Mui-focused": {
        color:  styles.primary400,
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primary300}`
        },
        "&::before": {
            borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primary300}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primary300}`,
        },
    }
})

export default RegistrationForm;