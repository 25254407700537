import {
  GENERAL_ERROR_PROMPT,
  PASSWORD_OR_EMAIL_INCORRECT_PROMPT,
} from "../../helpers/shared";

export const getLoginFailurePromptBasedOnErrorCode = (errorCode: string) => {
  if (
    errorCode === "auth/unverified-email" ||
    errorCode === "auth/invalid-email" ||
    errorCode === "auth/invalid-recipient-email" ||
    errorCode === "auth/user-not-found" ||
    errorCode === "auth/wrong-password"
  ) {
    return PASSWORD_OR_EMAIL_INCORRECT_PROMPT;
  } else {
    return GENERAL_ERROR_PROMPT;
  }
};
