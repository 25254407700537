import styled from "styled-components";
import globalStyles from "../../styles/_stylesParams.module.scss";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import pxToRem from "../../helpers/pxToRem";

const PrimaryOnboardingGreenButton = styled(LoadingButton)({
  borderRadius: "8px",
  backgroundColor: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  "&:hover": {
    backgroundColor: globalStyles.primary600,
    fontWeight: "unset",
  },
  "&:active": {
    backgroundColor: globalStyles.primary700,
  },
});

export const PrimaryOnboardingButton = (props: LoadingButtonProps) => (
  <PrimaryOnboardingGreenButton
    variant='contained'
    {...props}
    className={`${props.className} p`}
  />
);

const SecondaryOnboardingGreenButton = styled(LoadingButton)({
  borderRadius: "8px",
  borderColor: globalStyles.primary400,
  color: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  background: "#FFF",
  "&:hover": {
    border: `2px solid  ${globalStyles.primary600}`,
    color: globalStyles.primary600,
    background: "#FFF",
  },
  "&:active": {
    border: `2px solid  ${globalStyles.primary700}`,
    color: globalStyles.primary700,
    background: "#FFF",
  },
});

export const SecondaryOnboardingButton = (props: LoadingButtonProps) => (
  <SecondaryOnboardingGreenButton
    variant='outlined'
    {...props}
    className={`${props.className} p`}
  />
);
