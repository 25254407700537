import { doc, onSnapshot } from "firebase/firestore";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, db } from "../../firebase";
import { getOrganisation, getOrgGlobalState, getPermission } from "../../firebase/types-organisations";
import { RootState } from "../../redux";
import { setIsAdmin, setOrganisation, setQrFormsPending, setTeamNotifications, setUserPermissions, setUserState } from "../../redux/globalSlice";


interface IListenerComponent2 {
    children: React.ReactNode;
}

const ListenerComponent2 = ({children}: IListenerComponent2) => {
    // const location = useLocation();
    // const {orgId} = useParams();
    const orgId = useSelector((state:RootState) => state.organisation.orgId);
    const dispatch = useDispatch();

    // Global User Information
    useEffect(()=> {
        if (!auth.currentUser) return;
        const docRef = doc(db, "users", auth.currentUser?.uid);
        const unsub = onSnapshot(docRef, (docSnap) => {
            if(docSnap.exists()) {
                const data = docSnap.data();
                dispatch(setUserState({
                    userId: auth.currentUser?.uid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                }))
            }
        });
        return () => { unsub(); }
    }, [auth.currentUser])


    // Global Organisation Information
    useEffect(()=> {
        console.log("listener2 route 1.1", orgId);
        if(!orgId) return;
        console.log("listener2 route 1.2");
        const docRef = doc(db, "organisations", orgId);
        const unsub = onSnapshot(docRef, (docSnap) => {
            if(docSnap.exists()) {
                // console.log("listener2 route 1.3");
                const data = docSnap.data();
                const orgData = getOrganisation(data);
                const orgGlobalState = getOrgGlobalState(data);

                dispatch(setOrganisation(orgData))
                dispatch(setTeamNotifications(orgGlobalState.teamNotifications));
                // console.log("listener2 route 1.4");
                
            }
        })

        const formsRef = doc(db, "registrations", orgId)
        const formsUnsub = onSnapshot(formsRef, (docSnap) => {
            if(docSnap.exists()) {
                const data = docSnap.data();
                dispatch(setQrFormsPending(data.pendingApproval));
            } else {
                dispatch(setQrFormsPending(0));
            }
        })

        console.log("listener2 route 2");

        if(!auth.currentUser?.uid) return;

        console.log("listener2 route 2.1");

        // Fetch user permission
        const usersSubcol = doc(db, "organisations", orgId, "users", auth.currentUser.uid);
        const usersUnsub = onSnapshot(usersSubcol, (docSnap) => {
            if(docSnap.exists()) {
                const data = docSnap.data();
                const permissions = getPermission(data);
                dispatch(setUserPermissions(permissions));
                if(data.isAdmin !== undefined) {
                    dispatch(setIsAdmin(data.isAdmin))
                }
            }
        })
        
        return () => { unsub(); formsUnsub(); usersUnsub(); }
    }, [orgId, auth.currentUser])

    return (
        <>
            {children}
        </>
    )
}

export default memo(ListenerComponent2);