import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from "../../styles/_stylesParams.module.scss";
import { Moment } from 'moment';

interface CustomDatePickerProps extends Omit<DatePickerProps<Moment>, 'renderInput'> {
    label?: React.ReactNode;
    customHeight: number; // Changed to mandatory
    fontSize: number; // Changed to mandatory
    shrinkLabelWidth?: number;
    valueCheck?: any; // Consider refining this type
    pickerIconSize?: number;
    placeholder?: string;
    setCleared?: () => void;
    format?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string; // Helper text for displaying error messages
}

export default function CustomErrorDatePicker({
    label,
    onChange,
    setCleared,
    disabled,
    format,
    value,
    placeholder,
    views,
    pickerIconSize = 19,
    fontSize,
    valueCheck,
    customHeight,
    error,
    shrinkLabelWidth,
    helperText,
    ...other // Catch all other props to forward
}: CustomDatePickerProps) {
    const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1
    // const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: valueCheck !== undefined && valueCheck.trim().length === 0 || valueCheck !== undefined && valueCheck.trim() === "" || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
    const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: !valueCheck || valueCheck === null ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                views={views}
                onChange={onChange}
                value={value}
                format={format}
                disabled={disabled}
                sx={{
            
                    '& .MuiInput-underline:after': { borderBottomColor: globalStyles.primary400, },
                    '& .MuiOutlinedInput-root': { 
                        '& fieldset': { borderColor: error ? 'red' : globalStyles.primary400 }, 
                        '&:hover fieldset': { borderColor: globalStyles.primary400 }, 
                        '&.Mui-focused fieldset': { borderColor: globalStyles.primary400 } 
                    },
                }}
                slotProps={{
                    field: { clearable: true, onClear: setCleared},
                    textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        error: !!error,
                        helperText: error ? helperText : undefined,
                        placeholder: placeholder,
                        sx:{ '& .MuiSvgIcon-root': { height: pxToRem(pickerIconSize), width:pxToRem(pickerIconSize)}},
                        // Custom styles for the TextField
                        InputLabelProps: inputLabelProps, 
                        InputProps:{ style:{ height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize) } }, 
                    },
                }}
            />
        </LocalizationProvider>
    );
}
