import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { useMemo } from "react";
import styled from "styled-components";
import constants from "../../../helpers/constants";
import pxToRem from "../../../helpers/pxToRem";
import globalStyles from "../../../styles/_stylesParams.module.scss";

const InputTextFieldStyle = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary300,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary500,
    },
  },
});

export enum TextFieldInputMode {
  Search = 'search',
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Url = 'url',
  None = 'none',
  Numeric = 'numeric',
  Decimal = 'decimal',
}

interface IInputTextField extends StandardTextFieldProps {
  customHeight?: number,  // Always in px FIXME: Change it to mandatory
  fontSize?: number,  // Always in px FIXME: Change it to mandatory
  title?: string;
  titleColor?: string;
  label?: string;
  containerStyle?: React.CSSProperties;
  adornment?: JSX.Element;
  inputMode?: TextFieldInputMode;
  isEndAdornment?: boolean;
  valueCheck?: any;
  shrinkLabelWidth?: number;  // Controlling the width of the shrink Input label Text
}

const InputTextField = ({ defaultValue, title, titleColor, label, adornment, isEndAdornment, shrinkLabelWidth, containerStyle, inputMode, fontSize = 16, valueCheck, customHeight = 44, ...rest }: IInputTextField) => {
  const adornmentPosition = useMemo(() => { if (isEndAdornment) return "endAdornment"; return "startAdornment"; }, [isEndAdornment]);
  const inputProps = useMemo(() => { if (!adornment) return {}; return { [adornmentPosition]: ( <InputAdornment position={isEndAdornment ? "end" : "start"}> {adornment} </InputAdornment> ), }; }, [adornmentPosition, adornment]);
  const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset : 1
  const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: valueCheck !== undefined && valueCheck.trim().length === 0 || valueCheck !== undefined && valueCheck.trim() === "" || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
  return (
    <Stack style={containerStyle}>
        {title && <p className='smallMarginBottom' style={{color: titleColor ? titleColor : globalStyles['shades100']}}>{title}</p>}
        <InputTextFieldStyle autoComplete="off" sx={{'& fieldset legend': {  width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto' }}} label={label} inputMode={inputMode} InputLabelProps={inputLabelProps} InputProps={{...inputProps, style:{height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize)}}} {...rest} />
    </Stack>
  );
};

export default InputTextField;