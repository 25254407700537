import { Grid, Stack } from "@mui/material";
import dayjs from 'dayjs';
import { serverTimestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import MediaUploadPhotoModal from "../../../components/CustomizedModal/MediaUploadPhotoModal";
import UploadModal from "../../../components/CustomizedModal/UploadModal";
import AutoCloseSnackbar from "../../../components/SnackBarComponent/autoCloseSnackBar";
import { deleteAlumniPhotoUrl, updateAlumniPhoto } from "../../../firebase/alumniApis";
import { createPhoto, deletePhoto, fetchPhotosForAlumni, updatePhoto } from "../../../firebase/mediaApis";
import { fileRemoveFromStorage, uploadFileToStorageWithProgress } from "../../../firebase/storageApis";
import { PhotoData } from "../../../firebase/type-media";
import { PermissionState } from "../../../firebase/types-organisations";
import { getAlumniStoragePath } from "../../../firebase/types-sharedApis";
import pxToRem from "../../../helpers/pxToRem";
import { getError } from "../../../helpers/utils";
import { RootState } from "../../../redux";
import styles from "./index.module.scss";
import ProfilePhoto from "./PhotoCard";

interface IPhotoTabInterface {
    orgId: string | undefined | null;
    orgName: string | undefined | null;
    alumniId: string | undefined | null;
}

const storage = getStorage();


const PhotoTab = ({ orgId, alumniId }: IPhotoTabInterface) => {
    const alumniData = useSelector((state: RootState) =>
        state.alumniDataState.alumniList.find(alumni => alumni.objectID === alumniId)
    );
    const [isPhotoModal, setIsPhotoModal] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuShowId, setMenuShowId] = useState(-1);
    const [errorMsg, setErrorMsg] = useState("");
    const [openCopyBar, setOpenCopyBar] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [photoname, setPhotoname] = useState<string | undefined>();
    const [selectedPhoto, setSelectedPhoto] = useState<any>();
    const [errorStr, setErrorStr] = useState<string | undefined>();


    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null)
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [photoSubtitle, setPhotoSubTitle] = useState<string>();


    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState<PhotoData | null>({
        name: 'Profile Photo',
    });
    const [schoolPhoto, setSchoolPhoto] = useState<PhotoData | null>({
        name: 'School Photo',
    });
    const [recentPhoto, setRecentPhoto] = useState<PhotoData | null>({
        name: 'Recent Photo',
    });

    const [photoUploadState, setPhotoUploadState] = useState(false);

    const [isRemovePhoto, setIsRemovePhoto] = useState(false);
    const [isRemovingPhoto, setIsRemovingPhoto] = useState(false);

    const [photosList, setPhotosList] = useState<PhotoData[]>([]);

    useEffect(() => {
        if (alumniData?.photoUrl) {
            setProfilePhoto({
                id: alumniData?.objectID,
                name: 'Profile Photo',
                link: alumniData?.photoUrl,
                uploaded_at: alumniData?.updated_at,
            })
        } else {
            setProfilePhoto({ name: 'Profile Photo', link: '', uploaded_at: undefined });
        }

    }, [alumniData?.photoUrl])

    useEffect(() => {
        const fetchAndUpdatePhotos = async () => {
            if (orgId && alumniId) {
                try {
                    const fetchedPhotos = await fetchPhotosForAlumni(orgId, alumniId);
                    // Set photos if they exist in the fetched data; otherwise, reset to default
                    setRecentPhoto(fetchedPhotos.recent || { name: 'Recent Photo', link: '', uploaded_at: undefined });
                    setSchoolPhoto(fetchedPhotos.school || { name: 'School Photo', link: '', uploaded_at: undefined });
                } catch (error) {
                    console.error("Failed to load Photos:", error);
                    // Optionally set to default if there's an error
                    setRecentPhoto({ name: 'Recent Photo', link: '', uploaded_at: undefined });
                    setSchoolPhoto({ name: 'School Photo', link: '', uploaded_at: undefined });
                }
            }
        };

        fetchAndUpdatePhotos();
    }, [orgId, alumniId, triggerUpdate]);  // Dependencies that when changed, re-invoke this effect



    const resetForm = () => {
        setPhotoname(undefined);
        setPhotoSubTitle("")
        if (inputRef.current) { inputRef.current.value = ''; }
        setSelectedPhoto(null)
        setCurrentDeleteId(null);
        setErrorStr("");
        setMenuShowId(-1);
    }

    const formatDate = (timestamp: number) => {
        // Check if the timestamp is in seconds (usually a 10-digit number)
        const date = timestamp.toString().length === 10
            ? dayjs.unix(timestamp) // Convert from seconds to date
            : dayjs(timestamp); // Assume milliseconds if not in seconds

        const today = dayjs();

        return date.isSame(today, 'day')
            ? 'Today'
            : date.format('D MMM YY');
    };


    const addData = async (photo: File, name: string, orginalPhoto: File) => {
        if (photo && orgId && alumniId) {
            setPhotoUploadState(true);

            try {
                // Upload photo to Firebase Storage and get the download URL
                const photoRef = ref(storage, `mediaPhotos/${orgId}/${alumniId}/${name}`);
                const uploadResult = await uploadBytes(photoRef, photo);
                const downloadURL = await getDownloadURL(uploadResult.ref);



                const photoSizeInKB = (photo.size / 1024).toFixed(2);

                if (selectedPhoto) {
                    const photoData = {
                        name: photoname,
                        link: downloadURL,
                        size: `${photoSizeInKB} KB`,
                        uploaded_at: serverTimestamp(),
                    };
                    const res = await updatePhoto(orgId, alumniId, selectedPhoto.id, photoData,);
                    if (res.code === 200) {
                        setIsPhotoModal(false);
                        setTriggerUpdate(prev => !prev);
                    } else {
                        throw new Error('Failed to save photo metadata.');
                    }

                } else {
                    const originalPhotoRef = ref(storage, `mediaPhotos/${orgId}/${alumniId}/${name}_original`);
                    const originalUploadResult = await uploadBytes(originalPhotoRef, orginalPhoto);
                    const originalDownloadURL = await getDownloadURL(originalUploadResult.ref);

                    const photoData = {
                        name: photoname,
                        link: downloadURL,
                        originalUrl: originalDownloadURL,
                        size: `${photoSizeInKB} KB`,
                        uploaded_at: serverTimestamp(),
                    };
                    const res = await createPhoto(orgId, alumniId, photoData);
                    if (res.code === 200) {
                        setIsPhotoModal(false);
                        setTriggerUpdate(prev => !prev);
                    } else {
                        throw new Error('Failed to save photo metadata.');
                    }

                }

            } catch (error) {
                console.error("Errors happened when trying to upload and save photo.", error);
                setErrorMsg("Errors: photo upload and save.");
            }

            setPhotoUploadState(false);
            resetForm();
        };
    }

    const deleteData = async () => {
        if (!orgId || !alumniId || !currentDeleteId) { return; }
        setDeleteLoadingStatus(true);
        try {
            await deletePhoto(orgId, alumniId, currentDeleteId);
            setTriggerUpdate(prev => !prev); // Triggering update after successful deletion
        } catch (ex) {
            setErrorMsg("Errors: link delete, please try again");
            console.error(ex);
        } finally {
            setDeleteLoadingStatus(false);
            setIsDeleteModal(false);
            setIsPhotoModal(false);
            setCurrentDeleteId(null);
            setMenuShowId(-1);
            resetForm();
        }
    }


    const handleLinkClick = (url: string) => {
        // Preventing default behavior is not necessary unless inside a form or similar.
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank'; // Open in a new tab
        anchor.rel = 'noopener noreferrer'; // Security measure for opening new tabs

        // This part forces the browser to download the resource if it's downloadable
        // anchor.download = 'photoname.ext'; // Uncomment and edit if you know the photo name and extension

        // Append the anchor to the body, click it, and remove it
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    const uploadImageToAlumni = async (file: File, name: string) => {
        if (file && orgId && alumniId) {
            setPhotoUploadState(true);
            try {
                const storageRef = await uploadFileToStorageWithProgress({ path: getAlumniStoragePath(alumniId), file: file, onProgress: () => { } });
                if (storageRef.code == 200) {
                    await updateAlumniPhoto(orgId, alumniId, storageRef.data[0], storageRef.data[1]);
                    setIsUploadModalOpen(false);
                }
                else {
                    setErrorMsg(storageRef.errorMsg as string);
                }
            }
            catch (error) {
                setErrorMsg(getError(error));
            }
            setPhotoUploadState(false);
        }
    }

    const removeAlumniPicture = async () => {
        setIsRemovingPhoto(true);
        try {
            if (alumniId) {
                await fileRemoveFromStorage(getAlumniStoragePath(alumniId));
                await deleteAlumniPhotoUrl(orgId as string, alumniId);
                setIsUploadModalOpen(false);
            }
            else {
                setErrorMsg("User not found. Please try again.");
            }
        }
        catch (error) {
            setErrorMsg(getError(error));
        }
        setIsRemovingPhoto(false);
        setIsRemovePhoto(false);
    }

    return (
        <>

            {/* <MediaUploadPhotoModal fileDelete={() => setIsRemovePhoto(true)} title="Upload New Picture" subTitle="Adjust the position of your photo by dragging the image" uploadState={photoUploadState} fileUrl={photoUrl} open={isUploadModalOpen} close={() => { setIsUploadModalOpen(false); }} upload={uploadImageToStorage} /> */}
            <MediaUploadPhotoModal fileDelete={() => { setIsDeleteModal(true) }} uploadState={photoUploadState} fileUrl={selectedPhoto?.link as string} defaultFileName={photoname} open={isPhotoModal} close={() => { setIsPhotoModal(false); setSelectedPhoto(null) }} upload={addData} subTitle={photoSubtitle}/>

            <UploadModal fileDelete={() => setIsRemovePhoto(true)} uploadState={photoUploadState} fileUrl={profilePhoto?.link as string} open={isUploadModalOpen} close={() => { setIsUploadModalOpen(false) }} upload={uploadImageToAlumni} />
            <AlertDialogSimpleComponent secondaryButtonTitle="Cancel" secondaryButtonClose={() => setIsRemovePhoto(false)} primaryButtonState={isRemovingPhoto} visible={isRemovePhoto} message={'Are you sure you want to delete this profile picture?'} primaryButtonClose={removeAlumniPicture} primaryButtonTitle="Yes, I'm Sure" />
            <CustomizedModal open={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} type='ERROR' children={<p>{errorMsg}</p>} />
            <AutoCloseSnackbar
                open={openCopyBar}
                message="Copy to Clipboard"
                onClose={() => setOpenCopyBar(false)}
                duration={2000}  // Optional
            />
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} isLoading={deleteLoadingStatus} loadingStr={"Removing..."} width={600}>
                <Stack>
                    <Stack style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24) }} spacing={3} alignItems={'center'}>
                        <label className={styles.alertBodyText}>{`Are you sure you want to delete this ${photoname?.toLowerCase()}?`}</label>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(null); }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <PrimaryButton className={styles.button} onClick={deleteData}> Yes </PrimaryButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack marginTop={pxToRem(12)}>
                <Grid container spacing={pxToRem(24)} justifyContent="flex-start" wrap={'nowrap'}> {/* This sets the horizontal spacing and alignment */}
                    {profilePhoto &&
                        <Grid item key={profilePhoto.id} sx={{ cursor: "pointer" }}>
                            <ProfilePhoto
                                photo={profilePhoto} // Pass the entire photo object
                                onPicClick={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) setIsUploadModalOpen(true) }}
                                onDownload={() => handleLinkClick(profilePhoto.link as string)}
                                onDelete={() => {if (alumniPermission && alumniPermission === PermissionState.EDITOR) {setIsRemovePhoto(true); setIsUploadModalOpen(false) }}}
                                isPermit={!(alumniPermission && alumniPermission === PermissionState.EDITOR)}
                            />
                        </Grid>}
                    {recentPhoto &&
                        <Grid item key={recentPhoto.id} sx={{ cursor: "pointer" }}>
                            <ProfilePhoto
                                photo={recentPhoto} // Pass the entire photo object
                                onPicClick={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) setIsPhotoModal(true); setSelectedPhoto(recentPhoto.link ? recentPhoto : null); setPhotoname('Recent Photo'); setPhotoSubTitle(" Keep alumni profiles up to date with recent photos "); setCurrentDeleteId(recentPhoto.id as string) }}
                                onDownload={() => handleLinkClick(recentPhoto.originalUrl as string)}
                                onDelete={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) {setPhotoname('Recent Photo'); setIsDeleteModal(true); setCurrentDeleteId(recentPhoto.id as string) }}}
                                isPermit={!(alumniPermission && alumniPermission === PermissionState.EDITOR)}
                            />
                        </Grid>}
                    {schoolPhoto &&
                        <Grid item key={schoolPhoto.id} sx={{ cursor: "pointer" }}>
                            <ProfilePhoto
                                photo={schoolPhoto} // Pass the entire photo object
                                onPicClick={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) setIsPhotoModal(true); setSelectedPhoto(schoolPhoto.link ? schoolPhoto : null); setPhotoname('School Photo'); setPhotoSubTitle("Keep a snapshot of alumni from their school years"); setCurrentDeleteId(schoolPhoto.id as string) }}
                                onDownload={() => handleLinkClick(schoolPhoto.originalUrl as string)}
                                onDelete={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) {setPhotoname('School Photo'); setIsDeleteModal(true); setCurrentDeleteId(schoolPhoto.id as string) }}}
                                isPermit={!(alumniPermission && alumniPermission === PermissionState.EDITOR)}
                            />
                        </Grid>}
                </Grid>
            </Stack>

        </>
    );
}
export default memo(PhotoTab);