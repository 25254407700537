import React, { useState } from 'react';
import styles from './index.module.scss';
import CustomModal from '../../../SelfRegistrationFormsPage/components/modals/CustomModal';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import pxToRem from '../../../../helpers/pxToRem';
import LogoPath from "../../../../assets/images/logo/logo512.png"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { flattenBy } from '@tanstack/react-table';
import { getFunctions, httpsCallable } from 'firebase/functions';
import LoadingComponent from '../../../../components/LoadingStatus/Loading';
import CustomizedTooltip from '../../../../components/CustomizedToolTip';
import { PlanCard, plans } from '../../../BillingPage/components/managePlan/managePlanPopup';

interface updatePlanPopupProps {
    open: boolean;
    isLoading: boolean;
    title: string;
    description: string;
    buttonText: string;
    onClose: () => void; // Function to call when closing the popup
  }
  
const SuccessUpdatePlanPopup: React.FC<updatePlanPopupProps> = ({ title, description,  open, onClose, isLoading, buttonText }) => {
  
    return (
    <CustomModal open={open} handleClose={() => { }} width={200}>
            <div className={styles.popup}>
            <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            </IconButton>
            <div className={styles.popupTop}>
                <div className={styles.logo}>
                {/* <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="64" cy="64" r="64" fill="white" />
                </svg> */}
                <svg width={pxToRem(128)} height={pxToRem(128)} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                    <clipPath id="circleView">
                        <circle cx="64" cy="64" r="64" />
                    </clipPath>
                    </defs>
                    <circle cx="64" cy="64" r="64" fill="white" />
                    <image
                    clipPath="url(#circleView)"
                    href={LogoPath}
                    x='12' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                    y='12' // Same calculation for y
                    height='102.4'
                    width='102.4'
                    />
                </svg>
                </div>
            </div>
            {!isLoading ? (
                <div className={styles.popupBottom}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleText}>
                        {title}
                    </div>

                </div>
                <div className={`${styles.divider} horizontalDivider`}></div>
                <div className={styles.inlineText}>
                    {description}
                </div>
                <Button className={styles.selectPlanButton} onClick={onClose}>{buttonText}</Button>
                </div>
            ) : (
                <div className={styles.popupBottomLoading}>
                <LoadingComponent isLoading={isLoading}></LoadingComponent>
                </div>
            )

            }
            </div>

        </CustomModal>
    );
};

export default SuccessUpdatePlanPopup;