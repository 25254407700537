import { IconButton, Stack } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DocumentData, DocumentSnapshot, collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../../../firebase";
import { Alumni, getAlumni } from "../../../../firebase/types-alumni";
import pxToRem from "../../../../helpers/pxToRem";
import { getTimeDiffDes2DatesMillsNow } from "../../../../helpers/timeUtils";
import { sleep } from "../../../../helpers/utils";
import { URL_PARAMS } from "../../../../routers/shared";
import { AlumniDataItemInsideTable } from "../../../AlumniPage";
import { ApprovedData } from "../../helpers";
import styles from "./ApprovedTable.module.scss";
import { FORMS_PAGINATION_PERPAGEROWS } from "../../constants";

interface IApprovedTable {
    approvedCount?: number;
}

const ApprovedTable = ({approvedCount}: IApprovedTable) => {
    const {orgId, formId} = useParams();
    const [approvedAlumniDataRows, setApprovedAlumniDataRows] = useState<ApprovedData[]>([]);
    const [approvedAlumniDataRowsLoading, setApprovedAlumniDataRowsLoading] = useState(true);
    const columnHelper = createColumnHelper<ApprovedData>();
    const [totalRows, setTotalRows] = useState<number>();
    const [lastDoc, setLastDoc] = useState<DocumentSnapshot<DocumentData> | null>(null);
    const navigate = useNavigate();
    
    const columns = [ 
        columnHelper.accessor('name', { cell: info => info.getValue(), header: `Alumni` }),
        columnHelper.accessor('currentEmployment', { cell: info => info.getValue(), header: "Employment" }), 
        columnHelper.accessor('living', { cell: info => info.getValue(), header: "Living" }),
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "Peer Year" }), 
        columnHelper.accessor('approvedAt', { cell: info => info.getValue(), header: "Approved" }), 
    ];

    const table = useReactTable({ 
        data: approvedAlumniDataRows, 
        columns: columns, 
        getCoreRowModel: getCoreRowModel(), 
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(), 
        getSortedRowModel: getSortedRowModel(),
    });

    const CustomPaginationFooter = () => {
        const currentIndex = table.getState().pagination.pageIndex + 1;
        const pageSize = table.getState().pagination.pageSize;
        const currentRows = table.getRowModel().rows;
    
        const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
        const endItem = startItem + currentRows.length - 1;
    
        const goToLastPage = () => {
            const totalRows = approvedAlumniDataRows.length;
            const pageSize = FORMS_PAGINATION_PERPAGEROWS;
            const lastPageIndex = Math.max(0, Math.ceil(totalRows / pageSize));
            table.setPageIndex(lastPageIndex);
        };

        const checkFetchOrNot = () => {
            const localRowCount = approvedAlumniDataRows.length;
            const localMaxIndex = Math.ceil(localRowCount / pageSize)
            return (totalRows && localRowCount < totalRows && currentIndex >= localMaxIndex);
        }
        
        const nextPage = () => {
            if(checkFetchOrNot()){
                if(!approvedAlumniDataRowsLoading && lastDoc){
                    fetchData(lastDoc, async(code) => { 
                        await sleep(10);
                        if(code === 200){ goToLastPage(); }
                    });
                }
            }
            else{
                if(table.getCanNextPage()){
                    table.nextPage();
                }
            }
        }
        
        return(
            <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
                <Stack direction={"row"} style={{ padding: 4 , alignItems: 'center' }}>
                    <Stack className={styles.datagrid_footer_text} alignItems={"center"}>  { `${startItem} - ${endItem} of ${totalRows}` } </Stack>
                    <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> </IconButton>
                    <IconButton disabled={false} onClick={() => nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></IconButton>
                </Stack>
            </Stack>
        );
    }

    const fetchData = async (lastDocument:DocumentSnapshot<DocumentData> | null = null, callback:(code:number)=>void = () =>{}) => {
        if(orgId && formId){
            let qry;
            setApprovedAlumniDataRowsLoading(true);
            const collectionRef = collection(db, "organisations", orgId, "alumni");
            setTotalRows(approvedCount);

            if(!lastDocument){
                setApprovedAlumniDataRows([]);
                qry = query(collectionRef, orderBy("created_at", "desc"), where("throughFormId", "==", formId), limit(FORMS_PAGINATION_PERPAGEROWS)); //TODO
            }
            else{
                qry = query(collectionRef, orderBy("created_at", "desc"), where("throughFormId", "==", formId), startAfter(lastDocument), limit(FORMS_PAGINATION_PERPAGEROWS));
            }
            
            getDocs(qry).then((querySnapshot) => {
                querySnapshot.docs.map(async (doc) => {
                    const approvedId = doc.id;
                    const approvedAlumni: Alumni = getAlumni(doc.data());

                    const approvedTable: ApprovedData = {
                        id: approvedId, 
                        name: {
                            icon: approvedAlumni.photoUrl || "",
                            title: approvedAlumni.title || "",
                            firstName: approvedAlumni.firstName || "",
                            lastName: approvedAlumni.lastName || "",
                            email: approvedAlumni.emailPreferred || "",
                        },
                        living: approvedAlumni.living || "",
                        currentEmployment: approvedAlumni.currentEmployment && approvedAlumni.currentEmployment[0].jobTitle || "",
                        peerYear: approvedAlumni.peerYear ? approvedAlumni.peerYear.toString() : "-",
                        approvedAt: approvedAlumni.created_at,
                    };
                    //Add into table
                    setApprovedAlumniDataRows(prev => [...prev, approvedTable]);
                });                
                setApprovedAlumniDataRowsLoading(false);

                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                callback(200);
            })
            .catch((error) => {
                console.error("Error fetching documents: ", error);
                callback(400);
                setApprovedAlumniDataRowsLoading(false);
            });
        }
    }

    useEffect(()=>{
        fetchData();
    }, [orgId]);

    const rowClick = (alumniId: string) => {
        if(!orgId) return;
        window.open(`${window.location.origin}/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}/${alumniId}`, '_blank');
    }

    const currentLocation = useLocation();
    return (
        <>
            <Stack spacing={0} className={styles.tableWrapper}>
                <table className={styles.approvedTable}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }} >
                                        <Stack style={{ justifyContent:'space-between'}} direction={"row"}>
                                            <Stack direction={"row"} style={{alignItems:'center', flexGrow: 1, cursor:'pointer'}} {...{onClick: header.column.getToggleSortingHandler()}}>
                                                { header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() )}
                                            </Stack>
                                            {
                                                <Stack className={styles.sortButton} {...{onClick: header.column.getToggleSortingHandler()}}> 
                                                    <Stack className={`${ { desc:styles.sortButtonActive }[header.column.getIsSorted() as string] }`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round"/> </svg></Stack> 
                                                    <Stack className={`${ { asc:styles.sortButtonActive }[header.column.getIsSorted() as string] }`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round"/> </svg> </Stack> 
                                                </Stack>
                                            }
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            // status === "loading" || status === "stalled"?
                            //     <RowSkeleton />
                            // :
                                table.getRowModel().rows.map(row => (
                                    <tr key={row.id} onClick={() => { rowClick(row.original.id) }}>
                                            {
                                                row.getVisibleCells().map(cell => {
                                                    let cellJSX: JSX.Element | undefined = undefined;
                                                    if(cell.column.id === "name"){
                                                        cellJSX = <Stack key={cell.id} className={styles.alumni}>{ AlumniDataItemInsideTable(row.original.name.title ?? "", row.original.name.firstName ?? "", row.original.name.lastName ?? "", row.original.name.email ?? "", row.original.name.icon ?? "")}</Stack>;
                                                    }
                                                    else if(cell.column.id === "currentEmployment"){
                                                        cellJSX = <Stack key={cell.id} className={styles.text}>{ row.original.currentEmployment || "-" }</Stack>;
                                                    }
                                                    else if(cell.column.id === "peerYear"){
                                                        cellJSX = <Stack key={cell.id} className={styles.text}>{ row.original.peerYear ||  "-"}</Stack>;
                                                    }
                                                    else if(cell.column.id === "living"){
                                                        cellJSX = <Stack key={cell.id} className={styles.text}>{ row.original.living || "-" }</Stack>;
                                                    }
                                                    else if(cell.column.id === "approvedAt"){
                                                        cellJSX = <Stack key={cell.id} className={styles.text}>{ (row.original.approvedAt && typeof row.original.approvedAt === "number") ? getTimeDiffDes2DatesMillsNow(row.original.approvedAt) : "-" }</Stack>;
                                                    }
                                                    return (
                                                        <td key={cell.id} style={{padding:0, flex:1, height:pxToRem(52), justifyContent:'center', alignItems:'center' }}>
                                                          {/* <Link to={`${currentLocation.pathname}/${row.original.id}`} className={styles['row-link']}> */}
                                                            {cellJSX}
                                                          {/* </Link> */}
                                                        </td>
                                                    );
                                                })
                                            }
                                        {/* </Link> */}
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </Stack>
            { approvedAlumniDataRows.length <= 0 ? null : CustomPaginationFooter() }
        </>
    )
}

export default ApprovedTable;