import { Box, Breadcrumbs, Divider, Snackbar, Stack, Tab } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BetaButton, PrimaryButton } from "../../components/Buttons/ButtonUI";
import CustomizedPaper from "../../components/CustomizedPaper";
import { db } from "../../firebase";
import { checkPendingCount } from "../../firebase/registrationApis";
import { getOrganisation } from "../../firebase/types-organisations";
import pxToRem from "../../helpers/pxToRem";
import { RootState } from "../../redux";
import { setCurrentOrgDetail } from "../../redux/organisationSlice";
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import ActiveForms from "./components/ActiveForms";
import ClosedForms from "./components/ClosedForms";
import FormModal from "./components/modals/FormModal";
import OperationModals, { Operation } from "./components/modals/OperationModals";
import { FORMS_TABS, SUBTITLE } from "./constants";
import styles from "./index.module.scss";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { betaStateAction } from "../../redux/betaSlice";

const SelfRegistrationFormsPage = (props: any) => {
    const params = useParams();
    const dispatch = useDispatch();
	const navigate = useNavigate();

    const organisation = useSelector( (state: RootState) => state.organisation );
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const [ orgId, setOrgId ] = useState<string|null|undefined>(organisation.organisation?.id);
    const [ orgName, setOrgName ] = useState<string|null|undefined>(organisation.organisation?.name);

    const [ selectedTab, setSelectedTab ] = useState(FORMS_TABS.ACTIVE);

    // Form add & delete modal
    const [openDeleteForm, setOpenDeleteForm] = useState(false);
    const [openDeleteActionForm, setOpenDeleteActionForm] = useState(false);

    const [totalSubmissions, setTotalSubmissions] = useState<number>();

    const [selectedFormId, setSelectedFormId] = useState<string>();
    const [selectedFormName, setSelectedFormName] = useState<string>();

    const [linkCopied, setLinkCopied] = useState(false);
    const [qrDownloaded, setQrDownloaded] = useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    const handleDeleteModalOpen = async (id: string, name: string) => {
        setSelectedFormId(id);
        setSelectedFormName(name);
        const orgIdTemp = orgId || params.orgId;
        if(!orgIdTemp) {
            console.log("orgId is not defined");
            return;
        }
        const res = await checkPendingCount(orgIdTemp, id);
        if(res.code === 200) {
            setTotalSubmissions(res.data);
            if(res.data <= 0) {
                setOpenDeleteForm(true);
            }
            else {
                setOpenDeleteActionForm(true);
            }
        }
    }

    const handleLinkCopied = () => {
        setLinkCopied(true);
    }

    const handleQrDownloaded = () => {
        setQrDownloaded(true);
    }

    // useEffect(() => {
    //     const orgId = params.orgId;
    //     if(!orgId){ return;};
    //     const docRef = doc(db, "organisations", orgId);
    //     const unsubscribeOrganisation = onSnapshot(docRef, (docSnap) => {
    //         if(docSnap.exists()){
    //             const organisation = getOrganisation(docSnap.data());
    //             setOrgId(orgId);
    //             setOrgName(organisation.name ?? "");
    //             dispatch(setCurrentOrgDetail({orgId: orgId, organisation: organisation})); //Set Organisation
    //         }
    //     })
    //     return () => { unsubscribeOrganisation(); }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [params.orgId])

    return (
        <>
            <OperationModals
                operation={Operation.DELETE}
                totalSubmissions={totalSubmissions || 0}
                openActionModal={openDeleteActionForm}
                closeActionModal={()=>setOpenDeleteActionForm(false)}
                openNoActionModal={openDeleteForm}
                closeNoActionModal={()=>setOpenDeleteForm(false)}
                orgId={orgId || ""}
                formId={selectedFormId}
                formName={selectedFormName}
            />
            <Stack style={{marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent:'space-between'}} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Link key={1} to={`/dashboard`}>Dashboard</Link>
                    <Link key={2} to={`/dashboard/organisation/${orgId}`}>{orgName}</Link>
                    <Link key={3} to="#">QR Forms</Link>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
            </Stack>
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                <Stack className={`${styles.content} ${styles.selfRegistrationForms}`}>
                    <Stack direction={"row"} style={{ justifyContent:'space-between' }}>
                        <Stack>
                            <h6 className="regular">Alumni Registration Forms</h6>
                            <small className={styles['text--subtitle']}>{SUBTITLE}</small>
                        </Stack>
                    </Stack>
                    <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                    <Stack>
                        <TabContext value={selectedTab}>
                            <Stack direction="row" justifyContent={"space-between"}>
                                <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} aria-label="basic tabs"
                                        TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: 'none',
                                                height: 0 
                                            }
                                        }}
                                        sx={{
                                            '& button': {
                                                color: styles.neutrals500,
                                                border: '1px solid #F5F5F5',
                                                borderRadius:2,
                                                margin: '6px'
                                            },
                                            '& button:hover': { backgroundColor:styles.neutral100},
                                            '& button:focus': { backgroundColor:styles.neutral100},
                                            '& button:active': { backgroundColor: styles.neutrals300},
                                            '& button.Mui-selected': { 
                                                color: styles.shades100,
                                                backgroundColor: styles.neutrals100,
                                                margin: '6px'
                                            },
                                        }}
                                        >
                                        <Tab label="Active Forms" {...a11yProps(FORMS_TABS.ACTIVE)} 
                                            sx={{
                                                '&.Mui-selected': {
                                                    margin: "1px"
                                                }
                                            }}
                                            value={FORMS_TABS.ACTIVE}
                                            />
                                        <Tab label="Archived" {...a11yProps(FORMS_TABS.ARCHIVED)} 
                                            sx={{
                                                '&.Mui-selected': {
                                                    margin: "1px"
                                                }
                                            }}
                                            value={FORMS_TABS.ARCHIVED}
                                        />
                                    </TabList>
                                </Box>
                            </Stack>
                            <TabPanel value={selectedTab}
                                sx={{
                                    "&.MuiTabPanel-root": {
                                        padding: 0,
                                        paddingTop: pxToRem(12)
                                    }
                                }}>
                                <div style={{display: selectedTab === FORMS_TABS.ACTIVE ? 'block' : 'none'}}>
                                    <ActiveForms handleDeleteForm={handleDeleteModalOpen} linkCopied={handleLinkCopied} qrDownloaded={handleQrDownloaded}/>
                                </div>
                                <div style={{display: selectedTab === FORMS_TABS.ARCHIVED ? 'block' : 'none'}}>
                                    <ClosedForms handleDeleteForm={handleDeleteModalOpen}/> 
                                </div>
                            </TabPanel>
                        </TabContext>
                    </Stack>
                    
                </Stack>
            </CustomizedPaper>
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={linkCopied}
                onClose={()=>setLinkCopied(false)}
                autoHideDuration={1000}
                message={`Copied to Clipboard`}
            />
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={qrDownloaded}
                onClose={()=>setQrDownloaded(false)}
                autoHideDuration={1000}
                message={`QR Code Downloaded`}
            />
        </>
    );
}

function a11yProps(index: string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default SelfRegistrationFormsPage;