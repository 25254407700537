import moment from "moment";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Firestore } from "firebase/firestore";

export const getTimeDiffDes = (preTime: number) => {
  const nowUnix = moment().unix();
  const differenceInSeconds = nowUnix - preTime;
  const positiveDifferenceInSeconds = Math.abs(differenceInSeconds);
  return `${moment .duration(positiveDifferenceInSeconds, "seconds") .humanize()} ago`;
};
export const getTimeDiffDes2Dates = (startDate: Date, endDate: Date) => {
  const duration = moment.duration(moment(endDate).diff(moment(startDate)));
  return duration.humanize() + ' ago';
};
export const getTimeDiffDes2DatesMills = (startDate: number, endDate: number) => {
    const duration = moment.duration(moment(endDate).diff(moment(startDate)));
    return duration.humanize() + ' ago';
};
export const getTimeDiffDes2DatesMillsNow = (date: number) => {
    if(date){
        const duration = moment.duration(moment(date).diff(moment()));
        return duration.humanize() + ' ago';
    }
    else{
        return "";
    }
};
export const getDateTimeLocalFromMills = (mills: number) => {
    return moment(mills).format("DD/MM/YYYY");
}