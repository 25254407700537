import { URL_PARAMS } from "../../routers/shared";

export const getDashboardTitle = (url: string): string => {
  switch (true) {
    case url.includes(URL_PARAMS.ACCOUNT_SETTINGS):
      return "Account Settings";
    case url.includes(URL_PARAMS.TEAM):
      return ""; // Adjust this based on your actual requirement
    default:
      return "Home";
  }
};