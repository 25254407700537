import { Button, Stack } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { PrimaryButton } from "../Buttons/ButtonUI";
import CustomizedPaper from "../CustomizedPaper";
import OnboardingField from "../Inputs/TextFields/OnboardingField";
import styles from "./index.module.scss";

const elementScrollIntoView = (elementId: string) => {
  const element = document.querySelector(`#${elementId}`);
  if (element) {
    element.scrollIntoView();
  }
};

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
      return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  script.async = true;  // Asynchronously load the script
  script.defer = true;  // Defer execution until the HTML document is parsed
  position.appendChild(script);
}

interface IAutoCompleteCustomized {
  updateSelectedPlace: (place: any) => void;
  handleCreateNew: () => void;
}

const AutoCompleteCustomized = ({
  updateSelectedPlace,
  handleCreateNew,
}: IAutoCompleteCustomized) => {
  const [possiblePlaces, setPossiblePlaces] = useState<any[]>([]);
  const [searchPlaceInput, setSearchPlaceInput] = useState("");
  const [resultPlacesPanelVis, setResultPlacesPanelVis] = useState(false);
  const loaded = React.useRef(false);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

    // Retrieve user's current location
    useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            console.error('Geolocation error:', error);
          }
        );
      }
    }, []);

    if (typeof window !== 'undefined' && !loaded.current) {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;  // Make sure the environment variable is correctly named and set
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
          document.querySelector('head'),
          'google-maps'
        );
      }
      loaded.current = true;
    }
  
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY,
      options: {
        input: "",
        language: "en-AU",
        //fields: ["address_components", "geometry", "icon", "name"],
        types: ["school", "secondary_school", "university", "primary_school"],
      },
    });
  const [onFocusedPossiblePlace, setOnFocusedPossiblePlace] = useState<
    null | number
  >();

  const handlePlaceSelected = (placeIndex: number) => {
    const targetPossiblePlace = possiblePlaces[placeIndex];
    if (!targetPossiblePlace) return;
    placesService?.getDetails(
      {
        placeId: targetPossiblePlace.place_id,
      },
      (placeDetails: any) => {
        updateSelectedPlace(placeDetails);
      }
    );
  };

  useEffect(() => {
    // OLD CODE
    // fetch place details for the first element in placePredictions array
    // if (placePredictions.length) {
    // setPossiblePlaces(placePredictions);
    // setOnFocusedPossiblePlace(undefined);
    // }

    // NEW CODE
    // Ensure placePredictions is not empty before updating state // ERROR Need to test this par
    if (placePredictions.length > 0) {
      setPossiblePlaces(placePredictions);
      setOnFocusedPossiblePlace(undefined);
    }
  }, [placePredictions]);

  useEffect(() => {
    if (onFocusedPossiblePlace !== null) {
      elementScrollIntoView(`possiblePlace-${onFocusedPossiblePlace}`);
    }
  }, [onFocusedPossiblePlace]);

  const handleSearchChange = (evt: { target: { value: any; }; }) => {
    const inputValue = evt.target.value;
    setSearchPlaceInput(inputValue);
    if (!inputValue) {
      setPossiblePlaces([]);
      return;
    }

    // Invoke getPlacePredictions with location bias when available
    if (userLocation) {
      getPlacePredictions({
        input: inputValue,
        locationBias: { center: new window.google.maps.LatLng({
          lat: userLocation.lat, 
          lng: userLocation.lng
        }), radius: 10000 } 
      });
    } else {
      getPlacePredictions({ input: inputValue });
    }
  };

  return (
    <Stack>
      <OnboardingField
        className={styles.input}
        onChange={(evt) => {
          if (!resultPlacesPanelVis) {
            setResultPlacesPanelVis(true);
          }
          setTimeout(() => {
            setSearchPlaceInput(evt.target.value);
            handleSearchChange(evt)
          }, 500);
          if (evt.target.value.length === 0) {
            setPossiblePlaces([]);
          }
        }}
        adornment={
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            className={styles.searchIcon}
          >
            <path
              d='M21 21L15.0001 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z'
              stroke='#1F1F1F'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        isEndAdornment={false}
        onFocus={() => {
          setResultPlacesPanelVis(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setOnFocusedPossiblePlace(undefined);
            setPossiblePlaces([]);
            setResultPlacesPanelVis(false);
          } else if (e.key === "ArrowDown") {
            setOnFocusedPossiblePlace((preState) => {
              if (preState == null) {
                return 0;
              }
              if (preState < possiblePlaces.length - 1) {
                return preState + 1;
              }
              return preState;
            });
          } else if (e.key === "ArrowUp") {
            setOnFocusedPossiblePlace((preState) => {
              if (preState == null) return 0;
              if (preState === 0) {
                return 0;
              }
              return preState - 1;
            });
          } else if (e.key === "Enter") {
            if (onFocusedPossiblePlace == null) return;
            handlePlaceSelected(onFocusedPossiblePlace);
          }
        }}
      />
      <div
        style={{
          visibility: resultPlacesPanelVis ? "visible" : "hidden",
          position: "relative",
          zIndex:1000,
        }}
      >
        <CustomizedPaper cancelHoriontalPadding className={styles.paper}>
          <>
            <Stack
              className={`${styles.possiblePlacesStack} ${
                possiblePlaces.length === 0 ? "" : "mediumMarginBottom"
              }`}
            >
              {possiblePlaces.map((possiblePlaces, index) => (
                <Stack
                  key={`possiblePlace-${index}`}
                  id={`possiblePlace-${index}`}
                  className={`${styles.possiblePlace} ${
                    onFocusedPossiblePlace === index
                      ? styles["possiblePlace--Focused"]
                      : ""
                  }`}
                  onClick={() => {
                    setOnFocusedPossiblePlace(index);
                    handlePlaceSelected(index);
                  }}
                >
                  <small style={{ textAlign: "left" }}>
                    {possiblePlaces?.structured_formatting?.main_text ?? ""}
                  </small>
                  <Stack className={styles.placeSecDes}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
                        stroke='#1F1F1F'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z'
                        stroke='#1F1F1F'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <div className='pxs'>
                      {possiblePlaces?.structured_formatting?.secondary_text ??
                        ""}
                    </div>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack className={styles.createNewStack}>
              <div className='pxs'>
                {searchPlaceInput.length !== 0 && possiblePlaces.length === 0
                  ? "No organisation found, try again or create a new organisation "
                  : "Don’t see anything you like? "}
              </div>
              <PrimaryButton
                className={styles.createOrgButton}
                onClick={handleCreateNew}
              >
                <small>Create New</small>
              </PrimaryButton>
            </Stack>
          </>
        </CustomizedPaper>
      </div>
      <Button className={styles.createNewOrgTextButton} onClick={handleCreateNew} >
        <small>Create New Organisation</small>
      </Button>
    </Stack>
  );
};

export default AutoCompleteCustomized;
