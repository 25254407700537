import { Button, Checkbox, IconButton, LinearProgress, linearProgressClasses, Stack, styled } from "@mui/material";
import { useState } from "react";
import styles from "./components.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { removeChecklist } from "../../../firebase/organisationApis";
import { Link } from "react-router-dom";
import { URL_PARAMS } from "../../../routers/shared";

interface IOrgSetupChecklist {

}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 32,
    borderRadius: 16,
    boxShadow: `0px 2px 2px 0px #00000040`,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: styles.neutrals100,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 16,
      backgroundColor: styles.secondaryBlue700,
    },
  }));

const OrgSetupChecklist = () => {
    const org = useSelector((state: RootState) => state.organisation.organisation);
    const orgId = useSelector((state: RootState) => state.organisation.orgId);
    const userPermissions = useSelector((state: RootState) => state.globalState.permissions)

    const value = () => {
        let count = 0;
        if(org?.checklist?.createOrg) count = count+1/3;
        if(org?.checklist?.uploadLogo) count = count+1/3;
        if(org?.checklist?.inviteTeam) count = count+1/3;
        return count;
    };

    const Item = ({text, checked, link}: any) => {
        return (
            <Stack>
                <Checkbox 
                    disabled
                    checked={checked || false}
                    icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="11.5" stroke="#737373"/> </svg> }
                    checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="12" fill="#608BDE"/> <path d="M17.3346 8L10.0013 15.3333L6.66797 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                />
                {
                    link ?
                    <p style={{color: checked ? styles.secondaryBlue500 : styles.shades100}}><Link to={link} style={{textDecoration:'none', color:'inherit'}}>{text}</Link></p>
                    :
                    <p style={{color: checked ? styles.secondaryBlue500 : styles.shades100}}>{text}</p>
                }
            </Stack>
        )
    }

    const handleRemoveChecklist = async () => {
        if(!orgId) return;
        await removeChecklist(orgId);
    }

    return (
        <>
            {
                org?.checklist !== undefined &&
                    <Stack className={styles.checklist}>
                        <p>Finish setting up your organisation</p>
                        <Stack className={styles.listItems}>
                            <Item text="Create Organisation" checked={org.checklist.createOrg} />
                            <Item text="Upload your organisation’s logo" checked={org.checklist.uploadLogo} link={userPermissions?.isAdmin ? `/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ORGANISATION_SETTINGS}`:''}/>
                            <Item text="Invite your team to collaborate" checked={org.checklist.inviteTeam} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.TEAM}`}/>
                        </Stack>
                        <div className={styles.checklistProgress}>
                            <BorderLinearProgress variant="determinate" value={value() * 100} style={{flexGrow: 1}}/>
                            <IconButton onClick={handleRemoveChecklist}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M22 10L10 22M10 10L22 22" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M22 10L10 22M10 10L22 22" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M22 10L10 22M10 10L22 22" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                            </IconButton>
                        </div>
                    </Stack>
            }
        </>
    );
}

export default OrgSetupChecklist;