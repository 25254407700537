class UserActivityUtil {
    static plannerKey: string = 'lastPlannerActivity';
    static alumniKey: string = 'lastAlumniActivity'; // Corrected case to follow TypeScript naming conventions
  
    // Update the timestamp of the user's last activity based on a specific key
    static updateUserActivity(key: 'planner' | 'alumni' | 'network'): void {
        const now = new Date().toISOString();
        const activityKey = key === 'planner' ? this.plannerKey : this.alumniKey;
        localStorage.setItem(activityKey, now);
    }
  
    // Check if the user session is still valid based on inactivity timeout and a specific key
    static checkSessionValidity(key: 'planner' | 'alumni', inactivityTimeoutMinutes: number = 10): boolean {
        const activityKey = key === 'planner' ? this.plannerKey : this.alumniKey;
        const lastActivity = localStorage.getItem(activityKey);
        if (!lastActivity) {
            // If no activity has ever been recorded, consider the session invalid
            return false;
        }
  
        const now = new Date();
        const lastActivityDate = new Date(lastActivity);
        const diff = now.getTime() - lastActivityDate.getTime(); // difference in milliseconds
  
        // Check if the difference exceeds the inactivity timeout
        return diff <= inactivityTimeoutMinutes * 60 * 1000;
    }
  
    // Log out the user and clear the activity tracking based on a specific key
    static forceLogout(key: 'planner' | 'alumni'): void {
        const activityKey = key === 'planner' ? this.plannerKey : this.alumniKey;
        // Implement the log out functionality here
  
        // Clear the last activity from storage
        localStorage.removeItem(activityKey);
  
        // Optionally, redirect to login page or show a login dialog
    }

    static clearUserActivityFromStorage(userId: string | null): void {
        if (userId){
            const localStorageKey = `lastActivityUpdate-${userId}`;
            const alertStorageKey = 'alertPreference'
        
            // Remove specific user activity key
            localStorage.removeItem(localStorageKey);
            localStorage.removeItem(alertStorageKey);
        
            console.log('User activity data cleared from local storage.');
        } 
    }
    
}
  
export default UserActivityUtil;
