import { Button } from "@mui/material";
import styles from "../../index.module.scss";
import { useState } from "react";
import FormModal from "./FormModal";

interface IEditFormModal {
    formId: string;
    orgName: string;
}

const EditFormModal = ({formId, orgName}: IEditFormModal) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <>
            <Button sx={{
                color: styles.neutrals0
            }} onClick={openModal}>
                Edit Form
            </Button>
            <FormModal
                isOpen={isOpen}
                formId={formId}
                handleClose={closeModal}
                orgName={orgName}
            />
        </>
    )
}

export default EditFormModal;