import React from 'react';
import { Stack, keyframes, styled } from '@mui/material';
import pxToRem from '../../helpers/pxToRem';

interface LoadingComponentProps {
  isLoading: boolean;
  loadingStr?: string;
  loadingColor?: string;
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjusted for better visibility
  borderRadius: '8px',

}));

const SvgContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: pxToRem(64),
  height: pxToRem(64),
  animation: `${rotate} 1.5s linear infinite`,
  // Ensure the rotation is around the center
  transformOrigin: 'center',
});

const LoadingComponent: React.FC<LoadingComponentProps> = ({ isLoading, loadingStr, loadingColor }) => {
  if (!isLoading) return null;

  return (
    <LoadingOverlay>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <SvgContainer>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke={loadingColor ? loadingColor : "#549189"}  strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138"></circle>
          </svg>
        </SvgContainer>
        {loadingStr && <div style={{textAlign: 'center'}} dangerouslySetInnerHTML={{ __html: loadingStr }}/>}
      </Stack>
    </LoadingOverlay>
  );
};

export default LoadingComponent;
