import { Divider, IconButton, Modal, Snackbar, Stack } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import { SecondaryButton } from "../../../../components/Buttons/ButtonUI";
import InputTextField from "../../../../components/Inputs/TextFields/InputTextField";
import constants from "../../../../helpers/constants";
import pxToRem from "../../../../helpers/pxToRem";
import { handleQrCodeClick } from "../../helpers";
import styles from "../../index.module.scss";

interface IQRCodeModal {
    isOpen: boolean;
    handleClose: ()=>void;
    title: string;
    link: string;
}

const QRCodeModal = ({isOpen, handleClose, title, link}: IQRCodeModal) => {

    const [linkCopied, setLinkCopied] = useState(false);
    const [qrDownloaded, setQrDownloaded] = useState(false);

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={linkCopied}
                onClose={()=>setLinkCopied(false)}
                autoHideDuration={1000}
                message={`Copied to Clipboard`}
            />
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={qrDownloaded}
                onClose={()=>setQrDownloaded(false)}
                autoHideDuration={1000}
                message={`QR Code Downloaded`}
            />
            <Modal
                open={isOpen}
                onClose={()=>{}}
            >
                <Stack className={styles.qrModalWrapper}>
                    <h5 className={styles.qrModalTitle}>Share</h5>
                    <small className={styles['regular-400-14']}>Copy the link or download the QR code</small>
                    <Divider style={{marginTop:pxToRem(24), marginBottom:pxToRem(40)}} />
                    <Stack direction={"row"} gap={pxToRem(24)}>
                        <Stack className={styles.qrCodeWrapper} id={"qrCodeContainer"}>
                            <QRCodeCanvas id={link} size={125} value={link}/>
                        </Stack>
                        <Stack alignItems={"flex-start"} justifyContent={"space-between"}>
                            <p style={{marginLeft:'8px', color: styles.shades100}}>{title}</p>
                            <Stack>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <IconButton id={"downloadQrBtn"} onClick={(e)=> {
                                        handleQrCodeClick(e, link, title);
                                        setQrDownloaded(true);
                                    }}>
                                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 19V20.2C25 21.8802 25 22.7202 24.673 23.362C24.3854 23.9265 23.9265 24.3854 23.362 24.673C22.7202 25 21.8802 25 20.2 25H11.8C10.1198 25 9.27976 25 8.63803 24.673C8.07354 24.3854 7.6146 23.9265 7.32698 23.362C7 22.7202 7 21.8802 7 20.2V19M21 14L16 19M16 19L11 14M16 19V7" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                    </IconButton>
                                    <small className={styles['regular-400-14']} style={{lineHeight:'20px'}}>Download PNG</small>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <IconButton id={"copyLinkBtn"} onClick={()=>{
                                        navigator.clipboard.writeText(link);
                                        setLinkCopied(true);
                                    }}>
                                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 12V9.2C20 8.0799 20 7.51984 19.782 7.09202C19.5903 6.71569 19.2843 6.40973 18.908 6.21799C18.4802 6 17.9201 6 16.8 6H9.2C8.0799 6 7.51984 6 7.09202 6.21799C6.71569 6.40973 6.40973 6.71569 6.21799 7.09202C6 7.51984 6 8.0799 6 9.2V16.8C6 17.9201 6 18.4802 6.21799 18.908C6.40973 19.2843 6.71569 19.5903 7.09202 19.782C7.51984 20 8.0799 20 9.2 20H12M15.2 26H22.8C23.9201 26 24.4802 26 24.908 25.782C25.2843 25.5903 25.5903 25.2843 25.782 24.908C26 24.4802 26 23.9201 26 22.8V15.2C26 14.0799 26 13.5198 25.782 13.092C25.5903 12.7157 25.2843 12.4097 24.908 12.218C24.4802 12 23.9201 12 22.8 12H15.2C14.0799 12 13.5198 12 13.092 12.218C12.7157 12.4097 12.4097 12.7157 12.218 13.092C12 13.5198 12 14.0799 12 15.2V22.8C12 23.9201 12 24.4802 12.218 24.908C12.4097 25.2843 12.7157 25.5903 13.092 25.782C13.5198 26 14.0799 26 15.2 26Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                    </IconButton>
                                    <InputTextField value={link} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} disabled/>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Divider style={{marginTop:pxToRem(40), marginBottom:pxToRem(24)}} />
                    <Stack alignItems={"flex-end"} justifyContent={"center"}>
                        <SecondaryButton className={styles.closeQrModalButton} onClick={handleClose}>Close</SecondaryButton>
                    </Stack>
                </Stack>
            </Modal>
        </>
    );
}

export default QRCodeModal;