import { Fragment, memo, useEffect, useMemo, useState } from "react";
import useTextInput from "../../../hooks/useTextInput";
import { Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import CustomizedModal from "../../../components/CustomizedModal";
import styles from "./../index.module.scss";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import Line from "../../../components/Line/Line";
import { CustomizedDropdown } from '../../../components/Inputs/Dropdown/CustomizedDropdown'
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { collection, deleteDoc, deleteField, doc, FieldValue, onSnapshot, orderBy, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import { Schooling, getSchooling } from "../../../firebase/types-alumni";
import { createNewSchooling, updateSchooling } from "../../../firebase/alumniApis";
import { durationCalculationStr, getOrganisationTypeLabel, schoolingDurationCalculation } from "../../../helpers/utils";
import { useParams } from "react-router-dom";
import { ITabInterface } from "./TabInterfaces";
import constants from "../../../helpers/constants";
import { Campus, House, PermissionState } from "../../../firebase/types-organisations";
import pxToRem from "../../../helpers/pxToRem";
import { getAlumniProfileError, getErrorMsgForInput } from "../../../helpers/inputValidators";
import moment, { Moment } from "moment";
import CustomDatePicker from "../../../components/DatePicker";

export type TSchooling = { id: string; label?: string; value?: string; type: "DataInitial" | "Data" | "Space" | "DataEnd"; }
export type TSchoolingRow = { id: string; rows: TSchooling[]; schooling?: Schooling; }
const SchoolingTab = ({ orgId, orgName, alumniId, organisationCampus, organisationDefaultCampus, organisationHouse, schoolingRows }: ITabInterface) => {

    //const [schoolingRows, setSchoolingRows] = useState<TSchoolingRow[]>([]);
    const [isNewSchooling, setIsNewSchooling] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");

    const [schoolOptionDefault, setSchoolOptionDefault] = useState({ displayText: orgName ?? "", value: 'CURRENT' });
    const [errorMsg, setErrorMsg] = useState("");

    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);

    const schoolOption = useTextInput({ inputValidator: (input) => { return undefined; }, cancelHasTouchedAutoUpdate: true, defaultValue: 'CURRENT' });
    const type = useTextInput({ inputValidator: (input) => { return undefined; }, cancelHasTouchedAutoUpdate: true, });
    const [typeOptionDefault, setTypeOptionDefault] = useState({ displayText: 'Primary', value: 'PRIMARY' });
    const [yearEnrolled, setYearEnrolled] = useState<Moment | null>(null);
    const [peerYear, setPeerYear] = useState<Moment | null>(null);
    const [yearLeft, setYearLeft] = useState<Moment | null>(null);

    const schoolName = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });
    const schoolCampus = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });
    const schoolHouse = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [menuShowId, setMenuShowId] = useState(-1);
    const menuList = [{ label: "Edit", val: 0 }, { label: "Delete", val: 1 }, { label: "Exit", val: -1 }];
    const [editId, setEditId] = useState<string | undefined>(undefined);
    const [editIsCurrent, setEditIsCurrent] = useState(false);

    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | undefined>(undefined);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
    const menuClose = () => { setAnchorEl(null); }


    const schoolingRowItemGroup = (tSchoolingRow: TSchoolingRow, schooling: TSchooling, key: string, size: number) => {
        const onDelete = () => {
            setCurrentDeleteId(tSchoolingRow.id);
            setIsDeleteModal(true);
        }
        const onEdit = () => {
            resetForm();
            const schoolFB: Schooling | undefined = tSchoolingRow.schooling;

            if (schoolFB !== undefined) {
                setEditId(tSchoolingRow.id);
                setIsNewSchooling(true); //Edit Mode
                if (schoolFB.schoolOption === "CURRENT") {
                    setEditIsCurrent(true)
                    schoolOption.setValue("CURRENT")
                    schoolName.setValue(orgName ?? "")
                }
                else {
                    console.log(" - - - - > ADING NEW", schoolName.value)
                    setEditIsCurrent(false);
                    schoolOption.setValue("NEW")
                    schoolName.setValue(schoolFB.schoolName ?? "");
                    schoolCampus.setValue(schoolFB.schoolCampus ?? "");
                    schoolCampus.setHasTouched(false);
                    if (schoolFB.schoolName !== undefined) schoolName.setValue(schoolFB.schoolName.toString());
                }
                // if(schoolFB.schoolOption === "CURRENT") setSchoolOptionDefault({ displayText: orgName??"", value: 'CURRENT' });
                // else setSchoolOptionDefault({ displayText: "Add New", value: "NEW" });

                // switch(schoolFB.type){
                //     case "PRIMARY": setTypeOptionDefault({ displayText: 'Primary', value: 'PRIMARY' }); break;
                //     case "SECONDARY": setTypeOptionDefault({ displayText: 'Secondary', value: 'SECONDARY' }); break;
                //     case "PRIMARY_SECONDARY": setTypeOptionDefault({ displayText: 'Primary & Secondary', value: 'PRIMARY_SECONDARY' }); break;
                //     default: return;
                // }

                // FIXME: UPDATED
                if (schoolFB.yearStarted) setYearEnrolled(moment(schoolFB.yearStarted, "YYYY"));
                if (schoolFB.peerYear) setPeerYear(moment(schoolFB.peerYear, "YYYY"));
                if (schoolFB.yearLeft) setYearLeft(moment(schoolFB.yearLeft, "YYYY"));

                if (schoolFB.schoolCampus !== undefined) schoolCampus.setValue(schoolFB.schoolCampus.toString());    // NOTE: NEED TO TEST
                if (schoolFB.schoolHouse !== undefined) schoolHouse.setValue(schoolFB.schoolHouse.toString());   // NOTE: NEED TO TEST
            }
        }

        return (
            <Fragment key={key}>
                {
                    schooling.type === "DataInitial" ?
                        <tr style={{ backgroundColor: '#549189' }}>

                            {/* <td className={styles.alumni_details_tableBottomBorder}>
                                <Stack direction={"row"} spacing={2}>
                                    <label className={ menuShowId===-1?styles.normal_modeLabel:menuShowId===0?styles.edit_modeLabel:styles.delete_modeLabel }>{ schooling.label }</label>
                                    { menuShowId === 0? <Stack className={styles.table_btn} onClick={()=>onEdit()}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 32 32" fill="none"> <path d="M15 8.12132H10.8C9.11984 8.12132 8.27976 8.12132 7.63803 8.4483C7.07354 8.73592 6.6146 9.19486 6.32698 9.75935C6 10.4011 6 11.2412 6 12.9213V21.3213C6 23.0015 6 23.8416 6.32698 24.4833C6.6146 25.0478 7.07354 25.5067 7.63803 25.7943C8.27976 26.1213 9.11984 26.1213 10.8 26.1213H19.2C20.8802 26.1213 21.7202 26.1213 22.362 25.7943C22.9265 25.5067 23.3854 25.0478 23.673 24.4833C24 23.8416 24 23.0015 24 21.3213V17.1213M12 20.1213H13.6745C14.1637 20.1213 14.4083 20.1213 14.6385 20.0661C14.8425 20.0171 15.0376 19.9363 15.2166 19.8266C15.4184 19.7029 15.5914 19.53 15.9373 19.1841L25.5 9.62132C26.3284 8.79289 26.3284 7.44975 25.5 6.62132C24.6716 5.79289 23.3284 5.79289 22.5 6.62132L12.9372 16.1841C12.5913 16.53 12.4184 16.7029 12.2947 16.9048C12.185 17.0837 12.1042 17.2788 12.0552 17.4829C12 17.713 12 17.9576 12 18.4468V20.1213Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></Stack> : null }
                                    { menuShowId === 1? <Stack className={styles.table_btn} onClick={()=>onDelete()}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(22)} height={pxToRem(22)} viewBox="0 0 24 24" fill="none"> <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></Stack> : null }
                                </Stack>
                            </td> */}
                            <td className={styles.alumni_details_tableBottomTitleLeft}>{schooling.value}</td>
                            <td className={styles.alumni_details_tableBottomTitleRight}>
                                {
                                    alumniPermission && alumniPermission === PermissionState.EDITOR &&
                                    <Stack direction="row-reverse">
                                        <Stack className={styles.table_btn} onClick={() => onEdit()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12132H5.8C4.11984 3.12132 3.27976 3.12132 2.63803 3.4483C2.07354 3.73592 1.6146 4.19486 1.32698 4.75935C1 5.40109 1 6.24116 1 7.92132V16.3213C1 18.0015 1 18.8416 1.32698 19.4833C1.6146 20.0478 2.07354 20.5067 2.63803 20.7943C3.27976 21.1213 4.11984 21.1213 5.8 21.1213H14.2C15.8802 21.1213 16.7202 21.1213 17.362 20.7943C17.9265 20.5067 18.3854 20.0478 18.673 19.4833C19 18.8416 19 18.0015 19 16.3213V12.1213M6.99997 15.1213H8.67452C9.1637 15.1213 9.40829 15.1213 9.63846 15.0661C9.84254 15.0171 10.0376 14.9363 10.2166 14.8266C10.4184 14.7029 10.5914 14.53 10.9373 14.1841L20.5 4.62132C21.3284 3.79289 21.3284 2.44975 20.5 1.62132C19.6716 0.792894 18.3284 0.792893 17.5 1.62132L7.93723 11.1841C7.59133 11.53 7.41838 11.7029 7.29469 11.9048C7.18504 12.0837 7.10423 12.2788 7.05523 12.4829C6.99997 12.713 6.99997 12.9576 6.99997 13.4468V15.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                        </Stack>
                                    </Stack>
                                }
                            </td>
                        </tr>
                        :
                        schooling.type === "Data" ?
                            <tr>
                                <td className={styles.alumni_details_tableBottomGrayBorder}>{schooling.label}</td>
                                <td className={styles.alumni_details_tableBottomBorder}>{schooling.value}</td>
                            </tr>
                            : schooling.type === "DataEnd" ?
                                <tr>
                                    <td className={styles.alumni_details_end_tableBottomGrayBorder}>{schooling.label}</td>
                                    <td className={styles.alumni_details_end_tableBottomBorder}>{schooling.value}</td>
                                </tr>
                                :
                                <tr className={styles.noBorder}>
                                    <td colSpan={3} className={styles.noBorder}>&nbsp;</td>
                                </tr>
                }
            </Fragment>
        );
    }
    const schoolingRowCreate = (rSchoolingRows: TSchoolingRow, key: string) => {
        return (<Fragment key={key}>{rSchoolingRows.rows.map((tSchooling, index) => schoolingRowItemGroup(rSchoolingRows, tSchooling, tSchooling.id, rSchoolingRows.rows.length - 1))}</Fragment>);
    }
    const SchoolingGroup = ({ tSchoolingRowArr }: { tSchoolingRowArr: TSchoolingRow[] }) => {
        return (<Fragment key={"1_schoolingrowarr_group"}>{tSchoolingRowArr.map((schoolingInfom, index) => schoolingRowCreate(schoolingInfom, index + "__tsra"))}</Fragment>);
    }
    const onDelete = (rowId: string) => {
        setCurrentDeleteId(rowId);
        setIsDeleteModal(true);
    }

    const getSchoolingType = (type: string): string => {
        if (type === "PRIMARY") return "Primary";
        else if (type === "SECONDARY") return "Secondary";
        else if (type === "PRIMARY_SECONDARY") return "Primary & Secondary";
        return ";"
    }

    const [existingIds, setExistingIds] = useState<Set<string>>(new Set());
    const resetForm = () => {
        setEditId(undefined);
        setCurrentDeleteId(undefined);
        setMenuShowId(-1);
        setSchoolOptionDefault({ displayText: orgName ?? "", value: 'CURRENT' });
        setTypeOptionDefault({ displayText: 'Primary', value: 'PRIMARY' });
        setYearEnrolled(null);
        setPeerYear(null);
        setYearLeft(null);
        schoolCampus.setValue("");
        schoolHouse.setValue("");
        schoolName.setValue(orgName ?? ""); // Default School name always needs to be orgName cause the only way it will set value is when drop down is selected so in the case where drop down is no selected it needs to org name.
        if (organisationDefaultCampus) { schoolCampus.setValue(organisationDefaultCampus); }
        schoolOption.setValue("CURRENT"); // Will change the label to current organisation
        if (currentOrganisationType !== undefined) { type.setValue(currentOrganisationType as string); }
    }
    const addData = async () => {
        if (orgId && alumniId) {
            if (schoolOption.value === 'CURRENT') {
                console.log("Current School Validation", schoolName.value, type.value, schoolCampus.value);
                const mandatoryInputs = [schoolName, type, schoolCampus];  // Current School Validation
                let validated = true;
                mandatoryInputs.map((input) => input.setHasTouched(true));
                mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
                if (!validated) { return; }
                let optionalValidated = true;   // Optional Fields
                const optionalInput = [schoolHouse];
                optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
                if (!optionalValidated) { return; }
            } else {
                // New School Validation
                console.log("NEW School Validation", schoolName.value);
                const mandatoryInputs = [schoolName];
                let validated = true;
                mandatoryInputs.map((input) => input.setHasTouched(true));
                mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
                console.log("NEW School Validation Mandatory", validated);
                if (!validated) { return; }
                let optionalValidated = true;   // Optional Fields
                const optionalInput = [schoolCampus, schoolHouse];
                optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
                if (!optionalValidated) { return; }
            }
            console.log("School Ready to Add to firebase")
            setLoadingStatus(true);
            setLoadingStatusStr("Saving..");
            const schooling: Schooling = {
                ...(schoolOption.value === 'NEW' ? schoolOption.value && { schoolOption: schoolOption.value.trim() } : schoolOption.value && { schoolOption: "CURRENT" }), // only for new school
                ...(schoolOption.value === 'NEW' && schoolName.value && { schoolName: schoolName.value.trim() }), // FIXME: FIXE school option and school name
                ...(type.value && { type: type.value.trim() }),
                ...(yearEnrolled && { yearStarted: parseInt(moment(yearEnrolled, "YYYY").format("YYYY")) }),
                ...(peerYear && { peerYear: parseInt(moment(peerYear, "YYYY").format("YYYY")) }),
                ...(yearLeft && { yearLeft: parseInt(moment(yearLeft, "YYYY").format("YYYY")) }),
                ...(schoolHouse.value && { schoolHouse: schoolHouse.value.trim() }),
                ...(schoolCampus.value && { schoolCampus: schoolCampus.value.trim() }),
                alumniId: alumniId,
                orgId: orgId,
                created_at: serverTimestamp(),
                updated_at: serverTimestamp()
            }
            const res = await createNewSchooling(orgId, alumniId, schooling, schoolOption.value === 'CURRENT' && peerYear ? parseInt(moment(peerYear, "YYYY").format("YYYY")) : undefined);
            if (res.code === 500) { setErrorMsg("Errors: Schooling create."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewSchooling(false);
            resetForm();
        }
    }
    const deleteData = async () => {
        if (orgId && alumniId) {
            if (currentDeleteId === undefined) return;
            if (orgId !== undefined && orgId !== null) {
                setDeleteLoadingStatus(true);
                try { await deleteDoc(doc(db, "organisations", orgId, "alumni", alumniId, "schooling", currentDeleteId)); }
                catch (ex) { setErrorMsg("Errors: Schooling delete."); console.log(ex); }
                setDeleteLoadingStatus(false);
                setIsDeleteModal(false);
                setCurrentDeleteId(undefined);
                resetForm();
            }
        }
    }
    const updateData = async (schoolingId: string | undefined) => {
        if (orgId && alumniId) {
            if (schoolingId === undefined) return;
            if (schoolOption.value === 'CURRENT') {
                const mandatoryInputs = [schoolName];  // Current School Validation
                let validated = true;
                mandatoryInputs.map((input) => input.setHasTouched(true));
                mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
                if (!validated) { return; }
                let optionalValidated = true;   // Optional Fields
                const optionalInput = [schoolHouse];
                optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
                if (!optionalValidated) { return; }
            } else {
                // New School Validation
                const mandatoryInputs = [schoolName, type];
                let validated = true;
                mandatoryInputs.map((input) => input.setHasTouched(true));
                mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
                if (!validated) { return; }
                let optionalValidated = true;   // Optional Fields
                const optionalInput = [schoolCampus, schoolHouse];
                optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
                if (!optionalValidated) { return; }
            }
            setLoadingStatus(true);
            setLoadingStatusStr("Updating..");

            const schooling: Schooling<FieldValue> = {
                ...(!schoolOption.value ? { schoolOption: deleteField() } : { schoolOption: schoolOption.value.trim() }),   // "NEW" || "CURRENT", Mandatory always needs to be there
                ...(!schoolName.value && schoolOption.value === 'CURRENT' ? { schoolName: deleteField() } : { schoolName: schoolName.value.trim() }), // Mandatory Field
                ...(!type.value ? { type: deleteField() } : { type: type.value.trim() }),
                ...(yearEnrolled === null ? { yearStarted: deleteField() } : { yearStarted: parseInt(moment(yearEnrolled, "YYYY").format("YYYY")) }),
                ...(peerYear === null ? { peerYear: deleteField() } : { peerYear: parseInt(moment(peerYear, "YYYY").format("YYYY")) }),
                ...(yearLeft === null ? { yearLeft: deleteField() } : { yearLeft: parseInt(moment(yearLeft, "YYYY").format("YYYY")) }),
                ...(schoolHouse.value === "" ? { schoolHouse: deleteField() } : { schoolHouse: schoolHouse.value.trim() }),
                ...(schoolCampus.value === "" ? { schoolCampus: deleteField() } : { schoolCampus: schoolCampus.value.trim() }),
                updated_at: serverTimestamp()
            }
            const res = await updateSchooling(orgId, alumniId, schooling, schoolingId, schoolOption.value === 'CURRENT' && peerYear ? parseInt(moment(peerYear, "YYYY").format("YYYY")) : undefined);
            if (res.code === 500) { setErrorMsg("Errors: Schooling update."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewSchooling(false);
            resetForm();
        }
    }

    const schoolOptions = [{ label: orgName ?? "", value: 'CURRENT' }, { label: "Add New", value: "NEW" }];
    const typeOptions = [{ label: "Primary", value: "PRIMARY" }, { label: "Secondary", value: "SECONDARY" }, { label: "Primary & Secondary", value: "PRIMARY_SECONDARY" }];
    const currentOrganisationType = useSelector((state: RootState) => state.organisation.organisation?.type);
    useMemo(() => {
        if (currentOrganisationType !== undefined) {
            type.setValue(currentOrganisationType as string);
        }
    }, [currentOrganisationType, editId]);

    useEffect(() => {
        if (!editId && isNewSchooling && (schoolOption.value === "NEW" || schoolOptionDefault.value === "NEW")) {  // When Add new selected when adding new school details
            schoolCampus.setValue("");
            schoolHouse.setValue("");
            schoolName.setValue("");
            schoolName.setHasTouched(false);
            schoolCampus.setHasTouched(false);
        } else if (schoolOption.value === "CURRENT") {
            if (organisationDefaultCampus) { schoolCampus.setValue(organisationDefaultCampus) }
            schoolName.setValue(orgName ?? "")
        }
    }, [organisationDefaultCampus, schoolOption.value])

    const schoolingDuration = schoolingDurationCalculation(yearEnrolled, peerYear, yearLeft);
    return (
        <Stack>
            <CustomizedModal open={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} type='ERROR' children={<p>{errorMsg}</p>} />
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} type="ERROR" isLoading={deleteLoadingStatus} loadingStr={"Removing..."} >
                <Stack>
                    <Stack spacing={0.5} style={{ marginTop: 20, marginBottom: 10 }} className={styles.modalHeaderTitleWrapper}>
                        <label className={styles.modalTitle}>Delete Schooling</label>
                        <label className={styles.modalSubTitle}>This action cannot be undone</label>
                    </Stack>
                    <Stack style={{ marginBottom: 20 }} spacing={3} className={styles.modalHeaderTitleWrapper}>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                        <label className={styles.modalBodyText}>Are you sure you want to delete this schooling?</label>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    </Stack>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(undefined); resetForm() }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <WarningButton className={styles.button} onClick={() => deleteData()}> Yes, I'm Sure </WarningButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal width={660} open={isNewSchooling} handleClose={() => { }} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack width={"100%"} alignItems={"end"}>
                        <IconButton onClick={() => { setIsNewSchooling(false); resetForm(); }}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.alumni_details_modalHeaderTitleWrapper}>
                        <div className={styles.alumni_details_iconWithCircleWrapper}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"> <path d="M9 17L4 22M15 17L20 22M12 2V4M12 22V17M5.2 17H18.8C19.9201 17 20.4802 17 20.908 16.782C21.2843 16.5903 21.5903 16.2843 21.782 15.908C22 15.4802 22 14.9201 22 13.8V7.2C22 6.07989 22 5.51984 21.782 5.09202C21.5903 4.71569 21.2843 4.40973 20.908 4.21799C20.4802 4 19.9201 4 18.8 4H5.2C4.07989 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.07989 2 7.2V13.8C2 14.9201 2 15.4802 2.21799 15.908C2.40973 16.2843 2.71569 16.5903 3.09202 16.782C3.51984 17 4.0799 17 5.2 17Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <label className={styles.alumni_details_modalHeaderTitle}> {(editId) ? "Edit Schooling" : "Add New Schooling"}</label>
                        </div>
                    </Stack>
                    <Stack className={styles.popupContent} style={{ paddingRight: 10, paddingBottom: 10 }}>
                        <Stack>
                            <Stack style={{ flex: 1, marginTop: 50 }} spacing={2} >
                                {editId ?
                                    schoolOption.value === "CURRENT" ? <InputTextField disabled={editIsCurrent ? true : false} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={schoolName.value} containerStyle={{ flex: 1.5 }} label='School Name' value={schoolName.value} onChange={(e) => schoolName.setValue(e.target.value)} error={schoolName.hasError && schoolName.hasTouched} helperText={<small>{schoolName.getErrorMessage()}</small>} /> : null
                                    :
                                    <CustomizedDropdown
                                        fullWidth isNotOptionalNone
                                        label="School" nonePlaceHolder="None" noneEnable={true}
                                        valueCheck={schoolOption.value} customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize}
                                        datas={schoolOptions}
                                        value={schoolOption.value}
                                        onChange={(e) => { schoolOption.setValue(e.target.value as string) }}
                                        error={schoolOption.hasTouched && schoolOption.value === undefined ? true : false}
                                        helperText={schoolOption.hasTouched && schoolOption.value === undefined ? "This field cannot be empty" : undefined}
                                        shrinkLabelWidth={39}
                                    />
                                }
                                {
                                    (schoolOption.value === "NEW" || schoolOptionDefault.value === "NEW") ?
                                        <InputTextField fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={schoolName.value} label='School Name*' value={schoolName.value} onChange={(e) => schoolName.setValue(e.target.value)} error={schoolName.hasError && schoolName.hasTouched} helperText={<small>{schoolName.getErrorMessage()}</small>} /> : null
                                }
                                <CustomizedDropdown
                                    fullWidth isNotOptionalNone
                                    label="Type" nonePlaceHolder="None" noneEnable={true}
                                    valueCheck={type.value} customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize}
                                    datas={typeOptions}
                                    value={type.value}
                                    onChange={(e) => { type.setValue(e.target.value as string) }}
                                    error={type.hasTouched && type.value === undefined ? true : false}
                                    helperText={type.hasTouched && type.value === undefined ? "This field cannot be empty" : undefined}
                                    shrinkLabelWidth={33}
                                />
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    <div style={{ flex: 1.5 }} >
                                        <CustomDatePicker label="Year enrolled" views={['year']}
                                            setCleared={() => setYearEnrolled(null)} placeholder={""} fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight} format="YYYY"
                                            valueCheck={yearEnrolled ? moment(yearEnrolled, "YYYY") : null}
                                            value={yearEnrolled ? moment(yearEnrolled, "YYYY") : null}
                                            onChange={(value) => { if (value) { setYearEnrolled(value); } }}
                                        />
                                    </div>
                                    <div style={{ flex: 1.5 }} >
                                        <CustomDatePicker label="Peer Year" views={['year']}
                                            setCleared={() => setPeerYear(null)} placeholder={""} fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight} format="YYYY"
                                            valueCheck={peerYear ? moment(peerYear, "YYYY") : null}
                                            value={peerYear ? moment(peerYear, "YYYY") : null}
                                            onChange={(value) => { if (value) { setPeerYear(value); } }}
                                        />
                                    </div>
                                    <Stack style={{ alignItems: 'center', height: '100%', minWidth: pxToRem(110) }}>
                                        <div className='pxs' >{`Duration: ${schoolingDuration} ${schoolingDuration === 1 ? "Year" : "Years"}`}</div>
                                    </Stack>
                                </Stack>
                                <CustomDatePicker label="Year left (If different to peer year)" views={['year']}
                                    setCleared={() => setYearLeft(null)} placeholder={""} fontSize={constants.addAlumniFontSize}
                                    customHeight={constants.addAlumniInputHeight} format="YYYY"
                                    valueCheck={yearLeft ? moment(yearLeft, "YYYY") : null}
                                    value={yearLeft ? moment(yearLeft, "YYYY") : null}
                                    onChange={(value) => { if (value) { setYearLeft(value); } }}
                                />
                                {
                                    (editId && !editIsCurrent) || (schoolOption.value === "NEW" || schoolOptionDefault.value === "NEW") ?
                                        <Stack direction={"row"} spacing={2}>
                                            <InputTextField fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={schoolCampus.value} containerStyle={{ flex: 1.5 }} label='School Campus' value={schoolCampus.value} onChange={(e) => schoolCampus.setValue(e.target.value)} error={schoolCampus.hasError && schoolCampus.hasTouched} helperText={<small>{schoolCampus.getErrorMessage()}</small>} />
                                            <InputTextField fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={schoolHouse.value} containerStyle={{ flex: 1.5 }} label='School House' value={schoolHouse.value} onChange={(e) => schoolHouse.setValue(e.target.value)} error={schoolHouse.hasError && schoolHouse.hasTouched} helperText={<small>{schoolHouse.getErrorMessage()}</small>} />
                                        </Stack>
                                        :
                                        <Stack direction={"row"} spacing={2}>
                                            {
                                                organisationCampus && organisationCampus.length > 0 && (
                                                    <CustomizedDropdown
                                                        fullWidth isNotOptionalNone
                                                        label="School Campus" nonePlaceHolder="None" noneEnable={true}
                                                        valueCheck={schoolCampus.value} customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize}
                                                        datas={organisationCampus ?? []}
                                                        value={schoolCampus.value}
                                                        onChange={(e) => { schoolCampus.setValue(e.target.value as string) }}
                                                        error={schoolCampus.hasTouched && schoolCampus.value === undefined ? true : false}
                                                        helperText={schoolCampus.hasTouched && schoolCampus.value === undefined ? "This field cannot be empty" : undefined}
                                                        shrinkLabelWidth={85}
                                                    />
                                                )
                                            }
                                            {
                                                organisationHouse && organisationHouse.length > 0 && (
                                                    <CustomizedDropdown
                                                        fullWidth
                                                        label="School House" nonePlaceHolder="Select" noneEnable={true}
                                                        valueCheck={schoolHouse.value} customHeight={constants.addAlumniInputHeight} fontSize={constants.addAlumniFontSize}
                                                        datas={organisationHouse ?? []}
                                                        value={schoolHouse.value}
                                                        onChange={(e) => { schoolHouse.setValue(e.target.value as string) }}
                                                        error={schoolHouse.hasTouched && schoolHouse.value === undefined ? true : false}
                                                        helperText={schoolHouse.hasTouched && schoolHouse.value === undefined ? "This field cannot be empty" : undefined}
                                                        shrinkLabelWidth={73}
                                                    />
                                                )
                                            }
                                        </Stack>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        {(editId) ?
                            <WarningButton className={styles.button} onClick={() => { onDelete(editId); setIsNewSchooling(false); }}> Delete </WarningButton>
                            :
                            <SecondaryButton className={styles.button} onClick={() => { setIsNewSchooling(false); resetForm(); }}> Cancel </SecondaryButton>
                        }
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button}
                                onClick={() => {
                                    if (editId === undefined) addData();
                                    else updateData(editId);
                                }}
                            >Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack>
                <Menu id="schooling-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={menuClose} autoFocus={false}>
                    {menuList.map((menu, index) => <MenuItem key={index} onClick={() => { setMenuShowId(menu.val); menuClose(); }} style={{ minWidth: 150 }}>{menu.label}</MenuItem>)}
                </Menu>
                <Stack style={{ borderRadius: pxToRem(8), backgroundColor: "#F9F9FA", alignItems: "center", justifyContent: "center", height: pxToRem(40), marginBottom: schoolingRows && schoolingRows.length == 0 ? 0 : pxToRem(20), padding: pxToRem(10) }} direction={"row"}>
                    <Stack style={{ fontWeight: 400, marginLeft: pxToRem(10), fontSize: pxToRem(14) }}>
                        Schooling
                    </Stack>
                    <Stack style={{ flex: 1, alignItems: 'center', marginRight: pxToRem(10) }} direction="row-reverse" spacing={1.5}>
                        {/* <IconButton sx={{ '&:hover': { backgroundColor: 'transparent', }, }} style={{padding:'0px 2px'}}  onClick={menuClick}> <svg width={pxToRem(12)} height={pxToRem(20)} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="6" cy="2" r="2" transform="rotate(90 6 2)" fill="#737373"/> <circle cx="6" cy="10" r="2" transform="rotate(90 6 10)" fill="#737373"/> <circle cx="6" cy="18" r="2" transform="rotate(90 6 18)" fill="#737373"/> </svg> </IconButton> */}
                        {alumniPermission && alumniPermission === PermissionState.EDITOR &&
                            <IconButton className={styles.buttonHover} onClick={() => setIsNewSchooling(true)}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>}
                    </Stack>
                </Stack>
                {
                    <table className={styles.alumni_profile_page_table}>
                        {schoolingRows && schoolingRows.length > 0 ?
                            <tbody>
                                <SchoolingGroup tSchoolingRowArr={schoolingRows} />
                            </tbody> : null}
                    </table>
                }
            </Stack>
            {
                schoolingRows && schoolingRows.length <= 0
                    ?
                    alumniPermission && alumniPermission === PermissionState.EDITOR ?
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">Add New</label>
                            <IconButton onClick={() => setIsNewSchooling(true)}><svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                        :
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">No Record</label>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                    :
                    null
            }
        </Stack>
    );
}

export default memo(SchoolingTab);