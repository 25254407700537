import { collection, doc, onSnapshot, orderBy, query, runTransaction, Timestamp, Unsubscribe, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { auth, db } from "../../firebase";
import { getOrganisationData } from "../../firebase/organisationApis";
import { getPermission, Organisation } from "../../firebase/types-organisations";
import { getInvitation, getTeamMember } from "../../firebase/types-userApi";
import { TeamMember, TeamPermission } from "../../pages/TeamPage/helpers";
import { RootState } from "../../redux";
import { addOrganisationList, removeOrganisationList, updateOrganisationList } from "../../redux/organisationSlice";
import { addTeamPermissions, removeTeamPermissions, resetTeamState, setTeamMembers, setTeamPendingInvitations, updateTeamPermissions } from "../../redux/teamSlice";
import moment from "moment";
import { Alumni } from "../../firebase/types-alumni";
import { Engagement, getEngagementDetails, NewEngagement } from "../../firebase/types-engagement";
import { Network } from "../../firebase/types-network";
import { getTask, Task } from "../../firebase/types-organisationPlanners";
import { Form, getForm } from "../../firebase/types-registration";
import { getOrgWeeklyGoals, getWeeklyGoal } from "../../firebase/types-weeklyGoals";
import { AlumniTable } from "../../pages/AlumniPage";
import { NetworkTable } from "../../pages/NetworkPage";
import { getCurrentWeekNumber } from "../../pages/OrganisationPage/helper";
import { addAlumni, formatEducation, formatEmployment, formatLiving, formatName, removeAlumni, resetAlumniState, setAlumniList, updateAlumni } from "../../redux/alumniDataSlice";
import { addForm, addGoal, addOrgWeeklyGoalsProgress, addTask, removeForm, removeOrgWeeklyGoalsProgress, removeTask, setDashboardDefault, updateForm, updateOrgWeeklyGoalsProgress, updateTask } from "../../redux/dashboardSlice";
import { addEngagement, removeEngagement, setEngagements, updateEngagement } from "../../redux/engagementSlice";
import { resetGlobalState } from "../../redux/globalSlice";
import { addNetwork, removeNetwork, resetNetworkState, updateNetwork } from "../../redux/networkDataSlice";


interface IListenerComponent {
    children: React.ReactNode;
    fallback: React.ReactNode;
}

enum FirebaseEntityName {
    ORG='organisations',
    ALUMNI='alumni',
    FORMS='forms',
    PLANNER='organisationPlanner',
    INVITATIONS='invitations',
    TEAM='team',
    PERMISSIONS="permissions",
    WEEKLY_GOALS="weeklyGoals",
    ORG_WEEKLY_GOALS="orgWeeklyGoals",
    ENGAGEMENTS='engagements',
    NETWORK='network',
    PHILANTHROPY='philanthropy',
}

enum ApiEntryPointName {
    ORG = 'organisation',
    ALUMNI= 'alumni',
    FORMS= 'forms',
    PLANNER= 'planner',
    ENGAGEMENT= 'engagement',
    NETWORK='network',
    PHILANTHROPY='philanthropy',
    TEAM = 'team',
    INVITES = 'invites',
    BILLING = 'billing'
}

interface RouteUnsubscriber {
    name: FirebaseEntityName,
    value?: string | string[],
    listenerUnsub?:Unsubscribe
}

const ListenerComponent = ({children, fallback}: IListenerComponent) => {
    const [isLoadingListener, setIsLoadingListener] = useState(false);
    const [isLoadingCleanup, setIsLoadingCleanup] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    // const {orgId} = useParams();
    const teams = useSelector((state:RootState) => state.teamState.teamMembers);
    const organisation = useSelector((state:RootState) => state.globalState.organisation);
    const orgId = useSelector((state:RootState) => state.organisation.orgId);
    const isAdmin = useSelector((state:RootState) => state.globalState.isAdmin);

    const organisationList = useSelector((state:RootState) => state.organisation.organisationList);
    const [dataDict, setDataDict] = useState<{name: FirebaseEntityName, value: any, listenerUnsub?: Unsubscribe}[]>([])

    const listenerExists = (name: FirebaseEntityName):boolean => dataDict.some((item) => item.name === name && item.listenerUnsub !== undefined);
    
    const removeUnsub = (nameToRemove: FirebaseEntityName): void => {
        setDataDict(prev => {
            const newList = [...prev];
            const indexToRemove = newList.findIndex((item) => item.name === nameToRemove);
            
            if(indexToRemove !== -1) {
                const toRemove = newList.splice(indexToRemove, 1)[0];
                toRemove.listenerUnsub && toRemove.listenerUnsub();
            };
            return newList
        })
    };

    const addListener = (name: FirebaseEntityName, value: any, listenerUnsub?: Unsubscribe) => {
        setDataDict(prev => {
            const newList = [...prev];
            newList.push({
                name: name,
                value: value,
                listenerUnsub: listenerUnsub})
            return newList;
        })
    }

    // Attach Organisation Listeners
    useEffect(() => {
        if(auth.currentUser && auth.currentUser.uid) {
            // const orgColRef = collection(db, "users", auth.currentUser.uid, "organisations");
            // const qry = query(orgColRef, orderBy('updated_at', "desc"));
            
            const orgRef = collection(db, "organisations");
            const qry = query(orgRef, where('users', 'array-contains', auth.currentUser.uid))

            const unsub = onSnapshot(qry, (querySnap) => {
                querySnap.docChanges().forEach(async(change) => {
                    const id = change.doc.id;
                    try {
                        switch(change.type){
                            case 'added':{ //Added
                                const organization: Organisation = { ...await getOrganisationData(id), id: id };
                                dispatch(addOrganisationList(organization));
                                break;
                            }
                            case 'modified':{ //Updated
                                console.log("modified")
                                const organization: Organisation = { ...await getOrganisationData(id), id: id };
                                dispatch(updateOrganisationList(organization));
                                break;
                            }
                            case 'removed':{ //Removed
                                dispatch(removeOrganisationList(id));
                                break;
                            }
                            default: break;
                        }
                    }
                    catch (error) {
                        console.error("Error processing change:", error);
                    }
                });
            },
                (error) => {
                    console.error("Error fetching snapshot:", error);
                    // Handle Firestore permission errors specifically
                    if (error.code === 'permission-denied') {
                        // Optionally, notify the user or redirect them to a login page
                        console.error("Permission denied. The user might have been deleted or has no access.");
                        // Example: redirect to login
                        // window.location.href = "/login";
                    }
                });
            // console.log('listener route 1.3');
            return () => {unsub();}
        } 
    },[auth.currentUser])

    // Update Other Feature Listener
    useEffect(() => {
        updateListeners();
    }, [location.pathname, orgId, isAdmin])


    // Update listeners based on location path
    const updateListeners = async () => {
        // console.log("updating listeners")
        const keywords = location.pathname.split('/');

        if(!orgId) return;

        setIsLoadingListener(true);
        for(const key of Object.values(ApiEntryPointName)) {
            if(keywords.includes(key)) {
                console.log("looking at --->", key)
                // Attach new Listener
                const value = keywords[keywords.indexOf(key)+1] || undefined
                switch(key) {
                    case ApiEntryPointName.ORG:
                        // Open forms listener for dashboard + reuse for forms page
                        if(!listenerExists(FirebaseEntityName.FORMS)) {
                            // console.log("Adding QR forms listener");
                            const formsRef = collection(db, "registrations", orgId, "forms");
                            const q = query(formsRef, orderBy("pendingApproval", "desc"), where("isActive", "==", true));
                            const formsUnsub = onSnapshot(q, (snap) => {
                                snap.docChanges().forEach(async(change) => {
                                    const id = change.doc.id;
                                    switch(change.type) {
                                        case 'added': {
                                            const form:Form = {
                                                formId: id,
                                                ...getForm(change.doc.data())
                                            };
                                            dispatch(addForm(form));
                                            break;
                                        }
                                        case 'modified': {
                                            const form:Form = {
                                                formId: id,
                                                ...getForm(change.doc.data())
                                            };
                                            dispatch(updateForm(form));
                                            break;
                                        }
                                        case 'removed': {
                                            dispatch(removeForm(id));
                                            break;
                                        }
                                    }
                                })
                            });
                            addListener(FirebaseEntityName.FORMS, "", formsUnsub);
                        } 

                        if(!listenerExists(FirebaseEntityName.PLANNER)) {
                            const plannerRef = collection(db,"organisations", orgId, "organisationPlanner");
                            const q = query(plannerRef, orderBy("date", 'asc'));
                            const plannerUnsub = onSnapshot(q, (snap) => {
                                snap.docChanges().forEach(async (change) => {
                                    const id = change.doc.id;
                                    switch(change.type) {
                                        case 'added': {
                                            const task:Task = {
                                                id: id,
                                                ...getTask(change.doc.data())
                                            };
                                            dispatch(addTask(task));
                                            break;
                                        }
                                        case 'modified': {
                                            console.log("Task modified!");
                                            const task:Task = {
                                                id: id,
                                                ...getTask(change.doc.data())
                                            };
                                            dispatch(updateTask(task));
                                            break;
                                        }
                                        case 'removed': {
                                            dispatch(removeTask(id));
                                            break;
                                        }
                                    }
                                })
                            });
                            addListener(FirebaseEntityName.PLANNER, "", plannerUnsub);
                        }

                        if (!listenerExists(FirebaseEntityName.ENGAGEMENTS)) {
                            const engagementsRef = collection(db, "organisations", orgId, "engagements");
                            const queryEngagements = query(engagementsRef, orderBy("start_time", 'asc')); // Assuming you want to order by start time
                        
                            const engagementUnsub = onSnapshot(queryEngagements, (snapshot) => {
                                snapshot.docChanges().forEach((change) => {
                                    const id = change.doc.id;
                                    switch (change.type) {
                                        case 'added':
                                            const newEngagement: NewEngagement = {
                                                id: id,
                                                ...getEngagementDetails(change.doc.data()) // Casting Firestore document data to Engagement type
                                            };
                                            dispatch(addEngagement(newEngagement));
                                            break;
                                        case 'modified':
                                            const updatedEngagement: Engagement = {
                                                id: id,
                                                ...getEngagementDetails(change.doc.data())
                                            };
                                            dispatch(updateEngagement(updatedEngagement));
                                            break;
                                        case 'removed':
                                            dispatch(removeEngagement(id));
                                            break;
                                    }
                                });
                            });
                        
                            addListener(FirebaseEntityName.ENGAGEMENTS, "", engagementUnsub);
                        }
                    
                        if(!listenerExists(FirebaseEntityName.WEEKLY_GOALS)) {
                            const currentDate = moment();
                            const weeklyGoalsRef = collection(db, FirebaseEntityName.WEEKLY_GOALS, currentDate.format("YYYY"), "goals");
                            const weeklyGoalsUnsub = onSnapshot(weeklyGoalsRef, (snap) => {
                                snap.docChanges().forEach(async (change) => {
                                    const id = change.doc.id;
                                    switch(change.type) {
                                        case 'added': {
                                            var weeklyGoal = getWeeklyGoal(change.doc.data());
                                            weeklyGoal.code = id;
                                            if(weeklyGoal.code) {
                                                const currentWeekNumber = getCurrentWeekNumber();
                                                if(parseInt(weeklyGoal.code) <= currentWeekNumber){
                                                    dispatch(addGoal(weeklyGoal))
                                                }

                                                // var temp = weeklyGoal.code.split('-');

                                                // const givenMonth = temp[0]; // Example month as a string
                                                // const givenWeekInMonth = temp[1]; // Example week number in the month as a string
                                                // const givenYear = moment().year(); // Given year

                                                // // Parse the given month and week number into a Moment.js date
                                                // const givenDate = moment().year(givenYear).month(parseInt(givenMonth)-1).startOf('month').add(parseInt(givenWeekInMonth) - 1, 'weeks');

                                                // // Check if the given month and week combo is the same or before the current month and week number
                                                // const isSameOrBefore = givenDate.isSameOrBefore(currentDate, 'week');

                                                // if(isSameOrBefore) {
                                                //     dispatch(addGoal(weeklyGoal))
                                                // }
                                            }
                                        }
                                    }
                                })
                            });
                            addListener(FirebaseEntityName.WEEKLY_GOALS, "", weeklyGoalsUnsub);
                        }

                        if(!listenerExists(FirebaseEntityName.ORG_WEEKLY_GOALS)) {
                            const currentDate = moment();
                            const orgWeeklyGoalsRef = collection(db, "organisations", orgId, FirebaseEntityName.WEEKLY_GOALS, currentDate.format("YYYY"), "goals");
                            const orgWeeklyGoalsUnsub = onSnapshot(orgWeeklyGoalsRef, (snap) => {
                                snap.docChanges().forEach(async (change) => {
                                    const id = change.doc.id;
                                    switch(change.type) {
                                        case 'added': {
                                            var progress = getOrgWeeklyGoals(change.doc.data());
                                            progress.code = change.doc.id;
                                            dispatch(addOrgWeeklyGoalsProgress(progress));
                                            break;
                                        }
                                        case 'modified': {
                                            var progress = getOrgWeeklyGoals(change.doc.data());
                                            progress.code = change.doc.id;
                                            dispatch(updateOrgWeeklyGoalsProgress(progress));
                                            break;
                                        }
                                        case 'removed': {
                                            dispatch(removeOrgWeeklyGoalsProgress(change.doc.id));
                                            break;
                                        }
                                    }
                                });
                            });
                            addListener(FirebaseEntityName.ORG_WEEKLY_GOALS, "", orgWeeklyGoalsUnsub);
                        }

                        if (!listenerExists(FirebaseEntityName.ALUMNI)) {
                            const alumniRef = collection(db, "organisations", orgId, "alumni");
                            const queryAlumni = query(alumniRef, orderBy("firstName", 'asc'));
                    
                            const alumniUnsub = onSnapshot(queryAlumni, (snapshot) => {
                                snapshot.docChanges().forEach((change) => {
                                    const id = change.doc.id;
                                    const data = change.doc.data() as Alumni;
                                    const { currentEmployment, educationDegree, firstName, lastName, suburb, city, country, created_at, updated_at, ...otherData } = data;
                    
                                    const formattedAlumni: AlumniTable = {
                                        objectID: id,
                                        name: formatName(firstName, lastName),
                                        firstName: firstName,
                                        lastName: lastName,
                                        currentEmployment: formatEmployment(currentEmployment),
                                        educationDegree: formatEducation(educationDegree),
                                        living: formatLiving(suburb, city, country),
                                        created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
                                        updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
                                        ...otherData // Now spreading the rest of the data that doesn't need transformation
                                    };
                    
                                    switch (change.type) {
                                        case 'added':
                                            dispatch(addAlumni(formattedAlumni));
                                            break;
                                        case 'modified':
                                            dispatch(updateAlumni(formattedAlumni));
                                            break;
                                        case 'removed':
                                            dispatch(removeAlumni(id));
                                            break;
                                    }
                                });
                            });
                    
                            addListener(FirebaseEntityName.ALUMNI, orgId, alumniUnsub);
                        }

                        if (!listenerExists(FirebaseEntityName.NETWORK)) {
                            const networkRef = collection(db, "organisations", orgId, "network");
                            const queryNetwork = query(networkRef, orderBy("firstName", 'asc'));
                    
                            const networkUnsub = onSnapshot(queryNetwork, (snapshot) => {
                                snapshot.docChanges().forEach((change) => {
                                    const id = change.doc.id;
                                    const data = change.doc.data() as Network;
                                    const { currentEmployment, educationDegree, firstName, lastName, networkTitle,suburb, city, country, created_at, updated_at, ...otherData } = data;
                    
                                    const formattedNetwork: NetworkTable = {
                                        objectID: id,
                                        name: formatName(firstName, lastName),
                                        firstName: firstName,
                                        lastName: lastName,
                                        currentEmployment: formatEmployment(currentEmployment),
                                        educationDegree: formatEducation(educationDegree),
                                        living: formatLiving(suburb, city, country),
                                        networkTitle: networkTitle,
                                        created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
                                        updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
                                        ...otherData // Now spreading the rest of the data that doesn't need transformation
                                    };
                    
                                    switch (change.type) {
                                        case 'added':
                                            dispatch(addNetwork(formattedNetwork));
                                            break;
                                        case 'modified':
                                            dispatch(updateNetwork(formattedNetwork));
                                            break;
                                        case 'removed':
                                            dispatch(removeNetwork(id));
                                            break;
                                    }
                                });
                            });
                    
                            addListener(FirebaseEntityName.NETWORK, orgId, networkUnsub);
                        }
                    

                        break;
                    case ApiEntryPointName.ALUMNI:
                        if(!value) {
                            // On all alumni pages

                        } else {
                            // On specific Alumni page

                        }
                        break;
                    case ApiEntryPointName.FORMS:
                        break;
                    case ApiEntryPointName.PLANNER:
                        if(!value) {
                            // On all planner page
                        } else {
                            // On specific planning item page
                        }
                        break;
                    case ApiEntryPointName.TEAM:
                        if(isAdmin) {
                            if(!listenerExists(FirebaseEntityName.INVITATIONS)) {
                                // console.log("Adding invitations listener");
                                const inviteRef = collection(db, "invitations");
                                const q = query(inviteRef, where("orgId", "==",orgId))
                                const invitationsUnsub = onSnapshot(q, (querySnap) => {
                                    // console.log("updating invitations")
                                    if(querySnap.docs.length == 0) {
                                        dispatch(setTeamPendingInvitations([]))
                                    } else {
                                        const invitations = querySnap.docs.map(doc => {
                                            return getInvitation(doc.id, doc.data())
                                        })
                                        dispatch(setTeamPendingInvitations(invitations))
                                    }
                                })
                                addListener(FirebaseEntityName.INVITATIONS, "", invitationsUnsub);
                            } else {
                                // console.log("invitations listener already open!");
                            }

                            if(!listenerExists(FirebaseEntityName.PERMISSIONS)) {
                                // console.log("Adding Permissions listener");
                                // Subscribe to team members permissions
                                const usersSubcolRef = collection(db, "organisations", orgId, "users");
                                const permissionsUnsub = onSnapshot(usersSubcolRef, (snap) => {
                                    snap.docChanges().forEach(async(change) => {
                                        switch(change.type) {
                                            case 'added':{ //Added
                                                const teamPermission:TeamPermission = {
                                                    userId: change.doc.id,
                                                    permissions: getPermission(change.doc.data())
                                                }
                                                dispatch(addTeamPermissions(teamPermission));
                                                break;
                                            }
                                            case 'modified':{ //Updated
                                                const teamPermission:TeamPermission = {
                                                    userId: change.doc.id,
                                                    permissions: getPermission(change.doc.data())
                                                }
                                                dispatch(updateTeamPermissions(teamPermission));
                                                break;
                                            }
                                            case 'removed':{ //Removed
                                                dispatch(removeTeamPermissions(change.doc.id));
                                                break;
                                            }
                                            default: break;
                                        }
                                    })
                                })

                                // Update listener list
                                addListener(FirebaseEntityName.PERMISSIONS, "", permissionsUnsub)
                            } else {
                                // console.log("permissions listener already open!");
                            }
                        } else {
                            if(listenerExists(FirebaseEntityName.INVITATIONS)) console.log('not admin - remove invitations listener'); removeUnsub(FirebaseEntityName.INVITATIONS);
                            if(listenerExists(FirebaseEntityName.PERMISSIONS)) console.log('not admin - remove permissions listener'); removeUnsub(FirebaseEntityName.PERMISSIONS);
                        }
                        break;
                }
            }
        }
        setIsLoadingListener(false);
    }

    // // Changes to listeners if user account type changes
    // useEffect(() => {
    //     const keywords = location.pathname.split('/');
    //     if(!orgId) return;
    //     setIsLoadingAdmin(true);
    //     for(const key of Object.values(ApiEntryPointName)) {
    //         if(keywords.includes(key)) {
    //             const value = keywords[keywords.indexOf(key)+1] || undefined
    //             switch(key) {
    //                 // case ApiEntryPointName.ALUMNI:
    //                 //     break;
    //                 // case ApiEntryPointName.FORMS:
    //                 //     break;
    //                 // case ApiEntryPointName.PLANNER:
    //                 //     break;
    //                 case ApiEntryPointName.TEAM:
    //                     if(!isAdmin) {
    //                         if(listenerExists(FirebaseEntityName.INVITATIONS)) console.log('not admin - remove invitations listener'); removeUnsub(FirebaseEntityName.INVITATIONS);
    //                         if(listenerExists(FirebaseEntityName.PERMISSIONS)) console.log('not admin - remove permissions listener'); removeUnsub(FirebaseEntityName.PERMISSIONS);
    //                     }
    //                     break;
    //             }
    //         }
    //     }
    //     setIsLoadingAdmin(false);
    // }, [isAdmin]);

    const fetchTeamMembersData = async () => {
        console.log("Team member update check");
        // Fetch Team members (for both admin and users)
        const prevTeam = dataDict.filter(u => u.name === FirebaseEntityName.TEAM)
        if((organisation?.users && prevTeam.length === 0) || (prevTeam.length>0 && JSON.stringify(prevTeam[0].value) !== JSON.stringify(organisation?.users))) {
            // If team members doesn't exist/have been updated, refetch user information
            // console.log("updating team members")
            console.log("Refetching team members");
            var usersList:TeamMember[] = [];
            await runTransaction(db, async(t) => {
                if(organisation?.users) {
                    for(const uid of organisation?.users) {
                        const docRef = doc(db, "users", uid);
                        const snap = await t.get(docRef);
                        if(snap.exists()) {
                            const user = getTeamMember(snap.id, snap.data());
                            usersList.push(user)
                        }
                    }
                }
            })
            dispatch(setTeamMembers(usersList))
            if(dataDict.some(i => i.name === FirebaseEntityName.TEAM)) removeUnsub(FirebaseEntityName.TEAM);
            addListener(FirebaseEntityName.TEAM, organisation?.users, undefined);
        }
    }

    useEffect(() => {
        fetchTeamMembersData();
    }, [organisation?.users])


    // Listener Clean up when user unmounts from a specific organisation
    const cleanUp = () => {
        console.log("Cleaning up!!!!!!");
        setIsLoadingCleanup(true);
        for(const item of dataDict) {
            if(item.listenerUnsub) item.listenerUnsub();
        }
        setDataDict([]);
        dispatch(resetTeamState());
        dispatch(setDashboardDefault());
        dispatch(resetGlobalState());
        dispatch(resetAlumniState());
        dispatch(resetNetworkState())
        dispatch(setEngagements([]));
        dispatch(setAlumniList([]))
        setIsLoadingCleanup(false);
    }

    useEffect(() => {
        console.log("mounted", orgId);
        return cleanUp;
    }, [orgId])

    const loading = () => {
        return isLoadingCleanup || isLoadingListener;
    }

    return (
        <>{loading()?fallback : children}</>
    )
}

export default ListenerComponent;

