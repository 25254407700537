import React, { useState, useEffect, memo, useMemo, forwardRef, useImperativeHandle } from "react";
import { IconButton, Stack } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import CustomizedModal from "../../../components/CustomizedModal";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import BasicSearchTable from "../../OrganisationPlannerPage/plannerTableComponents/SearchTable";
import { ITabInterface } from "./TabInterfaces";
import { getAlumniTasks, markAllDelete, markAllDone, markAsDelete, markAsDone, updateTaskDate } from "../../../firebase/organisationPlannerApi";
import { PlannerTable, plannerDataItemInsideTable } from "../../OrganisationPlannerPage";
import styles from "./../index.module.scss";
import RoundCheckbox from "../../OrganisationPlannerPage/components/RoundCheckbox/RoundCheckbox";
import dayjs from 'dayjs';
import pxToRem from "../../../helpers/pxToRem";
import TableOptionsMenu from "../../OrganisationPlannerPage/components/tableOptionMenu";
import { Dayjs } from 'dayjs';
import CustomizedPaper from "../../../components/CustomizedPaper";
import CustomizedTooltip from "../../../components/CustomizedToolTip";
import OptionsMenu from "../../OrganisationPlannerPage/components/optionMenu";
import { getUserNameById } from "../../../firebase/userApis";
import { Task } from "../../../firebase/types-organisationPlanners";
import ActionSnackbar from "../../../components/SnackBarComponent/CustomizedSnackBar";
import { getCurrentUserId } from "../../../firebase/userApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import StatusIcon from "../../OrganisationPlannerPage/components/RenderIcon";
import ButtonDatePicker from "../../OrganisationPlannerPage/components/calendarButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


type TaskRow = {
  objectID: string;
  title?: string;
  detail?: string;
  date?: number;
  alumniList?: string[];
  createdByUserId?: string;
  assignee?: string;
  isDeleted?: boolean;
  isDone?: boolean;
  shortDetail?: string;
}

interface rowSelectionType {
  [key: string]: boolean;
}

const getStatusByDate = (date: Dayjs | null, isDone: boolean) => {
  const currentDate = dayjs().startOf('day');

  let status = 'Unplanned'; // Default status
  if (isDone) {
    status = 'Done';
  } else {
    if (date?.isValid()) {
      if (date.isAfter(currentDate)) {
        status = 'Planned';
      } else if (date.isBefore(currentDate)) {
        status = 'Overdue';
      } else {
        status = 'Planned';
      }
    }
  }

  return status;
}

interface IBasicSearchTable {
  orgId: string | null | undefined
  orgName: string | null | undefined
  alumniId: string | null | undefined
  setOpenAddPlanner: (state: boolean) => void,
  rowClick: (id: string) => void;
  editPlanner: (id: string) => void;
  addNewRow: () => JSX.Element;
}

interface TaskTabHandles {
  triggerUpdate: () => void;
}

const TaskTab = forwardRef<TaskTabHandles, IBasicSearchTable>(({ orgId, orgName, alumniId, setOpenAddPlanner, rowClick, editPlanner, addNewRow }, ref) => {

  const [tasks, setTasks] = useState<PlannerTable[]>([]); // State to hold tasks
  const columnHelper = createColumnHelper<PlannerTable>();
  const userId = getCurrentUserId();

  const [rowSelection, setRowSelection] = useState<rowSelectionType>({})
  const [userNames, setUserNames] = useState<{ [key: string]: string }>({});
  const [totalRows, setTotalRows] = useState(0);

  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [snackbarInfo, setSnackbarInfo] = useState<{ open: boolean; message: string; undoAction?: () => void }>({ open: false, message: "" });
  const [emptyStateFlag, setEmptyStateFlag] = useState(false);

  const plannerPermission = useSelector((state: RootState) => state.globalState.permissions?.planner);
  const dashData = useSelector((state: RootState) => state.dashboardState);
  const filtered = useMemo(() => {
    return dashData.tasks.filter(task => 
      task.alumniList?.includes(alumniId as string) && !task.isDone && !task.isDeleted
    );
  }, [dashData.tasks, alumniId]);  // Make sure dashData.tasks and alumniId are stable
  

  // Function to show the Snackbar with an undo option
  const showUndoSnackbar = (message: string, undoAction: () => void) => {
    setSnackbarInfo({ open: true, message, undoAction });
  };

  // Function to close the Snackbar
  const closeSnackbar = () => {
    setSnackbarInfo(prev => ({ ...prev, open: false }));
  };

  // Function to delete a task


  useEffect(() => {
    const fetchTasks = () => {
      // Transform the planner data to match the expected structure in plannerDataRows
      const taskRows = filtered.map(planner => ({
        ...planner,
        objectID: planner.id  // Rename 'id' to 'objectID'
      }));

      // Assuming 'date' is in a format that can be directly compared (e.g., ISO 8601 string or timestamp)
      taskRows.sort((a, b) => {
        // Convert date strings to Date objects if they're not already
        return (a.date as number) - (b.date as number);
      });

      setTasks(taskRows);
      if (taskRows.length > 0) {
        setEmptyStateFlag(false);
      }
      setTotalRows(taskRows.length);
    };

    if (alumniId) {
      fetchTasks();
    }
  }, [orgId, alumniId, filtered ]);



  useImperativeHandle(ref, () => ({
    triggerUpdate: () => {
      setUpdateTrigger(prev => !prev);
    }
  }));


  const userIds = useMemo(() => new Set(tasks.map(row => row.assignee).filter(Boolean)), [tasks]);
  // Fetch user names
  useEffect(() => {
    const fetchUserNames = async () => {
      const names: { [key: string]: string } = {};
      for (let userId of Array.from(userIds)) {
        if (!userId) continue; // Skip undefined or null userIds
        const result = await getUserNameById(userId);
        if (result.code === 200 && result.data) {
          names[userId as string] = result.data;
        }
      }
      setUserNames(names);
    };
    fetchUserNames();
  }, [userIds]);

  const toggleAllRowsSelected = () => {
    const newSelection: rowSelectionType = {};
    const isAllSelected = table.getIsAllPageRowsSelected();

    table.getRowModel().rows.forEach((row: { id: string | number; }) => {
      newSelection[row.id] = !isAllSelected;
    });

    setRowSelection(newSelection);
  };

  const columns = [
    columnHelper.accessor('isDelete', {
      cell: info => info.getValue(),
      header: () => {
        const isAllSelected = table.getIsAllPageRowsSelected();
        const isSomeSelected = Object.keys(rowSelection).length > 0 && !isAllSelected;

        return (
          <>
            {emptyStateFlag ? (
              <RoundCheckbox
                id="total_selection"
                checked={isAllSelected}
                indeterminate={isSomeSelected}
                onChange={() => { }}
              />
            ) : (
              <RoundCheckbox
                id="total_selection"
                checked={isAllSelected}
                indeterminate={isSomeSelected}
                onChange={toggleAllRowsSelected}
              />
            )}

          </>
        );
      },
    }),

    columnHelper.accessor('title', { cell: info => info.getValue(), header: "Task" }),
    columnHelper.accessor('date', { cell: info => info.getValue(), header: "Planned" }),
    columnHelper.accessor('isDone', { cell: info => info.getValue(), header: "Status" }),
    columnHelper.accessor('assignee', { cell: info => info.getValue(), header: "Assigned to" }),
  ];

  const viewerColumns = [
    columnHelper.accessor('title', { cell: info => info.getValue(), header: "Task" }),
    columnHelper.accessor('date', { cell: info => info.getValue(), header: "Planned" }),
    columnHelper.accessor('isDone', { cell: info => info.getValue(), header: "Status" }),
    columnHelper.accessor('assignee', { cell: info => info.getValue(), header: "Assigned to" }),
  ];

  const table = useReactTable({
    data: tasks,
    columns: (plannerPermission && plannerPermission === "EDITOR") ? columns : viewerColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row, index) => row.objectID || `row-${index}`,
    onRowSelectionChange: setRowSelection, // Updated to use custom handler
    state: {
      rowSelection
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
  });

  const formatDate = (dateString: number) => {

    const date = dayjs.unix(dateString);
    const today = dayjs();
    if (dateString == 9999999999999) {
      return "No Date"
    } else {
      if (date.format('YYYY-MM-DD') == today.format('YYYY-MM-DD')) {
        return 'Today';
      } else {
        return dayjs.unix(dateString).format('D MMM YY')
      }
    }

  };

  const handleMarkAllTasksAsDone = async () => {
    const selectedTaskIds = Object.keys(rowSelection).filter(key => rowSelection[key]);
    if (selectedTaskIds.length === 0) {
      alert("Please make a selection first");
      return;
    }

    let timeoutId: NodeJS.Timeout;

    const performMarkAllAsDone = async () => {
      try {
        const response = await markAllDone(orgId as string, selectedTaskIds, userId as string);
        if (response.code === 200) {
          setTasks(tasks.filter(task => !selectedTaskIds.includes(task.objectID as string)));
          setTotalRows(totalRows - selectedTaskIds.length);
          setRowSelection({});
        } else {
          alert("Failed to mark all tasks as done. Please try again.");
        }
      } catch (error) {
        console.error("Error marking all tasks as done:", error);
        alert("Failed to mark all tasks as done. Please try again.");
      }
    };

    showUndoSnackbar("All selected tasks marked as done.", () => {
      clearTimeout(timeoutId);
      closeSnackbar();
    });

    timeoutId = setTimeout(() => {
      performMarkAllAsDone();
      closeSnackbar();
    }, 2000);
  };


  const handleMarkAllTasksAsDeleted = async () => {
    const selectedTaskIds = Object.keys(rowSelection).filter(key => rowSelection[key]);
    if (selectedTaskIds.length === 0) {
      alert("Please make a selection first");
      return;
    }

    let timeoutId: NodeJS.Timeout;

    const performMarkAllAsDeleted = async () => {
      try {
        const response = await markAllDelete(orgId as string, selectedTaskIds, userId as string);
        if (response.code === 200) {
          setTasks(tasks.filter(task => !selectedTaskIds.includes(task.objectID as string)));
          setTotalRows(totalRows - selectedTaskIds.length);
          setRowSelection({});
        } else {
          alert("Failed to mark all tasks as deleted. Please try again.");
        }
      } catch (error) {
        console.error("Error marking all tasks as deleted:", error);
        alert("Failed to mark all tasks as deleted. Please try again.");
      }
    };

    showUndoSnackbar("All selected tasks moved to Recycling Bin.", () => {
      clearTimeout(timeoutId);
      closeSnackbar();
    });

    timeoutId = setTimeout(() => {
      performMarkAllAsDeleted();
      closeSnackbar();
    }, 2000);
  };
  
  const handleUpdateTaskDate = async (taskId: string, editPlannedDate: Dayjs | null) => {
		let newDate = editPlannedDate?.isValid() ? editPlannedDate.unix() : 9999999999999;
	
		// Function to actually update the task date
		const performUpdateDate = async () => {
			try {
				const response = await updateTaskDate(orgId as string, taskId, newDate, userId as string);
				if (response.code === 200) {
					setUpdateTrigger(prev=> !prev)
				} else {
					alert("Failed to update task date. Please try again.");
				}
			} catch (error) {
				console.error("Error updating task date:", error);
				alert("Failed to update task date. Please try again.");
			}
		};
	
	
		performUpdateDate();

	};


  const handleMarkTaskAsDone = async (taskId: string) => {
    let timeoutId: NodeJS.Timeout;

    const performMarkAsDone = async () => {
      try {
        const response = await markAsDone(orgId as string, taskId, userId as string);
        if (response.code === 200) {
          setTasks(prevTasks => prevTasks.filter(task => task.objectID !== taskId));
          setTotalRows(prevTotalRows => prevTotalRows - 1);
        } else {
          alert("Failed to mark task as done. Please try again.");
        }
      } catch (error) {
        console.error("Error marking task as done:", error);
        alert("Failed to mark task as done. Please try again.");
      }
    };

    // Show the Snackbar with an undo option
    showUndoSnackbar("Task marked as done.", () => {
      clearTimeout(timeoutId);
      // Here, you might want to add logic to revert the task status if necessary
      closeSnackbar();
    });

    timeoutId = setTimeout(() => {
      performMarkAsDone();
      closeSnackbar();
    }, 2000); // Delay of 3 seconds
  };


  const handleMarkTaskAsDeleted = async (taskId: string) => {
    let timeoutId: NodeJS.Timeout;

    const performMarkAsDeleted = async () => {
      try {
        const response = await markAsDelete(orgId as string, taskId, userId as string);
        if (response.code === 200) {
          setTasks(prevTasks => prevTasks.filter(task => task.objectID !== taskId));
          setTotalRows(prevTotalRows => prevTotalRows - 1);
        } else {
          alert("Failed to mark task as deleted. Please try again.");
        }
      } catch (error) {
        console.error("Error marking task as deleted:", error);
        alert("Failed to mark task as deleted. Please try again.");
      }
    };

    // Show the Snackbar with an undo option
    showUndoSnackbar("Moved to Recycling Bin.", () => {
      clearTimeout(timeoutId);
      // Here, you might want to add logic to revert the deletion if necessary
      closeSnackbar();
    });

    timeoutId = setTimeout(() => {
      performMarkAsDeleted();
      closeSnackbar();
    }, 2000); // Delay of 3 seconds
  };


  const CustomPaginationFooter = () => {
    const currentIndex = table.getState().pagination.pageIndex + 1;
    const totalPageCount = table.getPageCount();
    const pageSize = table.getState().pagination.pageSize;

    const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
    const endItem = currentIndex === totalPageCount ? totalRows : currentIndex * pageSize;

    return (
      <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
        <Stack direction={"row"} style={{ padding: 4, alignItems: 'center' }}>
          <Stack className={styles.datagrid_footer_text} alignItems={"center"}> {`${startItem} - ${endItem} of ${totalRows}`} </Stack>
          <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
          <IconButton disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>
        </Stack>
      </Stack>
    );
  }

  const emptyTableRow = (): JSX.Element => {
    setTasks(emptyDataRow)
    setEmptyStateFlag(true)
    return (
      <div>
      </div>
    )
  }

  const emptyDataRow = [
    {
      title: "Your tasks will appear here",
      shortDetail: "Details of your task will appear here",
    }
  ]


  return (
    <Stack>


      <Stack style={{ marginBottom: pxToRem(16) }}>
        <Stack direction={"row"} spacing={2} style={{ alignItems: 'center', justifyContent: "start" }}>
          <Stack direction={"column"} spacing={2} style={{ marginLeft: pxToRem(4) }} width={"100%"}>
            <h6 className='regular'>Upcoming Tasks</h6>
            <small className={`xssSpace ${styles["text--neutrals500"]}`} style={{ marginTop: pxToRem(4), marginBottom: pxToRem(4) }}>  View and manage upcoming tasks assigned to this alumni  </small>
            <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
              {(plannerPermission && plannerPermission === "EDITOR") && <PrimaryButton style={{ width: pxToRem(132), height: pxToRem(32) }} onClick={() => setOpenAddPlanner(true)}>New Task</PrimaryButton>}
              <Stack direction={"row"} style={{ alignItems: 'center', position: 'relative' }}>
                {(plannerPermission && plannerPermission === "EDITOR") &&
                  <OptionsMenu
                    onMarkComplete={() => {
                      handleMarkAllTasksAsDone()
                    }}
                    onDelete={() => {
                      handleMarkAllTasksAsDeleted()
                    }}
                    isDisabled={Object.values(rowSelection).every(value => value === false)}
                  />}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* Display tasks */}
      <Stack>
        <>
          <Stack spacing={0} className={styles.tableWrapper}>
            <table className={styles.alumni_task_table}>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} style={{ textAlign: 'left' }}>
                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                          <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                          </Stack>
                          {header.column.id === 'date' &&
                            (
                              <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                              </Stack>
                            )}
                        </Stack>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  // status === "loading" || status === "stalled"?
                  //     <RowSkeleton />
                  // :
                  table.getRowModel().rows.map(row => (
                    <tr key={row.id} onClick={() => { if (totalRows !== 0) { rowClick(row.original.objectID ?? "") } }} className={rowSelection[row.id] ? styles.selectedRow : styles.normalRow}>
                      {
                        row.getVisibleCells().map(cell => {
                          let cellJSX: JSX.Element | undefined = undefined;
                          if (cell.column.id === "title") {
                            cellJSX = <Stack key={cell.id} className={styles.task}>{plannerDataItemInsideTable(row.original.title ?? "", row.original.shortDetail ?? "")}</Stack>;
                          }


                          else if (cell.column.id === "isDone" && totalRows !== 0) {
                            // const status = row.original.status;
                            const status = getStatusByDate(row.original.date ? dayjs.unix(row.original.date as number) : null, row.original.isDone as boolean);
                            cellJSX = <div onClick={(e) => e.stopPropagation()}> <StatusIcon disable={!(plannerPermission && plannerPermission === "EDITOR")}status={status} onMarkComplete={() => {handleMarkTaskAsDone(row.id)}}></StatusIcon></div>

                          }
                          else if (cell.column.id === "date" && totalRows !== 0) {
                            cellJSX = <Stack key={cell.id}  onClick={(e) => e.stopPropagation()} className={styles.text} direction={"row"} style={{ alignItems: "center" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
																<ButtonDatePicker
																	label={row.original.date == null ? null : formatDate(row.original.date as number)}
																	value={dayjs.unix(row.original.date as number)}
																	onChange={(newValue) => handleUpdateTaskDate(row.id, newValue)}
                                  disabled={!(plannerPermission && plannerPermission === "EDITOR")}
																/>
															</LocalizationProvider>
                              <small >
                                {formatDate(row.original.date as number) || "No Date"}
                              </small>
                            </Stack>;
                          }
                          else if (cell.column.id === "assignee" && totalRows !== 0) {
                            cellJSX = <Stack key={cell.id} className={styles.buttonBarWrapper} direction={"row"} style={{ alignItems: "center" }} onClick={(e) => e.stopPropagation()}>
                              <small>
                                {userNames[row.original.assignee as string] || "-"}
                              </small>
                              <Stack style={{ alignItems: "end" }}>
                                {(plannerPermission && plannerPermission === "EDITOR") &&
                                  <TableOptionsMenu
                                    onMarkComplete={() => {
                                      handleMarkTaskAsDone(row.id)
                                    }}
                                    onEdit={() => {
                                      editPlanner(row.id);
                                    }}
                                    onDelete={() => {
                                      handleMarkTaskAsDeleted(row.id)
                                    }} />
                                }

                              </Stack>
                            </Stack>;
                          } else if (cell.column.id === "isDelete") {
                            cellJSX = (
                              <div onClick={(e) => e.stopPropagation()} style={{ width: pxToRem(30) }}>
                                {emptyStateFlag ? (<RoundCheckbox
                                  id={"checkbox" + row.id}
                                  checked={false}
                                  onChange={() => { }}
                                />) : (<RoundCheckbox
                                  id={"checkbox" + row.id}
                                  checked={row.getIsSelected()}
                                  onChange={row.getToggleSelectedHandler()}
                                />)}

                              </div>
                            );

                          }
                          return (<td key={cell.id}> {cellJSX} </td>)
                        })
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {tasks.length <= 0 ? emptyTableRow() : null}
          </Stack>
          {tasks.length <= 0 ? null : CustomPaginationFooter()}
          <ActionSnackbar
            open={snackbarInfo.open}
            message={snackbarInfo.message}
            onClose={closeSnackbar}
            onUndo={() => {
              snackbarInfo.undoAction?.();
            }}
          />
        </>
      </Stack>
    </Stack>
  );
});

export default memo(TaskTab);