import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Form } from "../firebase/types-registration";
import { Task } from "../firebase/types-organisationPlanners";
import { OrgWeeklyGoals, OrgWeeklyGoalsProgress, WeeklyGoalType } from "../firebase/types-weeklyGoals";
import { Engagement } from "../firebase/types-engagement";


interface IWeeklyGoalState {
    monthSelected?: number;
    weekSelected?: number;
}
interface IDashboardStateSlice {
    forms: Form[];
    tasks: Task[];
    engagements: Engagement[],

    weeklyGoalState: IWeeklyGoalState;
    goals: WeeklyGoalType[];
    progress: OrgWeeklyGoals[];
}

const INITIAL_STATE: IDashboardStateSlice = {
    forms: [],
    tasks: [],
    engagements: [],

    weeklyGoalState: {},
    goals: [],
    progress: []
}

export const dashboardStateSlice = createSlice({
    name: "dashboardStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setDashboardDefault: (state) => {
            state.forms = INITIAL_STATE.forms;
            state.tasks = INITIAL_STATE.tasks;
            state.weeklyGoalState = INITIAL_STATE.weeklyGoalState;
            state.progress = INITIAL_STATE.progress;
            state.goals = INITIAL_STATE.goals;
        },
        setForms: (state, action: PayloadAction<Form[]>) => {
            state.forms = action.payload;
        },
        addForm: (state, action: PayloadAction<Form>) => {
            if (!state.forms.some(item=> item.formId === action.payload.formId)){
                state.forms.push(action.payload);
            }
        },
        updateForm: (state, action: PayloadAction<Form>) => {
            const index = state.forms.findIndex(item => item.formId === action.payload.formId);
            if (index !== undefined && index !== -1 && state.forms) {
                state.forms[index] = action.payload;
            }
        },
        removeForm: (state, action: PayloadAction<string>) => {
            state.forms = state.forms.filter(item => item.formId !== action.payload);
        },
        setTasks: (state, action: PayloadAction<Task[]>) => {
            state.tasks = action.payload;
        },
        addTask: (state, action: PayloadAction<Task>) => {
            if (!state.tasks.some(item=> item.id === action.payload.id)){
                state.tasks.push(action.payload);
            }
        },
        updateTask: (state, action: PayloadAction<Task>) => {
            const index = state.tasks.findIndex(item => item.id === action.payload.id);
            if (index !== undefined && index !== -1 && state.tasks) {
                state.tasks[index] = action.payload;
            }
        },
        setEngagements: (state, action: PayloadAction<Engagement[]>) => {
            state.engagements = action.payload;
        },
        addEngagement: (state, action: PayloadAction<Engagement>) => {
            state.engagements.push(action.payload);
        },
        updateEngagement: (state, action: PayloadAction<Engagement>) => {
            const index = state.engagements.findIndex(eng => eng.id === action.payload.id);
            if (index !== -1) {
                state.engagements[index] = action.payload;
            }
        },
        removeEngagement: (state, action: PayloadAction<string>) => {
            state.engagements = state.engagements.filter(eng => eng.id !== action.payload);
        },
        removeTask: (state, action: PayloadAction<string>) => {
            state.tasks = state.tasks.filter(item => item.id !== action.payload);
        },
        setWeeklyGoalsDefault: (state) => {
            state.weeklyGoalState = {};
        },
        selectMonth: (state, action: PayloadAction<number>) => {
            state.weeklyGoalState.monthSelected = action.payload;
        },
        selectWeek: (state, action: PayloadAction<number|undefined>) => {
            state.weeklyGoalState.weekSelected = action.payload;
        },
        addGoal: (state, action: PayloadAction<WeeklyGoalType>) => {
            if (!state.goals.some(item=> item.code === action.payload.code)){
                state.goals.push(action.payload);
            }
        },
        addOrgWeeklyGoalsProgress: (state, action: PayloadAction<OrgWeeklyGoals>) => {
            if (!state.progress.some(item=> item.code === action.payload.code)){
                state.progress.push(action.payload);
            }
        },
        updateOrgWeeklyGoalsProgress: (state, action: PayloadAction<OrgWeeklyGoals>) => {
            const index = state.progress.findIndex(item => item.code === action.payload.code);
            if (index !== undefined && index !== -1 && state.progress) {
                state.progress[index] = action.payload;
            }
        },
        removeOrgWeeklyGoalsProgress: (state, action: PayloadAction<string>) => {
            state.progress.filter(item => item.code !== action.payload);
        },
        resetWeeklyGoalsState: (state) => {
            state.weeklyGoalState = INITIAL_STATE.weeklyGoalState;
        }
    }
});

export const {
    setDashboardDefault,
    setForms,
    addForm,
    updateForm,
    removeForm,
    setTasks,
    addTask,
    updateTask,
    removeTask,
    setWeeklyGoalsDefault,
    selectMonth,
    selectWeek,
    addGoal,
    addOrgWeeklyGoalsProgress,
    updateOrgWeeklyGoalsProgress,
    removeOrgWeeklyGoalsProgress,
    resetWeeklyGoalsState
} = dashboardStateSlice.actions;
export default dashboardStateSlice.reducer;