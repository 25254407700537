import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHits, usePagination, useSearchBox, useInstantSearch } from 'react-instantsearch';
import pxToRem from "../../../helpers/pxToRem";
import { AlumniTable } from "../../AlumniPage";
import { joinStringWithComma, joinStringWithSpace } from "../../../helpers/utils";
import algoliasearch from "algoliasearch";
import { stringToColor } from "./colorHelper";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import MultilineTextField from "../../../components/Inputs/TextFields/MultilineInputTextField";
import AlumniContextMenu from "./AlumniContextMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { combinedDataSelector } from "../../../redux/reselect";

// Assuming alumniData is defined and contains the necessary data


interface AlumniSearchBarProps {
  onSelectionChange: (selected: AlumniTable[]) => void;
  setAlumniIds?: string[];
  orgId: string;
}

interface OptionDetailsProps {
  option: {
    type?: string;
    currentEmployment?: string;
    peerYear?: string | number; // Allow both string and number types
  };
}

export const DisplayOptionDetails: React.FC<OptionDetailsProps> = ({ option }) => {
  // Collect the pieces of information if they exist, converting numbers to strings where necessary
  const details = [
    option.type && `${option.type}`,
    option.currentEmployment && `${option.currentEmployment}`,
    option.peerYear !== undefined && `Peer Year: ${option.peerYear.toString()}` // Ensure peerYear is converted to a string
  ].filter(Boolean); // Remove any undefined or false values

  // Join the details with " • " only if more than one detail exists
  const detailsText = details.length > 1 ? details.join(' • ') : details[0] || '';

  return (
    <small style={{ color: "#737373" }}>{detailsText}</small>
  );
}

function AlumniSearchBar({ onSelectionChange, setAlumniIds, orgId }: AlumniSearchBarProps) {
  // const alumniData = useSelector((state: RootState) => state.alumniDataState.alumniList);

  const combineData = useSelector(combinedDataSelector);
  const [selectedAlumni, setSelectedAlumni] = useState<AlumniTable[]>([]);
  const [showAllSelected, setShowAllSelected] = useState(false);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; alumniId: string; type: string} | null>(null);
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value.toLowerCase());
  };


  // Function to toggle the display of all selected alumni
  const toggleShowAll = () => setShowAllSelected(!showAllSelected);


  // Filtered alumni based on search input with simple fuzzy logic
  const filteredAlumni = combineData.filter(alumni => {
    const normalizedSearchInput = searchInput.toLowerCase().split(/\s+/);

    const containsAllWords = (str: string | undefined) => {
      const normalizedStr = str?.toLowerCase() || "";
      const result = normalizedSearchInput.every(word => normalizedStr.includes(word));
      return result;
    };

    // Debug each field specifically
    const isMatch = containsAllWords(alumni.title) ||
      containsAllWords(alumni.firstName) ||
      containsAllWords(alumni.lastName) ||
      containsAllWords(alumni.name) ||
      containsAllWords(alumni.emailPreferred) ||
      containsAllWords(alumni.currentEmployment) ||
      containsAllWords(alumni.educationDegree) ||
      containsAllWords(alumni.living) ||
      containsAllWords(alumni.peerYear?.toString());
    return isMatch;
  });

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>, alumniId: string, type: string) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      type,
      alumniId,
    });
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // Inside your component:
  useEffect(() => {
    if (setAlumniIds) {
      const preSelectedAlumni = combineData.filter(alumni =>
        setAlumniIds.includes(alumni.objectID as string)
      );
      setSelectedAlumni(preSelectedAlumni);
      onSelectionChange(preSelectedAlumni);
    }
  }, [setAlumniIds]);

  const handleDelete = (alumniToDelete: AlumniTable) => () => {
    const newSelectedAlumni = selectedAlumni.filter(
      (alumni) => alumni.objectID !== alumniToDelete.objectID
    );
    setSelectedAlumni(newSelectedAlumni);
    onSelectionChange(newSelectedAlumni);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        id="alumni-search-bar"
        options={filteredAlumni}
        getOptionLabel={(option) => `${option.name} - ${option.educationDegree}, ${option.peerYear}, ${option.objectID}`}
        isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
        renderInput={(params) => (
          <MultilineTextField
            {...params}
            placeholder="Search alumni or network"
            onChange={handleSearchChange}
            InputProps={{
              ...params.InputProps,
              style: { fontFamily: 'Open Sans', fontSize: pxToRem(14), height: pxToRem(40) },
              startAdornment: (
                <>
                  <InputAdornment position="start" style={{ marginLeft: pxToRem(16) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(26)} height={pxToRem(27)} viewBox="0 0 24 25" fill="none"> <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              onKeyDown: (event) => {
                // Block deletion of items when the input field is empty
                if (event.key === 'Backspace' && params.inputProps.value === '') {
                  event.stopPropagation();
                }
              }
            }}
          />
        )}
        value={selectedAlumni}
        onChange={(event, newValue, reason) => {
          if (reason === 'selectOption') {
            // Get the last selected item (the new addition)
            const newOption = newValue[newValue.length - 1];

            // Check if the new option is already in the selectedAlumni
            const isDuplicate = selectedAlumni.some(
              (alumni) => alumni.objectID === newOption.objectID
            );

            // If not a duplicate, add it to the selectedAlumni
            if (!isDuplicate) {
              setSelectedAlumni([...selectedAlumni, newOption]);
              onSelectionChange([...selectedAlumni, newOption]);
            } else {
              // If it's a duplicate, just set the previous state
              setSelectedAlumni([...selectedAlumni]);
              onSelectionChange([...selectedAlumni]);
            }
          } else {
            // For other reasons like 'removeOption', 'clear', etc.
            setSelectedAlumni(newValue);
            onSelectionChange(newValue);
          }
        }}
        renderTags={() => null}
        renderOption={(props, option) => {
          const isSelected = selectedAlumni.some(alumni => alumni.objectID === option.objectID);
          return (
            <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: pxToRem(12),
                  marginBottom: pxToRem(12),
                  height: pxToRem(40)
                }}>
                  <small>{option.name}</small>
                  <DisplayOptionDetails option={option} />                
                  </Box>
              </Box>
              <small style={{ marginLeft: 'auto', color: "#737373" }}>{isSelected ? "Remove" : "Select"}</small>
            </li>
          )
        }}
        ListboxProps={
          {
            style:{
                maxHeight: pxToRem(240),
            }
          }
        }
      />
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {(showAllSelected ? selectedAlumni : selectedAlumni.slice(0, 6)).map((alumni, index) => (
          <Grid item key={alumni.objectID} sx={{ position: 'relative', width: pxToRem(69), height: pxToRem(87), display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ position: 'relative', width: pxToRem(48), height: pxToRem(48) }} onContextMenu={(e) => handleContextMenu(e, alumni.objectID as string, alumni.type as string)}>
              {alumni.photoUrl ? (
                <Avatar alt={alumni.name} src={alumni.photoUrl} sx={{ width: '100%', height: '100%' }} />

              ) : (
                <Avatar alt={alumni.name} sx={{ width: '100%', height: '100%', bgcolor: stringToColor(alumni.firstName as string) }} >{(alumni.firstName as string).charAt(0) + (alumni.lastName as string).charAt(0)}</Avatar>

              )
              }
              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: '-8px', // Adjust these values as needed
                  right: '-8px',
                  zIndex: 1,
                  backgroundColor: 'background.paper',
                  '&:hover': {
                    backgroundColor: 'background.paper',
                  },
                }}
                onClick={handleDelete(alumni)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="caption" sx={{ fontSize: pxToRem(12), maxWidth: pxToRem(60), mt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {alumni.name}
            </Typography>

            {contextMenu && (
              <AlumniContextMenu
                mouseX={contextMenu.mouseX}
                mouseY={contextMenu.mouseY}
                orgId={orgId}
                alumniId={contextMenu.alumniId}
                open={Boolean(contextMenu)}
                type={contextMenu.type.toLowerCase()}
                onClose={handleClose}
              />
            )}
          </Grid>
        ))}

        {selectedAlumni.length > 6 && (
          <Grid item>
            <Box onClick={toggleShowAll}>
              <svg width={pxToRem(48)} height={pxToRem(48)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F5F5F5" />
                <circle cx="12" cy="20" r="2" fill="#737373" />
                <circle cx="20" cy="20" r="2" fill="#737373" />
                <circle cx="28" cy="20" r="2" fill="#737373" />
              </svg>


            </Box>
            <Typography variant="caption" sx={{ fontSize: pxToRem(12), maxWidth: pxToRem(60), mt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {showAllSelected ? "See Less" : "See More"}</Typography>
          </Grid>
        )}
      </Grid>


    </Box>
  );
}

export default AlumniSearchBar;