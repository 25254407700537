import React, { memo } from 'react';
import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Avatar, Box, Breadcrumbs, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography, TextField, TextareaAutosize, Icon, Grid } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Unsubscribe, doc, onSnapshot, serverTimestamp, FieldValue, deleteField, getDoc } from "firebase/firestore";
import { getCurrentUserId } from '../../firebase/userApi';
import { RootState } from '../../redux';
import algoliasearch from 'algoliasearch/lite';
import Line from '../../components/Line/Line';
import pxToRem from '../../helpers/pxToRem';
import CustomizedPaper from '../../components/CustomizedPaper';
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import useTextInput from '../../hooks/useTextInput';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { getErrorMsgForTitle } from '../../helpers/inputValidators';
import { joinStringWithSpace } from '../../helpers/utils';
import { BetaButton, PrimaryButton, SecondaryButton } from '../../components/Buttons/ButtonUI';
import { Network, getNetwork } from '../../firebase/types-network';
import { db } from '../../firebase';
import { URL_PARAMS } from '../../routers/shared';
import { betaStateAction } from '../../redux/betaSlice';
import MediaCard from './components/MediaCard';
import URLSTab from './URLSTab';
import FilesTab from './FilesTab';
import { fetchCounts, fetchUrlCount } from '../../firebase/networkMediaApis';
import { url } from 'inspector';
import PhotosTab from './photosTab';
import { getOrgById } from '../../firebase/organisationApis';

export const UNLIMITED_SIZE = 1000;
export const PAGINATION_PERPAGEROWS = 10;

const NetworkMediaPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = getCurrentUserId();
    const organisation = useSelector((state: RootState) => state.organisation);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const networkList = useSelector((state: RootState) => state.networkDataState.networkList);

    const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
    const [networkId, setNetworkId] = useState<string | null | undefined>(undefined);
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [urlCount, setUrlCount] = useState(0);
    const [fileCount, setFileCount] = useState(0);
    const [photoCount, setPhotoCount] = useState(0);
    const permissions = useSelector((state: RootState) => state.globalState.permissions);

    const networkData = useSelector((state: RootState) =>
        state.networkDataState.networkList.find(network => network.objectID === networkId)
    );
    
    const [selectedMedia, setSelectedMedia] = useState<string | null>(null);

    const [firstNameStr, setFirstNameStr] = useState("");
    const [lastNameStr, setLastNameStr] = useState("")

    const handleMediaSelect = (mediaType: string) => {
        setSelectedMedia(mediaType);
    };

    useEffect(() => {
        if (!params.orgId || !params.networkId) {
            console.error("Invalid organization or Network ID.");
            return;
        }

        // Set initial org and network IDs from params
        setOrgId(params.orgId);
        setNetworkId(params.networkId);

        // Find the engagement in the Redux store
        const foundNetwork = networkList.find(e => e.objectID === params.networkId);

        if (!orgName) {
            const fetchOrgName = async () => {
                const foundOrg = await getOrgById(params.orgId as string);
                if (foundOrg.code === 200) {
                    setOrgName(foundOrg.data);
                }
            }
            fetchOrgName()
        }
    
        if (foundNetwork) {
            setFirstNameStr(foundNetwork.firstName ?? "");
            setLastNameStr(foundNetwork.lastName ?? "");
            
            // Fetch the count of URLs
            const fetchAndSetCounts = async () => {
                const result = await fetchCounts(params.orgId as string, params.networkId as string);
                if (result.code === 200) {
                    setUrlCount(result.data.urlsCount ?? 0);
                    setPhotoCount(result.data.photosCount ?? 0);
                    setFileCount(result.data.filesCount ?? 0);
                    console.log("URLs:", result.data.urlsCount, "Photos:", result.data.photosCount, "Files:", result.data.filesCount);
                } else {
                    setErrorMsg("Failed to fetch counts");
                }
            };
    
            fetchAndSetCounts();
        } else {
            navigate(`/${URL_PARAMS.ERROR404}`);
        }
    }, [params.orgId, params.networkId, networkList, selectedMedia]);



    return (
        <div>
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/network`)}><span className={styles.breadcrumbsLink}>Network</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/network/${networkId}`)}><span>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span></Stack>
                    <Stack><span>Media</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => { if (orgState === 'REGULAR') { navigate('/pricing') } else { dispatch(betaStateAction.setBetaState(true)) } }} />
            </Stack>
            {selectedMedia === null && (
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    <Stack className={styles.headerBar} direction={'row'} spacing={pxToRem(12)}>
                        <Stack>
                            <IconButton onClick={() => navigate(`/dashboard/organisation/${orgId}/network/${networkId}`)}>
                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </IconButton>
                        </Stack>
                        <Stack direction={'column'} spacing={1}>
                            <h6 className={styles.headerTitle}>
                                Media
                            </h6>
                            <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                        </Stack>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`}></div>

                    <Stack className={styles.photoContainer} gap={pxToRem(4)}>
                        <h5>
                            Profile Photos
                        </h5>
                        <span className={styles.headerText}>{'Store profile photos of your network here '}</span>
                    <PhotosTab orgId={orgId} orgName={orgName} networkId={networkId}/>
                    </Stack>

                    <Grid item xs={12}>
                        <Stack className={styles.mediaContainer}>
                            <Grid container direction="column" spacing={pxToRem(24)} maxWidth={'50%'}>
                                {/* <Grid item xs={6} style={{ flexGrow: 1 }}>
                                    <MediaCard name={"Profile Photos"} num={photoCount + (networkData?.photoUrl ? 1 : 0)} backgroundColor={styles.primary400} onClick={() => handleMediaSelect('profilePhotos')}
                                        icon={<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3919 55.2744L28.9827 37.6837C30.0387 36.6276 30.5668 36.0996 31.1756 35.9018C31.7112 35.7277 32.2881 35.7277 32.8237 35.9018C33.4326 36.0996 33.9606 36.6276 35.0167 37.6837L52.4901 55.1571M37.333 40L44.9827 32.3503C46.0387 31.2943 46.5668 30.7663 47.1756 30.5684C47.7112 30.3944 48.2881 30.3944 48.8237 30.5684C49.4326 30.7663 49.9606 31.2943 51.0167 32.3503L58.6663 40M26.6663 24C26.6663 26.9455 24.2785 29.3333 21.333 29.3333C18.3875 29.3333 15.9997 26.9455 15.9997 24C15.9997 21.0545 18.3875 18.6667 21.333 18.6667C24.2785 18.6667 26.6663 21.0545 26.6663 24ZM18.133 56H45.8663C50.3468 56 52.587 56 54.2983 55.1281C55.8036 54.3611 57.0274 53.1372 57.7944 51.6319C58.6663 49.9206 58.6663 47.6804 58.6663 43.2V20.8C58.6663 16.3196 58.6663 14.0794 57.7944 12.3681C57.0274 10.8628 55.8036 9.63893 54.2983 8.87195C52.587 8 50.3468 8 45.8663 8H18.133C13.6526 8 11.4124 8 9.70108 8.87195C8.19579 9.63893 6.97194 10.8628 6.20496 12.3681C5.33301 14.0794 5.33301 16.3196 5.33301 20.8V43.2C5.33301 47.6804 5.33301 49.9206 6.20496 51.6319C6.97194 53.1372 8.19579 54.3611 9.70108 55.1281C11.4124 56 13.6526 56 18.133 56Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        } />
                                </Grid> */}
                                <Grid item xs={6} style={{ flexGrow: 1 }}>
                                    <MediaCard name={"Files"} num={fileCount} backgroundColor={styles.primary400} onClick={() => handleMediaSelect('files')}
                                        icon={<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M34.6663 18.6667L31.6916 12.7171C30.8354 11.0048 30.4073 10.1486 29.7687 9.5231C29.2039 8.96995 28.5232 8.54926 27.7759 8.29149C26.9308 8 25.9736 8 24.0591 8H13.8663C10.8794 8 9.38592 8 8.24506 8.5813C7.24153 9.09262 6.42563 9.90852 5.91431 10.9121C5.33301 12.0529 5.33301 13.5464 5.33301 16.5333V18.6667M5.33301 18.6667H45.8663C50.3468 18.6667 52.587 18.6667 54.2983 19.5386C55.8036 20.3056 57.0274 21.5294 57.7944 23.0347C58.6663 24.746 58.6663 26.9862 58.6663 31.4667V43.2C58.6663 47.6804 58.6663 49.9206 57.7944 51.6319C57.0274 53.1372 55.8036 54.3611 54.2983 55.1281C52.587 56 50.3468 56 45.8663 56H18.133C13.6526 56 11.4124 56 9.70108 55.1281C8.19579 54.3611 6.97194 53.1372 6.20496 51.6319C5.33301 49.9206 5.33301 47.6804 5.33301 43.2V18.6667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        } />
                                </Grid>
                                <Grid item xs={6} style={{ flexGrow: 1 }}>
                                    <MediaCard name={"URLS"} num={urlCount} backgroundColor={styles.primary500} onClick={() => handleMediaSelect('urls')}
                                        icon={<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9997 18.6666H18.6663C11.3025 18.6666 5.33301 24.6362 5.33301 32C5.33301 39.3638 11.3025 45.3333 18.6663 45.3333H23.9997C31.3635 45.3333 37.333 39.3638 37.333 32M43.9997 45.3333H45.333C52.6968 45.3333 58.6663 39.3638 58.6663 32C58.6663 24.6362 52.6968 18.6666 45.333 18.6666H39.9997C32.6359 18.6666 26.6663 24.6362 26.6663 32" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        } />
                                </Grid>

                            </Grid>
                        </Stack>
                    </Grid>
                </Stack>
            </CustomizedPaper>)}

            {selectedMedia === 'profilePhotos' && (
                <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                    <Stack className={styles.content}>
                        <Stack className={styles.headerBar} direction={'row'} spacing={1}>
                            <Stack>
                                <IconButton onClick={()=>setSelectedMedia(null)}>
                                    <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                <h6 className={styles.headerTitle}>
                                    Photos
                                </h6>
                                <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                            </Stack>

                        </Stack>
                        <div className={`${styles.divider} horizontalDivider`}></div>
                        <PhotosTab orgId={orgId} orgName={orgName} networkId={networkId}/>
                    </Stack>
                </CustomizedPaper>
            )}

            {selectedMedia === 'files' && (
                <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                    <Stack className={styles.content}>
                        <Stack className={styles.headerBar} direction={'row'} spacing={1}>
                            <Stack>
                                <IconButton onClick={()=>setSelectedMedia(null)}>
                                    <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                <h6 className={styles.headerTitle}>
                                    Files
                                </h6>
                                <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                            </Stack>

                        </Stack>
                        <div className={`${styles.divider} horizontalDivider`}></div>
                        <FilesTab orgId={orgId} orgName={orgName} networkId={networkId}/>


                    </Stack>
                </CustomizedPaper>
            )}

            {selectedMedia === 'urls' && (
                <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                    <Stack className={styles.content}>
                        <Stack className={styles.headerBar} direction={'row'} spacing={1}>
                            <Stack>
                                <IconButton onClick={()=>setSelectedMedia(null)}>
                                    <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                <h6 className={styles.headerTitle}>
                                    URLS
                                </h6>
                                <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                            </Stack>

                        </Stack>
                        <div className={`${styles.divider} horizontalDivider`}></div>
                        <URLSTab orgId={orgId} orgName={orgName} networkId={networkId}/>

                    </Stack>
                </CustomizedPaper>
            )}
        </div>
    );
}

export default NetworkMediaPage;