export const URL_PARAMS = {
    //Start Page
    LOGIN: "login",
    FORGETPASSWORD: "forget-password",
    SIGNUP: "register",
    VERIFY_EMAIL: "verify-email",
    BETA_CREATE_NEW_PASSWORD:"update-password",
    ONBOARDING: "onboarding",

    //Dashboard
    DASHBOARD: "dashboard",
    ALUMNI: "alumni",
    TEAM: "team",
    PLANNER: "planner",
    EVENTS: "events",
    PHILANTHROPY: "philanthropy",
    ANALYTICS: "analytics",
    ORGANISATION: "organisation",
    ACCOUNT_SETTINGS: "account-settings",
    ORGANISATION_SETTINGS: "organisation-settings",
    ALUMNI_DETAILS: "alumni-details",
    SELF_REGISTRATION_FORMS: "forms", 
    BILLING: "billing",
    ENGAGEMENT: "engagement",
    NETWORK: "network",

    //Error
    ERROR404: "page-not-found",

    //Landing Page
    LANDING: 'landing',
    HOME: 'home',
    LEARN_MORE: 'learn-more',
    ABOUT: 'about',
    CONTACT_US: 'contact-us',
    PRICING: 'pricing',

    // Self Registration
    REGISTER: 'self-register',

    LINKEDIN: 'https://au.linkedin.com/company/loop-alumni',
    INSTAGRAM: 'https://www.instagram.com/loopalumni/',
    FACEBOOK: 'https://www.facebook.com/LoopAlumni',

    IMPORT: 'import',
};


export interface RouteStore {
    [routeName: string]: JSX.Element;
}