import { FieldValue } from "firebase/firestore";
import { URL_PARAMS } from "../../routers/shared";

export const NON_SORTABLE_COLS = ['link', 'qrCode']

export interface FormsData {
  id: string;
  name: string;
  link: string;
  qrCode: string;
  totalCount: number;
  pendingApproval: number;
  createdAt: string;
  lastEdited: string;
  lastSubmission: string;
  closedAt: string;
}

export type FormDetails = {
  name: string;
  createdAt: string;
  pendingApproval: number;
  lastEdited: string;
  lastSubmission: string;
  totalCount: number;
  link: string;
}

export interface ResponseData {
  id: string;
  name: RespondentDetails;
  gender: string;
  schooling: string;
  peerYear: string;
  enrolled: string;
  interests: string;
  skills: string;
}

export interface ApprovedData {
  id: string;
  name: ApprovedAlumniName;
  currentEmployment: string;
  living: string;
  peerYear: string;
  approvedAt?: number | FieldValue;
}

export type ApprovedAlumniName = {
  icon: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type RespondentDetails = {
  title?: string;
  firstName: string;
  lastName: string;
  pronouns?: string;
  email: string;
  contactNumber?: string;
  linkedIn?: string;
}

export function createData(
  id: string,
  name: string,
  link: string,
  qrCode: string,
  totalCount: number,
  pendingApproval: number,
  createdAt: string,
  closedAt: string,
  lastEdited: string,
  lastSubmission: string,
): FormsData {
  return {
    id,
    name,
    link,
    qrCode,
    totalCount,
    pendingApproval,
    createdAt,
    lastEdited,
    lastSubmission,
    closedAt,
  };
}

export const formatFormURL = (link:string) => {
  return `${window.location.origin}/${URL_PARAMS.REGISTER}/`+link;
}

export const formatName = (firstName:string, lastName:string, title?:string, pronouns?:string) => {
  var resultStr = "";
  if(title) {
      resultStr = title + " ";
  }
  resultStr += firstName + " " + lastName;
  if(pronouns) {
      resultStr += " (" + pronouns + ")"
  }
  return resultStr;
}

export const formatDetails = (details: RespondentDetails) => {
  var resultStr = details.email;
  if(details.contactNumber) {
      resultStr += " | "+details.contactNumber;
  }
  if(details.linkedIn) {
      resultStr += " | "+details.linkedIn;
  }
  return resultStr;
}

export const handleLinkClick = (e:any, val: any) => {
  e.stopPropagation();
  navigator.clipboard.writeText(formatFormURL(val));
}

export const handleQrCodeClick = (e:any, val: any, title: string) => {
  e.stopPropagation();
  const canvas = document.getElementById(val) as HTMLCanvasElement;
  if(canvas) {
      const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${title}_qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  }
}