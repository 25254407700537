import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Form } from "../firebase/types-registration";

interface IFormsStateSlice {
    detailsChanged?: boolean | null | undefined;
    isChanged?: boolean | null | undefined;
    isApproved: boolean | null | undefined;
    addedCount?: number | null | undefined;
    alumniName?: string | null | undefined;
    alumniId?: string | null | undefined;

    pendingCount? : number | null | undefined; 

    resolver?: ()=>void | null | undefined;
    duplicateResult?: "APPROVE"|"DENY"| null | undefined;
    duplicateName?: string | null | undefined;

    // forms: Form[];
}

const INITIAL_STATE: IFormsStateSlice = {
    isApproved: false,
    addedCount: 0,
    // forms: []
}

export const formsStateSlice = createSlice({
    name: "formStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setFormsDefault: (state) => {
            state = INITIAL_STATE;
        },
        setDetailsChanged: (state) => {
            state.detailsChanged = !state.detailsChanged;
        },
        setIsChanged: (state) => {
            state.isChanged = !state.isChanged;
        },
        setPendingCount: (state, action: PayloadAction<number|undefined>) => {
            state.pendingCount = action.payload;
        },
        setSingleAlumni: (state, action: PayloadAction<{id:string, name:string}>) => {
            state.alumniId = action.payload.id;
            state.alumniName = action.payload.name;
            state.isApproved = true;
            state.addedCount = 1;
        },
        setMultipleAlumni: (state, action: PayloadAction<number|undefined>) => {
            state.addedCount = action.payload;
            state.isApproved = true;
        },
        setResolver: (state, action: PayloadAction<()=>void>) => {
            state.resolver = action.payload;  
        },
        setDuplicateAlumni: (state, action: PayloadAction<{result:"APPROVE"|"DENY"|null, name: string}>) => {
            state.duplicateResult = action.payload.result;
            state.duplicateName = action.payload.name;
        },

        // setForms: (state, action: PayloadAction<Form[]>) => {
        //     state.forms = action.payload;
        // },
        // addForm: (state, action: PayloadAction<Form>) => {
        //     if (!state.forms.some(item=> item.formId === action.payload.formId)){
        //         state.forms.push(action.payload);
        //         console.log("Confirm adding");
        //     }
        // },
        // updateForm: (state, action: PayloadAction<Form>) => {
        //     const index = state.forms.findIndex(item => item.formId === action.payload.formId);
        //     if (index !== undefined && index !== -1 && state.forms) {
        //         state.forms[index] = action.payload;
        //     }
        // },
        // removeForm: (state, action: PayloadAction<string>) => {
        //     state.forms.filter(item => item.formId !== action.payload);
        // }
    }
});

export const {
    setFormsDefault,
    setDetailsChanged,
    setIsChanged,
    setPendingCount,
    setSingleAlumni,
    setMultipleAlumni,
    setResolver,
    setDuplicateAlumni,
    // setForms,
    // addForm,
    // updateForm,
    // removeForm
} = formsStateSlice.actions;
export default formsStateSlice.reducer;