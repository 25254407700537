import React, { useState } from 'react';
import { Autocomplete, TextField, Chip, Button, styled, InputAdornment, IconButton } from '@mui/material';
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from "../../styles/_stylesParams.module.scss";

const InputTextFieldStyle = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary400,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary400,
    },
  },
});

interface AutoCompleteProps {
  label: string;
  placeHolder?: string;
  value: string;
  setValue: (value: string) => void;
  options: string[];
  freeSolo?: boolean;
  valueCheck?: any;
  disableClearable?: boolean;
  customHeight: number;
  fontSize: number;
  shrinkLabelWidth: number;
  error?: boolean;
  helperText?: React.ReactNode;
}

export default function MultiAutoComplete({
  placeHolder,
  helperText,
  error,
  label,
  value,
  valueCheck,
  options,
  freeSolo,
  setValue,
  customHeight = 44, 
  fontSize = 16,
}: AutoCompleteProps) {
  const [inputValue, setInputValue] = useState('');

  const handleAutocompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string[],
    reason: string
  ) => {
    setValue(newValue.join(', '));
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleAddClick = () => {
    const newTags = value ? value.split(', ').concat(inputValue) : [inputValue];
    setValue(newTags.join(', '));
    setInputValue(''); // Clear input field after adding
  };

  const currentTags = value ? value.split(', ') : [];

  const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1
  const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: (valueCheck !== undefined && valueCheck.trim().length === 0) || (valueCheck !== undefined && valueCheck.trim() === "") || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }

  return (
    <Autocomplete
      multiple
      freeSolo={freeSolo}
      options={options}
      value={currentTags}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleAutocompleteChange}
      disableClearable={inputValue !== ''}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          padding: currentTags.length > 0 ? '6px' : '0px',  // Conditionally setting padding
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: currentTags.length > 0 ? '6px' : '0px'  // Apply consistent padding to the input
        }
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <InputTextFieldStyle
          {...params}
          label={label}
          InputLabelProps={inputLabelProps}
          placeholder={placeHolder}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && <IconButton onClick={handleAddClick} className={`${globalStyles["text--neutrals500"]} small`} >Add</IconButton>}
                {params.InputProps.endAdornment}
              </InputAdornment>
            )
          }}
          sx={{
            '& input': { marginLeft:pxToRem(14), height:pxToRem(customHeight), fontSize: pxToRem(fontSize) },
            '& .MuiAutocomplete-endAdornment': { top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }
          }}
        />
      )}
    />
  );
}