import { Breadcrumbs, Divider, IconButton, Snackbar, Stack, Link, Button } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import { getOrganisation, PermissionState } from "../../../firebase/types-organisations";
import { FormInfo, getForm } from "../../../firebase/types-registration";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { setCurrentOrgDetail } from "../../../redux/organisationSlice";
import { SUBTITLE } from "../constants";
import { formatFormURL, handleQrCodeClick } from "../helpers";
import styles from "../index.module.scss";
import ApprovedAlumni from "./ApprovedAlumni";
import FormPreview from "./FormPreview";
import PendingCard from "./PendingCard";
import EditFormButton from "./modals/EditFormModal";
import RejectedRespondents from "./modals/RejectedRespondents";
import CustomizedPaper from "../../../components/CustomizedPaper";
import dashboardStyles from "../../DashboardContainerPage/index.module.scss";
import { URL_PARAMS } from "../../../routers/shared";

interface IFormPage {
}

const FormPage = ({
}: IFormPage) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const organisation = useSelector((state:RootState) => state.organisation)
    const [orgId, setOrgId] = useState<string|null|undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string|null|undefined>(organisation.organisation?.name);
    const [logo, setLogo] = useState<string|null|undefined>(organisation.organisation?.photoUrl);

    const formsPermission = useSelector((state: RootState) => state.globalState.permissions?.forms);

    const [formName, setFormName] = useState<string>();
    const [formCreatedFull, setFormCreatedFull] = useState<string>();
    const [formClosedFull, setFormClosedFull] = useState<string>();
    const [formLastEdited, setFormLastEdited] = useState<string>();
    const [formLastSubmission, setFormLastSubmission] = useState<string>();
    const [totalResponses, setTotalResponses] = useState<number>();
    const [pendingApproval, setPendingApproval] = useState<number>();
    const [formInfo, setFormInfo] = useState<FormInfo>();

    const [approvedCount, setApprovedCount] = useState<number>();
    const [rejectCount, setRejectCount] = useState<number>();
    
    const [link, setLink] = useState<string>();
    const [isActive, setIsActive] = useState<boolean>();

    const [linkCopied, setLinkCopied] = useState(false);
    const [qrDownloaded, setQrDownloaded] = useState(false);

    const [lastAlumni, setLastAlumni] = useState<{id: string, name?: string}[]>([]);

    const handleLastAlumni = (alumni: {id: string, name?: string}[]) => {
        setLastAlumni(alumni);
    }

    useEffect(()=>{
        const {orgId, formId} = params;
        if(!orgId || !formId) return;
        const formRef = doc(db, "registrations", orgId, "forms", formId);
        const unsubForm = onSnapshot(formRef, (docSnap) => {
            if(docSnap.exists()) {
                const form = getForm(docSnap.data());
                const createdAt = (form.createdAt && typeof form.createdAt === 'number') ? moment(form.createdAt).format("DD MMM YY") : "-";
                const createdAtFull = (form.createdAt && typeof form.createdAt === 'number') ? moment(form.createdAt).format("DD MMM YYYY") : "-";
                // const closedAt = (form.closedAt && typeof form.closedAt === 'number') ? moment(form.closedAt).format("DD MMM YY") : "-";
                const closedAtFull = (form.closedAt && typeof form.closedAt === 'number') ? moment(form.closedAt).format("DD MMM YYYY") : "-";
                const lastEdited = (form.lastEdited && typeof form.lastEdited === 'number') ? moment(form.lastEdited).format("DD MMM YY") : "-";
                const lastSubmission = (form.lastSubmission && typeof form.lastSubmission === 'number') ? moment(form.lastSubmission).format("DD MMM YY") : "-";
                setFormInfo(form.formInfo);
                setFormName(form.formName);
                setFormCreatedFull(createdAtFull);
                setFormClosedFull(closedAtFull);
                setFormLastEdited(lastEdited);
                setFormLastSubmission(lastSubmission);
                setTotalResponses(form.totalCount);
                setPendingApproval(form.pendingApproval);
                setApprovedCount(form.approvedCount);
                setRejectCount(form.rejectCount);
                setLink(formatFormURL(orgId+"/"+formId));
                setIsActive(form.isActive);
            }
        });
        const orgRef = doc(db, "organisations", orgId);
        const unsubOrg = onSnapshot(orgRef, (docSnap) => {
            if(docSnap.exists()) {
                const org = getOrganisation(docSnap.data());
                setOrgId(orgId);
                setOrgName(org.name ?? "");
                setLogo(org.photoUrl ?? "");
                dispatch(setCurrentOrgDetail({orgId: orgId, organisation: org}));
            }
        })
        return () => { unsubForm(); unsubOrg(); }
    }, [params])

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={linkCopied}
                onClose={()=>setLinkCopied(false)}
                autoHideDuration={1000}
                message={`Copied to Clipboard`}
            />
            <Snackbar
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
                open={qrDownloaded}
                onClose={()=>setQrDownloaded(false)}
                autoHideDuration={1000}
                message={`QR Code Downloaded`}
            />
            <Snackbar
                anchorOrigin={{vertical:"top", horizontal:"center"}}
                open={lastAlumni.length>0}
                onClose={()=>setLastAlumni([])}
                autoHideDuration={5000}
                message={
                    lastAlumni.length > 0 ?
                        lastAlumni.length > 1 ? 
                            `${lastAlumni.length} New Alumni have been added to Loop’s database`
                        : 
                            `${lastAlumni[0].name} has successfully been added onto Loop’s database`
                    : null
                }
                action={(
                    lastAlumni.length > 0 ?
                        lastAlumni.length > 1 ?
                            <ReactLink to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}`} target={"_blank"}>
                                <Button style={{color:styles.shades0}}>View all Alumni</Button>
                            </ReactLink>
                        :
                            <ReactLink to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}/${lastAlumni[0].id}`} target={"_blank"}>
                                <Button style={{color: styles.shades0}}>View Profile</Button>
                            </ReactLink>
                    : null
                )}
                ContentProps={{
                    classes: {
                        root: styles.approveBanner
                    }
                }}
            />
            <div style={{display:"none"}}>
                <QRCodeCanvas id={link} value={link || ""}/>
            </div>
            <Stack style={{marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent:'space-between'}} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Link key={1} href={`/dashboard`}>Dashboard</Link>
                    <Link key={2} href={`/dashboard/organisation/${orgId}`}>{orgName}</Link>
                    <Link key={3} href={`/dashboard/organisation/${orgId}/forms`}>QR Forms</Link>
                    <Link key={4} href={'#'}>{formName}</Link>
                </Breadcrumbs>
            </Stack>  
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                <Stack className={`${styles.content} ${styles.selfRegistrationForms}`} >       
                    <Stack style={{height:'100%'}}>
                        <Stack id="1072" style={{marginBottom:"24px"}}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"}>
                                <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                    <IconButton onClick={()=>{navigate(-1)}} style={{padding:0}}>
                                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.9961 22L12.9961 16L18.9961 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                    </IconButton>
                                    <p className={styles.formTitle}>{formName} <span style={{color:styles.primary300}}>({isActive ? "Active" : "Archived"})</span></p>
                                </Stack>
                            </Stack>
                            <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                            <Stack direction={"row"} gap={pxToRem(40)} alignItems={"flex-start"} justifyContent={"space-between"}>
                                <Stack id="stats" gap={pxToRem(24)} justifyContent={"space-between"} style={{width:'100%'}}>
                                    <Stack gap={pxToRem(24)} justifyContent={"space-between"}>
                                        <Stack className={styles.statCard} direction="row" justifyContent="space-between" alignItems="center" style={{backgroundColor:styles.primary400}}>
                                            <Stack>
                                                <p >Form Created</p>
                                                <h5>{formCreatedFull}</h5>
                                            </Stack>
                                            { isActive && formsPermission && formsPermission === PermissionState.EDITOR && <EditFormButton formId={params.formId || ""} orgName={orgName || ""}/>}
                                        </Stack>
                                        {
                                            isActive ? 
                                                <PendingCard count={pendingApproval||0} handleLastAlumni={handleLastAlumni}/>
                                            :
                                                <Stack className={styles.statCard} style={{backgroundColor:styles.primary500}}>
                                                    <Stack>
                                                        <p>Form Closed</p>
                                                        <h5>{formClosedFull}</h5>
                                                    </Stack>
                                                </Stack>
                                        }
                                    </Stack>
                                    <Stack direction="row" gap={pxToRem(24)} style={{display: isActive ? "flex": "none"}}>
                                        <Stack direction="row" alignItems={"center"} gap={pxToRem(12)}>
                                            <IconButton id={"copyLinkBtn"} onClick={()=>{
                                                navigator.clipboard.writeText(link || "");
                                                setLinkCopied(true);
                                            }}>
                                                <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.9961 12V9.2C19.9961 8.0799 19.9961 7.51984 19.7781 7.09202C19.5864 6.71569 19.2804 6.40973 18.9041 6.21799C18.4763 6 17.9162 6 16.7961 6H9.19609C8.07599 6 7.51594 6 7.08811 6.21799C6.71179 6.40973 6.40583 6.71569 6.21408 7.09202C5.99609 7.51984 5.99609 8.0799 5.99609 9.2V16.8C5.99609 17.9201 5.99609 18.4802 6.21408 18.908C6.40583 19.2843 6.71179 19.5903 7.08811 19.782C7.51594 20 8.07599 20 9.19609 20H11.9961M15.1961 26H22.7961C23.9162 26 24.4763 26 24.9041 25.782C25.2804 25.5903 25.5864 25.2843 25.7781 24.908C25.9961 24.4802 25.9961 23.9201 25.9961 22.8V15.2C25.9961 14.0799 25.9961 13.5198 25.7781 13.092C25.5864 12.7157 25.2804 12.4097 24.9041 12.218C24.4763 12 23.9162 12 22.7961 12H15.1961C14.076 12 13.5159 12 13.0881 12.218C12.7118 12.4097 12.4058 12.7157 12.2141 13.092C11.9961 13.5198 11.9961 14.0799 11.9961 15.2V22.8C11.9961 23.9201 11.9961 24.4802 12.2141 24.908C12.4058 25.2843 12.7118 25.5903 13.0881 25.782C13.5159 26 14.076 26 15.1961 26Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                            </IconButton>
                                            <small className={styles['regular-400-14']}>Copy Link</small>
                                        </Stack>
                                        <Stack direction="row" alignItems={"center"} gap={pxToRem(12)}>
                                            <IconButton id={"downloadQrBtn"} onClick={(e)=> {
                                                handleQrCodeClick(e, link, formName || "");
                                                setQrDownloaded(true);
                                            }}>
                                                <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.9961 19V20.2C24.9961 21.8802 24.9961 22.7202 24.6691 23.362C24.3815 23.9265 23.9226 24.3854 23.3581 24.673C22.7163 25 21.8763 25 20.1961 25H11.7961C10.1159 25 9.27586 25 8.63412 24.673C8.06964 24.3854 7.61069 23.9265 7.32307 23.362C6.99609 22.7202 6.99609 21.8802 6.99609 20.2V19M20.9961 14L15.9961 19M15.9961 19L10.9961 14M15.9961 19V7" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                            </IconButton>
                                            <small className={styles['regular-400-14']}>Download QR Code</small>
                                        </Stack>
                                    </Stack>
                                    <Stack gap={pxToRem(12)} justifyContent={"space-between"}>
                                        <Stack className={styles.smlStatCard} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99607 18.5608H18.9961M0.996094 18.5608H2.67064C3.15982 18.5608 3.40441 18.5608 3.63458 18.5055C3.83866 18.4565 4.03375 18.3757 4.21269 18.2661C4.41452 18.1424 4.58748 17.9694 4.93338 17.6235L17.4961 5.06077C18.3245 4.23235 18.3245 2.8892 17.4961 2.06077C16.6677 1.23235 15.3245 1.23235 14.4961 2.06077L1.93335 14.6235C1.58745 14.9694 1.4145 15.1424 1.29081 15.3442C1.18116 15.5231 1.10035 15.7182 1.05135 15.9223C0.996094 16.1525 0.996094 16.3971 0.996094 16.8863V18.5608Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                                <small>Form Last Edited</small>
                                            </Stack>
                                            <small>{formLastEdited}</small>
                                        </Stack>
                                        <Stack className={styles.smlStatCard} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9961 3C13.9261 3 14.3911 3 14.7726 3.10222C15.8078 3.37962 16.6165 4.18827 16.8939 5.22354C16.9961 5.60504 16.9961 6.07003 16.9961 7V16.2C16.9961 17.8802 16.9961 18.7202 16.6691 19.362C16.3815 19.9265 15.9226 20.3854 15.3581 20.673C14.7163 21 13.8763 21 12.1961 21H5.79609C4.11594 21 3.27586 21 2.63412 20.673C2.06964 20.3854 1.61069 19.9265 1.32307 19.362C0.996094 18.7202 0.996094 17.8802 0.996094 16.2V7C0.996094 6.07003 0.996094 5.60504 1.09832 5.22354C1.37572 4.18827 2.18436 3.37962 3.21964 3.10222C3.60114 3 4.06612 3 4.99609 3M8.99609 16V10M5.99609 13H11.9961M6.59609 5H11.3961C11.9561 5 12.2362 5 12.4501 4.89101C12.6382 4.79513 12.7912 4.64215 12.8871 4.45399C12.9961 4.24008 12.9961 3.96005 12.9961 3.4V2.6C12.9961 2.03995 12.9961 1.75992 12.8871 1.54601C12.7912 1.35785 12.6382 1.20487 12.4501 1.10899C12.2362 1 11.9561 1 11.3961 1H6.59609C6.03604 1 5.75602 1 5.5421 1.10899C5.35394 1.20487 5.20096 1.35785 5.10509 1.54601C4.99609 1.75992 4.99609 2.03995 4.99609 2.6V3.4C4.99609 3.96005 4.99609 4.24008 5.10509 4.45399C5.20096 4.64215 5.35394 4.79513 5.5421 4.89101C5.75602 5 6.03604 5 6.59609 5Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                                <small>Last Submission</small>
                                            </Stack>
                                            <small>{formLastSubmission}</small>
                                        </Stack>
                                        <Stack className={styles.smlStatCard} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.99609 21H4.59609C4.03604 21 3.75602 21 3.5421 20.891C3.35394 20.7951 3.20096 20.6422 3.10509 20.454C2.99609 20.2401 2.99609 19.9601 2.99609 19.4V4.6C2.99609 4.03995 2.99609 3.75992 3.10509 3.54601C3.20096 3.35785 3.35394 3.20487 3.5421 3.10899C3.75601 3 4.03604 3 4.59609 3H7.39609C7.95615 3 8.23617 3 8.45008 3.10899C8.63825 3.20487 8.79123 3.35785 8.8871 3.54601C8.99609 3.75992 8.99609 4.03995 8.99609 4.6V7M8.99609 21H14.9961M8.99609 21L8.99609 7M8.99609 7H13.3961C13.9561 7 14.2362 7 14.4501 7.10899C14.6382 7.20487 14.7912 7.35785 14.8871 7.54601C14.9961 7.75992 14.9961 8.03995 14.9961 8.6V21M14.9961 11H19.3961C19.9561 11 20.2362 11 20.4501 11.109C20.6382 11.2049 20.7912 11.3578 20.8871 11.546C20.9961 11.7599 20.9961 12.0399 20.9961 12.6V19.4C20.9961 19.9601 20.9961 20.2401 20.8871 20.454C20.7912 20.6422 20.6382 20.7951 20.4501 20.891C20.2362 21 19.9561 21 19.3961 21H14.9961" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                                <small>Total Responses</small>
                                            </Stack>
                                            <small>{totalResponses}</small>
                                        </Stack>
                                        <Stack className={styles.smlStatCard} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                                <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9961 15.5H7.4961C6.10053 15.5 5.40275 15.5 4.83495 15.6722C3.55655 16.06 2.55613 17.0605 2.16833 18.3389C1.99609 18.9067 1.99609 19.6044 1.99609 21M15.9961 18L17.9961 20L21.9961 16M14.4961 7.5C14.4961 9.98528 12.4814 12 9.99609 12C7.51081 12 5.49609 9.98528 5.49609 7.5C5.49609 5.01472 7.51081 3 9.99609 3C12.4814 3 14.4961 5.01472 14.4961 7.5Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                                <small>Approved Alumni</small>
                                                <ApprovedAlumni approvedCount={approvedCount}/>
                                            </Stack>
                                            <small>{approvedCount}</small>
                                        </Stack>
                                        <Stack className={styles.smlStatCard} direction={"row"} alignItems={"center"} justifyContent={"space-between"} style={{display: isActive ? "flex": "none"}}>
                                            <Stack direction={"row"} alignItems={"center"} gap={pxToRem(12)}>
                                                <svg width={pxToRem(22)} height={pxToRem(22)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.92609 3.93L18.0661 18.07M20.9961 11C20.9961 16.5228 16.5189 21 10.9961 21C5.47325 21 0.996094 16.5228 0.996094 11C0.996094 5.47715 5.47325 1 10.9961 1C16.5189 1 20.9961 5.47715 20.9961 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                                <small>Rejected Respondents</small>
                                                <RejectedRespondents/>
                                            </Stack>
                                            <small>{rejectCount}</small>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <FormPreview 
                                    header={formInfo?.header || ""}
                                    logo={logo || ""}
                                    desc={formInfo?.description || ""}
                                    closeStatement={formInfo?.closeStatement || ""}
                                />
                            </Stack>
                        </Stack>

                    </Stack>
                </Stack>
            </CustomizedPaper>
        </>
    );
}

export default FormPage;
