import styled from "styled-components";
import globalStyles from "../../styles/_stylesParams.module.scss";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import pxToRem from "../../helpers/pxToRem";
import { useNavigate } from "react-router-dom";

const PrimaryButtonStyle = styled(LoadingButton)({
  borderRadius: "8px",
  backgroundColor: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  "&:hover": {
    backgroundColor: globalStyles.primary600,
    fontWeight: "unset",
  },
  "&:active": {
    backgroundColor: globalStyles.primary700,
  },
});

const PrimaryQRFormButtonStyle = styled(LoadingButton)({
  borderRadius: '24px',
  backgroundColor: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  "&:hover": {
    backgroundColor: globalStyles.primary600,
    fontWeight: "unset",
  },
  "&:active": {
    backgroundColor: globalStyles.primary700,
  },
});

export const PrimaryButton = (props: LoadingButtonProps) => (
  <PrimaryButtonStyle variant='contained' {...props} className={`${props.className} p`} />
);

export const PrimaryQRFormButton = (props: LoadingButtonProps) => (
  <PrimaryQRFormButtonStyle variant='contained' {...props} className={`${props.className} p`} />
);

const PrimaryDangerButtonStyle = styled(LoadingButton)({
    borderRadius: "8px",
    backgroundColor: globalStyles.secondaryPink600,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    color: 'white',
    height: '2rem',
    "&:hover": {
      backgroundColor: globalStyles.secondaryPink800,
      fontWeight: "unset",
    },
    "&:active": {
      backgroundColor: globalStyles.secondaryPink700,
    },
});
export const PrimaryDangerButton = (props: LoadingButtonProps) => (
    <PrimaryDangerButtonStyle variant='contained' {...props} className={`${props.className} p`} />
);

const SecondaryButtonStyle = styled(LoadingButton)({
  borderRadius: "8px",
  borderColor: globalStyles.primary400,
  color: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  background: "#FFF",
  "&:hover": {
    border: `1px solid  ${globalStyles.primary600}`,
    color: globalStyles.primary600,
    background: "#FFF",
    fontWeight:400,

  },
  "&:active": {
    border: `2px solid  ${globalStyles.primary700}`,
    color: globalStyles.primary700,
    background: "#FFF",
  },
});
export const SecondaryButton = (props: LoadingButtonProps) => (
  <SecondaryButtonStyle variant='outlined' {...props} className={`${props.className} p`} />
);

const SecondaryButtonStyle2 = styled(LoadingButton)({
    borderRadius: "8px",
    borderColor: globalStyles.primary400,
    color: globalStyles.primary400,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    background: "#FFF",
    "&:hover": {
      border: `1px solid  ${globalStyles.primary600}`,
      color: globalStyles.primary600,
      background: "#FFF",
      fontWeight:400,
  
    },
  });
  export const SecondaryButton2 = (props: LoadingButtonProps) => (
    <SecondaryButtonStyle2 {...props} className={`${props.className} p`} />
  );

const WarningButtonStyle = styled(LoadingButton)({
    borderRadius: "8px",
    borderColor: globalStyles.secondaryPink600,
    color: globalStyles.secondaryPink600,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    background: "#FFF",
    "&:hover": {
        border: `2px solid  ${globalStyles.secondaryPink800}`,
        color: globalStyles.secondaryPink800,
        background: "#FFF",
    },
    "&:active": {
        border: `2px solid  ${globalStyles.secondaryPink700}`,
        color: globalStyles.secondaryPink700,
        background: "#FFF",
    },
});
export const WarningButton = (props: LoadingButtonProps) => (
    <WarningButtonStyle variant='outlined' {...props} className={`${props.className} p`} />
);

const PrimaryFlatButtonStyle = styled(LoadingButton)({
    borderRadius: "8px",
    color: globalStyles.primary500,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    "&:hover": { color: globalStyles.primary800, },
    "&:active": { color: globalStyles.primary700, },
});
export const PrimaryFlatButton = (props: LoadingButtonProps) => (
    <PrimaryFlatButtonStyle {...props} className={`${props.className} p`} />
);

const FlatButtonStyle = styled(LoadingButton)({
    borderRadius: "8px",
    color: globalStyles.neutrals600,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    "&:hover": { color: globalStyles.secondaryPink800, },
    "&:active": { color: globalStyles.secondaryPink700, },
});
export const FlatButton = (props: LoadingButtonProps) => (
    <FlatButtonStyle {...props} className={`${props.className} p`} />
);

const LinkFlatButtonStyle = styled(LoadingButton)({
    borderRadius: "8px",
    textDecoration: 'underline',
    color: globalStyles.neutrals600,
    "&:hover": { color: globalStyles.secondaryPink800, textDecoration:'underline' },
    "&:active": { color: globalStyles.secondaryPink700, },
});
export const LinkFlatButton = (props: LoadingButtonProps) => (
    <LinkFlatButtonStyle {...props} className={`${props.className} p`} />
);

const LinkFlatButtonStyle2 = styled(LoadingButton)({
    borderRadius: "8px",
    textDecoration: 'underline',
    color: "#1F1F1F",
    "&:hover": { fontWeight:'bold', textDecoration:'underline' }
});
export const LinkFlatButton2 = (props: LoadingButtonProps) => (
    <LinkFlatButtonStyle2 {...props} className={`${props.className} p`} />
);

interface BetaButtonProps extends LoadingButtonProps {
  text: string; // Add a text prop for custom button text
}

const BetaButtonStyle = styled(LoadingButton)({
    borderColor: globalStyles.primary400,
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    height: "2rem", 
    borderRadius: "0.5rem",
    border: "1px solid var(--secondary-blue-600, #5880CC)",
    background: "#5880CC",
    color: "#FFF",
    fontSize: pxToRem(14),
    fontWeight: 600,
    fontFamily: "Open Sans",
    letterSpacing: pxToRem(0.35),
    "&:hover": {
        color: "var(--secondary-blue-600, #5880CC)",
        borderRadius: "0.5rem",
        border: "1px solid var(--secondary-blue-600, #5880CC)",
        background: "var(--secondary-blue-50, rgba(175, 202, 255, 0.30))",
    },
    "&:active": {
        color: globalStyles.primary700,
        background: "#FFF",
        bordeRadius: "0.5rem",
        border: "2px solid var(--secondary-blue-800, #426099)",
    },
});

const ReuglarUserButtonStyle = styled(LoadingButton)({
  borderColor: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  height: "2rem", 
  borderRadius: pxToRem(16),
  border: "1px solid var(--secondary-blue-600, #549189)",
  background: "#FFF",
  color: "#549189",
  fontSize: pxToRem(14),
  fontFamily: "Open Sans",
  letterSpacing: pxToRem(0.35),
  gap: pxToRem(5),
  "&:hover": {
      border: "2px solid var(--secondary-blue-600, #549189)",
      fontWeight: 600,
  },
  "&:active": {
    color: "#FFF",
      background: "#549189",
      border: "2px solid var(--secondary-blue-600, #549189)",
  },
});
export const BetaButton = ({ text, ...props }: BetaButtonProps) => {

  if (text === 'REGULAR') {
    // Define the style for the "REGULAR" button appearance directly or reference another styled component
    return (
      <ReuglarUserButtonStyle
        variant='outlined'
        {...props}// Specify the link you want to redirect to
      >
      <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9996 10.9994L3.49964 20.4994M14.0181 3.53789C15.2361 4.34609 16.4068 5.29892 17.5008 6.39291C18.6042 7.49634 19.564 8.67782 20.3767 9.90717M9.2546 7.89556L6.37973 6.93727C6.04865 6.82691 5.68398 6.89714 5.41756 7.12257L2.56041 9.54016C1.97548 10.0351 2.14166 10.977 2.86064 11.2419L5.56784 12.2393M11.6807 18.3519L12.6781 21.0591C12.943 21.7781 13.8849 21.9443 14.3798 21.3594L16.7974 18.5022C17.0228 18.2358 17.0931 17.8711 16.9827 17.5401L16.0244 14.6652M19.3482 2.27015L14.4418 3.08789C13.9119 3.17619 13.426 3.4366 13.0591 3.82884L6.446 10.898C4.73185 12.7304 4.77953 15.5919 6.55378 17.3662C8.32803 19.1404 11.1896 19.1881 13.022 17.474L20.0911 10.8609C20.4834 10.494 20.7438 10.008 20.8321 9.4782L21.6498 4.57173C21.8754 3.21809 20.7019 2.04454 19.3482 2.27015Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      See Plans

      </ReuglarUserButtonStyle>
    );
  }

  // Render the original button for other cases
  return (
    <BetaButtonStyle variant='outlined' {...props} className={`${props.className} p`}>
      {text}
    </BetaButtonStyle>
  );
};

interface DetailButtonProps extends LoadingButtonProps {
  name: string;
  number: number;
  svgIcon: JSX.Element;
  backgroundColor: string; // Hex color for the background
  textColor?: string;
}

const DetailButtonStyle = styled(LoadingButton)<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  borderRadius: "8px",
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  height: pxToRem(36),
  width: pxToRem(178),
  backgroundColor: backgroundColor,
  display: 'flex',          // Ensures flexbox layout
  justifyContent: 'space-between', // Distributes space between children elements
  alignItems: 'center',     // Centers items vertically
  '& .buttonContent': {
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(8),
  },
  "&:hover": {
    backgroundColor: `${backgroundColor}E0`, // Slightly darker on hover by changing the opacity
  },
  "&:active": {
    backgroundColor: `${backgroundColor}C0`, // Even darker for the active state
  },
  
}));

export const DetailButton: React.FC<DetailButtonProps> = ({
  name,
  number,
  svgIcon,
  backgroundColor,
  textColor,
  ...props
}) => (
  <DetailButtonStyle variant="contained" backgroundColor={backgroundColor} {...props}>
    <div className="buttonContent">
      {svgIcon}
      <span style={{color: textColor ? textColor : "fff"}}>{name}</span>
    </div>
    <span style={{color: textColor ? textColor : "fff"}}>{textColor ? `` : `(${number})`}</span>
  </DetailButtonStyle>
);

const InvitationButtonStyle = styled(LoadingButton)({
  borderColor: globalStyles.primary400,
  paddingLeft: pxToRem(16),
  paddingRight: pxToRem(16),
  height: pxToRem(32), 
  borderRadius: pxToRem(8),
  border: "1px solid var(--secondary-blue-600, #549189)",
  background: "#549189",
  color: "#FFF",
  fontSize: pxToRem(14),
  fontFamily: "Open Sans",
  letterSpacing: pxToRem(0.35),
  gap: pxToRem(5),
  "&:hover": {
      border: "2px solid var(--secondary-blue-600, #549189)",
      background: "#549189",
      color: "#FFF",
      fontWeight: 600,
  },
});
export const InvitationButton = ({ ...props }: LoadingButtonProps) => {

  return (
    <InvitationButtonStyle variant='outlined' {...props} className={`${props.className} p`}>
      <svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M18 19V13M15 16H21M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      Invite New User
    </InvitationButtonStyle>
  );
};
