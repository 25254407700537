import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldValue } from "firebase/firestore";
import { Alumni } from "../firebase/types-alumni";
import { AlumniTable } from "../pages/AlumniPage";


// Helper functions to format alumni data
export const formatName = (firstName: string | undefined, lastName: string | undefined): string => {
    return [firstName, lastName].filter(Boolean).join(" ");
};

export const formatEmployment = (jobs?: Array<{ id: string; jobTitle: string }> | undefined): string => {
    // This function maps over the 'jobs' array, extracting the 'jobTitle' and joining them with a comma.
    return jobs ? jobs.map(job => job.jobTitle).join(", ") : "";
};

export const formatEducation = (degrees?: Array<{ id: string; degree: string }> | undefined): string => {
    // Similar to the above, but for 'degrees', extracting the 'degree' field.
    return degrees ? degrees.map(degree => degree.degree).join(", ") : "";
};

export const formatLiving = (suburb: string | undefined, city: string | undefined, country: string | undefined): string => {
    return [suburb, city, country].filter(Boolean).join(", ") ?? "-";
};

interface IAlumniDataStateSlice {
    alumniList: AlumniTable[];
}

const INITIAL_STATE: IAlumniDataStateSlice = {
    alumniList: []
}

export const alumniDataStateSlice = createSlice({
    name: "alumniState",
    initialState: INITIAL_STATE,
    reducers: {
        setAlumniList: (state, action: PayloadAction<AlumniTable[]>) => {
            state.alumniList = action.payload;
        },
        addAlumni: (state, action: PayloadAction<AlumniTable>) => {
            // Ensure action.payload includes a `objectID` from Firebase
            state.alumniList.push(action.payload);
        },
        updateAlumni: (state, action: PayloadAction<AlumniTable>) => {
            // Ensure action.payload includes a `objectID`
            const index = state.alumniList.findIndex(alumni => alumni.objectID === action.payload.objectID);
            if (index !== -1) {
                state.alumniList[index] = action.payload;
            }
        },
        removeAlumni: (state, action: PayloadAction<string>) => {
            // action.payload is the `objectID` to be removed
            state.alumniList = state.alumniList.filter(alumni => alumni.objectID !== action.payload);
        },
        resetAlumniState: (state) => {
            state.alumniList = [];
        }
    }
});

export const {
    setAlumniList,
    addAlumni,
    updateAlumni,
    removeAlumni,
    resetAlumniState
} = alumniDataStateSlice.actions;
export default alumniDataStateSlice.reducer;
