import { serverTimestamp } from "firebase/firestore";
import { ValueOf } from "../helpers/type-helpers";
import { Alumni, Schooling } from "./types-alumni";
import { Admin, Organisations, User } from "./types-organisations";
import { FormResponse } from "./types-registration";

// List of alumni subcollections in Firestore
export const ALUMNI_SUBCOLS = ["education","employment", "notes", "schooling"];
export const NETWORK_SUBCOLS = ["education","employment", "notes"];

// undefined: can't decide, becuase user not exist in org
// true: org can be removed
// false: org can be deleted
export const getIfOrganisationIsRemovable = ( userId: string, organisation: ValueOf<Organisations> ) => {
  const allAdmins = organisation?.admins ?? {};
  const allUsers = organisation?.users ?? {};
  const currentAdmins: { [orgUid: string]: Admin } = {};
  const approvedUsers: { [orgUid: string]: User } = {};
  Object.entries(allAdmins).map(([orgId, org]) => {
    if (org.state === "CURRENT") {
      currentAdmins[orgId] = org;
    }
  });
  Object.entries(allUsers).map(([userId, user]) => {
    if (user.details.state === "APPROVED") {
      approvedUsers[userId] = user;
    }
  });
  const adminKeys = Object.keys(currentAdmins);
  const userKeys = Object.keys(approvedUsers);

  if (adminKeys.length > 1 && adminKeys.includes(userId)) {
    return { removable: true, isAdmin: true };
  } else if (adminKeys.length === 1 && adminKeys[0] === userId) {
    return { removable: false, isAdmin: true };
  } else if (userKeys.includes(userId)) {
    return { removable: true, isAdmin: false };
  }
};

export const formToAlumni = (formResponse: FormResponse, orgId: string, formId?: string): Alumni => {
  return {
    ...(formResponse.title && {title: formResponse.title}),
    ...(formResponse.firstName && {firstName: formResponse.firstName}),
    ...(formResponse.lastName && {lastName: formResponse.lastName}),
    ...(formResponse.preferredName && {preferredName: formResponse.preferredName}),
    ...(formResponse.gender && { gender: formResponse.gender}),
    ...(formResponse.email && {emailPreferred: formResponse.email}),
    ...(formResponse.contactNumber && {contactNumber: formResponse.contactNumber}),
    ...(formResponse.communicationOpt && {subscribeEvents: formResponse.communicationOpt}),
    ...(formResponse.newsletterOpt && {subscribeNewsLetter: formResponse.newsletterOpt}),

    // ...(formResponse.peerYear && {peerYear: formResponse.peerYear}),

    ...(formResponse.personalInterests && {personalInterests: formResponse.personalInterests as []}),
    ...(formResponse.skills && {skills: formResponse.skills as []}),
    
    ...(formResponse.linkedIn && {linkendIn: formResponse.linkedIn}),
    ...(formId && {throughFormId: formId}),
    orgId: orgId,

    created_at: serverTimestamp(),
  }
}

export const formToSchooling = (formResponse: FormResponse, orgId: string, orgType: string|undefined, alumniId: string): Schooling => {
  return {
    schoolOption:"CURRENT",
    ...(orgType && {type: orgType}),

    ...(formResponse.peerYear && {peerYear: formResponse.peerYear}),
    ...(formResponse.yearEnrolled && {yearStarted: formResponse.yearEnrolled}),
    
    ...(formResponse.schoolHouse && {schoolHouse: formResponse.schoolHouse}),
    ...(formResponse.campus && {schoolCampus: formResponse.campus}),

    alumniId:alumniId,
    orgId: orgId,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp()
  }
}

