import { DocumentData, FieldValue, Timestamp } from "firebase/firestore";

// Define a type for Linked Alumni details
export type LinkedAlumni = {
    alumniId: string;
    status: string;
    involvement: string;
};


export type NewEngagement<FV = never> = {
    orgId?: string|FV;
    title?: string | FV;
    type?: string | FV;
    location?: string | FV;

    start_time?: number | FV;
    end_time?: number | FV;

    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;

    createdByUserId?: string | FV;
}

export type Engagement<FV = never> = {
    id?:string | FV;
    orgId?: string|FV;
    title?: string | FV;
    type?: string | FV;
    location?: string | FV;

    start_time?: number | FV;
    end_time?: number | FV;

    agenda?: string | FV;
    outcome?: string | FV;
    student_num?: number | FV;
    invited_num?: number | FV;
    planned_num?: number | FV;

    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;

    createdByUserId?: string | FV;
    linkedAlumnis?: LinkedAlumni[] | FV; // Add linked alumni
   
}

export const getEngagement = (data: DocumentData) : Engagement => {
    return{
        title: data.title,
        type: data.type,
        location: data.location,
        start_time: data.start_time,
        end_time: data.end_time,
        createdByUserId: data.createdByUserId,
        created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
        updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,
    }
}


export const getEngagementDetails = (data: DocumentData) : Engagement => {
    return {
        title: data.title,
        type: data.type,
        location: data.location,
        start_time: data.start_time,
        end_time: data.end_time,
        agenda: data.agenda,
        outcome: data.outcome,
        student_num: data.student_num,
        invited_num: data.invited_num,
        planned_num: data.planned_num,
        createdByUserId: data.createdByUserId,
        created_at: data.created_at ? (data.created_at as Timestamp).toMillis() : undefined,
        updated_at: data.updated_at ? (data.updated_at as Timestamp).toMillis() : undefined,
        linkedAlumnis: data.linkedAlumnis || [] // Ensure this field is handled
    }
}