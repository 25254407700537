import { useEffect, useState } from "react";
import { OperationModalText, closeModal, deleteModal } from "../../constants";
import styles from "../../index.module.scss";
import { Stack, Divider, Button } from "@mui/material";
import { SecondaryButton, PrimaryButton, PrimaryDangerButton } from "../../../../components/Buttons/ButtonUI";
import pxToRem from "../../../../helpers/pxToRem";
import CustomModal from "./CustomModal";
import { approveAllAlumni, closeForm, deleteForm, rejectAllAlumni } from "../../../../firebase/registrationApis";
import { useDispatch } from "react-redux";
import { setDetailsChanged } from "../../../../redux/formsSlice";

export enum Operation {
    CLOSE = 0,
    DELETE = 1
}

interface IOperationModals {
    operation: Operation;
    totalSubmissions: number;
    openActionModal: boolean;
    closeActionModal: ()=>void;
    openNoActionModal: boolean;
    closeNoActionModal: ()=>void;
    orgId?: string;
    formId?: string;
    formName?: string;
}

const OperationModals = ({
    operation, totalSubmissions, 
    openActionModal, closeActionModal,
    openNoActionModal, closeNoActionModal,
    orgId, formId, formName
}: IOperationModals) => {
    const dispatch = useDispatch();
    const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
    const [openConfirmReject, setOpenConfirmReject] = useState(false);
    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const [errorText, setErrorText] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [modalText, setModalText] = useState<OperationModalText>();

    useEffect(()=> {
        switch(operation) {
            case Operation.CLOSE:
                setModalText(closeModal);
                break;
            case Operation.DELETE:
                setModalText(deleteModal);
                break;
            default:
                console.log("Unknown Operation!!");
                setErrorText("Unknown operation!");  
                setOpenErrorModal(true);
        }
    },[operation]);
    

    const handleOperation = async () => {
        if(!orgId || !formId) return;
        setIsLoading(true);
        let res;
        switch(operation) {
            case Operation.CLOSE:
                res = await closeForm(orgId, formId);
                break;
            case Operation.DELETE:
                res = await deleteForm(orgId, formId);
                break;
            default:
                setErrorText("Unknown operation!");  
                setOpenErrorModal(true);
        }
        if(res?.code === 200) {
            setOpenSuccessModal(true);
        } else {
            setErrorText(`Error occurred trying to ${modalText && modalText?.operation}!`);  
            setOpenErrorModal(true);
        }
        closeNoActionModal();
        setIsLoading(false);
    }

    const confirmApprove = () => {
        closeActionModal();
        setOpenConfirmApprove(true);
    }

    const confirmReject = () => {
        closeActionModal();
        setOpenConfirmReject(true);
    }

    const closeConfirmApprove = () => {
        setOpenConfirmApprove(false);
    }

    const closeConfirmReject = () => {
        setOpenConfirmReject(false);
    }

    const closeChangeModal = () => {
        setOpenChangeModal(false);
        setOpenSuccessModal(true);
    }

    const closeSuccessModal = () => {
        setOpenSuccessModal(false);
        // Update forms table
        console.log("Updating table!!");
        dispatch(setDetailsChanged());
    }

    const handleApproveAll = async () => {
        if(!orgId || !formId) {
            return;
        }
        setIsLoading(true);
        const res = await approveAllAlumni(orgId, formId);
        if(res.code === 200) {
            let res2;
            switch(operation) {
                case Operation.CLOSE:
                    res2 = await closeForm(orgId, formId);
                    break;
                case Operation.DELETE:
                    res2 = await deleteForm(orgId, formId);
                    break;
                default:
                    setErrorText("Unknown operation!");  
                    setOpenErrorModal(true);
            }
            if(res2?.code === 200) {
                setOpenChangeModal(true)
            } else {
                setErrorText(`Error occurred trying to ${modalText && modalText?.operation}!`);  
                setOpenErrorModal(true);
            }
        }
        setOpenConfirmApprove(false);
        setIsLoading(false);
    }

    const handleRejectAll = async () => {
        if(!orgId || !formId) return;
        setIsLoading(true);
        const res = await rejectAllAlumni(orgId, formId);
        if(res.code === 200) {
            let res2;
            switch(operation) {
                case Operation.CLOSE:
                    res2 = await closeForm(orgId, formId);
                    break;
                case Operation.DELETE:
                    res2 = await deleteForm(orgId, formId);
                    break;
                default:
                    setErrorText("Unknown operation!");
                    setOpenErrorModal(true);
            }
            if(res2?.code === 200) {
                setOpenSuccessModal(true);
            } else {
                setErrorText(`Error occurred trying to ${modalText && modalText?.operation}!`);  
                setOpenErrorModal(true);
            }
        }
        setOpenConfirmReject(false);
        setIsLoading(false);
    }


    return (
        <>
                <CustomModal
                    open={openNoActionModal}
                    handleClose={()=>{}}
                    isLoading={isLoading}
                    className={styles.operationModal}
                    contentStyle={`${styles["insetBanner--red"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <h5>{modalText && modalText.title}</h5>
                        <small>{modalText && modalText.subtitle}</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <small>Are you sure you want to {modalText && modalText.operation} the form <strong>“{formName}”</strong>?</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <SecondaryButton onClick={closeNoActionModal} className={styles.cancelBtn}>Cancel</SecondaryButton>
                            <PrimaryDangerButton onClick={handleOperation} className={styles.destructiveBtn}>Yes, I'm Sure</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                </CustomModal>
                <CustomModal
                    open={openActionModal}
                    handleClose={()=>{}}
                    className={`${styles.operationModal}`}
                    contentStyle={`${styles["insetBanner--red"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <h5>{modalText && modalText.title}</h5>
                        <small>{modalText && modalText.subtitle}</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <small>Please remove all submissions pending approval before {modalText && modalText.operationVerb} the form <strong>"{formName}"</strong>. This action cannot be undone.</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <SecondaryButton onClick={closeActionModal} className={styles.cancelBtn}>Cancel</SecondaryButton>
                            <Stack direction={"row"} gap={pxToRem(12)}>
                                <Button onClick={confirmReject} className={styles.rejectAllBtn}>Reject All</Button>
                                <PrimaryButton onClick={confirmApprove} className={styles.approveBtn}>Approve All</PrimaryButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </CustomModal>
                <CustomModal
                    open={openConfirmApprove}
                    handleClose={()=>{}}
                    isLoading={isLoading}
                    className={`${styles.operationModal}`}
                    contentStyle={`${styles["insetBanner--red"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <h5>Approving All</h5>
                        <small>This action cannot be undone</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <small>Are you sure you want to approve all {totalSubmissions} submissions?</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <SecondaryButton onClick={closeConfirmApprove} className={styles.cancelBtn}>Cancel</SecondaryButton>
                            <PrimaryDangerButton onClick={handleApproveAll} className={styles.destructiveBtn}>Yes, I'm Sure</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                </CustomModal>
                <CustomModal
                    open={openConfirmReject}
                    handleClose={()=>{}}
                    isLoading={isLoading}
                    className={`${styles.operationModal}`}
                    contentStyle={`${styles["insetBanner--red"]} ${styles['operationModalWrapper']}`}
                >
                    <Stack>
                        <h5>Rejecting All</h5>
                        <small>This action cannot be undone</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <small>Are you sure you want to reject all {totalSubmissions} submissions?</small>
                        <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <SecondaryButton onClick={closeConfirmReject} className={styles.cancelBtn}>Cancel</SecondaryButton>
                            <PrimaryDangerButton onClick={handleRejectAll} className={styles.destructiveBtn}>Yes, I'm Sure</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                
                </CustomModal>
                <CustomModal
                    open={openChangeModal}
                    handleClose={closeChangeModal}
                    className={`${styles.operationModal}`}
                    contentStyle={`${styles["insetBanner--green"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <p style={{paddingTop:pxToRem(10)}}>{totalSubmissions} new alumni have successfully been added to Loop’s database.</p>
                        <Divider style={{ marginTop: pxToRem(40), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <PrimaryButton onClick={closeChangeModal} className={styles.approveBtn}>Continue</PrimaryButton>
                        </Stack>
                    </Stack>
                </CustomModal>
                <CustomModal
                    open={openSuccessModal}
                    handleClose={closeSuccessModal}
                    className={`${styles.operationModal}`}
                    contentStyle={`${styles["insetBanner--green"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <p style={{paddingTop:pxToRem(10)}}>The form “<strong>{formName}</strong>” has successfully been {modalText && modalText.operationPast}.</p>
                        <Divider style={{ marginTop: pxToRem(40), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <PrimaryButton onClick={closeSuccessModal} className={styles.approveBtn} style={{width: pxToRem(132)}}>Ok</PrimaryButton>
                        </Stack>
                    </Stack>
                </CustomModal>
                <CustomModal
                    open={openErrorModal}
                    handleClose={()=>setOpenErrorModal(false)}
                    className={styles.operationModal}
                    contentStyle={`${styles["insetBanner--red"]} ${styles["operationModalWrapper"]}`}
                >
                    <Stack>
                        <p style={{paddingTop:pxToRem(10)}}>An unexpected error occurred whilst working on your request. Please try again later.</p>
                        <p>{errorText}</p>
                        <Divider style={{ marginTop: pxToRem(40), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <PrimaryDangerButton onClick={()=>setOpenErrorModal(false)} className={styles.destructiveBtn}>Close</PrimaryDangerButton>
                        </Stack>
                    </Stack>
                </CustomModal>
            </>
    );
}

export default OperationModals;