import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormInfo } from "../firebase/types-registration";

interface IAlumniRegStateSlice {
    step: number | null | undefined;
    terms: boolean | undefined;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    preferredName: string | null | undefined;

    email: string | null | undefined;
    contactNumber: string | null | undefined;
    linkedIn: string | null | undefined;
    communicationOpt: boolean | undefined;
    newsletterOpt: boolean | undefined;

    campus: string | null | undefined;
    schoolHouse: string | null | undefined;
    peerYear: number | null | undefined;
    yearEnrolled: number | null | undefined;

    identity: IIdentity;
    identityIsCompleted: boolean | null | undefined;
    interests: string[] | null | undefined;
    interestsIsCompleted: boolean | null | undefined;
    skills: string[] | null | undefined;
    skillsIsCompleted: boolean | null | undefined;

    orgInfo: FormInfo;

    inProgress: boolean | null | undefined;

    campuses: string[] | null;
    schoolHouses: string[] | null;
    logo?: string | null;
}

interface IIdentity {
    pronouns: string | null | undefined;
    gender: string | null | undefined;
    title: string | null | undefined;
}

const DEFAULT_ORG_INFO: FormInfo = {
    header: null,
    description: null,
    closeStatement: null,
    defaultPeerYear: null,
}

const INITIAL_IDENTITY_STATE: IIdentity = {
    pronouns: null,
    gender: null,
    title: null,
}

const INITIAL_STATE: IAlumniRegStateSlice = {
    step: 1,
    terms: false,
    firstName: null,
    lastName: null,
    preferredName: null,

    email: null,
    contactNumber: null,
    linkedIn: null,
    communicationOpt: false,
    newsletterOpt: false,

    campus: null,
    schoolHouse: null,
    peerYear: null,
    yearEnrolled: null,

    identity: INITIAL_IDENTITY_STATE,
    identityIsCompleted: false,
    interests: [],
    interestsIsCompleted: false,
    skills: [],
    skillsIsCompleted: false,

    orgInfo: DEFAULT_ORG_INFO,

    campuses: null,
    schoolHouses: null,
    logo: null,

    inProgress: false,
}



export const alumniRegStateSlice = createSlice({
    name: "alumniStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setAlumniRegDefault:(state) => {
            state = INITIAL_STATE;
        },
        setCurrentStep: (state, action: PayloadAction<number|undefined>) => {
            state.step = action.payload;
        },
        setCurrentTerms: (state, action: PayloadAction<boolean|undefined>) => {
            state.terms = action.payload;
        },
        setCurrentFirstName: (state, action: PayloadAction<string|undefined>) => {
            state.firstName = action.payload;
        },
        setCurrentLastName: (state, action: PayloadAction<string|undefined>) => {
            state.lastName = action.payload;
        },
        setCurrentPreferredName: (state, action: PayloadAction<string|undefined>) => {
            state.preferredName = action.payload;
        },
        setCurrentEmail: (state, action: PayloadAction<string|undefined>) => {
            state.email = action.payload;
        },
        setCurrentContactNumber: (state, action: PayloadAction<string|undefined>) => {
            state.contactNumber = action.payload;
        },
        setCurrentLinkedIn: (state, action: PayloadAction<string|undefined>) => {
            state.linkedIn = action.payload;
        },
        setCurrentCommOpt: (state, action: PayloadAction<boolean|undefined>) => {
            state.communicationOpt = action.payload;
        },
        setCurrentNewsOpt: (state, action: PayloadAction<boolean|undefined>) => {
            state.newsletterOpt = action.payload;
        },
        setCurrentCampus: (state, action: PayloadAction<string|undefined>) => {
            state.campus = action.payload;
        },
        setCurrentSchoolHouse: (state, action: PayloadAction<string|undefined>) => {
            state.schoolHouse = action.payload;
        },
        setCurrentPeerYear: (state, action: PayloadAction<number|undefined>) => {
            state.peerYear = action.payload;
        },
        setCurrentYearEnrolled: (state, action: PayloadAction<number|undefined>) => {
            state.yearEnrolled = action.payload;
        },
        setFormInfo:  (state, action: PayloadAction<FormInfo>) => {
            state.orgInfo.header = action.payload.header;
            state.orgInfo.description = action.payload.description;
            state.orgInfo.closeStatement = action.payload.closeStatement;
            state.orgInfo.defaultPeerYear = action.payload.defaultPeerYear;
        },
        setOrgInfo: (state, action: PayloadAction<{
            campuses:string[],
            schoolHouses:string[],
            logo: string,
        }>) => {
            state.campuses = action.payload.campuses;
            state.schoolHouses = action.payload.schoolHouses;
            state.logo = action.payload.logo;
        },
        setCurrentIdentity: (state, action: PayloadAction<IIdentity>) => {
            state.identityIsCompleted = true;
            state.identity.pronouns = action.payload.pronouns;
            state.identity.gender = action.payload.gender;
            state.identity.title = action.payload.title;
        },
        setCurrentInterests: (state, action: PayloadAction<string[]|undefined>) => {
            state.interests = action.payload;
            state.interestsIsCompleted = (state.interests && state.interests.length > 0);
        },
        setCurrentSkills: (state, action: PayloadAction<string[]|undefined>) => {
            state.skills = action.payload;
            state.skillsIsCompleted = (state.skills && state.skills.length > 0);
        },
        setProgress: (state, action: PayloadAction<boolean|undefined>) => {
            state.inProgress = action.payload;
        }
    }
});

export const { 
    setAlumniRegDefault, 
    setCurrentStep,
    setCurrentTerms,
    setCurrentFirstName, 
    setCurrentLastName, 
    setCurrentPreferredName, 
    setCurrentEmail, 
    setCurrentContactNumber, 
    setCurrentLinkedIn, 
    setCurrentCommOpt, 
    setCurrentNewsOpt, 
    setCurrentCampus, 
    setCurrentSchoolHouse,
    setCurrentPeerYear,
    setCurrentYearEnrolled,
    setFormInfo,
    setOrgInfo,
    setCurrentIdentity,
    setCurrentInterests,
    setCurrentSkills,
    setProgress
} = alumniRegStateSlice.actions;
export default alumniRegStateSlice.reducer;

//Example
/*
Read: 
    useSelector( (state: RootState) => state.alumni.alumniId ?? {} );
Set:
    const dispatch = useDispatch();
    dispatch(setAlumniId);

*/
