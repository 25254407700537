import { Avatar, Breadcrumbs, Divider, Grid, Paper } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FieldValue, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BetaButton } from "../../components/Buttons/ButtonUI";
import Line from "../../components/Line/Line";
import { db } from "../../firebase";
import { getOrganisation, PermissionState } from "../../firebase/types-organisations";
import pxToRem from "../../helpers/pxToRem";
import { getTimeDiffDes2DatesMillsNow } from "../../helpers/timeUtils";
import { extractFourFirstLetters, getNumberFormatted } from "../../helpers/utils";
import { RootState } from "../../redux";
import { betaStateAction } from "../../redux/betaSlice";
import { setCurrentOrgDetail } from "../../redux/organisationSlice";
import { setPageStatus } from "../../redux/pageStatusSlice";
import { URL_PARAMS } from "../../routers/shared";
import globalStyles from "../../styles/_stylesParams.module.scss";
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import styles from "./index.module.scss";
import FreeTrialPopup from "../../components/FreeTrialPopup";
import { getCurrentUserId } from "../../firebase/userApi";
import ActionCards, { EngagementActionCard, EngagementActionCardPlaceholder, PlannerTaskActionCard, PlannerTaskActionCardPlaceHolder, QRFormActionCard, QRFormActionCardPlaceholder } from "../DashboardContainerPage/components/ActionCards";
import FeatureCard from "../DashboardContainerPage/components/FeatureCard";
import WeeklyGoalsCard from "../DashboardContainerPage/components/WeeklyGoalsCard";
import OrgSetupChecklist from "../DashboardContainerPage/components/OrgSetupChecklist";
import WeeklyGoals from "./components/WeeklyGoals";
import { resetWeeklyGoalsState } from "../../redux/dashboardSlice";
import moment from "moment";
import { AlertDialogSidebarComponent } from "../../components/AlertComponent";

const OrganisationPage = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const organisation = useSelector( (state: RootState) => state.organisation );
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const [ orgId, setOrgId] = useState<string|null|undefined>(organisation.orgId);
    const [ orgName, setOrgName] = useState<string|null|undefined>(organisation.organisation?.name);
    const userId = getCurrentUserId();
    const firstName = useSelector((state: RootState) => state.user?.userInfos.firstName ?? "");   
    const totalAlumni = useSelector((rootState: RootState) => rootState.organisation.organisation?.totalAlumni);
    const alumniLastUpdated = useSelector((rootState: RootState) => rootState.organisation.organisation?.alumniLastUpdated);
    const orgCreateTime = useSelector((rootState: RootState) => rootState.organisation.organisation?.created_at);
    const orgTrialEnd = useSelector((rootState: RootState) => rootState.organisation.organisation?.trial_end);
   const [orgStatus, setOrgStatus] = useState<string|null|undefined>(organisation.organisation?.status);
    const [isPopupVisible, setIsPopupVisible] = useState(orgStatus === "ACTIVETRIAL");

    const [weeklyOpen, setWeeklyOpen] = useState(false);
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    const openWeekly = () => setWeeklyOpen(true);
    const closeWeekly = () => {
        dispatch(resetWeeklyGoalsState());
        setWeeklyOpen(false);
    };
    
    // const forms = useSelector((state:RootState) => state.formsState.forms);

    const dashData = useSelector((state:RootState) => state.dashboardState);
    const validTasks = dashData.tasks.filter(task => !task.isDone && !task.isDeleted);
    const engagements = useSelector((state:RootState) => state.engagementState);
    const permissions = useSelector((state:RootState) => state.globalState.permissions);

    const handleClosePopup = () => {
		setIsPopupVisible(false);
	};

    const getStatusDisplay = (status: string) => {
        const statusMap: Record<string, { textStatus: string; colorStatus: string }> = {
          ACTIVE: { textStatus: "Active", colorStatus: "green" },
          ACTIVETRIAL: { textStatus: "Active Trial", colorStatus: "orange" },
          EXPIRED: { textStatus: "Expired", colorStatus: "red" },
        };
      
        return statusMap[status] || { text: "Unknown", color: "grey" };
    };

    // const { textStatus, colorStatus } = getStatusDisplay(orgStatus || "");
      

    const loadAndSetOrganisationInfo = async() => {
        const { orgId } = params;
        if(orgId){
            const docRef = doc(db, "organisations", orgId);
                const unsubscribeOrganisation = onSnapshot(docRef, (docSnap) => {
                if(docSnap.exists()){
                    const organisation = getOrganisation(docSnap.data());
                    setOrgId(orgId);
                    setOrgName(organisation.name);
                    setOrgStatus(organisation.status); // Set status from Firestore
                    setIsPopupVisible(organisation.status === "ACTIVETRIAL"); // Show popup based on status
                    dispatch(setCurrentOrgDetail({orgId: orgId, organisation: organisation})); //Set Organisation in Redux
                }
                else{
                    navigate(`/${URL_PARAMS.ERROR404}`);
                }
             })
        }
        else{
            navigate(`/${URL_PARAMS.ERROR404}`);
        }
        dispatch(setPageStatus(false));
    }
    
    //Load organisation info
    useEffect(() => { 
        loadAndSetOrganisationInfo(); 
        if(orgId) dispatch(setPageStatus(false));
    }, [orgId, params.orgId]);


    const navigateToAlumni = () => {
        if(orgId) navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}`);
    }

    return ( 
        <>
            <AlertDialogSidebarComponent visible={isOpenAlert} title='You do not currently have permission to access this area' message={` Please contact your organisation's administrator to request access.`} primaryButtonClose={() => setIsOpenAlert(false)} primaryButtonTitle="Close"/>
            <Stack style={{marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent:'space-between'}} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>Organisation</span></Stack>
                    <Stack><span>{orgName}</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
            </Stack>
            {
                weeklyOpen ?
                    <WeeklyGoals handleBack={closeWeekly}/>
                :
                    <Stack className={styles.mainContainer}>
                        <Stack direction='row' className={styles.titleContainer}>
                            <Avatar onClick={() => {}} sx={{ width: pxToRem(95), height: pxToRem(95), backgroundColor: "#fff", border:'1px solid #F5F5F5' }}>
                                <Avatar src={organisation.organisation?.photoUrl} sx={{ width: pxToRem(90), height: pxToRem(90),  backgroundColor: "#AFCAFF4D" }}>
                                    <h4 style={{ color: "#608BDE"}}>{extractFourFirstLetters(orgName)}</h4>
                                </Avatar>
                            </Avatar>
                            <Stack>
                                <label>{orgName}</label>
                                <small style={{color: '#737373'}}>{ `Hi ${firstName}, welcome back to your workspace`}</small>
                            </Stack>
                        </Stack>
                        <Grid container rowSpacing={pxToRem(24)} columnSpacing={pxToRem(24)}>
                            <Grid item xs={organisation.organisation?.checklist ? 8 : 12}>
                                <WeeklyGoalsCard handleOpen={openWeekly} style={{cursor:'pointer'}}/>
                            </Grid>
                            {
                                organisation.organisation?.checklist &&
                                <Grid item xs={4}>
                                    <OrgSetupChecklist/>
                                </Grid>
                            }
                            {
                                permissions && (!permissions.engagement || permissions.engagement !== PermissionState.NO_ACCESS) &&
                                <Grid item xs={12} lg={8}>
                                    <ActionCards title={'Upcoming Engagements'} desc={'Overview of your upcoming engagements'} style={{flexGrow:1}}
                                        link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ENGAGEMENT}`}
                                    >
                                        {
                                            engagements.engagements.length > 0 ?
                                                [...engagements.engagements]
                                                .sort((a,b) => {
                                                    if(moment(a.start_time).isSameOrBefore(moment(b.start_time))) {
                                                        return -1;
                                                    }
                                                    return 1;
                                                })
                                                .slice(0,5).map(t => (
                                                    <EngagementActionCard engagement={t} key={t.id} />
                                                ))
                                            : <EngagementActionCardPlaceholder/>
                                        }
                                    </ActionCards>
                                </Grid>
                            }
                            {
                                permissions && permissions.forms !== PermissionState.NO_ACCESS &&
                                    <Grid item xs={12} lg={4}>
                                        <ActionCards title={'QR Forms'} desc={'Actionable items'} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.SELF_REGISTRATION_FORMS}`}>
                                            {
                                                dashData.forms.length > 0 ?
                                                    dashData.forms.slice(0,5).map(f => {
                                                        return (
                                                            <QRFormActionCard key={f.formId} form={f}/>
                                                        )
                                                    })
                                                :
                                                    <QRFormActionCardPlaceholder/>
                                            }
                                        </ActionCards>
                                    </Grid>
                            }
                            {
                                permissions && permissions.planner !== PermissionState.NO_ACCESS &&
                                <Grid item xs>
                                    <ActionCards title={'Upcoming Tasks'} desc={'Overview of your planned tasks and any overdue items'} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.PLANNER}`}>
                                        {
                                            validTasks.length > 0 ?
                                                [...validTasks]
                                                    .sort((a,b) => {
                                                        if(moment(a.date).isSameOrBefore(moment(b.date))) {
                                                            return -1;
                                                        }
                                                        return 1;
                                                    })
                                                    .slice(0,5).map(t => (
                                                        <PlannerTaskActionCard task={t} key={t.id} />
                                                    ))
                                            :
                                                <PlannerTaskActionCardPlaceHolder/>
                                        }
                                    </ActionCards>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Stack className={styles.featureContainer}>
                                    <Grid container rowSpacing={pxToRem(24)} columnSpacing={pxToRem(24)}>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                            <FeatureCard name={"Alumni"} disabled={permissions && permissions.alumni===PermissionState.NO_ACCESS} icon={<Stack className={styles.alumniCount}>{totalAlumni ?? 0}</Stack>} className={styles.alumniFeatureCard} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}`} isVariant onClick={() => setIsOpenAlert(true)}/>
                                        </Grid>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                            <FeatureCard name={"Engagement"} disabled={(permissions!==undefined && permissions.engagement !== undefined && permissions.engagement === PermissionState.NO_ACCESS)} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ENGAGEMENT}`} 
                                                icon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.2513 40.3327V31.166M8.2513 12.8327V3.66602M3.66797 8.24935H12.8346M3.66797 35.7493H12.8346M23.8346 5.49935L20.6553 13.7656C20.1383 15.1099 19.8798 15.782 19.4778 16.3473C19.1215 16.8484 18.6837 17.2862 18.1826 17.6425C17.6173 18.0445 16.9451 18.303 15.6009 18.82L7.33464 21.9993L15.6009 25.1787C16.9451 25.6957 17.6173 25.9542 18.1826 26.3562C18.6837 26.7125 19.1215 27.1503 19.4778 27.6514C19.8798 28.2167 20.1383 28.8888 20.6553 30.2331L23.8346 38.4993L27.014 30.2331C27.531 28.8888 27.7895 28.2167 28.1915 27.6514C28.5478 27.1503 28.9856 26.7125 29.4866 26.3562C30.052 25.9542 30.7241 25.6957 32.0684 25.1787L40.3346 21.9993L32.0684 18.82C30.7241 18.303 30.052 18.0445 29.4866 17.6425C28.9856 17.2862 28.5478 16.8484 28.1915 16.3473C27.7895 15.782 27.531 15.1099 27.014 13.7656L23.8346 5.49935Z" stroke="#447D75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                disabledIcon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.2513 40.3327V31.166M8.2513 12.8327V3.66602M3.66797 8.24935H12.8346M3.66797 35.7493H12.8346M23.8346 5.49935L20.6553 13.7656C20.1383 15.1099 19.8798 15.782 19.4778 16.3473C19.1215 16.8484 18.6837 17.2862 18.1826 17.6425C17.6173 18.0445 16.9451 18.303 15.6009 18.82L7.33464 21.9993L15.6009 25.1787C16.9451 25.6957 17.6173 25.9542 18.1826 26.3562C18.6837 26.7125 19.1215 27.1503 19.4778 27.6514C19.8798 28.2167 20.1383 28.8888 20.6553 30.2331L23.8346 38.4993L27.014 30.2331C27.531 28.8888 27.7895 28.2167 28.1915 27.6514C28.5478 27.1503 28.9856 26.7125 29.4866 26.3562C30.052 25.9542 30.7241 25.6957 32.0684 25.1787L40.3346 21.9993L32.0684 18.82C30.7241 18.303 30.052 18.0445 29.4866 17.6425C28.9856 17.2862 28.5478 16.8484 28.1915 16.3473C27.7895 15.782 27.531 15.1099 27.014 13.7656L23.8346 5.49935Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg>}
                                                onClick={() => setIsOpenAlert(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                            <FeatureCard name={"Planner"} disabled={permissions && permissions.planner===PermissionState.NO_ACCESS} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.PLANNER}`} 
                                                icon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.5 16.1327C5.5 13.0524 5.5 11.5122 6.09946 10.3357C6.62677 9.30084 7.46816 8.45945 8.50305 7.93215C9.67957 7.33268 11.2197 7.33268 14.3 7.33268H29.7C32.7803 7.33268 34.3204 7.33268 35.4969 7.93215C36.5318 8.45945 37.3732 9.30084 37.9005 10.3357C38.5 11.5122 38.5 13.0524 38.5 16.1327V31.5327C38.5 34.613 38.5 36.1531 37.9005 37.3296C37.3732 38.3645 36.5318 39.2059 35.4969 39.7332C34.3204 40.3327 32.7803 40.3327 29.7 40.3327H14.3C11.2197 40.3327 9.67957 40.3327 8.50305 39.7332C7.46816 39.2059 6.62677 38.3645 6.09946 37.3296C5.5 36.1531 5.5 34.613 5.5 31.5327V16.1327Z" fill="#447D75"/> <path d="M38.5 18.3327H5.5M29.3333 3.66602V10.9993M14.6667 3.66602V10.9993M14.3 40.3327H29.7C32.7803 40.3327 34.3204 40.3327 35.4969 39.7332C36.5318 39.2059 37.3732 38.3645 37.9005 37.3296C38.5 36.1531 38.5 34.613 38.5 31.5327V16.1327C38.5 13.0524 38.5 11.5122 37.9005 10.3357C37.3732 9.30084 36.5318 8.45945 35.4969 7.93215C34.3204 7.33268 32.7803 7.33268 29.7 7.33268H14.3C11.2197 7.33268 9.67957 7.33268 8.50305 7.93215C7.46816 8.45945 6.62677 9.30084 6.09946 10.3357C5.5 11.5122 5.5 13.0524 5.5 16.1327V31.5327C5.5 34.613 5.5 36.1531 6.09946 37.3296C6.62677 38.3645 7.46816 39.2059 8.50305 39.7332C9.67957 40.3327 11.2197 40.3327 14.3 40.3327Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M6 14C6 10.6863 8.68629 8 12 8H32C35.3137 8 38 10.6863 38 14V19H6V14Z" fill="white"/> </svg> }
                                                disabledIcon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.5 16.1327C5.5 13.0524 5.5 11.5122 6.09946 10.3357C6.62677 9.30084 7.46816 8.45945 8.50305 7.93215C9.67957 7.33268 11.2197 7.33268 14.3 7.33268H29.7C32.7803 7.33268 34.3204 7.33268 35.4969 7.93215C36.5318 8.45945 37.3732 9.30084 37.9005 10.3357C38.5 11.5122 38.5 13.0524 38.5 16.1327V31.5327C38.5 34.613 38.5 36.1531 37.9005 37.3296C37.3732 38.3645 36.5318 39.2059 35.4969 39.7332C34.3204 40.3327 32.7803 40.3327 29.7 40.3327H14.3C11.2197 40.3327 9.67957 40.3327 8.50305 39.7332C7.46816 39.2059 6.62677 38.3645 6.09946 37.3296C5.5 36.1531 5.5 34.613 5.5 31.5327V16.1327Z" fill={styles.neutrals400}/> <path d="M38.5 18.3327H5.5M29.3333 3.66602V10.9993M14.6667 3.66602V10.9993M14.3 40.3327H29.7C32.7803 40.3327 34.3204 40.3327 35.4969 39.7332C36.5318 39.2059 37.3732 38.3645 37.9005 37.3296C38.5 36.1531 38.5 34.613 38.5 31.5327V16.1327C38.5 13.0524 38.5 11.5122 37.9005 10.3357C37.3732 9.30084 36.5318 8.45945 35.4969 7.93215C34.3204 7.33268 32.7803 7.33268 29.7 7.33268H14.3C11.2197 7.33268 9.67957 7.33268 8.50305 7.93215C7.46816 8.45945 6.62677 9.30084 6.09946 10.3357C5.5 11.5122 5.5 13.0524 5.5 16.1327V31.5327C5.5 34.613 5.5 36.1531 6.09946 37.3296C6.62677 38.3645 7.46816 39.2059 8.50305 39.7332C9.67957 40.3327 11.2197 40.3327 14.3 40.3327Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M6 14C6 10.6863 8.68629 8 12 8H32C35.3137 8 38 10.6863 38 14V19H6V14Z" fill="white"/> </svg>}
                                                onClick={() => setIsOpenAlert(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                        <FeatureCard name={"Network"} disabled={permissions && permissions.network===PermissionState.NO_ACCESS} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.NETWORK}`} 
                                                icon={<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.36716 13.0622L12.8009 19.8004C13.2917 20.1511 13.5372 20.3264 13.794 20.3668C14.0201 20.4023 14.2516 20.3665 14.4563 20.2643C14.6889 20.1481 14.8699 19.9068 15.2318 19.4243L17.2253 16.7663C17.3276 16.6299 17.3788 16.5616 17.4389 16.5028C17.4923 16.4505 17.5509 16.4038 17.6138 16.3634C17.6846 16.3179 17.7626 16.2833 17.9185 16.214L26.4294 12.4314C26.7822 12.2746 26.9586 12.1962 27.0916 12.0738C27.2092 11.9655 27.3019 11.8328 27.363 11.685C27.4321 11.518 27.445 11.3254 27.4707 10.9402L28.0938 1.59269M26.3 26.3L32.0552 28.7665C32.723 29.0527 33.0568 29.1958 33.2337 29.4335C33.3889 29.642 33.4652 29.8987 33.4491 30.1581C33.4308 30.4538 33.2293 30.756 32.8263 31.3605L30.1225 35.4163C29.9315 35.7029 29.8359 35.8461 29.7098 35.9498C29.598 36.0417 29.4693 36.1106 29.3309 36.1526C29.1746 36.2 29.0025 36.2 28.6581 36.2H24.2685C23.8124 36.2 23.5843 36.2 23.3902 36.1237C23.2189 36.0563 23.0671 35.947 22.949 35.8057C22.8152 35.6457 22.7431 35.4294 22.5989 34.9966L21.0305 30.2916C20.9455 30.0364 20.9029 29.9087 20.8917 29.779C20.8818 29.664 20.89 29.5482 20.9161 29.4357C20.9455 29.3089 21.0057 29.1886 21.126 28.9479L22.3174 26.5653C22.5594 26.0812 22.6804 25.8391 22.8668 25.6889C23.0311 25.5564 23.23 25.474 23.4398 25.4515C23.6779 25.426 23.9346 25.5115 24.4481 25.6827L26.3 26.3ZM45 23C45 35.1503 35.1503 45 23 45C10.8497 45 1 35.1503 1 23C1 10.8497 10.8497 1 23 1C35.1503 1 45 10.8497 45 23Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                disabledIcon={<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.36716 13.0622L12.8009 19.8004C13.2917 20.1511 13.5372 20.3264 13.794 20.3668C14.0201 20.4023 14.2516 20.3665 14.4563 20.2643C14.6889 20.1481 14.8699 19.9068 15.2318 19.4243L17.2253 16.7663C17.3276 16.6299 17.3788 16.5616 17.4389 16.5028C17.4923 16.4505 17.5509 16.4038 17.6138 16.3634C17.6846 16.3179 17.7626 16.2833 17.9185 16.214L26.4294 12.4314C26.7822 12.2746 26.9586 12.1962 27.0916 12.0738C27.2092 11.9655 27.3019 11.8328 27.363 11.685C27.4321 11.518 27.445 11.3254 27.4707 10.9402L28.0938 1.59269M26.3 26.3L32.0552 28.7665C32.723 29.0527 33.0568 29.1958 33.2337 29.4335C33.3889 29.642 33.4652 29.8987 33.4491 30.1581C33.4308 30.4538 33.2293 30.756 32.8263 31.3605L30.1225 35.4163C29.9315 35.7029 29.8359 35.8461 29.7098 35.9498C29.598 36.0417 29.4693 36.1106 29.3309 36.1526C29.1746 36.2 29.0025 36.2 28.6581 36.2H24.2685C23.8124 36.2 23.5843 36.2 23.3902 36.1237C23.2189 36.0563 23.0671 35.947 22.949 35.8057C22.8152 35.6457 22.7431 35.4294 22.5989 34.9966L21.0305 30.2916C20.9455 30.0364 20.9029 29.9087 20.8917 29.779C20.8818 29.664 20.89 29.5482 20.9161 29.4357C20.9455 29.3089 21.0057 29.1886 21.126 28.9479L22.3174 26.5653C22.5594 26.0812 22.6804 25.8391 22.8668 25.6889C23.0311 25.5564 23.23 25.474 23.4398 25.4515C23.6779 25.426 23.9346 25.5115 24.4481 25.6827L26.3 26.3ZM45 23C45 35.1503 35.1503 45 23 45C10.8497 45 1 35.1503 1 23C1 10.8497 10.8497 1 23 1C35.1503 1 45 10.8497 45 23Z" stroke={styles.neutrals400} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                // onClick={() => setIsOpenAlert(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                            <FeatureCard name={"QR Forms"} disabled={permissions && permissions.forms===PermissionState.NO_ACCESS} link={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.SELF_REGISTRATION_FORMS}`} 
                                                icon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.5013 31.1673H12.8346C7.77202 31.1673 3.66797 27.0633 3.66797 22.0007C3.66797 16.938 7.77203 12.834 12.8346 12.834H16.5013M27.5013 31.1673H31.168C36.2306 31.1673 40.3346 27.0633 40.3346 22.0007C40.3346 16.938 36.2306 12.834 31.168 12.834H27.5013M12.8346 22.0007L31.168 22.0007" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                disabledIcon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.5013 31.1673H12.8346C7.77202 31.1673 3.66797 27.0633 3.66797 22.0007C3.66797 16.938 7.77203 12.834 12.8346 12.834H16.5013M27.5013 31.1673H31.168C36.2306 31.1673 40.3346 27.0633 40.3346 22.0007C40.3346 16.938 36.2306 12.834 31.168 12.834H27.5013M12.8346 22.0007L31.168 22.0007" stroke={styles.neutrals400} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                onClick={() => setIsOpenAlert(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{flexGrow:1}}>
                                            <FeatureCard name={"Philanthropy (Coming Soon)"} disabled 
                                                icon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.0013 36.8261H15.7868C16.4108 36.8261 17.0309 36.9003 17.6358 37.0488L22.6923 38.2776C23.7894 38.5449 24.9324 38.5709 26.041 38.3556L31.6318 37.2679C33.1086 36.9802 34.4672 36.273 35.5319 35.2372L39.4874 31.3895C40.617 30.2925 40.617 28.5124 39.4874 27.4136C38.4704 26.4243 36.86 26.3129 35.7094 27.1519L31.0994 30.5152C30.4392 30.9978 29.6359 31.2577 28.8097 31.2577H24.3581L27.1916 31.2575C28.7887 31.2575 30.0824 29.9991 30.0824 28.4455V27.8831C30.0824 26.5931 29.1798 25.4682 27.8938 25.1564L23.5204 24.0928C22.8087 23.9202 22.0798 23.833 21.3471 23.833C19.5782 23.833 16.3764 25.2975 16.3764 25.2975L11.0013 27.5453M3.66797 26.7663L3.66797 37.3996C3.66797 38.4264 3.66797 38.9398 3.86779 39.332C4.04356 39.6769 4.32402 39.9574 4.66899 40.1332C5.06116 40.333 5.57454 40.333 6.6013 40.333H8.06797C9.09473 40.333 9.60811 40.333 10.0003 40.1332C10.3452 39.9574 10.6257 39.6769 10.8015 39.332C11.0013 38.9398 11.0013 38.4264 11.0013 37.3996V26.7663C11.0013 25.7396 11.0013 25.2262 10.8015 24.834C10.6257 24.489 10.3452 24.2086 10.0003 24.0328C9.60811 23.833 9.09473 23.833 8.06797 23.833H6.6013C5.57454 23.833 5.06116 23.833 4.66899 24.0328C4.32402 24.2086 4.04356 24.489 3.86779 24.834C3.66797 25.2262 3.66797 25.7396 3.66797 26.7663ZM31.5188 6.58545C30.4246 4.29587 27.902 3.08292 25.4488 4.25366C22.9955 5.42441 21.9503 8.20084 22.9775 10.6382C23.6123 12.1445 25.431 15.0696 26.7278 17.0845C27.2069 17.829 27.4465 18.2013 27.7965 18.419C28.0966 18.6058 28.4724 18.7065 28.8257 18.6948C29.2376 18.6812 29.6312 18.4786 30.4184 18.0734C32.5489 16.9769 35.5866 15.353 36.8895 14.3659C38.9977 12.7687 39.5199 9.83287 37.9415 7.60109C36.3632 5.3693 33.6112 5.1497 31.5188 6.58545Z" stroke="#447D75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                disabledIcon={<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.0013 36.8261H15.7868C16.4108 36.8261 17.0309 36.9003 17.6358 37.0488L22.6923 38.2776C23.7894 38.5449 24.9324 38.5709 26.041 38.3556L31.6318 37.2679C33.1086 36.9802 34.4672 36.273 35.5319 35.2372L39.4874 31.3895C40.617 30.2925 40.617 28.5124 39.4874 27.4136C38.4704 26.4243 36.86 26.3129 35.7094 27.1519L31.0994 30.5152C30.4392 30.9978 29.6359 31.2577 28.8097 31.2577H24.3581L27.1916 31.2575C28.7887 31.2575 30.0824 29.9991 30.0824 28.4455V27.8831C30.0824 26.5931 29.1798 25.4682 27.8938 25.1564L23.5204 24.0928C22.8087 23.9202 22.0798 23.833 21.3471 23.833C19.5782 23.833 16.3764 25.2975 16.3764 25.2975L11.0013 27.5453M3.66797 26.7663L3.66797 37.3996C3.66797 38.4264 3.66797 38.9398 3.86779 39.332C4.04356 39.6769 4.32402 39.9574 4.66899 40.1332C5.06116 40.333 5.57454 40.333 6.6013 40.333H8.06797C9.09473 40.333 9.60811 40.333 10.0003 40.1332C10.3452 39.9574 10.6257 39.6769 10.8015 39.332C11.0013 38.9398 11.0013 38.4264 11.0013 37.3996V26.7663C11.0013 25.7396 11.0013 25.2262 10.8015 24.834C10.6257 24.489 10.3452 24.2086 10.0003 24.0328C9.60811 23.833 9.09473 23.833 8.06797 23.833H6.6013C5.57454 23.833 5.06116 23.833 4.66899 24.0328C4.32402 24.2086 4.04356 24.489 3.86779 24.834C3.66797 25.2262 3.66797 25.7396 3.66797 26.7663ZM31.5188 6.58545C30.4246 4.29587 27.902 3.08292 25.4488 4.25366C22.9955 5.42441 21.9503 8.20084 22.9775 10.6382C23.6123 12.1445 25.431 15.0696 26.7278 17.0845C27.2069 17.829 27.4465 18.2013 27.7965 18.419C28.0966 18.6058 28.4724 18.7065 28.8257 18.6948C29.2376 18.6812 29.6312 18.4786 30.4184 18.0734C32.5489 16.9769 35.5866 15.353 36.8895 14.3659C38.9977 12.7687 39.5199 9.83287 37.9415 7.60109C36.3632 5.3693 33.6112 5.1497 31.5188 6.58545Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                // onClick={() => setIsOpenAlert(true)}
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                        
                    </Stack>
            }
            <FreeTrialPopup
				organizationName={orgName as string}
                orgState={orgState as string}
                orgId={orgId as string}
                userId={userId as string}
				startTime={orgCreateTime as number}
                endTime={orgTrialEnd as number}
				onClose={handleClosePopup} 
                open={isPopupVisible} />
        </>
    );
};

export default OrganisationPage;