import Box from "@mui/material/Box";
import { memo } from "react";
import { Rings, ThreeDots } from "react-loader-spinner";
import pxToRem from "../../helpers/pxToRem";
import globalStyles from "../../styles/_stylesParams.module.scss";

interface ILoadingSpinner { fillViewPortWidth?: boolean; fillViewPortHeight?: boolean; }
const LoadingSpinner = ({ fillViewPortWidth, fillViewPortHeight }: ILoadingSpinner) => {
    return(
        <Box sx={{ width: fillViewPortWidth ? "100vw" : "unset", height: fillViewPortHeight ? "100vh" : "unset", display: "flex", justifyContent: "center", alignItems: "center", }} >
            <ThreeDots  color={globalStyles['primary500']} width={pxToRem(150)} height={pxToRem(150)} />
        </Box>
)};
export default memo(LoadingSpinner);
