import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason, styled, TextField } from '@mui/material';
import React from 'react';
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from "../../styles/_stylesParams.module.scss";
const InputTextFieldStyle = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary400,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary400,
    },
  },
});


type AutocompleteOnInputChange = ( event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason ) => void;
// type AutocompleteOnChange<T> = ( event: React.SyntheticEvent<Element, Event>, value: string | string[] | null, reason: AutocompleteChangeReason ) => void;
type AutoCompleteProps= {
    label: string;
    placeHolder?: string;
    value?: string;
    setValue?: (value: string) => void;
    options: string[];
    freeSolo?: boolean;
    multiple?: boolean;
    disableClearable?: boolean;

    customHeight?: number,  // Always in px FIXME: Change it to mandatory
    fontSize?: number,  // Always in px FIXME: Change it to mandatory
    valueCheck?: any;
    shrinkLabelWidth?: number; 
    error?: boolean;
    helperText?: React.ReactNode;
    helperTextSize?: number;
    clearIconSize?: number;
    inputValue?: string;
    noOptionsText?: string;
    onChange?: ( event: React.SyntheticEvent<Element, Event>, value: string | string[] | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string | string[]> | undefined ) => void;
    onInputChange?: AutocompleteOnInputChange;
}


export default function AutoCompleteControlled({ valueCheck,inputValue,noOptionsText, clearIconSize=19, customHeight = 44, fontSize = 16, shrinkLabelWidth, placeHolder, helperText, error, helperTextSize,
  label ,value, options, multiple, disableClearable, freeSolo,onChange,onInputChange}: AutoCompleteProps){

    const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1
    const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: valueCheck !== undefined && valueCheck.trim().length === 0 || valueCheck !== undefined && valueCheck.trim() === "" || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }
    return (
        <Autocomplete
            // isOptionEqualToValue={(option, value) => option === value}
            noOptionsText={noOptionsText}
            fullWidth
            // multiple={multiple} 
            options={options} 
            //freeSolo={freeSolo}
            autoComplete
            onChange={onChange}
            //onInputChange={onInputChange}
            disableClearable={disableClearable}
            // inputValue={inputValue}
            value={value || null} 
            sx={{ 
              '& .MuiAutocomplete-inputRoot': { padding: 0, }, '& .MuiOutlinedInput-root .MuiAutocomplete-input':{ padding:0 },
              '& fieldset legend': {  width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto' }
            }}
            renderInput={(params) => ( <InputTextFieldStyle
              sx={{ 
                '& input': { marginLeft:pxToRem(14), height:pxToRem(customHeight), fontSize: pxToRem(fontSize) },
                '& .MuiAutocomplete-endAdornment' :{top:0, bottom:0, justifyContent:'center', alignItems:'center', display:'flex'}, 
                '& .MuiSvgIcon-root': { height:pxToRem(clearIconSize), width: pxToRem(clearIconSize) }
              }}
              {...params} label={label} placeholder={placeHolder} InputLabelProps={inputLabelProps} error={error} helperText={helperText}
              /> 
            )}
        />
    )
}