import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import pxToRem from '../../../helpers/pxToRem';


interface DatePickerButtonProps {
  label: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  minDate?: Dayjs | null;
  selectedDates: [Dayjs | null, Dayjs | null];
}

function CustomDay(props: PickersDayProps<Dayjs>, selectedDates: [Dayjs | null, Dayjs | null]) {
  const { day } = props;
  const isSelected = day.isSame(selectedDates[0], 'day') || day.isSame(selectedDates[1], 'day');
  const isInRange = selectedDates[0] && selectedDates[1] && day.isAfter(selectedDates[0], 'day') && day.isBefore(selectedDates[1], 'day');

  return (
    <PickersDay
      {...props}
      sx={{
        ...(isSelected && {
          backgroundColor: '#b3e5fc',
        }),
        ...(isInRange && {
          backgroundColor: '#e1f5fe',
          '&:hover': {
            backgroundColor: '#b3e5fc',
          },
        }),
      }}
    />
  );
}


function DatePickerButton({ label, open, setOpen, value, onChange, minDate, selectedDates }: DatePickerButtonProps) {
  const hiddenTextFieldStyles: CSSProperties = {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
  };

  const fieldRef = useRef<HTMLDivElement>(null); 
  return (
    <Stack direction={"row"}>
      <IconButton onClick={() => setOpen(true)}>
        <svg width={pxToRem(36)} height={pxToRem(37)} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 16.9785H9M22 8.97852V12.9785M14 8.97852V12.9785M13.8 28.9785H22.2C23.8802 28.9785 24.7202 28.9785 25.362 28.6515C25.9265 28.3639 26.3854 27.905 26.673 27.3405C27 26.6988 27 25.8587 27 24.1785V15.7785C27 14.0984 27 13.2583 26.673 12.6165C26.3854 12.0521 25.9265 11.5931 25.362 11.3055C24.7202 10.9785 23.8802 10.9785 22.2 10.9785H13.8C12.1198 10.9785 11.2798 10.9785 10.638 11.3055C10.0735 11.5931 9.6146 12.0521 9.32698 12.6165C9 13.2583 9 14.0984 9 15.7785V24.1785C9 25.8587 9 26.6988 9.32698 27.3405C9.6146 27.905 10.0735 28.3639 10.638 28.6515C11.2798 28.9785 12.1198 28.9785 13.8 28.9785Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </IconButton>
      {open && (
        <div style={hiddenTextFieldStyles}>
          <DatePicker
            value={value}
            open={open}
            onChange={onChange}
            onClose={() => setOpen(false)}
            minDate={minDate || undefined}
            slotProps={{ field: { ref: fieldRef }}}
            slots={{ day: (dayProps) => CustomDay(dayProps, selectedDates) }}
            closeOnSelect={false}  // Keep open after selection
          />
        </div>
      )}
    </Stack>
  );
}


interface PickerWithButtonFieldProps {
  onDateRangeSelect: (dates: [Dayjs | null, Dayjs | null]) => void;
  initialLabel?: string;
  clearDate?: boolean;
}

function PickerWithButtonField({ onDateRangeSelect, initialLabel = 'Pick a date range', clearDate = false }: PickerWithButtonFieldProps) {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isPickingStart, setIsPickingStart] = useState(false);

  useEffect(() => {
    if (clearDate) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [clearDate]);

	const handleDateChange = (date: Dayjs | null) => {
    // If there's no start date or we're re-selecting the start date, set the start date
    if (!startDate || isPickingStart) {
        setStartDate(date);
        onDateRangeSelect([date, date]); // Update the selected range
        // Keep the picker open to allow selecting an end date
        setIsPickingStart(false); // Indicate that the next selection will be for the end date
    } else {
        // If we're selecting the end date
        // Check if the selected date is the same as the start date
        if (date?.isSame(startDate, 'day')) {
            // If the same date is selected, consider it as selecting a single day
            setEndDate(startDate);
            setIsPickerOpen(false); // Close the picker as the selection is complete
            onDateRangeSelect([startDate, startDate]); // Update the selected range
        } else {
            // If a different date is selected, set it as the end date
            setEndDate(date);
            setIsPickerOpen(false); // Close the picker as the selection is complete
            onDateRangeSelect([startDate, date]); // Update the selected range
        }
    }
};




  const formatDate = (date: Dayjs | null) => {
    return date ? date.format('DD MMMM YYYY') : '';
  };

  const getLabel = () => {
    const formattedStart = formatDate(startDate);
    const formattedEnd = formatDate(endDate);
    if (!formattedStart && !formattedEnd) return initialLabel;
    return formattedStart === formattedEnd ? formattedStart : `${formattedStart} - ${formattedEnd}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerButton
        label={getLabel()}
        open={isPickerOpen}
        setOpen={setIsPickerOpen}
        value={startDate || endDate}
        onChange={handleDateChange}
        minDate={startDate}
        selectedDates={[startDate, endDate]}
      />
    </LocalizationProvider>
  );
}

export default PickerWithButtonField;