import { Button, IconButton, Modal, Stack } from "@mui/material";
import styles from "./PendingCard.module.scss";
import { useParams } from "react-router-dom";
import pxToRem from "../../../helpers/pxToRem";
import { useState } from "react";
import ResponseTable from "./tableParts/ResponseTable";
import PendingRespondents from "./modals/PendingRespondents";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { PermissionState } from "../../../firebase/types-organisations";

interface IPendingCard {
    count: number;
    handleLastAlumni: (alumni: {id: string, name?: string}[]) => void;
}

const PendingCard = ({count, handleLastAlumni}: IPendingCard) => {

    const actionCard = () => {
        return (
            <Stack className={styles.pendingCard}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={pxToRem(24)}>
                    <Stack direction={"row"} justifyContent={"space-between"} gap={pxToRem(14)}>
                        <div className={styles.redDeco}></div>
                        <Stack alignItems="center" justifyContent="center" className={styles.pendingCount}><h2>{count}</h2></Stack>
                    </Stack>
                    <div className={styles.title}><p>Pending Responses</p></div>
                    <PendingRespondents handleLastAlumni={handleLastAlumni}/>
                </Stack>
            </Stack>                    
        )
    }

    const zeroCard = () => {
        return (
            <Stack className={styles.statCard} style={{backgroundColor:styles.primary500}}>
                <Stack>
                    <p>Pending responses to be actioned</p>
                    <h5>{count}</h5>
                </Stack>
            </Stack>
        )
    }

    return (
        <>
            {
                count > 0 ?
                    actionCard()
                :
                    zeroCard()
            }
        </>
    )
}

export default PendingCard;