import styles from "./WeeklyGoal.module.scss";
import { useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment";
import pxToRem from "../../../helpers/pxToRem";
import GoalCount from "./GoalCount";
import { Checkbox, IconButton, Skeleton, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { WeeklyGoalType, OrgWeeklyGoalsProgress } from "../../../firebase/types-weeklyGoals";
import { checkTask } from "../../../firebase/weeklyGoalsApi";
import { selectWeek } from "../../../redux/dashboardSlice";
import { getCurrentWeekNumber, getStartDateOfWeek } from "../helper";


interface IWeeklyGoal {
    title?: string;
    img?: string;
}

const THEME_COLORS = [styles.secondaryBlue100, styles.secondaryBlue600, styles.secondaryBlue500,]


const WeeklyGoal = ({title, img}: IWeeklyGoal) => {
    const goals = useSelector((state:RootState) => state.dashboardState.goals);
    const weeklyGoalState = useSelector((state:RootState) => state.dashboardState.weeklyGoalState);
    const progress = useSelector((state:RootState) => state.dashboardState.progress);
    const orgId = useSelector((state:RootState) => state.organisation.orgId);
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState<{date: Moment, month: number, week: number}>();

    const [tasks, setTasks] = useState<{task: string, checked: boolean}[]>()

    const [goal, setGoal] = useState<WeeklyGoalType|undefined>();

    const handleCheck = async (id: number) => {
        if(!orgId || !startDate || !tasks) return;
        await checkTask(orgId, startDate.date.format("YYYY"), startDate.week, id, !tasks[id].checked);
    }

    const getWeeklyGoal = () => {
        if(weeklyGoalState.weekSelected && weeklyGoalState.monthSelected) {
            const currentDate = new Date();
            const startDate = moment(getStartDateOfWeek(currentDate.getFullYear(), weeklyGoalState.weekSelected))

            console.log(startDate);

            setStartDate({date: startDate, month: weeklyGoalState.monthSelected, week: weeklyGoalState.weekSelected});

            // Set Goal Content
            const currentGoal = goals.filter(g => g.code && parseInt(g.code) === weeklyGoalState.weekSelected)[0]

            setGoal(currentGoal);

        } else {
            // Calculate this week's date
            const currentDate = new Date();
            const currentWeek = getCurrentWeekNumber();

            const startDate = moment(getStartDateOfWeek(currentDate.getFullYear(), currentWeek))

            console.log(startDate);

            setStartDate({date: startDate, month: currentDate.getMonth(), week: currentWeek});
            
            var currentGoal = goals.filter(g => g.code && parseInt(g.code) === currentWeek)[0]

            setGoal(currentGoal);
        }
    }

    useEffect(() => {
        getWeeklyGoal();
    }, [weeklyGoalState])

    const checkOrgProgress = () => {
        if(goal!==undefined && progress != undefined && startDate !== undefined) {
            const orgProgress = progress.filter(p => p.code && parseInt(p.code) === startDate.week )[0]
            if(orgProgress){
                if(goal.tasks) {
                    setTasks(goal.tasks.map((t, i) => {
                        const checked:OrgWeeklyGoalsProgress|undefined = orgProgress.tasks.filter((op) => { return op.taskNo.toString() === i.toString()})[0]
                        if(checked) {
                            return {task: t, checked: checked.checked}
                        }
                        return {task: t, checked: false}
                    }))
                }
            } else {
                if(goal.tasks) setTasks(goal.tasks.map(t => {return {task: t, checked: false}}))
            }
        }
    }

    useEffect(() => {
        checkOrgProgress();
    },[progress, goal, startDate]);

    const [imgLoaded, setImgLoaded] = useState(false);

    return (
        <Stack gap={pxToRem(24)}>
            <Stack className={styles.titleContainer}>
                { weeklyGoalState.monthSelected && weeklyGoalState.weekSelected &&
                        <Stack className={styles.selectedWeekPage}>
                            <IconButton onClick={()=>{
                                dispatch(selectWeek(undefined))
                            }}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19 22L13 16L19 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                            </IconButton>
                            <h5>{moment(weeklyGoalState.monthSelected, "MM").format("MMMM")} Goals - Week {weeklyGoalState.weekSelected}</h5>
                        </Stack>
                }
                {startDate && <p>Week starting {startDate.date.format("dddd, DD MMMM YY")}</p>}
                { goal && <h5>{goal.goalHeading}</h5> }
            </Stack>
            {
                goal && tasks !== undefined ?
                    <>
                        <Stack className={styles.imageContainer}>
                            {
                                goal.imgLarge !== undefined ? 
                                    <>
                                        {
                                            !imgLoaded && 
                                            <Skeleton variant={'rectangular'} width='100%' sx={{height: pxToRem(500), borderRadius: pxToRem(8)}}/>
                                        }
                                        <img className={styles.image} src={goal.imgLarge} style={imgLoaded ? {} : {display:'none'}} onLoad={()=>{setImgLoaded(true)}}/>
                                    </>
                                :
                                    // Need place holder image here (In case there is not image)
                                    <Skeleton variant="rectangular" width={'100%'} height={'500px'}/>
                            }
                        </Stack>
                        <Stack className={styles.midContent}>
                            <Stack gap={pxToRem(24)}>
                                <ThemeCard theme={"Goal"} text={goal.goalDescription} bgColor={THEME_COLORS[0]}/>
                                <ThemeCard theme={"Impact"} text={goal.goalImpact} bgColor={THEME_COLORS[1]}/>
                                {/* {
                                    goal.themes.map((t, i) => (
                                        <ThemeCard key={i} theme={t.name} text={t.text} bgColor={THEME_COLORS[i]}/>
                                    ))
                                } */}
                            </Stack>
                            {/* <Stack> */}
                                <GoalCount completed={tasks.filter(t => t.checked).length} total={goal.tasks.length}/>
                            {/* </Stack> */}
                        </Stack>
                        <Stack className={styles.tasksContainer}>
                            <Stack className={styles.tasksTitle} >
                                <h5>Tasks</h5>                    
                                <p>Complete all tasks to achieve this week's goal</p>
                            </Stack>
                            <Stack className={styles.tasks}>
                                {
                                    tasks.map((t,i) => (
                                        <TaskCard key={i} taskId={i} task={t.task} checked={t.checked} handleCheck={handleCheck} />
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </>
                :
                    <p>There are no weekly goals this week!</p>
            }
        </Stack>
    );
};

interface IThemeCard {
    theme: string;
    text: string;
    bgColor?: string;
}
const ThemeCard = ({theme, text, bgColor}: IThemeCard) => {

    return (
        <Stack direction='row' height={'104px'}>
                <Stack className={styles.themeBox} style={{backgroundColor: bgColor?bgColor:styles.secondaryBlue50}}><p>{theme}</p></Stack>
                <Stack height={'100%'} marginLeft={pxToRem(24)} alignItems='center' justifyContent={'center'}>
                    <small className={styles.themeText}>{text}</small>
                </Stack>
        </Stack>
    )
}

interface ITaskCard {
    taskId: number;
    task: string;
    checked: boolean;
    handleCheck: (taskId: number) => void;
}
const TaskCard = ({taskId, task, checked, handleCheck}:ITaskCard) => {

    return (
        <Stack className={checked?styles['taskCard--checked']:styles.taskCard} onClick={()=>handleCheck(taskId)}>
            <small>{task}</small>
            <Checkbox checked={checked}
                icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="11.5" stroke="#737373"/> </svg> }
                checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="12" fill="#608BDE"/> <path d="M17.3332 8L9.99984 15.3333L6.6665 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
            />
        </Stack>
    );
};

export default WeeklyGoal;