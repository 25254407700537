import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Dialog, DialogTitle, Divider, Grid, Paper, Skeleton, Stack } from '@mui/material'; // Make sure to import MUI components correctly
import styles from './index.module.scss';
import { BetaButton, PrimaryButton } from '../../components/Buttons/ButtonUI';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_PARAMS } from '../../routers/shared';
import { Organisation, getOrganisation } from '../../firebase/types-organisations';
import { extractFourFirstLetters, getNumberFormatted } from '../../helpers/utils';
import { getDashboardTitle } from './helpers';
import CustomizedPaper from '../../components/CustomizedPaper';
import pxToRem from '../../helpers/pxToRem';
import { setPageStatus } from '../../redux/pageStatusSlice';
import { getDateTimeLocalFromMills, getTimeDiffDes2DatesMillsNow } from '../../helpers/timeUtils';
import { betaStateAction } from '../../redux/betaSlice';
import globalStyles from "../../styles/_stylesParams.module.scss"
import { updateLastActivity } from '../../firebase/userApis';
import { getCurrentUserId } from '../../firebase/userApi';
import CreateNewOrgModal from './components/createNewOrgModal/createNewOrgModal';
import PaymentPlanPopup from '../BillingPage/components/paymentPlan/PaymentPlan';
import ExpiredPopup from '../../components/ExpiredPopup';
import { setCurrentOrgDetail } from '../../redux/organisationSlice';


const Dashboard = () => {
    const location = useLocation();
    const dispatch = useDispatch(); 
    useEffect(()=>{ 
        dispatch(setPageStatus(false)); 
        console.log("Reset current org details")
        dispatch(setCurrentOrgDetail({
            orgId: null,
            organisation: undefined
        }))
    }, []); //Page Loading Off

    const title = useMemo( () => getDashboardTitle(location.pathname), [location.pathname] );
    const userId = getCurrentUserId();
    const titleVisible = useMemo( () => location.pathname === "/", [location.pathname] );
    const firstName = useSelector((state: RootState) => state.user?.userInfos.firstName ?? "");   
    const organisationList = useSelector((rootState: RootState) => rootState.organisation.organisationList);
    const [organisationListLoading, setOrganisationListLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();

    // const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [activeOrgIdForPopup, setActiveOrgIdForPopup] = useState<string | null>(null);
    const expiredPopupOrg = organisationList?.find(org => org.id === activeOrgIdForPopup);

	const handleClosePopup = () => {
		setActiveOrgIdForPopup(null);
	};

    const [openNewOrg, setOpenNewOrg] = useState(false);

    const handleOpenNewOrg = () => {
        setOpenNewOrg(true);
    };
    
    const handleCloseNewOrg = () => {
        setOpenNewOrg(false);
    };
    
    const navigateToOrganisation = async (orgId: string | undefined) => {
        if (userId && orgId) {
            try {
                await updateLastActivity(userId, orgId);
                navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}`);
            } catch (error) {
                console.error('Error updating last activity:', error);
                // Optionally navigate even if there's an error, or handle it differently
                navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}`);
            }
        }
    };


    
    const GridItem = (key:string, organisation: Organisation) => {
        let  status:JSX.Element | null = null;

        if(organisation.status === "ACTIVE") status = <><span className={`${styles.dashboardTrialInfo} ${styles.dashboardTrialInfo_Green}`}></span> <label className={styles.dashboardTrialInfo_Title}>Active</label></>
        if(organisation.status === "ACTIVETRIAL") status = <><span className={`${styles.dashboardTrialInfo} ${styles.dashboardTrialInfo_Yellow}`}></span> <label className={styles.dashboardTrialInfo_Title}>Active trial</label></>
        else if(organisation.status === "EXPIRED") status = <><span className={`${styles.dashboardTrialInfo} ${styles.dashboardTrialInfo_Red}`}></span> <label className={styles.dashboardTrialInfo_Title}>{typeof(organisation.expiredIn) === "number"?`Expired in ${getDateTimeLocalFromMills(organisation.expiredIn)}`:""}</label></>

        const handleOpenPopup = (orgId: string) => {
            if (organisation.status === "EXPIRED") {
                setActiveOrgIdForPopup(orgId);
            } else {
                navigateToOrganisation(organisation.id);
            }
        };
        
        return(
            <>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={key}>
                <Stack onClick={()=> handleOpenPopup(organisation.id as string)} className={styles.dashboardCardLink}>
                    <Paper elevation={1} style={{ height: pxToRem(350)}} className={styles.dashboardCard}>
                        <Stack direction={"column"}>
                            <Stack style={{ height: pxToRem(190), padding: pxToRem(20) }} spacing={3}>
                                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                                    <p className={styles.dashboardOrganizationSmallTitle}>Total Alumni</p>
                                    <Divider style={{flexGrow: 1, backgroundColor:'white', height: pxToRem(1)}}/>
                                </Stack>
                                <Stack alignItems={"center"}>
                                    <label style={{cursor:'pointer'}} className={styles.dashboardAlumniCountTitle}>{getNumberFormatted(organisation.totalAlumni)}</label>
                                </Stack>
                            </Stack>
                            <Stack spacing={3} direction={"row"} style={{ height: pxToRem(160), backgroundColor: 'white', borderBottomLeftRadius:"0.5rem", borderBottomRightRadius:"0.5rem", padding: pxToRem(20), alignItems:"center", justifyContent:"space-between" }}>
                                <div style={{ justifyContent:'end' }}>
                                    <Stack>
                                        <label className='regular-400-14' style={{ maxHeight: pxToRem(50), overflow: "hidden" }}>{organisation.name}</label>
                                        <label className='regular-400-14' style={{ color: styles['neutrals400']}}>{ typeof(organisation.alumniLastUpdated)==="number"? `Last Updated ${getTimeDiffDes2DatesMillsNow(organisation.alumniLastUpdated)}`:null }</label>
                                    </Stack>
                                </div>
                                <Stack>
                                    {/* <PrimaryButton onClick={(e)=>{ navigateToAlumni(e, tOrganisation.id) }} style={{height: pxToRem(35), cursor: 'pointer' }}>+</PrimaryButton> */}
                                </Stack>
                            </Stack>
                            {/* <div>
                             <Avatar src={organisation.photoUrl} 
                            sx={{ 
                                backgroundColor: organisation.photoUrl ? globalStyles['neutrals0'] : "#AFCAFF4D", 
                                padding: pxToRem(4), 
                                border: `${pxToRem(4)} solid ${globalStyles['neutrals0']}`}} 
                                style={{color: globalStyles['secondaryBlue500'], fontWeight:600}}
                                className={styles.dashboardCardImageLogo}>{extractFourFirstLetters(organisation.name)}
                                </Avatar>
                            </div> */}

                            <Avatar className={styles.dashboardCardImageLogo} onClick={() => {}} sx={{ width: pxToRem(96), height: pxToRem(96), backgroundColor: "#fff" }}>
                                <Avatar src={organisation?.photoUrl} sx={{ width: pxToRem(96), height: pxToRem(96),  backgroundColor: "#AFCAFF4D" }}>
                                    <h4 style={{ color: "#608BDE"}}>{extractFourFirstLetters(organisation.name)}</h4>
                                </Avatar>
                            </Avatar>
                        </Stack>
                    </Paper>
                </Stack>
                <Stack direction={"row"} style={{padding: pxToRem(8), alignItems:'center'}} spacing={1}> {status } </Stack>
            </Grid>
            {/* <ExpiredPopup orgId={organisation.id as string} userId={userId as string} onClose={handleClosePopup} open={isPopupVisible} organizationName={organisation.name as string} isTrialExpired={organisation.subscriptionId === undefined}/> */}
              {/* <PaymentPlanPopup trialData={'6'} orgId={organisation.id as string} userId={userId as string} onClose={handleClosePopup} open={isPopupVisible}/> */}
</>
        );
    }
    const GridItemClean = (key:string) => {
        return(
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={key} style={{ position: 'relative' }}>
                {/* <Link className={styles.dashboardCardLink} to={`/${URL_PARAMS.DASHBOARD}`}> */}
                    <Paper elevation={1} style={{ height: pxToRem(350)}} className={styles.dashboardCardClean}  onClick={() => setOpenNewOrg(true)}>
                        <Stack direction={"column"}>
                            <Stack className={styles.dashboardCardTopRoundBordeClean} spacing={3}></Stack>
                            <Stack direction={"column"} style={{ height: pxToRem(160), backgroundColor: styles['neutrals0'], borderBottomLeftRadius:"0.5rem", borderBottomRightRadius:"0.5rem", padding: pxToRem(20), alignItems:"center", justifyContent:"center" }}>
                                <label className={styles.dashboardCardCleanText}>Add New Organisation</label>
                                <label style={{color: styles['neutrals0']}}>""</label>
                            </Stack>
                            {/* <Avatar className={styles.dashboardCardImageLogo} style={{ fontSize: '3rem', color:'#eceaea'}}>+</Avatar> */}
                            <Avatar className={styles.dashboardCardImageLogoClean}>
                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27 11.25V42.75M11.25 27H42.75" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            </Avatar>
                        </Stack>
                    </Paper>
                {/* </Link> */}
            </Grid>
        );
    }

    return (
        <>
            {/* <Stack style={{marginTop: pxToRem(8), direction:'rtl'}} > 
            <BetaButton onClick={()=>{ dispatch(betaStateAction.setBetaState(true))  }}/> 
            </Stack> */}
            <h5 className={`semiBold ${styles.panelTitle}`} style={{ display: titleVisible ? "unset" : "none" }} > {title} </h5>
            <CustomizedPaper className={`${styles.content} ${styles.contentDashboard}`} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding>
                <Stack style={{ display: 'flex', padding: pxToRem(30) }} spacing={0}>
                    <Stack spacing={1} flex={1}>
                        <p className={styles.dashboardLightTitle}> { `Hi ${firstName}, welcome to your dashboard, find all your organisations in one place` } </p>
                        <h6 className={styles.dashboardHeadingTitle}>Your Organisations</h6>
                    </Stack>
                    <Divider  style={{marginTop:pxToRem(20), marginBottom:pxToRem(24)}}/>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            { 
                                organisationListLoading?
                                    <>
                                         <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                                            <Paper elevation={1} style={{ height: pxToRem(350)}} className={styles.dashboardCardClean}>
                                                <Stack direction={"column"}>
                                                    <Stack className={styles.dashboardCardTopRoundBorder} style={{ height: pxToRem(190), padding:pxToRem(20), backgroundColor:'#F5F5F5' }} spacing={3}></Stack>
                                                    <Stack spacing={3} direction={"row"} style={{ height: pxToRem(160), backgroundColor: 'white', borderBottomLeftRadius:"0.5rem", borderBottomRightRadius:"0.5rem", padding: pxToRem(20), alignItems:"center", justifyContent:"space-between" }}> </Stack>
                                                    <Skeleton variant='circular' className={styles.dashboardCardImageLogo} sx={{ bgcolor: 'gray' }}/>
                                                </Stack>
                                            </Paper>
                                            <Stack direction={"row"} style={{padding: 8, alignItems:'center'}} spacing={1}>
                                                <label className={styles.dashboardTrialInfo_Title}><Skeleton width={pxToRem(100)}/></label>
                                            </Stack>
                                        </Grid>
                                    </>
                                :
                                    organisationList?organisationList.filter((organization) => organization.status !== "TEMPDEL").map((organization, index) => ( GridItem(index.toString(), organization) )) : null 
                            }
                            { GridItemClean("ItemClean_1") }
                        </Grid>    
                    </Box>
                </Stack>
            </CustomizedPaper>
            <CreateNewOrgModal userId={userId as string} open={openNewOrg} onClose={() => setOpenNewOrg(false)}/>
            {expiredPopupOrg && (
                <ExpiredPopup
                    orgId={expiredPopupOrg.id as string}
                    userId={userId as string}
                    onClose={handleClosePopup}
                    open={activeOrgIdForPopup === expiredPopupOrg.id}
                    organizationName={expiredPopupOrg.name as string}
                    isTrialExpired={expiredPopupOrg.subscriptionId === undefined}
                />
            )}
        </>
    );
};

export default Dashboard;