import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organisation, Permission } from "../firebase/types-organisations";

interface IUserInfo {
    userId?: string;
    firstName?: string;
    lastName?: string;
}

const INITIAL_USERINFO_STATE: IUserInfo = {
    userId: undefined,
    firstName: undefined,
    lastName: undefined
}

// export enum Permissions {
//     VIEW = "VIEW",
//     EDIT = "EDIT",
//     NO_ACCESS = "NO_ACCESS"
// }

// export interface IUserPermissions {
//     alumni?: Permission;
//     billingPayments?: Permission;
//     orgPlanner?: Permission;
//     qrForms?: Permission;
// }

// const INITIAL_USER_PERMISSIONS:IUserPermissions = {
//     alumni: undefined,
//     billingPayments: undefined,
//     orgPlanner: undefined,
//     qrForms: undefined,
// }

interface IGlobalStateSlice {
    userInfo: IUserInfo;

    organisation?: Organisation;

    isAdmin?: boolean;
    permissions?: Permission;

    teamNotifications?: number;
    qrFormsPending?: number;
}

const INITIAL_STATE: IGlobalStateSlice = {
    userInfo:INITIAL_USERINFO_STATE,
}

export const globalStateSlice = createSlice({
    name: "globalStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setUserState: (state, action: PayloadAction<IUserInfo>) => {
            state.userInfo = action.payload;
        },
        setOrganisation: (state, action: PayloadAction<Organisation>) => {
            state.organisation = action.payload;
        },
        setIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload;
        },
        setUserPermissions: (state, action: PayloadAction<Permission>) => {
            state.permissions = action.payload;
        },
        setTeamNotifications: (state, action: PayloadAction<number>) => {
            state.teamNotifications = action.payload;
        },
        setQrFormsPending: (state, action: PayloadAction<number>) => {
            state.qrFormsPending = action.payload;
        },
        resetGlobalState: (state) => {
            state.permissions = INITIAL_STATE.permissions;
            state.organisation = INITIAL_STATE.organisation;
            state.isAdmin = INITIAL_STATE.isAdmin;
            state.teamNotifications = INITIAL_STATE.teamNotifications;
            state.qrFormsPending = INITIAL_STATE.qrFormsPending;
        }
    }
});

export const { 
    setUserState,
    setOrganisation,
    setIsAdmin,
    setUserPermissions,
    setTeamNotifications,
    setQrFormsPending,
    resetGlobalState } = globalStateSlice.actions;
export default globalStateSlice.reducer;

//Example
/*
Read: 
    useSelector( (state: RootState) => state.xxx);
Set:
    const dispatch = useDispatch();
    dispatch(setAlumniId);

*/
