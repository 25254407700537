import { Button, Modal, Stack, IconButton } from "@mui/material";
import { useMemo, useState } from "react";
import pxToRem from "../../../../helpers/pxToRem";
import ResponseTable from "../tableParts/ResponseTable";
import styles from "../PendingCard.module.scss";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ResponseData, formatDetails, formatName } from "../../helpers";
import IndeterminateCheckbox from "../tableParts/IndeterminateCheckbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { PermissionState } from "../../../../firebase/types-organisations";

interface IPendingRespondents {
    handleLastAlumni: (alumni: {id: string, name?: string}[]) => void;
}

const PendingRespondents = ({handleLastAlumni}:IPendingRespondents) => {
    const [modalOpen, setModalOpen] = useState(false);
    const formsPermission = useSelector((state:RootState) => state.globalState.permissions?.forms)

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const columnHelper = createColumnHelper<ResponseData>();

    const pendingColumns: ColumnDef<any, any>[] = useMemo(()=> [
        ...formsPermission && formsPermission === PermissionState.EDITOR ? [
            columnHelper.display({
                id: 'pendingSelect',
                cell: ({ row, cell }) => (
                    <div>
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                                id: cell.id,
                                className: styles.round
                            }}
                        />
                    </div>
                ),
                header: ({table}) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                            id: 'pendingHeader',
                            className: styles.round
                        }}
                />),
                size: 68,
            }), 
        ] : [],
        columnHelper.accessor('name', { cell: info => {
            const details = info.getValue();
            return (
                <Stack >
                    <small>{formatName(details.firstName, details.lastName, details.title, details.pronouns)}</small>
                    <p>{formatDetails(info.getValue())}</p>
                </Stack>
            )
            // formatDetails(info.getValue());
        }, header: "Respondent" }), 
        columnHelper.accessor('gender', { cell: info => info.getValue(), header: "Gender", size:100 }), 
        columnHelper.accessor('schooling', { cell: info => info.getValue(), header: "Schooling", size:93 }), 
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "Peer Year", size:99 }), 
        columnHelper.accessor('enrolled', { cell: info => info.getValue(), header: 'Enrolled', size:104 }),
        columnHelper.accessor('interests', { cell: info => info.getValue(), header: 'Interests', maxSize:154 }),
        columnHelper.accessor('skills', { cell: info => info.getValue(), header: 'Skills', maxSize:147 }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    return (
        <>
            <Button onClick={openModal}
                sx={{
                    color: styles.secondaryPink600
                }}
            >
                Action       
            </Button>
            <Modal open={modalOpen}>
                <Stack className={styles.tableModal}>
                    <IconButton className={styles.rejectCloseButton} onClick={closeModal}>
                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                    <Stack mb={pxToRem(12)} gap={pxToRem(12)}>
                        <p className={styles.pendingTitle}>Pending Responses</p>
                        <p className={styles.pendingSubtitle}>Approved alumni will automatically be added to Loop’s database</p>
                    </Stack>
                    <ResponseTable columns={pendingColumns} rejected={false} handleLastAlumni={handleLastAlumni}/>
                </Stack>
            </Modal>
        </>
    )
}

export default PendingRespondents;