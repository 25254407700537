import React, { memo } from 'react';
import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Avatar, Box, Breadcrumbs, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography, TextField, TextareaAutosize, Icon } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Unsubscribe, doc, onSnapshot, serverTimestamp, FieldValue, deleteField, getDoc } from "firebase/firestore";
import { getCurrentUserId } from '../../../firebase/userApi';
import { RootState } from '../../../redux';
import algoliasearch from 'algoliasearch/lite';
import Line from '../../../components/Line/Line';
import pxToRem from '../../../helpers/pxToRem';
import CustomizedPaper from '../../../components/CustomizedPaper';
import dashboardStyles from "../../DashboardContainerPage/index.module.scss";
import useTextInput from '../../../hooks/useTextInput';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { getErrorMsgForTitle } from '../../../helpers/inputValidators';
import { NetworkTable } from '..';
import { joinStringWithSpace } from '../../../helpers/utils';
import { BetaButton, PrimaryButton, SecondaryButton } from '../../../components/Buttons/ButtonUI';
import { Network, getNetwork, Connection } from '../../../firebase/types-network';
import { db } from '../../../firebase';
import { URL_PARAMS } from '../../../routers/shared';
import { betaStateAction } from '../../../redux/betaSlice';
import PlannerModal from '../../OrganisationPlannerPage/components/PlannerModal';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { NewComment, NewTask, Task } from '../../../firebase/types-organisationPlanners';
import { getTimeDiffDes2DatesMillsNow } from '../../../helpers/timeUtils';
import { createNewComment, createNewTask, editSingleTask, getSingleTask } from '../../../firebase/organisationPlannerApi';
import ButtonDatePicker from '../../OrganisationPlannerPage/components/calendarButton';
import StandardInputTextFeild from '../../../components/Inputs/TextFields/StandardInputTextField';
import UserDropdown from '../../OrganisationPlannerPage/components/UserDropdown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultilineTextField from '../../../components/Inputs/TextFields/MultilineInputTextField';
import { Configure, InstantSearch } from 'react-instantsearch';
import CustomizedModal from '../../../components/CustomizedModal';
import CommentsList from '../../OrganisationPlannerPage/components/commentList';
import NotesTab from '../tabs/NotesTab';
import NetworkDetailsTabsHooks from '../networkDetailsComponents/NetworkDetailsTabsHooks';
import NetworkSearchBar, { ConnectionData, ConnectionList } from './NetworkSearchTable';
import { getOrgById } from '../../../firebase/organisationApis';


export const UNLIMITED_SIZE = 1000;
export const PAGINATION_PERPAGEROWS = 10;

const NetworkConnectionPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = getCurrentUserId();
    const organisation = useSelector((state: RootState) => state.organisation);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    const networkList =  useSelector((state: RootState) => state.networkDataState.networkList);

    const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
    const [networkId, setNetworkId] = useState<string | null | undefined>(undefined);
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [targetNetwork, setTargetNetwork] = useState<NetworkTable | null>(null)

    const [firstNameStr, setFirstNameStr] = useState("");
    const [lastNameStr, setLastNameStr] = useState("");
    const [connections, setConnections] = useState<ConnectionData[]>([]);

    const connectionData = useSelector((state: RootState) => {
        const networkList = state.networkDataState.networkList; 
        const alumniList = state.alumniDataState.alumniList; 

        return [...networkList, ...alumniList];
    });

    useEffect(() => {
        if (!params.orgId || !params.networkId) {
            console.error("Invalid organization or Network ID.");
            return;
        }

        // Find the engagement in the Redux store
        const foundNetwork = networkList.find(e => e.objectID === params.networkId);

        if (!orgName) {
            const fetchOrgName = async () => {
                const foundOrg = await getOrgById(params.orgId as string);
                if (foundOrg.code === 200) {
                    setOrgName(foundOrg.data);
                }
            }
            fetchOrgName()
        }

        if (foundNetwork) {
            setNetworkId( params.networkId);
            setTargetNetwork(foundNetwork);
            const linedConnections = foundNetwork.connectionList?.map(linkedConnection => {
                const fullConnectionDetails = connectionData.find(connection => connection.objectID === linkedConnection.objectId);
                return {
                    ...fullConnectionDetails, // Spread the full details from the alumni list
                    ...linkedConnection       // This will override status and involvement from the engagement data
                } as ConnectionData;
            });
            setConnections(linedConnections ?? [])
            setFirstNameStr(foundNetwork.firstName ?? "");
            setLastNameStr(foundNetwork.lastName ?? "");
        }
        else {
            navigate(`/${URL_PARAMS.ERROR404}`);
        }
    }, [params.orgId, params.networkId, networkList]);

    const { notes } = NetworkDetailsTabsHooks({ oid: params?.orgId, networkId: params?.networkId, orgName: orgName ?? undefined })

    return (
        <div>
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/network`)}><span className={styles.breadcrumbsLink}>Network</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/network/${networkId}`)}><span>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span></Stack>
                    <Stack><span>Connections</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
            </Stack>
            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    <Stack className={styles.headerBar} direction={'row'} spacing={1}>
                        <Stack>
                        <IconButton onClick={() => navigate(`/dashboard/organisation/${orgId}/network/${networkId}`)}>
                            <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 18L9 12L15 6" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </IconButton>
                        </Stack>
                        <Stack direction={'column'} spacing={1}>
                            <h6 className={styles.headerTitle}>
                                Connections
                            </h6>
                            <span className={styles.headerText}>{(joinStringWithSpace(firstNameStr.trim(), lastNameStr.trim()))}</span>
                        </Stack>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`}></div>
                    <NetworkSearchBar linkedConnections={connections} network={targetNetwork} orgId={orgId as string} networkId={networkId as string} />
                   
                </Stack>
            </CustomizedPaper>

        </div>
    );
}

export default NetworkConnectionPage;