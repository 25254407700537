// Basic size, same as the number in webpack.config
export const baseSize = 16;
// Function to set rem
function setRem() {
  const clientScreenWidth = document.documentElement.clientWidth;
  if (clientScreenWidth >= 1280) {
    // Set to 1920 means the current design is based on 1920 width monitor
    const scale = clientScreenWidth / 1440;
    // Set font size of the root element
    document.documentElement.style.fontSize = `${Math.min(
        baseSize * scale,
        22
    )}px`;
    }
    else if (clientScreenWidth < 1280 && clientScreenWidth >= 780) {
      // Design width is for Ipad Air, whose widh is 780 px
      const scale = clientScreenWidth / 820;
      // Set font size of the root element
      document.documentElement.style.fontSize = `${Math.min(
        baseSize * scale,
        18
      )}px`;
    } else {
      // Design width is for iphone XR, whose widh is 414 px
      const scale = clientScreenWidth / 360;
      document.documentElement.style.fontSize = `${Math.min(
        baseSize * scale,
        18
      )}px`;
    }
}
// inilization
setRem();
// rem Reset rem when the size of window changes
window.onresize = function () {
  setRem();
};
