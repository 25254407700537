import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAlumniStateSlice {
    alumniId: string | null | undefined;
}

const INITIAL_STATE: IAlumniStateSlice = {
    alumniId: null,
}

export const alumniStateSlice = createSlice({
    name: "alumniStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setAlumniDefault:(state) => {
            state = INITIAL_STATE;
        },
        setAlunmiId: (state, action: PayloadAction<string|undefined>) => {
            state.alumniId = action.payload;
        }
    }
});

export const { setAlumniDefault, setAlunmiId } = alumniStateSlice.actions;
export default alumniStateSlice.reducer;

//Example
/*
Read: 
    useSelector( (state: RootState) => state.alumni.alumniId ?? {} );
Set:
    const dispatch = useDispatch();
    dispatch(setAlumniId);

*/
