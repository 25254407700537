// transfer.ts

/**
 * Converts an array of strings to a single string with elements separated by ', '.
 * @param array Array of string elements.
 * @returns A single string with elements joined by ', '.
 */
export function arrayToString(array: string[]): string {
    return array.join(', ');
  }
  
  /**
   * Converts a string to an array of strings, splitting by ', ' and trimming whitespace.
   * @param input A string with elements separated by ', '.
   * @returns An array of trimmed strings.
   */
  export function stringToArray(input: string): string[] {
    return input.split(',').map(item => item.trim());
  }