interface ILine {
    width: string | number;
    height: string | number;
    lineColor: string;
}

const Line = ({ width, height, lineColor, style, ...props }: ILine & React.HTMLProps<HTMLDivElement>) => {
    const combinedStyles = { width, height, backgroundColor: lineColor, ...style, };
    return <div style={combinedStyles} {...props}></div>;
}

export default Line;
