import { Autocomplete, IconButton, InputAdornment, styled, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from "../../styles/_stylesParams.module.scss";
const InputTextFieldStyle = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary400,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary400,
      paddingRight: 0,
    },
  },
});

type AutoCompleteProps= {
    label: string;
    placeHolder?: string;
    value?: string;
    setValue?: (value: string) => void;
    options: string[];
    freeSolo?: boolean;
    multiple?: boolean;
    disableClearable?: boolean;

    customHeight?: number,  // Always in px FIXME: Change it to mandatory
    fontSize?: number,  // Always in px FIXME: Change it to mandatory
    valueCheck?: any;
    shrinkLabelWidth?: number; 
    error?: boolean;
    helperText?: React.ReactNode;
    helperTextSize?: number;
    clearIconSize?: number;
    inputValue?: string;
    noOptionsText?: string;
    maxLength?: number;
    forcePopupIcon?: boolean;
}
export default function AutoComplete({ valueCheck,inputValue,noOptionsText, clearIconSize=19, customHeight = 44, fontSize = 16, shrinkLabelWidth, placeHolder, helperText, error, helperTextSize,
  label ,value, options, multiple, disableClearable, freeSolo,setValue, maxLength, forcePopupIcon}: AutoCompleteProps){
    const [showSaveButton, setShowSaveButton] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    
    const handleAutocompleteChange = (event: React.ChangeEvent<{}>, newValue: string | string[] | null, reason: string) => {
        if (setValue && reason === 'selectOption') {
            if (typeof newValue === 'string') {
                // Handle when a single value is selected
                setValue(newValue);
            } else if (Array.isArray(newValue)) {
                // Handle when multiple values are selected
                // In this example, we'll just take the first value if multiple values are selected
                setValue(newValue[0]);
            }
        }
    };  
    const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
      if (setValue) {
        setValue(newInputValue);
      }
      setShowSaveButton(true);
    };

    const handleBlur = () => {
      setShowSaveButton(false); // Hide save button when not focused
    };

    const translateY = customHeight && fontSize ?  ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset + 1 : 1
    const inputLabelProps = { sx: { "&:not(.Mui-focused)": { transform: (valueCheck !== undefined && valueCheck.trim().length === 0) || (valueCheck !== undefined && valueCheck.trim() === "") || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {}}, "&.MuiFormLabel-root":{ fontSize: pxToRem(fontSize) }, } }

    return (
        <Autocomplete
            fullWidth
            multiple={multiple}
            options={options}
            freeSolo={freeSolo}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleAutocompleteChange}
            disableClearable={disableClearable}
            noOptionsText={noOptionsText}
            forcePopupIcon={forcePopupIcon}
            onBlur={handleBlur}
            sx={{ 
              '& .MuiAutocomplete-inputRoot': { padding: 0, }, '& .MuiOutlinedInput-root .MuiAutocomplete-input':{ padding:0 },
              '& fieldset legend': {  width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto' }
            }}
            renderInput={(params) => ( <InputTextFieldStyle
              
              sx={{ 
                '& input': { marginLeft:pxToRem(14), height:pxToRem(customHeight), fontSize: pxToRem(fontSize), width: '100%' },
                '& .MuiAutocomplete-endAdornment' :{top:0, bottom:0, justifyContent:'center', alignItems:'center', display:'flex'}, 
                '& .MuiSvgIcon-root': { height:pxToRem(clearIconSize), width: pxToRem(clearIconSize) }
              }}
              {...params} inputRef={inputRef} label={label} placeholder={placeHolder} InputLabelProps={inputLabelProps} error={error} helperText={helperText} InputProps={{
                ...params.InputProps, style: { paddingRight: '19px' },
                endAdornment: showSaveButton ? (
                  <IconButton
                  className={`${globalStyles["text--neutrals500"]} small`}
                    onClick={() => {
                      if (inputValue !== undefined && setValue) {
                        setTimeout(()=>{
                            inputRef.current?.blur();
                          }, 0)
                      setShowSaveButton(false);
                    }
                    }}
                    size="small"
                  >
                    Save
                  </IconButton>
                ) : null
              }}
              /> 
            )}
        />
    )
}
/**
 * Example:
 *   const [personalInterest, setPersonalInterest] = useState("")
 *   <AutoComplete 
 *     label='Personal Interests' 
 *     setValue={(value: any)=>setPersonalInterest(value)} 
 *     options={['Test', 'Saujan']} 
 *     freeSolo
 *    />
 * 
 *   options: Array of auto complete strings set
 *   freeSolo: Autocomplete allows users to enter values that are not present in the predefined list of options. 
 *   
 */