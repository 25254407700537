import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface UserInfoStateFromDb {
    uid: string | null;
    email: string | null;
    displayName: string | null;
    firstName: string|null;
    lastName: string| null;
    emailVerified: boolean;
    contactNumber: string | null;
    role: string | null;
    onBoarding: boolean | undefined,
    profilePicture: string | null | undefined;
    profilePictureId: string | null | undefined;
    // Beta User
    beta?: boolean;
    betaPasswordUpdate?:boolean;
}

interface IUserSlice {
    userInfos: UserInfo;
    loginRoute: boolean;
    userDelete: boolean;
}

export interface UserInfo extends UserInfoStateFromDb { }

const INITIAL_USER_INFOS = {  
    uid: null,
    email: null,
    firstName: null,
    lastName: null,
    emailVerified: false,
    onBoarding: false,
    profilePicture: null,
    displayName: null,
    profilePictureId: null,
    contactNumber: null,
    role: null,
};

const INITIAL_STATE: IUserSlice = {
    userInfos: INITIAL_USER_INFOS,
    loginRoute: false,
    userDelete: false
};

export const userSlice = createSlice({
    name: "user",
    initialState: INITIAL_STATE,
    reducers: {
        setUserInfoDefault:(state) => {
            state = INITIAL_STATE;
        },
        // setUserLogin: (state, action: PayloadAction<UserInfo>) => {
        //     const { uid, email, displayName, firstName, lastName, emailVerified, profilePicture: photoUrl, onBoarding, profilePictureId, beta, betaPasswordUpdate } = action.payload;
        //     state.userInfos.uid = uid;
        //     state.userInfos.email = email;
        //     state.userInfos.firstName = firstName;
        //     state.userInfos.lastName = lastName;
        //     state.userInfos.emailVerified = emailVerified;
        //     state.userInfos.onBoarding = onBoarding;
        //     state.userInfos.profilePicture = photoUrl;
        //     state.userInfos.displayName = displayName;
        //     state.userInfos.profilePictureId = profilePictureId;
        //     state.userInfos.beta = beta;
        //     state.userInfos.betaPasswordUpdate = betaPasswordUpdate;
        // },
        setUserLogin: (state, action: PayloadAction<Partial<UserInfo>>) => {
            // Merge the existing state with the incoming partial data
            state.userInfos = {
                ...state.userInfos,
                ...action.payload
            };
        },
        updateUserUid: (state, action: PayloadAction<string>) =>{
            state.userInfos.uid = action.payload;
        },
        setUserLogout: (state) => {
            state.userInfos = { ...INITIAL_USER_INFOS };
        },
        setUserEmailVerified: (state) => {
            state.userInfos.emailVerified = true;
        },
        setProfilePicture: (state, action: PayloadAction<string>) => {
            state.userInfos.profilePicture = action.payload;
        },
        setUserOnboarding: (state, action: PayloadAction<boolean>) => { state.userInfos.onBoarding = action.payload },
        setDisplayName: (state, action: PayloadAction<string>) => { state.userInfos.displayName = action.payload },
        setDisplayContactNumber: (state, action: PayloadAction<string>) => { state.userInfos.contactNumber = action.payload },
        setDisplayRole: (state, action: PayloadAction<string>) => { state.userInfos.role = action.payload },
        setBetaPasswordUpdate: (state, action: PayloadAction<boolean>) => { state.userInfos.betaPasswordUpdate = action.payload },
        setLoginRoute: (state, action: PayloadAction<boolean>) => { state.loginRoute = action.payload },
        setUserDelete: (state, action: PayloadAction<boolean>) => { state.userDelete = action.payload },
    },
});

export const { setUserInfoDefault, setUserLogin, setDisplayName, setDisplayContactNumber, setDisplayRole, setUserLogout, setUserEmailVerified, setProfilePicture, setUserOnboarding, updateUserUid, setBetaPasswordUpdate, setLoginRoute, setUserDelete } = userSlice.actions;
export default userSlice.reducer;