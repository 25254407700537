const constants = {
    defaultFontSize: 14,
    defaultInputHeight: 36,

    onboardingInputHeight: 44,
    onboardingFontSize: 16,
    onboardingInputOffset: 4,

    addAlumniFontSize: 14,
    addAlumniInputHeight: 36,

    regInputHeight: 46,

    mobileScreenSize: { width: 600 }
}
export default constants;