import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BetaButton, PrimaryButton, SecondaryButton, } from "../../components/Buttons/ButtonUI";
import CustomizedModal from "../../components/CustomizedModal";
import pxToRem from "../../helpers/pxToRem";
import useTextInput from "../../hooks/useTextInput";
import { RootState } from "../../redux";
import styles from "./index.module.scss";
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import { serverTimestamp, doc, onSnapshot } from "firebase/firestore";
import { Breadcrumbs, CircularProgress, Divider, Skeleton, TextareaAutosize } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { URL_PARAMS } from "../../routers/shared";
import CustomizedPaper from "../../components/CustomizedPaper";
import { db } from "../../firebase";
import { getOrganisation } from "../../firebase/types-organisations";
import { setPageStatus } from "../../redux/pageStatusSlice";
import { Configure, Highlight, Hits, InstantSearch, Pagination, SearchBox, SortBy, useHits, usePagination, useSearchBox } from 'react-instantsearch';
import { getCurrentUserId, getUserDetails } from "../../firebase/userApi";
import dayjs from 'dayjs';
import { getTimeDiffDes2DatesMillsNow } from "../../helpers/timeUtils";
import { getUserNameById } from "../../firebase/userApis";
import AlertComponent from "../../components/AlertComponent";
import { betaStateAction } from "../../redux/betaSlice";
import TrialBanner from "./components/trialAlertBox/TrialBanner";
import PopTrialBanner from "./components/trialAlertBox/PopTrialBanner";
import SubscriptionComponent from "./components/planStatusBox";
import FreeTrialPopup from "../../components/FreeTrialPopup";
import WelcomeFreeTrialPopup from "../../components/FreeTrialPopup/WelcomeFreeTrialPopup";

const BillingPage = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userId = getCurrentUserId();
	const [updateUser, setUpdateUser] = useState("");
	const [updateTimeStr, setUpdateTimeStr] = useState("");
	const [orgEndTime, setOrgEndTime] = useState(0);
	const organisation = useSelector((state: RootState) => state.organisation);
	const orgState = useSelector((state: RootState) => state.organisation.orgState);
	const teamsLength = organisation.organisation?.users?.length;
	//Update this when the team management is working
	//const teams = useSelector((state:RootState) => state.teamState.teamMembers)


	const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
	const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
	const [orgStatus, setOrgStatus] = useState<string | null | undefined>(organisation.organisation?.status);
	const [orgSubscriptionId, setOrgSubscriptionId] = useState<string | null | undefined>(organisation.organisation?.subscriptionId);
	const [orgAdminEmail, setOrgAdminEmail] = useState<string | null | undefined>(organisation.organisation?.adminEmail);
	const [loadingStatus, setLoadingStatus] = useState(false);
	const [loadingStatusStr, setLoadingStatusStr] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [isPopupVisible, setIsPopupVisible] = useState(true);

	const handleClosePopup = () => {
		setIsPopupVisible(false);
	};


	useEffect(() => {
		const { orgId } = params;
		if (!orgId) { return; }
		const docRef = doc(db, "organisations", orgId);

		const unsubscribeOrganisation = onSnapshot(docRef, async (docSnap) => {
			if (docSnap.exists()) {
				const organisation = getOrganisation(docSnap.data());
				setOrgId(orgId);
				setOrgName(organisation.name ?? "");

				// Extract update_at and updated_by fields
				setOrgEndTime(organisation.trial_end as number);
				setOrgStatus(organisation.status);
				setOrgSubscriptionId(organisation.subscriptionId);
				setOrgAdminEmail(organisation.adminEmail);
				const updated_at = organisation.plannerUpdateAt; // Convert Firestore timestamp to JavaScript Date
				const updated_by_userId = organisation.plannerUpdateBy;
				if (updated_at && typeof (updated_at) === "number") { setUpdateTimeStr(getTimeDiffDes2DatesMillsNow(updated_at)); }
				if (organisation.status === "EXPIRED") {
					// Construct the URL to navigate to
					const dashboardUrl = `${window.location.origin}/${URL_PARAMS.DASHBOARD}`;
					
					// Use window.location.assign() to navigate and reload
					window.location.assign(dashboardUrl);
				}
				// Optionally, fetch the user's name using the user ID
				if (updated_by_userId) {
					const userRes = await getUserNameById(updated_by_userId);
					if (userRes.code === 200 && userRes.data) {
						setUpdateUser(userRes.data);
						// Now you have the updated_by user's name and can use it as needed
					}
				}

			} else {
				navigate(`/${URL_PARAMS.ERROR404}`);
			}
		});

		dispatch(setPageStatus(false));
		return () => { unsubscribeOrganisation(); }
	}, [params.orgId, dispatch, navigate]);

	return (
		<>
			<AlertComponent visible={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} severity="error" title="Error" message={errorMsg} />
			<Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
				<Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
					<Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
					<Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
					<Stack><span>Billing</span></Stack>
				</Breadcrumbs>
				<BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
			</Stack>
			<CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
				<Stack className={styles.content}>
					<Stack direction={"row"} spacing={2} style={{ alignItems: 'center', position: 'relative' }}>
						<div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
							<h6 style={{ flexGrow: 1 }} className='regular'>Billing</h6>
						</div>
					</Stack>
					<small className={`xssSpace ${styles["text--neutrals500"]}`}> Manage your billing and payment details or switch plans </small>
					<div className={`${styles.divider} horizontalDivider`}></div>
					<h6>{orgName}</h6>
					  {orgStatus === 'ACTIVE' ? (
                        <SubscriptionComponent subscriptionId={orgSubscriptionId as string} orgId={orgId as string} adminEmail={orgAdminEmail as string} seatsUsed={teamsLength ?? 0} />
                    ) : (
                        <TrialBanner isShown={true} endTime={orgEndTime as number} orgId={orgId as string} userId={userId as string}  orgState={orgState as string} />
                    )}
				</Stack>
			</CustomizedPaper>

		</>
	)
};

export default BillingPage;