import { Avatar, Stack } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import loopLogo from "../../../assets/images/shared/loopSquareLogo.png";
import { RootState } from "../../../redux";
import { setProgress } from "../../../redux/registrationSlice";
import styles from "../index.module.scss";

interface FinishRegistrationProps {

}

const FinishRegistration = (props: FinishRegistrationProps) => {
    const dispatch = useDispatch();
    const orgInfo = useSelector((state:RootState) => state.alumniReg.orgInfo);
    const logo = useSelector((state:RootState) => state.alumniReg.logo);

    useEffect(() => {
        // Reset process
        dispatch(setProgress(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            <Stack alignItems="center" style={{backgroundColor:styles.primary600, backgroundSize: 'cover', height:'100vh'}}>
                <Stack alignItems="center" justifyContent="space-evenly" className={styles.regContainer} style={{maxWidth:"400px"}}>
                        <Stack alignItems="center" className={styles.titlePageHolder} spacing={2} style={{width: '100%'}}>
                            {
                                logo ?
                                    <Avatar src={logo} className={styles.regLogo}/>
                                : 
                                    <Avatar sx={{backgroundColor: "white"}} className={styles.regLogoBg}>
                                        <Avatar src={loopLogo} className={styles.regLogo}/>
                                    </Avatar> 
                            }
                            <pre className={styles.titleText}>{orgInfo.closeStatement}</pre>
                        </Stack>
                </Stack>
            </Stack>
        </>
    );
}


export default FinishRegistration;