import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

interface AnimationContentContainerProps {
  render: (loadedAnimation: boolean) => JSX.Element;
  // Threshold to start animation
  customThreshold?: number;
}

//Animation controller compoent to detect whether componet has enter the screen and should start the animation, it control the children's animation through context
const AnimationController = ({
  render,
  customThreshold,
}: AnimationContentContainerProps) => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  //ref is used to bind the actual component so that it can be monitored, inView dictates whether this component is inside the screen
  const [ref, inView] = useInView({
    threshold: customThreshold ?? 0.2,
  });

  useEffect(() => {
    //Animation will only run once
    if (!alreadyLoaded && inView) {
      setAlreadyLoaded(true);
    }
  }, [inView]);

  return (
    <Box ref={ref}>
      <Box sx={{ width: "100%", position: "relative", overflow: "hidden" }}>
        {render(alreadyLoaded)}
      </Box>
    </Box>
  );
};

export default AnimationController;