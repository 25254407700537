import { arrayUnion, collection, deleteField, doc, DocumentData, FieldValue, getDoc, getDocs, query, runTransaction, serverTimestamp, updateDoc, where, writeBatch } from "firebase/firestore";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { db } from ".";
import { getErrorMessage } from "../helpers/errorMessageHelpers";
import { ValueOf } from "../helpers/type-helpers";
import { getIfOrganisationIsRemovable } from "./helpers";
import { Res } from "./shared";
import { getOrganisation, Organisation, Organisations, PermissionState } from "./types-organisations";
import { Campu, OrganisationSearch, OrganisationSearchs } from "./types-organisationSearchs";

const REG_COLLECTION = "registrations";

//---OLD
interface IcreateNewOrganization {
  organisationName: string;
  campu: Campu;
  userId: string;
}
export const createNewOrganization = async ({
  organisationName,
  campu,
  userId,
}: IcreateNewOrganization): Promise<
  Res<{
    organisationSearches: OrganisationSearchs;
    organisations: Organisations;
  }>
> => {
  try {
    const creationTimeStamp = moment().unix();
    const batch = writeBatch(db);
    const orgId = uuidv4();
    const campusId = uuidv4();

    // 1. Create organisation
    const organisationSearch: OrganisationSearch = {
      name: organisationName,
      campus: {
        [campusId]: campu,
      },
    };
    const organisationSearchRef = doc(db, "organisationSearchs", orgId);
    batch.set(organisationSearchRef, organisationSearch);
    // 2. Create organisationSearch
    const organisation: ValueOf<Organisations> = {
      details: {
        name: organisationName,
      },
      admins: {
        [userId]: {
          state: "CURRENT",
          lastAssigned: {
            actionedBy: "DEFAULT",
            timeStamp: creationTimeStamp,
          },
        },
      },
    };
    const organisationRef = doc(db, "organisations", orgId);
    batch.set(organisationRef, organisation);
    // 3. Bind user with organisation
    const userRef = doc(db, "users", userId);
    batch.update(userRef, {
      [`organisations.${orgId}`]: organisationName,
    });

    // Commit transaction
    await batch.commit();

    return {
      code: 200,
      data: {
        organisationSearches: {
          [orgId]: organisationSearch,
        },
        organisations: {
          [orgId]: organisation,
        },
      },
    };
  } catch (error) {
    console.log("Errors happened when trying to create new organization");
    console.log(error);

    return {
      code: 500,
    };
  }
};
export const getAllAdminsAndUsers = async (
  orgUid: string
): Promise<
  Res<{
    admins: [];
    users: [];
  }>
> => {
  try {
    const orgRef = doc(db, "organisations", orgUid);
    const docSnap = await getDoc(orgRef);
    const admins = (await docSnap.get("admins")) ?? [];
    const users = (await docSnap.get("users")) ?? [];

    return {
      code: 200,
      data: { admins, users },
    };
  } catch (error) {
    console.log(
      "Errors happen when trying to get organisation admins and users"
    );
    console.log(error);

    return {
      code: 500,
    };
  }
};
// Used for case that transaction isn't needed
// improve performance
export const getOrgDeleteOrRemoveStatusByOrgIds = async (
  userId: string,
  orgIds: string[]
): Promise<
  Res<{
    deleteableOrgs: { [orgUid: string]: string };
    removableOrgs: { [orgUid: string]: string };
  }>
> => {
  try {
    const deleteableOrgs: { [orgUid: string]: string } = {};
    const removableOrgs: { [orgUid: string]: string } = {};
    const organisations = collection(db, "organisations");
    const filteredExistingOrgsQuery = query(
      organisations,
      where("__name__", "in", orgIds)
    );
    const querySnapshot = await getDocs(filteredExistingOrgsQuery);

    querySnapshot.forEach((doc) => {
      const docId = doc.id;
      if (doc.exists()) {
        const organisation = doc.data() as ValueOf<Organisations>;
        const orgCanBeRemoved = getIfOrganisationIsRemovable(
          userId,
          organisation
        );
        if (orgCanBeRemoved !== undefined) {
          if (orgCanBeRemoved.removable) {
            removableOrgs[docId] = organisation.details.name;
          } else {
            deleteableOrgs[docId] = organisation.details.name;
          }
        }
      }
    });

    return {
      code: 200,
      data: {
        deleteableOrgs,
        removableOrgs,
      },
    };
  } catch (error) {
    console.log(
      "Errors happened when trying to query if organisation can be removed or deleted"
    );
    console.log(error);

    return {
      code: 500,
    };
  }
};
export const deleteOrganisation = async (
  orgUid: string,
  orgName: string
): Promise<Res<{ orgUid: string; orgName: string }>> => {
  try {
    const batch = writeBatch(db);
    const orgRef = doc(db, "organisations", orgUid);
    const orgSearchRef = doc(db, "organisationSearchs", orgUid);
    const deletedState = { value: "DELETED", time: moment().unix() };

    batch.update(orgRef, { state: deletedState });
    batch.update(orgSearchRef, { state: deletedState });

    await batch.commit();

    return {
      code: 200,
      data: {
        orgUid,
        orgName,
      },
    };
  } catch (error) {
    console.log("Errors happened when trying to delete organisation");
    console.log(error);

    return {
      code: 500,
    };
  }
};
export const ORG_NOT_EXIST = "Organisation not exist";
export const getOrgById = async (orgId: string): Promise<Res<string>> => {
  try {
    const docRef = doc(db, "organisations", orgId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error(ORG_NOT_EXIST);
    }

    const orgName = docSnap.data().name as string;

    return {
      code: 200,
      data: orgName,
    };
  } catch (error) {
    console.log("Errors happened when trying to");
    console.log(error);

    if (getErrorMessage(error) === ORG_NOT_EXIST) {
      return {
        code: 500,
        errorMsg: ORG_NOT_EXIST,
      };
    }

    return {
      code: 500,
    };
  }
};

export const createOrganisation = async(userId: string, organisation: Organisation) => {
    try {
        const batch = writeBatch(db);
        const organisationRef = doc(collection(db, 'organisations'));
        batch.set(organisationRef, {...organisation, admins: [userId], checklist: {createOrg: true}});

        const usersSubcol = doc(db, "organisations", organisationRef.id, "users", userId);
        batch.set(usersSubcol, {
          alumni: PermissionState.EDITOR,
          billing: PermissionState.EDITOR,
          planner: PermissionState.EDITOR,
          forms: PermissionState.EDITOR,
          engagement: PermissionState.EDITOR,
          network: PermissionState.EDITOR,
          philanthropy: PermissionState.EDITOR,
          isAdmin: true
        })

        // Update the user's document to include the new organisation ID in the organisations array
        const userRef = doc(db, 'users', userId);
        batch.update(userRef, {
          organisations: arrayUnion(organisationRef.id),
          onBoarding: true
        });

        await batch.commit();
        return {code: 200, data: "Organisation Created Successfully" };
    } 
    catch (error) {
        console.log(error);
        return { code: 500 };
    }
}


export const updateOrganisation = async(orgId: string, organisation: Organisation<FieldValue>) => {
  try {
      const docRef = doc(db, 'organisations', orgId);
      await updateDoc(docRef, organisation);
      return { code: 200, data: "Organisation Updated Successfully" };
  } 
  catch (error) {
      console.log(error);
      return { code: 500 };
  }
}

export const updateOrganisationPhoto = async(orgId:string, photoUrlId: string, photoUrl: string): Promise<Res<string>> => {
    try{
        await runTransaction(db, async (t) => {
          const docRef = doc(db, "organisations", orgId);
          const orgFormRef = doc(db, REG_COLLECTION, orgId);

          // Check for createOrg checklist
          const orgSnap = await t.get(docRef);
          if(orgSnap.exists()) {
            const org = getOrganisation(orgSnap.data());
            // If createOrg does not exist/false then user has deleted the checklist from dashboard -> don't update checklist
            if(org.checklist && org.checklist.createOrg) {
              t.update(docRef, {
                checklist: {
                  ...org.checklist,
                  uploadLogo: true
                }
              });
            }
          }
  
          const updatedData = { updated_at: serverTimestamp(), photoUrlId: photoUrlId, photoUrl: photoUrl};
          const updateForm = { logo: photoUrl};
          t.update(docRef, updatedData);
          const regSnap = await getDoc(orgFormRef);
          if (regSnap.exists()) {
              t.update(orgFormRef, updateForm);
          }
        });
        return { code: 200, data: "Update photo successfully." };
        // const batch = writeBatch(db);
        
    }
    catch{
        return { code: 500 };
    }
}

export const deleteOrganisationPhoto = async(orgId:string): Promise<Res<string>> => {
  try{
      const batch = writeBatch(db);
      const docRef = doc(db, "organisations", orgId);
      const orgFormRef = doc(db, REG_COLLECTION, orgId);
      const updatedData = {
        updated_at: serverTimestamp(),
        photoUrlId: deleteField(), // Indicates that the field should be deleted
        photoUrl: deleteField() // Indicates that the field should be deleted
      };    
      
      const updateForm = {
        logo: deleteField() // Indicates that the field should be deleted
      };

      batch.update(docRef, updatedData);
      const regSnap = await getDoc(orgFormRef);
      if (regSnap.exists()) {
          batch.update(orgFormRef, updateForm);
      }

      await batch.commit();
      return { code: 200, data: "Update photo successfully." };
  }
  catch{
      return { code: 500 };
  }
}

export const deleteOrganisationInfo = async (orgId: string): Promise<Res<string>> => {
  try {
      const batch = writeBatch(db);
      const docRef = doc(db, "organisations", orgId);

      const timestampNow = serverTimestamp(); // Firebase server timestamp for "now"
      const thirtyDaysMs = 30 * 24 * 60 * 60 * 1000; // milliseconds in 30 days

      // Calculating 30 days ahead from the current server timestamp
      const deleteExpectDate = new Date(new Date().getTime() + thirtyDaysMs);

      const updatedData = {
          updated_at: timestampNow,
          status: "TEMPDEL",
          deleteRequestDate: timestampNow,
          deleteExpectDate: deleteExpectDate // this will be a JavaScript Date object
      };

      batch.update(docRef, updatedData);
      await batch.commit();

      return { code: 200, data: "Organisation delete successfully." };
  } catch (error) {
      console.error("Failed to mark organisation for deletion:", error);
      return { code: 500 };
  }
}

export const orgCount = async (userId: string): Promise<Res<DocumentData[]>> => {
  try {
    const orgRef = collection(db, "organisations");
    const qry = query(orgRef, where("users", "array-contains", userId));
    const orgSnap = await getDocs(qry);
    return {code:200, data: orgSnap.docs}
  } catch(error) {
    console.log("Error fetching organisation information!",error);
    return {code:500};
  }

}
export const restoreOrganisationStatus = async (orgId: string, status: 'EXPIRED' | 'ACTIVE'): Promise<Res<string>> => {
  try {
      const docRef = doc(db, "organisations", orgId);
      const updatedData: any = {
          status: status,
          updated_at: serverTimestamp()
      };

      if (status === 'EXPIRED') {
          updatedData.expiredIn = serverTimestamp();
          updatedData.deleteRequestDate = deleteField(); // This removes the field if it exists
          updatedData.deleteExpectDate = deleteField();
      } else {
          updatedData.deleteRequestDate = deleteField(); // This removes the field if it exists
          updatedData.expiredIn = deleteField();
          updatedData.deleteExpectDate = deleteField();
      }

      await updateDoc(docRef, updatedData);
      return { code: 200, data: `Organisation status updated to ${status} successfully.` };
  } catch (error) {
      console.error('Error updating organisation status:', error);
      return { code: 500 };
  }
}

export const getOrganisationData = async (orgId: string): Promise<Organisation | null> => {
  const docRef = doc(db, 'organisations', orgId);
  const snapShot = await getDoc(docRef);
  if (snapShot.exists()) return getOrganisation(snapShot.data());
  return null;
};  

export const removeChecklist = async (orgId: string) => {
  try {
    const orgRef = doc(db, "organisations", orgId);
    await updateDoc(orgRef, {checklist: deleteField()});
    return {code:200}
  } catch (error) {
    return {code:500}
  }
}

