import { DocumentData, FieldValue, Timestamp } from 'firebase/firestore';

export type Url<FV = never> = {
    id?: string;
    title?: string | FV;
    url?: string | FV;
    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;
  };
  
// Helper function to convert document data to Url type
export const getUrl = (docId: string, data: DocumentData): Url => {
	return {
			id: docId,
			title: data.title,
			url: data.url,
			created_at: data.created_at ? (data.created_at as Timestamp).toMillis() : undefined,
			updated_at: data.updated_at ? (data.updated_at as Timestamp).toMillis() : undefined,
	};
};

export type FileData<FV = never> = {
	id?: string;
	title?: string | FV;
	name?: string | FV;
	size?: string | FV;
	link?: string | FV;
	uploaded_at?: FieldValue | number;
};

// Helper function to convert document data to FileData type
export const getFile = (docId: string, data: DocumentData): FileData => {
	return {
			id: docId,
			name: data.name,
			title: data.title,
			size: data.size,
			link: data.link,
			uploaded_at: data.uploaded_at ? (data.uploaded_at as Timestamp).toMillis() : undefined,
	};
};

export type PhotoData<FV = never> = {
	id?: string;
	name?: string | FV;
	size?: string | FV;
	link?: string | FV;
	originalUrl?: string | FV;
	type?: string | FV; // Additional attribute for photo type
	uploaded_at?: FieldValue | number;
};

export interface PhotosResponse {
    profile?: PhotoData;
    recent?: PhotoData;
    school?: PhotoData;
}

// Helper function to convert document data to PhotoData type
export const getPhoto = (docId: string, data: DocumentData): PhotoData => {
	return {
		id: docId,
		name: data.name,
		size: data.size,
		link: data.link,
		originalUrl: data.originalUrl,
		type: data.type,
		uploaded_at: data.uploaded_at ? (data.uploaded_at as Timestamp).toMillis() : undefined,
	};
};