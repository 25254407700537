import { Fragment, memo, useEffect, useState } from "react";
import useTextInput from "../../../hooks/useTextInput";
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import CustomizedModal from "../../../components/CustomizedModal";
import styles from "./../index.module.scss";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import Line from "../../../components/Line/Line";
import { Employment, getEmployment } from "../../../firebase/types-network";
import { collection, deleteDoc, deleteField, doc, FieldValue, onSnapshot, orderBy, query, serverTimestamp, writeBatch } from "firebase/firestore";
import { createNewEmployment, updateEmployment } from "../../../firebase/networkApis";
import { CustomizedDropdown } from "../../../components/Inputs/Dropdown/CustomizedDropdown";
import { db } from "../../../firebase";
import moment, { Moment } from "moment";
import { useParams } from "react-router-dom";
import { ITabInterface } from "./TabInterfaces";
import constants from "../../../helpers/constants";
import globalStyles from "../../../styles/_stylesParams.module.scss"
import pxToRem from "../../../helpers/pxToRem";
import CustomDatePicker from "../../../components/DatePicker";
import { getAlumniProfileError, getErrorMsgForCompanyName, getErrorMsgForInput, getErrorMsgForPostalCode, getHelpDeskMessageError } from "../../../helpers/inputValidators";
import AutoComplete from "../../../components/AutoComplete/AutoComplete";
import { valueExistsInAutocomplete } from "../../../helpers/utils";
import CustomErrorDatePicker from "../../../components/DatePicker/ErrorDatePicker";
import { PermissionState } from "../../../firebase/types-organisations";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

export type TEmployment = { id: string; label?: string; value?: string; type: "DataInitial" | "Data" | "Space" | "DataEnd" | "DataOnly"; }
export type TEmploymentRow = { id: string; rows: TEmployment[]; employment?: Employment }
export type TJobTitleUpdateStatus = 'TitleChangedStillInRole' | 'StillInRoleRemoved' | 'StillInRoleAdded' | undefined;
const EmploymentTab = ({ orgId, orgName, networkId, employmentRows, fetchedNetworkProfile, organisationIndustry, organisationJobTitle, }: ITabInterface) => {
    const [isNewEmploymentModal, setIsNewEmploymentModal] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");

    const [errorMsg, setErrorMsg] = useState("");
    const [employmentTypeDefault, setEmploymentTypeDefault] = useState({ displayText: "Full-time", value: "FULL_TIME" });

    const networkPermission = useSelector((state: RootState) => state.globalState.permissions?.network);

    const jobTitle = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });
    const [prevJobTitle, setPrevJobTitle] = useState<null | string>(null);
    const nameOfCompany = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100, undefined, true) });
    const employmentType = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100, undefined, true) });
    const industry = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100, undefined, true) });
    const [startedMonth, setStartedMonth] = useState<Moment | null>(null);
    const [startedYear, setStartedYear] = useState<Moment | null>(null);
    const [endedMonth, setEndedMonth] = useState<Moment | null>(null);
    const [endedYear, setEndedYear] = useState<Moment | null>(null);

    const [stillInRole, setStillInRole] = useState(false);
    const [prevStillInRole, setPrevStillInRole] = useState<null | boolean>(null);
    const suburb = useTextInput({ inputValidator: (input) => getErrorMsgForInput(input, 2, undefined, true) });
    const state = useTextInput({ inputValidator: (input) => getErrorMsgForInput(input, 2, undefined, true) });
    const postcode = useTextInput({ inputValidator: (input) => getErrorMsgForPostalCode(input, true) });
    const country = useTextInput({ inputValidator: (input) => getErrorMsgForInput(input, 2, undefined, true) });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [menuShowId, setMenuShowId] = useState(-1);
    const menuList = [{ label: "Edit", val: 0 }, { label: "Delete", val: 1 }, { label: "Exit", val: -1 }];
    const [editId, setEditId] = useState<string | undefined>(undefined);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [currentDeleteId, setCurrentDeleteId] = useState<string | undefined>(undefined);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); }
    const menuClose = () => { setAnchorEl(null); }

    const [startYearError, setStartYearError] = useState(false);
    const [endYearError, setEndYearError] = useState(false);

    useEffect(() => {
        console.log("DEBUGGING NETWORK ID", networkId)
        // Explicitly convert the expression result to boolean using double negation (!!)
        // Start Year Error Check
        setStartYearError(!!(startedMonth && !startedYear));
        const isEndYearRequired = !!startedYear; // End year is required if start year is provided
        setEndYearError((isEndYearRequired && !endedYear && !stillInRole) || (!!endedMonth && !endedYear));
    }, [startedMonth, startedYear, endedMonth, endedYear, stillInRole]); // Dependencies


    const employmentRowItemGroup = (tEmploymentRow: TEmploymentRow, employment: TEmployment, key: string, size: number) => {
        const onDelete = () => {
            setCurrentDeleteId(tEmploymentRow.id);
            setIsDeleteModal(true);
        }
        const onEdit = () => {
            const employmentFB: Employment | undefined = tEmploymentRow.employment;
            if (employmentFB !== undefined) {
                setEditId(tEmploymentRow.id);
                setIsNewEmploymentModal(true); //Edit Mode
                jobTitle.setValue(employmentFB.jobTitle ?? "");
                setPrevJobTitle(employmentFB.jobTitle ?? null)
                nameOfCompany.setValue(employmentFB.nameOfCompany ?? "");
                employmentType.setValue(employmentFB.employmentType ?? "")

                industry.setValue(employmentFB.industry ?? "");
                setStartedMonth(employmentFB.startedMonth ? moment(employmentFB.startedMonth, "MM") : null)
                setStartedYear(employmentFB.startedYear ? moment(employmentFB.startedYear, "YYYY") : null)
                setEndedMonth(employmentFB.endMonth ? moment(employmentFB.endMonth, "MM") : null)
                setEndedYear(employmentFB.endYear ? moment(employmentFB.endYear, "YYYY") : null)
                setStillInRole(employmentFB.stillInRole ?? false);
                setPrevStillInRole(employmentFB.stillInRole ?? null)
                suburb.setValue(employmentFB.suburb ?? "");
                state.setValue(employmentFB.state ?? "");
                postcode.setValue(employmentFB.postCode ?? "");
                country.setValue(employmentFB.country ?? "");
            }
        }

        return (
            <Fragment key={key}>
                {
                    employment.type === "DataInitial" ?
                        <tr style={{ backgroundColor: '#549189' }}>
                            <td className={styles.network_details_tableBottomTitleLeft}>{employment.value}</td>
                            <td className={styles.network_details_tableBottomTitleRight}>
                                {
                                    (!networkPermission || networkPermission === PermissionState.EDITOR) &&
                                    <Stack direction="row-reverse">
                                        <Stack className={styles.table_btn} onClick={() => onEdit()}>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12132H5.8C4.11984 3.12132 3.27976 3.12132 2.63803 3.4483C2.07354 3.73592 1.6146 4.19486 1.32698 4.75935C1 5.40109 1 6.24116 1 7.92132V16.3213C1 18.0015 1 18.8416 1.32698 19.4833C1.6146 20.0478 2.07354 20.5067 2.63803 20.7943C3.27976 21.1213 4.11984 21.1213 5.8 21.1213H14.2C15.8802 21.1213 16.7202 21.1213 17.362 20.7943C17.9265 20.5067 18.3854 20.0478 18.673 19.4833C19 18.8416 19 18.0015 19 16.3213V12.1213M6.99997 15.1213H8.67452C9.1637 15.1213 9.40829 15.1213 9.63846 15.0661C9.84254 15.0171 10.0376 14.9363 10.2166 14.8266C10.4184 14.7029 10.5914 14.53 10.9373 14.1841L20.5 4.62132C21.3284 3.79289 21.3284 2.44975 20.5 1.62132C19.6716 0.792894 18.3284 0.792893 17.5 1.62132L7.93723 11.1841C7.59133 11.53 7.41838 11.7029 7.29469 11.9048C7.18504 12.0837 7.10423 12.2788 7.05523 12.4829C6.99997 12.713 6.99997 12.9576 6.99997 13.4468V15.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                        </Stack>
                                    </Stack>
                                }
                            </td>
                        </tr>
                        :
                        employment.type === "Data" ?
                            <tr>
                                <td className={styles.network_details_tableBottomGrayBorder}>{employment.label}</td>
                                <td className={styles.network_details_tableBottomBorder}>{employment.value}</td>
                            </tr>
                            : employment.type === "DataEnd" ?
                                <tr>
                                    <td className={styles.network_details_end_tableBottomGrayBorder}>{employment.label}</td>
                                    <td className={styles.network_details_end_tableBottomBorder}>{employment.value}</td>
                                </tr>
                                : employment.type === "DataOnly" ?
                                    <tr style={{ backgroundColor: '#549189' }}>
                                        <td className={styles.network_details_only_tableBottomTitleLeft}>{employment.value}</td>
                                        <td className={styles.network_details_only_tableBottomTitleRight}>
                                            {
                                                (!networkPermission || networkPermission === PermissionState.EDITOR) &&
                                                <Stack direction="row-reverse">
                                                    <Stack className={styles.table_btn} onClick={() => onEdit()}>
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12132H5.8C4.11984 3.12132 3.27976 3.12132 2.63803 3.4483C2.07354 3.73592 1.6146 4.19486 1.32698 4.75935C1 5.40109 1 6.24116 1 7.92132V16.3213C1 18.0015 1 18.8416 1.32698 19.4833C1.6146 20.0478 2.07354 20.5067 2.63803 20.7943C3.27976 21.1213 4.11984 21.1213 5.8 21.1213H14.2C15.8802 21.1213 16.7202 21.1213 17.362 20.7943C17.9265 20.5067 18.3854 20.0478 18.673 19.4833C19 18.8416 19 18.0015 19 16.3213V12.1213M6.99997 15.1213H8.67452C9.1637 15.1213 9.40829 15.1213 9.63846 15.0661C9.84254 15.0171 10.0376 14.9363 10.2166 14.8266C10.4184 14.7029 10.5914 14.53 10.9373 14.1841L20.5 4.62132C21.3284 3.79289 21.3284 2.44975 20.5 1.62132C19.6716 0.792894 18.3284 0.792893 17.5 1.62132L7.93723 11.1841C7.59133 11.53 7.41838 11.7029 7.29469 11.9048C7.18504 12.0837 7.10423 12.2788 7.05523 12.4829C6.99997 12.713 6.99997 12.9576 6.99997 13.4468V15.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                                    </Stack>
                                                </Stack>
                                            }
                                        </td>
                                    </tr>

                                    :
                                    <tr className={styles.noBorder} >
                                        <td colSpan={3} className={styles.noBorder}>&nbsp;</td>
                                    </tr>
                }
            </Fragment>
        );
    }
    const employmentRowCreate = (tEmploymentRows: TEmploymentRow, index: string) => {
        return (<Fragment key={index}>{tEmploymentRows.rows.map((tEmplopyment, index) => employmentRowItemGroup(tEmploymentRows, tEmplopyment, tEmplopyment.id, tEmploymentRows.rows.length - 1))}</Fragment>);
    }
    const EmploymentGroup = ({ tEmploymentRowArr }: { tEmploymentRowArr: TEmploymentRow[] }) => {
        return (<Fragment key={"1_employmentarr_group"}>{tEmploymentRowArr.map((employmentInfom, index) => employmentRowCreate(employmentInfom, index + "__tera"))}</Fragment>);
    }
    const onDelete = (rowId: string) => {
        setCurrentDeleteId(rowId);
        setIsDeleteModal(true);
    }

    const convertWorkTypeToStr = (code: string): string => {
        switch (code) {
            case "FULL_TIME": return "Full-time";
            case "PART_TIME": return "Part-time";
            case "CASUAL": return "Casual";
            case "CONTRACTOR": return "Contractor";
            case "INTERN": return "Intern";
            default: return "";
        }
    }

    const [existingIds, setExistingIds] = useState<Set<string>>(new Set());
    const resetForm = () => {
        setEditId(undefined);
        setCurrentDeleteId(undefined);
        setMenuShowId(-1);

        jobTitle.setValue(""); jobTitle.setHasTouched(false);
        nameOfCompany.setValue(""); nameOfCompany.setHasTouched(false);
        employmentType.setValue(""); employmentType.setHasTouched(false);
        industry.setValue(""); industry.setHasTouched(false);
        setStartedMonth(null);
        setStartedYear(null);
        setEndedMonth(null);
        setEndedYear(null);
        setStillInRole(false);
        suburb.setValue(""); suburb.setHasTouched(false);
        state.setValue(""); state.setHasTouched(false);
        postcode.setValue(""); postcode.setHasTouched(false);
        country.setValue(""); country.setHasTouched(false);
    }
    const addData = async () => {
        if (orgId && networkId) {

            // Check if start month is provided but start year is not
            if (startedMonth && !startedYear) {
                alert("Please ensure you have a valid input for the start year.");
                return; // Exit the function early
            }
            // Check if end month is provided but end year is not
            if (endedMonth && !endedYear) {
                alert("Please ensure you have a valid input for the end year.");
                return; // Exit the function early
            }

            // Check if a start year is provided but an end year is not
            // This makes the end year mandatory when a start year is specified
            if (startedYear && !endedYear && !stillInRole) {
                alert("An end year is required when a start year is provided.");
                return; // Exit the function early
            }
            const mandatoryInputs = [jobTitle];  // Current School Validation
            let validated = true;
            mandatoryInputs.map((input) => input.setHasTouched(true));
            mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
            if (!validated) { return; }
            let optionalValidated = true;   // Optional Fields
            const optionalInput = [nameOfCompany, employmentType, industry, suburb, state, postcode, country];
            optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
            console.log("Current School Validation OPTIONAL", optionalValidated);
            if (!optionalValidated) { return; }
            setLoadingStatus(true);
            setLoadingStatusStr("Saving..");
            const employment: Employment = {
                ...(jobTitle.value && { jobTitle: jobTitle.value.trim() }),
                ...(nameOfCompany.value && { nameOfCompany: nameOfCompany.value.trim() }),
                ...(employmentType.value && { employmentType: employmentType.value.trim() }),
                ...(industry.value && { industry: industry.value.trim() }),
                ...(startedMonth && { startedMonth: parseInt(moment(startedMonth, "MMM").format("MM")) }),
                ...(startedYear && { startedYear: parseInt(moment(startedYear, "YYYY").format("YYYY")) }),
                ...(endedMonth && !stillInRole && { endMonth: parseInt(moment(endedMonth, "MMM").format("MM")) }),
                ...(endedYear && !stillInRole && { endYear: parseInt(moment(endedYear, "YYYY").format("YYYY")) }),
                ...(stillInRole && { stillInRole: stillInRole }),
                ...(suburb.value && { suburb: suburb.value.trim() }),
                ...(state.value && { state: state.value.trim() }),
                ...(postcode.value && { postCode: postcode.value.trim() }),
                ...(country.value && { country: country.value.trim() }),
                networkId: networkId,
                orgId: orgId,
                created_at: serverTimestamp(),
                updated_at: serverTimestamp()
            }
            const res = await createNewEmployment(orgId, networkId, employment, stillInRole && jobTitle.value ? jobTitle.value : undefined,
                jobTitle.value ? jobTitle.value.trim() : undefined, nameOfCompany.value ? nameOfCompany.value.trim() : undefined, industry.value ? industry.value.trim() : undefined
            );
            if (res.code === 500) { setErrorMsg("Errors: employment create."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewEmploymentModal(false);
            resetForm();
        }
    }

    const determineJobTitleUpdateStatus = (prevStillInRole: boolean | null, stillInRole: boolean, prevJobTitle: string | null, jobTitle: string): TJobTitleUpdateStatus => {
        if (stillInRole) {
            if (prevJobTitle !== jobTitle) {
                return 'TitleChangedStillInRole'; // When the job title changes, but the person is still in the role
            } else if (!prevStillInRole) {
                return 'StillInRoleAdded'; // When previously not in the role, but now in the role (regardless of job title change)
            }
        } else {
            if (prevStillInRole) {
                return 'StillInRoleRemoved'; // When the person was previously in the role but now isn't
            }
        }
        return undefined
    }

    const updateData = async (employmentId: string | undefined) => {
        if (orgId && networkId) {
            // Check if start month is provided but start year is not
            if (startedMonth && !startedYear) {
                alert("Please ensure you have a valid input for the start year.");
                return; // Exit the function early
            }
            // Check if end month is provided but end year is not
            if (endedMonth && !endedYear) {
                alert("Please ensure you have a valid input for the end year.");
                return; // Exit the function early
            }

            // Check if a start year is provided but an end year is not
            // This makes the end year mandatory when a start year is specified
            if (startedYear && !endedYear && !stillInRole) {
                alert("An end year is required when a start year is provided.");
                return; // Exit the function early
            }
            if (employmentId === undefined || employmentId === "") { return; }
            const mandatoryInputs = [jobTitle];  // Current School Validation
            let validated = true;
            mandatoryInputs.map((input) => input.setHasTouched(true));
            mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
            if (!validated) { return; }
            let optionalValidated = true;   // Optional Fields
            const optionalInput = [nameOfCompany, employmentType, industry, suburb, state, postcode, country];
            optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
            console.log("Current School Validation OPTIONAL", optionalValidated);
            if (!optionalValidated) { return; }
            setLoadingStatus(true);
            setLoadingStatusStr("Updating..");
            const employment: Employment<FieldValue> = {
                ...(jobTitle.value === "" ? { jobTitle: deleteField() } : { jobTitle: jobTitle.value.trim() }),
                ...(nameOfCompany.value === "" ? { nameOfCompany: deleteField() } : { nameOfCompany: nameOfCompany.value.trim() }),
                ...(employmentType.value === "" ? { employmentType: deleteField() } : { employmentType: employmentType.value.trim() }),
                ...(industry.value === "" ? { industry: deleteField() } : { industry: industry.value.trim() }),
                ...(startedMonth === null ? { startedMonth: deleteField() } : { startedMonth: parseInt(moment(startedMonth, "MMM").format("MM")) }),
                ...(startedYear === null ? { startedYear: deleteField() } : { startedYear: parseInt(moment(startedYear, "YYYY").format("YYYY")) }),
                ...(endedMonth === null ? { endMonth: deleteField() } : !stillInRole && { endMonth: parseInt(moment(endedMonth, "MMM").format("MM")) }),
                ...(endedYear === null ? { endYear: deleteField() } : !stillInRole && { endYear: parseInt(moment(endedYear, "YYYY").format("YYYY")) }),
                ...(stillInRole ? { stillInRole: true } : { stillInRole: false }),
                ...(suburb.value === "" ? { suburb: deleteField() } : { suburb: suburb.value.trim() }),
                ...(state.value === "" ? { state: deleteField() } : { state: state.value.trim() }),
                ...(postcode.value === "" ? { postCode: deleteField() } : { postCode: postcode.value.trim() }),
                ...(country.value === "" ? { country: deleteField() } : { country: country.value.trim() }),
                updated_at: serverTimestamp()
            }

            console.log("Still in role --- -  >", stillInRole)
            const jobTitleUpdateStatus = determineJobTitleUpdateStatus(prevStillInRole, stillInRole, prevJobTitle, jobTitle.value.trim());
            const res = await updateEmployment(orgId, networkId, employment, employmentId, fetchedNetworkProfile?.currentEmployment, { jobTitleUpdateStatus: jobTitleUpdateStatus, stillInRole: stillInRole, jobTitle: jobTitle.value.trim() },
                jobTitle.value ? jobTitle.value.trim() : undefined, nameOfCompany.value ? nameOfCompany.value.trim() : undefined, industry.value ? industry.value.trim() : undefined
            );
            if (res.code === 500) { setErrorMsg("Errors: Employment update."); }
            setLoadingStatus(false);
            setLoadingStatusStr("");
            setIsNewEmploymentModal(false);
            resetForm();
        }
    }
    const deleteData = async () => {
        if (orgId && networkId) {
            const batch = writeBatch(db);
            if (currentDeleteId === undefined) return;
            setDeleteLoadingStatus(true);
            try {
                const docEmploymentRef = doc(db, "organisations", orgId, "network", networkId, "employment", currentDeleteId);
                if (fetchedNetworkProfile?.currentEmployment) {
                    const docNetworkRef = doc(db, "organisations", orgId, "network", networkId);
                    const employmentIndex = fetchedNetworkProfile?.currentEmployment.findIndex((emp: { id: string; }) => emp.id === currentDeleteId);
                    if (employmentIndex !== -1) {
                        fetchedNetworkProfile?.currentEmployment.splice(employmentIndex, 1);
                        batch.set(docNetworkRef, { currentEmployment: fetchedNetworkProfile?.currentEmployment }, { merge: true });
                    }
                }
                batch.delete(docEmploymentRef)
                batch.commit();
            }
            catch (ex) { setErrorMsg("Errors: Employment delete, please try again"); console.log(ex); }
            setDeleteLoadingStatus(false);
            setIsDeleteModal(false);
            setCurrentDeleteId(undefined);
            resetForm();
        }
    }




    const employmentOptions = [{ label: "Full-time", value: "FULL_TIME" }, { label: "Part-time", value: "PART_TIME" }, { label: "Casual", value: "CASUAL" }, { label: "Contractor", value: "CONTRACTOR" }, { label: "Intern", value: "INTERN" }];
    return (
        <Stack>
            <CustomizedModal open={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} type='ERROR' children={<p>{errorMsg}</p>} />
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} type="ERROR" isLoading={deleteLoadingStatus} loadingStr={"Removing..."} >
                <Stack>
                    <Stack spacing={0.5} style={{ marginTop: 20, marginBottom: 10 }} className={styles.modalHeaderTitleWrapper}>
                        <label className={styles.modalTitle}>Delete Employment</label>
                        <label className={styles.modalSubTitle}>This action cannot be undone</label>
                    </Stack>
                    <Stack style={{ marginBottom: 20 }} spacing={3} className={styles.modalHeaderTitleWrapper}>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                        <label className={styles.modalBodyText}>Are you sure you want to delete this employment?</label>
                        <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    </Stack>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(undefined); resetForm() }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <WarningButton className={styles.button} onClick={() => deleteData()}> Yes, I'm Sure </WarningButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal width={660} open={isNewEmploymentModal} handleClose={() => { }} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack width={"100%"} alignItems={"end"}>
                        <IconButton onClick={() => { setIsNewEmploymentModal(false); resetForm(); }}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.network_details_modalHeaderTitleWrapper}>
                        <div className={styles.network_details_iconWithCircleWrapper}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(40)} height={pxToRem(40)} viewBox="0 0 40 40" fill="none"> <path d="M24 15C24 14.07 24 13.605 23.8978 13.2235C23.6204 12.1883 22.8117 11.3796 21.7765 11.1022C21.395 11 20.93 11 20 11C19.07 11 18.605 11 18.2235 11.1022C17.1883 11.3796 16.3796 12.1883 16.1022 13.2235C16 13.605 16 14.07 16 15M13.2 29H26.8C27.9201 29 28.4802 29 28.908 28.782C29.2843 28.5903 29.5903 28.2843 29.782 27.908C30 27.4802 30 26.9201 30 25.8V18.2C30 17.0799 30 16.5198 29.782 16.092C29.5903 15.7157 29.2843 15.4097 28.908 15.218C28.4802 15 27.9201 15 26.8 15H13.2C12.0799 15 11.5198 15 11.092 15.218C10.7157 15.4097 10.4097 15.7157 10.218 16.092C10 16.5198 10 17.0799 10 18.2V25.8C10 26.9201 10 27.4802 10.218 27.908C10.4097 28.2843 10.7157 28.5903 11.092 28.782C11.5198 29 12.0799 29 13.2 29Z" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round" /> </svg> </div>
                        <div style={{ flexGrow: 1 }}>
                            <label className={styles.network_details_modalHeaderTitle}> {(editId === undefined) ? "Add New Employment" : "Edit Employment"} </label>
                        </div>
                    </Stack>
                    <Stack className={styles.popupContent} style={{ paddingRight: pxToRem(10), marginTop: pxToRem(30), paddingTop: pxToRem(10) }}>
                        <Stack>
                            <Stack spacing={2}>
                                {/* <InputTextField containerStyle={{flex:1}} label='Job Title*' value={jobTitle.value} onChange={(e) => jobTitle.setValue(e.target.value)} error={jobTitle.hasError && jobTitle.hasTouched} helperText={<small>{jobTitle.getErrorMessage()}</small>} /> */}
                                <AutoComplete shrinkLabelWidth={52} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={jobTitle.value} label='Job Title*' inputValue={jobTitle.value} setValue={(value: string) => jobTitle.setValue(value)} options={organisationJobTitle ?? []} freeSolo error={jobTitle.hasError && jobTitle.hasTouched} helperText={<small>{jobTitle.getErrorMessage()}</small>} />
                                {/* <InputTextField containerStyle={{flex:1}} label='Name of Company' value={nameOfCompany.value} onChange={(e) => nameOfCompany.setValue(e.target.value)} error={nameOfCompany.hasError && nameOfCompany.hasTouched} helperText={<small>{nameOfCompany.getErrorMessage()}</small>} /> */}
                                <InputTextField type={"text"} shrinkLabelWidth={98} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={nameOfCompany.value} containerStyle={{ flex: 1 }} label='Name of Company' value={nameOfCompany.value} onChange={(e) => nameOfCompany.setValue(e.target.value)} error={nameOfCompany.hasError && nameOfCompany.hasTouched} helperText={<small>{nameOfCompany.getErrorMessage()}</small>} />
                                <Stack direction={"row"} alignItems='center' spacing={2} sx={{ width: '100%' }}>
                                    <Box sx={{ flex: 1 }}> {/* Wrapper with flex: 1 */}
                                        <CustomizedDropdown
                                            label="Employment Type" nonePlaceHolder="Select" noneEnable={true}
                                            valueCheck={employmentType.value}
                                            customHeight={constants.addAlumniInputHeight}
                                            fontSize={constants.addAlumniFontSize}
                                            datas={employmentOptions} value={employmentType.value ?? ""}
                                            onChange={(e) => { employmentType.setValue(e.target.value as string) }}
                                            error={employmentType.hasTouched && employmentType.value === undefined ? true : false}
                                            helperText={employmentType.hasTouched && employmentType.value === undefined ? "This field cannot be empty" : undefined}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box sx={{ flex: 1 }}> {/* Wrapper with flex: 1 */}
                                        <AutoComplete
                                            shrinkLabelWidth={48} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight}
                                            valueCheck={industry.value} label='Industry' inputValue={industry.value}
                                            setValue={(value: string) => industry.setValue(value)} options={organisationIndustry ?? []}
                                            freeSolo error={industry.hasError && industry.hasTouched}
                                            helperText={<small>{industry.getErrorMessage()}</small>}
                                        />
                                    </Box>
                                </Stack>

                                <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: pxToRem(8), marginTop: pxToRem(24) }}></div>
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    <label style={{ flex: 1 }} className="regular-400-14">Started</label>
                                    {/* <InputTextField containerStyle={{flex: 1}} label='Month' value={startedMonth.value} onChange={(e) => startedMonth.setValue(e.target.value)} error={startedMonth.hasError && startedMonth.hasTouched} helperText={<small>{startedMonth.getErrorMessage()}</small>} /> */}
                                    {/* <InputTextField type={"text"} shrinkLabelWidth={40} fontSize={constants.addAlumniFontSize}customHeight={constants.addAlumniInputHeight} valueCheck={startedMonth.value} containerStyle={{flex:1}} label='Month' value={startedMonth.value} onChange={(e) => startedMonth.setValue(e.target.value)} error={startedMonth.hasError && startedMonth.hasTouched} helperText={<small>{startedMonth.getErrorMessage()}</small>} /> */}
                                    <Stack style={{ flex: 3.5, display: 'flex' }} direction={"row"} alignItems='center' spacing={2}>
                                        <CustomErrorDatePicker label="Month" views={['month']}
                                            setCleared={() => setStartedMonth(null)}
                                            placeholder={""}
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            format="MMM"
                                            value={startedMonth ? moment(startedMonth, "MM") : null}
                                            valueCheck={startedMonth ? moment(startedMonth, "MM") : null}
                                            onChange={(value) => { if (value) { setStartedMonth(value); } }}
                                            error={startYearError}
                                            helperText={startYearError ? " " : undefined}
                                        />
                                        <CustomErrorDatePicker label="Year" views={['year']}
                                            setCleared={() => setStartedYear(null)}
                                            placeholder={""}
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            format="YYYY"
                                            valueCheck={startedYear ? moment(startedYear, "YYYY") : null}
                                            value={startedYear ? moment(startedYear, "YYYY") : null}
                                            onChange={(value) => { if (value) { setStartedYear(value); } }}
                                            error={startYearError}
                                            helperText={startYearError ? "Year is required" : undefined}
                                        />
                                    </Stack>
                                    <div style={{ flex: 1.5 }}>&nbsp;</div>
                                </Stack>
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    <label style={{ flex: 1 }} className="regular-400-14">Ended</label>
                                    <Stack style={{ flex: 3.5, display: 'flex' }} direction={"row"} alignItems='center' spacing={2}>
                                        <CustomErrorDatePicker label="Month" views={['month']}
                                            disabled={stillInRole ? true : false}
                                            setCleared={() => setEndedMonth(null)}
                                            placeholder={""}
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            format="MMM"
                                            valueCheck={endedMonth ? moment(endedMonth, "MM") : null}
                                            value={endedMonth ? moment(endedMonth, "MM") : null}
                                            onChange={(value) => { if (value) { setEndedMonth(value); } }}
                                            error={endYearError}
                                            helperText={endYearError ? " " : undefined}
                                        />
                                        <CustomErrorDatePicker label="Year" views={['year']}
                                            disabled={stillInRole ? true : false}
                                            setCleared={() => setEndedYear(null)}
                                            placeholder={""}
                                            format="YYYY"
                                            fontSize={constants.addAlumniFontSize}
                                            customHeight={constants.addAlumniInputHeight}
                                            valueCheck={endedYear ? moment(endedYear, "YYYY") : null}
                                            value={endedYear ? moment(endedYear, "YYYY") : null}
                                            onChange={(value) => { if (value) { setEndedYear(value); } }}
                                            error={endYearError}
                                            helperText={endYearError ? "Year is required" : undefined}
                                        />
                                    </Stack>
                                    {/* <InputTextField type={"text"} shrinkLabelWidth={40} fontSize={constants.addAlumniFontSize}customHeight={constants.addAlumniInputHeight} valueCheck={endedMonth.value} containerStyle={{flex:1}} label='Month' value={endedMonth.value} onChange={(e) => endedMonth.setValue(e.target.value)} error={endedMonth.hasError && endedMonth.hasTouched} helperText={<small>{endedMonth.getErrorMessage()}</small>} />
                                    <InputTextField type={"text"} shrinkLabelWidth={30} fontSize={constants.addAlumniFontSize}customHeight={constants.addAlumniInputHeight} valueCheck={endedYear.value} containerStyle={{flex:1}} label='Year' value={endedYear.value} onChange={(e) => endedYear.setValue(e.target.value)} error={endedYear.hasError && endedYear.hasTouched} helperText={<small>{endedYear.getErrorMessage()}</small>} /> */}
                                    <div style={{ flex: 1.5 }}> <FormGroup> <FormControlLabel control={<Checkbox checked={stillInRole} onChange={() => setStillInRole(!stillInRole)} />} label={<label className="regular-400-12" >Still in role</label>} /> </FormGroup> </div>
                                </Stack>
                                <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: pxToRem(8), marginTop: pxToRem(24) }}></div>
                                <label className="regular-400-14" style={{ flex: 1 }}>Location of current role</label>
                                {/* <InputTextField containerStyle={{flex:1}} label='Suburb' value={suburb.value} onChange={(e) => suburb.setValue(e.target.value)} error={suburb.hasError && suburb.hasTouched} helperText={<small>{suburb.getErrorMessage()}</small>} /> */}
                                <InputTextField type={"text"} shrinkLabelWidth={44} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={suburb.value} containerStyle={{ flex: 1 }} label='City' value={suburb.value} onChange={(e) => suburb.setValue(e.target.value)} error={suburb.hasError && suburb.hasTouched} helperText={<small>{suburb.getErrorMessage()}</small>} />
                                <Stack direction={"row"} alignItems='center' spacing={2} >
                                    {/* <InputTextField containerStyle={{flex: 1}} label='State' value={state.value} onChange={(e) => state.setValue(e.target.value)} error={state.hasError && state.hasTouched} helperText={<small>{state.getErrorMessage()}</small>} /> */}
                                    <InputTextField type={"text"} shrinkLabelWidth={34} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={state.value} containerStyle={{ flex: 1 }} label='State' value={state.value} onChange={(e) => state.setValue(e.target.value)} error={state.hasError && state.hasTouched} helperText={<small>{state.getErrorMessage()}</small>} />
                                    {/* <InputTextField containerStyle={{flex: 1}} label='Postcode' value={postcode.value} onChange={(e) => postcode.setValue(e.target.value)} error={postcode.hasError && postcode.hasTouched} helperText={<small>{postcode.getErrorMessage()}</small>} /> */}
                                    <InputTextField type={"text"} shrinkLabelWidth={55} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={postcode.value} containerStyle={{ flex: 1 }} label='Postcode' value={postcode.value} onChange={(e) => postcode.setValue(e.target.value)} error={postcode.hasError && postcode.hasTouched} helperText={<small>{postcode.getErrorMessage()}</small>} />
                                </Stack>
                                {/* <InputTextField containerStyle={{flex:1}} label='Country' value={country.value} onChange={(e) => country.setValue(e.target.value)} error={country.hasError && country.hasTouched} helperText={<small>{country.getErrorMessage()}</small>} /> */}
                                <InputTextField type={"text"} shrinkLabelWidth={48} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={country.value} containerStyle={{ flex: 1 }} label='Country' value={country.value} onChange={(e) => country.setValue(e.target.value)} error={country.hasError && country.hasTouched} helperText={<small>{country.getErrorMessage()}</small>} />
                            </Stack>
                        </Stack>
                    </Stack>
                    {/* <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: 20}}></div> */}
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: pxToRem(12), marginTop: pxToRem(24) }} />
                    <Stack direction={"row"} justifyContent='space-between'>
                        {(editId) ?
                            <WarningButton className={styles.button} onClick={() => { onDelete(editId); setIsNewEmploymentModal(false); }}> Delete </WarningButton>
                            :
                            <SecondaryButton className={styles.button} onClick={() => { setIsNewEmploymentModal(false); resetForm(); }}> Cancel </SecondaryButton>
                        }
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button}
                                onClick={() => {
                                    if (editId === undefined) addData()
                                    else updateData(editId)
                                }}
                            > Save & Close </PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack>
                <Menu id="schooling-menu" anchorEl={anchorEl} open={isMenuOpen} onClose={menuClose} autoFocus={false}>
                    {menuList.map((menu, index) => <MenuItem key={index} onClick={() => { setMenuShowId(menu.val); menuClose(); }} style={{ minWidth: 150 }}>{menu.label}</MenuItem>)}
                </Menu>
                <Stack style={{ borderRadius: pxToRem(8), backgroundColor: "#F9F9FA", alignItems: "center", justifyContent: "center", height: pxToRem(40), marginBottom: employmentRows && employmentRows.length == 0 ? 0 : pxToRem(20), padding: pxToRem(10) }} direction={"row"}>
                    <Stack style={{ fontWeight: 400, marginLeft: pxToRem(10), fontSize: pxToRem(14) }}>
                        Employment
                    </Stack>
                    <Stack style={{ flex: 1, alignItems: 'center', marginRight: pxToRem(10) }} direction="row-reverse" spacing={1.5}>
                        {/* <IconButton sx={{ '&:hover': { backgroundColor: 'transparent', }, }} style={{padding:'0px 2px'}}  onClick={menuClick}> <svg width={pxToRem(12)} height={pxToRem(20)} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="6" cy="2" r="2" transform="rotate(90 6 2)" fill="#737373"/> <circle cx="6" cy="10" r="2" transform="rotate(90 6 10)" fill="#737373"/> <circle cx="6" cy="18" r="2" transform="rotate(90 6 18)" fill="#737373"/> </svg> </IconButton> */}
                        {(!networkPermission || networkPermission === PermissionState.EDITOR) && <IconButton className={styles.buttonHover} onClick={() => setIsNewEmploymentModal(true)}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>}
                    </Stack>
                </Stack>
                <table className={styles.network_profile_page_table}>
                    {employmentRows && employmentRows.length > 0 ?
                        <tbody>
                            <EmploymentGroup tEmploymentRowArr={employmentRows} />
                        </tbody> : null}
                </table>
            </Stack>
            {
                employmentRows && employmentRows.length <= 0
                    ?
                    (!networkPermission || networkPermission === PermissionState.EDITOR) ?
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">Add New</label>
                            <IconButton onClick={() => setIsNewEmploymentModal(true)} ><svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                        :
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                            <label className="regular-400-14">No Record</label>
                            <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5" />
                        </Stack>
                    :
                    null
            }
        </Stack>
    );
}

export default memo(EmploymentTab);