import { Avatar, Divider, IconButton, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { doc, getDoc } from "firebase/firestore";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../../components/Buttons/ButtonUI";
import InputTextField from "../../../../components/Inputs/TextFields/InputTextField";
import { db } from "../../../../firebase";
import { createNewForm, editForm } from "../../../../firebase/registrationApis";
import { getOrganisation } from "../../../../firebase/types-organisations";
import { FormInfo, getForm } from "../../../../firebase/types-registration";
import constants from "../../../../helpers/constants";
import { getFormDescriptionError, getFormHeaderError, getFormTitleError } from "../../../../helpers/inputValidators";
import pxToRem from "../../../../helpers/pxToRem";
import { getTimeDiffDes2DatesMillsNow } from "../../../../helpers/timeUtils";
import { extractTwoFirstLetters } from "../../../../helpers/utils";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux";
import { setDetailsChanged } from "../../../../redux/formsSlice";
import { URL_PARAMS } from "../../../../routers/shared";
import { formatFormURL } from "../../helpers";
import styles from "../../index.module.scss";
import FormPreview from "../FormPreview";
import CustomModal from "./CustomModal";
import QRCodeModal from "./QRCodeModal";
import CustomDatePicker from "../../../../components/DatePicker";
interface IFormModal {
    formId?: string | null;
    isOpen: boolean;
    handleClose: ()=>void;
    orgName: string;
}

const FormModal = ({formId, isOpen, handleClose, orgName}: IFormModal) => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const detailsChanged = useSelector((state:RootState) => state.formsState.detailsChanged);
    const {orgId} = params;

    const [reset, setReset] = useState(false);

    const [QRCodeModalOpen, setQRCodeModalOpen] = useState(false);
    const [link, setLink] = useState("");
    const [newFormTitle, setNewFormTitle] = useState("");
    const [lastEdited, setLastEdited] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [orgLogo, setOrgLogo] = useState<string>("");
    const formTitle = useTextInput({
        inputValidator: (input: string) => { return getFormTitleError(input, 2, 64) },
    });
    const formHeader = useTextInput({
        inputValidator: (input: string) => { return getFormHeaderError(input, 2, 50) },
    });
    const formDescription = useTextInput({
        inputValidator: (input: string) => { return getFormDescriptionError(input, 2, 300) },
    });
    const formCloseStatement = useTextInput({
        inputValidator: (input: string) => { return getFormDescriptionError(input, 2, 300) },
    });
    const [defaultPeerYear, setDefaultPeerYear] = useState<Moment | null>(null);

    const handleUpload = () => {
        navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ORGANISATION_SETTINGS}`);
    }

    const handleQrModalClose = () => {
        setQRCodeModalOpen(false);
        dispatch(setDetailsChanged());
    }

    const resetForm = () => {
        formTitle.reset();
        formHeader.reset();
        formDescription.reset();
        formCloseStatement.reset();
        setDefaultPeerYear(null);
    }
    

    const handleGenerateCode = async () => {
        const mandatoryInputs = [formTitle, formHeader, formDescription, formCloseStatement];
        const optionalInputs: any[] = [];
        mandatoryInputs.map((input) => input.setHasTouched(true));
        const validated = mandatoryInputs.every(input => !input.hasError);
        if (!validated) { return; }
        const optionalValidated = optionalInputs.every(optionalInput => !(optionalInput.hasError && optionalInput.value.trim() !== ""));
        if(!optionalValidated){ return; }

        if(orgId) {
            setIsLoading(true);
            const formInfo:FormInfo = {
                header: formHeader.value,
                description: formDescription.value,
                closeStatement: formCloseStatement.value,
                defaultPeerYear: parseInt(moment(defaultPeerYear,"YYYY").format("YYYY"))
            }
            const {code, data} = await createNewForm(orgId, formInfo, formTitle.value);
            if(code === 200) {
                setLink(formatFormURL(orgId+"/"+data));
                setNewFormTitle(formTitle.value);
                setQRCodeModalOpen(true);
                handleClose();
            }
            setIsLoading(false);
            resetForm();
        }
    }

    const handleEdit = async () => {
        console.log(formDescription.value);
        if(orgId && formId) {
            setIsLoading(true);
            const updatedFormInfo:FormInfo = {
                header: formHeader.value,
                description: formDescription.value,
                closeStatement: formCloseStatement.value,
                defaultPeerYear: parseInt(moment(defaultPeerYear,"YYYY").format("YYYY"))
            };
            console.log("Editing with following info!");
            console.log(updatedFormInfo);
            const {code} = await editForm(orgId, formId, formTitle.value, updatedFormInfo);
            if(code === 200) {
                console.log("success!");
            }
            setIsLoading(false);
            dispatch(setDetailsChanged());
            handleClose();
        }

    }

    const fetchData = async () => {
        // Load org logo
        if(orgId) {
            setIsLoading(true);
            const orgRef = doc(db, "organisations", orgId);
            const snapshot = await getDoc(orgRef);
            if(snapshot.exists()) {
                const org = getOrganisation(snapshot.data());
                setOrgLogo(org.photoUrl ?? "");
            }
            // Load exisiting form data if formId is provided
            if(formId) {
                const colRef = doc(db, "registrations", orgId, "forms", formId);
                const snapshot = await getDoc(colRef);
                if(snapshot.exists()) {
                    const formData = getForm(snapshot.data());
                    formTitle.setValue(formData.formName);
                    formHeader.setValue(formData.formInfo.header || "");
                    formDescription.setValue(formData.formInfo.description || "");
                    formCloseStatement.setValue(formData.formInfo.closeStatement || "");

                    const lastEdit = (formData.lastEdited && typeof formData.lastEdited === 'number') ? getTimeDiffDes2DatesMillsNow(formData.lastEdited) : "-";
                    setLastEdited(lastEdit);
                    if(formData.formInfo.defaultPeerYear) {
                        setDefaultPeerYear(moment(formData.formInfo.defaultPeerYear, "YYYY"));
                    }
                } 
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, formId, detailsChanged, reset])

    return (
        <>
            <CustomModal open={isOpen} handleClose={handleClose} isLoading={isLoading} className={styles.modalContainer} contentStyle={`${styles["colorBanner--green"]} ${styles["modalContentWrapper"]}`}>
                <Stack spacing={2}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"}>
                        <IconButton onClick={handleClose}>
                            <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 10L10 22M10 10L22 22" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </IconButton>
                    </Stack>
                    <Stack marginTop={0}>
                        {
                            formId ? (
                                <>
                                    <Stack>
                                        <h5 className={styles["light-300-24"]}>Edit Form</h5>
                                        <small className={styles["regular-400-14"]} style={{color:styles.neutrals400}}>Last edited {lastEdited}</small>
                                    </Stack>
                                    <Divider style={{ marginTop: pxToRem(24), marginBottom: pxToRem(12)}}/>
                                </>
                            )
                            :
                                <h5 className={styles['light-300-24']} style={{lineHeight:'24px', color: styles.shades100, marginBottom:'24px'}}>Create New Form</h5>
                        }
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} spacing={3}>
                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                <Avatar onClick={() => {}} sx={{ width: pxToRem(104), height: pxToRem(104), backgroundColor: "#fff", border:'1px solid #F5F5F5' }}>
                                    <Avatar src={orgLogo} sx={{ width: pxToRem(90), height: pxToRem(90),  backgroundColor: "#AFCAFF4D" }}>
                                        <h4 style={{ color: "#608BDE"}}>{extractTwoFirstLetters(orgName)}</h4>
                                    </Avatar>
                                </Avatar>
                                <Stack alignItems={"flex-start"} justifyContent={"center"}>
                                    <p className={styles.logoUploadHeader}>Upload your organisation's logo</p>
                                    <p className={styles.logoUploadText}>This will be displayed on the cover page of the registration form</p>
                                </Stack>
                            </Stack>
                            <PrimaryButton id="uploadLogo" className={styles.uploadButton} onClick={handleUpload}>Upload Logo</PrimaryButton>
                        </Stack>
                        <Divider style={{ marginTop: pxToRem(12), marginBottom: pxToRem(24)}}/>
                        <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"} gap={pxToRem(41)}>
                            <Stack style={{flexGrow:1}}>
                                <Stack id="formContainer" gap={pxToRem(24)}>
                                    <Stack id="formTitle" spacing={1.5}>
                                        <Stack>
                                            <p className={styles.formContentTitle}>Form Title</p>
                                            <p className={styles.formContentHelper}>This will not be displayed on the form</p>
                                        </Stack>
                                        <InputTextField id={"formTitleField"} type={"text"} label={"Maximum 64 characters"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={formTitle.value} containerStyle={{flex:1}} value={formTitle.value} onChange={(e) => formTitle.setValue(e.target.value)} error={formTitle.hasError && formTitle.hasTouched} helperText={<small>{formTitle.getErrorMessage()}</small>}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": { borderColor: styles.neutrals400, },
                                                    "&:hover fieldset": { border: '2px solid #76a8a1', borderColor: styles.primary300},
                                                    "&.Mui-focused fieldset": { border: '2px solid #447d75', borderColor: styles.primary500},
                                                },                                                
                                            }}
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Stack>
                                    <h5 className={styles.modalHeader}>Customise cover page</h5>
                                    <Stack id="formHeader" spacing={1.5}>
                                        <Stack>
                                            <p className={styles.formContentTitle}>Form Header</p>
                                            <p className={styles.formContentHelper}>E.g. Congratulations graduating class of XXXX, Hello “Organisation Name” Alumni! </p>
                                        </Stack>
                                        <InputTextField id={"formHeaderField"} type={"text"} label={"Maximum 50 characters"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={formHeader.value} containerStyle={{flex:1}} value={formHeader.value} onChange={(e) => formHeader.setValue(e.target.value)} error={formHeader.hasError && formHeader.hasTouched} helperText={<small>{formHeader.getErrorMessage()}</small>} 
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": { borderColor: styles.neutrals400, },
                                                    "&:hover fieldset": { border: '2px solid #76a8a1'},
                                                    "&.Mui-focused fieldset": { border: '2px solid #447d75'},
                                                },
                                            }}
                                            inputProps={{ maxLength: 50 }}
                                        />
                                    </Stack>
                                    <Stack id="formDescription" spacing={1.5}>
                                        <Stack>
                                            <p className={styles.formContentTitle}>Form Description</p>
                                            <p className={styles.formContentHelper}>Use this space to encourage alumni to register to your alumni program</p>
                                        </Stack>
                                        <TextField
                                            sx={{
                                                    "& label.Mui-focused": { color: styles.primary400, },
                                                    "& .MuiInput-underline:after": { borderBottomColor: "#B2BAC2", },
                                                    "& .MuiInputLabel-root": { fontSize: pxToRem(constants.defaultFontSize), },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: styles.neutrals400, },
                                                        "&:hover fieldset": { border: '2px solid #76a8a1'},
                                                        "&.Mui-focused fieldset": { border: '2px solid #447d75'},
                                                        "& .MuiOutlinedInput-input": { padding: 0, fontSize: pxToRem(constants.defaultFontSize) },
                                                    },
                                            }}
                                            value={formDescription.value} onChange={(e) => {
                                                if(e.target.value.split("\n").length < 5) formDescription.setValue(e.target.value);
                                            }}
                                            id="outlined-multiline-flexible formDescField" label="Maximum 300 characters" multiline minRows={5} maxRows={5} style={{width:'100%'}}
                                            error={formDescription.hasError && formDescription.hasTouched} helperText={<small>{formDescription.getErrorMessage()}</small>}
                                            inputProps={{ maxLength: 300 }}
                                        />
                                    </Stack>
                                    <Stack id="formCloseStatement" spacing={1.5}>
                                        <Stack>
                                            <p className={styles.formContentTitle}>Closing Statement</p>
                                            <p className={styles.formContentHelper}>This will be displayed on the last page of the form</p>
                                        </Stack>
                                        <TextField
                                            sx={{
                                                    "& label.Mui-focused": { color: styles.primary400, },
                                                    "& .MuiInput-underline:after": { borderBottomColor: "#B2BAC2", },
                                                    "& .MuiInputLabel-root": { fontSize: pxToRem(constants.defaultFontSize), },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: styles.neutrals400, },
                                                        "&:hover fieldset": { border: '2px solid #76a8a1'},
                                                        "&.Mui-focused fieldset": { border: '2px solid #447d75'},
                                                        "& .MuiOutlinedInput-input": { padding: 0, fontSize: pxToRem(constants.defaultFontSize) },
                                                    },
                                            }}
                                            value={formCloseStatement.value} onChange={(e) => {
                                                if(e.target.value.split("\n").length < 5) formCloseStatement.setValue(e.target.value);
                                            }}
                                            id="outlined-multiline-flexible formCloseStatementField" label="Maximum 300 characters" multiline minRows={5} maxRows={5} style={{width:'100%'}}
                                            error={formCloseStatement.hasError && formCloseStatement.hasTouched} helperText={<small>{formCloseStatement.getErrorMessage()}</small>}
                                            inputProps={{ maxLength: 300 }}
                                        />
                                    </Stack>
                                    <Stack id="defaultPeerYear" spacing={1.5}>
                                        <Stack>
                                            <p className={styles.formContentTitle}>(Optional) Do you wish to assign a default Peer Year to this form?</p>
                                            <p className={styles.formContentHelper}>Note: Respondents will still be able to change this manually should they need to</p>
                                        </Stack>
                                        <DatePicker
                                            views={['year']}
                                            label="Select"
                                            value={defaultPeerYear}
                                            onChange={(e)=> {setDefaultPeerYear(e);}}
                                            minDate={moment(1900, "YYYY")}
                                            maxDate={moment(new Date().getFullYear(), "YYYY")}
                                            sx={{"& label.Mui-focused": {
                                                color:  styles.primary400,
                                            },
                                            "& .MuiInputBase-root": {
                                                "& fieldset": {borderColor: styles.neutrals400,},
                                                "&:hover fieldset": { border: '2px solid #76a8a1' },
                                                "&.Mui-focused fieldset": { border: '2px solid #447d75' },
                                            },
                                            }}
                                            slotProps={{
                                                actionBar: {
                                                    actions: ['clear']
                                                },
                                                field: { clearable: true, onClear: ()=>{setDefaultPeerYear(null)}},
                                                textField: {
                                                    InputLabelProps: {
                                                        sx: {
                                                            "&:not(.Mui-focused)": {
                                                                transform: !defaultPeerYear ? `translate(${pxToRem(14)}, ${pxToRem(8.5)})` : {}
                                                            }, 
                                                        }
                                                    }
                                                }
                                            }}
                                        /> 
                                    </Stack>
                                </Stack>
                                {
                                    formId && handleEdit ?
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} style={{marginTop:"40px"}}>
                                            <SecondaryButton className={styles.updateCancelBtn} onClick={() => {handleClose(); setReset(prev=>!prev)}}>Cancel</SecondaryButton>
                                            <PrimaryButton className={styles.updateFormBtn} onClick={handleEdit}>
                                                Update Form
                                            </PrimaryButton>
                                        </Stack>
                                    :
                                        <PrimaryButton id={"createFormBtn"} className={styles.generateCodeButton} onClick={handleGenerateCode}>Generate QR Code</PrimaryButton>
                                }
                            </Stack>
                            <FormPreview
                                header={formHeader.value}
                                logo={orgLogo}
                                desc={formDescription.value}
                                closeStatement={formCloseStatement.value}
                            />
                        </Stack>
                    </Stack>
                </Stack>      
            </CustomModal> 
            <QRCodeModal isOpen={QRCodeModalOpen} handleClose={handleQrModalClose} title={newFormTitle} link={link} />
        </>
    );
}

export default FormModal;