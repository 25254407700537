import { Fade } from "@mui/material";
import { useEffect, useState } from "react";
import VizSensor from "react-visibility-sensor";

interface ITransitionComponent {
    children: JSX.Element;
    timeout?: number;
    loadedAnimation: boolean
    // loop?: boolean;
}

const TransitionComponent = ({children, timeout, loadedAnimation}: ITransitionComponent) => {
    return (
        <Fade in={loadedAnimation} timeout={timeout || 1000}>
            {children}
        </Fade>
    )
}

export default TransitionComponent;