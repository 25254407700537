import sharedStatesReducer from "./sharedStateSlice";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import onBoardingReducer from "./onBoardingSlice";
import alumniReducer from "./alumniSlice";
import organisationReducer from "./organisationSlice";
import pageStatusReducer from "./pageStatusSlice";
import betaReducer from "./betaSlice";
import alumniRegReducer from "./registrationSlice"
import formsReducer from "./formsSlice";
import globalReducer from "./globalSlice";
import teamReducer from "./teamSlice";
import dashboardReducer from "./dashboardSlice";
import engagementReducer from "./engagementSlice";
import alumniDataReducer from "./alumniDataSlice"
import networkDataReducer from "./networkDataSlice"


const store = configureStore({
    reducer: {
        betaReducer: betaReducer,
        pageStatus: pageStatusReducer,
        user: userReducer,
        sharedStates: sharedStatesReducer,
        onBoarding: onBoardingReducer,
        alumni: alumniReducer,
        organisation: organisationReducer,
        alumniReg: alumniRegReducer,
        formsState: formsReducer,
        globalState: globalReducer,
        teamState: teamReducer,
        dashboardState: dashboardReducer,
        engagementState: engagementReducer,
        alumniDataState: alumniDataReducer,
        networkDataState: networkDataReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
