import { Stack } from "@mui/material";
import styles from './components.module.scss';
import { Link } from "react-router-dom";
import { URL_PARAMS } from "../../../routers/shared";
import { useEffect } from "react";

interface IFeatureCard {
    name: string;
    icon: JSX.Element;
    disabledIcon?: JSX.Element;
    link?: string;
    className?: string;
    isVariant?: boolean;
    disabled?: boolean;
    onClick?: () => void;  // Optional onClick handler
}

const FeatureCard = ({ name, icon, disabledIcon, link, className, isVariant, disabled, onClick }: IFeatureCard) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (disabled && onClick) {
            e.preventDefault(); // Prevent the link from navigating
            onClick(); // Call the provided onClick handler
        }
    };

    return (
        <Link
            to={!disabled && link ? link : '#'}
            style={{ textDecoration: 'none' }}
            onClick={handleClick} // Attach the click handler
        >
            <fieldset disabled={disabled} style={{ border: 0 }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} className={`${styles.featureCard} ${className && !disabled ? className : ''} ${disabled ? styles['featureCard--disabled'] : ''}`}>
                    <div className={disabled ? styles['featureCardIcon--disabled'] : styles['featureCardIcon']}>{disabled ? disabledIcon ? disabledIcon : icon : icon}</div>
                    <h6>{name}</h6>
                    {
                        isVariant &&
                        (disabled ?
                            <>
                                <svg className={styles.variantEclipse1} width="169" height="107" viewBox="0 0 169 107" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M168 90.5C168 139.995 130.552 180 84.5 180C38.4477 180 1 139.995 1 90.5C1 41.0048 38.4477 1 84.5 1C130.552 1 168 41.0048 168 90.5Z" stroke="#d4d4d4" strokeOpacity="0.7" strokeWidth="2" /> </svg>
                                <svg className={styles.variantEclipse2} width="142" height="128" viewBox="0 0 142 128" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M168 61.5C168 110.995 130.552 151 84.5 151C38.4477 151 1 110.995 1 61.5C1 12.0048 38.4477 -28 84.5 -28C130.552 -28 168 12.0048 168 61.5Z" stroke="#d4d4d4" strokeOpacity="0.7" strokeWidth="2" /> </svg>
                            </>

                            :
                            <>
                                <svg className={styles.variantEclipse1} width="169" height="107" viewBox="0 0 169 107" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M168 90.5C168 139.995 130.552 180 84.5 180C38.4477 180 1 139.995 1 90.5C1 41.0048 38.4477 1 84.5 1C130.552 1 168 41.0048 168 90.5Z" stroke="#9BC7C1" strokeOpacity="0.7" strokeWidth="2" /> </svg>
                                <svg className={styles.variantEclipse2} width="142" height="128" viewBox="0 0 142 128" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M168 61.5C168 110.995 130.552 151 84.5 151C38.4477 151 1 110.995 1 61.5C1 12.0048 38.4477 -28 84.5 -28C130.552 -28 168 12.0048 168 61.5Z" stroke="#9BC7C1" strokeOpacity="0.7" strokeWidth="2" /> </svg>
                            </>

                        )

                    }
                </Stack>
            </fieldset>
        </Link>
    );
}

export default FeatureCard;