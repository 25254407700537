import { IconButton, Stack } from "@mui/material";
import styles from "./components.module.scss";
import { CSSProperties, useMemo } from "react";
import pxToRem from "../../../helpers/pxToRem";
import weeklyGoalsBg from "../../../assets/images/shared/Dashboard_WeeklyGoals.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { getCurrentWeekNumber } from "../../OrganisationPage/helper";

interface IWeeklyGoalsCard {
    style?: CSSProperties;
    handleOpen: () => void;
}

const WeeklyGoalsCard = ({style, handleOpen}: IWeeklyGoalsCard) => {

    const progress = useSelector((state: RootState) => state.dashboardState.progress); 
    const goals = useSelector((state: RootState) => state.dashboardState.goals); 

    const weeklyText = useMemo(() => {
        const currentWeekNo = getCurrentWeekNumber();

        const weekProgress = progress.filter(p => p.code && p.code === currentWeekNo.toString())[0];
        const weekTasks = goals.filter(g => g.code && g.code === currentWeekNo.toString())[0]
        if(weekProgress){
            const weekTasksCompleted = weekProgress.tasks.filter(t => t.checked === true).length
    
            if(weekTasks && weekTasks.tasks.length === weekTasksCompleted) return "You have completed this week's goals!"
            return `${(weekTasks?weekTasks.tasks.length:0) - weekTasksCompleted}/${weekTasks?weekTasks.tasks.length:0} tasks left to go until you complete this week's goal!`
        }
        return `${(weekTasks?weekTasks.tasks.length:0) - 0}/${weekTasks?weekTasks.tasks.length:0} tasks left to go until you complete this week's goal!`
    }, []);

    const thisWeeksGoal = useMemo(() => {
        const currentWeekNo = getCurrentWeekNumber();
        return goals.filter(g => g.code && g.code === currentWeekNo.toString())[0]
    },[goals])

    return (
        <Stack className={styles.weeklyContainer} onClick={handleOpen} style={style}>
            <div className={styles.imgContainer}>
                <img src={thisWeeksGoal && thisWeeksGoal.imgSmall || weeklyGoalsBg}/>
            </div>
            <Stack direction='row' alignItems={'center'} style={{flexGrow:1}}>
                <Stack gap={pxToRem(24)}>
                    <div style={{gap:pxToRem(4)}}>
                        <h3>Weekly Goals</h3>
                        <small>{thisWeeksGoal && thisWeeksGoal.dashboardHeading}</small>
                    </div>
                    <p>{weeklyText}</p>
                </Stack>
                <IconButton style={{marginLeft:'auto'}}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12 24L20 16L12 8" stroke="#525252" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default WeeklyGoalsCard;