import Stack from "@mui/material/Stack";

const Error404 = (props: any) => {
    return ( 
        <Stack>
            <h5>ERROR 404</h5>
        </Stack>
    );
};

export default Error404;
