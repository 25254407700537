import { Avatar, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import formBg from "../../../assets/images/registration/registrationBgBlank.png";
import loopLogo from "../../../assets/images/shared/loopSquareLogo.png";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import { CustomizedCheckBox } from "../../../components/Checkbox";
import pxToRem from "../../../helpers/pxToRem";
import styles from "../index.module.scss";

interface IFormPreview {
    header: string;
    logo: string;
    desc: string;
    closeStatement: string;
}

enum PreviewPages {
    TITLE_PAGE = 1,
    FINAL_PAGE = 2,
}

const FormPreview = ({header, logo, desc, closeStatement}:IFormPreview) => {

    const [ page, setPage ] = useState<PreviewPages>(PreviewPages.TITLE_PAGE);

    const nextPage = () => {
        if(page===PreviewPages.TITLE_PAGE) {
            setPage(PreviewPages.FINAL_PAGE);
        }
    }

    const prevPage = () => {
        if(page===PreviewPages.FINAL_PAGE) {
            setPage(PreviewPages.TITLE_PAGE);
        }
    }

    const render = () => {
        switch(page) {
            case 1: return(
                <>
                    <Stack alignItems="center" justifyContent="center" style={{position:'relative', width: '100%', height: '100%'}} spacing={2}>
                        <h6 className={styles.previewHeader}>{header || "Form Header"}</h6>
                        <Stack alignItems="center" className={styles.titlePageHolder} spacing={2} style={{width: '100%'}}>
                            <Avatar src={logo || loopLogo} sx={{backgroundColor: "white"}} className={styles.previewRegLogoFrontPage}/>
                            <pre className={styles.previewDesc}>{desc || "Form Description"}</pre>
                        </Stack>
                        <Stack style={{width:"100%"}} alignItems="center" spacing={2}>
                            <Stack direction="row" alignItems="center">
                                <CustomizedCheckBox
                                    sx={{
                                        "& .MuiSvgIcon-root": { 
                                            fontSize: pxToRem(20)
                                        }
                                    }}
                                    style={{color: styles.neutrals0}}
                                    disabled
                                />
                                <p className={styles.previewTcs}>I agree to the Privacy Policy</p>
                            </Stack>
                            <Stack className={styles.previewButton} justifyContent="center" alignItems="center"><p>Register</p></Stack>
                            {/* <PrimaryButton className={styles.previewButton} disabled><p>Register</p></PrimaryButton> */}
                        </Stack>
                        <p className={styles.previewFooterText}>
                            Powered by Loop
                        </p>
                    </Stack>
                </>
            )
            case 2: return(
                <Stack alignItems="center" className={styles.titlePageHolder} spacing={2} style={{width:'100%'}}>
                    <Avatar src={logo || loopLogo} sx={{backgroundColor: "white"}} className={styles.previewRegLogoFrontPage}/>
                    {
                        closeStatement ? 
                            <pre className={styles.previewDesc}>{closeStatement}</pre>
                        : 
                            <p className={styles.previewDesc}>Form Close Statement</p>
                    }
                </Stack>
            )
        }
    }

    return (
        <Stack id="formPreview" alignItems={"flex-end"} justifyContent={"center"}>
            <Stack alignItems={"center"} justifyContent={"space-between"}>
                <Stack className={styles.previewContainer} alignItems={"center"} justifyContent={"center"}>
                    <h5 className={styles.previewTitle}>Preview</h5>
                    <Stack 
                        className={styles.previewScreen} alignItems={"center"} 
                        justifyContent={"center"} style={{backgroundImage:`url(${formBg})`, maxWidth:'400px'}}
                    >
                        {render()}
                    </Stack>
                </Stack>
                <Stack direction={"row"} spacing={1.5} style={{marginTop: pxToRem(24)}}>
                    <IconButton onClick={prevPage} disabled={page === PreviewPages.TITLE_PAGE}>
                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 22L13.5 16L19.5 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                    <IconButton onClick={nextPage} disabled={page === PreviewPages.FINAL_PAGE}>
                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22L19.5 16L13.5 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>


        
    );
}

export default FormPreview;