import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'; // Your SCSS module file path
import { Button, IconButton, Modal, Stack, colors } from '@mui/material';
import pxToRem from '../../../../helpers/pxToRem';
import LogoPath from "../../../../assets/images/logo/logo512.png"
import { SubscriptionInfo } from '../planStatusBox';
import { getFunctions, httpsCallable } from 'firebase/functions';
import LoadingComponent from '../../../../components/LoadingStatus/Loading';
import { setIsChanged } from '../../../../redux/formsSlice';
import { start } from 'repl';
import CustomModal from '../../../SelfRegistrationFormsPage/components/modals/CustomModal';

interface ManagePlanPopupProps {
    trialData: string;
    orgId?: string;
    userId?: string;
    subscriptionId: string;
    seatUsed: number;
    open: boolean;
    subscriptionInfo: SubscriptionInfo | null;
    onClose: () => void; // Function to call when closing the popup
    onStatusChange: (status: boolean) => void;
    onCancelState: (status: boolean) => void;
}

interface Plan {
    name: string;
    price: number
    priceDescription: string;
    description: string | null;
    category?: string;
}

interface PlanCardProps {
    plan: Plan;
    selected: boolean;
    disable?: boolean;
    currentPlanCost?: number;
    onClick: (planPrice: number) => void;
}

export const plans: Plan[] = [
    { name: 'Annually', price: 2700, priceDescription: 'A$2,700 paid annually for 1-3 users (Unlock 2 months free)', description: ' (Best value - save 16.6%)', category: 'Basic' },
    { name: 'Annually Premium', price: 3500, priceDescription: 'A$3,500 paid annually for 4-10 users (Unlock 2 months free)', description: ' (Best value - save 16.6%)', category: 'Premium' },
    { name: 'Monthly', price: 270, priceDescription: 'A$270 paid monthly for 1-3 users', description: null, category: 'Basic' },
    { name: 'Monthly Premium', price: 350, priceDescription: 'A$350 paid monthly for 4-10 users', description: null, category: 'Premium' }
];

export const currentPlans: Plan[] = [
    { name: 'Annually', price: 2700, priceDescription: 'A$2,700 paid annually', description: null },
    { name: 'Annually Premium', price: 3500, priceDescription: 'A$3,500 paid annually', description: null },
    { name: 'Monthly', price: 270, priceDescription: 'A$270 paid monthly', description: null },
    { name: 'Monthly Premium', price: 350, priceDescription: 'A$350 paid monthly', description: null }
];


export const PlanCard: React.FC<PlanCardProps> = ({ disable, plan, selected, onClick, currentPlanCost }) => {
    return (
        <Stack className={`${styles.planCard} ${selected ? styles.planCardSelected : ''} ${disable ? styles.planCardDisabled : ''}`} onClick={() => onClick(plan.price)}>
            <Stack className={styles.planCardHeader} direction={'row'}>
                <span>{plan.name}</span>
                {currentPlanCost == plan.price && <span className={styles.smallGray}>(Current Plan)</span>}
                {(currentPlanCost != plan.price && plan.description) && <span className={styles.smallGray}>{plan.description}</span>}
            </Stack>
            <span className={styles.smallGray}>{plan.priceDescription}</span>
        </Stack>
    );
};


const ManagePlanPopup: React.FC<ManagePlanPopupProps> = ({ trialData, subscriptionInfo, subscriptionId, seatUsed, orgId, userId, open, onClose, onStatusChange, onCancelState }) => {
    const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
    const [selectedPlanType, setSelectedPlanType] = useState<'monthly' | 'yearly'>('monthly');
    const [selectedPlanLevel, setSelectedPlanLevel] = useState<'lower' | 'higher'>('lower');
    const [isUpgrade, setIsUpgrade] = useState<boolean | null>(null);
    const [isReviewOpen, setIsReviewOpen] = useState<boolean>(false);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [showCancel, setShowCancel] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<'Basic' | 'Premium'>('Basic');

    const handlePlanSelect = (planPrice: number) => {
        // Assume that you have a way to determine the current plan's price
        const currentPrice = subscriptionInfo?.subscriptionCost ?? 270;

        // Check if the selected plan price is higher or lower than the current price
        setIsUpgrade(planPrice > currentPrice);
        setSelectedPlan(planPrice);

        // Determine the plan type and level based on the price
        switch (planPrice) {
            case 270:
                setSelectedPlanType('monthly');
                setSelectedPlanLevel('lower');
                break;
            case 350:
                setSelectedPlanType('monthly');
                setSelectedPlanLevel('higher');
                break;
            case 2700:
                setSelectedPlanType('yearly');
                setSelectedPlanLevel('lower');
                break;
            case 3500:
                setSelectedPlanType('yearly');
                setSelectedPlanLevel('higher');
                break;
            default:
                // Handle the case where the price doesn't match known values
                console.log('Unknown plan price selected.');
        }
    };

    useEffect(() => {
        // Reset the plan selection when popup is closed
        if (!open) {
            setSelectedPlan(null);
            setIsSelectOpen(false);
            setIsReviewOpen(false);
            setSelectedCategory('Basic');
            setShowCancel(false);
        }
    }, [open]);

    const CurrentPlanCard: React.FC<PlanCardProps> = ({ disable, plan, selected, onClick }) => {
        return (
            <Stack className={`${styles.planCurrentCard}`} onClick={() => onClick(plan.price)} display={'row'}>
                <Stack className={styles.planCurrentCardHeader}>
                    <Stack direction={'column'}>
                        <span>{plan.name}</span>
                        <span className={styles.smallGray}>{plan.priceDescription}</span>
                    </Stack>
                    <Stack>
                        <Button className={styles.manageButton} onClick={() => setIsSelectOpen(true)}>
                            Change
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        );
    };

    const handleConfirm = async () => {
        setIsLoading(true)

        const functions = getFunctions();
        const currentPlanType = subscriptionInfo?.planType;
        const currentPlanLevel = (subscriptionInfo?.planType === "Monthly" && subscriptionInfo?.subscriptionCost < 300) || (subscriptionInfo?.planType === "Annually" && subscriptionInfo?.subscriptionCost < 3000) ? 'lower' : 'higher';
        const functionName = determineFunctionName(currentPlanType as string, currentPlanLevel, selectedPlanType, selectedPlanLevel);

        const callCloudFunction = httpsCallable(functions, functionName);
        try {
            const result = await callCloudFunction({ subscriptionId });
            console.log('Operation successful:', result.data);
            setIsLoading(false);
            onStatusChange(true); // Notify parent of success
            onClose();
        } catch (error) {
            console.error('Operation failed:', error);
            onStatusChange(false); // Notify parent of failure
            setIsLoading(false);
            onClose(); // Optionally close modal or update UI
        }
    };

    function determineFunctionName(currentType: string, currentLevel: string, selectedType: string, selectedLevel: string): string {
        if (currentType === 'Monthly' && selectedType === 'yearly') {
            if (currentLevel === 'lower' && selectedLevel === 'higher') {
                return 'upgradeFromLowerMonthlyToUpperYearly';
            } else if (currentLevel === 'higher' && selectedLevel === 'lower') {
                return 'upgradeFromHigherrMonthlyToLowerYearly';
            } else {
                return 'upgradeToYearlyPlan'; // covers both lower to lower and lower to higher transitions
            }
        } else if (currentType === 'Annually' && selectedType === 'monthly') {
            if (currentLevel === 'higher' && selectedLevel === 'lower') {
                return 'downgradeFromHigherYearlyToLowerMonthly';
            } else {
                return 'downgradeYearlyToMonthly'; // covers both lower to lower and lower to higher transitions
            }
        } else if (currentLevel === 'lower' && selectedLevel === 'higher') {
            return 'upgradeToAdvancedPlan';
        } else if (currentLevel === 'higher' && selectedLevel === 'lower') {
            return 'downgradeToNormalPlan';
        }
        return 'upgradeToAdvancedPlan'; // Default to a basic upgrade if conditions are unclear
    }

    const handleContinue = () => {
        if (!selectedPlan) return;
        // Handle the continue action, possibly navigating to a checkout or confirmation page
        setIsReviewOpen(true);
    };

    const handleCancelReview = () => {
        setIsReviewOpen(false);
    };

    const handleCancelCancel = () => {
        setShowCancel(false);
    };


    const getFilteredPlans = (selectedCategory: 'Basic' | 'Premium') => {
        return plans
            .filter(plan => plan.category === selectedCategory)
            .map(plan => ({
                ...plan,
                disable: shouldDisablePlan(plan)
            }));
    };

    const shouldDisablePlan = (plan: Plan) => {
        switch (subscriptionInfo?.subscriptionCost) {
            case 270:
                return plan.price === 270;
            case 350:
                if (seatUsed <= 3) {
                    return !(plan.price === 3500 || plan.price === 2700 || plan.price === 270);
                } else {
                    return plan.price !== 3500;
                }
            case 2700:
                return plan.price !== 3500;
            case 3500:
                if (seatUsed <= 3) {
                    return plan.price !== 2700;
                } else {
                    return true; // Disable all
                }
            default:
                return false; // No plan is disabled if current price doesn't match known values
        }
    };

    const filteredPlans = getFilteredPlans(selectedCategory);

    const cancelSubscription = async (subscriptionId: string) => {
        setIsLoading(true)
        const functions = getFunctions(); // getFunctions(firebaseApp, 'australia-southeast1') if you have multiple apps
        functions.region = 'australia-southeast1'; // Set the region here
        const cancel = httpsCallable(functions, 'cancelSubscription');
        try {
            const result = await cancel({ subscriptionId });
            setIsLoading(false);
            onCancelState(true); // Notify parent of success
            onClose();
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error cancelling subscription:', error.message);
                onClose();
                alert('Failed to cancel subscription. Please try again.');
            } else {
                console.error('Unknown error:', error);
                onClose();
                alert('An unexpected error occurred.');
            }
        }
    };

    return (
        <>
            <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <div className={styles.popupBackdrop}>
                    </div>
                    {isLoading ? (

                        <div className={styles.popup}>
                            <LoadingComponent isLoading={isLoading} />
                        </div>
                    ) : showCancel ? (
                        <div className={styles.popup}>
                            <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </IconButton>
                            <Stack className={styles.contentBoxSmall} direction={'column'} spacing={4}>
                                <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                    <svg width={pxToRem(56)} height={pxToRem(56)} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <clipPath id="circleView">
                                                <circle cx="28" cy="28" r="28" />
                                            </clipPath>
                                        </defs>
                                        <circle cx="28.5" cy="28" r="27.5" fill="white" stroke="#76A8A1" />
                                        <image
                                            clipPath="url(#circleView)"
                                            href={LogoPath}
                                            x='5' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                                            y='5' // Same calculation for y
                                            height='48'
                                            width='48'
                                        />
                                    </svg>
                                    <h3 className={styles.head3}>Cancel Plan</h3>
                                </Stack>
                                {subscriptionInfo && subscriptionInfo.status === 'trialing' ? (
                                    <Stack className={styles.blackReminderBox}>
                                        We're sorry to see you go!
                                        <br /><br />
                                        Your subscription will be canceled at the end of your 14 day free trial, on {subscriptionInfo?.nextPaymentDate}
                                    </Stack>
                                ) : (
                                    <Stack className={styles.blackReminderBox}>
                                        We're sorry to see you go!
                                        <br />
                                        <br />You will have access to this organisation until the end of your billing period on {subscriptionInfo?.nextPaymentDate}
                                    </Stack>
                                )}

                                <Stack className={styles.blackReminderBox}>
                                    Are you sure you want to continue?
                                </Stack>

                                {/* <Stack className={styles.planCurrent}>
                                    <small className={styles.neutrals500}>
                                        CURRENT PLAN
                                    </small>

                                    <PlanCard
                                        plan={currentPlans.find(p => p.price === subscriptionInfo?.subscriptionCost)!}
                                        selected={false}
                                        onClick={() => { }}
                                    />
                                </Stack> */}
                                <Stack >
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={50} marginTop={pxToRem(50)}>
                                        <Button onClick={handleCancelCancel} className={styles.continueButton}>Back</Button>
                                        <Button onClick={() => cancelSubscription(subscriptionId)} className={styles.continueButton}>Confirm</Button>
                                    </Stack>

                                </Stack>

                            </Stack>
                        </div>
                    ) : (


                        <div className={styles.popup}>
                            <IconButton className={styles.closeButton} onClick={onClose}><svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </IconButton>
                            {!isReviewOpen ? (
                                <>
                                    {!isSelectOpen ? (
                                        <>
                                            <Stack className={styles.contentBoxSmall} direction={'column'} spacing={4}>
                                                <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                                    <svg width={pxToRem(56)} height={pxToRem(56)} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <defs>
                                                            <clipPath id="circleView">
                                                                <circle cx="28" cy="28" r="28" />
                                                            </clipPath>
                                                        </defs>
                                                        <circle cx="28.5" cy="28" r="27.5" fill="white" stroke="#76A8A1" />
                                                        <image
                                                            clipPath="url(#circleView)"
                                                            href={LogoPath}
                                                            x='5' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                                                            y='5' // Same calculation for y
                                                            height='48'
                                                            width='48'
                                                        />
                                                    </svg>
                                                    <h3 className={styles.head3}>Manage your Plan</h3>
                                                </Stack>


                                                {subscriptionInfo && subscriptionInfo.status === 'trialing' ? (
                                                    <Stack className={styles.blackReminderBox}>
                                                        Your subscription will take effect at the end of your 14 day free trial, on {subscriptionInfo?.nextPaymentDate}
                                                    </Stack>
                                                ) : (
                                                    <Stack className={styles.blackReminderBox}>
                                                        Your subscription will renew automatically on {subscriptionInfo?.nextPaymentDate}
                                                    </Stack>
                                                )}



                                                <Stack className={styles.planCurrent}>
                                                    <small className={styles.neutrals500}>
                                                        CURRENT PLAN
                                                    </small>

                                                    <CurrentPlanCard
                                                        plan={currentPlans.find(p => p.price === subscriptionInfo?.subscriptionCost)!}
                                                        selected={false}
                                                        onClick={() => { }}
                                                    />
                                                    <Stack justifyContent={'start'} alignItems={'start'}>
                                                        <Button className={styles.cancelButton} onClick={() => setShowCancel(true)}>
                                                            Cancel Plan
                                                        </Button>

                                                    </Stack>



                                                </Stack>
                                            </Stack>
                                        </>
                                    ) : (
                                        <>
                                            <Stack className={styles.contentBox} direction={'column'} spacing={4}>
                                                <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                                    <svg width={pxToRem(56)} height={pxToRem(56)} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <defs>
                                                            <clipPath id="circleView">
                                                                <circle cx="28" cy="28" r="28" />
                                                            </clipPath>
                                                        </defs>
                                                        <circle cx="28.5" cy="28" r="27.5" fill="white" stroke="#76A8A1" />
                                                        <image
                                                            clipPath="url(#circleView)"
                                                            href={LogoPath}
                                                            x='5' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                                                            y='5' // Same calculation for y
                                                            height='48'
                                                            width='48'
                                                        />
                                                    </svg>
                                                    <h3 className={styles.head3}>Select a new payment plan</h3>
                                                </Stack>
                                                <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                                    <small className={styles.smallGray}>
                                                        Learn more about our pricing
                                                    </small>
                                                    <a href="/pricing" target="_blank" rel="noopener noreferrer" className={styles.linkText}>here</a>
                                                </Stack>

                                                <>
                                                    {selectedCategory === 'Basic' && seatUsed > 3 ? (

                                                        <Stack className={styles.greenBox}>
                                                            Our basic plan accommodates 1-3 users. To select this plan, please reduce the number of users on your team.
                                                        </Stack>
                                                    ) : (
                                                        <Stack className={styles.blackReminderBox}>
                                                            Your new subscription will come into effect immediately.
                                                        </Stack>

                                                    )}
                                                 

                                                </>

                                                <Stack className={styles.planSelection}>
                                                    <Stack className={styles.tabBackground} direction="row" spacing={1}>
                                                        <Button
                                                            className={`${styles.tabButton} ${selectedCategory === 'Basic' ? styles.selected : ''}`}
                                                            onClick={() => setSelectedCategory('Basic')}
                                                        >
                                                            Basic
                                                        </Button>
                                                        <Button
                                                            className={`${styles.tabButton} ${selectedCategory === 'Premium' ? styles.selected : ''}`}
                                                            onClick={() => setSelectedCategory('Premium')}
                                                        >
                                                            Premium
                                                        </Button>
                                                    </Stack>
                                                    {filteredPlans.map(plan => (
                                                        <PlanCard
                                                            key={plan.price}
                                                            plan={plan}
                                                            selected={selectedPlan === plan.price}
                                                            currentPlanCost={subscriptionInfo?.subscriptionCost}
                                                            disable={plan.disable}
                                                            onClick={plan.disable ? () => { } : handlePlanSelect} // Disable onClick if the plan is disabled
                                                        />
                                                    ))}
                                                       <Stack className={styles.XpopupHeader} direction={'row'} spacing={1}>
                                                        <small className={styles.xsmallGray}>  Having issues with your current plan? </small>
                                                        <a href="/dashboard/account-settings?help=true" target="_blank" rel="noopener noreferrer" className={styles.xslinkText}>Contact Us</a>
                                                    </Stack>


                                                </Stack>
                                            </Stack>
                                            {selectedPlan && (<Stack alignItems={'end'} justifyContent={'end'} width={'100%'} maxWidth={pxToRem(450)}>
                                                <Button onClick={handleContinue} className={styles.continueButton}>Continue</Button>
                                            </Stack>)}
                                        </>
                                    )}
                                </>


                            ) : (
                                <Stack className={styles.contentBoxSmall} direction={'column'} spacing={4}>
                                    <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                        <svg width={pxToRem(56)} height={pxToRem(56)} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <clipPath id="circleView">
                                                    <circle cx="28" cy="28" r="28" />
                                                </clipPath>
                                            </defs>
                                            <circle cx="28.5" cy="28" r="27.5" fill="white" stroke="#76A8A1" />
                                            <image
                                                clipPath="url(#circleView)"
                                                href={LogoPath}
                                                x='5' // Half of 128 (circle diameter) minus half of 102.4 (image size)
                                                y='5' // Same calculation for y
                                                height='48'
                                                width='48'
                                            />
                                        </svg>
                                        <h3 className={styles.head3}>Review Plan</h3>
                                    </Stack>
                                    <Stack className={styles.popupHeader} direction={'row'} spacing={1}>
                                        <small className={styles.smallGray}>
                                            Learn more about our pricing
                                        </small>
                                        <a href="/pricing" target="_blank" rel="noopener noreferrer" className={styles.linkText}>here</a>
                                    </Stack>
                                    {isUpgrade ? (
                                        <Stack className={styles.blackReminderBox}>
                                            Your plan will come into effect today, you will get the credit for time remaining in the billing cycle and get charged for the upgrade immediately.
                                        </Stack>
                                    ) : (
                                        <Stack className={styles.blackReminderBox}>
                                            Your plan will come into effect immediately, you will get charged for the new plan price at the beginning of your next billing cycle, {subscriptionInfo?.nextPaymentDate}
                                        </Stack>
                                    )}
                                    <Stack marginTop={pxToRem(20)}>
                                        <PlanCard
                                            plan={plans.find(p => p.price === selectedPlan)!}
                                            selected={true}
                                            onClick={() => { }}
                                        />
                                    </Stack>

                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={50}>
                                        <Button onClick={handleCancelReview} className={styles.continueButton}>Cancel</Button>
                                        <Button onClick={handleConfirm} className={styles.continueButton}>Confirm</Button>
                                    </Stack>
                                </Stack>)}

                        </div>

                    )}
                </div>

            </Modal>
        </>

    );
};

export default ManagePlanPopup;