import {Box, Divider, IconButton, Stack, Typography, useMediaQuery} from "@mui/material";
import { Link } from 'react-router-dom';
import globalStyles from "../../../../styles/_stylesParams.module.scss";
import styles from './index.module.scss';
import {URL_PARAMS} from "../../../../routers/shared";
import pxToRem from "../../../../helpers/pxToRem";
import { useEffect, useState } from "react";
import { getTermsConditions, getCookiesNotice, getPrivacyPolicy, getSiteTerms } from "../../../../firebase/landingApis";
import loopFullLogo from "../../../../assets/images/landingPage/newPage/loop-full-logo.png";

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const [siteTermsLink, setSiteTermsLink] = useState("");
    const [privacyLink, setPrivacyLink] = useState("");
    const [cookiesLink, setCookiesLink] = useState("");

    const fetchLinks = async () => {
        const tlink = await getSiteTerms();
        const plink = await getPrivacyPolicy();
        const clink = await getCookiesNotice();
        setSiteTermsLink(tlink);
        setPrivacyLink(plink);
        setCookiesLink(clink);
    }

    useEffect(() => {
        fetchLinks(); 
    }, [])

    return (
        <Stack className={styles.footerContainer} >
            <Stack direction='row' alignItems="center" justifyContent="space-between" width='100%'>
                <Box
                    component={"img"}
                    className={styles.logo}
                    alt="Loop Full Logo"
                    src={loopFullLogo}
                />
                <Stack direction='row' justifyContent="flex-start" flexWrap='wrap' columnGap={pxToRem(56)} rowGap={pxToRem(28)}>
                    <Stack sx={{minWidth: pxToRem(94)}}> <p className={styles.footerTitle}>Join Loop</p> <Link className={styles.footerSubtitle} to={`/${URL_PARAMS.LEARN_MORE}`}>Learn More</Link> </Stack>
                    <Stack sx={{minWidth: pxToRem(130)}}> <p className={styles.footerTitle}>Keep in Touch</p> <Link className={styles.footerSubtitle} to={`/${URL_PARAMS.CONTACT_US}`}>Contact</Link> </Stack>
                    <Stack sx={{minWidth: pxToRem(108)}}> 
                        <p className={styles.footerTitle}>About Loop</p>
                        <Link className={styles.footerSubtitle} to={`/${URL_PARAMS.ABOUT}`}>About Us</Link> 
                        {/* <Link className={styles.footerSubtitle} href={`/${URL_PARAMS.PRICING}`}>Pricing</Link>  */}
                    </Stack>
                </Stack>
            </Stack>
            <Divider sx={{marginTop: styles.mediumSpace, marginBottom: styles.mediumSpace, height: '2px'}} color={styles.primary600}/>
            <Stack justifyContent='space-between' alignItems="center" direction='row' width={'100%'} flexWrap="wrap"  color={globalStyles.primary600} gap={pxToRem(20)}>
                <Typography className={styles.moreInfo}>
                    © 2024 Loop&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={siteTermsLink} target="_blank">Terms of Use</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={privacyLink} target="_blank">Privacy Notice</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={cookiesLink} target="_blank">Cookies Notice</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    ABN 47 654 729 932
                </Typography>
                <Stack direction='row' alignItems='center' justifyContent="flex-end" gap={pxToRem(16)} flexGrow={1}>
                    <Link to={URL_PARAMS.LINKEDIN}>
                        <svg width={pxToRem(22)} height={pxToRem(22)} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.9179 0H1.52736C0.684679 0 0 0.667221 0 1.48629V19.1285C0 19.9476 0.684679 20.6148 1.52736 20.6148H19.9179C20.7606 20.6148 21.4501 19.9476 21.4501 19.1285V1.48629C21.4501 0.667221 20.7606 0 19.9179 0ZM6.4829 17.6699H3.30369V7.8318H6.48769V17.6699H6.4829ZM4.8933 6.48815C3.87346 6.48815 3.04993 5.69209 3.04993 4.71656C3.04993 3.74104 3.87346 2.94498 4.8933 2.94498C5.90835 2.94498 6.73666 3.74104 6.73666 4.71656C6.73666 5.69669 5.91313 6.48815 4.8933 6.48815ZM18.4001 17.6699H15.2209V12.8843C15.2209 11.7431 15.197 10.2752 13.5691 10.2752C11.9124 10.2752 11.6587 11.5176 11.6587 12.8014V17.6699H8.47948V7.8318H11.5294V9.17544H11.5725C11.9986 8.40239 13.0376 7.58792 14.5841 7.58792C17.8016 7.58792 18.4001 9.62639 18.4001 12.2769V17.6699Z" fill="#D4D4D4"/> </svg>
                    </Link>
                    <Link to={URL_PARAMS.FACEBOOK}>
                        <svg width={pxToRem(22)} height={pxToRem(21)} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.4513 0H2.59769C1.98816 0 1.4036 0.232705 0.972603 0.646923C0.541602 1.06114 0.299469 1.62294 0.299469 2.20873L0.299469 18.4061C0.299469 18.9919 0.541602 19.5537 0.972603 19.9679C1.4036 20.3821 1.98816 20.6148 2.59769 20.6148H9.16917V13.6063H6.15275V10.3074H9.16917V7.79314C9.16917 4.9333 10.9407 3.35359 13.6541 3.35359C14.9535 3.35359 16.3123 3.57631 16.3123 3.57631V6.38324H14.8151C13.34 6.38324 12.8798 7.26305 12.8798 8.16541V10.3074H16.173L15.6463 13.6063H12.8798V20.6148H19.4513C20.0608 20.6148 20.6454 20.3821 21.0764 19.9679C21.5074 19.5537 21.7495 18.9919 21.7495 18.4061V2.20873C21.7495 1.62294 21.5074 1.06114 21.0764 0.646923C20.6454 0.232705 20.0608 0 19.4513 0Z" fill="#D4D4D4"/> </svg>
                    </Link>
                    <Link to={URL_PARAMS.INSTAGRAM}>
                        <svg width={pxToRem(23)} height={pxToRem(21)} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.3367 5.02372C8.2916 5.02372 5.83538 7.3843 5.83538 10.3109C5.83538 13.2374 8.2916 15.598 11.3367 15.598C14.3819 15.598 16.8381 13.2374 16.8381 10.3109C16.8381 7.3843 14.3819 5.02372 11.3367 5.02372ZM11.3367 13.7482C9.36889 13.7482 7.76014 12.2067 7.76014 10.3109C7.76014 8.41504 9.36411 6.87353 11.3367 6.87353C13.3094 6.87353 14.9134 8.41504 14.9134 10.3109C14.9134 12.2067 13.3046 13.7482 11.3367 13.7482ZM18.3463 4.80744C18.3463 5.49307 17.7718 6.04065 17.0632 6.04065C16.3497 6.04065 15.78 5.48847 15.78 4.80744C15.78 4.12642 16.3545 3.57424 17.0632 3.57424C17.7718 3.57424 18.3463 4.12642 18.3463 4.80744ZM21.99 6.05906C21.9086 4.40711 21.516 2.94383 20.2567 1.73823C19.0023 0.532627 17.4797 0.155301 15.7608 0.072474C13.9893 -0.024158 8.67943 -0.024158 6.90788 0.072474C5.19379 0.1507 3.67122 0.528025 2.41199 1.73362C1.15275 2.93922 0.764926 4.40251 0.678743 6.05446C0.578196 7.75702 0.578196 12.8601 0.678743 14.5627C0.760138 16.2146 1.15275 17.6779 2.41199 18.8835C3.67122 20.0891 5.189 20.4664 6.90788 20.5493C8.67943 20.6459 13.9893 20.6459 15.7608 20.5493C17.4797 20.471 19.0023 20.0937 20.2567 18.8835C21.5112 17.6779 21.9038 16.2146 21.99 14.5627C22.0905 12.8601 22.0905 7.76162 21.99 6.05906ZM19.7013 16.3895C19.3279 17.2914 18.6049 17.9862 17.6616 18.3497C16.2492 18.8881 12.8976 18.7639 11.3367 18.7639C9.77587 18.7639 6.41951 18.8835 5.01185 18.3497C4.07341 17.9908 3.35043 17.296 2.97218 16.3895C2.41199 15.032 2.54126 11.811 2.54126 10.3109C2.54126 8.81077 2.41677 5.5851 2.97218 4.23225C3.34564 3.33035 4.06862 2.63552 5.01185 2.272C6.4243 1.73362 9.77587 1.85787 11.3367 1.85787C12.8976 1.85787 16.254 1.73823 17.6616 2.272C18.6001 2.63092 19.3231 3.32575 19.7013 4.23225C20.2615 5.5897 20.1322 8.81077 20.1322 10.3109C20.1322 11.811 20.2615 15.0366 19.7013 16.3895Z" fill="#D4D4D4"/> </svg>
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    );
}


export default Footer;