import { useEffect, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Stack, IconButton } from "@mui/material";
import dayjs from 'dayjs';
import styles from "./ImportHistoryTable.module.scss";
import pxToRem from "../../../helpers/pxToRem";
import { collection, FirestoreError, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { getImportAlumni, ImportAlumni } from "../../../firebase/types-alumni";
import AlertComponent, { AlertDialogComponent, AlertDialogLoadingComponent, AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import { revertImportAlumni } from "../../../firebase/alumniApis";
import globalStyles from "../../../styles/_stylesParams.module.scss";

interface ImportHistoryTableProps {
    orgId: string;
    trigger: boolean;
}
export const ImportHistoryTable: React.FC<ImportHistoryTableProps> = ({ orgId, trigger }) => {
    const [importHistory, setImportHistory] = useState<ImportAlumni[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [finishDelete, setFinishDelete] = useState(false);
    const [revertFile, setRevertFile] = useState<ImportAlumni<never> | undefined>(undefined);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const fetchImportHistory = async () => {
        try {
            const importHistoryRef = collection(db, "organisations", orgId, "ImportHistory");
            const snapshot = await getDocs(importHistoryRef);
            const historyData = snapshot.docs.map(doc => getImportAlumni(doc.data(), doc.id));
            setImportHistory(historyData);
            console.log(importHistory);
            setLoading(false);
        } catch (err) {
            const firestoreError = err as FirestoreError;
            setError(`Failed to fetch import history: ${firestoreError.message}`);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImportHistory();
    }, [triggerUpdate, trigger]);

    const formatDate = (dateString: number) => {

        const date = dayjs(dateString);  // Convert milliseconds directly

        return date.format('DD MMM YY | HH:mm');
    };



    const downloadError = (filename: string, errorCSV: string,) => {
        const blob = new Blob([errorCSV], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const newFilename = (filename && `${filename.split('.')[0]}_ERRORS.${filename.split('.')[1]}`) || `ERRORS_${new Date()}.csv`;
        a.download = newFilename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    const columnHelper = createColumnHelper<ImportAlumni>();
    const columns = [
        columnHelper.accessor('fileName', {
            header: 'File Name',
            cell: info => info.getValue()
        }),
        columnHelper.accessor('date', {
            header: 'Date and Time',
            cell: info => info.getValue()
        }),
        columnHelper.accessor('totalImported', {
            header: 'Total Imported Alumni',
            cell: info => info.getValue()
        }),
        columnHelper.accessor('totalRejected', {
            header: 'Total Rejected Alumni',
            cell: info => info.getValue()
        }),
        columnHelper.accessor('error', {
            header: 'Errors',
            cell: info => info.getValue()
        }),
        columnHelper.accessor('id', {
            header: 'Revert',
            cell: info => info.getValue()
        })
    ];

    const table = useReactTable({
        data: importHistory,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
    });

    const revertImport = (orgId: string, importId: string) => {
        setIsDeleting(true)
        console.log("Reverting import for record ID:", importId);
        revertImportAlumni(orgId, importId)
            .then(response => {
                if (response.code === 200) {
                    setIsDeleting(false);
                    setIsDelete(false);
                    setFinishDelete(true);
                    setTriggerUpdate(prev => !prev)
                } else {
                    setErrorMsg(response.message as string);
                    setIsDeleting(false);
                    throw new Error(response.message);
                }
            })
            .catch(error => {
                setErrorMsg(error.message as string);
                setIsDeleting(false);
                console.error("Error reverting import:", error);
                alert("Failed to revert import: " + error.message); // Display error message via alert or another method
            });
    };
    return (
        <>
            <AlertDialogSimpleComponent visible={finishDelete} message={`The file ${revertFile?.fileName} has successfully been reverted.`} primaryButtonClose={() => setFinishDelete(false)} primaryButtonTitle="Close" />
            <AlertDialogLoadingComponent
                severity="error"
                primaryButtonState={isDeleting}
                title="Revert Import Alumni"
                message={`Are you sure you want to revert the import from ${revertFile?.fileName}? <br/>Doing so will remove ${revertFile?.totalImported} alumni and all associated data from your organisation. This action cannot be undone. `}
                visible={isDelete}
                primaryButtonTitle="Yes, I'm Sure"
                primaryButtonClose={() => revertImport(orgId, revertFile?.id as string)}
                secondaryButtonTitle="Cancel"
                secondaryButtonClose={() => {
                    if (!isDeleting) {  // Check if the deletion process is not running
                        setIsDelete(false);
                        setRevertFile(undefined);
                    }
                }}
            />
            <div className={styles.tableWrapper}>
                <table className={styles.engagement_table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }}>
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                            {(header.column.id === 'status' || header.column.id === 'name' || header.column.id === 'involvement' || header.column.id === 'date' || header.column.id === 'title') &&
                                                (
                                                    <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                        <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                        <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                    </Stack>
                                                )}
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id} onClick={() => { console.log(row.original.id ?? "") }} className={styles.normalRow}>
                                {
                                    row.getVisibleCells().map(cell => {
                                        let cellJSX: JSX.Element | undefined = undefined;
                                        if (cell.column.id === "fileName") {
                                            cellJSX = (
                                                <Stack key={cell.id} minWidth={pxToRem(240)}>
                                                    <small>{row.original.fileName}</small>
                                                </Stack>
                                            );
                                        } else if (cell.column.id === "date") {
                                            cellJSX = (
                                                <Stack key={cell.id} minWidth={pxToRem(190)}>
                                                    {row.original.date ? formatDate(row.original.date as number) : ""}
                                                </Stack>
                                            );
                                        } else if (cell.column.id === "totalImported") {
                                            cellJSX = (
                                                <Stack key={cell.id} alignItems="start">
                                                    {row.original.totalImported}
                                                </Stack>
                                            );
                                        } else if (cell.column.id === "totalRejected") {
                                            cellJSX = (
                                                <Stack key={cell.id} alignItems="start">
                                                    {row.original.totalRejected}
                                                </Stack>
                                            );
                                        }  else if (cell.column.id === "error") {
                                            cellJSX = (
                                              <Stack key={cell.id} minWidth={pxToRem(50)} minHeight={pxToRem(68)} justifyContent='center' alignItems={'center'}>
                                                {row.original.error !== '' ? (
                                                  <IconButton onClick={() => downloadError(row.original.fileName as string, row.original.error as string)} aria-label="Download Errors">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                  </IconButton>
                                                ) : (
                                                  <span>-</span>
                                                )}
                                              </Stack>
                                            );
                                        } else if (cell.column.id === "id") {
                                            cellJSX = (
                                                <Stack key={cell.id} minWidth={pxToRem(50)} minHeight={pxToRem(68)} justifyContent='center' alignItems={'center'}>
                                                    <IconButton onClick={() => { setRevertFile(row.original); setIsDelete(true) }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 7H14C17.3137 7 20 9.68629 20 13C20 16.3137 17.3137 19 14 19H4M4 7L8 3M4 7L8 11" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M4 7H14C17.3137 7 20 9.68629 20 13C20 16.3137 17.3137 19 14 19H4M4 7L8 3M4 7L8 11" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M4 7H14C17.3137 7 20 9.68629 20 13C20 16.3137 17.3137 19 14 19H4M4 7L8 3M4 7L8 11" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </IconButton>
                                                </Stack>
                                            );
                                        }
                                        return (<td key={cell.id}> {cellJSX} </td>)
                                    })
                                }
                            </tr>
                        ))}
                        {importHistory.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'start', padding: '20px', height: pxToRem(52), color: '#A3A3A3'}}>

                                    No import history available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ImportHistoryTable;
