import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import CommentBox from './commentBox';
import { Comment } from '../../../firebase/types-organisationPlanners';
import { deleteComment, editComment, getAllComments } from '../../../firebase/organisationPlannerApi';
import { getCurrentUserId } from '../../../firebase/userApi';
import { Timestamp } from 'firebase/firestore';
import styles from "../index.module.scss";


interface CommentsListProps {
  orgId: string;
  plannerId: string;
  updateState?: boolean;
  isEditable: boolean;
}

const CommentsList: React.FC<CommentsListProps> = ({ orgId, plannerId, updateState, isEditable }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const response = await getAllComments(orgId, plannerId);
        if (response.code === 200) {
          setComments(response.data as Comment[]);
        } else {
          console.error(response.errorMsg);
          // Handle error
        }
      } catch (error) {
        console.error("Error fetching comments: ", error);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [orgId, plannerId, updateState]);

  const handleEditComment = async (newContent: string, commentId: string) => {

    if (!newContent.trim()) {
      alert("Please enter a comment.");
      return;
    }
    // Implement logic to edit a comment
    try {
      const response = await editComment(orgId, plannerId, commentId, newContent);
      if (response.code === 200) {
        // Update the local state to reflect the edited comment
        setComments(comments.map(comment => comment.Id === commentId ? { ...comment, content: newContent } : comment));
      } else {
        console.error(response.errorMsg);
        // Handle error
      }
    } catch (error) {
      console.error("Error editing comment: ", error);
      // Handle error
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    // Implement logic to delete a comment
    try {
      const response = await deleteComment(orgId, plannerId, commentId);
      if (response.code === 200) {
        // Remove the deleted comment from the local state
        setComments(comments.filter(comment => comment.Id !== commentId));
      } else {
        console.error(response.errorMsg);
        // Handle error
      }
    } catch (error) {
      console.error("Error deleting comment: ", error);
      // Handle error
    }
  };

  return (
    <Box className={`${styles.commentListBox}`}>

      {comments.map(comment => (
        <CommentBox
          key={comment.Id}
          userId={comment.userId}
          timestamp={comment.created_at as number}
          content={comment.content}
          onEdit={(newContent) => handleEditComment(newContent, comment.Id)}
          onDelete={() => handleDeleteComment(comment.Id)}
          currentUserId={getCurrentUserId() as string}
          isEditable={isEditable}
        />
      ))}
    </Box>
  );
};

export default CommentsList;
