import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { OnboardingSteps } from "../../pages/OnboardingPage";
import { SecondaryButton } from "../Buttons/ButtonUI";
import styles from "./components.module.scss";

interface IStepButton {
  stepIndex: number;
  title: string;
  des: string;
  activeStep: number;
  setStepIndex: (stepIndex: OnboardingSteps) => void;
  clickable: boolean;
  step: OnboardingSteps;
}

export const StepButton = ({
  stepIndex,
  title,
  des,
  activeStep,
  setStepIndex,
  clickable,
  step,
}: IStepButton) => (
  <Stack
    className={styles.stackstepButton}
    sx={{ cursor: clickable ? "pointer" : "unset" }}
    onClick={() => {
      if (clickable) setStepIndex(step);
    }}
  >
    <Stack
      className={`${
        activeStep === stepIndex
          ? styles["stepButton--active"]
          : styles["stepButton--inActive"]
      } ${styles.stepButton}`}
    >
      <h6 className='regular text-center'>{stepIndex}</h6>
    </Stack>
    <Stack className={styles.textStackStackButton}>
      <small
        className={`semiBold ${
          activeStep === stepIndex ? styles.shade100 : styles.neutral500
        }`}
      >
        {title}
      </small>
      <small
        className={`regular ${
          activeStep === stepIndex ? styles.shade100 : styles.neutral500
        }`}
      >
        {des}
      </small>
    </Stack>
  </Stack>
);

interface IPlaceCard {
  name: string;
  address: string;
  extraText: string;
  clickHandler: () => void;
  usedInsedModal?: boolean;
}

export const PlaceCard = ({
  name,
  address,
  extraText,
  clickHandler,
  usedInsedModal,
}: IPlaceCard) => (
  <Stack
    className={`${styles.placeCard} ${
      usedInsedModal
        ? styles["placeCard_width--model"]
        : styles["placeCard_width"]
    }`}
  >
    <Stack
      direction={"row"}
      justifyContent='space-between'
      alignItems={"center"}
    >
      <Stack>
        <p className='xsMarginBottom'>{name}</p>
        <Stack className={styles.PlaceCard_positionStack}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
              stroke='#1F1F1F'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 22C16 18 20 14.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 14.4183 8 18 12 22Z'
              stroke='#1F1F1F'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <Stack className={styles.positionColumm}>
            <p>{address}</p>
            <p>{extraText}</p>
          </Stack>
        </Stack>
      </Stack>
      <SecondaryButton
        className={styles.joinButton}
        onClick={clickHandler}
      >
        <small>Join</small>
      </SecondaryButton>
    </Stack>
  </Stack>
);

export const OnboardingLoadingSpinner = () => (
  <Stack className={styles.OnboardingLoadingSpinner}>
    <Skeleton variant='circular' className={styles.iconSpinner} />
    <Skeleton
      variant='text'
      className={`mediumMarginTop ${styles.userNameSpinner} p`}
    />
    <Skeleton variant='text' className={`xsMarginTop ${styles.desSpinner} p`} />
    <Stack direction={"row"} justifyContent='flex-start' width={"100%"}>
      <Skeleton
        variant='text'
        className={`mediumMarginTop ${styles.desSpinner} smallMarginBottom small`}
      />
    </Stack>
    <Skeleton variant='rounded' className={styles.resultsPanel} />
    <Stack direction={"row"} justifyContent='flex-start' width={"100%"}>
      <Skeleton
        variant='text'
        className={`mediumMarginTop ${styles.desSpinner}  p`}
      />
    </Stack>
  </Stack>
);
