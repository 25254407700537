import React, { useRef, useEffect } from 'react';
import './RoundCheckbox.css';

interface StatusRoundCheckboxProps {
  id: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StatusRoundCheckbox: React.FC<StatusRoundCheckboxProps> = ({ id, checked, indeterminate, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className="round">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        ref={inputRef}
        className="round__input"
      />
      <label htmlFor={id} className="round__label"></label>
    </div>
  );
};

export default StatusRoundCheckbox;
