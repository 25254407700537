import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organisation } from "../firebase/types-organisations";

interface IOrganisationStateSlice {
    orgId: string | null | undefined;
    organisation: Organisation | undefined;
    organisationList?: Organisation[];
    orgState?: 'BETA' | 'EARLY ACCESS' | 'REGULAR' | undefined; // Add orgState here
}

const INITIAL_STATE: IOrganisationStateSlice = {
    orgId: null,
    organisation: undefined,
    organisationList: [],
    orgState: undefined, // Initialize orgState
}

// Helper function to determine the organisation state
const calculateOrgState = (organisation?: Organisation): 'BETA' | 'EARLY ACCESS' | 'REGULAR' | undefined => {
    if (!organisation || !organisation.created_at) return undefined;
    const TrialEndDate = new Date(organisation.trial_end as number);
    const createAtDate = new Date(organisation.created_at as number);
    const nowDate =  new Date();
    const betaEndDate = new Date('2024-03-28');
    const earlyEndDate = new Date('2024-07-01');
    //remove user tag
    if (createAtDate <= betaEndDate) {
        return 'BETA';
    } else if (TrialEndDate > nowDate){
        return 'REGULAR';
    }
};

export const organisationStateSlice = createSlice({
    name: "organisation",
    initialState: INITIAL_STATE,
    reducers: {
        setOrganisationStateDefault: (state) => {
            // state = { ...INITIAL_STATE };
            state.orgId = INITIAL_STATE.orgId;
            state.orgState = INITIAL_STATE.orgState;
            state.organisation = INITIAL_STATE.organisation;
            state.organisationList = INITIAL_STATE.organisationList;
        },
        setCurrentOrgDetail: (state, action: PayloadAction<IOrganisationStateSlice>) => {
            state.orgId = action.payload.orgId; // Assuming id is part of Organisation
            state.organisation = action.payload.organisation;
            state.orgState = calculateOrgState(action.payload.organisation); // Calculate orgState when setting details
        },
        setCurrentOrgId: (state, action: PayloadAction<string | undefined>) => {
            state.orgId = action.payload;
        },
        setCurrentOrg: (state, action: PayloadAction<Organisation>) => {
            state.organisation = action.payload;
            state.orgState = calculateOrgState(action.payload); // Calculate orgState here as well
        },
        addOrganisationList: (state, action: PayloadAction<Organisation>) => {
            if (state.organisationList && !state.organisationList.some(item => item.id === action.payload.id)) {
                state.organisationList.push(action.payload);
            }
        },
        updateOrganisationList: (state, action: PayloadAction<Organisation>) => {
            const index = state.organisationList?.findIndex(item => item.id === action.payload.id);
            if (index !== undefined && index !== -1 && state.organisationList) {
                state.organisationList[index] = action.payload;
                // Optionally update orgState if the updated organisation matches the current orgId
                if (state.orgId === action.payload.id) {
                    state.orgState = calculateOrgState(action.payload);
                }
            }
        },
        removeOrganisationList: (state, action: PayloadAction<string>) => {
            state.organisationList = state.organisationList?.filter(item => item.id !== action.payload);
        },
        clearOrgPhotoUrl: (state) => {
            if (state.organisation) state.organisation.photoUrl = undefined;
        }
    }
});

export const { setOrganisationStateDefault, clearOrgPhotoUrl, setCurrentOrgId, setCurrentOrg, setCurrentOrgDetail, addOrganisationList, updateOrganisationList, removeOrganisationList } = organisationStateSlice.actions;
export default organisationStateSlice.reducer;
