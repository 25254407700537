import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IBetaStateSlice {
    isBetaOpen?: boolean;

    isComingSoonOpen?: boolean;
    title?: string;
    subTitle?:string;
}

const INITIAL_STATE: IBetaStateSlice = {
    isBetaOpen: false,
    
    isComingSoonOpen: false,
    title:"",
    subTitle:""
}

export const betaStateSlice = createSlice({
    name: "betaStateSlice",
    initialState: INITIAL_STATE,
    reducers: {
        setBetaState:(state, action: PayloadAction<boolean>) => {
            state.isBetaOpen = action.payload;
        },
        openComingSoonModal:(state, action:PayloadAction<IBetaStateSlice>) => {
            state.isComingSoonOpen = action.payload.isComingSoonOpen;
            state.title = action.payload.title;
            state.subTitle = action.payload.subTitle;
        },
        closeComingSoonModal:(state) =>{
            state.isComingSoonOpen = false;
            state.title = "";
            state.subTitle = "";
        }
    }
});

export const betaStateAction = betaStateSlice.actions;
export default betaStateSlice.reducer;

//Example
/*
Read: 
    useSelector( (state: RootState) => state.xxx);
Set:
    const dispatch = useDispatch();
    dispatch(setAlumniId);

*/
