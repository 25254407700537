import { Skeleton } from "@mui/material";
import pxToRem from "../../../../../helpers/pxToRem";
import styles from "../../../index.module.scss";

const RespondentsSkeleton = () => {
    return(
        <>
            <tr className={styles.trskeleton}>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>
                <td> <Skeleton width={pxToRem(80)}/></td>           
            </tr>
        </>
    );
}


export default RespondentsSkeleton;