import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import pxToRem from '../../helpers/pxToRem';

// Styled FormControl with custom styles
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputBase-root": {
    color: theme.palette.text.primary, // Ensure text color is consistent
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide the border
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide border on hover
    },
     "& .MuiOutlinedInput-input": {
      backgroundColor: '#F5F5F5',
      padding: '0 12px',
      fontSize: pxToRem(14),
      fontFamily: 'Open Sans',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      height: 'auto'  // Ensures input height adjusts to content
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide border on focus
    },
    backgroundColor: '#F5F5F5', // Background color
    borderRadius: pxToRem(8), // Border radius
    height: pxToRem(32), // Match height to autocomplete
    "& .MuiSelect-select": {
      padding: '0 12px', // Uniform padding
      height: 'auto', // Auto height for flexbox alignment
      display: 'flex',
      alignItems: 'center'
    }
  },
  "& .MuiSvgIcon-root": {
    // Adjust the icon size to match that of the autocomplete
    fontSize: pxToRem(25), // Adjusted size
  }
}));

interface TableDropdownProps {
    defaultValue: string;
    options: { value: string; label: string }[];
    onChange: (event: SelectChangeEvent<string>) => void;  // Changed to match your use case
  }
  
  export const TableDropdown: React.FC<TableDropdownProps> = ({ defaultValue, options, onChange }) => {
    const [value, setValue] = useState(defaultValue);  // Local state to manage the selected value

    useEffect(() => {
        setValue(defaultValue); // Ensure local state updates when defaultValue prop changes
      }, [defaultValue]);
  
    const handleChange = (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
      setValue(newValue);  // Update local state
      onChange(event);  // Directly passing the event to parent handler
    };
  
    return (
      <StyledFormControl fullWidth variant="outlined" size="small">
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            return options.find(option => option.value === selected)?.label || '-';
          }}
          MenuProps={{
            PaperProps: {
              style: {
                width: '150px', // Ensure the dropdown menu matches the width of the select
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    );
  };
  
  export default TableDropdown;