import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import globalStyles from "../../styles/_stylesParams.module.scss";

export const CustomizedCheckBox = styled(Checkbox)({
  padding: "0",
  color:globalStyles.neutrals400,
  "&.Mui-checked": {
    color: globalStyles.secondaryBlue500,
    borderRadius: "4px",
  },
});
