import React from 'react';
import styles from '../index.module.scss';
import pxToRem from '../../../../../helpers/pxToRem';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URL_PARAMS } from '../../../../../routers/shared';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';

interface SeatsIndicatorProps {
	seats: number;
	users: number;
	orgId: string
}

const MAX_SEATS = 10;

const SeatsIndicator: React.FC<SeatsIndicatorProps> = ({ seats, users, orgId }) => {
	const dots = [];
	const navigate = useNavigate();
	const billingPermission = useSelector((state: RootState) => state.globalState.permissions?.billing);

	for (let i = 0; i < MAX_SEATS; i++) {
		let svg;
		if (i < users) {
			// SVG for an occupied seat (user)
			svg = <svg width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#447D75" /></svg>;
		} else if (i < seats) {
			// SVG for an unoccupied seat (available)
			svg = <svg width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7.5" stroke="#447D75" /></svg>;

		} else {
			// SVG for the remaining slots that are not part of the seats
			svg = <svg width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#E5E5E5" /></svg>;
		}

		dots.push(
			<span key={i} className={styles.dot}>
				{svg}
			</span>
		);
	}

	return (

		<Stack direction={'row'} width={'100%'}>
			<Stack direction={'column'} width={'100%'}>
				<Typography className={styles.inlineText}>Seats</Typography>
				<Stack direction={'column'} alignItems={'start'}>
					<Stack className={styles.dotsContainer}>{dots}</Stack>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
						<div className={styles.inlineBlackText}>
							{users} of {seats} seats used
						</div>
						{(billingPermission && billingPermission === 'EDITOR') &&
							<Button className={styles.manageButton} onClick={() => navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/team`)}>
								Manage Users
							</Button>
						}
					</Stack>


				</Stack>
			</Stack>
		</Stack>
	);
};

export default SeatsIndicator;
