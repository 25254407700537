import { Breadcrumbs, Modal, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AlertComponent, { AlertDialogComponent, AlertDialogSimpleComponent } from "../../components/AlertComponent";
import { BetaButton, PrimaryButton, SecondaryButton } from "../../components/Buttons/ButtonUI";
import CustomizedPaper from "../../components/CustomizedPaper";
import { validateEngagementTitle, validateLocationOfEngagement, validateTypeOfEngagement } from "../../helpers/inputValidators";
import pxToRem from "../../helpers/pxToRem";
import useTextInput from "../../hooks/useTextInput";
import { RootState } from "../../redux";
import { betaStateAction } from "../../redux/betaSlice";
import { URL_PARAMS } from "../../routers/shared";
import dashboardStyles from "../DashboardContainerPage/index.module.scss";
import styles from "./index.module.scss";
import { getCurrentUserId } from "../../firebase/userApi";
import InputTextField from "../../components/Inputs/TextFields/InputTextField";
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import { CustomizedCheckBox } from "../../components/Checkbox";
import LoadingComponent from "../../components/LoadingStatus/Loading";
import { createNewEngagement } from "../../firebase/engagementApis";
import { NewEngagement } from "../../firebase/types-engagement";
import constants from "../../helpers/constants";
import { mergeAndSortTypes } from "../../helpers/mergeTypes";
import ButtonDateTimePicker from "./components/dateTimePickerButton";
import SearchTable from "./EngagementTableComponents/SearchTable";

interface EngagementTableHandles {
    triggerUpdate: () => void;
}

const EngagementPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = getCurrentUserId();
    const [updateUser, setUpdateUser] = useState("")
    const [updateTimeStr, setUpdateTimeStr] = useState("")
    const organisation = useSelector((state: RootState) => state.organisation);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    // Selector to get engagement types from Redux
    const engagementTypes = useSelector((state: RootState) => state.organisation.organisation?.engagementTypes);

    const [deleteTable, setDeleteTable] = useState(false);
    const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [openAddEngagement, setOpenAddEngagement] = useState(false);
    const [newStartDate, setNewStartDate] = useState<Dayjs | null>(null);
    const [newEndDate, setNewEndDate] = useState<Dayjs | null>(null);
    const [isNewAllDay, setIsNewAllDay] = useState<boolean>(false);
    const newTitle = useTextInput({ inputValidator: (input) => validateEngagementTitle(input) });
    const newType = useTextInput({ inputValidator: (input) => validateTypeOfEngagement(input) });
    const [refreshEngagementTypes, setRefreshEngagementTypes] = useState(false);
    const newLocation = useTextInput({ inputValidator: (input) => validateLocationOfEngagement(input) });
    const [organisationEngagementType, setOrganisationEngagementType] = useState<string[]>(['Assembly', 'Fundraiser', 'Reunion', 'Seminar', 'Sport Event']);
    const [organisationEngagementLocation, setOrganisationEngagementLocation] = useState<string[]>(() => {
        // Extract campus names array from the organisation object
        const campusNames = organisation.organisation?.campus;
        console.log("--------------",campusNames)
    
        // Check if campusNames is an array and filter out undefined or falsy values
        if (Array.isArray(campusNames)) {
            return campusNames
                .filter(campusName => typeof campusName === 'string' && campusName)  // Filter out non-string and empty strings
                .map(campusName => `On Campus (${campusName})`); // Map to formatted string
        }
        return []; // Return an empty array if campusNames is not an array or contains invalid entries
    });
    const memoizedSetOpenAddPlanner = useCallback((state: boolean) => setOpenAddEngagement(state), []);

    const EngagementTableRef = useRef<EngagementTableHandles>(null);

    useEffect(() => {
        // Function to merge and sort engagement types
        function updateEngagementTypes(types: string[]) {
            const allTypes = mergeAndSortTypes(organisationEngagementType, types);
            setOrganisationEngagementType(allTypes);
        }

        if (engagementTypes) {
            updateEngagementTypes(engagementTypes);
        }
    }, [engagementTypes]);

    // Update the default end date whenever the start date changes
    useEffect(() => {
        if (newStartDate && !isNewAllDay) {
            const updatedEndDate = newStartDate.add(0.5, 'hour'); // Set end time to 30 minutes after start time
            setNewEndDate(updatedEndDate);
        }
    }, [newStartDate, isNewAllDay]);

    const handleCancelNew = () => {
        setNewStartDate(null);
        setNewEndDate(null);
        setIsNewAllDay(false);
        newTitle.setValue("");
        newType.setValue("");
        newLocation.setValue("");
        newTitle.setHasTouched(false);
        newType.setHasTouched(false);
        newLocation.setHasTouched(false);
        setOpenAddEngagement(false);
    };

    const handleSaveNewEngagement = async () => {
        // Ensure all required fields are filled out
        let errors = [];

        // Check each field and add specific error messages
        if (!newTitle.value) {
            errors.push("Title");
        }
        if (!newLocation.value) {
            errors.push("Location");
        }
        if (!newStartDate) {
            errors.push("Start date");
        }
        if (!isNewAllDay && !newEndDate) {
            errors.push("End date");
        }
    
        // If there are any errors, set the error message
        if (errors.length > 0) {
            setErrorMsg(`Please fill in all required fields: ${errors.join(", ")}.`);
            return;
        }
    
        setLoadingStatus(true);
        setLoadingStatusStr("Creating New Engagement");

        const engagementData: NewEngagement = {
            title: newTitle.value,
            location: newLocation.value,
            ...(newType.value && { type: newType.value }),
            start_time: newStartDate?.valueOf(),
            end_time: isNewAllDay ? newStartDate?.valueOf() : newEndDate?.valueOf(),
            created_at: serverTimestamp(), // These will be overridden in the transaction to ensure atomicity
            updated_at: serverTimestamp()
        };
        let engagementId: string;

        try {

            const response = await createNewEngagement(orgId as string, engagementData, userId as string);
            if (response.code === 200) {
                setLoadingStatusStr("");
                setLoadingStatus(false);
                setErrorMsg(""); // Clear any error messages
                engagementId = response.data
                navigate(`/dashboard/organisation/${orgId}/engagement/${engagementId}`)
            } else {
                setLoadingStatusStr("");
                setLoadingStatus(false);
                alert("Failed to create new engagement.");
            }
        } catch (error) {
            setLoadingStatusStr("");
            setLoadingStatus(false);
            // Handle errors, such as displaying a message to the user
            console.error("Error saving task:", error);
            alert("Failed to save task. Please try again.");
        } finally {
            setNewStartDate(null);
            setNewEndDate(null);
            setIsNewAllDay(false);
            newTitle.setValue("");
            newType.setValue("");
            newLocation.setValue("");
            newTitle.setHasTouched(false);
            newType.setHasTouched(false);
            newLocation.setHasTouched(false);
            setOpenAddEngagement(false);
        }
    };

    const formatDate = (date: Dayjs | null) => {
        return date?.isValid() ? date.format(isNewAllDay ? "DD MMM YY" : "DD MMM YY, HH:mm") : null;
    };

    const formatRange = (start: Dayjs | null, end: Dayjs | null) => {
        if (!start || !end || !start.isValid() || !end.isValid()) {
            return formatDate(start);
        }
        if (start.isSame(end, 'day')) {
            return `${start.format("DD MMM YY")} from ${start.format("hh:mm A")} to ${end.format("hh:mm A")}`;
        } else {
            return `${formatDate(start)} to ${formatDate(end)}`;
        }
    };

    const rowClick = (id: string) => {
        if (id) { navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ENGAGEMENT}/${id}`); }
    }

    return (
        <>
            <AlertDialogSimpleComponent visible={errorMsg !== ""} primaryButtonClose={function (): void { setErrorMsg(""); }} title="Alert" message={errorMsg} primaryButtonTitle="OK"/>
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
                    <Stack><span>Engagement</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => { if (orgState === 'REGULAR') { navigate('/pricing') } else { dispatch(betaStateAction.setBetaState(true)) } }} />
            </Stack>

            <Modal open={openAddEngagement} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <div className={styles.popupBackdrop}>
                    </div>
                    {loadingStatus ? (

                        <div className={styles.popup}>
                            <LoadingComponent isLoading={loadingStatus} loadingStr={loadingStatusStr} />
                        </div>) : (
                        <div className={styles.popup}>
                            <Stack className={styles.contentBox} direction={'column'}>
                                <Stack direction={"column"} style={{ alignItems: 'left', position: 'relative', width: '100%' }}>
                                    <h6 style={{ flexGrow: 1 }} className='light'>Create New Engagement</h6>
                                    <small className={`xssSpace ${styles["text--neutrals500"]}`}> {'Capture the details of your event or activity here'} </small>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                </Stack>

                                <Stack direction={"column"} width={'70%'} spacing={pxToRem(24)}>
                                    <Stack direction={"row"}>
                                        <Stack direction={"row"} style={{ alignItems: "center" }}>
                                            <small style={{ marginRight: pxToRem(10) }}>Date:</small>
                                            {!isNewAllDay && <small style={{ marginRight: pxToRem(10) }}>From</small>}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <ButtonDateTimePicker
                                                    label={newStartDate == null ? null : newStartDate.format('MM/DD/YYYY')}
                                                    value={newStartDate}
                                                    onChange={(newValue) => setNewStartDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                            <small style={{ marginRight: pxToRem(10) }}>
                                                {newStartDate ? formatDate(newStartDate) : null}
                                            </small>
                                        </Stack>
                                        {!isNewAllDay && (
                                            <Stack direction={"row"} style={{ alignItems: "center" }}>
                                                <small style={{ marginRight: pxToRem(10) }}>To</small>

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <ButtonDateTimePicker
                                                        label={newEndDate == null ? null : newEndDate.format('MM/DD/YYYY')}
                                                        value={newEndDate}
                                                        onChange={(newValue) => setNewEndDate(newValue)}
                                                        minDateTime={newStartDate ?? undefined}
                                                        disabled={!newStartDate}
                                                    />
                                                </LocalizationProvider>
                                                <small style={{ marginRight: pxToRem(10) }}>
                                                    {newEndDate ? formatDate(newEndDate) : null}
                                                </small>
                                            </Stack>
                                        )}
                                        <Stack direction={"row"} style={{ alignItems: "center" }}>
                                            <CustomizedCheckBox checked={isNewAllDay} disabled={!newStartDate} onChange={(e) => setIsNewAllDay(e.target.checked)} />
                                            <small style={{ marginLeft: pxToRem(6) }} >All Day</small>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <Stack>
                                            <small style={{ marginRight: pxToRem(10) }} >Type of Engagement*</small>
                                            <Typography className={styles.xssmallGreyText}>
                                                i.e. session, sport event, reunion etc
                                            </Typography>
                                        </Stack>

                                        <AutoComplete shrinkLabelWidth={130} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={newType.value} label='Maximum 64 characters' inputValue={newType.value} setValue={(value: string) => newType.setValue(value)} options={organisationEngagementType ?? []} freeSolo forcePopupIcon error={newType.hasError && newType.hasTouched} helperText={<small>{newType.getErrorMessage()}</small>} />

                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <small style={{ marginRight: pxToRem(10) }} >Engagement Title*</small>
                                        <InputTextField type={"text"} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={newTitle.value} containerStyle={{ flex: 1 }} label='Maximum 64 characters' value={newTitle.value} onChange={(e) => newTitle.setValue(e.target.value)} error={newTitle.hasError && newTitle.hasTouched} helperText={<small>{newTitle.getErrorMessage()}</small>} />
                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <Stack>
                                            <small style={{ marginRight: pxToRem(10) }} >Location of Engagement</small>
                                            <Typography className={styles.xssmallGreyText}>
                                                Tip: You can add your organisation's campuses in your organisation settings or enter a new location
                                            </Typography>
                                        </Stack>
                                        <AutoComplete fontSize={constants.addAlumniFontSize} shrinkLabelWidth={200} customHeight={constants.addAlumniInputHeight} valueCheck={newLocation.value} label='Enter the location of your engagement' inputValue={newLocation.value} setValue={(value: string) => newLocation.setValue(value)} options={organisationEngagementLocation ?? []} freeSolo forcePopupIcon error={newLocation.hasError && newLocation.hasTouched} helperText={<small>{newLocation.getErrorMessage()}</small>} />
                                    </Stack>
                                </Stack>

                                <Stack direction={"column"} width={'100%'}>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                    <Stack direction={"row"} justifyContent='space-between' width={'100%'}>
                                        <SecondaryButton onClick={handleCancelNew} className={`${styles.button} regular`}> Cancel </SecondaryButton>
                                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                                            <PrimaryButton className={styles.button} onClick={handleSaveNewEngagement}>Save</PrimaryButton>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Stack>
                        </div>
                    )}
                </div>
            </Modal>

            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    <Stack direction={"row"} spacing={2} style={{ alignItems: 'center', position: 'relative' }}>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                            <h6 style={{ flexGrow: 1 }} className='regular'>Engagement {` | ${orgName}`}</h6>

                        </div>

                    </Stack>
                    <small className={`xssSpace ${styles["text--neutrals500"]}`}> {'Keep a record of your conducted activities and track which alumni were engaged.'} </small>

                    <div className={`${styles.divider} horizontalDivider`}></div>

                    <Stack>
                        <SearchTable orgId={orgId} ref={EngagementTableRef} setOpenAddEngagement={memoizedSetOpenAddPlanner} rowClick={rowClick} />

                    </Stack>

                </Stack>
            </CustomizedPaper>
        </>
    );
};
export default EngagementPage;
