import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeamMember, TeamPermission } from "../pages/TeamPage/helpers";
import { Invitation } from "../firebase/types-userApi";
import { Permission } from "../firebase/types-organisations";
interface ITeamStateSlice {
    teamMembers: TeamMember[];
    teamPendingInvitations: Invitation[];
    teamPermissions?: TeamPermission[];
}

const INITIAL_STATE: ITeamStateSlice = {
    teamMembers: [],
    teamPendingInvitations: []
}

export const teamStateSlice = createSlice({
    name: "teamState",
    initialState: INITIAL_STATE,
    reducers: {
        setTeamMembers:(state, action: PayloadAction<TeamMember[]>) => {
            state.teamMembers = action.payload;
        },
        setTeamPendingInvitations:(state, action: PayloadAction<Invitation[]>) => {
            state.teamPendingInvitations = action.payload;
        },
        setTeamPermissions: (state, action: PayloadAction<TeamPermission[]>) => {
            state.teamPermissions = action.payload;
        },
        addTeamPermissions: (state, action: PayloadAction<TeamPermission>) => {
            if(!state.teamPermissions) {
                state.teamPermissions = [action.payload]
            } else if (!state.teamPermissions.some(item=> item.userId === action.payload.userId)) {
                state.teamPermissions?.push(action.payload);
            }
        },
        removeTeamPermissions: (state, action: PayloadAction<string>) => {
            state.teamPermissions?.filter(item => item.userId !== action.payload);
        },
        updateTeamPermissions: (state, action: PayloadAction<TeamPermission>) => {
            const index = state.teamPermissions?.findIndex(item => item.userId === action.payload.userId);
            if (index !== undefined && index !== -1 && state.teamPermissions) {
                state.teamPermissions[index] = action.payload;
            }
        },
        resetTeamState: (state) => {
            state.teamMembers = [];
            state.teamPendingInvitations = [];
            state.teamPermissions = undefined;
        }
    }
});

export const {
    setTeamMembers,
    setTeamPendingInvitations,
    addTeamPermissions,
    removeTeamPermissions,
    updateTeamPermissions,
    resetTeamState
} = teamStateSlice.actions;
export default teamStateSlice.reducer;

//Example
/*
Read: 
    useSelector( (state: RootState) => state.xxx);
Set:
    const dispatch = useDispatch();
    dispatch(setAlumniId);

*/
