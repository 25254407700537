type GeolibInputCoordinates = {
  latitude: number;
  longitude: number;
};

export const getLngLatFromPlace = (
  place: any
): GeolibInputCoordinates | null => {
  if (!place) return null;
  return {
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  };
};

export const getPostCodeOfAPlace = (selectedPlace: any) => {
  const address_components = selectedPlace?.address_components;
  if (!address_components) return;
  const postcodeArray = selectedPlace.address_components.filter(
    (component: { long_name: string; types: string[] }) =>
      component?.types?.includes("postal_code")
  );
  if (postcodeArray.length > 0) {
    return postcodeArray[0]?.long_name ?? null;
  }
  return null;
};

export const getLocalityFromPlace = (place: any) => {
  const address_components = place?.address_components;

  if (address_components) {
    const filtered_address_components = address_components.filter(
      (address_component: any) => address_component?.types?.includes("locality")
    );
    const locality = filtered_address_components[0]?.short_name;

    return locality;
  }

  return undefined;
};

export const getCountryISOCodeFromPlace = (place: any) => {
  const address_components = place?.address_components;

  if (address_components) {
    const filtered_address_components = address_components.filter(
      (address_component: any) => address_component?.types?.includes("country")
    );
    const countryISOCode = filtered_address_components[0]?.short_name;

    return countryISOCode;
  }

  return undefined;
};

export const getStateISOCodeFromAuPlace = (place: any) => {

  const address_components = place?.address_components;
  if (address_components) {
    const filtered_address_components = address_components.filter(
      (address_component: any) =>
        address_component?.types?.includes("administrative_area_level_1")
    );
    const countryISOCode = filtered_address_components[0]?.short_name;

    return countryISOCode;
  }

  return undefined;
};
