import { DocumentData } from "firebase/firestore";

export type WeeklyGoalTheme = {
    name: string;
    text: string;
}

export type WeeklyGoalType = {
    code?: string;

    dashboardHeading: string;
    goalHeading: string;
    imgLarge?: string;
    imgSmall?: string;
    goalDescription: string;
    goalImpact: string;
    tasks: string[];
}

export const getWeeklyGoal = (data: DocumentData):WeeklyGoalType => {
    return {

        imgSmall: data.imgSmall,
        dashboardHeading: data.dashboardHeading,
        goalHeading: data.goalHeading,
        imgLarge: data.imgLarge,
        goalDescription: data.goalDescription,
        goalImpact: data.goalImpact,
        tasks: data.tasks

        // heading: data.heading,
        // subheading: data.subheading,
        // tasks: data.tasks,
        // goal: data.goal,
        // impact: data.impact,
        // // themes: data.themes&&data.themes.map((t:any):WeeklyGoalTheme => {return {name: t.name, text: t.text}}),
        // imgLarge: data.imgLarge,
        // imgSmall: data.imgSmall
    }
}

export type OrgWeeklyGoalsProgress = {
    taskNo: number;
    checked: boolean;
}

export type OrgWeeklyGoalsProgressType = {
    [taskNo: number]: boolean;
}

export type OrgWeeklyGoals = {
    code?: string;
    tasks: OrgWeeklyGoalsProgress[];
}

export const getOrgWeeklyGoals = (data: DocumentData):OrgWeeklyGoals => {
    let tasks:OrgWeeklyGoalsProgress[] = [];
    for(const [key, value] of Object.entries(data.tasks)) {
        tasks.push({
            taskNo: key as unknown as number,
            checked: value as boolean
        })
    }
    return {
        tasks: tasks
    }
}