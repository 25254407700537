import { IconButton, Stack, Tooltip } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { memo, useState } from "react";
import { AlumniDataItemInsideTable, AlumniTable } from "..";
import pxToRem from "../../../helpers/pxToRem";
import styles from "../index.module.scss";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SecondaryButton2 } from "../../../components/Buttons/ButtonUI";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import { PermissionState } from "../../../firebase/types-organisations";
import { RootState } from "../../../redux";
import { CSVLink } from "react-csv";
import moment from "moment";
interface IBasicSearchTable {
    totalRows: number;
    alumniDataRows: AlumniTable[];
    searchInput: string;
    rowClick: (id: string) => void;
    addNewRow: () => JSX.Element;
}
interface ISearchTable {
    orgName: string;
    setOpenAddAlumni: (state: boolean) => void,
    setAdvanceSearchDialog: (state: boolean) => void,
    rowClick: (id: string) => void;
    addNewRow: () => JSX.Element;
}

// export const SearchTable = ({ orgName, setOpenAddAlumni, setAdvanceSearchDialog, rowClick, addNewRow}: ISearchTable) => {
export const SearchTable = (({ orgName, setOpenAddAlumni, setAdvanceSearchDialog, rowClick, addNewRow }: ISearchTable) => {
    const [searchInput, setSearchInput] = useState("");;
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value.toLowerCase());
    };
    const [csvData, setCsvData] = useState<string[][]>([]);
    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);
    const allAlumni = useSelector((state: RootState) => state.alumniDataState.alumniList);


    // Filtered alumni based on search input with simple fuzzy logic
    const filteredAlumni = allAlumni.filter(alumni => {
        const normalizedSearchInput = searchInput.toLowerCase().split(/\s+/);

        const containsAllWords = (str: string | undefined) => {
            const normalizedStr = str?.toLowerCase() || "";
            const result = normalizedSearchInput.every(word => normalizedStr.includes(word));
            return result;
        };

        // Debug each field specifically
        const isMatch = containsAllWords(alumni.title) ||
            containsAllWords(alumni.firstName) ||
            containsAllWords(alumni.lastName) ||
            containsAllWords(alumni.name) ||
            containsAllWords(alumni.emailPreferred) ||
            containsAllWords(alumni.currentEmployment) ||
            containsAllWords(alumni.educationDegree) ||
            containsAllWords(alumni.living) ||
            containsAllWords(alumni.peerYear?.toString());
        return isMatch;
    });

    const csvExport = () => {
        const csvHeader = ["First Name", "Last Name", "Email"];
        const data = [
            csvHeader,
            ...filteredAlumni.map(alumni => [
                alumni.firstName || "",
                alumni.lastName || "",
                alumni.emailPreferred || "",
            ])
        ];
        setCsvData(data);
    }

    // Formatting the filename to include the organization name and the current date
    const formattedDate = moment().format("DD/MM/YYYY");
    const fileName = `${orgName.replace(/\s+/g, '_')}_Alumni_Email_Data_${formattedDate}`;


    return (
        <Stack>
            {alumniPermission && alumniPermission === PermissionState.EDITOR &&
                <CSVLink
                    style={{ position: 'relative' }}
                    data={csvData}
                    filename={fileName}
                    asyncOnClick={true}
                    onClick={(event, done) => {
                        csvExport();
                        done(true);
                    }}
                >
                    <Tooltip title="Export Email Addresses" placement='left'>
                        <IconButton style={{ position: 'absolute', right: pxToRem(10), top: pxToRem(-48) }}>
                            <svg width={pxToRem(36)} height={pxToRem(36)} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"> <g id="Export"> <g id="share-03"> <path id="Icon" d="M27 15L27 9.00001M27 9.00001H21M27 9.00001L18 18M16 9H13.8C12.1198 9 11.2798 9 10.638 9.32698C10.0735 9.6146 9.6146 10.0735 9.32698 10.638C9 11.2798 9 12.1198 9 13.8V22.2C9 23.8802 9 24.7202 9.32698 25.362C9.6146 25.9265 10.0735 26.3854 10.638 26.673C11.2798 27 12.1198 27 13.8 27H22.2C23.8802 27 24.7202 27 25.362 26.673C25.9265 26.3854 26.3854 25.9265 26.673 25.362C27 24.7202 27 23.8802 27 22.2V20" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </g> </g> </svg>
                        </IconButton>
                    </Tooltip>
                </CSVLink>
            }
            <Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>
                {
                    alumniPermission && alumniPermission === PermissionState.EDITOR &&
                    <Stack className={styles.createAlumni} onClick={() => { setOpenAddAlumni(true) }}>
                        <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 25' fill='none' > <path d='M12 5.16797V19.168M5 12.168H19' stroke='white' strokeLinecap='round' strokeLinejoin='round' /> </svg>
                    </Stack>
                }
                <InputTextField
                    containerStyle={{ flex: 1 }}
                    value={searchInput}
                    placeholder="Start typing..."
                    onChange={handleSearchChange}
                    adornment={<svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(25)} viewBox="0 0 24 25" fill="none"> <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>}
                />
                <SecondaryButton2 onClick={() => setAdvanceSearchDialog(true)} className={styles.searchAdvance}>
                    <small className={styles.shades100}>Advanced Search</small>
                </SecondaryButton2>
            </Stack>
            <BasicSearchTable
                alumniDataRows={filteredAlumni}
                totalRows={filteredAlumni.length ?? 0}
                searchInput={searchInput}
                rowClick={rowClick}
                addNewRow={addNewRow}
            />
        </Stack>
    );
});
const BasicSearchTable = memo(({ alumniDataRows, totalRows, searchInput, rowClick, addNewRow }: IBasicSearchTable) => {
    const columnHelper = createColumnHelper<AlumniTable>();
    const currentLocation = useLocation();

    const columns = [
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Name" }),
        columnHelper.accessor('currentEmployment', { cell: info => info.getValue(), header: "Employment" }),
        columnHelper.accessor('educationDegree', { cell: info => info.getValue(), header: "Education" }),
        columnHelper.accessor('living', { cell: info => info.getValue(), header: 'Living' }),
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "Peer Year" }),
    ];

    const table = useReactTable({
        data: alumniDataRows,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const CustomPaginationFooter = () => {
        const currentIndex = table.getState().pagination.pageIndex + 1;
        const totalPageCount = table.getPageCount();
        const pageSize = table.getState().pagination.pageSize;

        const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
        const endItem = currentIndex === totalPageCount ? totalRows : currentIndex * pageSize;

        return (
            <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
                <Stack direction={"row"} style={{ padding: 4, alignItems: 'center' }}>
                    <Stack className={styles.datagrid_footer_text} alignItems={"center"}> {`${startItem} - ${endItem} of ${totalRows}`} </Stack>
                    <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                    <IconButton disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>
                </Stack>
            </Stack>
        );
    }

    return (
        <>
            <Stack spacing={0} className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }} >
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                            {
                                                <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                    <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                    <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            // status === "loading" || status === "stalled"?
                            //     <RowSkeleton />
                            // :
                            table.getRowModel().rows.map(row => (
                                <tr key={row.id} onClick={() => { rowClick(row.original.objectID ?? "") }}>
                                    {
                                        row.getVisibleCells().map(cell => {
                                            let cellJSX: JSX.Element | undefined = undefined;
                                            if (cell.column.id === "name") {
                                                cellJSX = <Stack key={cell.id} className={styles.alumni}>{AlumniDataItemInsideTable(row.original.title ?? "", row.original.firstName ?? "", row.original.lastName ?? "", row.original.emailPreferred ?? "", row.original.photoUrl ?? "")}</Stack>;
                                            }
                                            else if (cell.column.id === "peerYear") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.peerYear === -1 ? "-" : row.original.peerYear ?? "-"}</Stack>;
                                            }
                                            else if (cell.column.id === "currentEmployment") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.currentEmployment || "-"}</Stack>;
                                            }
                                            else if (cell.column.id === "living") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.living || "-"}</Stack>;
                                            }
                                            else if (cell.column.id === "educationDegree") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.educationDegree || "-"}</Stack>;
                                            }
                                            // return( <td key={cell.id}> {cellJSX} </td> )
                                            return (
                                                <td key={cell.id} style={{ padding: 0, flex: 1, height: pxToRem(52), justifyContent: 'center', alignItems: 'center' }}>
                                                    <Link to={`${currentLocation.pathname}/${row.original.objectID}`} className={styles['row-link']}>
                                                        {cellJSX}
                                                    </Link>
                                                </td>
                                            );
                                        })
                                    }
                                    {/* </Link> */}
                                </tr>
                            ))
                        }
                         {alumniDataRows.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'start', padding: '20px', height: pxToRem(52), color: '#A3A3A3'}}>

                                    {searchInput ? 'No matches found, please try again.' : "No alumni yet. Start building your alumni community today"}
                                
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Stack>
            {alumniDataRows.length > 0 ? (
                CustomPaginationFooter()
            ) : (
                addNewRow()
            )}
        </>
    );
});

export default memo(SearchTable);