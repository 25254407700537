import { Divider, Stack } from "@mui/material";
import styles from "./components.module.scss"
import pxToRem from "../../../helpers/pxToRem";
import { CSSProperties, ReactNode, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { URL_PARAMS } from "../../../routers/shared";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { Form } from "../../../firebase/types-registration";
import { Task } from "../../../firebase/types-organisationPlanners";
import moment from "moment";
import { Engagement } from "../../../firebase/types-engagement";

interface IActionCards {
    title: string;
    desc: string;
    children: ReactNode;
    style?: CSSProperties;
    link?: string;
}

const ActionCards = ({title, desc, children, style, link}: IActionCards) => {

    return (
        <Stack className={styles.actionCard} style={style}>
            <p>{title}</p>
            <small>{desc}</small>
            <Divider style={{margin: `${pxToRem(12)} 0`}}/>
            <Stack style={{width: '100%', gap:pxToRem(12), height: 'fit-content', borderRadius: pxToRem(8),overflowY:'scroll', marginBottom: pxToRem(8)}}>
                {children}
            </Stack>
            {link && 
                <Link to={link} className={styles.seeAll}>See All</Link>
            }
        </Stack>
    );
}

interface IQRFormActionCard {
    form: Form;
}

export const QRFormActionCard = ({form}: IQRFormActionCard) => {
    const orgId = useSelector((state:RootState) => state.organisation.orgId);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.SELF_REGISTRATION_FORMS}${form.formId ? `/${form.formId}`: ''}`)
    }

    return (
        // link underlines the text in the div as well
            <Stack onClick={handleClick} className={styles.QRFormActionCard}>
                <Stack className={form.pendingApproval > 0 ? styles['responses--action'] : styles.responses}><p>{form.pendingApproval}</p></Stack>
                <Stack className={styles.QRFormActionCardContent}>
                    <small>{form.formName}</small>
                    <p>New Responses</p>
                </Stack>
            </Stack>
    )
}

export const QRFormActionCardPlaceholder = () => {
    return (
        <Stack className={`${styles.QRFormActionCard} ${styles['QRFormActionCard--placeholder']}`}>
            <Stack className={styles.responses}><p>0</p></Stack>
            <Stack className={styles.QRFormActionCardContent}>
                <small>Your QR forms will appear here</small>
                <p>New Responses</p>
            </Stack>
        </Stack>
    )
}

interface IEngagementActionCard {
    engagement: Engagement;
}
// Change the 

export const EngagementActionCard = ({engagement}: IEngagementActionCard) => {
    const orgId = useSelector((state:RootState) => state.organisation.orgId);
    return (
        <Link style={{textDecoration:'none'}} to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ENGAGEMENT}${engagement.id ? `/${engagement.id}` : ''}`}>
            <Stack className={styles.engagementActionCard}>
                <div>
                    <p className={styles.engagementDay}>{engagement.start_time ? moment(engagement.start_time).format("ddd") : '-'}</p>
                    <p className={styles.engagementDate}>{engagement.start_time ? moment(engagement.start_time).format("DD"): '-'}</p>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} flexGrow={1}>
                    <div style={{gap:pxToRem(12)}}>
                        <p className={styles.engagement}>{engagement.title || '-'}</p>
                        <p className={styles.alumniCount}>{engagement.linkedAlumnis ? engagement.linkedAlumnis.length : '0'} Alumni engaged</p>
                    </div>
                    <Stack rowGap={pxToRem(12)}>
                        <Stack direction={'row'} alignItems={'center'} gap={pxToRem(12)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_6155_450)"> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </g> <defs> <clipPath id="clip0_6155_450"> <rect width="16" height="16" fill="white"/> </clipPath> </defs> </svg>
                            <p className={styles.engagementMetadata}>{engagement.start_time && engagement.end_time ?  engagement.start_time === engagement.end_time ? 'All Day' : moment(engagement.start_time).format("h:mm a") : '-'}</p>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} gap={pxToRem(12)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                            <p className={styles.engagementMetadata}>{engagement.location ? engagement.location : '-'}</p>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Link>
    );
}

export const EngagementActionCardPlaceholder = () => {
    return (
        <Stack className={`${styles.engagementActionCard} ${styles['engagementActionCard--placeholder']}`}>
            <div>
                <p className={styles.engagementDay} style={{color: styles.neutrals400}}>Day</p>
                <p className={styles.engagementDate} style={{color: styles.neutrals400}}>00</p>
            </div>
            <Divider orientation="vertical" style={{height:'60%'}}/>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} flexGrow={1}>
                <div style={{gap:pxToRem(12)}}>
                    <p className={styles.engagement} style={{color: styles.neutrals500}}>No upcoming engagements in the next 3 months </p>
                    <p className={styles.alumniCount} style={{color: styles.neutrals500}}>Your engagements will appear here</p>
                </div>
                <Stack rowGap={pxToRem(12)}>
                    <Stack direction={'row'} alignItems={'center'} gap={pxToRem(12)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_6155_450)"> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9987 3.99967V7.99967L10.6654 9.33301M14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </g> <defs> <clipPath id="clip0_6155_450"> <rect width="16" height="16" fill="white"/> </clipPath> </defs> </svg>
                        <p className={styles.engagementMetadata}>-</p>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={pxToRem(12)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 8.66634C9.10587 8.66634 10.0013 7.77091 10.0013 6.66634C10.0013 5.56177 9.10587 4.66634 8.0013 4.66634C6.89673 4.66634 6.0013 5.56177 6.0013 6.66634C6.0013 7.77091 6.89673 8.66634 8.0013 8.66634Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.0013 14.6663C10.668 11.9997 13.3346 9.61186 13.3346 6.66634C13.3346 3.72082 10.9468 1.33301 8.0013 1.33301C5.05578 1.33301 2.66797 3.72082 2.66797 6.66634C2.66797 9.61186 5.33464 11.9997 8.0013 14.6663Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                        <p className={styles.engagementMetadata}>-</p>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

interface IPlannerTaskActionCard {
    task: Task
}

export const PlannerTaskActionCard = ({task}: IPlannerTaskActionCard) => {
    const orgId = useSelector((state:RootState) => state.organisation.orgId);

    const dateMoment = useMemo(()=> {
        if(task.date) {
            return moment(task.date*1000);
        }
    }, [task.date])

    const date = useMemo(() => {
        if(dateMoment) {
            if(!dateMoment.isValid()) return "No date"
            return dateMoment.format("ddd DD");
        } else {
            return "No date"
        }
    }, [dateMoment])

    const isOverdue = moment().isAfter(dateMoment, 'day');

    return (
        <Link style={{textDecoration:'none'}} to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.PLANNER}${task.id ? `/${task.id}` : ''}`}>
            <Stack className={(dateMoment && dateMoment.isValid()) ? isOverdue ? styles['taskActionCard--overdue'] : styles['taskActionCard'] : styles['taskActionCard--unplanned']}>
                <div>
                    <p className={styles.taskItem}>{task.title || '-'}</p>
                    <p className={styles.taskUser}>
                        {
                            task.alumniList?.length === 1 ? 
                                task.alumniName
                            :
                                `${task.alumniList?.length} Alumni`
                        }
                    </p>
                </div>
                <p className={styles.taskItem}>{
                    isOverdue ? 'Overdue' : date
                }</p>
            </Stack>
        </Link>
    );
}

export const PlannerTaskActionCardPlaceHolder = () => {
    return (
        <Stack className={`${styles['taskActionCard']} ${styles['taskActionCard--placeholder']}`}>
            <div>
                <p className={styles.taskItem}>No upcoming tasks</p>
                <p className={styles.taskUser}>
                    Your tasks will appear here
                </p>
            </div>
        </Stack>
    );
}


export default ActionCards;