import { FieldValue } from "firebase/firestore";
import { AccountType, Permission, PermissionFeature, PermissionState } from "../../firebase/types-organisations";
import { UserType } from "../../firebase/types-userApi";


export interface TeamMember {
    userId: string;
    
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    photoUrl: string;

    lastActive: number;
}

export interface TeamPermission {
    userId: string;
    permissions: Permission;
}

export interface TeamTableData {
    userId: string;
    userType: AccountType;
    name: string;
    email: string;
    photoUrl?: string;
    role: string;
    lastActive?: number;
}

export interface PermissionTableData {
    feature: PermissionFeature;
    state: PermissionState;
}

export const rowsToPermission = (rows: PermissionTableData[]): Permission => {
    return {
        alumni: rows.filter(row => row.feature === PermissionFeature.ALUMNI)[0].state,
        billing: rows.filter(row => row.feature === PermissionFeature.BILLING)[0].state,
        planner: rows.filter(row => row.feature === PermissionFeature.PLANNER)[0].state,
        forms: rows.filter(row => row.feature === PermissionFeature.FORMS)[0].state,
        engagement: rows.filter(row => row.feature === PermissionFeature.ENGAGEMENT)[0].state,
        network: rows.filter(row => row.feature === PermissionFeature.NETWORK)[0].state,
        philanthropy: rows.filter(row => row.feature === PermissionFeature.PHILANTHROPY)[0].state,
    }
}

export const permissionToRows = (permission: Permission): PermissionTableData[] => {
    return [
        { feature: PermissionFeature.ALUMNI, state: permission.alumni },
        { feature: PermissionFeature.BILLING, state: permission.billing },
        { feature: PermissionFeature.PLANNER, state: permission.planner },
        { feature: PermissionFeature.FORMS, state: permission.forms },
        { feature: PermissionFeature.ENGAGEMENT, state: permission.engagement },
        { feature: PermissionFeature.NETWORK, state: permission.network },
        { feature: PermissionFeature.PHILANTHROPY, state: permission.philanthropy },

    ]
}

// export interface TeamPendingInviteData extends TeamTableData {
//     sentAt: number;
//     initial: string;
// }