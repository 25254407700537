import { Button, Stack, styled } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { submitRegistration } from "../../../firebase/registrationApis";
import { FormResponse } from "../../../firebase/types-registration";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { setCurrentStep } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";

const TellUsMore = (props:any) => {
    const params = useParams()
    const {orgId, formId} = params;
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);

    const identityIsChecked = useSelector((state:RootState) => state.alumniReg.identityIsCompleted);
    const interestsIsChecked = useSelector((state:RootState) => state.alumniReg.interestsIsCompleted);
    const skillsIsChecked = useSelector((state:RootState) => state.alumniReg.skillsIsCompleted);
    
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        // const timeStamp = serverTimestamp();
        const formResponse: FormResponse = {
            ...(alumniData.firstName && { firstName: alumniData.firstName}),
            ...(alumniData.lastName && { lastName: alumniData.lastName}),
            ...(alumniData.preferredName && {  preferredName: alumniData.preferredName}),
            ...(alumniData.email && {  email: alumniData.email}),
            ...(alumniData.contactNumber && {  contactNumber: alumniData.contactNumber}),
            ...(alumniData.linkedIn && {   linkedIn: alumniData.linkedIn}),
            ...(alumniData.communicationOpt && { communicationOpt: alumniData.communicationOpt}),
            ...(alumniData.newsletterOpt && { newsletterOpt: alumniData.newsletterOpt}),
            ...(alumniData.campus && { campus: alumniData.campus}),
            ...(alumniData.schoolHouse && { schoolHouse: alumniData.schoolHouse}),
            ...(alumniData.peerYear && { peerYear: alumniData.peerYear}),
            ...(alumniData.yearEnrolled && { yearEnrolled: alumniData.yearEnrolled}),
            ...(alumniData.identity.pronouns && { pronouns: alumniData.identity.pronouns}),
            ...(alumniData.identity.gender && { gender: alumniData.identity.gender}),
            ...(alumniData.identity.title && { title: alumniData.identity.title}),
            ...(alumniData.interests && { personalInterests: alumniData.interests}),
            ...(alumniData.skills && { skills: alumniData.skills}),
            rejected: false,
            submittedAt: serverTimestamp(),
        }
        if(orgId && formId) {
            const res = await submitRegistration(orgId, formId, formResponse);
            if(res.code === 200){ 
                setLoading(false);
                //dispatch(setUserOnboarding(true)); // NOTE Removed casue of this the onBoarding Priavte Route wrapper was triggering and redirecting user to onBoarding directly
                handleNext();
            }
            else{
                setLoading(false); // For error
            }
        } else {
            console.log("org and form IDs not found!");
            setLoading(false);
        }
    }

    const handleNext = () => {
        dispatch(setCurrentStep(REG_PAGES.FINAL_PAGE));
    }

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(()=>{
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[alumniData.inProgress])

    return (
        <>
            <Stack className={styles.formContainer} spacing={3} direction="column" justifyContent="space-between">
                <Button className={styles.optionalButtons} variant="outlined" onClick={()=>handleNavigation(REG_PAGES.IDENTITY)}
                    sx={{
                        "&.MuiButton-root": {
                            "&:hover": {
                                border: `2px solid ${styles.primary400}`,
                                fontWeight: '300'
                            }
                        }
                    }}
                >
                    <Stack direction="row" justifyContent="center" style={{position:'relative',width:"100%", height:"auto"}}>
                        <Stack style={{flexGrow:1}}>
                            Identity
                        </Stack>
                        <Stack>
                            {identityIsChecked && <svg style={{position:'absolute', right:'1%'}} width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#608BDE"/><path d="M17.3346 8L10.0013 15.3333L6.66797 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/></svg>}
                        </Stack>
                    </Stack>
                </Button>
                <Button className={styles.optionalButtons} variant="outlined" onClick={()=>handleNavigation(REG_PAGES.PERSONAL_INTERESTS)}
                    sx={{
                        "&.MuiButton-root": {
                            "&:hover": {
                                border: `2px solid ${styles.primary400}`,
                                fontWeight: '300'
                            }
                        }
                    }}
                >
                    <Stack direction="row" justifyContent="center" style={{position:'relative',width:"100%", height:"auto"}}>
                        <Stack style={{flexGrow:1}}>
                            Personal Interests
                        </Stack>
                        <Stack>
                            {interestsIsChecked && <svg style={{position:'absolute', right:'1%'}} width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#608BDE"/><path d="M17.3346 8L10.0013 15.3333L6.66797 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/></svg>}
                        </Stack>
                    </Stack>
                </Button>
                <Button className={styles.optionalButtons} variant="outlined" onClick={()=>handleNavigation(REG_PAGES.SKILLS)}
                    sx={{
                        "&.MuiButton-root": {
                            "&:hover": {
                                border: `2px solid ${styles.primary400}`,
                                fontWeight: '300'
                            }
                        }
                    }}
                >
                    <Stack direction="row" justifyContent="center" style={{position:'relative',width:"100%", height:"auto"}}>
                        <Stack style={{flexGrow:1}}>
                            Skills
                        </Stack>
                        <Stack>
                            {skillsIsChecked && <svg style={{position:'absolute', right:'1%'}} width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#608BDE"/><path d="M17.3346 8L10.0013 15.3333L6.66797 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/></svg>}
                        </Stack>
                    </Stack>
                </Button>
            </Stack>
            <PrimaryQRFormButton loading={loading} className={styles.nextButton} onClick={handleSubmit}>Next</PrimaryQRFormButton>
        </>
    );
}

export default TellUsMore;