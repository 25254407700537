export const createImage = (url) => new Promise((resolve, reject) => { const image = new Image(); image.addEventListener('load', () => resolve(image)); image.addEventListener('error', (error) => reject(error)); image.setAttribute('crossOrigin', 'anonymous'); image.src = url; });
function getRadianAngle(degreeValue) { return (degreeValue * Math.PI) / 180 }
function rotateSize(width, height, rotation) { const rotRad = getRadianAngle(rotation); return { width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height), height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height) }; }
export async function getCroppedImg( imageSrc, pixelCrop, rotation = 0, imageMode = "BLOB", imageFormat ="image/png", flip = { horizontal: false, vertical: false } ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) { return null; }
    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize( image.width, image.height, rotation );

    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    ctx.drawImage(image, 0, 0)
    const croppedCanvas = document.createElement('canvas')
    const croppedCtx = croppedCanvas.getContext('2d')
    if (!croppedCtx) { return null; }

    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    croppedCtx.drawImage( canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height )
    if(imageMode == "BLOB") return new Promise((resolve, reject) => { croppedCanvas.toBlob((file) => { resolve(file) }, imageFormat) });
    else if(imageMode == "BASE64") return croppedCanvas.toDataURL(imageFormat);
    return null;
}