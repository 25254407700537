import { Avatar, Checkbox, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlertComponent from "../../../components/AlertComponent";
import { PrimaryButton, PrimaryDangerButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import { auth } from "../../../firebase";
import { AccountType, DEFAULT_PERMISSIONS, Organisation } from "../../../firebase/types-organisations";
import { UserType } from "../../../firebase/types-userApi";
import { createInvitation, getUsersById, updateUserType } from "../../../firebase/userApis";
import constants from "../../../helpers/constants";
import { getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForInput } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import modalStyles from "./DeleteUserModal.module.scss";
import styles from "./MyAccountPanel.module.scss";


interface IAssignNewAdminModal {
    org: Organisation
}


const AssignNewAdminModal = ({org}: IAssignNewAdminModal) => {
    const [open, setOpen] = useState(false);
    const currentUser = useSelector((state: RootState) => state.user.userInfos);

    const [assignAdmin, setAssignAdmin] = useState(true);
    const [enterNewUser, setEnterNewUser] = useState(false);
    const [confirmAdmin, setConfirmAdmin] = useState(false);
    const [continueDelete, setContinueDelete] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setAssignAdmin(true);
        setEnterNewUser(false);
        setConfirmAdmin(false);
        setContinueDelete(false);
        setSelectedTeam(false);
        firstName.reset();
        lastName.reset();
        email.reset();
        contactNumber.reset();
        resetSelectedTeam();
    }
    const handleOpen = () => setOpen(true);
    const handleNewAssign = () => {
        setAssignAdmin(false);
        setEnterNewUser(true);
        resetSelectedTeam();
    }

    const resetSelectedTeam = () => {
        setTeam(team?.map(el => {
            return {
                id: el.id,
                user: el.user,
                selected: false
            }
        }))
    }

    const [errorMsg, setErrorMsg] = useState<string|null>("");

    const [team, setTeam] = useState<{ id: string, user: UserType, selected: boolean}[]>();

    const [assignLoading, setAssignLoading] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(false);

    const fetchUsers = async () => {
        const toAssignIDs = org.users?.filter(item => item !== auth.currentUser?.uid) || []
        const res = await getUsersById(toAssignIDs)
        if(res.code === 200) {
            setTeam(res.data.map(item => {return {id: item.id, user: item.user, selected: false}}));
        }
    }

    const firstName = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "First Name") } });
    const lastName = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "Name") } });
    const email = useTextInput({ inputValidator: (input) => { return getErrorMsgForEmailInput(input, false) } });
    const contactNumber = useTextInput({ inputValidator: (input) => { return getErrorMsgForContactNumber(input, false) } });

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleCheck = (email: string) => {
        const item = team?.filter(teamMember => teamMember.user.email === email);
        setTeam(prev => {
            return prev?.map(item => {
                if (item.user.email === email) {
                    return { id: item.id, user: item.user, selected: !item.selected }
                }
                return item
            })
        })
    }

    const assignNewAdmin = async () => {
        setAssignLoading(true);

        try {
            if(!org.id) {
                throw new Error("Organisation is invalid!");
            }
            if(!auth.currentUser) {
                throw new Error("User is not authenticated!");
            }

            // Assign new admin
            if(selectedTeam) {
                const selectedMembers = team?.filter(t => t.selected) || [];
                for(const member of selectedMembers) {
                    const res = await updateUserType(member.id, org.id, "ADMIN");
                    if(res.code === 500) throw new Error(res.errorMsg || `Unexpected error occurred whilst assiging ${member.user.firstName} to admin. Please try again later.`);
                }
            } else {
                // Create an invitation with new user details
                const res = await createInvitation({
                    orgId: org.id, 
                    orgName: org.name,
                    host: currentUser.firstName + " " + currentUser.lastName,
                    inviteeEmail: email.value,
                    accountType: AccountType.ADMIN,
                    permissions: DEFAULT_PERMISSIONS
                })
            }

            // Remove my admin priviledges
            const removeRes = await updateUserType(auth.currentUser.uid, org.id, "USER");
            if(removeRes.code === 500) throw new Error(removeRes.errorMsg ||  `Unexpected error occurred whilst removing your admin priviledges. Please try again later.`)
        

        } catch(error: any) {
            setErrorMsg(error.message || 'Unexpected error occurred.');
            setAssignLoading(false);
            handleClose();
        }
        setAssignLoading(false);
    }

    const NewUserDetails = () => {
        const handleContinue = () => {
            setEnterNewUser(false);
            setConfirmAdmin(true);
        }

        const disabled = firstName.hasError || lastName.hasError || email.hasError || contactNumber.hasError;

        return (
            <Stack>
                <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                    <IconButton onClick={() => {setAssignAdmin(true); setEnterNewUser(false);}}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19 22L13 16L19 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                    </IconButton>
                    <Stack className={modalStyles.titleContainer}>
                        <h5>Details of New User</h5>
                        <p>Please fill in the details of the new user to continue</p>
                    </Stack>
                </Stack>
                <Divider sx={{margin: `${pxToRem(24)} 0`}}/>
                <Stack gap={pxToRem(24)}>
                    <Stack gap={pxToRem(12)}>
                        <small>First Name</small>
                        <InputTextField type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={firstName.value} containerStyle={{ flex: 1 }} label='First Name' value={firstName.value} onChange={(e) => firstName.setValue(e.target.value)} error={firstName.hasError && firstName.hasTouched} helperText={<small>{firstName.getErrorMessage()}</small>} />
                    </Stack>
                    <Stack gap={pxToRem(12)}>
                        <small>Last Name</small>
                        <InputTextField type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={lastName.value} containerStyle={{ flex: 1 }} label='Last Name' value={lastName.value} onChange={(e) => lastName.setValue(e.target.value)} error={lastName.hasError && lastName.hasTouched} helperText={<small>{lastName.getErrorMessage()}</small>} />
                    </Stack>
                    <Stack gap={pxToRem(12)}>
                        <small>Email</small>
                        <InputTextField type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={email.value} containerStyle={{ flex: 1 }} label='example@email.com' value={email.value} onChange={(e) => email.setValue(e.target.value)} error={email.hasError && email.hasTouched} helperText={<small>{email.getErrorMessage()}</small>} />
                    </Stack>
                    <Stack gap={pxToRem(12)}>
                        <small>Contact Number</small>
                        <InputTextField type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={contactNumber.value} containerStyle={{ flex: 1 }} label='Contact Number' value={contactNumber.value} onChange={(e) => contactNumber.setValue(e.target.value)} error={contactNumber.hasError && contactNumber.hasTouched} helperText={<small>{contactNumber.getErrorMessage()}</small>} />
                    </Stack>
                </Stack>
                <Divider style={{margin: `${pxToRem(40)} 0 ${pxToRem(24)} 0`}}/>
                <Stack direction='row' justifyContent={'space-between'}>
                    <SecondaryButton className={modalStyles.cancelBtn} onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton className={modalStyles.assignBtn} onClick={handleContinue} disabled={disabled}>Continue</PrimaryButton>
                </Stack>
            </Stack>
        )
    }

    const ConfirmAdmin = () => {
        const handleContinue = async () => {
            await assignNewAdmin();
            setConfirmAdmin(false);
            setContinueDelete(true);
        }

        const handleBack = () => {
            if(selectedTeam) {
                setSelectedTeam(false);
                setAssignAdmin(true);
            } else {
                setEnterNewUser(true); 
            }
            setConfirmAdmin(false);
        }

        return (
            <Stack>
                <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                    <IconButton onClick={handleBack} disabled={assignLoading}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19 22L13 16L19 10" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M19 22L13 16L19 10" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                    </IconButton>
                    <Stack className={modalStyles.titleContainer}>
                        <h5>Assigning Admin</h5>
                        <p>You are about to give this user Admin authority</p>
                    </Stack>
                </Stack>
                <Divider sx={{margin: `${pxToRem(24)} 0`}}/>
                <p className={modalStyles.confirmAdminText}>
                    Assigning <span style={{fontWeight: 600, color: styles.primary500}}>{selectedTeam ? team?.filter(t=>t.selected).map(t=>t.user.firstName+" "+t.user.lastName).join(', ') : email.value || "<email here>"}</span> as an Admin will give this user access and permission to edit all associated data relevant to <span style={{fontWeight: 600}}>{org.name || "<org name here>"}</span>. 
                    This includes the ability to manage user permissions, define access levels, and configure this organisation’s settings.
                    <br/><br/>
                    Do you wish to continue?
                </p>
                <Divider style={{margin: `${pxToRem(40)} 0 ${pxToRem(24)} 0`}}/>
                <Stack direction='row' justifyContent={'space-between'}>
                    <SecondaryButton className={modalStyles.cancelBtn} disabled={assignLoading} onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton className={modalStyles.assignBtn} loading={assignLoading} onClick={handleContinue}>Yes, Assign</PrimaryButton>
                </Stack>
            </Stack>
        )
    }

    const ContinueDelete = () => {
        const handleContinue = () => {
            handleClose();
        }

        return (
            <Stack>
                <p className={modalStyles.confirmAdminText}>
                    You have assigned <span style={{fontWeight: 600, color: styles.primary500}}>{selectedTeam ? team?.filter(t=>t.selected).map(t=>t.user.firstName+" "+t.user.lastName) : email.value || "<email here>"}</span> as an Admin for <span style={{fontWeight: 600}}>{org.name}</span>. 
                    <br/><br/>
                    Do you still wish to delete your account?
                </p>
                <Divider style={{margin: `${pxToRem(40)} 0 ${pxToRem(24)} 0`}}/>
                <Stack direction='row' justifyContent={'space-between'}>
                    <SecondaryButton className={modalStyles.cancelBtn} onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryDangerButton className={modalStyles.assignBtn} onClick={handleContinue}>Yes, delete my account</PrimaryDangerButton>
                </Stack>
            </Stack>
            
        )
    }

    return (
        <>
            <AlertComponent message={errorMsg || ""} visible={errorMsg !== null} title={"Something went wrong"} handleClose={()=>{setErrorMsg(null)}}/>
            <PrimaryButton className={styles.orgButton} style={{marginLeft:'auto'}} onClick={handleOpen}>Assign New Admin</PrimaryButton>
            <CustomizedModal open={open} handleClose={()=>{}}>
                <Stack className={modalStyles.modalContainer}>
                    {
                        assignAdmin &&
                        <>
                            <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="28" cy="28" r="28" fill="#F5F5F5"/> <path d="M36.1663 38.5V31.5M32.6663 35H39.6663M27.9997 31.5H23.333C21.1586 31.5 20.0714 31.5 19.2138 31.8552C18.0704 32.3289 17.1619 33.2373 16.6882 34.3808C16.333 35.2384 16.333 36.3256 16.333 38.5M32.083 17.8392C33.7932 18.5315 34.9997 20.2082 34.9997 22.1667C34.9997 24.1251 33.7932 25.8018 32.083 26.4941M29.7497 22.1667C29.7497 24.744 27.6603 26.8333 25.083 26.8333C22.5057 26.8333 20.4163 24.744 20.4163 22.1667C20.4163 19.5893 22.5057 17.5 25.083 17.5C27.6603 17.5 29.7497 19.5893 29.7497 22.1667Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> <path d="M36.1663 38.5V31.5M32.6663 35H39.6663M27.9997 31.5H23.333C21.1586 31.5 20.0714 31.5 19.2138 31.8552C18.0704 32.3289 17.1619 33.2373 16.6882 34.3808C16.333 35.2384 16.333 36.3256 16.333 38.5M32.083 17.8392C33.7932 18.5315 34.9997 20.2082 34.9997 22.1667C34.9997 24.1251 33.7932 25.8018 32.083 26.4941M29.7497 22.1667C29.7497 24.744 27.6603 26.8333 25.083 26.8333C22.5057 26.8333 20.4163 24.744 20.4163 22.1667C20.4163 19.5893 22.5057 17.5 25.083 17.5C27.6603 17.5 29.7497 19.5893 29.7497 22.1667Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> <path d="M36.1663 38.5V31.5M32.6663 35H39.6663M27.9997 31.5H23.333C21.1586 31.5 20.0714 31.5 19.2138 31.8552C18.0704 32.3289 17.1619 33.2373 16.6882 34.3808C16.333 35.2384 16.333 36.3256 16.333 38.5M32.083 17.8392C33.7932 18.5315 34.9997 20.2082 34.9997 22.1667C34.9997 24.1251 33.7932 25.8018 32.083 26.4941M29.7497 22.1667C29.7497 24.744 27.6603 26.8333 25.083 26.8333C22.5057 26.8333 20.4163 24.744 20.4163 22.1667C20.4163 19.5893 22.5057 17.5 25.083 17.5C27.6603 17.5 29.7497 19.5893 29.7497 22.1667Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                                <Stack className={modalStyles.titleContainer}>
                                    <h5>Assign New Admin</h5>
                                    <p>Assign a new Admin from your team or assign a new user</p>
                                </Stack>
                            </Stack>
                            <Divider sx={{margin: `${pxToRem(24)} 0`}}/>
                            <Stack className={modalStyles.content}>
                                <p>{org.name}</p>
                                <PrimaryButton className={modalStyles.assignBtn} disabled={assignLoading} onClick={handleNewAssign}>Assign to New User</PrimaryButton>
                                <p style={{fontSize: pxToRem(14)}}>Your team</p>
                                <Stack gap={pxToRem(12)}>
                                    {
                                        team && team.length > 0 ?
                                        team.map(({ user, selected }) => (
                                            <>
                                                <Stack direction={'row'} gap={pxToRem(24)} className={modalStyles.teamCard}>
                                                    {user.photoId ? (
                                                        <Avatar alt={user.firstName + " " + user.lastName} src={user.photoId} sx={{ width: pxToRem(40), height: pxToRem(40) }} />
                                                    ) : (
                                                        <Avatar alt={user.firstName + " " + user.lastName} sx={{ width: pxToRem(40), height: pxToRem(40), bgcolor: 'rgba(175, 202, 255, 0.3)', color: "#608BDE" }} >
                                                            <Typography variant="h6" sx={{ color: "#608BDE" }}>
                                                                {(user.firstName as string).charAt(0)}
                                                            </Typography>
                                                        </Avatar>

                                                    )}
                                                    <Stack>
                                                        <small>{user.firstName + " " + user.lastName + " (" + (user.role || "No Role") +")"} </small>
                                                        <small className={modalStyles.userPosition}>{user.email}</small>
                                                    </Stack>
                                                    <Checkbox checked={selected} onChange={() => handleCheck(user.email)} style={{marginLeft: 'auto'}} sx={{'&:hover': { backgroundColor: 'transparent !important' }}}
                                                        icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="11.5" stroke="#737373"/> </svg> }
                                                        checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="12" cy="12" r="12" fill="#447D75"/> <path d="M17.3337 8L10.0003 15.3333L6.66699 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> </svg> }
                                                    />
                                                </Stack>
                                            </>
                                        ))
                                        :
                                            <small style={{color: styles.neutrals500}}>(0) Users on your team</small>
                                    }
                                </Stack>
                            </Stack>
                            <Divider style={{margin: `${pxToRem(40)} 0 ${pxToRem(24)} 0`}}/>
                            <Stack direction='row' justifyContent={'space-between'}>
                                <SecondaryButton className={modalStyles.cancelBtn} disabled={assignLoading} onClick={handleClose}>Cancel</SecondaryButton>
                                <PrimaryButton className={modalStyles.assignBtn} disabled={!team?.some(t => t.selected)} loading={assignLoading} onClick={()=>{setAssignAdmin(false); setSelectedTeam(true); setConfirmAdmin(true);}}>Continue</PrimaryButton>
                            </Stack>
                        </>
                    }
                    {
                        enterNewUser && NewUserDetails()
                    }
                    {
                        confirmAdmin && ConfirmAdmin()
                    }
                    {
                        continueDelete && ContinueDelete()
                    }
                </Stack>
            </CustomizedModal>
        </>
    );
}

export default AssignNewAdminModal;