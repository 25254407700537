import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type TOrganisation = { name: string | null,  address: string | null, 
                       suburb: string | null, locationState: string | null, 
                       postCode: string | null, country: string | null,
                       latitude?: number , longitude?: number };
type TAlumni = 'PRIMARY' | 'SECONDARY' | 'PRIMARY_SECONDARY' | null;
type TCampuses = {
    campusesList: string[] | null,
    defaultCampus: string | null
};
type TOnBoardingSlice = { organisation: TOrganisation, alumni: TAlumni, campuses: TCampuses };
const INITIAL_STATE: TOnBoardingSlice = {
    organisation:{ name: null, address: null, suburb: null, locationState: null, postCode: null, country: null },
    alumni: null, campuses: {campusesList: null, defaultCampus:null}};
export const sharedStateSlice = createSlice({
  name: "onBoarding",
  initialState: INITIAL_STATE,
  reducers: {
    setOnBoardingDefault: (state) => {
        state = INITIAL_STATE
    },
    setOnBoardingOrganisation: (state, action: PayloadAction<TOrganisation>) => {   
        const { name, address, suburb, locationState, postCode, country, latitude, longitude } = action.payload;
        state.organisation.name = name;
        state.organisation.address = address;
        state.organisation.suburb = suburb;
        state.organisation.locationState = locationState;
        state.organisation.postCode = postCode;
        state.organisation.country = country;
        state.organisation.latitude = latitude;
        state.organisation.longitude = longitude;
    },
    setAlumniType: (state, action: PayloadAction<TAlumni>)=>{
        state.alumni = action.payload;
    },
    setCampuses : (state, action: PayloadAction<TCampuses>)=>{
        state.campuses = action.payload;
    },
    resetOnBoardingState: (state) => {
        state.organisation = { ...INITIAL_STATE.organisation };
        state.alumni = INITIAL_STATE.alumni;
        state.campuses = { ...INITIAL_STATE.campuses };
    }
  },
});

export const { setOnBoardingDefault, setOnBoardingOrganisation, setAlumniType, setCampuses, resetOnBoardingState} = sharedStateSlice.actions;

export default sharedStateSlice.reducer;
