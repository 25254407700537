import Stack from "@mui/material/Stack";
import { useMemo, useState } from "react";
import AlertComponent from "../AlertComponent";
import { PrimaryOnboardingButton } from "../Buttons/OnboardingButtons";
import { CustomizedDropdown } from "../Inputs/Dropdown/CustomizedDropdown";
import { AsyncStatus, Organisation } from "../../firebase/shared";
import { OrganisationSearch, OrganisationSearchs, } from "../../firebase/types-organisationSearchs";
import { EMPTY_INPUT_HELPER_MSG, GENERAL_ERROR_PROMPT, } from "../../helpers/shared";
import useTextInput from "../../hooks/useTextInput";
import styles from "./OrganizationSelector.module.scss";
import { State, Country } from "country-state-city";
import { getCountryISOCodeFromPlace, getLngLatFromPlace, getLocalityFromPlace, getPostCodeOfAPlace, getStateISOCodeFromAuPlace, } from "../../helpers/googlePlaceHelpers";
import { RootState } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import CustomizedPaper from "../CustomizedPaper";
import { OnboardingSteps } from "../../pages/OnboardingPage";

import { resetOnBoardingState, setOnBoardingOrganisation } from "../../redux/onBoardingSlice";
import { PrimaryButton, SecondaryButton } from "../Buttons/ButtonUI";
import InputTextField from "../Inputs/TextFields/InputTextField";
import constants from "../../helpers/constants";
import { PlaceCard } from "./components";
import pxToRem from "../../helpers/pxToRem";
import { serverTimestamp } from "firebase/firestore";
import { createOrganisation } from "../../firebase/organisationApis";

interface SelectedSimilarOrg extends OrganisationSearch {
  organisationUid: string;
}

interface IOrganizationSelector {
  selectedPlace: any | null;
  updateOnboardingData: (orgUid: string, orgName: string) => void;
  updateOnboardingStep: (step: OnboardingSteps) => void;
  usedInsideModal?: boolean;
  handleCloseModal?: () => void;
}

const OrganizationSelector = ({ selectedPlace, updateOnboardingData, updateOnboardingStep, usedInsideModal, handleCloseModal, }: IOrganizationSelector) => {
  // Org not existed
  const organisation = useSelector((state: RootState) => state.onBoarding.organisation); // Redux Data
  const orgName = useTextInput({
    inputValidator: (input: string) => { if (input.length === 0) { return EMPTY_INPUT_HELPER_MSG; } },
    defaultValue: organisation && organisation.name ? organisation.name : selectedPlace?.name,
  });

  const streetAddress = useTextInput({
    inputValidator: (input: string) => { if (input.length === 0) { return EMPTY_INPUT_HELPER_MSG; } },
    defaultValue: organisation && organisation.address ? organisation.address : selectedPlace?.formatted_address ?? "",
  });

  const postcode = useTextInput({
    inputValidator: (input) => { return undefined; },
    defaultValue: organisation && organisation.postCode ? organisation.postCode : getPostCodeOfAPlace(selectedPlace),
    cancelHasTouchedAutoUpdate: true,
  });

  // const suburb = useTextInput({
  //   inputValidator: (input) => { return undefined; },
  //   defaultValue: organisation && organisation.suburb ?  organisation.suburb : getLocalityFromPlace(selectedPlace),
  //   cancelHasTouchedAutoUpdate: true,
  // });

  const reduxOrganisationCountry = organisation && organisation.country ? organisation.country : null;
  const reduxOrganisationState = organisation && organisation.locationState ? organisation.locationState : null;
  const [selectedState, setSelectedState] = useState<string | null>(reduxOrganisationState ? reduxOrganisationState : getStateISOCodeFromAuPlace(selectedPlace));
  const [selectedCountry, setSelectedCountry] = useState<null | string>(reduxOrganisationCountry ? reduxOrganisationCountry : getCountryISOCodeFromPlace(selectedPlace));
  // Org existed
  const [similarOrgs, setSimilarOrgs] = useState<OrganisationSearchs>({});
  // other
  const [loadSimilarOrgsStatus, setLoadSimilarOrgsStatus] =
    useState<AsyncStatus>("loading");
  const [submitStatus, setSubmitStatus] = useState<AsyncStatus>("default");
  const [hasTouchedState, setHasTouchedState] = useState(false);
  const [hasTouchedCountry, setHasTouchedCountry] = useState(false);
  const [selectedSimilarOrg, setSelectedSimilarOrg] =
    useState<SelectedSimilarOrg>();
  const [sendJoinReqStatus, setSendJoinReqStatus] =
    useState<AsyncStatus>("default");
  const userId = useSelector((state: RootState) => state.user.userInfos.uid);
  const userName = useSelector((state: RootState) => state.user.userInfos.displayName);
  const allCountries = useMemo(() => Country.getAllCountries().map((country) => ({ value: country.isoCode, label: country.name, })), []);
  const allStates = useMemo(() => {
    if (!selectedCountry) return [];
    return State.getStatesOfCountry(selectedCountry).map((state) => ({ label: state.name, value: state.isoCode }));
  }, [selectedCountry]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Reset all field value and redux
  const resetAllVariables = () => {
    dispatch(resetOnBoardingState())
  }

  // Functionality Changed to add more info of the organisation
  const handleCreateOrganisation = async () => {
    orgName.setHasTouched(true); streetAddress.setHasTouched(true);
    // suburb.setHasTouched(true);
    postcode.setHasTouched(true); setHasTouchedCountry(true); setHasTouchedState(true);
    if (orgName.hasError || streetAddress.hasError || //suburb.hasError ||
      // Users are only forced to selecte state when there are multiple states in a specific country
      !selectedCountry //|| postcode.hasError 
      || (!selectedState && allStates.length > 0)
    )
      return;

    const lngLat = getLngLatFromPlace(selectedPlace);
    dispatch(setOnBoardingOrganisation({
      name: orgName.value,
      address: streetAddress.value,
      locationState: selectedState,
      country: selectedCountry,
      postCode: selectedPlace ? getPostCodeOfAPlace(selectedPlace) ?? postcode.value : postcode.value,
      ...(lngLat && {
        latitude: lngLat.latitude,
        longitude: lngLat.longitude,
      }),
      suburb: null
    }))
    updateOnboardingStep({ type: "CREATE_SUCCESS", count: 3 });
    // updateOnboardingStep({ type: "SELECT_ALUMINI_TYPE", count: 2 });
    return
  };

  const navigateToNextStep = async () => {
    orgName.setHasTouched(true); streetAddress.setHasTouched(true);
    // suburb.setHasTouched(true);
    postcode.setHasTouched(true); setHasTouchedCountry(true); setHasTouchedState(true);
    if (orgName.hasError || streetAddress.hasError || //suburb.hasError ||
      // Users are only forced to selecte state when there are multiple states in a specific country
      !selectedCountry //|| postcode.hasError 
      // || (!selectedState && allStates.length > 0)
    )
      return;

    const lngLat = getLngLatFromPlace(selectedPlace);
    dispatch(setOnBoardingOrganisation({
      name: orgName.value,
      address: streetAddress.value,
      locationState: selectedState,
      country: selectedCountry,
      postCode: selectedPlace ? getPostCodeOfAPlace(selectedPlace) ?? postcode.value : postcode.value,
      ...(lngLat && {
        latitude: lngLat.latitude,
        longitude: lngLat.longitude,
      }),
      suburb: null
    }))

    if (userId) {
      setLoading(true);
      const timeStamp = serverTimestamp();
      const now = new Date();
      const timestampNow = now.getTime();
      let trialEndTimestamp = new Date(timestampNow + (14 * 24 * 60 * 60 * 1000)).getTime();
      let postCode = selectedPlace ? getPostCodeOfAPlace(selectedPlace) ?? postcode.value : postcode.value;
      const lngLat = getLngLatFromPlace(selectedPlace);

      const organisation: Organisation = {
        ...(orgName.value && { name: orgName.value }),
        ...(selectedCountry && { country: selectedCountry }),
        ...(streetAddress.value && { streetAddress: streetAddress.value }),
        ...(selectedState && { state: selectedState }),
        ...(postcode && { postCode: postCode }),
        ...(lngLat && { latitude: lngLat.latitude }),
        ...(lngLat && { longitude: lngLat.longitude }),
        createdByUserId: userId,
        users: [userId],
        status: "ACTIVETRIAL",
        created_at: timeStamp,
        trial_end: trialEndTimestamp,
        updated_at: timeStamp
      }
      const response = await createOrganisation(userId, organisation)
      if (response.code === 200) {
        setLoading(false);
        //dispatch(setUserOnboarding(true)); // NOTE Removed casue of this the onBoarding Priavte Route wrapper was triggering and redirecting user to onBoarding directly
        updateOnboardingStep({ type: "CREATE_SUCCESS", count: 3 })
      }
      else {
        setLoading(false); // For error
      }
    }
  }

  const getFormWidth = useMemo(() => {
    return usedInsideModal ? styles["input--modal"] : styles["input--modal"];
  }, [usedInsideModal]);

  const render = () => {
    if (Object.keys(similarOrgs).length === 0) {
      return (
        // FIXME: Currenly using container as the main width for all the child component and need to check => scaleWidthUp_toFitModal
        <Stack className={usedInsideModal ? styles.scaleWidthUp_toFitModal : styles.container} alignItems={"center"}>
          {/* TODO: Customized alert */}
          <AlertComponent severity={"error"} message={GENERAL_ERROR_PROMPT} visible={submitStatus === "failure"} handleClose={function (state: boolean): void { setSubmitStatus("default"); }} />
          <AlertComponent severity={"error"} message={GENERAL_ERROR_PROMPT} visible={sendJoinReqStatus === "failure"} handleClose={function (state: boolean): void { setSendJoinReqStatus("default"); }} />
          <h4 className='regular xsMarginBottom' style={{ textAlign: "center", marginTop: pxToRem(40), display: usedInsideModal ? "none" : "unset" }}>Create a New Organisation</h4>
          <small className='regular largeMarginBottom' style={{ textAlign: "center", display: usedInsideModal ? "none" : "unset" }} >Enter the details of your organisation{" "}</small>
          <Stack className={styles.form}>
            <InputTextField
              customHeight={constants.onboardingInputHeight}
              fontSize={constants.onboardingFontSize}
              className={styles.inputComponent}
              valueCheck={orgName.value}
              value={orgName.value} onChange={(e) => orgName.setValue(e.target.value)}
              title={"Name of Organisation*"}
              isEndAdornment
              adornment={
                orgName.hasError && orgName.hasTouched ? (
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                    <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                  </svg>
                ) : undefined
              }
              error={orgName.hasError && orgName.hasTouched}
              helperText={<small>{orgName.getErrorMessage()}</small>}
            />

            <Stack className={styles.org_address}>
              <p style={{ color: styles['shades100'] }}>Organisation's Address</p>
              <CustomizedDropdown
                customHeight={constants.onboardingInputHeight}
                fontSize={constants.onboardingFontSize}
                containerStyle={{ flex: 1 }}
                // input={<OutlinedInput label='Country*' />}
                datas={allCountries}
                label='Country*'
                value={selectedCountry ?? ""}
                valueCheck={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value as string)}
                error={hasTouchedCountry && !selectedCountry}
                helperText={hasTouchedCountry && !selectedCountry ? "This field cannot be empty" : undefined}
              />

              <InputTextField
                customHeight={constants.onboardingInputHeight}
                fontSize={constants.onboardingFontSize}
                className={styles.inputComponent}
                valueCheck={streetAddress.value}
                value={streetAddress.value}
                onChange={(e) => streetAddress.setValue(e.target.value)}
                label='Street Address*'
                error={streetAddress.hasError && streetAddress.hasTouched}
                helperText={<small>{streetAddress.getErrorMessage()}</small>}
                isEndAdornment
                adornment={
                  streetAddress.hasError && streetAddress.hasTouched ? (
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                      <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                  ) : undefined
                }
              />

              {/* <InputTextField  
                customHeight={constants.onboardingInputHeight}
                fontSize={constants.onboardingFontSize}
                className={styles.inputComponent}
                valueCheck={suburb.value}
                value={suburb.value}
                onChange={(e) => suburb.setValue(e.target.value)}
                error={suburb.hasError && suburb.hasTouched}
                helperText={<small>{suburb.getErrorMessage()}</small>}
                label='Suburb'
                isEndAdornment
                adornment={
                  suburb.hasError && suburb.hasTouched ? (
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                      <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                  ) : undefined
                }
              /> */}

              <Stack className={styles.stateRow}>
                <CustomizedDropdown
                  customHeight={constants.onboardingInputHeight}
                  fontSize={constants.onboardingFontSize}
                  containerStyle={{ flex: 1 }}
                  datas={allStates}
                  disabled={allStates.length === 0 && !(hasTouchedState && !selectedState)}
                  value={selectedState ?? ""}
                  valueCheck={selectedState}
                  onChange={(e) => setSelectedState(e.target.value as string)}
                  label='State'
                />
                <InputTextField
                  customHeight={constants.onboardingInputHeight}
                  fontSize={constants.onboardingFontSize}
                  className={styles.inputComponent}
                  containerStyle={{ flex: 1 }}
                  label='Postcode'
                  isEndAdornment
                  adornment={
                    postcode.hasError && postcode.hasTouched ? (
                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' >
                        <path d='M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z' stroke='#d32f2f' strokeLinecap='round' strokeLinejoin='round' />
                      </svg>
                    ) : undefined
                  }
                  valueCheck={postcode.value}
                  value={postcode.value} onChange={(e) => postcode.setValue(e.target.value)}
                  error={postcode.hasError && postcode.hasTouched}
                  helperText={<small>{postcode.getErrorMessage()}</small>}
                />
              </Stack>
            </Stack>
          </Stack>
          {usedInsideModal ? (
            <>
              <div
                className={`horizontalDivider ${styles.classCreateNewOrg_modal_divider}`}
                style={{ width: "100%" }}
              ></div>
              <Stack className={styles.orgDetailsConfirm_buttonStack_modal}>
                <SecondaryButton
                  className={styles.secondaryButton}
                  onClick={() => {
                    if (handleCloseModal) {
                      handleCloseModal();
                    }
                  }}
                >
                  <small>Cancel</small>
                </SecondaryButton>
                <PrimaryButton
                  className={styles.createNewOrgButton_modal}
                  loading={submitStatus === "loading"}
                  onClick={handleCreateOrganisation}
                >
                  <small>Create Organisation</small>
                </PrimaryButton>
              </Stack>
            </>
          ) : (
            <>
              <PrimaryOnboardingButton className={`${styles.createOrgButton} largeMarginTop `}
                loading={submitStatus === "loading"} onClick={navigateToNextStep} >
                Create Organisation
              </PrimaryOnboardingButton>
              <p className={`${styles.backToSearch} smallMarginTop`} style={{ textAlign: "center" }}
                onClick={() => {
                  resetAllVariables();
                  updateOnboardingStep({ type: "SEARCH_PLACE", count: 1 });
                }}>
                Back to Search
              </p>
            </>
          )}
        </Stack>
      );
    }
    // if (selectedSimilarOrg) {
    //   return usedInsideModal ? (
    //     <Stack
    //       className={`${styles.modal_selectedOrgDetailForm} ${styles.scaleDown_toFitModal}`}
    //     >
    //       <h6 className='regular'>Is this the correct Organisation?</h6>
    //       <Stack className={styles.selectedSImilarOrgDetailStack}>
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <small>Name of Organisation:</small>
    //           <small>{selectedSimilarOrg.name}</small>
    //         </Stack>
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <small>Address:</small>
    //           <small>
    //             {Object.values(selectedSimilarOrg.campus)[0].address}
    //           </small>
    //         </Stack>
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <small>Existing Alumni on database:</small>
    //           {/* TODO: change to real alumni count */}
    //           <small>0</small>
    //         </Stack>
    //       </Stack>
    //       <div className='horizontalDivider' style={{ width: "100%" }}></div>
    //       <Stack className={styles.orgDetailsConfirm_buttonStack_modal}>
    //         <SecondaryButton
    //           className={styles.secondaryButton}
    //           onClick={() => {
    //             if (handleCloseModal) {
    //               handleCloseModal();
    //             }
    //           }}
    //         >
    //           <small>Cancel</small>
    //         </SecondaryButton>
    //         <PrimaryButton
    //           className={styles.secondaryButton}
    //           loading={sendJoinReqStatus === "loading"}
    //           onClick={sendJoinRequest}
    //         >
    //           <small>Request to Join</small>
    //         </PrimaryButton>
    //       </Stack>
    //     </Stack>
    //   ) : (
    //     <>
    //       <h4 className={`regular`}>Is this the correct organisation?</h4>
    //       <small className='xsMarginTop largeMarginBottom'>
    //         Please check these details carefully
    //       </small>
    //       <Stack
    //         className={`${styles.selectedSimilarOrgDetailsForm} largeMarginBottom ${getFormWidth}`}
    //       >
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <p className={styles.neutrals500}>Name of Organisation:</p>
    //           <p>{selectedSimilarOrg.name}</p>
    //         </Stack>
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <p className={styles.neutrals500}>Address:</p>
    //           <p>{Object.values(selectedSimilarOrg.campus)[0].address}</p>
    //         </Stack>
    //         <Stack className={styles.selectedSImilarOrgDetailRow}>
    //           <p className={styles.neutrals500}>Existing Alumni on database:</p>
    //           {/* TODO: change to real alumni count */}
    //           <p>0</p>
    //         </Stack>
    //       </Stack>
    //       <PrimaryButton
    //         className={`${styles.reqToJoinButton} ${getFormWidth}`}
    //         loading={sendJoinReqStatus === "loading"}
    //         onClick={sendJoinRequest}
    //       >
    //         <p>Request to Join Organisation</p>
    //       </PrimaryButton>
    //       <p
    //         className={`${styles.backToSearch} mediumMarginTop`}
    //         onClick={() => setSelectedSimilarOrg(undefined)}
    //       >
    //         Back
    //       </p>
    //     </>
    //   );
    // }

    return (
      <>
        <Stack className={`${styles.lockIconBg} mediumMarginBottom`} sx={{ display: usedInsideModal ? "none" : "flex" }} >
          <svg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52' fill='none' >
            <path d='M16.2502 23.8333H9.96683C8.75338 23.8333 8.14666 23.8333 7.68318 24.0695C7.2755 24.2772 6.94404 24.6087 6.73632 25.0164C6.50016 25.4798 6.50016 26.0866 6.50016 27.3V45.5M35.7502 23.8333H42.0335C43.2469 23.8333 43.8537 23.8333 44.3171 24.0695C44.7248 24.2772 45.0563 24.6087 45.264 25.0164C45.5002 25.4798 45.5002 26.0866 45.5002 27.3V45.5M35.7502 45.5V13.4333C35.7502 11.0064 35.7502 9.79299 35.2779 8.86604C34.8624 8.05067 34.1995 7.38776 33.3841 6.97231C32.4572 6.5 31.2437 6.5 28.8168 6.5H23.1835C20.7566 6.5 19.5432 6.5 18.6162 6.97231C17.8008 7.38776 17.1379 8.05067 16.7225 8.86604C16.2502 9.79299 16.2502 11.0064 16.2502 13.4333V45.5M47.6668 45.5H4.3335M23.8335 15.1667H28.1668M23.8335 23.8333H28.1668M23.8335 32.5H28.1668' stroke='black' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </Stack>
        <h6 className='regular xsMarginBottom' style={{ display: usedInsideModal ? "none" : "flex" }}>{`Hello, ${userName}!`}</h6>
        <p className='regular mediumMarginBottom' style={{ display: usedInsideModal ? "none" : "flex" }}>
          {`You have selected “${selectedPlace.name}”`}
        </p>
        <Stack className={usedInsideModal ? styles["searchResultForm--modal"] : styles.searchResultForm}>
          <small className={styles.primary500}>{`(${Object.keys(similarOrgs).length
            }) existing organisations found, do you want to request access or start fresh?`}</small>
          <Stack className={styles.resultsFormWrapper}>
            <CustomizedPaper className={styles.similarOrgsForm} cancelHoriontalPadding>
              <>
                <Stack className={styles.orgResultCardsColumn}>
                  {Object.entries(similarOrgs).map(
                    ([orgUid, organisationSearch]) => (
                      <PlaceCard
                        key={orgUid}
                        name={organisationSearch.name}
                        address={Object.values(organisationSearch.campus)[0].address}
                        // TODO: add alumni count
                        extraText={""}
                        clickHandler={() =>
                          setSelectedSimilarOrg({
                            organisationUid: orgUid,
                            ...organisationSearch,
                          })
                        }
                        usedInsedModal={usedInsideModal}
                      />
                    )
                  )}
                </Stack>
                <Stack className={styles.createNewOrgStack}>
                  <small>Don’t see anything you like? </small>
                  <PrimaryButton className={styles["createOrgButton--small"]} onClick={() => setSimilarOrgs({})}>
                    <small>Create New</small>
                  </PrimaryButton>
                </Stack>
              </>
            </CustomizedPaper>
            <p className={`${styles.backToSearch} smallMarginTop`} onClick={() => updateOnboardingStep({ type: "SEARCH_PLACE", count: 1 })} style={{ display: usedInsideModal ? "none" : "flex" }}>
              Back to Search
            </p>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <>
      {/* TODO: customized */}
      <AlertComponent severity={"error"} message={GENERAL_ERROR_PROMPT} visible={loadSimilarOrgsStatus === "failure"}
        handleClose={function (state: boolean): void { setLoadSimilarOrgsStatus("default"); }} />
      {render()}
    </>
  );
};

export default OrganizationSelector;
