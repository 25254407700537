import React from 'react';
import { Menu, MenuItem } from '@mui/material';

// Define the props interface
interface AlumniContextMenuProps {
  mouseX: number;
  mouseY: number;
  orgId: string;
  alumniId: string;
  open: boolean;
  type: string;
  onClose: () => void;
}

const AlumniContextMenu: React.FC<AlumniContextMenuProps> = ({
  mouseX,
  mouseY,
  orgId,
  alumniId,
  open,
  type,
  onClose,
}) => {
  const handleGoToProfile = () => {
    window.open(`/dashboard/organisation/${orgId}/${type}/${alumniId}`, '_blank');
    onClose();
  };

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={open ? { top: mouseY, left: mouseX } : undefined}
    >
      <MenuItem onClick={handleGoToProfile}>View Profile</MenuItem>
    </Menu>
  );
};

export default AlumniContextMenu;
