import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Stack } from '@mui/material';
import { httpsCallable, getFunctions } from 'firebase/functions';
import styles from "./index.module.scss";
import SeatsIndicator from './seatsBar/seatsBar';
import dayjs from 'dayjs'; // For date formatting
import { SubscriptionResponse } from '../../../../firebase/types-billingApis';
import AlertComponent, { AlertDialogComponent, AlertDialogSimpleComponent } from '../../../../components/AlertComponent';
import { handleLinkClick } from '../../../SelfRegistrationFormsPage/helpers';
import CustomizedModal from '../../../../components/CustomizedModal';
import { PrimaryDangerButton, SecondaryButton } from '../../../../components/Buttons/ButtonUI';
import pxToRem from '../../../../helpers/pxToRem';
import LoadingComponent from '../../../../components/LoadingStatus/Loading';
import ManagePlanPopup from '../managePlan/managePlanPopup';
import { fetchInvitationsCount } from '../../../../firebase/userApis';
import SuccessUpdatePlanPopup from '../updatePlanPopup/successUpdatePopup';
import FailedUpdatePlanPopup from '../updatePlanPopup/failedUpdatePopup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';

interface SubscriptionComponentProps {
    adminEmail: string;
    subscriptionId: string;
    orgId: string;
    seatsUsed: number;
}


export interface SubscriptionInfo {
    planType: string;
    cancelAtTheEnd: boolean;
    status: string;
    subscriptionCost: number;
    currency: string;
    nextPaymentDate: string;
    currentPeriodStart: string;
    currentPeriodEnd: string;
    totalSeats: number;
}


const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({ adminEmail, subscriptionId, orgId, seatsUsed }) => {
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo | null>(null);
    const [daysLeft, setDaysLeft] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [alertMsg, setAlertMsg] = useState<string>('');
    const [showAlertMsg, setShowAlertMsg] = useState<boolean>(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);  // State to trigger reload
    const [isUpdatePlanOpen, setIsUpdatePlanOpen] = useState<boolean>(false);
    const [invitationCount, setInvitationCount] = useState<number>(0);
    const [isLoadingRenew, setIsLoadingRenew] = useState<boolean>(false);
    const [isCancelSuccess, setIsCancelSuccess] = useState<'success' | 'failed' | null>(null);
    const [operationStatus, setOperationStatus] = useState<'success' | 'failed' | null>(null);
    const billingPermission = useSelector((state: RootState) => state.globalState.permissions?.billing);

    const handleStatusChange = (status: boolean) => {
        setOperationStatus(status ? 'success' : 'failed');
    };

    const handleCancelStatus = (status: boolean) => {
        setIsCancelSuccess(status ? 'success' : 'failed');
    };

    const handleCancelClose = () => {
        if (isCancelSuccess === 'success') {
            setIsCancelSuccess(null); // Resets the popup state to close it
            setReloadTrigger(!reloadTrigger)
        } else {
            setIsCancelSuccess(null); // Resets the popup state to close it
        }
    }

    const handleClose = () => {
        if (operationStatus === 'success') {
            setOperationStatus(null); // Resets the popup state to close it
            setReloadTrigger(!reloadTrigger)
        } else {
            setOperationStatus(null); // Resets the popup state to close it
        }
    }

    useEffect(() => {
        if (!subscriptionId) return; // Ensure subscriptionId is present
        fetchSubscriptionDetails(); // Function to fetch subscription details
        updateInvitationCount();
    }, [subscriptionId, reloadTrigger, seatsUsed]); // Include reloadTrigger in the dependency array


    const updateInvitationCount = async () => {
        const result = await fetchInvitationsCount(orgId);
        if (result.success) {
            setInvitationCount(result.count as number);
        } else {
            // Handle the error scenario, maybe set some error state
            console.error("Failed to fetch invitation count");
        }
    };



    const fetchSubscriptionDetails = () => {
        setIsLoading(true); // Set loading to true when the component mounts
        // Assuming Firebase has been initialized elsewhere
        const functions = getFunctions(); // getFunctions(firebaseApp, 'australia-southeast1') if you have multiple apps
        functions.region = 'australia-southeast1'; // Set the region here
        const getSubscriptionDetails = httpsCallable(functions, 'getSubscriptionDetails');


        getSubscriptionDetails({ subscriptionId })
            .then((result) => {
                console.log(result.data)
                // Assuming result.data directly returns the structure you've shown
                const data = result.data as SubscriptionResponse;
                const firstItem = data.items[0];
                const planType = firstItem.plan.interval === 'month' ? 'Monthly' : 'Annually';
                const subscriptionCost = firstItem.plan.amount / 100; // Convert cents to dollars
                const currency = firstItem.plan.currency.toUpperCase();
                const nextPaymentDate = dayjs.unix(data.currentPeriodEnd).format("MMMM D, YYYY");
                const status = data.status;
                const now = new Date().getTime();
                const trialEnd = new Date(data.currentPeriodEnd * 1000).getTime();
                const tempDaysLeft = Math.ceil((trialEnd - now) / (24 * 60 * 60 * 1000));
                setDaysLeft(tempDaysLeft);

                setSubscriptionInfo({
                    planType: planType,
                    cancelAtTheEnd: data.cancelAtTheEnd,
                    status: status,
                    subscriptionCost: subscriptionCost,
                    currency: currency,
                    nextPaymentDate: nextPaymentDate,
                    currentPeriodStart: dayjs.unix(data.currentPeriodStart).format("MMMM D, YYYY"),
                    currentPeriodEnd: dayjs.unix(data.currentPeriodEnd).format("MMMM D, YYYY"),
                    totalSeats: (planType === "Monthly" && subscriptionCost < 300) || (planType === "Annually" && subscriptionCost < 3000) ? 3 : 10,
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching subscription details: ", error);
                setIsLoading(false);
            });
    };

    // Update the function to toggle the reload trigger
    const handleAlertDialogClose = () => {
        setShowAlertMsg(false);
    };


    const handleAlertDialogOpen = () => {
        setAlertMsg(`You have cancelled your subscription to Loop. By renewing your plan, your subscription will be reinstated. The next payment will be scheduled for ${(subscriptionInfo?.nextPaymentDate)} <br/> <br/> Do you wish to continue? `)
        setShowAlertMsg(true);
    };


    const handleManagePlanClick = () => {
        const encodedEmail = encodeURIComponent(adminEmail);
        // Assuming you have a way to generate the Stripe Customer Portal URL
        // const stripeCustomerPortalUrl = `https://billing.stripe.com/p/login/test_3cseWGbSv9u57TO3cc?prefilled_email=${encodedEmail}`;
        // window.location.href = stripeCustomerPortalUrl;
        //LIVE Customer Portal URL
        const stripeCustomerPortalUrl_live = `https://billing.stripe.com/p/login/8wMbKs2v5euEfCMfYY?prefilled_email=${encodedEmail}`;
        window.location.href = stripeCustomerPortalUrl_live;
    };


    const continueSubscription = async (subscriptionId: string) => {
        setIsLoadingRenew(true)
        const functions = getFunctions(); // getFunctions(firebaseApp, 'australia-southeast1') if you have multiple apps
        functions.region = 'australia-southeast1'; // Set the region here
        const continuePlan = httpsCallable(functions, 'continueSubscription');
        try {
            const result = await continuePlan({ subscriptionId });
            console.log('Result:', result.data);
            setIsLoadingRenew(false)
            setShowAlertMsg(false);
            setReloadTrigger(!reloadTrigger);
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error cancelling subscription:', error.message);
                setIsLoadingRenew(false)
                alert('Failed to cancel subscription. Please try again.');
            } else {
                console.error('Unknown error:', error);
                setIsLoadingRenew(false)
                alert('An unexpected error occurred.');
            }
        }
    };


    return (
        <div>
            {!isLoading && (
                <div className={styles.content}>
                    {subscriptionInfo && subscriptionInfo.status === 'trialing' && (
                        <div className={styles.trialBanner}>
                            <Stack direction={"row"}>
                                <Stack marginRight={pxToRem(10)}>
                                    <svg width="5" height="62" viewBox="0 0 5 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.499023" width="5" height="61" rx="2.5" fill="#294D47" />
                                    </svg>
                                </Stack>
                                <Stack>
                                    <svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.499023" width="61" height="61" rx="8" fill="white" />
                                        <path d="M32.3329 29.6656L19.6662 42.3322M33.6907 19.7169C35.3148 20.7945 36.8757 22.0649 38.3344 23.5236C39.8056 24.9948 41.0854 26.5701 42.1689 28.2092M27.3395 25.5271L23.5063 24.2494C23.0649 24.1022 22.5786 24.1959 22.2234 24.4964L18.4139 27.7199C17.634 28.3798 17.8555 29.6357 18.8142 29.9889L22.4238 31.3187M30.5742 39.4689L31.9041 43.0785C32.2573 44.0372 33.5132 44.2587 34.1731 43.4788L37.3965 39.6693C37.6971 39.3141 37.7907 38.8279 37.6436 38.3864L36.3659 34.5532M40.7976 18.0265L34.2557 19.1169C33.5493 19.2346 32.9013 19.5818 32.4121 20.1048L23.5947 29.5303C21.3091 31.9735 21.3727 35.7889 23.7384 38.1546C26.104 40.5202 29.9195 40.5838 32.3626 38.2983L41.7882 29.4809C42.3111 28.9916 42.6583 28.3437 42.7761 27.6373L43.8664 21.0953C44.1672 19.2905 42.6025 17.7257 40.7976 18.0265Z" stroke="#37665F" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Stack>
                                <Stack alignItems={"center"} justifyContent={"center"} marginLeft={pxToRem(20)}>
                                    <div className={styles.trialBannerInfo}>
                                        Your free trial will expire in {daysLeft} days
                                        {subscriptionInfo.cancelAtTheEnd ? (
                                            <div className={styles.trialBannerSubtext}>
                                                Your plan will be cancelled after the trial, renew your plan before {(subscriptionInfo?.nextPaymentDate)} to save your data.
                                            </div>

                                        ) : (
                                            <div className={styles.trialBannerSubtext}>
                                                After your trial ends, you will be charged {subscriptionInfo?.currency} ${subscriptionInfo?.subscriptionCost.toLocaleString()} per {subscriptionInfo?.planType === "Monthly" ? 'month' : 'year'} starting {(subscriptionInfo?.nextPaymentDate)}. You can always cancel before then.
                                            </div>
                                        )}
                                    </div>
                                </Stack>
                            </Stack>
                            {subscriptionInfo.cancelAtTheEnd && (billingPermission && billingPermission === 'EDITOR') && <Button className={styles.trialBannerButton} onClick={handleAlertDialogOpen}>Renew Your Plan</Button>}
                        </div>
                    )}
                    <Stack direction="column" spacing={3} className={styles.subscriptionContainer}>
                        <Stack className={styles.planStack}>
                            <Stack className={styles.planRow} direction={'row'}>
                                <Stack direction={'column'}>
                                    <Typography className={styles.inlineText}>Your Plan</Typography>
                                    <Typography className={styles.inlineResult}>{subscriptionInfo?.planType || 'Loading...'}</Typography>
                                </Stack>
                                {!subscriptionInfo?.cancelAtTheEnd && (billingPermission && billingPermission === 'EDITOR') && <Button className={styles.manageButton} onClick={() => setIsUpdatePlanOpen(true)}>
                                    Manage Plan
                                </Button>
                                }
                            </Stack>

                            <div className={`${styles.divider} horizontalDivider`}></div>
                            <Stack className={styles.planRow} direction={'row'}>
                                <Stack direction={'column'}>
                                    <Typography className={styles.inlineText}>Subscription</Typography>
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <Typography className={styles.inlineResult}>{subscriptionInfo?.currency} ${subscriptionInfo?.subscriptionCost.toLocaleString()} per {subscriptionInfo?.planType === "Monthly" ? 'month' : 'year'}</Typography>
                                        {subscriptionInfo && subscriptionInfo.cancelAtTheEnd ? (
                                            <Typography className={styles.inlineText}>({`Plan will be cancelled on ${(subscriptionInfo?.nextPaymentDate)}`})</Typography>
                                        ) : (
                                            <Typography className={styles.inlineText}>({`Next payment due ${(subscriptionInfo?.nextPaymentDate)}`})</Typography>
                                        )}
                                    </Stack>
                                </Stack>
                                {(billingPermission && billingPermission === 'EDITOR') &&
                                    <>
                                        {(subscriptionInfo && subscriptionInfo.status != 'trialing' && subscriptionInfo.cancelAtTheEnd) ? (
                                            <Button className={styles.manageButton} onClick={() => handleAlertDialogOpen()}>
                                                Renew Plan
                                            </Button>
                                        ) : (
                                            <Button className={styles.manageButton} onClick={() => handleManagePlanClick()}>
                                                Manage Payment
                                            </Button>
                                        )}
                                    </>
                                }
                            </Stack>

                            <div className={`${styles.divider} horizontalDivider`}></div>
                            <SeatsIndicator seats={subscriptionInfo?.totalSeats || 3} users={seatsUsed + invitationCount || 1} orgId={orgId} />
                        </Stack>
                    </Stack>
                </div>
            )}

            <LoadingComponent isLoading={isLoading}></LoadingComponent>
            <AlertDialogSimpleComponent message={alertMsg} visible={showAlertMsg} primaryButtonState={isLoadingRenew} primaryButtonTitle="Yes" secondaryButtonTitle="Close" primaryButtonClose={() => continueSubscription(subscriptionId)} secondaryButtonClose={() => handleAlertDialogClose()}></AlertDialogSimpleComponent>
            <ManagePlanPopup onStatusChange={handleStatusChange} onCancelState={handleCancelStatus} trialData={''} seatUsed={seatsUsed + invitationCount} orgId={orgId} subscriptionInfo={subscriptionInfo} subscriptionId={subscriptionId} open={isUpdatePlanOpen} onClose={() => { setIsUpdatePlanOpen(false); }} />
            {operationStatus === 'success' && (
                <SuccessUpdatePlanPopup open={true} isLoading={false} onClose={handleClose} title={' Your subscription has successfully been updated'} description={'Your payment has been processed. Click the button below to return to the billing page'} buttonText={'Close'} />
            )}
            {operationStatus === 'failed' && (
                <FailedUpdatePlanPopup open={true} isLoading={false} onClose={handleClose} onUpdatePaymentMethod={handleManagePlanClick} />
            )}
            {isCancelSuccess === 'success' && (
                <SuccessUpdatePlanPopup open={true} isLoading={false} onClose={handleCancelClose} title={' Your subscription has successfully been cancelled'} description={`You'll have access to Loop until the end of your current billing cycle. After that, you will be locked out from your data.`} buttonText={'Close'} />
            )}
        </div>
    );
}

export default SubscriptionComponent;