import { DocumentData, FieldValue, Timestamp } from "firebase/firestore";
import { URL_PARAMS } from "../routers/shared";

//--OLD
export enum PermissionState {
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
  NO_ACCESS = "NO_ACCESS"
}

export enum PermissionFeature {
  ALUMNI = 'alumni',
  BILLING = 'billing',
  PLANNER = 'planner',
  FORMS = 'forms',
  ENGAGEMENT = 'engagement',
  NETWORK = 'network',
  PHILANTHROPY = 'philanthropy'
}

// export type Permission = {
//   userId?: string;
//   feature: PermissionFeature;
//   state: PermissionState;
//   time?: number | FieldValue;
// };
export type Permission = {
  alumni: PermissionState;
  billing: PermissionState;
  planner: PermissionState;
  forms: PermissionState;
  engagement: PermissionState;
  network: PermissionState;
  philanthropy: PermissionState;
  isAdmin?: boolean;
  updatedAt?: number | FieldValue;
};

export const DEFAULT_PERMISSIONS:Permission = {
  alumni: PermissionState.EDITOR,
  billing: PermissionState.EDITOR,
  planner: PermissionState.EDITOR,
  forms: PermissionState.EDITOR,
  engagement: PermissionState.EDITOR,
  network: PermissionState.EDITOR,
  philanthropy: PermissionState.EDITOR,
  isAdmin: true
}

export const getPermission = (data: DocumentData): Permission => {
  return {
    alumni: data.alumni,
    billing: data.billing,
    planner: data.planner,
    forms: data.forms,
    engagement: data.engagement,
    network: data.network,
    philanthropy: data.philanthropy,
    isAdmin: data.isAdmin,
    updatedAt: data.updatedAt?(data.updatedAt as Timestamp).toMillis(): undefined
  }
}

export type Admin = {
  state: "CURRENT" | "REMOVED";
  lastAssigned?: {
    actionedBy: string | "DEFAULT"; //userUid // First admin's actionedBy attribute will be 'DEFAULT'
    timeStamp: number; // unix time
  };
  lastRemoved?: {
    actionedBy: string; //userUid
    timeStamp: number; // unix time
  };
  permissions?: {
    [auth: string]: Permission;
  };
};
export type User = {
    details: {
        position?: string;
        state: "APPROVED" | "PENDING" | "DELETED" | "REMOVED";
    };
    permissions?: {
        ALUMNI_DATABASE: Permission;
        PLANNER: Permission;
        BILLING_AND_PAYMENT: Permission;
        ENGAGEMENT: Permission;
        NETWORK: Permission;
        PHILANTHROPY: Permission;
    };
};
export type Organisations = {
  [organisationUid: string]: {
    details: {
      name: string;
      logo?: string;
    };
    houses?: {
      [houseUid: string]: {
        name: string;
      };
    };
    users?: {
      [userUid: string]: User;
    };
    admins?: {
      [userUid: string]: Admin;
    };
    state?: {
      value: "DELETED";
      time: number;
    };
  };
};
//--END OLD

export enum AccountType {
  ADMIN = "ADMIN",
  USER = "USER",
}

export type Campus = {
    id?:string;
    name: string;
    default: boolean;
    created_at?: FieldValue;
    updated_at?: FieldValue;
}
export const getCampus = (data: DocumentData): Campus => {
    return {
        id: data.id,
        name: data.name,
        default: data.default,
        created_at: data.created_at,
        updated_at: data.updated_at,
    };
};
export type House = {
    id?:string;

    name: string;
    created_at?: FieldValue;
    updated_at?: FieldValue;
}
export const getHouse = (data: DocumentData): House => {
    return {
        id: data.id,
        name: data.name,
        created_at: data.created_at,
        updated_at: data.updated_at,
    };
};

export type orgChecklist = {
  createOrg: boolean;
  inviteTeam: boolean;
  uploadLogo: boolean;
}

export type Organisation<FV = never> = {
    id?:string | FV;

    name?: string | FV;
    country?: string | FV;
    streetAddress?: string | FV;
    suburb?: string | FV;
    state?: string | FV;
    postCode?: string | FV;
    latitude?: number | FV;
    longitude?: number | FV;
    type?: string | FV;

    createdByUserId?: string | FV;
    users?: string[] | FV,

    status?: string | FV; //"ACTIVE" | "EXPIRED" | "TEMPDEL" | "PERMDEL" | "ACTIVETRIAL",
    lastSuccessfulCheckout?:  FieldValue | number;
    subscriptionId? : string | FV;
    adminEmail? : string | FV;

    photoUrl?:string | FV;
    photoUrlId?:string | FV;

    campus?: string[] | FieldValue,
    defaultCampus?: string | FV,
    house?: string[] | FieldValue,
    degree?:  string[] | FV,
    fieldOfStudy?:  string[] | FV,
    institution?:  string[] | FV,
    jobTitle?: string[] | FV,
    industry?: string[] | FV,
    networkTitleList?: string[] | FV,
    connectionRelationships?: string[] | FV,
    nameOfCompany?: string[] | FV,
    
    created_at?: FieldValue | number;
    updated_at?: FieldValue | number;

    totalAlumni?: number | undefined | FV;
    alumniLastUpdated?: FieldValue | number | undefined;

    totalNetwork?: number | undefined | FV;
    networkLastUpdated?: FieldValue | number | undefined;

    plannerUpdateAt?: FieldValue | number;
    plannerUpdateBy?: string | FV;

    engagementInvolvements?: string[] | FV,
    engagementTypes?: string[] | FV,

    expiredIn?: FieldValue | number | undefined;
    deleteRequestDate?: FieldValue | number | undefined;
    admins?: string[];
    trial_end?: FieldValue | number | undefined

    // Org Dash board checklist
    checklist?: orgChecklist;
}
export const getOrganisation = (data: DocumentData, ): Organisation => {
    return {
        name: data.name,
        country: data.country,
        streetAddress: data.streetAddress,
        suburb: data.suburb,
        state: data.state,
        postCode: data.postCode,
        latitude: data.latitude,
        longitude: data.longitude,
        type: data.type,

        createdByUserId: data.createdByUserId,
        users: data.users,

        status: data.status,
        lastSuccessfulCheckout: data.lastSuccessfulCheckout,
        subscriptionId : data.subscriptionId,
        adminEmail: data.adminEmail,

        photoUrl: data.photoUrl,
        photoUrlId: data.photoUrlId,

        campus: data.campus,
        defaultCampus: data.defaultCampus,
        house: data.house,
        degree:  data.degree,
        fieldOfStudy: data.fieldOfStudy,
        institution:  data.institution,
        jobTitle: data.jobTitle,
        industry: data.industry,
        nameOfCompany: data.nameOfCompany,
        networkTitleList: data.networkTitles,
        
        created_at: data.created_at?(data.created_at as Timestamp).toMillis():undefined,
        updated_at: data.updated_at?(data.updated_at as Timestamp).toMillis():undefined,

        totalAlumni: data.totalAlumni,
        alumniLastUpdated: data.alumniLastUpdated?(data.alumniLastUpdated as Timestamp).toMillis():undefined,

        totalNetwork: data.totalNetwork,
        networkLastUpdated: data.networkLastUpdated?(data.networkLastUpdated as Timestamp).toMillis():undefined,

        plannerUpdateBy: data.plannerUpdateBy,
        plannerUpdateAt: data.plannerUpdateAt?(data.plannerUpdateAt as Timestamp).toMillis():undefined,

        engagementInvolvements: data.engagementInvolvements,
        engagementTypes: data.engagementTypes,

        connectionRelationships: data.connectionRelationships,

        // permissions
        admins: data.admins,
        expiredIn: data.expiredIn?(data.expiredIn as Timestamp).toMillis():undefined,
        deleteRequestDate: data.deleteRequestDate?(data.deleteRequestDate as Timestamp).toMillis():undefined,
        trial_end: data.trial_end?(data.trial_end as Timestamp):undefined,

        checklist: data.checklist
    };
};

export type TagsEmployment = {
  industry: string[];
  jobTitle: string[];
  nameOfCompany: string[];
}
export const getTagsEmployment = (data: DocumentData):TagsEmployment => {
  return {
    industry: data.industry,
    jobTitle: data.jobTitle,
    nameOfCompany: data.nameOfCompany
  }
}
export type TagsPersonal= {
  interests: string[];
  skills: string[];
}
export const getTagsPersonal = (data: DocumentData):TagsPersonal => {
  return {
    interests: data.interests,
    skills: data.skills,
  }
}

export type OrgGlobalState = {
  teamNotifications: number;
}

export const getOrgGlobalState = (data: DocumentData): OrgGlobalState => {
  return {
    teamNotifications: data.globalState ? data.globalState.teamNotifications?data.globalState.teamNotifications:0 : 0
  }
}