import { memo } from 'react'
import { Chip, IconButton, Stack } from '@mui/material';
import styles from "./index.module.scss";
import InputTextField from '../Inputs/TextFields/InputTextField';
import useTextInput from '../../hooks/useTextInput';
import { getAlumniProfileError, getErrorMsgForInput } from '../../helpers/inputValidators';
import constants from '../../helpers/constants';
import pxToRem from '../../helpers/pxToRem';
import globalStyles from '../../styles/_stylesParams.module.scss'
type TagSelectorProps = { 
    tags: string[],
    setTags: (value: React.SetStateAction<string[]>) => void;
    usedInsideModal?: boolean;
    title?: string;
}

const TagInput = (tagSelectorProps: TagSelectorProps) => {
    const tag = useTextInput({ inputValidator: (input: string) => { return getAlumniProfileError(input, 2, 64) } });
    const handleChipDelete = (index: number) => {
        tagSelectorProps.setTags((prevTags) => {
            // Remove the chip at the specified index from the tags array
            const newTags = [...prevTags];
            newTags.splice(index, 1);
            return newTags;
        });
    };

    return (
        <Stack>
            <InputTextField 
                fontSize={constants.addAlumniFontSize} 
                customHeight={constants.addAlumniInputHeight} 
                valueCheck={tag.value} 
                containerStyle={{minHeight: pxToRem(constants.addAlumniInputHeight + 24)}} 
                label={tagSelectorProps.title} value={tag.value} 
                onChange={(e) => tag.setValue(e.target.value)} 
                error={tag.hasError && tag.hasTouched}
                helperText={tag.hasError ? <small>{tag.getErrorMessage()}</small> : undefined}
                isEndAdornment
                adornment={tag.value.length > 2  ? 
                    <label onClick={()=>{ 
                        if(tag.hasError && tag.hasTouched){return};
                        const trimmedValue = tag.value.trim().toLowerCase();
                        const tagExistsAlready = tagSelectorProps.tags.some(
                          (existingCampus) => existingCampus.trim().toLowerCase() === trimmedValue
                        );
                        if (!tag.hasError && !tagExistsAlready) {
                            tagSelectorProps.setTags((prevArray) => [...prevArray, tag.value]);
                            tag.reset();
                        }
                     }} 
                    style={{cursor: 'pointer'}}   className="regular-600-14">Add</label> : undefined}
                onKeyDown={(e) => {
                    const trimmedValue = tag.value.trim().toLowerCase();
                    const tagExistsAlready = tagSelectorProps.tags.some(
                      (existingCampus) => existingCampus.trim().toLowerCase() === trimmedValue
                    );
                
                    if (e.key === 'Enter' && !tag.hasError && !tagExistsAlready) {
                      tagSelectorProps.setTags((prevArray) => [...prevArray, tag.value]);
                      tag.reset();
                    }
                }}
            />
            <div>
                {tagSelectorProps.tags.map((tagName, index) => ( 
                    <Chip key={index} 
                        deleteIcon={
                        <IconButton style={{backgroundColor:globalStyles['neutrals300'], padding:pxToRem(0), marginLeft:pxToRem(1)}}>
                            <svg width={pxToRem(14)} height={pxToRem(14)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9 3L3 9M3 3L9 9" stroke="#525252" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                        </IconButton>} 
                        label={<label style={{color:globalStyles['neutrals0']}} className='regular-400-14'>{tagName}</label>} onDelete={() => handleChipDelete(index)} className={styles.chipStyle}
                    /> 
                ))}
            </div>
        </Stack>
    );
}

export default memo(TagInput);