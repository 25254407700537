import { addDoc, arrayUnion, collection, deleteDoc, deleteField, doc, FieldValue, getCountFromServer, getDoc, getDocs, increment, runTransaction, serverTimestamp, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from ".";
import { TJobTitleUpdateStatus } from "../pages/NetworkPage/tabs/EmploymentTab";
import { NETWORK_SUBCOLS } from "./helpers";
import { Res } from "./shared";
import { Connection, Education, Employment, ImportNetwork, Network, Note, Schooling } from "./types-network";

export const createNewNetwork = async (orgId: string, network: Network, nextFillInProfile?: boolean): Promise<Res<string>> => {
	let networkRefId = "";
	try {
		await runTransaction(db, async (transaction) => {
			const collRef = collection(db, "organisations", orgId, "network");
			const newNetworkDocRef = await addDoc(collRef, network);
			if (nextFillInProfile) { networkRefId = newNetworkDocRef.id }

			//Update Network Count
			const docNetwork = doc(db, "organisations", orgId);
			const docNetworkData = await transaction.get(docNetwork);
			let totalNetwork = 0;
			if (docNetworkData.exists() && docNetworkData.data()) totalNetwork = (docNetworkData.data().totalNetwork ?? 0) + 1;
			transaction.set(docNetwork, { totalNetwork: totalNetwork, networkLastUpdated: serverTimestamp() }, { merge: true });

			// Update networkTitle list
			if (network.networkTitle) {
				transaction.update(docNetwork, { networkTitles: arrayUnion(network.networkTitle) });
			}
		})

		return { code: 200, data: nextFillInProfile ? networkRefId : "New network created." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
};

export const updateNetwork = async (orgId: string, networkId: string, network: Network<FieldValue>): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(db, "organisations", orgId, "network", networkId);
		const orgDocRef = doc(db, "organisations", orgId);
		//const { created_at, ...updatedData } = network;
		batch.update(docRef, network);
		// Update networkTitle list in organization document if networkTitle is not null or empty
		if (network.networkTitle) {
			batch.update(orgDocRef, { networkTitles: arrayUnion(network.networkTitle),  networkLastUpdated: serverTimestamp() });
		}
		await batch.commit();
		return { code: 200, data: "Network updated successfully." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
}

export const updateNetworkPhoto = async (orgId: string, networkId: string, photoUrlId: string, photoUrl: string): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(db, "organisations", orgId, "network", networkId);
		const updatedData = { updated_at: serverTimestamp(), photoUrlId: photoUrlId, photoUrl: photoUrl };
		batch.update(docRef, updatedData);
		await batch.commit();
		return { code: 200, data: "Update photo successfully." };
	}
	catch {
		return { code: 500 };
	}
}

export const deleteNetwork = async (orgId: string, networkId: string) => {
	try {
		await deleteNetwork2(orgId, networkId, true);
		return { code: 200, data: "Network deleted." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
}

export const createNewEmployment = async (orgId: string, networkId: string, employment: Employment, currentEmploymentSnippet?: string | undefined,
	jobTitle?: string | undefined, nameOfCompany?: string | undefined, industry?: string | undefined
): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const collRef = collection(db, "organisations", orgId, "network", networkId, "employment");
		const docRef = doc(collRef);
		batch.set(docRef, employment);
		const orgDocRef = doc(db, 'organisations', orgId);
		if (currentEmploymentSnippet) { // FIXME: Need to think of new logic on how to handle this on while adding data to Algolia 
			const ref = doc(db, "organisations", orgId, "network", networkId);
			//batch.update(ref,{currentEmployment: {id:docRef.id, title: currentEmploymentSnippet}})
			batch.set(ref, { currentEmployment: arrayUnion({ id: docRef.id, jobTitle: currentEmploymentSnippet }) }, { merge: true });
		}
		if (jobTitle) {
			batch.update(orgDocRef, { jobTitle: arrayUnion(jobTitle), networkLastUpdated: serverTimestamp() });
		}
		if (nameOfCompany) {
			batch.update(orgDocRef, { nameOfCompany: arrayUnion(nameOfCompany), networkLastUpdated: serverTimestamp() });
		}
		if (industry) {
			batch.update(orgDocRef, { industry: arrayUnion(industry), networkLastUpdated: serverTimestamp() });
		}
		await batch.commit();
		return { code: 200, data: "New employment created." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
};
export const updateEmployment = async (orgId: string, networkId: string, employment: Employment<FieldValue>, employmentId: string,
	fetchedCurrentEmployment?: Array<{ id: string, jobTitle: string }>,
	currentEmploymentSnippet?: { jobTitleUpdateStatus: TJobTitleUpdateStatus, stillInRole: boolean, jobTitle: string | undefined },
	jobTitle?: string | undefined, nameOfCompany?: string | undefined, industry?: string | undefined): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(collection(db, "organisations", orgId, "network", networkId, "employment"), employmentId);
		const ref = doc(db, "organisations", orgId, "network", networkId);
		const orgDocRef = doc(db, 'organisations', orgId);
		batch.update(docRef, employment);
		if (currentEmploymentSnippet?.jobTitleUpdateStatus) {
			if (currentEmploymentSnippet?.jobTitleUpdateStatus === 'TitleChangedStillInRole') {
				if (fetchedCurrentEmployment) {
					const employmentIndex = fetchedCurrentEmployment.findIndex(emp => emp.id === employmentId);
					if (employmentIndex !== -1 && currentEmploymentSnippet.jobTitle) {
						fetchedCurrentEmployment[employmentIndex].jobTitle = currentEmploymentSnippet.jobTitle;
						batch.set(ref, { currentEmployment: fetchedCurrentEmployment }, { merge: true });
					} else { batch.set(ref, { currentEmployment: [...fetchedCurrentEmployment, { id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
				} else { batch.set(ref, { currentEmployment: [{ id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
			} else if (currentEmploymentSnippet.jobTitleUpdateStatus === 'StillInRoleAdded') {
				if (fetchedCurrentEmployment) { batch.set(ref, { currentEmployment: [...fetchedCurrentEmployment, { id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
				else { batch.set(ref, { currentEmployment: [{ id: employmentId, jobTitle: currentEmploymentSnippet.jobTitle }] }, { merge: true }); }
			} else if (currentEmploymentSnippet.jobTitleUpdateStatus === 'StillInRoleRemoved') {
				if (fetchedCurrentEmployment) {
					const employmentIndex = fetchedCurrentEmployment.findIndex(emp => emp.id === employmentId);
					if (employmentIndex !== -1) {
						fetchedCurrentEmployment.splice(employmentIndex, 1);
						batch.set(ref, { currentEmployment: fetchedCurrentEmployment }, { merge: true });
					}
				}
			}
		}
		if (jobTitle) {
			batch.update(orgDocRef, { jobTitle: arrayUnion(jobTitle),  networkLastUpdated: serverTimestamp() });
		}
		if (nameOfCompany) {
			batch.update(orgDocRef, { nameOfCompany: arrayUnion(nameOfCompany),  networkLastUpdated: serverTimestamp() });
		}
		if (industry) {
			batch.update(orgDocRef, { industry: arrayUnion(industry),  networkLastUpdated: serverTimestamp() });
		}
		await batch.commit();
		return { code: 200, data: "Employment updated." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
};

export const createNewEducation = async (orgId: string, networkId: string, education: Education<FieldValue>, currentEducationDegree: string, updateDegree?: string, updateFieldOfStudy?: string[], updateInstitution?: string): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(collection(db, "organisations", orgId, "network", networkId, "education"));
		const networkDocRef = doc(db, "organisations", orgId, "network", networkId);
		batch.set(docRef, education);
		const orgDocRef = doc(db, 'organisations', orgId);
		batch.set(networkDocRef, { educationDegree: arrayUnion({ id: docRef.id, degree: currentEducationDegree }) }, { merge: true });
		if (updateDegree) { batch.update(orgDocRef, { degree: arrayUnion(updateDegree),  networkLastUpdated: serverTimestamp() }) }
		if (updateFieldOfStudy) {
			updateFieldOfStudy.forEach(studyField => {
			  batch.update(orgDocRef, { fieldOfStudy: arrayUnion(studyField), networkLastUpdated: serverTimestamp() });
			});
		  }
		if (updateInstitution) { batch.update(orgDocRef, { institution: arrayUnion(updateInstitution), networkLastUpdated: serverTimestamp() }) }
		await batch.commit();
		return { code: 200, data: "New education created." };
	}
	catch (error) {
		console.log(error);
		return { code: 500 };
	}
};

export const updateEducation = async (orgId: string, networkId: string, education: Education<FieldValue>, educationId: string,
	fetchedEducationDegree?: Array<{ id: string, degree: string }>,
	currentEducationDegreeSnippet?: { degreeChangeStatus: 'updated' | 'unchanged', degree: string },
	updateDegree?: string, updateFieldOfStudy?: string[], updateInstitution?: string): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(collection(db, "organisations", orgId, "network", networkId, "education"), educationId);
		const networkDocRef = doc(db, "organisations", orgId, "network", networkId);
		const orgDocRef = doc(db, 'organisations', orgId);
		batch.update(docRef, education);
		if (fetchedEducationDegree && currentEducationDegreeSnippet?.degreeChangeStatus === 'updated') { // This is mandatory field so it will always be there
			const degreeIndex = fetchedEducationDegree.findIndex(educationDegree => educationDegree.id === educationId);
			if (degreeIndex !== -1 && currentEducationDegreeSnippet.degree) {
				fetchedEducationDegree[degreeIndex].degree = currentEducationDegreeSnippet.degree;
				batch.set(networkDocRef, { educationDegree: fetchedEducationDegree }, { merge: true });
			}
		}
		if (updateDegree) { batch.update(orgDocRef, { degree: arrayUnion(updateDegree),  networkLastUpdated: serverTimestamp() }) }
		if (updateFieldOfStudy) {
			updateFieldOfStudy.forEach(studyField => {
			  batch.update(orgDocRef, { fieldOfStudy: arrayUnion(studyField), networkLastUpdated: serverTimestamp() });
			});
		  }
		if (updateInstitution) { batch.update(orgDocRef, { institution: arrayUnion(updateInstitution), networkLastUpdated: serverTimestamp() }) }
		await batch.commit();
		return { code: 200, data: "Education updated." };
	}
	catch (error) {
		console.log("Errors: Update education.");
		console.log(error);
		return { code: 500 };
	}
};

export const createSkill = async (orgId: string, networkId: string, skills: string[]): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRefNetwork = doc(db, "organisations", orgId, "network", networkId);
		batch.set(docRefNetwork, { skills: arrayUnion(...skills) }, { merge: true });
		const docRefOrganisation = doc(db, "organisations", orgId, 'tags', 'personal');
		batch.set(docRefOrganisation, { skills: arrayUnion(...skills) }, { merge: true });
		await batch.commit();
		return { code: 200, data: "New skill created." };
	}
	catch (error) {
		console.log("Errors happened when trying to create new skill.", error);
		return { code: 500 };
	}
};

export const createPersonalInterest = async (orgId: string, networkId: string, interests: string[]): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRefNetwork = doc(db, "organisations", orgId, "network", networkId);
		batch.set(docRefNetwork, { personalInterests: arrayUnion(...interests) }, { merge: true });
		const docRefOrganisation = doc(db, "organisations", orgId, 'tags', 'personal');
		batch.set(docRefOrganisation, { interests: arrayUnion(...interests) }, { merge: true });
		await batch.commit();
		return { code: 200, data: "New interest created." };
	} catch (error) {
		console.log("Errors happened when trying to create new interest.", error);
		return { code: 500 };
	}
};

export const createNote = async (orgId: string, networkId: string, note: Note<FieldValue>): Promise<Res<string>> => {
	try {
		const batch = writeBatch(db);
		const docRef = doc(collection(db, "organisations", orgId, "network", networkId, "notes"));
		batch.set(docRef, note);
		await batch.commit();
		return { code: 200, data: "New note created." };
	}
	catch (error) {
		console.log("Errors happened when trying to create new note.", error);
		return { code: 500 };
	}
};

export const updateNote = async (orgId: string, networkId: string, noteId: string, note: Note<FieldValue>): Promise<Res<string>> => {
	try {
		const docRef = doc(db, "organisations", orgId, "network", networkId, "notes", noteId);
		await updateDoc(docRef, note)
		return { code: 200, data: "Note updated." };
	}
	catch (error) {
		console.log("Errors happened when trying to update note.", error);
		return { code: 500 };
	}
};

export const deleteNote = async (orgId: string, networkId: string, noteId: string): Promise<Res<string>> => {
	try {
		await deleteDoc(doc(db, "organisations", orgId, "network", networkId, "notes", noteId));
		return { code: 200, data: "Note updated." };
	}
	catch (error) {
		console.log("Errors happened when trying to update note.", error);
		return { code: 500 };
	}
};

export const getNetworkNameById = async (orgId: string, networkId: string): Promise<Res<string | null>> => {
	try {
		let networkName: string | null = null;
		const docRef = doc(db, "organisations", orgId, "network", networkId);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const networkData = docSnap.data();
			networkName = networkData.firstName + " " + networkData.lastName; // Assuming 'name' is the field for the network's name
		}
		return { code: 200, data: networkName };
	} catch (error) {
		console.error("Error getting network name:", error);
		return { code: 500, errorMsg: "An error occurred" };
	}
};


const batch = (arr: { a: Network, notes?: string, schooling?: Schooling }[], batch_size = 100) => {
	let chunk: { a: Network, notes?: string, schooling?: Schooling }[][] = [];
	while (arr.length > 0) {
		chunk.push(arr.splice(0, batch_size))
	}
	return chunk;
}

const batch2 = <T>(arr: Array<T>, batch_size = 100) => {
	let chunk: T[][] = [];
	while (arr.length > 0) {
		chunk.push(arr.splice(0, batch_size))
	}
	return chunk;
}

export const updateImportFiles = async (orgId: string, filename: string) => {
	try {
		// Update import file names
		const orgFileRef = doc(db, "organisations", orgId, "importFiles", filename);
		const snap = await getDoc(orgFileRef);
		if (snap.exists()) return { code: 400, errorMsg: `${filename} has already been uploaded.` };
		await setDoc(orgFileRef, {});
		return { code: 200 };
	}
	catch (error: any) {
		return { code: 500, errorMsg: error };
	}
}


export const importNetworkData = async (orgId: string, network: { a: Network, notes?: string, schooling?: Schooling }[], importDetails: ImportNetwork) => {
	try {
		const res = await resetRecentlyAdded(orgId);
		if (res.code !== 200) throw new Error("Failed to reset recently added");

		let networkIds: string[] = []; // To store all the newly added network IDs
		let successfulImports = 0; // Counter for successfully added network

		// Batch network to stay under transaction limit
		await runTransaction(db, async (transaction) => {
			const docNetwork = doc(db, "organisations", orgId);
			transaction.set(docNetwork, { totalNetwork: increment(network.length), networkLastUpdated: serverTimestamp() }, { merge: true });

			for (let item of network) {
				const orgNetworkRef = doc(collection(db, "organisations", orgId, "network"));
				networkIds.push(orgNetworkRef.id); // Collecting all newly created network IDs

				transaction.set(orgNetworkRef, {
					...item.a,
					orgId: orgId,
					created_at: serverTimestamp()
				});
				successfulImports++; // Incrementing for each successfully prepared network record

				if (item.notes) {
					const notesRef = doc(collection(db, "organisations", orgId, "network", orgNetworkRef.id, "notes"));
					transaction.set(notesRef, {
						title: "Imported Notes",
						note: item.notes,
						created_at: serverTimestamp(),
						updated_at: serverTimestamp(),
					});
				}

				if (item.schooling) {
					const schoolingRef = doc(collection(db, "organisations", orgId, "network", orgNetworkRef.id, "schooling"));
					transaction.set(schoolingRef, {
						...item.schooling,
						schoolOption: "CURRENT",
						networkId: orgNetworkRef.id,
						orgId: orgId,
						created_at: serverTimestamp(),
						updated_at: serverTimestamp()
					});
				}
			}
		});

		// Create a single ImportHistory record after all network have been added
		const historyRef = doc(collection(db, "organisations", orgId, "ImportHistory"));
		await setDoc(historyRef, {
			fileName: importDetails.fileName,
			date: serverTimestamp(),
			totalImported: successfulImports,
			totalRejected: importDetails.totalRejected,
			networkIds: networkIds,
			error: importDetails.error,
		});

		return { code: 200, totalSuccessImports: successfulImports };

	} catch (error) {
		const errorMsg = `Error occurred whilst importing network data: ${error}`;
		console.error(errorMsg);
		return { code: 500, errorMsg: errorMsg };
	}
}



export const resetRecentlyAdded = async (orgId: string) => {
	try {
		// Reset network recently added
		const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
		const snap = await getDocs(addedRef);
		for (let i of batch2(snap.docs)) {
			await runTransaction(db, async (t) => {
				i.forEach((document) => {
					const ref = doc(db, "organisations", orgId, 'recentlyAdded', document.id);
					t.delete(ref);
				})
			})
		}
		return { code: 200 }
	} catch (error) {
		const errorMsg = `Error occurred whilst resetting recetnly added: ${error}`;
		console.error(errorMsg);
		return { code: 500, errorMsg: errorMsg };
	}
}

export const revertImportNetwork = async (orgId: string, importId: string) => {
	const batch = writeBatch(db);

	try {
		const importRef = doc(db, "organisations", orgId, "ImportHistory", importId);
		const importDoc = await getDoc(importRef);

		if (!importDoc.exists()) {
			throw new Error("Import history record not found");
		}

		const networkList: string[] = importDoc.data()?.networkIds || [];
		const fileName: string = importDoc.data()?.fileName;
		let failedDeletions = 0;

		// Delete each network using the existing deleteNetwork2 function
		for (const userId of networkList) {
			try {
				const res = await deleteNetwork2(orgId, userId, false);
				if (res.code !== 200) {
					failedDeletions++;
					console.error(`Failed to delete network ID ${userId}`);
				}
			} catch (error) {
				failedDeletions++;
				console.error(`Failed to delete network ID ${userId}:`, error);
			}
		}

		// If fileName exists, delete the corresponding import file document
		if (fileName) {
			const fileRef = doc(db, "organisations", orgId, "importFiles", fileName);
			batch.delete(fileRef);
		}

		// Delete the ImportHistory document
		batch.delete(importRef);

		// Decrement the total network count in the organization by the number of successfully deleted network
		const totalNetworkToUpdate = networkList.length - failedDeletions;
		if (totalNetworkToUpdate > 0) {
			const orgRef = doc(db, "organisations", orgId);
			batch.update(orgRef, { totalNetwork: increment(-totalNetworkToUpdate), networkLastUpdated: serverTimestamp()});
		}

		// Commit the batch operation
		await batch.commit();
		return { code: 200, message: "Import successfully reverted." };
	} catch (error) {
		console.error("Failed to revert import:", error);
		return { code: 500 }; // Provide a more specific error message
	}
};


export const deleteRecentlyAdded = async (orgId: string) => {
	try {
		const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
		const snap = await getDocs(addedRef);
		const deletePromises = snap.docs.map(async (document) => {
			console.log(document.id);
			await deleteNetwork2(orgId, document.id);
			const ref = doc(db, "organisations", orgId, 'recentlyAdded', document.id);
			await deleteDoc(ref);
		});

		await Promise.all(deletePromises);

		// set 
		const docNetwork = doc(db, "organisations", orgId);
		await setDoc(docNetwork, { totalNetwork: increment(-snap.docs.length), networkLastUpdated: serverTimestamp() }, { merge: true });
		return { code: 200 }
	} catch (error: any) {
		return { code: 500, error: error }
	}
}

export const deleteNetwork2 = async (orgId: string, userId: string, changeTotal?: boolean) => {
	try {
		const batch = writeBatch(db);

		const subcolPromises = NETWORK_SUBCOLS.map(async (subcol) => {
			console.log(subcol);
			const colRef = collection(db, "organisations", orgId, "network", userId, subcol);
			const snap = await getDocs(colRef);
			if (snap.size === 0) return;

			console.log(snap.docs);

			snap.docs.forEach((doc) => {
				batch.delete(doc.ref);
			});
		});

		await Promise.all(subcolPromises);

		if (changeTotal) {
			//Update Network Count
			const docNetwork = doc(db, "organisations", orgId);
			batch.set(docNetwork, { totalNetwork: increment(-1), networkLastUpdated: serverTimestamp()}, { merge: true });
		}

		const networkRef = doc(db, "organisations", orgId, 'network', userId);
		batch.delete(networkRef);
		await batch.commit();

		return { code: 200 }
	} catch (error: any) {
		return { code: 500 }
	}
}

export const isRecentlyAdded = async (orgId: string): Promise<Res<number>> => {
	try {
		const addedRef = collection(db, "organisations", orgId, 'recentlyAdded');
		const count = await getCountFromServer(addedRef);
		return { code: 200, data: count.data().count }
	} catch (error) {
		return { code: 500 }
	}
}

/**
 * Updates the LinkedAlumnis list and engagement types in an engagement document.
 * Also ensures that each connection is bidirectional based on the type specified.
 * 
 * @param {string} orgId The organization ID.
 * @param {string} networkId The engagement ID.
 * @param {Array} connectionList Array of objects each containing `networkId`, `type`, and `relationship`.
 * @returns {Promise<Res<string>>} A promise resolving to the result of the operation.
 */
export async function updateConnections(
	orgId: string,
	networkId: string,
	connectionList: Array<{ objectID?: string, type: string, relationship: string }>
): Promise<Res<string>> {
	const networkRef = doc(db, "organisations", orgId, "network", networkId);
	const orgRef = doc(db, "organisations", orgId);

	try {
		const batch = writeBatch(db);
		const orgDoc = await getDoc(orgRef);
		let updatedConnectionRelationships = orgDoc.exists() ? orgDoc.data().connectionRelationships || [] : [];

		for (const connection of connectionList) {
			if (!updatedConnectionRelationships.includes(connection.relationship)) {
				updatedConnectionRelationships.push(connection.relationship);
			}

			const connectionRef = (connection.type === 'Network') ?
				doc(db, "organisations", orgId, "network", connection.objectID as string) :
				doc(db, "organisations", orgId, "alumni", connection.objectID as string);

			const connectionDoc = await getDoc(connectionRef);
			const existingConnections = connectionDoc.data()?.connectionList || [];
			let foundIndex = existingConnections.findIndex((conn: Connection) => conn.objectId === networkId);
			const reciprocalConnection = {
				objectId: networkId,
				type: 'Network', // Note: Ensure this type logic is correct for your use case
				relationship: connection.relationship
			};

			if (foundIndex !== -1) {
				// Update the existing connection if found
				existingConnections[foundIndex] = {
					...existingConnections[foundIndex],
					relationship: connection.relationship // Update to new relationship
				};
			} else {
				// Push new connection if not found
				existingConnections.push(reciprocalConnection);
			}

			batch.update(connectionRef, { connectionList: existingConnections });
		}

		batch.update(networkRef, {
			connectionList: connectionList.map(connection => ({
				objectId: connection.objectID,
				type: connection.type,
				relationship: connection.relationship
			}))
		});

		batch.update(orgRef, {
			connectionRelationships: updatedConnectionRelationships.sort(),
			networkLastUpdated: serverTimestamp()
		});

		await batch.commit();
		return { code: 200, data: "Connections updated successfully." };
	} catch (error) {
		console.error("Failed to update connections", error);
		return { code: 500, errorMsg: "Failed to update due to server error" };
	}
}

// Function to completely remove the photo URL field of an alumni in an organization
export const deleteNetworkPhotoUrl = async (orgId: string, networkId: string): Promise<string> => {

	// Reference to the Firestore document where the photo URL is stored
	const photoDocRef = doc(db, "organisations", orgId, "network", networkId);
  
	try {
		// Delete the photoUrl field entirely from the document
		await updateDoc(photoDocRef, {
			photoUrl: deleteField() // Use deleteField to remove the photoUrl field completely
		});
		console.log("Photo URL field successfully deleted from Firestore.");
  
		return "Photo URL field deleted successfully.";
	} catch (error) {
		console.error("Error deleting photo URL field:", error);
		throw new Error(`Failed to delete photo URL field: ${error instanceof Error ? error.message : error}`);
	}
  };
  
