import { HTMLProps, useEffect, useRef } from "react";

const IndeterminateCheckbox = ({
    indeterminate,
    labelClicked,
    className = '',
    id,
    ...rest
  }: { indeterminate?: boolean, labelClicked?: (e:any)=>void } & HTMLProps<HTMLInputElement>) => {
    const ref = useRef<HTMLInputElement>(null!)
  
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])
  
    return (
      <div className={className}>
        <input
          type="checkbox"
          id={id}
          ref={ref}
          className={'cursor-pointer'}
          {...rest}
        />
        <label htmlFor={id} onClick={labelClicked}></label>
      </div>
    )
  }

export default IndeterminateCheckbox;