import React, { useEffect, useState } from 'react';
import { Box, Stack, styled, Typography, linearProgressClasses, LinearProgress } from '@mui/material';
import pxToRem from '../../helpers/pxToRem';
import LogoPath from "../../assets/images/logo/logo512.png"

interface LoadingComponentProps {
  isLoading: boolean;
  loadingStr?: string;
}

const LoadingOverlay = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const ImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // Define the shining animation
  '@keyframes shine': {
    '0%': { filter: 'brightness(100%)' },
    '50%': { filter: 'brightness(90%)' }, // Increase brightness to 150%
    '100%': { filter: 'brightness(100%)' },
  },
  '& img': {
    animation: 'shine 0.8s ease-in-out infinite', // Apply the animation to the image
  },
}));

const LoadingBarContainer = styled('div')({
  marginTop: pxToRem(20), // Adjust based on your design needs
  width: '80%',
  backgroundColor: '#A3A3A3',
  borderRadius: pxToRem(10),
  display: 'flex',
  alignItems: 'center',
});

const LoadingCompleteOverlay = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledLoadingBar = styled(LinearProgress)({
  width: '100%',
  height: pxToRem(16),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
    borderRadius: pxToRem(10),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: pxToRem(10),
    backgroundColor: '#549189',
    boxShadow: '0px 4px 4px 0px #00000040', // Apply shadow here
  },
});

const capitalize = (str: string): string => {
  // Check if the string is not empty to avoid errors
  return str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

const ModalLoadingStatusBar: React.FC<LoadingComponentProps> = ({ isLoading, loadingStr }) => {
  const [currentSegment, setCurrentSegment] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [isLastSegment, setIsLastSegment] = useState(false); // New state to track if it's the last segment

  useEffect(() => {
    if (!isLoading) {
      setCurrentSegment('');
      setPercentage(0);
      setIsLastSegment(false); // Reset for the last segment
      return; // Exit early if not loading
    }

    if (loadingStr && isLoading) {
      const segments = loadingStr.split('-');
      const totalDuration = 100;

      // Adjust the logic based on the number of segments
      if (segments.length === 1) {
        // If there's only one item, set it as the current segment and fill the bar to 100%
        setCurrentSegment(segments[0]);
        setPercentage(totalDuration);
        setIsLastSegment(true);
      } else {
        // More than one item, proceed with the original logic
        const segmentDuration = totalDuration / (segments.length - 1);

        let index = 0;
        setCurrentSegment(segments[index]);
        setPercentage(segmentDuration);

        const updateStatus = () => {
          if (index < segments.length - 1) {
            let timeoutDuration = 1000; // Default duration for all but the second-to-last segment

            if (index === segments.length - 3) { // If it's the second-to-last segment
              timeoutDuration = 500; // Make it show faster
            }

            setTimeout(() => {
              if (index < segments.length - 2) {
                index += 1;
                setCurrentSegment(segments[index]);
                setPercentage((index + 1) * segmentDuration);
                updateStatus();
              } else {
                index += 1;
                setCurrentSegment(segments[segments.length - 1]);
                setIsLastSegment(true); // Indicate we are at the last segment
              }
            }, timeoutDuration);
          }
        };

        // Initiate the first update
        updateStatus();
      }

      return () => {
        setCurrentSegment(''); // Reset current segment on cleanup
        setIsLastSegment(false); // Reset last segment flag
      };
    }
  }, [loadingStr, isLoading]);

  if (!isLoading) return null;

  return (
    <>
      {isLastSegment ? (
        <LoadingCompleteOverlay>
          <Box sx={{
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center',
           height: '50%',
           width: "100%",
           backgroundColor: "#447D75",
           borderTopLeftRadius: pxToRem(8),
           borderTopRightRadius: pxToRem(8),
          }}>
                    <ImageContainer sx={{
                        position: 'absolute',
                        top: '50%', // Centers the top of the ImageContainer at the middle of LoadingCompleteOverlay
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Properly centers the ImageContainer
                    }}>
              <svg width={pxToRem(172)} height={pxToRem(172)} viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="87" cy="87" r="87" fill="#549189" />
                <circle opacity="0.6" cx="86.9992" cy="86.9992" r="57.5538" fill="#76A8A1" />
                <path d="M108.713 70.7168L78.8576 100.572L65.2871 87.0014" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </ImageContainer>
          </Box>
          <h5 style={{ marginTop: pxToRem(120), color: "#525252", fontWeight: 600 }}>{`Task Successfully ${capitalize(currentSegment)}!`}</h5>
        </LoadingCompleteOverlay >
      ) : (
        <LoadingOverlay>
          <ImageContainer>
            <img src={LogoPath} alt="Loading" style={{ maxHeight: pxToRem(172), height: pxToRem(172) }} />
          </ImageContainer>
          <LoadingBarContainer>
            <StyledLoadingBar variant="determinate" value={percentage} />
          </LoadingBarContainer>
          <h6 style={{ marginTop: pxToRem(20), color: "#A3A3A3", textAlign: 'center' }}>
            {currentSegment}
          </h6>
        </LoadingOverlay>
      )}
    </>
  );
};

export default ModalLoadingStatusBar;
