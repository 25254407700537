import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';

interface AutoCloseSnackbarProps {
    open: boolean;        // Controlled by parent component
    message: string;
    duration?: number;    // Duration in milliseconds
    onClose: () => void;  // Callback to inform parent when the Snackbar closes
}

const AutoCloseSnackbar: React.FC<AutoCloseSnackbarProps> = ({
    open,
    message,
    duration = 2000,
    onClose
}) => {
    // Automatically close the Snackbar after the specified duration
    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                onClose();  // Call parent's onClose to update state
            }, duration);

            return () => clearTimeout(timer);  // Cleanup timer
        }
    }, [open, duration, onClose]);  // Only re-run if these values change

    return (
        <Snackbar
            open={open}
            message={message}
            autoHideDuration={duration}
            onClose={onClose}  // Use onClose to handle manual close as well
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
    );
};

export default AutoCloseSnackbar;
