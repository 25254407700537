import {useEffect, useState} from 'react';
import { getCookiesNotice, getPrivacyPolicy, getTermsConditions } from '../firebase/landingApis';

const usePublicDocuments = () => {
    const [isShowing, setIsShowing] = useState(false);

    const [termsLink, setTermsLink] = useState<string>();
    const [privacyLink, setPrivacyLink] = useState<string>();
    const [cookiesLink, setCookiesLink] = useState<string>();

    const fetchLinks = async () => {
        const tlink = await getTermsConditions();
        const plink = await getPrivacyPolicy();
        const clink = await getCookiesNotice();
        setTermsLink(tlink);
        setPrivacyLink(plink);
        setCookiesLink(clink);
    }


    useEffect(() => {
        fetchLinks();
    }, [])

    return [
        termsLink, privacyLink, cookiesLink
    ];
}

export default usePublicDocuments;