import { Backdrop, Button, CircularProgress, Divider, IconButton, Snackbar, Stack } from "@mui/material";
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { DocumentData, DocumentSnapshot, collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../../../components/Buttons/ButtonUI";
import { db } from "../../../../firebase";
import { approveAlumni, checkDuplicate, rejectAlumni, restoreAlumni } from "../../../../firebase/registrationApis";
import { FormResponse, getResponse } from "../../../../firebase/types-registration";
import pxToRem from "../../../../helpers/pxToRem";
import { sleep } from "../../../../helpers/utils";
import { RootState } from "../../../../redux";
import { setIsChanged, setSingleAlumni } from "../../../../redux/formsSlice";
import { URL_PARAMS } from "../../../../routers/shared";
import { ResponseData, formatName } from "../../helpers";
import styles from "../../index.module.scss";
import CustomModal from "../modals/CustomModal";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import RespondentsSkeleton from "./skeleton/RespondentsSkeleton";
import { FORMS_PAGINATION_PERPAGEROWS } from "../../constants";
import { PermissionState } from "../../../../firebase/types-organisations";

interface IResponseTable {
    columns: ColumnDef<any>[];
    rejected: boolean;
    styleClass?: string;
    handleLastAlumni?: (alumni: {id: string, name?: string}[]) => void;
}

const ResponseTable = ({columns, rejected, styleClass, handleLastAlumni}: IResponseTable) => {
    const params = useParams();
    const {orgId, formId} = params;
    const dispatch = useDispatch();
    const [responseDataRows, setResponseDataRows] = useState<ResponseData[]>([]);
    const [responseDataRowsLoading, setResponseDataRowsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState<DocumentSnapshot<DocumentData> | null>(null);
    const formsPermission = useSelector((state:RootState) => state.globalState.permissions?.forms)

    const formState = useSelector((state:RootState) => state.formsState);

    const [totalRows, setTotalRows] = useState(0);
    const [rowSelection, setRowSelection] = useState({});

    const [approvedUserName, setApprovedUserName] = useState<string>();
    const [approvedUserId, setApprovedUserId] = useState<string>();
    const [approvedTotal, setApprovedTotal] = useState<number>()

    const [isLoading, setIsLoading] = useState(false);

    const table = useReactTable({
        initialState: { pagination: { pageIndex: 0, pageSize: FORMS_PAGINATION_PERPAGEROWS }},
        data: responseDataRows, 
        columns: columns, 
        getCoreRowModel: getCoreRowModel(), 
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            rowSelection
        },
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    });

    const fetchData = async (lastDocument:DocumentSnapshot<DocumentData> | null = null, callback:(code:number)=>void = () =>{}) => {
        if(orgId && formId){
            let qry;
            setResponseDataRowsLoading(true);
            const collectionRef = collection(db, "registrations", orgId, "forms", formId, "responses");
            const countQuery = query(collectionRef, where("rejected", "==", rejected));
            const snapshot = await getCountFromServer(countQuery);
            setTotalRows(snapshot.data().count);
            if(!lastDocument){
                setResponseDataRows([]);
                qry = query(collectionRef, orderBy("submittedAt", "desc"), where("rejected", "==", rejected), limit(FORMS_PAGINATION_PERPAGEROWS)); //TODO
            }
            else{
                qry = query(collectionRef, orderBy("submittedAt", "desc"), where("rejected", "==", rejected), startAfter(lastDocument), limit(FORMS_PAGINATION_PERPAGEROWS));
            }
            
            getDocs(qry).then((querySnapshot) => {
                querySnapshot.docs.map(async (doc) => {
                    const responseId = doc.id;
                    const response: FormResponse = getResponse(doc.data());

                    const responseTable: ResponseData = {
                        id: responseId, 
                        name: {
                            firstName: response.firstName || "",
                            lastName: response.lastName || "",
                            title: response.title,
                            pronouns: response.pronouns,
                            email: response.email || "",
                            contactNumber: response.contactNumber,
                            linkedIn: response.linkedIn,
                        },
                        gender: response.gender || "-",
                        schooling: response.campus || response.schoolHouse ? `${response.campus || '-'} , ${response.schoolHouse || '-'}` : '-',
                        peerYear: response.peerYear ? response.peerYear.toString() : "-",
                        enrolled: response.yearEnrolled ? response.yearEnrolled.toString() : "-",
                        interests: (response.personalInterests && response.personalInterests.length > 0) ? response.personalInterests.join(", ") : "-",
                        skills: (response.skills && response.skills.length > 0) ? response.skills.join(", ") : "-"
                    };
                    //Add into table
                    setResponseDataRows(prev => [...prev, responseTable]);
                });                
                setResponseDataRowsLoading(false);

                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                callback(200);
            })
            .catch((error) => {
                console.error("Error fetching documents: ", error);
                callback(400);
                setResponseDataRowsLoading(false);
            });
        }
    }
    useEffect(() => { 
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, formState.isChanged]);

    // const [duplicates, setDuplicates] = useState<{id: string, name: string}[]>([]);

    const handleApprove = async () => {
        setIsLoading(true);
        const userIds = table.getSelectedRowModel().rows.map(row => {
            return row.original.id;
        })
        if(orgId && formId) {
            // Disable duplicate checking 
            // const duplicateToRejects: {id:string, name:string}[] = [];
            // const toApprove: string[] = [];
            // for(const userId of userIds) {
            //     const res = await checkDuplicate(orgId,formId,userId);
            //     if(res.code === 200 && res.data.result) {
            //         duplicateToRejects.push({id:userId, name: res.data.name || ""});
            //         // // Remove from add pile
            //         // const index = userIds.indexOf(userId);
            //         // if(index > -1) {
            //         //     userIds.splice(index, 1);
            //         // }
            //     } else {
            //         toApprove.push(userId);
            //     }
            // }
            // console.log(duplicateToRejects);
            // setDuplicates(dups => {
            //     const newArr = dups.concat(duplicateToRejects);
            //     return newArr;
            // });

            // Approve 
            if(userIds.length > 0) {
                const res = await approveAlumni(orgId, formId, userIds);
                if(res.code === 200) {
                    // Remove rows from list
                    const list = responseDataRows.filter(row => userIds.includes(row.id) === false)
                    setResponseDataRows(list)
                    setRowSelection({});
                    // Trigger banner
                    if(userIds.length > 1) {
                        if(list.length <= 0 && handleLastAlumni) handleLastAlumni(userIds.map(id=>{return {id:id}}))
                        else setApprovedTotal(userIds.length);
                    } else {
                        if(list.length <= 0 && handleLastAlumni) handleLastAlumni([{id: res.data.id, name: res.data.name}])
                        else {
                            setApprovedUserId(res.data.id);
                            setApprovedUserName(res.data.name);
                        }
                    }
                    setOpenAlert(true);
                }
            }
            dispatch(setIsChanged()); 
        }
        setIsLoading(false);
    }

    // const handleDuplicateReject = async () => {
    //     const nextDuplicate = duplicates[0];
    //     setDuplicates( dups =>  {
    //         if(dups && dups.length>0) {
    //             dups.shift();
    //             return dups;
    //         }
    //         return [];
    //     });
    //     console.log("PRESSED REJECT");
    //     console.log(orgId, formId, nextDuplicate);
    //     if(orgId && formId && nextDuplicate) {
    //         const res = await rejectAlumni(orgId, formId, [nextDuplicate.id]);
    //         if(res.code===200) {
    //             const list = responseDataRows.filter(row => row.id !== nextDuplicate.id);
                
    //             setResponseDataRows(list);
    //             dispatch(setIsChanged());
    //         }
    //     }
    // }

    // const handleDuplicateAdd = async () => {
    //     const nextDuplicate = duplicates[0];
    //     setDuplicates( dups =>  {
    //         if(dups && dups.length>0) {
    //             dups.shift();
    //             return dups;
    //         }
    //         return [];
    //     });
    //     console.log("PRESSED APPROVE");
    //     console.log(orgId, formId, nextDuplicate);
    //     if(orgId && formId && nextDuplicate) {
    //         const res = await approveAlumni(orgId, formId, [nextDuplicate.id]);
    //         if(res.code===200) {
    //             const list = responseDataRows.filter(row => row.id !== nextDuplicate.id);
                
    //             setResponseDataRows(list);
    //             dispatch(setSingleAlumni(nextDuplicate))
    //             dispatch(setIsChanged());
    //         }
    //     }
    // }

    const handleReject = async () => {
        setIsLoading(true);
        const userIds = table.getSelectedRowModel().rows.map(row => {
            return row.original.id
        })
        if(orgId && formId) {
            const res = await rejectAlumni(orgId, formId, userIds);
            if(res.code === 200) {
                const list = responseDataRows.filter(row => userIds.includes(row.id) === false)
                setResponseDataRows(list)
                setRowSelection({});
                console.log("updating table!!")
                dispatch(setIsChanged());
            }            
        }
        setIsLoading(false);
    }

    const handleRestore = async () => {
        setIsLoading(true);
        const userIds = table.getSelectedRowModel().rows.map(row => {
            return row.original.id
        })
        if(orgId && formId) {
            const res = await restoreAlumni(orgId, formId, userIds);
            if(res.code === 200) {
                const list = responseDataRows.filter(row => userIds.includes(row.id) === false)
                console.log(list)
                setResponseDataRows(list)
                setRowSelection({});
                dispatch(setIsChanged());
            }
        }
        setIsLoading(false);
    }

    

    const CustomPaginationFooter = () => {
        const currentIndex = table.getState().pagination.pageIndex + 1;
        const pageSize = table.getState().pagination.pageSize;
        const currentRows = table.getRowModel().rows;
    
        const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
        const endItem = startItem + currentRows.length - 1;
    
        const goToLastPage = () => {
            const totalRows = responseDataRows.length;
            const pageSize = FORMS_PAGINATION_PERPAGEROWS;
            const lastPageIndex = Math.max(0, Math.ceil(totalRows / pageSize));
            table.setPageIndex(lastPageIndex);
        };

        const checkFetchOrNot = () => {
            const localRowCount = responseDataRows.length;
            const localMaxIndex = Math.ceil(localRowCount / pageSize)
            return (localRowCount < totalRows && currentIndex >= localMaxIndex);
        }
        
        const nextPage = () => {
            if(checkFetchOrNot()){
                if(!responseDataRowsLoading && lastDoc){
                    fetchData(lastDoc, async(code) => { 
                        await sleep(10);
                        if(code === 200){ goToLastPage(); }
                    });
                }
            }
            else{
                if(table.getCanNextPage()){
                    table.nextPage();
                }
            }
        }
        
        return(
            <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
                <Stack direction={"row"} style={{ padding: 4 , alignItems: 'center' }}>
                    <Stack className={styles.datagrid_footer_text} alignItems={"center"}>  { `${startItem} - ${endItem} of ${totalRows}` } </Stack>
                    <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg> </IconButton>
                    <IconButton disabled={false} onClick={() => nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </svg></IconButton>
                </Stack>
            </Stack>
        );
    }

    const [openAlert, setOpenAlert] = useState(false);

    const resetAlert = () => {
        setOpenAlert(false);
        setApprovedTotal(undefined);
        setApprovedUserId(undefined);
        setApprovedUserName(undefined);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical:"top", horizontal:"center"}}
                open={openAlert}
                onClose={resetAlert}
                autoHideDuration={5000}
                message={
                    approvedTotal && approvedTotal>0 ? 
                        `${approvedTotal} New Alumni have been added to Loop’s database`
                    : 
                        `${approvedUserName} has successfully been added onto Loop’s database`
                    }
                action={(
                    approvedTotal && approvedTotal > 0 ?
                        <Link to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}`} target={"_blank"}>
                            <Button style={{color:styles.shades0}}>View all Alumni</Button>
                        </Link>
                    :
                        <Link to={`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.ALUMNI}/${approvedUserId}`} target={"_blank"}>
                            <Button style={{color: styles.shades0}}>View Profile</Button>
                        </Link>
                )}
                ContentProps={{
                    classes: {
                        root: styles.approveBanner
                    }
                }}
            />
            <Backdrop
                sx={{ color: "#ffffffa8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress style={{'color':styles.primary400}}/>
            </Backdrop>
            {
                formsPermission && formsPermission === PermissionState.EDITOR ?
                    rejected ?
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} className={styles.restoreBtn}>
                            <SecondaryButton className={styles.pendingApproveBtn} onClick={handleRestore} disabled={Object.keys(rowSelection).length <= 0}
                                sx={{
                                    "&.MuiButtonBase-root": {
                                        fontSize: pxToRem(14),
                                        fontWeight: '400',
                                        lineHeight: pxToRem(20),
                                    }
                                }}
                            >Restore</SecondaryButton>
                        </Stack>
                    :
                        <Stack className={styles.restoreBtn} direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={pxToRem(12)}>
                            <Button onClick={handleReject} className={styles.rejectBtn} disabled={Object.keys(rowSelection).length <= 0}>Reject</Button>
                            <SecondaryButton className={styles.pendingApproveBtn} onClick={handleApprove} disabled={Object.keys(rowSelection).length <= 0}
                                sx={{
                                    "&.MuiButtonBase-root": {
                                        fontSize: pxToRem(14),
                                        fontWeight: '400',
                                        lineHeight: pxToRem(20),
                                    }
                                }}
                            >Approve ({Object.keys(rowSelection).length})</SecondaryButton>
                        </Stack>
                : null
            }
            <Stack spacing={4} marginTop={pxToRem(12)} className={`${styles.tableWrapper} ${styleClass && styles[styleClass]}`}>
                <table className={`${styles.table} ${styles.responseTable}`}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => 
                                    <th key={header.id} style={{width:header.getSize(), textAlign:'left'}} >
                                        <Stack style={{justifyContent:'space-between'}} direction={"row"}>
                                            {
                                                header.id !== 'select'?
                                                    <Stack direction={"row"} style={{alignItems:'center', flexGrow:1, cursor:'pointer'}}>
                                                        { header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() )}
                                                    </Stack>
                                                :
                                                    <Stack style={{alignItems:'center', justifyContent:'center', flexGrow:1, cursor:'pointer'}}>
                                                        { header.isPlaceholder ? null : flexRender( header.column.columnDef.header, header.getContext() )}
                                                    </Stack>
                                            }        
                                        </Stack>
                                    </th>
                                )}
                            </tr> 
                        ))}
                    </thead>
                    <tbody>
                        {
                            responseDataRowsLoading
                            ?
                                <RespondentsSkeleton />
                            :
                                table.getRowModel().rows.length > 0 ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Fragment key={row.id}>
                                                <tr key={row.id}className={row.getIsSelected() ? styles.selectedRow : ''}>
                                                    {row.getVisibleCells().map(cell => (
                                                        <td key={cell.id}>
                                                            <Stack style={{justifyContent:'space-between'}} direction={"row"}> 
                                                                {
                                                                    cell.column.id !== 'select' ?
                                                                        <Stack direction={"row"} className={(cell.column.id !== 'name' ? styles.responseOtherCols : styles.respondentDetails)} style={{alignItems:'center', flexGrow:1, cursor:'pointer'}}>
                                                                            { flexRender(cell.column.columnDef.cell, cell.getContext()) }
                                                                        </Stack>
                                                                    :
                                                                        <Stack style={{alignItems:'center', justifyContent:'center', flexGrow:1, cursor:'pointer'}}>
                                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                        </Stack>
                                                                }
                                                            </Stack>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                :
                                    <tr className={styles.trskeleton}>
                                        <td style={{width:'50%'}}>
                                            <small className={styles['regular-400-14']} style={{color:styles.neutrals400, lineHeight:pxToRem(20)}}>
                                                {
                                                    rejected?
                                                        "Rejected respondents will appear here"
                                                    :
                                                        "Respondents will appear here"
                                                }
                                            </small>
                                        </td>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                    </tr>
                        }
                    </tbody>
                </table>
            </Stack>
            { !responseDataRowsLoading && responseDataRows.length <= 0 ? null : CustomPaginationFooter()}

            {/* Duplicate alumni modal
            {
                duplicates.length > 0 &&
                    <CustomModal
                        open={duplicates.length > 0}
                        handleClose={()=>{}}
                        className={styles.operationModal}
                        contentStyle={`${styles['insetBanner--green']} ${styles['operationModalWrapper']}`}
                    >
                        <Stack>
                            <h5>Duplicate Alumni</h5>
                            <small>This alumni may already exist on Loop.</small>
                            <Divider style={{marginTop:pxToRem(24), marginBottom:pxToRem(24)}} />
                            <small> 
                                A “<strong>{duplicates[0].name || ""}</strong>” with the same email address already exists on loop’s platform. 
                                Do you wish to add this alumni to the database anyway?
                            </small>
                            <Divider style={{marginTop:pxToRem(24), marginBottom:pxToRem(24)}} />
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} style={{width:'100%'}}>
                                <SecondaryButton id="duplicateApprove" onClick={handleDuplicateReject}  className={styles.cancelBtn}>No, Reject Alumni</SecondaryButton>
                                <PrimaryButton id="duplicateDeny" onClick={handleDuplicateAdd} className={styles.approveBtn}>Yes, Add Alumni</PrimaryButton>
                            </Stack>
                        </Stack>
                    </CustomModal>
            } */}
        </>
    );
}

export default ResponseTable;