import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import pxToRem from "../../../helpers/pxToRem";
import { stringToColor } from "./colorHelper";
import { AlumniTable } from "../../AlumniPage";
import { joinStringWithComma, joinStringWithSpace } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

interface generateAlumniProps {
  setAlumniIds?: string[];
}

// Component to display a grid of alumni
function AlumniGrid({ setAlumniIds }: generateAlumniProps) {
  const alumniData = useSelector((state: RootState) => state.alumniDataState.alumniList);
  const [selectedAlumni, setSelectedAlumni] = useState<AlumniTable[]>([]);
  const [showAllSelected, setShowAllSelected] = useState(false);

  const toggleShowAll = () => setShowAllSelected(!showAllSelected);

  // Inside your component:
  useEffect(() => {
    if (setAlumniIds) {
      const preSelectedAlumni = alumniData.filter(alumni =>
        setAlumniIds.includes(alumni.objectID as string)
      );
      setSelectedAlumni(preSelectedAlumni);
    }
  }, [setAlumniIds]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {(showAllSelected ? selectedAlumni : selectedAlumni.slice(0, 6)).map((alumni, index) => (
          <Grid item key={alumni.objectID} sx={{ position: 'relative', width: pxToRem(69), height: pxToRem(87), display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ position: 'relative', width: pxToRem(48), height: pxToRem(48) }}>
              {alumni.photoUrl ? (
                <Avatar alt={alumni.name} src={alumni.photoUrl} sx={{ width: '100%', height: '100%' }} />

              ) : (
                <Avatar alt={alumni.name} sx={{ width: '100%', height: '100%', bgcolor: stringToColor(alumni.firstName as string) }} >{(alumni.firstName as string).charAt(0) + (alumni.lastName as string).charAt(0)}</Avatar>

              )
              }
            </Box>
            <Typography variant="caption" sx={{ fontSize: pxToRem(12), maxWidth: pxToRem(60), mt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {alumni.name}
            </Typography>
          </Grid>
        ))}

        {selectedAlumni.length > 6 && (
          <Grid item>
            <Box onClick={toggleShowAll}>
              <svg width={pxToRem(48)} height={pxToRem(48)} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F5F5F5" />
                <circle cx="12" cy="20" r="2" fill="#737373" />
                <circle cx="20" cy="20" r="2" fill="#737373" />
                <circle cx="28" cy="20" r="2" fill="#737373" />
              </svg>


            </Box>
            <Typography variant="caption" sx={{ fontSize: pxToRem(12), maxWidth: pxToRem(60), mt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {showAllSelected ? "See Less" : "See More"}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AlumniGrid;
