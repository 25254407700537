import { Box, IconButton, Stack } from "@mui/material";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from 'dayjs';
import { deleteField, FieldValue, serverTimestamp } from "firebase/firestore";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AlertComponent from "../../../components/AlertComponent";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import CustomizedModal from "../../../components/CustomizedModal";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import Line from "../../../components/Line/Line";
import AutoCloseSnackbar from "../../../components/SnackBarComponent/autoCloseSnackBar";
import { createUrl, deleteUrl, fetchUrlsForAlumni, updateUrl } from "../../../firebase/mediaApis";
import { Url } from "../../../firebase/type-media";
import { PermissionState } from "../../../firebase/types-organisations";
import constants from "../../../helpers/constants";
import { getAlumniProfileError, getErrorMsgForUrl } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import styles from "./index.module.scss";

interface IUrlTabInterface {
    orgId: string | undefined | null;
    orgName: string | undefined | null;
    alumniId: string | undefined | null;
}


interface UrlEntry {
    id: string;
    title: string;
    url: string;
    titleError?: string;
    urlError?: string;
}



const UrlTab = ({ orgId, alumniId }: IUrlTabInterface) => {
    const [isUrlModal, setIsUrlModal] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [menuShowId, setMenuShowId] = useState(-1);
    const menuList = [{ label: "Edit", val: 0 }, { label: "Delete", val: 1 }];
    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); };
    const menuClose = () => { setAnchorEl(null); }
    const [errorMsg, setErrorMsg] = useState("");
    const [openCopyBar, setOpenCopyBar] = useState(false);
    const [urlEntries, setUrlEntries] = useState<UrlEntry[]>([]);

    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);

    const title = useTextInput({ inputValidator: (input) => getAlumniProfileError(input, 2, 100) });
    const url = useTextInput({ inputValidator: (input) => getErrorMsgForUrl(input, true, 'URL') });
    const [currentEditId, setCurrentEditId] = useState<string | null>(null)
    const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null)
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteLoadingStatus, setDeleteLoadingStatus] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false)

    const [urlsList, setUrlsList] = useState<Url[]>([]);

    useEffect(() => {
        if (orgId && alumniId) {
            fetchUrlsForAlumni(orgId, alumniId)
                .then(fetchedUrls => {
                    setUrlsList(fetchedUrls);
                })
                .catch(error => {
                    console.error("Failed to load URLs:", error);
                });
        }
    }, [orgId, alumniId, triggerUpdate]);

    useEffect(() => {
        // Check if the modal is open and there are no entries, then add a default entry
        if (isUrlModal && urlEntries.length === 0) {
            handleAddUrl();
        }
    }, [isUrlModal]);


    const columnHelper = createColumnHelper<Url>();
    const resetForm = () => {
        title.setValue(""); title.setHasTouched(false);
        url.setValue(""); url.setHasTouched(false);
        setUrlEntries([]);
        setCurrentDeleteId(null);
        setCurrentEditId(null);
        setMenuShowId(-1);
    }

    const formatDate = (timestamp: number) => {
        // Check if the timestamp is in seconds (usually a 10-digit number)
        const date = timestamp.toString().length === 10
            ? dayjs.unix(timestamp) // Convert from seconds to date
            : dayjs(timestamp); // Assume milliseconds if not in seconds

        const today = dayjs();

        return date.isSame(today, 'day')
            ? 'Today'
            : date.format('D MMM YY');
    };


    const addData = async () => {
        if (!orgId || !alumniId) { return; }

        setLoadingStatus(true);
        setLoadingStatusStr("Saving..");


        let allEntriesValid = true;

        // Updated entries to trigger validations before API call
        const updatedEntries = urlEntries.map(entry => {
            const titleError = getAlumniProfileError(entry.title, 2, 100);
            const urlError = getErrorMsgForUrl(entry.url, false,'URL');
            
            if (titleError || urlError) {
                allEntriesValid = false; // Mark as invalid if there are any errors
            }
    
            return {
                ...entry,
                titleError: titleError,
                urlError: urlError
            };
        });
    
        setUrlEntries(updatedEntries);
    
        if (!allEntriesValid) {
            setLoadingStatus(false);
            setLoadingStatusStr("");
            return; // Stop the function if any entry is invalid
        }

        for (let entry of urlEntries) {
            const urlData = {
                title: entry.title.trim(),
                url: entry.url.trim(),
                created_at: serverTimestamp(),
                updated_at: serverTimestamp(),
            };

            const res = await createUrl(orgId, alumniId, urlData);
            if (res.code === 500) {
                setErrorMsg("Errors: URL creation failed for " + entry.title);
                setLoadingStatus(false);
                setLoadingStatusStr("");
                return;  // Stop further processing on error
            }
        }

        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsUrlModal(false);
        setTriggerUpdate(prev => !prev);
        resetForm();  // Assuming this resets the entire form or relevant state
    };

    const onEdit = (item: Url) => {
        if (!item.title || !item.id) { return }
        setCurrentEditId(item.id);
        title.setValue(item.title);
        url.setValue(item.url ?? "");
        setMenuShowId(0);
        setIsUrlModal(true);
    }

    const getUrlTitleById = (urlId: string | null): string | null => {
        const foundUrl = urlsList.find(url => url.id === urlId);
        return foundUrl ? foundUrl.title || null : null;
    };


    const updateData = async () => {
        if (!orgId || !alumniId || !currentEditId) { return; }
        const mandatoryInputs = [title];
        let validated = true;
        mandatoryInputs.map((input) => input.setHasTouched(true));
        mandatoryInputs.map((input) => { if (!validated) return; if (input.hasError) validated = false; });
        if (!validated) { return; }
        let optionalValidated = true;
        const optionalInput = [url];
        optionalInput.map((optionalInput) => { if (!optionalValidated) return; if (optionalInput.hasError && optionalInput.value.trim() !== "") optionalValidated = false; });
        if (!optionalValidated) { return; }
        setLoadingStatus(true);
        setLoadingStatusStr("Updating..");
        const urlData: Url<FieldValue> = {
            ...(title.value && { title: title.value.trim() }),
            ...(url.value === "" ? { url: deleteField() } : { url: url.value.trim() }),
            updated_at: serverTimestamp()
        }
        const res = await updateUrl(orgId, alumniId, currentEditId, urlData);
        if (res.code === 500) { setErrorMsg("Errors: url update."); }
        setCurrentEditId(null);
        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsUrlModal(false);
        setTriggerUpdate(prev => !prev)
        setMenuShowId(-1);
        resetForm();
    }

    const deleteData = async () => {
        if (!orgId || !alumniId || !currentDeleteId) { return }
        setDeleteLoadingStatus(true);
        try { await deleteUrl(orgId, alumniId, currentDeleteId) }
        catch (ex) { setErrorMsg("Errors: url delete, please try again"); console.log(ex); }
        setDeleteLoadingStatus(false);
        setIsDeleteModal(false);
        setCurrentDeleteId(null);
        setMenuShowId(-1);
        setTriggerUpdate(prev => !prev)
        resetForm();
    }

    const columns = [
        columnHelper.accessor('title', { cell: info => info.getValue(), header: "Name" }),
        columnHelper.accessor('url', { cell: info => info.getValue(), header: "Copy Link" }),
        columnHelper.accessor('created_at', { cell: info => info.getValue(), header: "Added" }),
        columnHelper.accessor('id', { cell: info => info.getValue(), header: "" }),

    ];

    const viewerColumns = [
        columnHelper.accessor('title', { cell: info => info.getValue(), header: "Name" }),
        columnHelper.accessor('url', { cell: info => info.getValue(), header: "Copy Link" }),
        columnHelper.accessor('created_at', { cell: info => info.getValue(), header: "Added" }),
    ];


    const table = useReactTable({
        data: urlsList,
        columns: (alumniPermission && alumniPermission === PermissionState.EDITOR)?  columns: viewerColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const handleLinkClick = (e: any, val: any) => {
        e.stopPropagation();
        navigator.clipboard.writeText(val);
        setOpenCopyBar(true);
    }

    const handleAddUrl = () => {
        const newEntry: UrlEntry = { id: ` id - ${urlEntries.length} `, title: '', url: '' };
        setUrlEntries([...urlEntries, newEntry]);
        // Optional: Scroll to the bottom of the list if needed
        setTimeout(() => {
            document.getElementById(newEntry.id)?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const isValidUrl = (url: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    const handleUpdateEntry = (id: string, field: 'title' | 'url', value: string) => {
        const updatedEntries = urlEntries.map(entry => {
            if (entry.id === id) {
                const isError = field === 'url' ? !isValidUrl(value) : value.trim() === '';
                const errorMessage = field === 'url' ? 'Invalid URL format' : 'Title cannot be empty';
                return {
                    ...entry,
                    [field]: value,
                    [`${field}Error`]: isError ? errorMessage : ''
                };
            }
            return entry;
        });
        setUrlEntries(updatedEntries);
    };

    const handleDeleteEntry = (id: string) => {
        const updatedEntries = urlEntries.filter(entry => entry.id !== id);
        setUrlEntries(updatedEntries);
    };

    return (
        <>
            <AlertComponent visible={errorMsg !== ""} handleClose={() => setErrorMsg("")} title={"Alert"} message={errorMsg} />
            <AutoCloseSnackbar
                open={openCopyBar}
                message="Copy to Clipboard"
                onClose={() => setOpenCopyBar(false)}
                duration={2000}  // Optional
            />
            <CustomizedModal width={660} open={isUrlModal} handleClose={() => { }} isLoading={loadingStatus} loadingStr={loadingStatusStr}>
                <Stack>
                    <Stack width={"100%"} alignItems={"end"}>
                        <IconButton onClick={() => { setIsUrlModal(false); resetForm(); }}> <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' > <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' /> </svg> </IconButton>
                    </Stack>
                    <Stack direction={"row"} spacing={1.5} className={styles.media_details_modalHeaderTitleWrapper}>
                        <div style={{ flexGrow: 1 }}>
                            <label className={styles.media_details_modalHeaderTitle}> {(currentEditId !== null) ? "Edit URL" : "Add New URL"} </label>
                        </div>
                    </Stack>
                    <Stack spacing={2} style={{maxHeight: pxToRem(450), overflowY: urlEntries.length < 3 ? 'hidden' : 'auto'}}>                        
                        {(currentEditId === null) ? (
                            <>
                                {urlEntries.map((entry, index) => (
                                    <Box key={entry.id} id={entry.id}>
                                        <div className={`${styles.divider} horizontalDivider`}></div>
                                        <Stack spacing={pxToRem(12)}>
                                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                <small>Title</small>
                                                {index !== 0 && (
                                                    <IconButton onClick={() => handleDeleteEntry(entry.id)} style={{ padding: 0 }}>
                                                        <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 24' fill='none' >
                                                            <path d='M18 6L6 18M6 6L18 18' stroke='#1F1F1F' strokeLinecap='round' strokeLinejoin='round' />
                                                        </svg>
                                                    </IconButton>
                                                )}
                                            </Stack>
                                            <InputTextField
                                                type={"text"}
                                                shrinkLabelWidth={33}
                                                fontSize={constants.addAlumniFontSize}
                                                customHeight={constants.addAlumniInputHeight}
                                                value={entry.title}
                                                containerStyle={{ flex: 1 }}
                                                valueCheck={entry.title}
                                                label='Title*'
                                                onChange={(e) => handleUpdateEntry(entry.id, 'title', e.target.value)}
                                                error={!!entry.titleError}
                                                helperText={entry.titleError}
                                            />
                                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={pxToRem(12)}>
                                                <small>https://</small>
                                                <InputTextField
                                                    type={"text"}
                                                    shrinkLabelWidth={33}
                                                    fontSize={constants.addAlumniFontSize}
                                                    customHeight={constants.addAlumniInputHeight}
                                                    valueCheck={entry.url}
                                                    value={entry.url}
                                                    containerStyle={{ flex: 1 }}
                                                    label='URL*'
                                                    onChange={(e) => handleUpdateEntry(entry.id, 'url', e.target.value)}
                                                    error={!!entry.urlError}
                                                    helperText={entry.urlError}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                ))}

                            </>
                        ) : (
                            <>
                                <div className={`${styles.divider} horizontalDivider`} style={{ marginTop: pxToRem(24), marginBottom: pxToRem(12) }}></div>
                                <small>Title</small>
                                <InputTextField type={"text"} shrinkLabelWidth={33} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={title.value} containerStyle={{ flex: 1 }} label='Title*' value={title.value} onChange={(e) => title.setValue(e.target.value)} error={title.hasError && title.hasTouched} helperText={<small>{title.getErrorMessage()}</small>} />
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={pxToRem(12)}>
                                    <small>https://</small><InputTextField type={"text"} shrinkLabelWidth={33} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={url.value} containerStyle={{ flex: 1 }} label='URL*' value={url.value} onChange={(e) => url.setValue(e.target.value)} error={url.hasError && url.hasTouched} helperText={<small>{url.getErrorMessage()}</small>} />
                                </Stack>


                            </>
                        )}
                    </Stack>
                    {!(currentEditId) &&
                        <Stack className={styles.dataImportSection}>
                            <Line width={pxToRem(235)} height={1} lineColor="#447D75" />
                            <IconButton className={styles.customIconButton} onClick={() => { handleAddUrl() }}>
                            <small className={styles.addAnotherText} style={{ color: "#447D75" }}>Add Another</small>
                            </IconButton>
                            <Line width={pxToRem(235)} height={pxToRem(1)} lineColor="#447D75" />
                        </Stack>}
                    <div className={`${styles.divider} horizontalDivider`}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        {(currentEditId) ?
                            <WarningButton className={styles.button} onClick={() => { setIsUrlModal(false); setIsDeleteModal(true); setCurrentDeleteId(currentEditId) }}> Delete </WarningButton>
                            :
                            <SecondaryButton className={styles.button} onClick={() => { setIsUrlModal(false); resetForm(); }}>Cancel</SecondaryButton>
                        }
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} onClick={() => {
                                if (menuShowId === -1) {
                                    addData();
                                } else if (menuShowId === 0) {
                                    updateData();
                                }
                            }}>Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <CustomizedModal open={isDeleteModal} handleClose={() => { }} isLoading={deleteLoadingStatus} loadingStr={"Removing..."} width={600}>
                <Stack>
                    <Stack style={{ marginTop: pxToRem(24), marginBottom: pxToRem(24) }} spacing={3} alignItems={'center'}>
                        <label className={styles.alertBodyText}>{`Are you sure you want to delete ${getUrlTitleById(currentDeleteId)}?`}</label>

                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{ marginBottom: 20 }}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setDeleteLoadingStatus(false); setIsDeleteModal(false); setCurrentDeleteId(null); }}> Cancel </SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}> <PrimaryButton className={styles.button} onClick={deleteData}> Yes </PrimaryButton> </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} paddingBottom={pxToRem(24)}>
                {(alumniPermission && alumniPermission === "EDITOR") ? (<PrimaryButton className={styles.button} onClick={() => setIsUrlModal(true)}>Add New URL</PrimaryButton>) : <h6 style={{ flexGrow: 1 }} className='light'>URL Table</h6>}
                <Stack className={styles.numberBox} direction={'column'} spacing={1}>
                    <small>{urlsList.length}</small>
                    <small>Links</small>
                </Stack>
            </Stack>

            <Stack>
                <table className={styles.media_table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }} >
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                            {(header.column.id === 'created_at') &&
                                                <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                    <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                    <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                </Stack>
                                            }
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            // status === "loading" || status === "stalled"?
                            //     <RowSkeleton />
                            // :
                            table.getRowModel().rows.map(row => (
                                <tr key={row.id} onClick={() => { if (alumniPermission && alumniPermission === PermissionState.EDITOR) onEdit(row.original) }}>
                                    {
                                        row.getVisibleCells().map(cell => {
                                            let cellJSX: JSX.Element | undefined = undefined;
                                            if (cell.column.id === "title") {
                                                cellJSX = <Stack minWidth={pxToRem(400)} key={cell.id} className={styles.alumni}>{row.original.title}</Stack>;
                                            }
                                            else if (cell.column.id === "url") {
                                                cellJSX = <Stack key={cell.id} alignItems={'start'}>
                                                    <IconButton onClick={(e) => handleLinkClick(e, row.original.url)}>
                                                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </IconButton>
                                                </Stack>;
                                            }
                                            else if (cell.column.id === "created_at") {
                                                cellJSX = <Stack key={cell.id} className={styles.text}>
                                                    {row.original.created_at ? formatDate(row.original.created_at as number) : ""}
                                                </Stack>;
                                            }
                                            else if (cell.column.id === "id" && (alumniPermission && alumniPermission === PermissionState.EDITOR)) {
                                                cellJSX = <Stack key={cell.id} alignItems={'center'} onClick={(e) => e.stopPropagation()}>
                                                    <IconButton disabled={!(alumniPermission && alumniPermission === PermissionState.EDITOR)} onClick={() => { if (row.original.id) { setIsDeleteModal(true); setCurrentDeleteId(row.original.id) } }} >
                                                        <svg style={{ cursor: 'pointer' }} width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.5 5.25V4.35C15.5 3.08988 15.5 2.45982 15.2548 1.97852C15.039 1.55516 14.6948 1.21095 14.2715 0.995235C13.7902 0.75 13.1601 0.75 11.9 0.75H10.1C8.83988 0.75 8.20982 0.75 7.72852 0.995235C7.30516 1.21095 6.96095 1.55516 6.74524 1.97852C6.5 2.45982 6.5 3.08988 6.5 4.35V5.25M8.75 11.4375V17.0625M13.25 11.4375V17.0625M0.875 5.25H21.125M18.875 5.25V17.85C18.875 19.7402 18.875 20.6853 18.5071 21.4072C18.1836 22.0423 17.6673 22.5586 17.0322 22.8821C16.3103 23.25 15.3652 23.25 13.475 23.25H8.525C6.63482 23.25 5.68973 23.25 4.96778 22.8821C4.33274 22.5586 3.81643 22.0423 3.49285 21.4072C3.125 20.6853 3.125 19.7402 3.125 17.85V5.25" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                                    </IconButton>
                                                </Stack>;
                                            }
                                            return (<td key={cell.id}> {cellJSX} </td>)

                                        })
                                    }
                                    {/* </Link> */}
                                </tr>
                            ))
                        }
                        {urlsList.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'start', padding: '20px', height: pxToRem(52), color: '#A3A3A3' }}>

                                    URLS of your media references will appear here

                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Stack>
        </>
    );
}
export default memo(UrlTab);
