import { Autocomplete, styled, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import pxToRem from '../../helpers/pxToRem';

interface TableAutoCompleteProps {
    defaultValue: string;
    options: string[];
    onValueChange: (newValue: string) => void;  // API call function to handle updates
}

const StyledTextField = styled(TextField)({
    "& .MuiInput-underline:after": {
        borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent",
            borderRadius: pxToRem(8),
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
        "& .MuiOutlinedInput-input": {
            backgroundColor: '#F5F5F5',
            padding: '0 12px',
            fontSize: pxToRem(14),
            fontFamily: 'Open Sans',
            fontWeight: 400,
            lineHeight: pxToRem(20),
            letterSpacing: '-0.02em',
            textAlign: 'left',
            height: 'auto'  // Ensures input height adjusts to content
        },
        height: pxToRem(32),
        minWidth: pxToRem(120),
        padding: '12px',  // Remove padding to align text
        backgroundColor: '#F5F5F5',
        borderRadius: pxToRem(8),
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
        "& .MuiAutocomplete-input:": {
            paddingLeft: '12px',  // Remove padding to align text]
            paddingRight: '12px',  // Remove padding to align text
        },
        padding: '0px'  // Removing any default padding set by MUI
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiAutocomplete-endAdornment": {
        right: '0px',  // Ensures that the end adornment icons are aligned to the right edge
    }
});

// export const TableAutoComplete: React.FC<TableAutoCompleteProps> = ({ defaultValue, options, onValueChange }) => {
//     const [inputValue, setInputValue] = useState(defaultValue);
//     const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

//     const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
//         if (timer) {
//             clearTimeout(timer);
//         }
//         setInputValue(newInputValue);
//         setTimer(setTimeout(() => {
//             onValueChange(newInputValue);
//         }, 1000));
//     };

//     useEffect(() => {
//         return () => {
//             if (timer) {
//                 clearTimeout(timer);
//             }
//         };
//     }, [timer]);

//     return (
//         <Autocomplete
//             freeSolo
//             value={inputValue}
//             onInputChange={handleInputChange}  // This triggers on text input changes
//             options={options}
//             renderInput={(params) => (
//                 <StyledTextField
//                     {...params}
//                     variant="outlined"
//                     fullWidth
//                 />
//             )}
//             forcePopupIcon={true}  // Force showing the dropdown arrow
//         />
//     );
// };

// export default TableAutoComplete;

export const TableAutoComplete: React.FC<TableAutoCompleteProps> = ({ defaultValue, options, onValueChange }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [error, setError] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const validateInput = (value: string): boolean => {
        const normalizedValue = value.trim();
        if (normalizedValue === '-') {
            setError(null);
            return true;
        }
        if (normalizedValue === '') {
            setError('Input cannot be empty');
            return false;
        }
        if (normalizedValue.length < 3) {
            setError('Input must be longer than 2 characters');
            return false;
        }
        if (/[^a-zA-Z0-9 \-]/.test(normalizedValue)) {
            setError('Input must not contain special characters');
            return false;
        }
        setError(null);
        return true;
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
        if (newValue !== null && validateInput(newValue)) {
            onValueChange(newValue);
            event.preventDefault();
            inputRef.current?.blur();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
        setInputValue(newInputValue);
        validateInput(newInputValue);
        if (newInputValue === defaultValue) {
            return;
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            if (validateInput(newInputValue)) {
                onValueChange(newInputValue);
                event.preventDefault();
                inputRef.current?.blur();
            }
        }, 2000); // Trigger onValueChange after 2 seconds of inactivity
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (validateInput(inputValue)) {
                onValueChange(inputValue);
                event.preventDefault();
                inputRef.current?.blur();
            }
        }
    };

    const handleBlur = () => {
        if (validateInput(inputValue)) {
            onValueChange(inputValue);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <Autocomplete
            freeSolo
            fullWidth
            value={inputValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onBlur={handleBlur}
            options={options}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    error={!!error}
                    helperText={error}
                    variant="outlined"
                    fullWidth
                    onKeyPress={handleKeyPress}
                    inputRef={inputRef}
                />
            )}
            forcePopupIcon={true}
        />
    );
};

export default TableAutoComplete;