
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PermissionState } from "../firebase/types-organisations";
import { RootState } from "../redux";
import { setCurrentOrgDetail } from "../redux/organisationSlice";
import { URL_PARAMS } from "../routers/shared";



interface IPermissionRoute {
    children: React.ReactNode;
    fallback: React.ReactNode;
}

const PermissionRoute = ({ children, fallback }: IPermissionRoute) => {
    const location = useLocation();
    const navigate = useNavigate();
    const userPermissions = useSelector((state: RootState) => state.globalState.permissions);
    const isAdmin = useSelector((state: RootState) => state.globalState.isAdmin);
    const organisationList = useSelector((state: RootState) => state.organisation.organisationList);
    const organisation = useSelector((state: RootState) => state.globalState.organisation);
    const { orgId } = useParams();
    // const orgId = useSelector((state:RootState) => state.organisation.orgId);
    const dispatch = useDispatch();

    const navigateHome = () => navigate(`/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}`);
    const navigateDash = () => navigate(`/${URL_PARAMS.DASHBOARD}`);

    useEffect(() => {
        if (!userPermissions) return;
        if (isAdmin === undefined) return;
        // console.log("permission route 1.3")
        const params = location.pathname.split("/")
        for (const p of params) {
            switch (p) {
                case 'alumni':
                    if (userPermissions.alumni === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break;
                case 'billing':
                    if (userPermissions.billing === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break;
                case 'planner':
                    if (userPermissions.planner === PermissionState.NO_ACCESS) {
                        console.log("Going home");
                        navigateHome();
                        return;
                    }
                    break;
                case 'forms':
                    if (userPermissions.forms === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break;
                case 'engagement':
                    if (userPermissions.engagement === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break;
                case 'network':
                    if (userPermissions.network === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break;
                case 'philanthropy':
                    if (userPermissions.philanthropy === PermissionState.NO_ACCESS) {
                        navigateHome();
                        return;
                    }
                    break
                case 'organisation-settings':
                    if (!isAdmin) {
                        navigateHome();
                        return;
                    }
                    break;
            }
        }
    }, [location.pathname, orgId, userPermissions, isAdmin])

    useEffect(() => {
        if (!organisation) return;
        if (organisation.status === "EXPIRED") {
            navigateDash();
        }
    }, [organisation, organisationList])

    const loading = () => {
        return !userPermissions && !(new RegExp(`${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ACCOUNT_SETTINGS}`)).test(location.pathname);
    }

    useEffect(() => {
        if (!orgId) return;
        dispatch(setCurrentOrgDetail({
            orgId: orgId,
            organisation: organisationList?.find((org) => org.id === orgId)
        }))
    }, [orgId, organisationList])

    return (
        <>{loading() ? fallback : children}</>
    )
}

export default PermissionRoute;