import React, { useState } from 'react';
import styles from './index.module.scss';
import { Button, Divider, Stack, Typography } from '@mui/material';
import pxToRem from '../../../../helpers/pxToRem';
import PaymentPlanPopup from '../paymentPlan/PaymentPlan';
import { calculateDaysLeftByEndDate } from '../../../../components/FreeTrialPopup';
import { useNavigate } from 'react-router-dom';
import { URL_PARAMS } from '../../../../routers/shared';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';

type TrialBannerProps = {
	isShown: boolean;
	endTime: number; // Assuming startTime is a timestamp
	orgId: string;
	orgState: string;
	userId: string;
};

const TrialBanner: React.FC<TrialBannerProps> = ({ endTime, orgId, orgState, userId, isShown }) => {


	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const daysLeft = calculateDaysLeftByEndDate(endTime);
	const navigate = useNavigate();
	const billingPermission = useSelector((state: RootState) => state.globalState.permissions?.billing);


	const handleClosePopup = () => {
		setIsPopupVisible(false);
	};

	if (!isShown) {
		return null;
	}
	return (
		<>
			<div className={styles.trialBanner}>
				<Stack direction={"row"}>
					<Stack marginRight={pxToRem(10)}>
						<svg width="5" height="62" viewBox="0 0 5 62" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect y="0.499023" width="5" height="61" rx="2.5" fill="#294D47" />
						</svg>
					</Stack>
					<Stack>
						<svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect y="0.499023" width="61" height="61" rx="8" fill="white" />
							<path d="M32.3329 29.6656L19.6662 42.3322M33.6907 19.7169C35.3148 20.7945 36.8757 22.0649 38.3344 23.5236C39.8056 24.9948 41.0854 26.5701 42.1689 28.2092M27.3395 25.5271L23.5063 24.2494C23.0649 24.1022 22.5786 24.1959 22.2234 24.4964L18.4139 27.7199C17.634 28.3798 17.8555 29.6357 18.8142 29.9889L22.4238 31.3187M30.5742 39.4689L31.9041 43.0785C32.2573 44.0372 33.5132 44.2587 34.1731 43.4788L37.3965 39.6693C37.6971 39.3141 37.7907 38.8279 37.6436 38.3864L36.3659 34.5532M40.7976 18.0265L34.2557 19.1169C33.5493 19.2346 32.9013 19.5818 32.4121 20.1048L23.5947 29.5303C21.3091 31.9735 21.3727 35.7889 23.7384 38.1546C26.104 40.5202 29.9195 40.5838 32.3626 38.2983L41.7882 29.4809C42.3111 28.9916 42.6583 28.3437 42.7761 27.6373L43.8664 21.0953C44.1672 19.2905 42.6025 17.7257 40.7976 18.0265Z" stroke="#37665F" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</Stack>
					<Stack alignItems={"center"} justifyContent={"center"} marginLeft={pxToRem(20)}>
						<div className={styles.trialBannerInfo}>
							Your free trial will expire in {daysLeft} days
							{daysLeft <= 14 ? <div className={styles.trialBannerSubtext}>
								Subscribe to a plan now to avoid being locked out from your data
							</div>
								:

								<div className={styles.trialBannerSubtext}>
									Enjoy your free trial. Feel free to take a look at our subscription options.
								</div>
							}
						</div>
					</Stack>
				</Stack>
				{(billingPermission && billingPermission === 'EDITOR') &&
					<>
						{daysLeft > 14 ? (
							<Button className={styles.trialBannerButton} onClick={() => window.open(`/${URL_PARAMS.PRICING}`, '_blank')}>Explore Plans</Button>
						) : (
							<Button className={styles.trialBannerButton} onClick={() => { setIsPopupVisible(true); }}>Select Payment Plan</Button>
						)
						}
					</>
				}
			</div>
			<div className={styles.planBanner}>
				<div className={styles.planTitle}>
					Your Plan
				</div>
				<Stack className={styles.planText} direction={'row'}>
					<div style={{ marginRight: pxToRem(15) }}>
						<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="4" cy="4" r="4" fill="#16A34A" />
						</svg>
					</div>
					<div>
						{`Active Trial ( ${daysLeft} days until trial expires)`}
					</div>
				</Stack>
				{daysLeft <= 14 ? <div className={styles.planGrayText}> Upgrade your plan today to avoid being locked out from your data</div>
				: 
				<div className={styles.planGrayText}> Enjoy your free trial. You will be able to choose a plan within 14 days before the free trial ends.</div>
			}
			</div>

			<PaymentPlanPopup orgId={orgId} userId={userId} onClose={handleClosePopup} open={isPopupVisible} dayLeft={daysLeft} />
		</>


	);
};

export default TrialBanner;
