import styles from "./index.module.scss";

interface ICustomizedPaper {
  className?: string;
  children: JSX.Element | string;
  cancelHoriontalPadding?: boolean;
  cancelVerticalPadding?: boolean;
  cancelBoxShadow?: boolean;
}

const CustomizedPaper = ({
  className,
  children,
  cancelBoxShadow,
  cancelHoriontalPadding,
  cancelVerticalPadding,
}: ICustomizedPaper) => (
  <div
    className={`${className} ${styles.paper} ${
      cancelHoriontalPadding ? "" : styles.horizontalPadding
    } ${cancelVerticalPadding ? "" : styles.verticalPadding}
    ${cancelBoxShadow ? "" : styles.border}
    ${cancelBoxShadow ? "" : styles.boxShadow}
    `}
  >
    {children}
  </div>
);

export default CustomizedPaper;
