import React, { useState } from 'react';
import { Avatar, Box, IconButton, Typography, Button, TextField, Dialog, Stack } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import pxToRem from '../../../helpers/pxToRem';
import styles from "../index.module.scss";
import { classNames } from 'react-easy-crop/helpers';
import CustomizedModal from '../../../components/CustomizedModal';
import { PrimaryDangerButton, SecondaryButton } from '../../../components/Buttons/ButtonUI';

interface OptionsMenuProps {
  onMarkComplete: (isComplete: boolean) => void;
  onDelete: (isDeleted: boolean) => void;
  isDisabled?: boolean;
  isDone?: boolean;
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({ onMarkComplete, onDelete, isDisabled = false, isDone = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [actionType, setActionType] = useState<'complete' | 'delete' | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmOpen = (type: 'delete') => {
    setActionType(type);
    setConfirmOpen(true);
    handleClose();
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    onDelete(true);
    handleConfirmClose();
  };

  const handleMarkComplete = () => {
    onMarkComplete(true);
    handleClose();
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{ width: pxToRem(38), height: pxToRem(38)}}
        
      >
        <svg width={pxToRem(4)} height={pxToRem(20)} viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#737373" /> <circle cx="2" cy="10" r="2" transform="rotate(90 2 10)" fill="#737373" /> <circle cx="2" cy="18" r="2" transform="rotate(90 2 18)" fill="#737373" /> </svg>
      </IconButton>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: styles.dotButtonMenu
        }}

      >
        { !isDone ? (<MenuItem className={styles.hoverMenuItem} onClick={() => handleMarkComplete()} disabled={isDisabled}>Mark as complete</MenuItem>): (<MenuItem className={styles.hoverMenuItem} onClick={() => handleMarkComplete()} disabled={true}>Mark as complete</MenuItem>)}
        <MenuItem className={styles.hoverMenuItem} onClick={() => handleConfirmOpen('delete')} disabled={isDisabled}>Delete</MenuItem>
      </Menu>
      <CustomizedModal open={confirmOpen} handleClose={() => { }} width={600} type='ERROR' >

        <Stack direction={"column"}>
          <Stack className={`${styles.alertTextBox}`} direction={"column"}>
            <Typography className={`${styles.alertText}`}>
              Are you sure you want to {actionType} these items?

            </Typography>
          </Stack>

          <div className={`${styles.narrowDivider} horizontalDivider`}></div>
          <Stack direction={"row"} justifyContent='space-between'>
            <SecondaryButton onClick={handleConfirmClose} className={`${styles.button} regular`}> Cancel </SecondaryButton>
            <Stack direction={"row"} className={`${styles.confirmButtonStack}`}>
              <PrimaryDangerButton className={styles.button} onClick={handleConfirmAction}>Confirm</PrimaryDangerButton>
            </Stack>
          </Stack>

        </Stack>
      </CustomizedModal>
    </>
  );
};

export default OptionsMenu;