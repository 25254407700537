
import { Button, IconButton, Stack } from "@mui/material";
import { DateRange } from '@mui/x-date-pickers-pro';
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import { CustomizedDropdown } from "../../../components/Inputs/Dropdown/CustomizedDropdown";
import PlannerInputTextField from "../../../components/Inputs/TextFields/PlannerInputTextField";
import { Engagement } from "../../../firebase/types-engagement";
import { getCurrentUserId } from "../../../firebase/userApi";
import constants from "../../../helpers/constants";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import PickerWithButtonField from "../../OrganisationPlannerPage/components/dateRangePicker";
import styles from "../index.module.scss";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

interface ISearchTable {
	orgId: string | null | undefined
	setOpenAddEngagement: (state: boolean) => void,
	rowClick: (id: string) => void;
}
export interface FilterType {
	value: string;
	hasTouched: boolean;
}
interface EngagementTableHandles {
	triggerUpdate: () => void;
}

export type EngagementTable = {
	id?: string,
	title?: string,
	type?: string,
	start_time?: number,

}

const getRange = (type: string) => {
    const now = dayjs(); // Capture the current moment, including time
    switch (type) {
        case 'WEEK':
            return [now.startOf('isoWeek'), now.endOf('isoWeek').add(1, 'day').startOf('day').subtract(1, 'minute')]; // End of the week, just before the next day starts
        case 'MONTH':
            return [now.startOf('month'), now.endOf('month').add(1, 'day').startOf('day').subtract(1, 'minute')]; // Last minute of the month
        case 'YEAR':
            return [now.startOf('year'), now.endOf('year').add(1, 'day').startOf('day').subtract(1, 'minute')]; // Last minute of the year
        case 'PAST':
            return [null, now.startOf('day').subtract(1, 'minute')]; // Everything before the current moment, minus one minute
        default:
            return [null, null];
    }
};

// const viewerColumns = [
//     columnHelper.accessor('title', { cell: info => info.getValue(), header: "Title" }),
//     columnHelper.accessor('type', { cell: info => info.getValue(), header: "type" }),
//     columnHelper.accessor('date', { cell: info => info.getValue(), header: "Date" }),
// ];
export const SearchTable = forwardRef<EngagementTableHandles, ISearchTable>(({ orgId, setOpenAddEngagement, rowClick }: ISearchTable, ref) => {
	const dispatch = useDispatch();
	const userId = getCurrentUserId();
	const [searchInput, setSearchInput] = useState("");
	const [selectedDate, setSelectedDate] = useState<DateRange<Dayjs>>([null, null]);
	const [clearFilter, setClearFilter] = useState(false);
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => { setSearchInput(event.target.value); };
	const [isTextFieldVisible, setIsTextFieldVisible] = useState(false);
	const filterOptions = [{ label: "All", value: "ALL" }, { label: "This Week", value: "WEEK" }, { label: "This Month", value: "MONTH" }, { label: "This Year", value: "YEAR" }, { label: "Past", value: "PAST" }];
	const [filterPlannerType, setFilterPlannerType] = useState<FilterType>({ value: "ALL", hasTouched: false });
	const [engagementRows, setEngagementRows] = useState<Engagement[]>([]);
	const [isEmptyState, setIsEmptyState] = useState(true);
	const columnHelper = createColumnHelper<EngagementTable>();
	const [updateTrigger, setUpdateTrigger] = useState(false)
	const engagements = useSelector((state: RootState) => state.engagementState.engagements);
	const engagementPermissions = useSelector((state:RootState) => state.globalState.permissions?.engagement);

	useEffect(() => {
		// Filter engagements based on search input and type
		let filteredEngagements = engagements;
		if (searchInput) {
			filteredEngagements = filteredEngagements.filter(engagement =>
				(engagement.title && engagement.title.toLowerCase().includes(searchInput.toLowerCase())) ||
				(engagement.type && engagement.type.toLowerCase().includes(searchInput.toLowerCase()))
			);
		}
	
		// Filter engagements based on selected date range
		if (selectedDate[0] !== null && selectedDate[1] !== null) {
			filteredEngagements = filteredEngagements.filter(engagement =>
				dayjs(engagement.start_time).isSameOrAfter(selectedDate[0]!.startOf('day')) &&
				dayjs(engagement.start_time).isSameOrBefore(selectedDate[1]!.endOf('day'))
			);
		}
	
		// Filter by type
		if (filterPlannerType.value !== "ALL") {
			const [startDate, endDate] = getRange(filterPlannerType.value);
			filteredEngagements = filteredEngagements.filter(engagement => {
				const engagementDate = dayjs(engagement.start_time);
				return (!startDate || engagementDate.isSameOrAfter(startDate)) &&
					(!endDate || engagementDate.isSameOrBefore(endDate));
			});
		}
	
		// Reference to today's date at midnight
		const today = dayjs().startOf('day');
	
		// Separate and sort future and past engagements
		const futureEngagements = filteredEngagements.filter(e => dayjs(e.start_time).isSameOrAfter(today)).sort((a, b) => dayjs(a.start_time).diff(dayjs(b.start_time)));
		const pastEngagements = filteredEngagements.filter(e => dayjs(e.start_time).isBefore(today)).sort((a, b) => dayjs(b.start_time).diff(dayjs(a.start_time)));
	
		// Combine future and past engagements
		const sortedEngagements = [...futureEngagements, ...pastEngagements];
	
		setEngagementRows(sortedEngagements);
		setIsEmptyState(sortedEngagements.length === 0);
	
	}, [engagements, searchInput, selectedDate, filterPlannerType, updateTrigger]); // Make sure to add all dependencies here
	



	useImperativeHandle(ref, () => ({
		triggerUpdate: () => {
			setUpdateTrigger(prev => !prev);
		}
	}));

	const toggleTextFieldVisibility = () => {
		setIsTextFieldVisible((prevVisible) => !prevVisible);
	};

	const hideTextField = () => {
		setIsTextFieldVisible(false);
	};

	const handleClearFilters = () => {
		// setFilterPlannerType({ value: "ALL", hasTouched: false });
		setSelectedDate([null, null]);
		setSearchInput('');
		setClearFilter(true);
	};

	const formatDate = (dateString: number) => {

		const date = dayjs(dateString);  // Convert milliseconds directly
		const today = dayjs();
		if (date.isSame(today, 'day')) {
			return 'Today';
		} else {
			return date.format('D MMM YY');
		}
	};


	const viewerColumns = [
		columnHelper.accessor('title', { cell: info => info.getValue(), header: "Engagement" }),
		columnHelper.accessor('type', { cell: info => info.getValue(), header: "Type" }),
		columnHelper.accessor('start_time', { cell: info => info.getValue(), header: "Date" }),
	];

	const table = useReactTable({
		data: engagementRows,
		columns: viewerColumns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getRowId: (row, index) => row.id || `row-${index}`,
		state: {
			pagination: {
				pageSize: engagementRows.length, // Set pageSize to the length of engagementRows or any other logic
				pageIndex: 0, // Using the state variable
			},
		},
	});

	const emptyTableRow = (): JSX.Element => {
		setEngagementRows(emptyDataRow)
		setIsEmptyState(true)
		return (
			<div>
			</div>
		)
	}

	const emptyDataRow = [
		{
			title: "Your engagements will appear here",
		}
	]


	return (
		<Stack>
			<Stack style={{ marginBottom: pxToRem(20) }}>
				<Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>
					<PickerWithButtonField
						onDateRangeSelect={(dates) => {
							setSelectedDate(dates)
							setIsTextFieldVisible(true)
							setClearFilter(false)
						}}
						clearDate={clearFilter}
					/>
					{
						!isTextFieldVisible && (
							<IconButton onClick={toggleTextFieldVisibility} style={{ marginLeft: 0 }}>
								<svg width={pxToRem(36)} height={pxToRem(36)} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M27 27L21.0001 21M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
								</svg>

							</IconButton>
						)
					}
					{
						isTextFieldVisible && (
							<PlannerInputTextField
								containerStyle={{ flex: 1, marginLeft: 1 }}
								value={searchInput}
								placeholder="Start typing..."
								onChange={handleInputChange}
								startAdornment={
									<IconButton onClick={hideTextField}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width={pxToRem(24)}
											height={pxToRem(25)}
											viewBox="0 0 24 25"
											fill="none"
										>
											<path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</IconButton>

								}
								endAdornment={
									selectedDate && selectedDate[0] && selectedDate[1] ? (
										<Stack direction="row" alignItems="center" spacing={1}>
											<div>
												{selectedDate[0].isSame(selectedDate[1], 'day')
													? selectedDate[0].format('DD MMMM YYYY')
													: `${selectedDate[0].format('DD MMMM YYYY')} - ${selectedDate[1].format('DD MMMM YYYY')}`}
											</div>
											<Button style={{ color: "#447D75" }} onClick={() => { handleClearFilters(); hideTextField(); }}>
												Clear Filters
											</Button>
										</Stack>
									) : undefined
								}
							/>
						)
					}

					<CustomizedDropdown

						defaultValue={"ALL"}
						valueCheck={filterPlannerType.value}
						// label="Plan Status"
						// shrinkLabelWidth={70}
						containerStyle={{ width: pxToRem(154), marginLeft: 12, color: "#A3A3A3" }} // Set a fixed width here
						// customHeight={constants.addAlumniInputHeight}
						fontSize={constants.addAlumniFontSize}
						datas={filterOptions}
						value={filterPlannerType.value}
						onChange={(e) => {
							const newValue = e.target.value as string; // Type assertion here
							setFilterPlannerType({ value: newValue, hasTouched: true });
						}}
						error={filterPlannerType.hasTouched && !filterPlannerType.value}
						helperText={filterPlannerType.hasTouched && !filterPlannerType.value ? "This field cannot be empty" : undefined}

					/>
				</Stack>
				<Stack direction={"row"} className={styles.buttonBarWrapper} spacing={2}>
				{(!engagementPermissions || engagementPermissions === "EDITOR") ? <PrimaryButton className={styles.button} onClick={() => { setOpenAddEngagement(true) }}>Create New</PrimaryButton> : <h6 style={{ flexGrow: 1 }} className='light'>Engagement Table</h6>}
				</Stack>

				<Stack marginTop={pxToRem(24)}>

					<div>
						<Stack spacing={0} className={styles.tableWrapper}>
							<table className={styles.engagement_table}>
								<thead>
									{table.getHeaderGroups().map(headerGroup => (
										<tr key={headerGroup.id}>
											{headerGroup.headers.map(header => (
												<th key={header.id} style={{ textAlign: 'left' }}>
													<Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
														<Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: () => { header.column.getToggleSortingHandler(); } }}>
															{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
														</Stack>
														{(header.column.id === 'type' || header.column.id === 'start_time' || header.column.id === 'title' )&& 
															(
																<Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
																	<Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
																	<Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
																</Stack>
															)}
													</Stack>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody>
									{
										// status === "loading" || status === "stalled"?
										//     <RowSkeleton />
										// :
										table.getRowModel().rows.map(row => (
											<tr key={row.id} onClick={() => { if (!isEmptyState) { rowClick(row.original.id ?? "") } }} className={styles.normalRow}>
												{
													row.getVisibleCells().map(cell => {
														let cellJSX: JSX.Element | undefined = undefined;
														if (cell.column.id === "title") {
															const isPlaceholder = row.original.title === "Your engagements will appear here";
															cellJSX = (
																<Stack key={cell.id} width={pxToRem(350)}>
																	<small style={{ color: isPlaceholder ? '#A3A3A3' : 'inherit' }}>
																		{row.original.title || "No Title"}
																	</small>
																</Stack>
															);
														}
														else if (cell.column.id === "type") {
															cellJSX = <Stack key={cell.id} > <small >
																{row.original.type || "-"}
															</small></Stack>;
														}

														else if (cell.column.id === "start_time") {
															cellJSX = <Stack key={cell.id} className={styles.text} direction={"row"} style={{ alignItems: "center" }}>
																{row.original.start_time &&
																	<Stack style={{ alignItems: "center", marginRight: pxToRem(5) }}>
																		<svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
																		</svg>
																	</Stack>
																}
																<small >
																	{row.original.start_time ? formatDate(row.original.start_time as number) : ""}
																</small>
															</Stack>;
														}
														return (<td key={cell.id}> {cellJSX} </td>)
													})
												}
											</tr>
										))
									}
								</tbody>
							</table>
							{engagementRows.length <= 0 ? emptyTableRow() : null}
						</Stack>
					</div>
				</Stack>
			</Stack>
		</Stack>
	)
});
export default memo(SearchTable);