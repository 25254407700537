import { addDoc, collection, doc, DocumentData, getDoc, getDocs, query, runTransaction, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { db } from ".";
import { Res } from "./shared";
import { Engagement, getEngagement, getEngagementDetails, LinkedAlumni, NewEngagement } from "./types-engagement";


export const createNewEngagement = async (orgId: string, engagement: NewEngagement, userId: string): Promise<Res<string>> => {
  try {
    let engagementId: string | undefined;
    await runTransaction(db, async (transaction) => {
      const orgRef = doc(db, "organisations", orgId);
      const orgDataSnapshot = await transaction.get(orgRef);
      let organisationData = orgDataSnapshot.data();

      // Check if the engagement type exists in the list; if not, add it
      const engagementTypes = organisationData?.engagementTypes || [];
      if (!engagementTypes.includes(engagement.type) && engagement.type) {
        engagementTypes.push(engagement.type);
        transaction.update(orgRef, { engagementTypes });
      }

      // Proceed to create the new engagement
      const collRef = collection(db, "organisations", orgId, "engagements");
      const docRef = await addDoc(collRef, {
        ...engagement,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        createdByUserId: userId,
      });
      engagementId = docRef.id;  // Store the new document's ID

      // Update total engagements count
      let totalEngagements = (organisationData?.totalEngagements ?? 0) + 1;
      transaction.update(orgRef, {
        totalEngagements: totalEngagements,
        engagementUpdateAt: serverTimestamp(),
        engagementUpdateBy: userId
      });
    });
    if (engagementId) {
      return { code: 200, data: engagementId};
    } else {
      return { code: 500, errorMsg: "Failed to create engagement." };
    }
  } catch (error) {
    console.error("Error creating new engagement:", error);
    return { code: 500 };
  };
}

export const editEngagement = async (orgId: string, engagementId: string, engagement: NewEngagement, userId: string): Promise<Res<string>> => {
  try {
    const engagementRef = doc(db, "organisations", orgId, "engagements", engagementId);
    await runTransaction(db, async (transaction) => {
      const engagementSnapshot = await transaction.get(engagementRef);
      if (!engagementSnapshot.exists()) {
        throw new Error("Engagement does not exist.");
      }

      // Optionally, update the engagement type list if new
      const orgRef = doc(db, "organisations", orgId);
      const orgDataSnapshot = await transaction.get(orgRef);
      let organisationData = orgDataSnapshot.data();
      const engagementTypes = organisationData?.engagementTypes || [];
      if (engagement.type && !engagementTypes.includes(engagement.type)) {
        engagementTypes.push(engagement.type);
        transaction.update(orgRef, { engagementTypes });
      }

      // Update the engagement
      transaction.update(engagementRef, {
        ...engagement,
        updated_at: serverTimestamp(),
        updatedByUserId: userId
      });

      // Optionally update other organization data
      transaction.update(orgRef, {
        engagementUpdateAt: serverTimestamp(),
        engagementUpdateBy: userId
      });
    });
    return { code: 200, data: "Engagement updated successfully." };
  } catch (error) {
    console.error("Error updating engagement:", error);
    return { code: 500, errorMsg: "Failed to update engagement" };
  }
}


/**
 * Fetches engagement types from an organization document in Firestore.
 * @param {string} orgId The ID of the organization.
 * @returns {Promise<string[]>} A promise resolving to a list of engagement types.
 */
export async function fetchEngagementTypes(orgId: string): Promise<string[]> {
    try {
        const orgRef = doc(db, "organisations", orgId);
        const orgDoc = await getDoc(orgRef);
        if (orgDoc.exists()) {
            const orgData = orgDoc.data();
            return orgData.engagementTypes || [];
        }
        throw new Error("Organization document does not exist.");
    } catch (error) {
        console.error("Error fetching engagement types:", error);
        throw error; // rethrow the error to handle it in the calling function
    }
}

/**
 * Fetches engagement involvements from an organization document in Firestore.
 * @param {string} orgId The ID of the organization.
 * @returns {Promise<string[]>} A promise resolving to a list of engagement involvements.
 */
export async function fetchEngagementInvolvements(orgId: string): Promise<string[]> {
  try {
      const orgRef = doc(db, "organisations", orgId);
      const orgDoc = await getDoc(orgRef);
      if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          return orgData.engagementInvolvements || [];
      }
      throw new Error("Organization document does not exist.");
  } catch (error) {
      console.error("Error fetching engagement involvements:", error);
      throw error; // rethrow the error to handle it in the calling function
  }
}


/**
 * Fetches all engagements for a specific organization.
 * @param {string} orgId The ID of the organization.
 * @returns {Promise<Engagement[]>} A promise that resolves to an array of Engagement objects.
 */
export async function getAllEngagementsForOrg(orgId: string): Promise<Res<Engagement[] | string>> {
  const engagementsRef = collection(db, "organisations", orgId, "engagements");
  const q = query(engagementsRef);
  try {
      const querySnapshot = await getDocs(q);
      const engagements: Engagement[] = [];

      querySnapshot.forEach((doc) => {
          const engagementData = getEngagement(doc.data() as DocumentData);
          engagements.push({ ...engagementData, id: doc.id });
      });

      return  { code: 200, data: engagements };;
  } catch (error) {
      console.error("Failed to fetch engagements:", error);
      return { code: 500, errorMsg: "Failed to fetch tasks for alumni" };
  }
}


/**
 * Fetches a specific engagement by its ID for a specific organization.
 * @param {string} orgId The ID of the organization.
 * @param {string} engId The ID of the engagement.
 * @returns {Promise<Engagement>} A promise that resolves to an Engagement object or null if not found.
 */
export async function getEngagementById(orgId: string, engId: string): Promise<Res<Engagement | null>> {
  const engagementDocRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      const docSnapshot = await getDoc(engagementDocRef);
      if (docSnapshot.exists()) {
          const engagementData = getEngagementDetails(docSnapshot.data() as DocumentData);
          return { code: 200, data: { ...engagementData, id: docSnapshot.id } };
      } else {
          console.log("No engagement found with the ID:", engId);
          return { code: 500, errorMsg: "Engagement not found" };
      }
  } catch (error) {
      console.error("Failed to fetch engagement:", error);
      return { code: 500, errorMsg: "Failed to fetch engagement" };
  }
}


// Update the agenda for a specific engagement
export async function updateAgenda(orgId: string, engId: string, agenda: string): Promise<Res<string>> {
  const engRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      await updateDoc(engRef, { agenda });
      return { code: 200, data: "Agenda Updated" };
  } catch (error) {
      console.error("Failed to update agenda:", error);
      return { code: 500, errorMsg: "Failed to update agenda due to server error" };
  }
}

// Update the outcome for a specific engagement
export async function updateOutcome(orgId: string, engId: string, outcome: string): Promise<Res<string>> {
  const engRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      await updateDoc(engRef, { outcome });
      return { code: 200, data: "Outcome Updated" };
  } catch (error) {
      console.error("Failed to update outcome:", error);
      return { code: 500, errorMsg: "Failed to update outcome due to server error" };
  }
}

// Update the number of students involved for a specific engagement
export async function updateStudentNum(orgId: string, engId: string, studentNum: number): Promise<Res<string>> {
  const engRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      await updateDoc(engRef, { student_num: studentNum });
      return { code: 200, data: "Student Involved Updated" };
  } catch (error) {
      console.error("Failed to update number of students involved:", error);
      return { code: 500, errorMsg: "Failed to update number of students involved due to server error" };
  }
}

// Update the number of invited individuals for a specific engagement
export async function updateInvitedNum(orgId: string, engId: string, invitedNum: number): Promise<Res<string>> {
  const engRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      await updateDoc(engRef, { invited_num: invitedNum });
      return { code: 200, data: "Invited Number Updated" };
  } catch (error) {
      console.error("Failed to update number of invited individuals:", error);
      return { code: 500, errorMsg: "Failed to update number of invited individuals due to server error" };
  }
}

// Update the number of planned attendees for a specific engagement
export async function updatePlannedNum(orgId: string, engId: string, plannedNum: number): Promise<Res<string>> {
  const engRef = doc(db, "organisations", orgId, "engagements", engId);
  try {
      await updateDoc(engRef, { planned_num: plannedNum });
      return { code: 200, data: "Planned Attendees Number Updtaed" };
  } catch (error) {
      console.error("Failed to update number of planned attendees:", error);
      return { code: 500, errorMsg: "Failed to update number of planned attendees due to server error" };
  }
}

/**
 * Updates the LinkedAlumnis list and engagement types in an engagement document.
 * 
 * @param {string} orgId The organization ID.
 * @param {string} engId The engagement ID.
 * @param {Array} newAlumniList Array of objects each containing `alumniId`, `status`, and `involvement`.
 * @returns {Promise<Res<string>>} A promise resolving to the result of the operation.
 */
export async function updateLinkedAlumnis(
  orgId: string,
  engId: string,
  newAlumniList: Array<{ objectID?: string, status: string, involvement: string }>
): Promise<Res<string>> {
  const engagementRef = doc(db, "organisations", orgId, "engagements", engId);
  const orgRef = doc(db, "organisations", orgId);

  try {
    const batch = writeBatch(db);
    const processedAlumni = newAlumniList.filter(alumni => alumni.objectID !== undefined);

    const orgDoc = await getDoc(orgRef);
    let engagementInvolvements = orgDoc.exists() ? orgDoc.data().engagementInvolvements || [] : [];

    // Add new involvements to the organization's list if they are not already present
    processedAlumni.forEach(alumni => {
      if (!engagementInvolvements.includes(alumni.involvement)) {
        engagementInvolvements.push(alumni.involvement);
      }
    });

    // Update the engagement document with new alumni list
    batch.update(engagementRef, {
      linkedAlumnis: processedAlumni.map(alumni => ({
        alumniId: alumni.objectID,
        status: alumni.status,
        involvement: alumni.involvement
      }))
    });

    // Update the organization document with new or existing involvements
    batch.update(orgRef, {
      engagementInvolvements: engagementInvolvements.sort() // Optionally sort the list if needed
    });

    await batch.commit(); // Commit the batch write
    return { code: 200, data: "LinkedAlumnis and engagement involvements updated successfully." };
  } catch (error) {
    console.error("Failed to update LinkedAlumnis and engagement involvements:", error);
    return { code: 500, errorMsg: "Failed to update due to server error" };
  }
}

export async function updateSpecificEngagement(
  orgId: string,
  engId: string,
  alumniId: string,
  engagementDetail: { status: string, involvement: string }
): Promise<Res<string>> {
  const engagementRef = doc(db, "organisations", orgId, "engagements", engId);
  const orgRef = doc(db, "organisations", orgId);

  try {
    const engagementDoc = await getDoc(engagementRef);
    if (!engagementDoc.exists()) {
      throw new Error("Engagement document does not exist.");
    }

    const engagementData = engagementDoc.data();
    const linkedAlumnis = engagementData.linkedAlumnis || [];
    const updatedAlumnis = linkedAlumnis.map((alumni: LinkedAlumni) =>
      alumni.alumniId === alumniId ? { ...alumni, ...engagementDetail } : alumni
    );

    // Check for new involvement to add to the organization's list
    const newInvolvement = engagementDetail.involvement;
    const orgDoc = await getDoc(orgRef);
    let engagementInvolvements = orgDoc.exists() && orgDoc.data().engagementInvolvements ? orgDoc.data().engagementInvolvements : [];

    // Add new involvement if it's not already present
    if (newInvolvement && !engagementInvolvements.includes(newInvolvement)) {
      engagementInvolvements.push(newInvolvement);
    }

    // Update the engagement document with new alumni list
    await updateDoc(engagementRef, {
      linkedAlumnis: updatedAlumnis
    });

    // Update the organization document with the possibly updated involvements list
    await updateDoc(orgRef, {
      engagementInvolvements
    });

    return { code: 200, data: "Engagement and organization involvements updated successfully." };
  } catch (error) {
    console.error("Failed to update engagement and engagement involvements:", error);
    return { code: 500, errorMsg: "Failed to update due to server error" };
  }
}


/**
 * Unlinks an alumni from a specific engagement.
 *
 * @param {string} orgId The organization ID.
 * @param {string} engId The engagement ID.
 * @param {string} alumniId The alumni ID to unlink.
 * @returns {Promise<Res<string>>} A promise resolving to the result of the operation.
 */
export async function unlinkAlumni(
  orgId: string,
  engId: string,
  alumniId: string
): Promise<Res<string>> {
  const engagementRef = doc(db, "organisations", orgId, "engagements", engId);

  try {
    const engagementDoc = await getDoc(engagementRef);
    if (!engagementDoc.exists()) {
      throw new Error("Engagement document does not exist.");
    }

    const engagementData = engagementDoc.data();
    const linkedAlumnis = engagementData.linkedAlumnis || [];
    const filteredAlumnis = linkedAlumnis.filter((alumni: LinkedAlumni) => alumni.alumniId !== alumniId);

    await updateDoc(engagementRef, {
      linkedAlumnis: filteredAlumnis
    });

    return { code: 200, data: "Alumni unlinked successfully." };
  } catch (error) {
    console.error("Failed to unlink alumni:", error);
    return { code: 500, errorMsg: "Failed to unlink alumni due to server error" };
  }
}


