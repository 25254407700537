import { IconButton, Stack, TextField, Typography } from "@mui/material";
import styles from "./HelpCentrePanel.module.scss";
import { PrimaryButton, PrimaryDangerButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import pxToRem from "../../../helpers/pxToRem";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import useTextInput from "../../../hooks/useTextInput";
import constants from "../../../helpers/constants";
import { ChangeEvent, memo, useEffect, useState } from "react";
import AlertComponent from "../../../components/AlertComponent";
import { joinStringWithSpace } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import globalStyles from "../../../../src/styles/_stylesParams.module.scss"
import { getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForInput, getErrorMsgForSubject, getHelpCenterMessageError, getHelpDeskMessageError } from "../../../helpers/inputValidators";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { uploadAllTypeFileToStorage, uploadFileToStorage } from "../../../firebase/storageApis";
import CustomizedModal from "../../../components/CustomizedModal";

const HelpCentrePanel = () => {
	const cfirstName = useSelector((state: RootState) => state.user.userInfos.firstName);
	const clastName = useSelector((state: RootState) => state.user.userInfos.lastName);
	const cdisplayName = useSelector((state: RootState) => state.user.userInfos.displayName);
	const currentName = (cfirstName && clastName) ? joinStringWithSpace(cfirstName, clastName) : cdisplayName;
	const cEmail = useSelector((state: RootState) => state.user.userInfos.email);
	const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
	const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
	const name = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "Name") } });
	const email = useTextInput({ inputValidator: (input) => { return getErrorMsgForEmailInput(input, true) } });
	const contactNumber = useTextInput({ inputValidator: (input) => { return getErrorMsgForContactNumber(input, false) } });
	const subject = useTextInput({ inputValidator: (input) => { return getErrorMsgForSubject(input, 3, 100,  "Subject", false) } });
	const message = useTextInput({ inputValidator: (input) => { return getHelpCenterMessageError(input, 3, 500, "Message") } });
	const [loading, setLoading] = useState(false)

	const [attachText, setAttachText] = useState("Attach File")
	const [file, setFile] = useState<File | null>(null); // Assuming you have a state to keep the file
	const [fileName, setFileName] = useState('');

	const [alertOpen, setAlertOpen] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
	
			const allowedTypes = [
				'application/pdf',
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'image/jpeg',
				'image/png',
				'text/csv'
			];
	
			if (!allowedTypes.includes(file.type) || file.size > 10485760) { // 10MB
				setConfirmOpen(true);
				return;
			}
	
			setFile(file);
			setFileName(file.name);
	
			// Format file size for display
			const fileSize = formatFileSize(file.size);
	
			// Set attach text with filename and size
			setAttachText(`${file.name.length > 40 ? `${file.name.substring(0, 40)}...` : file.name} (${fileSize})`);
		}
	};
	
	// Helper function to format file size
	function formatFileSize(size: number): string {
		if (size < 1024) return size + ' bytes';
		let sizeInKB = size / 1024;
		if (sizeInKB < 1024) return sizeInKB.toFixed(2) + ' KB';
		let sizeInMB = sizeInKB / 1024;
		return sizeInMB.toFixed(2) + ' MB';
	}
	


	useEffect(() => {
		name.setValue(currentName ?? "")
		email.setValue(cEmail ?? "")
	}, [])

	const resetForm = () => {
		name.setValue(""); name.setHasTouched(false);
		email.setValue(""); email.setHasTouched(false);
		contactNumber.setValue(""); contactNumber.setHasTouched(false);
		subject.setValue(""); subject.setHasTouched(false);
		message.setValue(""); message.setHasTouched(false);
		setFile(null);
		setFileName("");
		setAttachText("Attach File");

	}

	const triggerFileInputClick = () => {
		const fileInput = document.getElementById('raised-button-file');
		if (fileInput) { // This checks that fileInput is not null
			fileInput.click();
		}
	};

	const handleAlertClose = () => {
		setErrorMsg(undefined)
		setAlertOpen(false);
	};

	const handleConfirmClose = () => {
		setSuccessMsg(undefined)
		setConfirmOpen(false);
	};


	const onSend = async () => {
		const inputs = [name, email, contactNumber, subject, message]; // Mandatory Fields
		let validated = true;
		inputs.forEach((input) => input.setHasTouched(true));
		inputs.forEach((input) => { if (!validated) return; if (input.hasError) validated = false; });
		if (!validated) { return; }

		const currentUserId = auth.currentUser?.uid;
		if (!currentUserId) {
			setErrorMsg("User must be logged in to send a request.");
			setAlertOpen(true);
			return;
		}

		setLoading(true);

		if (file) {
			try {
				// Define the path where you want to save the file in Firebase Storage
				const filePath = `helpCentre/${currentUserId}/${file.name}`;
				let fileUrl = ''; // Placeholder for file URL

				if (file) {
					const uploadResult = await uploadAllTypeFileToStorage({ file, path: filePath });
					if (uploadResult.code !== 200) {
						throw new Error("Failed to upload file.");
					}
					fileUrl = uploadResult.data; // Get the URL of the uploaded file
				}

				const docRef = collection(db, "helpCentre");
				const requestData = {
					created_at: serverTimestamp(),
					name: name.value.trim(),
					email: email.value.trim(),
					contactNumber: contactNumber.value.trim(),
					subject: subject.value.trim(),
					message: message.value.trim(),
					userId: currentUserId,
					fileUrl: fileUrl // Include the file URL in the request data
				};

				await addDoc(docRef, requestData);
				resetForm();
				setSuccessMsg("Your query with attachment has successfully been sent!");
				setConfirmOpen(true);
			} catch (error) {
				console.error("Failed to send request:", error);
				setErrorMsg("Failed to send request. Please try again.");
				setAlertOpen(true)
			} finally {
				setLoading(false);
			}
		} else {
			try {
				const docRef = collection(db, "helpCentre");
				const requestData = {
					created_at: serverTimestamp(), name: name.value.trim(),
					email: email.value.trim(), contactNumber: contactNumber.value.trim(),
					subject: subject.value.trim(), message: message.value.trim(),
					userId: currentUserId
				};
				await addDoc(docRef, requestData)
				resetForm();
				setSuccessMsg("Your query has successfully been sent!");
				setConfirmOpen(true);
			}
			catch (error) { setErrorMsg("Failed to send request. Please try again."); setAlertOpen(true) }
			setLoading(false);
		}
	}

	return (
		<>
			<Stack className={styles.wrapper}>
				<Stack className={styles.container}>
					<label className={styles.headerTitle}>Help Centre</label>
					<label className={styles.headerSubTitle}>Please submit any general queries or report any issues below</label>
				</Stack>
				<div className={styles.divider} />
				<Stack className={styles.containerWrapper} spacing={3} direction={"column"}>
					<Stack style={{ alignItems: 'start', justifyContent: 'start', flex: 1, width: pxToRem(520) }} spacing={1.8}>
						<span className={styles.containerWrapperTitle}>{`Hi ${cfirstName}, how can we help?`}</span>
					</Stack>
					<Stack style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: pxToRem(520) }} spacing={1.8}>

						<InputTextField titleColor={globalStyles['shades100']} containerStyle={{ width: '100%' }} type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={name.value} title="Name*" value={name.value} onChange={(e) => name.setValue(e.target.value)} error={name.hasError && name.hasTouched} helperText={<small>{name.getErrorMessage()}</small>} />
						<InputTextField titleColor={globalStyles['shades100']} containerStyle={{ width: '100%' }} type={"email"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={email.value} title="Email*" value={email.value} onChange={(e) => email.setValue(e.target.value)} error={email.hasError && email.hasTouched} helperText={<small>{email.getErrorMessage()}</small>} />
						<InputTextField titleColor={globalStyles['shades100']} containerStyle={{ width: '100%' }} type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={contactNumber.value} title="Contact Number*" value={contactNumber.value} onChange={(e) => contactNumber.setValue(e.target.value)} error={contactNumber.hasError && contactNumber.hasTouched} helperText={<small>{contactNumber.getErrorMessage()}</small>} />
						<InputTextField titleColor={globalStyles['shades100']} containerStyle={{ width: '100%' }} type={"text"} fontSize={constants.defaultFontSize} customHeight={constants.defaultInputHeight} valueCheck={subject.value} title="Subject*" value={subject.value} onChange={(e) => subject.setValue(e.target.value)} error={subject.hasError && subject.hasTouched} helperText={<small>{subject.getErrorMessage()}</small>} />
						{<p style={{ color: globalStyles['shades100'], width: pxToRem(520), alignContent: 'start' }} className='smallMarginBottom'>Your Message*</p>}
						<TextField
							sx={{
								"& label.Mui-focused": { color: globalStyles.primary400, },
								"& .MuiInput-underline:after": { borderBottomColor: "#B2BAC2", },
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: globalStyles.neutrals400, },
									"&:hover fieldset": { borderColor: globalStyles.primary400, },
									"&.Mui-focused fieldset": { borderColor: globalStyles.primary400, },
									"& .MuiOutlinedInput-input": { padding: 0, fontSize: pxToRem(constants.defaultFontSize), maxHeight: pxToRem(200) },
								},
							}}
							value={message.value} onChange={(e) => message.setValue(e.target.value)}
							id="outlined-multiline-flexible" label="" multiline minRows={3} style={{ width: '100%' }}
							error={message.hasError && message.hasTouched} helperText={<small>{message.getErrorMessage()}</small>}
						/>
						<Stack style={{ alignItems: 'start', width: pxToRem(520) }}>
							<input
								accept=".pdf,.csv,.xls,.xlsx,.doc,.docx,.jpg,.png"
								style={{ display: 'none' }}
								id="raised-button-file"
								multiple
								type="file"
								onChange={handleFileChange}
							/>
							<label htmlFor="raised-button-file" style={{ cursor: 'pointer' }}>
								<Stack direction={"row"}>
								<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M19.1525 9.89952L10.1369 18.9151C8.08662 20.9654 4.7625 20.9654 2.71225 18.9151C0.661997 16.8649 0.661998 13.5408 2.71225 11.4905L11.7279 2.47489C13.0947 1.10806 15.3108 1.10806 16.6776 2.47489C18.0444 3.84173 18.0444 6.05781 16.6776 7.42464L8.01555 16.0867C7.33213 16.7701 6.22409 16.7701 5.54068 16.0867C4.85726 15.4033 4.85726 14.2952 5.54068 13.6118L13.1421 6.01043" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<p className={styles.semiBoldAttach} style={{ color: globalStyles['shades100'], marginLeft: pxToRem(10), textDecoration: attachText !== "Attach File" ? 'underline' : 'none' }}>{attachText}</p>
								</Stack>
							</label>
						</Stack>


						<PrimaryButton loading={loading} onClick={onSend} style={{ height: pxToRem(36), width: pxToRem(430), marginTop: pxToRem(40), borderRadius: pxToRem(16) }}>Submit</PrimaryButton>
					</Stack>
				</Stack>
			</Stack>
			<CustomizedModal open={alertOpen} handleClose={() => { }} width={600} type='ERROR' >
				<Stack direction={"column"}>
					<Stack className={`${styles.alertTextBox}`} direction={"column"}>
						<Stack style={{ alignItems: "start", width: pxToRem(520) }}>
							<Stack direction={'row'}>
								<Typography className={`${styles.alertTitle}`}>Upload Alert</Typography>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.9998 8.99999V13M11.9998 17H12.0098M10.6151 3.89171L2.39019 18.0983C1.93398 18.8863 1.70588 19.2803 1.73959 19.6037C1.769 19.8857 1.91677 20.142 2.14613 20.3088C2.40908 20.5 2.86435 20.5 3.77487 20.5H20.2246C21.1352 20.5 21.5904 20.5 21.8534 20.3088C22.0827 20.142 22.2305 19.8857 22.2599 19.6037C22.2936 19.2803 22.0655 18.8863 21.6093 18.0983L13.3844 3.89171C12.9299 3.10654 12.7026 2.71396 12.4061 2.58211C12.1474 2.4671 11.8521 2.4671 11.5935 2.58211C11.2969 2.71396 11.0696 3.10655 10.6151 3.89171Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</Stack>
							{errorMsg ? (
								<Typography className={`${styles.alertText}`}>
									{errorMsg}
								</Typography>
							) : (
								<>
									<Typography className={`${styles.alertText}`}>
										Oops, this file is too large and cannot be uploaded.
									</Typography>
									<Typography className={`${styles.alertText}`}>
										Please reduce the size of the file below 10MB and try again.
									</Typography>
								</>
							)}

						</Stack>

					</Stack>

					<div className={`${styles.narrowDivider} horizontalDivider`}></div>
					<Stack direction={"row"} justifyContent='end'>
						<PrimaryDangerButton onClick={handleAlertClose} className={`${styles.button} regular`}> OK </PrimaryDangerButton>
					</Stack>
				</Stack>
			</CustomizedModal>
			<CustomizedModal open={confirmOpen} handleClose={() => { }} width={600} >
				<Stack direction={"column"}>
					<Stack className={`${styles.confirmTextBox}`} direction={"column"}>
						<Typography className={`${styles.confirmTitle}`}>Your request has successfully been submitted!</Typography>
						<Typography className={`${styles.alertText}`}>
							We will get back to you shortly.
						</Typography>
					</Stack>
					<div className={`${styles.narrowDivider} horizontalDivider`}></div>
					<Stack direction={"row"} justifyContent='end'>
						<PrimaryButton onClick={handleConfirmClose} className={`${styles.button} regular`}> OK </PrimaryButton>
					</Stack>
				</Stack>
			</CustomizedModal>
		</>
	);
};

export default memo(HelpCentrePanel);