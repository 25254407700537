import { useEffect, useState } from 'react';
import { Stack, Breadcrumbs } from "@mui/material";
import CustomizedPaper from "../../components/CustomizedPaper";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../redux";
import pxToRem from "../../helpers/pxToRem";
import AlertComponent from "../../components/AlertComponent";
import { betaStateAction } from "../../redux/betaSlice";
import { BetaButton } from "../../components/Buttons/ButtonUI";
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { setErrorMessage } from '../../redux/pageStatusSlice';


interface IDashboardContainer {
    breadCrumbs: React.ReactNode[];
    children: React.ReactNode;
}

const DashboardContainer = ({ breadCrumbs, children }: IDashboardContainer) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [errorMsg, setErrorMsg] = useState("");
    const errorMsg = useSelector((state: RootState) => state.pageStatus.errorMessage);

    const orgState = useSelector((state: RootState) => state.organisation.orgState);

    return (
        <>
            <AlertComponent visible={errorMsg !== undefined} handleClose={() => { dispatch(setErrorMessage(undefined)) }} severity="error" title="Error" message={errorMsg || "Unexpected Error occured!"} />
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={styles.breadCrumbsLink}>
                    {breadCrumbs}
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => { if (orgState === 'REGULAR') { navigate('/pricing') } else { dispatch(betaStateAction.setBetaState(true)) } }} />
            </Stack>

            <CustomizedPaper className={styles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    {children}
                </Stack>
            </CustomizedPaper>
        </>
    );
}

export default DashboardContainer;