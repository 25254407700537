import { Button, IconButton, Modal, Stack } from "@mui/material";
import { useState } from "react";
import styles from "./PendingCard.module.scss"
import pxToRem from "../../../helpers/pxToRem";
import ResponseTable from "./tableParts/ResponseTable";
import ApprovedTable from "./tableParts/ApprovedTable";

interface IApprovedAlumni {
    approvedCount?: number;
}

const ApprovedAlumni = ({approvedCount}: IApprovedAlumni) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <Button style={{color: styles.neutrals500}} onClick={openModal}><small>View</small></Button>
            <Modal open={isModalOpen}>
                <Stack className={styles.tableModal}>
                    <IconButton className={styles.rejectCloseButton} onClick={closeModal}>
                        <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18M6 6L18 18" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                    <Stack mb={pxToRem(12)} gap={pxToRem(12)}>
                        <p className={styles.pendingTitle}>Approved Alumni (Total: {approvedCount})</p>
                        <p className={styles.pendingSubtitle}>Click on an alumni to view their profile</p>
                    </Stack>
                    <ApprovedTable approvedCount={approvedCount}/>
                </Stack>
            </Modal>
        </>
    )
}

export default ApprovedAlumni;