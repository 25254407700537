import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FieldValue } from "firebase/firestore";
import { Network } from "../firebase/types-network";
import { NetworkTable } from "../pages/NetworkPage";


// Helper functions to format network data
export const formatName = (firstName: string | undefined, lastName: string | undefined): string => {
    return [firstName, lastName].filter(Boolean).join(" ");
};

export const formatEmployment = (jobs?: Array<{ id: string; jobTitle: string }> | undefined): string => {
    // This function maps over the 'jobs' array, extracting the 'jobTitle' and joining them with a comma.
    return jobs ? jobs.map(job => job.jobTitle).join(", ") : "";
};

export const formatEducation = (degrees?: Array<{ id: string; degree: string }> | undefined): string => {
    // Similar to the above, but for 'degrees', extracting the 'degree' field.
    return degrees ? degrees.map(degree => degree.degree).join(", ") : "";
};

export const formatLiving = (suburb: string | undefined, city: string | undefined, country: string | undefined): string => {
    return [suburb, city, country].filter(Boolean).join(", ") ?? "-";
};

interface INetworkDataStateSlice {
    networkList: NetworkTable[];
}

const INITIAL_STATE: INetworkDataStateSlice = {
    networkList: []
}

export const networkDataStateSlice = createSlice({
    name: "networkState",
    initialState: INITIAL_STATE,
    reducers: {
        setNetworkList: (state, action: PayloadAction<NetworkTable[]>) => {
            state.networkList = action.payload;
        },
        addNetwork: (state, action: PayloadAction<NetworkTable>) => {
            // Ensure action.payload includes a `objectID` from Firebase
            state.networkList.push(action.payload);
        },
        updateNetwork: (state, action: PayloadAction<NetworkTable>) => {
            // Ensure action.payload includes a `objectID`
            const index = state.networkList.findIndex(network => network.objectID === action.payload.objectID);
            if (index !== -1) {
                state.networkList[index] = action.payload;
            }
        },
        removeNetwork: (state, action: PayloadAction<string>) => {
            // action.payload is the `objectID` to be removed
            state.networkList = state.networkList.filter(network => network.objectID !== action.payload);
        },
        resetNetworkState: (state) => {
            state.networkList = [];
        }
    }
});

export const {
    setNetworkList,
    addNetwork,
    updateNetwork,
    removeNetwork,
    resetNetworkState
} = networkDataStateSlice.actions;
export default networkDataStateSlice.reducer;
