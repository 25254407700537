import React, { useEffect, useState } from 'react';
import { Box, Stack, styled, Typography, linearProgressClasses, LinearProgress } from '@mui/material';
import pxToRem from '../../helpers/pxToRem';
import LogoPath from "../../assets/images/logo/logo512.png"

interface LoadingComponentProps {
  isLoading: boolean;
  loadingStr?: string;
  isDelete?: boolean;
}

const LoadingOverlay = styled('div')(({ theme }) => ({
  zIndex: 10000,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: pxToRem(20),
}));

const ImageContainer = styled('div')(({ theme }) => ({
  marginBottom: pxToRem(20), // Adjust based on your design needs
  // Define the shining animation
  '@keyframes shine': {
    '0%': { filter: 'brightness(100%)' },
    '50%': { filter: 'brightness(90%)' }, // Increase brightness to 150%
    '100%': { filter: 'brightness(100%)' },
  },
  '& img': {
    animation: 'shine 0.8s ease-in-out infinite', // Apply the animation to the image
  },
}));


const LoadingBarContainer = styled('div')({
  width: '50%',
  backgroundColor: '#A3A3A3',
  borderRadius: pxToRem(20),
  display: 'flex',
  alignItems: 'center',
});

const StyledLoadingBar = styled(LinearProgress)({
  width: '100%',
  height: pxToRem(16),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
    borderRadius: pxToRem(10),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: pxToRem(10),
    backgroundColor : '#549189',
    boxShadow: '0px 4px 4px 0px #00000040', // Apply shadow here
  },
});

const LoadingStatusBar: React.FC<LoadingComponentProps> = ({ isLoading, loadingStr, isDelete }) => {
  const [currentSegment, setCurrentSegment] = useState('');
  const [percentage, setPercentage] = useState(0);

    // Determine the color based on isDelete flag
    const barColor = isDelete ? '#EA6760' : '#549189';

  useEffect(() => {
    // Reset states when isLoading changes to ensure progress bar starts from 0%
    if (!isLoading) {
      setCurrentSegment('');
      setPercentage(0);
    }

    if (loadingStr && isLoading) {
      const segments = loadingStr.split('-');
      const segmentDuration = 100 / segments.length;
      
      let index = 0;
      setCurrentSegment(segments[index]); // Display the first segment immediately
      setPercentage(segmentDuration);

      const intervalId = setInterval(() => {
        index = index < segments.length - 1 ? index + 1 : segments.length - 1;
        setCurrentSegment(segments[index]);
        setPercentage((index + 1) * segmentDuration); // Update percentage based on current segment
      }, 200);

      return () => {
        clearInterval(intervalId);
        // Optionally, reset percentage and segment here as well if needed for your logic
      };
    }
  }, [loadingStr, isLoading]); // Adding isLoading to the dependency array

  if (!isLoading) return null;

  return (
    <LoadingOverlay>
       <ImageContainer>
              <img src={LogoPath} alt="Loading" style={{ maxHeight: pxToRem(172), height: pxToRem(172) }} />
            </ImageContainer>
      <LoadingBarContainer>
      <StyledLoadingBar 
          variant="determinate" 
          value={percentage} 
          sx={{
            '& .MuiLinearProgressBar': { backgroundColor: barColor },
          }}
          />
      </LoadingBarContainer>
      <small style={{ marginTop: pxToRem(20), color: "#A3A3A3" }}>
              {currentSegment}
            </small>
    </LoadingOverlay>
  );
};

export default LoadingStatusBar;
