import { FormControl, FormGroup, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton, PrimaryQRFormButton } from "../../../components/Buttons/ButtonUI";
import { EMPTY_INPUT_HELPER_MSG, YEAR_ENROLLED_BEFORE_PEER_YEAR } from "../../../helpers/shared";
import { RootState } from "../../../redux";
import { setCurrentCampus, setCurrentPeerYear, setCurrentSchoolHouse, setCurrentStep, setCurrentYearEnrolled } from "../../../redux/registrationSlice";
import { REG_PAGES } from "../constants";
import styles from "../index.module.scss";
import { QRFormsFormsControl } from "..";
import pxToRem from "../../../helpers/pxToRem";



const SchoolingHistory = () => {
    const dispatch = useDispatch();
    const alumniData = useSelector((state:RootState) => state.alumniReg);
    const step = useSelector((state:RootState) => state.alumniReg.step);
    const campuses = useSelector((state:RootState) => state.alumniReg.campuses);
    const houses = useSelector((state:RootState) => state.alumniReg.schoolHouses);
    const defaultPeerYear = useSelector((state:RootState) => state.alumniReg.orgInfo.defaultPeerYear);

    const [campus, setCampus] = useState(alumniData.campus || "");
    const [house, setHouse] = useState(alumniData.schoolHouse || "");
    const [peerYear, setPeerYear] = useState<Moment | null>(moment(alumniData.peerYear, "YYYY") || null);
    const [yearEnrolled, setYearEnrolled] = useState<Moment | null>(moment(alumniData.yearEnrolled,"YYYY") || null);

    // const [campusIsEmpty, setCampusIsEmpty] = useState(false);
    // const [houseIsEmpty, setHouseIsEmpty] = useState(false);
    const [peerYearIsEmpty, setPeerYearIsEmpty] = useState(false);
    const [yearEnrolledIsEmpty, setYearEnrolledIsEmpty] = useState(false);

    const [yearEnrolledBeforePeerYear, setYearEnrolledBeforePeerYear] = useState(false);

    const handleNavigation = (step: number) => {
        dispatch(setCurrentStep(step));
    }

    useEffect(() => {
        if(!alumniData.inProgress) {
            handleNavigation(REG_PAGES.FRONT_PAGE);
        }
        
        if(!alumniData.peerYear) {
            if(defaultPeerYear) setPeerYear(moment(defaultPeerYear,"YYYY"));
            else {
                setPeerYear(moment((new Date()).getFullYear(), "YYYY"));
            }
        }
        // if(defaultPeerYear && !peerYear) {
        //     setPeerYear(moment(defaultPeerYear,"YYYY"));
        // } else {
        //     setPeerYear(moment((new Date()).getFullYear(), "YYYY"));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleData = async () => {
        var hasError = false;
        // if(!campus) {
        //     // hasError = true;
        //     setCampusIsEmpty(true);
        // }
        // if(!house) {
        //     // hasError = true;
        //     setHouseIsEmpty(true);
        // }
        if(!peerYear?.isValid()) {
            hasError = true;
            setPeerYearIsEmpty(true);
        }
        if(!yearEnrolled?.isValid()) {
            hasError = true;
            setYearEnrolledIsEmpty(true);
        }
        if(peerYear && yearEnrolled && yearEnrolled > peerYear) {
            hasError = true;
            setYearEnrolledBeforePeerYear(true);
        }
        if(hasError) {
            return;
        }
        
        dispatch(setCurrentCampus(campus));
        dispatch(setCurrentSchoolHouse(house));
        dispatch(setCurrentYearEnrolled(parseInt(moment(yearEnrolled,"YYYY").format("YYYY"))));
        dispatch(setCurrentPeerYear(parseInt(moment(peerYear,"YYYY").format("YYYY"))))
        handleNext()
    }

    const handleNext = () => {
        if(step) {
            dispatch(setCurrentStep(step+1))
        }
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleData();
    }

    const getYearEnrolledErrorMsg = (hasError: boolean):string => {
        if(yearEnrolledBeforePeerYear) {
            return YEAR_ENROLLED_BEFORE_PEER_YEAR;
        }
        if(hasError) {
            return EMPTY_INPUT_HELPER_MSG;
        }
        return "";
    }

    return(
        <>
            <Stack className={styles.formContainer} spacing={3} direction="column"> 
                <form id="schooling-history-form" onSubmit={handleFormSubmit} style={{width: "auto"}}>
                    <FormGroup>
                        <Stack spacing={1.5}>
                            {(campuses && campuses.length > 0) &&
                                <QRFormsFormsControl variant="standard">
                                    <InputLabel id="standard-label">Campus</InputLabel>
                                    <Select
                                        labelId="standard-label"
                                        id="demo-simple-select-standard"
                                        value={campus}
                                        onChange={(e)=> {setCampus(e.target.value)}}
                                        label="Campus"
                                    >
                                        {campuses?.map( item =>
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </QRFormsFormsControl>
                            }

                            {(houses && houses.length > 0) &&
                                <QRFormsFormsControl variant="standard">
                                    <InputLabel id="standard-label">School House</InputLabel>
                                    <Select
                                        labelId="standard-label"
                                        id="demo-simple-select-standard"
                                        value={house}
                                        onChange={(e)=> {setHouse(e.target.value)}}
                                        label="School House"
                                    >
                                        {houses?.map( item =>
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </QRFormsFormsControl>
                            }
                        <DatePicker 
                            slotProps={{ textField: {
                                variant: "standard",
                                error: (peerYearIsEmpty),
                                helperText: (peerYearIsEmpty && EMPTY_INPUT_HELPER_MSG)
                            }}} 
                            views={["year"]}
                            label="Peer Year"
                            value={peerYear}
                            defaultValue={peerYear}
                            onChange={(e) => {console.log(peerYearIsEmpty); setPeerYear(e); setPeerYearIsEmpty(false)}}
                            minDate={moment(1900, "YYYY")}
                            maxDate={moment(new Date().getFullYear(), "YYYY")}
                            sx={{"& label.Mui-focused": {
                                color:  styles.primary400,
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#B2BAC2",
                              },
                            
                              "& .MuiInput-root": {
                                "&::after": {
                                    borderBottom: `2px solid ${styles.primary300}`
                                },
                                "&::before": {
                                    borderBottom: `1px solid ${styles.neutrals400}`
                                },
                                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                    borderBottom: `1px solid ${styles.primary300}`,
                                },
                                '&.Mui-focused:after': {
                                    borderBottom: `2px solid ${styles.primary300}`,
                                },
                              },}}
                            />
                        <DatePicker 
                            slotProps={{ textField: {
                                variant: "standard", 
                                error: (yearEnrolledIsEmpty || yearEnrolledBeforePeerYear), 
                                helperText: getYearEnrolledErrorMsg(yearEnrolledIsEmpty) }}} 
                            views={["year"]}
                            label="Year Enrolled"
                            value={yearEnrolled}
                            onChange={(e) => {setYearEnrolled(e); setYearEnrolledIsEmpty(false)}}
                            minDate={moment(1900, "YYYY")}
                            maxDate={moment(peerYear,"YYYY")}
                            sx={{"& label.Mui-focused": {
                                color:  styles.primary400,
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "#B2BAC2",
                            },
                            
                            "& .MuiInput-root": {
                                "&::after": {
                                    borderBottom: `2px solid ${styles.primary300}`
                                },
                                "&::before": {
                                    borderBottom: `1px solid ${styles.neutrals400}`
                                },
                                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                    borderBottom: `1px solid ${styles.primary300}`,
                                },
                                '&.Mui-focused:after': {
                                    borderBottom: `2px solid ${styles.primary300}`,
                                },
                            },}}
                            
                            />
                            </Stack>
                    </FormGroup>
                </form>
            </Stack>
            <PrimaryQRFormButton className={styles.nextButton} form="schooling-history-form" type="submit">Next: Extra Details</PrimaryQRFormButton>
        </>
    );
}

export default SchoolingHistory;