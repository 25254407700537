import { IconButton } from "@mui/material";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { QRCodeCanvas } from "qrcode.react";
import { useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import AlertComponent from "../../../components/AlertComponent";
import { checkPendingCount } from "../../../firebase/registrationApis";
import pxToRem from "../../../helpers/pxToRem";
import { RootState } from "../../../redux";
import { URL_PARAMS } from "../../../routers/shared";
import { FormDetails, FormsData, formatFormURL, handleLinkClick, handleQrCodeClick } from "../helpers";
import styles from "../index.module.scss";
import FormModal from "./modals/FormModal";
import OperationModals, { Operation } from "./modals/OperationModals";
import FormMenu from "./tableParts/FormMenu";
import FormsTable from "./tableParts/FormsTable";
import { PrimaryButton } from "../../../components/Buttons/ButtonUI";
import { PermissionState } from "../../../firebase/types-organisations";

const ISACTIVE = true;
interface IActiveForms {
    handleDeleteForm: (id: string, name: string) => void;
    linkCopied: ()=>void;
    qrDownloaded: ()=>void;
}

const ActiveForms = ({handleDeleteForm, linkCopied, qrDownloaded}: IActiveForms) => {
    
    const navigate = useNavigate();
    const params = useParams();
    const {orgId} = params;

    const organisation = useSelector((state:RootState) => state.organisation);
    const [errorMsg, setErrorMsg] = useState("");
    const [selectedFormId, setSelectedFormId] = useState<string>();
    const [selectedFormName, setSelectedFormName] = useState<string>();

    const formsPermission = useSelector((state:RootState) => state.globalState.permissions?.forms)

    const [openAddForm, setOpenAddForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openCloseForm, setOpenCloseForm] = useState(false);
    const [openCloseActionForm, setOpenCloseActionForm] = useState(false);
    
    const [totalForms, setTotalForms] = useState<number>();
    const [totalSubmissions, setTotalSubmissions] = useState<number|null>()

    const columnHelper = createColumnHelper<FormsData>();

    const rowClick = (id:string, details: FormDetails): void => {
        if(id){ 
            navigate(
                `/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.SELF_REGISTRATION_FORMS}/${id}`,
                {
                    state: {
                        isActive: true
                    }
                }
        ); }
    }

    const handleModalClose = () => {
        setOpenAddForm(false);
    };

    const handleEditModalOpen = (id: string) => {
        setSelectedFormId(id);
        setOpenEditForm(true);
    }

    const handleEditModalClose = () => {
        setSelectedFormId(undefined);
        setOpenEditForm(false);
    }

    const handleCloseModalOpen = async (id: string, name: string) => {
        // setState is an async function - batches write
        setSelectedFormId(id);
        setSelectedFormName(name);
        if(!orgId) return;
        const res = await checkPendingCount(orgId, id);
        if(res.code === 200) {
            setTotalSubmissions(res.data);
            if(res.data <= 0) {
                setOpenCloseForm(true);
            }
            else {
                setOpenCloseActionForm(true);
            };
        }
    }

    const columns: ColumnDef<any, any>[]  = useMemo(()=> [ 
        columnHelper.accessor('name', { cell: info => <p style={{color: styles.shades100}}>{info.getValue()}</p>, header: "Form" }), 
        columnHelper.accessor('link', {
            cell: info => (
                <IconButton onClick={(e)=>{
                    handleLinkClick(e, info.getValue());
                    linkCopied();
                }}>
                    <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.9961 12V9.2C19.9961 8.0799 19.9961 7.51984 19.7781 7.09202C19.5864 6.71569 19.2804 6.40973 18.9041 6.21799C18.4763 6 17.9162 6 16.7961 6H9.19609C8.07599 6 7.51594 6 7.08811 6.21799C6.71179 6.40973 6.40583 6.71569 6.21408 7.09202C5.99609 7.51984 5.99609 8.0799 5.99609 9.2V16.8C5.99609 17.9201 5.99609 18.4802 6.21408 18.908C6.40583 19.2843 6.71179 19.5903 7.08811 19.782C7.51594 20 8.07599 20 9.19609 20H11.9961M15.1961 26H22.7961C23.9162 26 24.4763 26 24.9041 25.782C25.2804 25.5903 25.5864 25.2843 25.7781 24.908C25.9961 24.4802 25.9961 23.9201 25.9961 22.8V15.2C25.9961 14.0799 25.9961 13.5198 25.7781 13.092C25.5864 12.7157 25.2804 12.4097 24.9041 12.218C24.4763 12 23.9162 12 22.7961 12H15.1961C14.076 12 13.5159 12 13.0881 12.218C12.7118 12.4097 12.4058 12.7157 12.2141 13.092C11.9961 13.5198 11.9961 14.0799 11.9961 15.2V22.8C11.9961 23.9201 11.9961 24.4802 12.2141 24.908C12.4058 25.2843 12.7118 25.5903 13.0881 25.782C13.5159 26 14.076 26 15.1961 26Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                </IconButton>
            ),
            header: "Copy Link",
            size: 134
        }),
        columnHelper.accessor('qrCode', { 
            cell: info => (
                <>
                    <IconButton onClick={(e)=>{
                        handleQrCodeClick(e, info.getValue(), info.row.original.name);
                        qrDownloaded();
                    }}>
                        <svg width={pxToRem(32)} height={pxToRem(32)} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.9961 19V20.2C24.9961 21.8802 24.9961 22.7202 24.6691 23.362C24.3815 23.9265 23.9226 24.3854 23.3581 24.673C22.7163 25 21.8763 25 20.1961 25H11.7961C10.1159 25 9.27586 25 8.63412 24.673C8.06964 24.3854 7.61069 23.9265 7.32307 23.362C6.99609 22.7202 6.99609 21.8802 6.99609 20.2V19M20.9961 14L15.9961 19M15.9961 19L10.9961 14M15.9961 19V7" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </IconButton>
                    <div style={{display:"none"}}>
                        <QRCodeCanvas id={info.getValue()} value={formatFormURL(info.getValue())}/>
                    </div>
                </>
            ),
            header: "QR Code",
            size: 134
        }),
        columnHelper.accessor('totalCount', { cell: info => info.getValue(), header: "Total Responses", size:100 }), 
        columnHelper.accessor('pendingApproval', { cell: info => info.getValue(), header: "Pending Approval", size:93 }), 
        columnHelper.accessor('createdAt', { cell: info => info.getValue(), header: "Created", size:99 }), 
        columnHelper.accessor('lastSubmission', { cell: info => info.getValue(), header: 'Last Submission', size:104 }),
        ...formsPermission && formsPermission === PermissionState.EDITOR ? [
            columnHelper.display({
                id: 'options',
                cell: ({ row }) => (
                    <FormMenu
                        isActive={ISACTIVE}
                        handleEditForm={()=>{handleEditModalOpen(row.original.id)}}
                        handleCloseForm={()=>{handleCloseModalOpen(row.original.id, row.original.name)}}
                        handleDeleteForm={()=>{handleDeleteForm(row.original.id, row.original.name)}}
                    />
                ),
                size: 37,
            })
        ] : []
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    
    return (
        <>
            <AlertComponent visible={errorMsg !== ""} handleClose={function (): void { setErrorMsg(""); }} severity="error" title="Error" message={errorMsg} />
            <FormModal
                isOpen={openEditForm}
                formId={selectedFormId}
                handleClose={handleEditModalClose}
                orgName={organisation.organisation?.name || ""}
            />
            <FormModal 
                isOpen={openAddForm}
                handleClose={handleModalClose} 
                orgName={organisation.organisation?.name || ""}
            />
            <OperationModals
                operation={Operation.CLOSE}
                totalSubmissions={totalSubmissions || 0}
                openActionModal={openCloseActionForm}
                closeActionModal={()=>setOpenCloseActionForm(false)}
                openNoActionModal={openCloseForm}
                closeNoActionModal={()=>setOpenCloseForm(false)}
                orgId={orgId}
                formId={selectedFormId}
                formName={selectedFormName}

            />
            <p className={styles.formTableTitle}>Active QR Codes Total ({totalForms})</p>
            {formsPermission && formsPermission === PermissionState.EDITOR &&
                <PrimaryButton className={styles.createFormButton} onClick={()=>setOpenAddForm(true)}>Create New Form</PrimaryButton>
            }
            <FormsTable 
                rowClick={rowClick} 
                handleEditForm={handleEditModalOpen}
                handleCloseForm={handleCloseModalOpen}    
                handleDeleteForm={handleDeleteForm}
                isActive={ISACTIVE}
                columns={columns}
                countCallback={(count:number) => {setTotalForms(count)}}
            />
        </>
    );
}

export default ActiveForms;