import { collection, onSnapshot, orderBy, query, Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { Education, Employment, getEducation, getEmployment, getNote, getSchooling, Note, Schooling } from '../../../firebase/types-alumni';
import { arrayToString } from '../../../helpers/transfer';
import { durationCalculationStr, formatEducationLabel } from '../../../helpers/utils';
import { TEducation, TEducationRow } from '../tabs/EducationTab';
import { TEmployment, TEmploymentRow } from '../tabs/EmploymentTab';
import { TSchooling, TSchoolingRow } from '../tabs/SchoolingTab';

type AlumniDetailsTabsHooksProps = {
  oid: string | undefined; // Organisation ID
  alumniId: string | undefined;
  orgName?: string;
}

function AlumniDetailsTabsHooks({ oid, alumniId, orgName }: AlumniDetailsTabsHooksProps) {
    const [schoolingRows, setSchoolingRows] = useState<TSchoolingRow[]>([]);
    const [employmentRows, setEmploymentRows] = useState<TEmploymentRow[]>([]);
    const [educationRows, setEducationRows] = useState<TEducationRow[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const getSchoolingType = (type:string):string => {
        if(type === "PRIMARY") return "Primary";
        else if(type === "SECONDARY") return "Secondary";
        else if(type === "PRIMARY_SECONDARY") return "Primary & Secondary";
        return ""
    }
    const convertWorkTypeToStr = (code: string):string => {
        switch(code){
            case "FULL_TIME": return "Full-time";
            case "PART_TIME": return "Part-time";
            case "CASUAL": return "Casual";
            case "CONTRACTOR": return "Contractor";
            case "INTERN": return "Intern";
            default: return"";
        }
    }

    // Sorting function
    // const sortEducationRows = (rows: TEducationRow[]): TEducationRow[] => {
    //     // Separate rows with and without end year
    //     const withEndYear = rows.filter(row => row.education?.endOrExpectedYear !== undefined);
    //     const withoutEndYear = rows.filter(row => row.education?.endOrExpectedYear === undefined);
    //     // Sort rows with end year by descending order
    //     withEndYear.sort((a, b) => {
    //         const aYear = a.education?.endOrExpectedYear ?? 0;
    //         const bYear = b.education?.endOrExpectedYear ?? 0;
    //         return bYear - aYear;
    //     });
    
    //     // Sort rows without end year by creation date
    //     withoutEndYear.sort((a, b) => {
    //     const aDate = a.education?.created_at as number ?? 0;
    //     const bDate = b.education?.created_at as number ?? 0;
    //     return aDate - bDate;
    //     });
    //     return [...withEndYear, ...withoutEndYear];
    // };
    const sortEducationRows = (rows: TEducationRow[]): TEducationRow[] => {
        return rows.sort((a, b) => {
            const yearA = a.education?.endOrExpectedYear?.toLocaleString() ?? "";
            const yearB = b.education?.endOrExpectedYear?.toLocaleString() ?? "";
            const monthA = a.education?.endOrExpectedMonth?.toLocaleString() ?? "";
            const monthB = b.education?.endOrExpectedMonth?.toLocaleString() ?? "";

            if(yearA === "") {
                return -1;
            }
            if(yearB === "") {
                return 1;
            }

            if(yearA === yearB) {
                return monthB.localeCompare(monthA);
            }

            return yearB.localeCompare(yearA);
        });
    };

    // const sortEmployments = (employmentRows: TEmploymentRow[]): TEmploymentRow[] => {
    //     return employmentRows.sort((a, b) => {
    //       const employmentA: Employment = a.employment!;
    //       const employmentB: Employment = b.employment!;
    //       // Check for current employment and sort by most recent
    //       if (employmentA.stillInRole && employmentB.stillInRole) {
    //         return (employmentB.startedYear ?? 0) - (employmentA.startedYear ?? 0) || 
    //                (employmentB.startedMonth ?? 0) - (employmentA.startedMonth ?? 0);
    //       } else if (employmentA.stillInRole) {
    //         return -1;
    //       } else if (employmentB.stillInRole) {
    //         return 1;
    //       }
    //       // Sort previous employment by most recent
    //       const endComparison = (employmentB.endYear ?? 0) - (employmentA.endYear ?? 0) || 
    //                             (employmentB.endMonth ?? 0) - (employmentA.endMonth ?? 0);
    //       if (endComparison !== 0) {
    //         return endComparison;
    //       }
    //       // Employments without year at the bottom
    //       if (employmentA.startedYear == null && employmentB.startedYear == null) {
    //         return 0; // Maintain original order if both don't have a year
    //       } else if (employmentA.startedYear == null) {
    //         return 1;
    //       } else if (employmentB.startedYear == null) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //   };

    const sortEmployments = (employmentRows: TEmploymentRow[]): TEmploymentRow[] => {
        return employmentRows.sort((a, b) => {
            const employmentA = a.employment;
            const employmentB = b.employment;
    
            const noDatesA = !employmentA?.startedYear && !employmentA?.endYear;
            const noDatesB = !employmentB?.startedYear && !employmentB?.endYear;
    
            const stillInRoleA = employmentA?.stillInRole;
            const stillInRoleB = employmentB?.stillInRole;
    
            // First, prioritize jobs with no start and end dates that are not 'still in role'
            if (noDatesA && !stillInRoleA && noDatesB && !stillInRoleB) return 0; // Both equally prioritized
            if (noDatesA && !stillInRoleA) return -1;
            if (noDatesB && !stillInRoleB) return 1;
    
            // Next, prioritize jobs with no start and end dates that are 'still in role'
            if (noDatesA && stillInRoleA && noDatesB && stillInRoleB) return 0; // Both equally prioritized
            if (noDatesA && stillInRoleA) return -1;
            if (noDatesB && stillInRoleB) return 1;
    
            // Then, 'still in role' jobs are prioritized over ended jobs
            if (stillInRoleA && !stillInRoleB) return -1;
            if (!stillInRoleA && stillInRoleB) return 1;
    
            // For 'still in role' jobs, sort by start date, with earlier jobs listed first
            if (stillInRoleA && stillInRoleB) {
                const startYearDiff = (employmentB?.startedYear ?? 0) - (employmentA?.startedYear ?? 0);
                return startYearDiff; // Ascending by start year
            }
    
            // For jobs that have ended, prioritize by most recent start date, then by end date
            // Adjusted sorting logic for jobs that have ended
            if (!stillInRoleA && !stillInRoleB) {
                // First, sort by the most recent end date
                const endYearDiff = (employmentB?.endYear ?? 0) - (employmentA?.endYear ?? 0);
                if (endYearDiff !== 0) return endYearDiff;

                // If end years are the same, sort by the earliest start date
                return (employmentA?.startedYear ?? 0) - (employmentB?.startedYear ?? 0);
            }
            return 0; // Fallback
        });
    };
    
    

    const sortSchoolings = (schoolingRows: TSchoolingRow[]): TSchoolingRow[] => {
        return schoolingRows.sort((a, b) => {
            const schoolingA = a.schooling as Schooling;
            const schoolingB = b.schooling as Schooling;
            // Check for current organization
            if (schoolingA?.schoolOption === 'CURRENT' && schoolingB?.schoolOption !== 'CURRENT') { return -1; }
            if (schoolingB?.schoolOption === 'CURRENT' && schoolingA?.schoolOption !== 'CURRENT') { return 1; }
            // Convert Firestore Timestamp to JavaScript Date and then to Moment.js object
            const createdAtA = schoolingA?.created_at instanceof Timestamp ? moment(schoolingA.created_at.toDate()) : null;
            const createdAtB = schoolingB?.created_at instanceof Timestamp ? moment(schoolingB.created_at.toDate()) : null;
            if (!createdAtA) return 1;
            if (!createdAtB) return -1;
            return createdAtA.diff(createdAtB);
        });
    };
        

    useEffect(() => {
        if (oid && alumniId && orgName) {
            const schoolingCollectionRef = collection(db, "organisations", oid, "alumni", alumniId, 'schooling');
            const schoolingCollectionQuery = query(schoolingCollectionRef, orderBy('updated_at', "desc"));
            const unsubscribeAlumniSchooling = onSnapshot(schoolingCollectionQuery, (querySnapshot) => {
                setSchoolingRows(prevSchooling => {
                    const updatedSchooling = [...prevSchooling];
                    querySnapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        const id = change.doc.id;
                        const schooling: Schooling = getSchooling(data);
                        switch (change.type) {
                            case 'added': {
                                const tschoolinArrNew: TSchooling[] = [];
                                const durationValue = durationCalculationStr(schooling.yearStarted?.toString(), schooling.peerYear?.toString(), schooling.yearLeft?.toString());
                                console.log("= == = = ? DURCATION VLUE CHECL", durationValue)
                                if(schooling.schoolOption === "CURRENT") { tschoolinArrNew.push({ id: id + "_1", label: 'School', value: orgName ?? "", type: 'DataInitial' }); }
                                else { tschoolinArrNew.push({ id: id + "_1", label: 'School', value: schooling.schoolName, type: 'DataInitial' }); }
                                if(schooling.schoolCampus !== undefined && schooling.schoolCampus !== "") tschoolinArrNew.push({ id: id + "_3", label: 'Campus', value: schooling.schoolCampus, type: 'Data' });
                                if(schooling.type !== undefined && schooling.type !== "") tschoolinArrNew.push({ id: id + "_2", label: 'Type', value: getSchoolingType(schooling.type), type: 'Data' });
                                if(schooling.peerYear !== undefined) tschoolinArrNew.push({ id: id + "_5", label: 'Peer Year', value: schooling.peerYear+"", type: 'Data' });
                                if(schooling.yearStarted !== undefined) tschoolinArrNew.push({ id: id + "_4", label: 'Year Enrolled', value: schooling.yearStarted+"", type: 'Data' });
                                if(schooling.yearLeft !== undefined) tschoolinArrNew.push({ id: id + "_11", label: 'Year Left', value: schooling.yearLeft+"", type: 'Data' });
                                if (durationValue) { tschoolinArrNew.push({ id: id + "_7", label: 'Duration', value: durationValue, type: 'Data', }); }
                                if(schooling.schoolHouse !== undefined && schooling.schoolHouse !== "") tschoolinArrNew.push({ id: id + "_6", label: 'School House', value: schooling.schoolHouse, type: 'Data' });
                                // if (durationValue.trim() !== '0' && durationValue.trim() !== '0 Years' && durationValue.trim() !== '0 Year') {
                                //     tschoolinArrNew.push({ id: id + "_7", label: 'Duration', value: durationValue, type: 'Data', });
                                // }
                                if (tschoolinArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tschoolinArrNew.length - 1;
                                    if (tschoolinArrNew[lastItemIndex].type === "Data") {
                                        tschoolinArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    }
                                }
                                tschoolinArrNew.push({ id: id + "_100", type: 'Space' });
                                updatedSchooling.push({ id, schooling: schooling, rows: tschoolinArrNew });
                                break;
                            }
                            case 'modified': {
                                const tschoolinArrNew: TSchooling[] = [];
                                const durationValue = durationCalculationStr(schooling.yearStarted?.toString(), schooling.peerYear?.toString(), schooling.yearLeft?.toString());
                                if(schooling.schoolOption === "CURRENT") tschoolinArrNew.push({ id: id + "_1", label: 'School', value: orgName ?? "", type: 'DataInitial' });
                                else tschoolinArrNew.push({ id: id + "_1", label: 'School', value: schooling.schoolName, type: 'DataInitial' });
                                if(schooling.schoolCampus !== undefined && schooling.schoolCampus !== "") tschoolinArrNew.push({ id: id + "_3", label: 'Campus', value: schooling.schoolCampus, type: 'Data' });
                                if(schooling.type !== undefined && schooling.type !== "") tschoolinArrNew.push({ id: id + "_2", label: 'Type', value: getSchoolingType(schooling.type), type: 'Data' });
                                if(schooling.peerYear !== undefined) tschoolinArrNew.push({ id: id + "_5", label: 'Peer Year', value: schooling.peerYear+"", type: 'Data' });
                                if(schooling.yearStarted !== undefined) tschoolinArrNew.push({ id: id + "_4", label: 'Year Enrolled', value: schooling.yearStarted+"", type: 'Data' });
                                if(schooling.yearLeft !== undefined) tschoolinArrNew.push({ id: id + "_11", label: 'Year Left', value: schooling.yearLeft+"", type: 'Data' });
                                if (durationValue) { tschoolinArrNew.push({ id: id + "_7", label: 'Duration', value: durationValue, type: 'Data', }); }
                                if(schooling.schoolHouse !== undefined && schooling.schoolHouse !== "") tschoolinArrNew.push({ id: id + "_6", label: 'School House', value: schooling.schoolHouse, type: 'Data' });
                                // if (durationValue.trim() !== '0' && durationValue.trim() !== '0 Years' && durationValue.trim() !== '0 Year') {
                                //     tschoolinArrNew.push({ id: id + "_7", label: 'Duration', value: durationValue, type: 'Data', });
                                // }

                                if (tschoolinArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tschoolinArrNew.length - 1;
                                    if (tschoolinArrNew[lastItemIndex].type === "Data") {
                                        tschoolinArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    }
                                }
                                tschoolinArrNew.push({ id: id + "_100", type: 'Space' });

                                const modifiedIndex = updatedSchooling.findIndex(item => item.id === id);
                                if (modifiedIndex !== -1) { updatedSchooling[modifiedIndex] ={ id, schooling: schooling, rows: tschoolinArrNew }; }
                                break;
                            }
                            case 'removed':
                                const removedIndex = updatedSchooling.findIndex(item => item.id === id);
                                if (removedIndex !== -1) { updatedSchooling.splice(removedIndex, 1); }
                                break;

                            default:
                                break;
                        }
                    });
                    const sortedSchooling = sortSchoolings(updatedSchooling) 
                    return sortedSchooling;
                });
            });

            const employmentCollectionRef = collection(db, "organisations", oid, "alumni", alumniId, 'employment');
            const employmentCollectionQuery = query(employmentCollectionRef, orderBy('updated_at', "desc"));
            const unsubscribeAlumniEmployment = onSnapshot(employmentCollectionQuery, (querySnapshot) => {
                setEmploymentRows(prevEmployment => {
                    let updatedEmployment = [...prevEmployment];
                    querySnapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        const id = change.doc.id;
                        let employment: Employment = getEmployment(data);
                        switch (change.type) {
                            case 'added': {
                                const tEmploymentArrNew: TEmployment[] = [];
                               // Calculate start and end strings
                                const startedEmployment = employment.startedYear ? 
                                (employment.startedMonth !== undefined ? 
                                    `${moment().month(employment.startedMonth - 1).format("MMM")} ${employment.startedYear}` : 
                                    `${employment.startedYear}`) :  "";
                                const endEmployment = employment.stillInRole ?  "Present" : 
                                (employment.endYear ? 
                                    (employment.endMonth !== undefined ? 
                                        `${moment().month(employment.endMonth - 1).format("MMM")} ${employment.endYear}` : 
                                        `${employment.endYear}`) : "");
                                 const duration = `${startedEmployment} - ${endEmployment}`;

                                // Separately add jobTitle, nameOfCompany, and duration to the array
                                tEmploymentArrNew.push({ id: id + "_1", label: "Job Title", value: employment.jobTitle, type: 'DataInitial' });
                                if(employment.nameOfCompany) {
                                    tEmploymentArrNew.push({ id: id + "_2", label: "Company", value: employment.nameOfCompany, type: 'Data' });
                                }
                                if(duration.trim() !== "-") {
                                    tEmploymentArrNew.push({ id: id + "_3", label: "Duration", value: duration, type: 'Data' });
                                }
                                // Continue adding other details as before
                                if(employment.employmentType !== undefined && employment.employmentType !== "") {
                                    tEmploymentArrNew.push({ id: id + "_4", label: "Type", value: convertWorkTypeToStr(employment.employmentType), type: 'Data' });
                                }
                                if(employment.industry !== undefined && employment.industry !== "") {
                                    tEmploymentArrNew.push({ id: id + "_5", label: "Field of Industry", value: employment.industry, type: 'Data' });
                                }
                                if(employment.suburb !== undefined && employment.suburb !== "") {
                                    tEmploymentArrNew.push({ id: id + "_6", label: "Location", value: employment.suburb, type: 'Data' });
                                }
                                if (tEmploymentArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tEmploymentArrNew.length - 1;
                                    if (tEmploymentArrNew[lastItemIndex].type === "Data") {
                                        tEmploymentArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    } else {
                                        tEmploymentArrNew[lastItemIndex].type = "DataOnly"; // Change type to 'DataEnd'
                                    }
                                }
                                tEmploymentArrNew.push({ id: id + "_100", type: 'Space' });
                                updatedEmployment.push({ id, employment: employment, rows: tEmploymentArrNew });
                                break;
                            }
                            case 'modified': {
                                const tEmploymentArrNew: TEmployment[] = [];
                                const startedEmployment = employment.startedYear ? 
                                (employment.startedMonth !== undefined ? 
                                    `${moment().month(employment.startedMonth - 1).format("MMM")} ${employment.startedYear}` : 
                                    `${employment.startedYear}`) :  "";
                                const endEmployment = employment.stillInRole ?  "Present" : 
                                (employment.endYear ? 
                                    (employment.endMonth !== undefined ? 
                                        `${moment().month(employment.endMonth - 1).format("MMM")} ${employment.endYear}` : 
                                        `${employment.endYear}`) : "");
                                const duration = `${startedEmployment} - ${endEmployment}`;

                                
                                // Separately add jobTitle, nameOfCompany, and duration to the array
                                tEmploymentArrNew.push({ id: id + "_1", label: "Job Title", value: employment.jobTitle, type: 'DataInitial' });
                                if(employment.nameOfCompany) {
                                    tEmploymentArrNew.push({ id: id + "_2", label: "Company", value: employment.nameOfCompany, type: 'Data' });
                                }
                                if(duration.trim() !== "-") {
                                    tEmploymentArrNew.push({ id: id + "_3", label: "Duration", value: duration, type: 'Data' });
                                }
                                
                                // Continue adding other details as before
                                if(employment.employmentType !== undefined && employment.employmentType !== "") {
                                    tEmploymentArrNew.push({ id: id + "_4", label: "Type", value: convertWorkTypeToStr(employment.employmentType), type: 'Data' });
                                }
                                if(employment.industry !== undefined && employment.industry !== "") {
                                    tEmploymentArrNew.push({ id: id + "_5", label: "Field of Industry", value: employment.industry, type: 'Data' });
                                }
                                if(employment.suburb !== undefined && employment.suburb !== "") {
                                    tEmploymentArrNew.push({ id: id + "_6", label: "Location", value: employment.suburb, type: 'Data' });
                                }
                                if (tEmploymentArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tEmploymentArrNew.length - 1;
                                    if (tEmploymentArrNew[lastItemIndex].type === "Data") {
                                        tEmploymentArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    } else {
                                        tEmploymentArrNew[lastItemIndex].type = "DataOnly"; // Change type to 'DataEnd'
                                    }
                                }
                                tEmploymentArrNew.push({ id: id + "_100", type: 'Space' });
                                
                                const modifiedIndex = updatedEmployment.findIndex(item => item.id === id);
                                if (modifiedIndex !== -1) {
                                    updatedEmployment[modifiedIndex] = { id, employment: employment, rows: tEmploymentArrNew };
                                }
                                break;
                            }
                            case 'removed':
                                const removedIndex = updatedEmployment.findIndex(item => item.id === id);
                                if (removedIndex !== -1) { updatedEmployment.splice(removedIndex, 1); }
                                break;

                            default:
                                break;
                        }
                    });
                    const sortedEmployment = sortEmployments(updatedEmployment)
                    console.log("sORT EMPLOYMENT CALLED")
                    return sortedEmployment;
                });
            });

            const noteCollectionRef = collection(db, "organisations", oid, "alumni", alumniId, 'notes');
            const noteCollectionQuery = query(noteCollectionRef, orderBy('updated_at', "desc"));
            const unsubscribeAlumniNote = onSnapshot(noteCollectionQuery, (querySnapshot) => {
                setNotes(prevNote => {
                    let updatedNote= [...prevNote];
                    querySnapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        const id = change.doc.id;
                        let note: Note = getNote(data);
                        switch (change.type) {
                            case 'added': {
                                updatedNote.push({ id, ...note });
                                break;
                            }
                            case 'modified': {
                                const modifiedIndex = updatedNote.findIndex(item => item.id === id);
                                if (modifiedIndex !== -1) { updatedNote[modifiedIndex] ={ id, ...note }; }
                                break;
                            }
                            case 'removed':
                                const removedIndex = updatedNote.findIndex(item => item.id === id);
                                if (removedIndex !== -1) { updatedNote.splice(removedIndex, 1); }
                                break;
                            default:
                                break;
                        }
                    });
                    const sortedNoteList = updatedNote.sort((a, b) => {
                        const aCreatedAt = typeof a.created_at === 'number' ? a.created_at : 0;
                        const bCreatedAt = typeof b.created_at === 'number' ? b.created_at : 0;
                        return bCreatedAt - aCreatedAt; 
                    });
                    return sortedNoteList;
                });
            });

            const educationCollectionRef = collection(db, "organisations", oid, "alumni", alumniId, 'education');
            const educationCollectionQuery = query(educationCollectionRef, orderBy('updated_at', "desc"));
            const unsubscribeAlumniEducation = onSnapshot(educationCollectionQuery, (querySnapshot) => {
                setEducationRows(prevEducation => {
                    let updatedEducation= [...prevEducation];
                    querySnapshot.docChanges().forEach((change) => {
                        const data = change.doc.data();
                        const id = change.doc.id;
                        let education: Education = getEducation(data);
                        switch (change.type) {
                            case 'added': {
                                const tEducationArrNew: TEducation[] = [];
                                tEducationArrNew.push({ id: id + "_1", label: 'Degree / Course', value: `${formatEducationLabel(education?.type)} ${education.degree}`, type: 'DataInitial' });
                                if(education.institution !== undefined && education.institution !== "") tEducationArrNew.push({ id: id + "_2", label: 'Institution', value: education.institution, type: 'Data' });
                                if(education.fieldOfStudy !== undefined && education.fieldOfStudy.length !== 0) tEducationArrNew.push({ id: id + "_3", label: 'Major', value: arrayToString(education.fieldOfStudy), type: 'Data' });   // Field of Study => Major
                                if(education.endOrExpectedYear !== undefined && education.endOrExpectedYear.toString() !== "") tEducationArrNew.push({ id: id + "_4", label: 'Completed / Expected to', value: education.endOrExpectedYear?.toString(), type: 'Data' });
                                if (tEducationArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tEducationArrNew.length - 1;
                                    if (tEducationArrNew[lastItemIndex].type === "Data") {
                                        tEducationArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    } else {
                                        tEducationArrNew[lastItemIndex].type = "DataOnly"; // Change type to 'DataEnd'
                                    }
                                }
                                tEducationArrNew.push({ id: id + "_100", type: 'Space' });
                                
                                updatedEducation.push({ id, education: education, rows: tEducationArrNew });
                                break;
                            }
                            case 'modified': {
                                const tEducationArrNew: TEducation[] = [];
                                tEducationArrNew.push({ id: id + "_1", label: 'Degree / Course', value: `${formatEducationLabel(education?.type)} ${education.degree}`, type: 'DataInitial' });
                                if(education.institution !== undefined && education.institution.toString() !== "") tEducationArrNew.push({ id: id + "_2", label: 'Institution', value: education.institution, type: 'Data' });
                                if(education.fieldOfStudy !== undefined && education.fieldOfStudy.length !== 0) tEducationArrNew.push({ id: id + "_3", label: 'Major', value: arrayToString(education.fieldOfStudy), type: 'Data' });   // Field of Study => Major
                                if(education.endOrExpectedYear !== undefined && education.endOrExpectedYear.toString() !== "") tEducationArrNew.push({ id: id + "_4", label: 'Completed / Expected to', value: education.endOrExpectedYear?.toString(), type: 'Data' });
                                if (tEducationArrNew.length > 0) {
                                    // Check if the last item exists and is of type 'Data', then change it to 'DataEnd'
                                    const lastItemIndex = tEducationArrNew.length - 1;
                                    if (tEducationArrNew[lastItemIndex].type === "Data") {
                                        tEducationArrNew[lastItemIndex].type = "DataEnd"; // Change type to 'DataEnd'
                                    } else {
                                        tEducationArrNew[lastItemIndex].type = "DataOnly"; // Change type to 'DataEnd'
                                    }
                                }
                                tEducationArrNew.push({ id: id + "_100", type: 'Space' });
                                setEducationRows((prevRows) => prevRows.map((edu) => (edu.id === id ? { ...edu, ...{ id: id, rows: tEducationArrNew, education: education } } : edu)) );
                                const modifiedIndex = updatedEducation.findIndex(item => item.id === id);
                                if (modifiedIndex !== -1) { updatedEducation[modifiedIndex] ={ id, education: education, rows: tEducationArrNew }; }
                                break;
                            }
                            case 'removed':
                                const removedIndex = updatedEducation.findIndex(item => item.id === id);
                                if (removedIndex !== -1) { updatedEducation.splice(removedIndex, 1); }
                                break;

                            default:
                                break;
                        }
                    });
                    const sortedEducation = sortEducationRows(updatedEducation)
                    return sortedEducation;
                });
            });


          

            return () => {
                unsubscribeAlumniSchooling();
                unsubscribeAlumniEmployment();
                unsubscribeAlumniEducation();
                unsubscribeAlumniNote();
            }
        }
    }, [oid, alumniId, orgName]);

    return { schoolingRows, employmentRows, educationRows, notes };
}

export default AlumniDetailsTabsHooks;

