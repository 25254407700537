import * as React from 'react';
import { Dayjs } from 'dayjs';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';

import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<
  Dayjs | null,
  Dayjs,
  FieldSection,
  DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  const handleIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Stop propagation to prevent the DateTimePicker from closing
    event.stopPropagation();
    setOpen?.(prev => !prev);
  };

  return (
    <Tooltip title={disabled ? "Select Start Time First" : "Select Time"}>
      <span>
        <IconButton
          id={id}
          disabled={disabled}
          ref={ref}
          aria-label={ariaLabel}
          onClick={handleIconButtonClick}
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 9.22146H3M14.5556 2.11035V5.66591M7.44444 2.11035V5.66591M7.26667 19.8881H14.7333C16.2268 19.8881 16.9735 19.8881 17.544 19.5975C18.0457 19.3418 18.4537 18.9339 18.7094 18.4321C19 17.8617 19 17.1149 19 15.6215V8.1548C19 6.66132 19 5.91459 19 15.6215V8.15479C19 6.66132 19 5.91459 19 15.6215V8.1548C19 6.66132 19 5.91459 19 15.6215V8.1548C19 6.66132 19 5.91459 18.7094 5.34415C18.4537 4.84239 18.0457 4.43444 17.544 4.17878C16.9735 3.88813 16.2268 3.88813 14.7333 3.88813H7.26667C5.77319 3.88813 5.02646 3.88813 4.45603 4.17878C3.95426 4.43444 3.54631 4.84239 3.29065 5.34415C3 5.91459 3 6.66132 3 8.15479V15.6215C3 17.1149 3 17.8617 3.29065 18.4321C3.54631 18.9339 3.95426 19.3418 4.45603 19.5975C5.02646 19.8881 5.77319 19.8881 7.26667 19.8881Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default function ButtonDateTimePicker(
  {minDateTime,
  disabled,
  ...props
}: Omit<DateTimePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>
& { minDateTime?: Dayjs; disabled?: boolean }
) {
  const [open, setOpen] = React.useState(false);

  return (
    <DateTimePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any }}
      minDateTime={minDateTime}
      ampm={false}
      {...props}
      open={open}
      disabled={disabled}
      closeOnSelect={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}
