import {db} from '.' ;
import { setDoc, doc, getDoc } from "firebase/firestore";

export const storeEoiEmail = async (email: string) => {
    try {
        await setDoc(doc(db,`eoi/${email}`), {});
    } catch (e) {
        console.error(e);
    }
}

export const storeSubscribeEmail = async (email: string) => {
    try {
        await setDoc(doc(db,`mailing/${email}`), {});
    } catch (e) {
        console.error(e);
    }
}

export const getTermsConditions = async () => {
    try {
        const snap = await getDoc(doc(db, 'publicDocuments', 'documents'));
        if(snap.exists()) {
            return snap.data().termsOfService.link;
        }
    } catch (e) {
        console.error(e);
    }
}

export const getPrivacyPolicy = async () => {
    try {
        const snap = await getDoc(doc(db, 'publicDocuments', 'documents'));
        if(snap.exists()) {
            return snap.data().privacyPolicy.link;
        }
    } catch (e) {
        console.error(e);
    }
}

export const getAboutLoop = async () => {
    try {
        const snap = await getDoc(doc(db, 'publicDocuments', 'documents'));
        if(snap.exists()) {
            return snap.data().about.link;
        }
    } catch (e) {
        console.error(e);
    }
}

export const getSiteTerms = async () => {
    try {
        const snap = await getDoc(doc(db, 'publicDocuments', 'documents'));
        if(snap.exists()) {
            return snap.data().siteTerms.link;
        }
    } catch (e) {
        console.error(e);
    }
}

export const getCookiesNotice = async () => {
    try {
        const snap = await getDoc(doc(db, 'publicDocuments', 'documents'));
        if(snap.exists()) {
            return snap.data().cookiesNotice.link;
        }
    } catch (e) {
        console.error(e);
    }
}