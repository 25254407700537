import { Backdrop, CircularProgress, DialogProps, Modal, Stack } from "@mui/material";
import { ModalType } from "../../../../components/CustomizedModal";
import styles from "../../index.module.scss";

interface ICustomModal {
    type?: ModalType;
    open: boolean;
    handleClose: ()=>void;
    children: JSX.Element;
    className?: string;
    contentStyle?: string;
    isLoading?: boolean;
    loadingStr?: string;
    width?: number
}

const CustomModal = ({type, open, handleClose, children, className, contentStyle, isLoading, loadingStr, width}: ICustomModal) => {
    const _handleClose: DialogProps["onClose"] = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }
        handleClose();
    }
    return (
        <>
            <Modal open={open} onClose={_handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' className={className ?? ""} >                    
                <Stack alignItems={"center"} justifyContent={"center"} style={{height:'100vh'}}>
                    <Stack className={`${contentStyle??""}`}>
                        <div>{children}</div>
                    </Stack>
                    <Backdrop
                        sx={{ color: "#ffffffa8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading || false}
                    >
                        <CircularProgress style={{'color':styles.primary400}}/>
                    </Backdrop>
                </Stack>
            </Modal>
        </>
    );
}

export default CustomModal;