import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { memo, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AlertComponent from "./components/AlertComponent";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import { LoadingFallbackComponent } from "./components/FallbackComponent";
import "./firebase";
import { auth } from "./firebase";
import { db } from "./firebase/index";
import { UserType } from "./firebase/types-userApi";
import { RootState } from "./redux";
import { setErrorMessage } from "./redux/pageStatusSlice";
import { setAuthDataLoaded, setUserDataLoaded } from "./redux/sharedStateSlice";
import { setUserLogin } from "./redux/userSlice";
import { RootRouters } from "./routers/routes";
import globalStyles from "./styles/_stylesParams.module.scss";
import ListenerComponent2 from "./HOC/ListenerComponent/ListenerComponent2";
import ScrollToTop from "./helpers/ScrollToTop";


const theme = createTheme({
    typography: { fontFamily: "Open Sans", button: { textTransform: "none", }, },
    components: {
        MuiButtonBase: { defaultProps: { disableRipple: true, }, },
        MuiTextField: { styleOverrides: { root: { "& .MuiOutlinedInput-input": { padding: "10px 16px", color: globalStyles.shades100, }, "& fieldset": { borderRadius: "8px", }, "& .MuiFormHelperText-root": { marginLeft: "0px", }, "& .Mui-error": { color: globalStyles.destructive500, }, }, }, },
        MuiButton: { styleOverrides: { root: { boxShadow: ["none"], fontWeight: 400, padding: "0px", minWidth: "unset", ":hover": { backgroundColor: "unset", fontWeight: "600", }, }, }, },
    }
});

function App() {
    const dispatch = useDispatch();
    const globalErrorMsg = useSelector((rootState: RootState) => rootState.pageStatus.errorMessage);
    const [alertMsg, setAlertMsg] = useState("");
    // const authMetaData = useRef<{ [k: string]: string; }>();
    // const navigate = useNavigate();
    useEffect(() => {
        const unsubscribe =    onAuthStateChanged(auth, async (user) => {
            console.log("MAIN AUTH TRIGGERED --------0-0-0-0-", user)
            if (user) {
                console.log("MAIN AUTH TRIGGERED 0", user)
                const uid = user.uid;
                const emailVerified = user.emailVerified;
                const name = user.displayName ?? "";
                const docRef = doc(db, 'users', uid);
                const snap = await getDoc(docRef);
                if(snap.exists()) {
                    const data = snap.data() as UserType;
                    dispatch(setUserLogin({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        role: data.role,
                        contactNumber: data.contactNumber,
                        uid: uid, 
                        displayName: name, 
                        email: data.email,
                        onBoarding: data.onBoarding,
                        emailVerified, 
                        profilePicture: user.photoURL
                    }));
                } else {
                    dispatch(setUserLogin({
                        uid: uid, 
                        displayName: name, 
                        emailVerified, 
                        profilePicture: user.photoURL 
                    }));
                }
                dispatch(setUserDataLoaded(true));
                console.log("MAIN AUTH TRIGGERED 1")
                console.log("------------>>>>> Login Status Loaded Dispatched 000")
                dispatch(setAuthDataLoaded(true)); 
            } else {
                console.log("MAIN AUTH TRIGGERED 3")
                dispatch(setAuthDataLoaded(true));
                console.log("------------>>>>> Login Status Loaded Dispatched 111")
            }
        })
        return () => unsubscribe();
    }, [dispatch]);


    const currentUserId = useSelector((rootState: RootState) => rootState.user.userInfos.uid);
    // Memoize the currentUserId to avoid setting up the listener again for the same user ID
    const memoizedUserId = useMemo(() => currentUserId, [currentUserId]);
    useEffect(() => {
        console.log("----->>>>>> memoizedUserId 0")
        if (memoizedUserId) {
            const docRef = doc(db, 'users', memoizedUserId);
            const unsubscribe = onSnapshot(docRef, (snap) => {
                if (snap.exists()) {
                    const userData = snap.data() as UserType;
                    // Dispatch actions or handle state updates with userData
                    dispatch(setUserLogin({
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        role: userData.role,
                        contactNumber: userData.contactNumber,
                        email: userData.email,
                        beta: userData.beta,
                        betaPasswordUpdate: userData.betaPasswordUpdate,
                        onBoarding: userData.onBoarding,
                        profilePictureId: userData.photoId,
                    }));
                    dispatch(setUserDataLoaded(true)); // Indicate that user data is loaded
                    console.log("------------>>>>> DATA Loaded Dispatched 111")
                } 
                // else { dispatch(setUserDataLoaded(false)); }
            });
        
            // Cleanup function
            return () => unsubscribe();
        } 
        // else { dispatch(setUserDataLoaded(false)); }
    }, [memoizedUserId]);

    return (
        <StyledEngineProvider injectFirst>
            <ErrorFallback>
                <div className='App' style={{ color: globalStyles.shades100 }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Suspense fallback={ <LoadingFallbackComponent /> } >
                            { globalErrorMsg?<AlertComponent message={globalErrorMsg??""} visible={globalErrorMsg?true:false} handleClose={()=>{ dispatch(setErrorMessage(undefined)) }} />:null }
                            { alertMsg !==""?<AlertComponent severity={"error"} message={alertMsg} visible={alertMsg !== ""} handleClose={function (state: boolean): void { setAlertMsg(""); }} />:null }
                            <ScrollToTop />
                            <Routes> { RootRouters.map((router, index) => <Route key={index} path={router.path} element={router.element} />) } </Routes>
                        </Suspense>
                    </ThemeProvider>
                </div>
            </ErrorFallback>
        </StyledEngineProvider>
    );
}

export default memo(App);
{/* <Button onClick={()=>{ console.log("-->Auth",auth.currentUser) }}>Global Test</Button>
<Button onClick={()=>{ auth.signOut() }}>LogOut</Button>
<Button onClick={()=>{ dispatch(setAuthDataLoaded(false)) }}>Turn Off Load Data Status</Button> */}
