import { Breadcrumbs, Modal, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { serverTimestamp } from "firebase/firestore";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AlertComponent, { AlertDialogSimpleComponent } from "../../../components/AlertComponent";
import { BetaButton, PrimaryButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import CustomizedPaper from "../../../components/CustomizedPaper";
import { validateAgenda, validateEngagementTitle, validateLocationOfEngagement, validateTypeOfEngagement } from "../../../helpers/inputValidators";
import pxToRem from "../../../helpers/pxToRem";
import useTextInput from "../../../hooks/useTextInput";
import { RootState } from "../../../redux";
import { betaStateAction } from "../../../redux/betaSlice";
import dashboardStyles from "../../DashboardContainerPage/index.module.scss";
import styles from "./index.module.scss";
import dayjs from 'dayjs';
import { getCurrentUserId } from "../../../firebase/userApi";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import MultilineTextField from "../../../components/Inputs/TextFields/MultilineInputTextField";
import AutoComplete from "../../../components/AutoComplete/AutoComplete";
import { CustomizedCheckBox } from "../../../components/Checkbox";
import LoadingComponent from "../../../components/LoadingStatus/Loading";
import { editEngagement, updateAgenda, updateInvitedNum, updateOutcome, updatePlannedNum, updateStudentNum } from "../../../firebase/engagementApis";
import { Engagement, NewEngagement } from "../../../firebase/types-engagement";
import constants from "../../../helpers/constants";
import { mergeAndSortTypes } from "../../../helpers/mergeTypes";
import ButtonDateTimePicker from "../components/dateTimePickerButton";
import AlumniSearchBar, { LinkedAlumniData } from "./AlumniSearchLinkBar";
import EngagementDetailsCard from "./DetailCard";
import NumberIconBar from "./NumIconBar";
import { getOrgById } from "../../../firebase/organisationApis";
import { combinedDataSelector } from "../../../redux/reselect";

interface EngagementTableHandles {
    triggerUpdate: () => void;
}

const EngagementDetailPage = () => {
    const params = useParams<{ orgId: string, engId: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = getCurrentUserId();
    const organisation = useSelector((state: RootState) => state.organisation);
    const orgState = useSelector((state: RootState) => state.organisation.orgState);
    // Selector to get engagement types from Redux
    const engagementTypes = useSelector((state: RootState) => state.organisation.organisation?.engagementTypes);

    const [deleteTable, setDeleteTable] = useState(false);
    const [relateAlumnis, setRelateAlumnis] = useState<LinkedAlumniData[]>([]);
    const [engagement, setEngagement] = useState<Engagement | null>(null);
    const [orgId, setOrgId] = useState<string | null | undefined>(organisation.orgId);
    const [orgName, setOrgName] = useState<string | null | undefined>(organisation.organisation?.name);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [openEditEngagement, setOpenEditEngagement] = useState(false);
    const [openEditAgenda, setOpenEditAgenda] = useState(false);
    const [openEditOutcome, setOpenEditOutcome] = useState(false);
    const agenda = useTextInput({ inputValidator: (input) => validateAgenda(input, 'agenda') });
    const outcome = useTextInput({ inputValidator: (input) => validateAgenda(input, 'outcome') });
    const [studentsNum, setStudentNum] = useState<number>(0);
    const [invitedNum, setInvitedNum] = useState<number>(0);
    const [plannedNum, setPlannedNum] = useState<number>(0);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [isAllDay, setIsAllDay] = useState<boolean>(false);
    const title = useTextInput({ inputValidator: (input) => validateEngagementTitle(input) });
    const type = useTextInput({ inputValidator: (input) => validateTypeOfEngagement(input) });
    const combineData = useSelector(combinedDataSelector);
    
    const [refreshEngagementTypes, setRefreshEngagementTypes] = useState(false);
    const location = useTextInput({ defaultValue: `On Campus (${organisation.organisation?.defaultCampus})`, inputValidator: (input) => validateLocationOfEngagement(input) });
    const [organisationEngagementType, setOrganisationEngagementType] = useState<string[]>(['Assembly', 'Fundraiser', 'Reunion', 'Seminar', 'Sport Event']);
    const [organisationEngagementLocation, setOrganisationEngagementLocation] = useState<string[]>(() => {
        // Use a fallback empty array if campus is undefined to ensure the return type is always string[]
        const campusNames = organisation.organisation?.campus;
        if (Array.isArray(campusNames)) {
            return campusNames.map(campusName => `On Campus (${campusName})`);
        }
        return []; // Explicitly return an empty array if campusNames is not an array
    });
    const engagements = useSelector((state: RootState) => state.engagementState.engagements);
    const engagementPermissions = useSelector((state:RootState) => state.globalState.permissions?.engagement);

    const EngagementTableRef = useRef<EngagementTableHandles>(null);

    useEffect(() => {
        if (!params.orgId || !params.engId) {
            console.error("Invalid organization or engagement ID.");
            return;
        }

        // Find the engagement in the Redux store
        const foundEngagement = engagements.find(e => e.id === params.engId);

        if (!orgName) {
            const fetchOrgName = async () => {
                const foundOrg = await getOrgById(params.orgId as string);
                if (foundOrg.code === 200) {
                    setOrgName(foundOrg.data);
                }
            }
            fetchOrgName()
        }

        if (foundEngagement) {
            setEngagement(foundEngagement);
            // Set all the state based on the found engagement
            if (foundEngagement.start_time === foundEngagement.end_time) {
                setIsAllDay(true);
                setStartDate(dayjs(foundEngagement.start_time));
                setEndDate(null);
            } else {
                setStartDate(dayjs(foundEngagement.start_time));
                setEndDate(dayjs(foundEngagement.end_time));
            }
            title.setValue(foundEngagement.title ?? "");
            type.setValue(foundEngagement.type ?? "");
            location.setValue(foundEngagement.location ?? "");
            agenda.setValue(foundEngagement.agenda ?? "");
            outcome.setValue(foundEngagement.outcome ?? "");
            setStudentNum(foundEngagement.student_num || 0);
            setInvitedNum(foundEngagement.invited_num || 0);
            setPlannedNum(foundEngagement.planned_num || 0);
            const linkedAlumniDetails = foundEngagement.linkedAlumnis?.map(linkedAlumni => {
                const fullAlumniDetails = combineData.find(alumni => alumni.objectID === linkedAlumni.alumniId);
                return {
                    ...fullAlumniDetails, // Spread the full details from the alumni list
                    ...linkedAlumni       // This will override status and involvement from the engagement data
                } as LinkedAlumniData;
            });
            setRelateAlumnis(linkedAlumniDetails || []);
        } else {
            console.error("Engagement not found in Redux store.");
        }
    }, [params.orgId, params.engId, engagements]); // Include engagements in dependencies to refresh when changes


    useEffect(() => {
        // Function to merge and sort engagement types
        function updateEngagementTypes(types: string[]) {
            const allTypes = mergeAndSortTypes(organisationEngagementType, types);
            setOrganisationEngagementType(allTypes);
        }

        if (engagementTypes) {
            updateEngagementTypes(engagementTypes);
        }
    }, [engagementTypes]);

    const handleCancelEdit = () => {
        if (engagement) {
            if (engagement.start_time === engagement.end_time) {
                setIsAllDay(true);
                setStartDate(dayjs(engagement.start_time));
                setEndDate(null);
            } else {
                setStartDate(dayjs(engagement.start_time));
                setEndDate(dayjs(engagement.end_time));
            }
            title.setValue(engagement.title ?? "");
            type.setValue(engagement.type ?? "");
            location.setValue(engagement.location ?? "");
            title.setHasTouched(false);
            type.setHasTouched(false);
            location.setHasTouched(false);
            setOpenEditEngagement(false);
        } else {
            const handleCancelEdit = () => {
                setStartDate(null);
                setEndDate(null);
                setIsAllDay(false);
                title.setValue("");
                type.setValue("");
                location.setValue(`On Campus (${organisation.organisation?.defaultCampus})`);
                title.setHasTouched(false);
                type.setHasTouched(false);
                location.setHasTouched(false);
                setOpenEditEngagement(false);
            };
        }

    };

    const handleCancelEditOutcome = () => {
        outcome.setValue(engagement?.outcome as string);
        outcome.setHasTouched(false);
        outcome.setErrorMessage('');
        setOpenEditOutcome(false);
    };

    const handleCancelEditAgenda = () => {
        agenda.setValue(engagement?.agenda as string);
        agenda.setHasTouched(false);
        agenda.setErrorMessage('')
        setOpenEditAgenda(false);
    };

    // Agenda Edit Handling
    const handleUpdateAgenda = async (newAgenda: string) => {
        const formattedAgenda = newAgenda
        .split('\n') // Split by every newline to process each line individually
        .map(line => line.replace(/\s+/g, ' ').trim()) // Trim each line and replace multiple spaces with a single space
        .join('\n') // Rejoin the lines with a single newline to preserve single line breaks
        .replace(/\n{2,}/g, '\n\n')// Replace multiple consecutive newlines with a single blank line
        .replace(/\n+$/, '');
        setLoadingStatus(true);
        setLoadingStatusStr("Updating Agenda...");

        try {
            const response = await updateAgenda(orgId as string, params.engId as string, formattedAgenda);
            if (response.code === 200) {
                console.log("Agenda updated successfully.");
                setOpenEditAgenda(false);  // Close modal on success
                setLoadingStatusStr("");
                setLoadingStatus(false);
            } else {
                console.error("Failed to update agenda:", response.errorMsg);
                setErrorMsg("Failed to update agenda: " + response.errorMsg);
                setLoadingStatusStr("");
                setLoadingStatus(false);
            }
        } catch (error) {
            console.error("Error updating agenda:", error);
            setErrorMsg("Error updating agenda. Please try again.");
            setLoadingStatusStr("");
            setLoadingStatus(false);
        } finally {
            // Any clean-up can be done here
        }
    };

    // Outcome Edit Handling
    const handleUpdateOutcome = async (newOutcome: string) => {

        const formattedOutcome = newOutcome
        .split('\n') // Split by every newline to process each line individually
        .map(line => line.replace(/\s+/g, ' ').trim()) // Trim each line and replace multiple spaces with a single space
        .join('\n') // Rejoin the lines with a single newline to preserve single line breaks
        .replace(/\n{2,}/g, '\n\n') // Replace multiple consecutive newlines with a single blank line
        .replace(/\n+$/, '');
        setLoadingStatus(true);
        setLoadingStatusStr("Updating Outcome...");

        try {
            const response = await updateOutcome(orgId as string, params.engId as string, formattedOutcome);
            if (response.code === 200) {
                console.log("Outcome updated successfully.");
                setOpenEditOutcome(false);  // Close modal on success
                // Optionally refresh the list or trigger any updates
                setLoadingStatusStr("");
                setLoadingStatus(false);
            } else {
                console.error("Failed to update outcome:", response.errorMsg);
                setErrorMsg("Failed to update outcome: " + response.errorMsg);
                setLoadingStatusStr("");
                setLoadingStatus(false);
            }
        } catch (error) {
            console.error("Error updating outcome:", error);
            setErrorMsg("Error updating outcome. Please try again.");
            setLoadingStatusStr("");
            setLoadingStatus(false);
        } finally {
            // Any clean-up can be done here
        }
    };

    // Save Button in Modal for Agenda
    const handleSaveEditAgenda = () => {
        if (!agenda.value) {
            setErrorMsg("Please fill in the agenda.");
            return;
        }
        handleUpdateAgenda(agenda.value);
    };

    // Save Button in Modal for Outcome
    const handleSaveEditOutcome = () => {
        if (!outcome.value) {
            setErrorMsg("Please fill in the outcome.");
            return;
        }
        handleUpdateOutcome(outcome.value);
    };


    // Update the number of students involved for a specific engagement
    const handleUpdateStudentNum = async (newStudentNum: number) => {
        try {
            const response = await updateStudentNum(orgId as string, params.engId as string, newStudentNum);
            if (response.code === 200) {
                console.log("Number of students involved updated successfully.");
            } else {
                console.error("Failed to update number of students involved:", response.errorMsg);
            }
        } catch (error) {
            console.error("Error updating number of students involved:", error);
        }
    };

    // Update the number of invited individuals for a specific engagement
    const handleUpdateInvitedNum = async (newInvitedNum: number) => {
        try {
            const response = await updateInvitedNum(orgId as string, params.engId as string, newInvitedNum);
            if (response.code === 200) {
                console.log("Number of invited individuals updated successfully.");
            } else {
                console.error("Failed to update number of invited individuals:", response.errorMsg);
            }
        } catch (error) {
            console.error("Error updating number of invited individuals:", error);
        }
    };

    // Update the number of planned attendees for a specific engagement
    const handleUpdatePlannedNum = async (newPlannedNum: number) => {
        try {
            const response = await updatePlannedNum(orgId as string, params.engId as string, newPlannedNum);
            if (response.code === 200) {
                console.log("Number of planned attendees updated successfully.");
            } else {
                console.error("Failed to update number of planned attendees:", response.errorMsg);
            }
        } catch (error) {
            console.error("Error updating number of planned attendees:", error);
        }
    };

    const handleSaveEditEngagement = async () => {
        // Ensure all required fields are filled out
        let errors = [];

        // Check each field and add specific error messages
        if (!title.value) {
            errors.push("Title");
        }
        if (!location.value) {
            errors.push("Location");
        }
        if (!startDate) {
            errors.push("Start date");
        }
        if (!isAllDay && !endDate) {
            errors.push("End date");
        }
    
        // If there are any errors, set the error message
        if (errors.length > 0) {
            setErrorMsg(`Please fill in all required fields: ${errors.join(", ")}.`);
            return;
        }
        setLoadingStatus(true);
        setLoadingStatusStr("Updating Engagement Details");

        const engagementData: NewEngagement = {
            title: title.value,
            location: location.value,
            ...(type.value && { type: type.value }),
            start_time: startDate?.valueOf(),
            end_time: isAllDay ? startDate?.valueOf() : endDate?.valueOf(),
            created_at: serverTimestamp(), // These will be overridden in the transaction to ensure atomicity
            updated_at: serverTimestamp()
        };

        try {

            const response = await editEngagement(orgId as string, params.engId as string, engagementData, userId as string);
            if (response.code === 200) {
                setOpenEditEngagement(false); // Close modal on success
                setRefreshEngagementTypes(prev => !prev);  // Toggle to refresh engagement types
                EngagementTableRef.current?.triggerUpdate();

                setLoadingStatusStr("");
                setLoadingStatus(false);

                setErrorMsg(""); // Clear any error messages
                // Optionally refresh the list or navigate away
            } else {
                setLoadingStatusStr("");
                setLoadingStatus(false);
                alert("Failed to create new engagement.");
            }
        } catch (error) {
            setLoadingStatusStr("");
            setLoadingStatus(false);
            // Handle errors, such as displaying a message to the user
            console.error("Error saving task:", error);
            alert("Failed to save task. Please try again.");
        } finally {
            setStartDate(null);
            setEndDate(null);
            setIsAllDay(false);
            title.setValue("");
            type.setValue("");
            location.setValue(`On Campus (${organisation.organisation?.defaultCampus})`);
            title.setHasTouched(false);
            type.setHasTouched(false);
            location.setHasTouched(false);
            setOpenEditEngagement(false);
        }
    };

    const formatDate = (date: Dayjs | null) => {
        return date?.isValid() ? date.format(isAllDay ? "DD MMM YY" : "DD MMM YY, HH:mm") : null;
    };


    const transferDate = (start: number, end: number) => {
        const startDate = dayjs(start);
        const endDate = dayjs(end);

        if (startDate.isSame(endDate, 'day')) {
            // Check if it's an all-day event by comparing if start and end are at start and end of the day
            if (startDate.hour() === endDate.hour() && startDate.minute() === endDate.minute()) {
                return `${startDate.format('D MMM YY')} (All day)`;
            }
            // If not all day but starts and ends on the same day, show hours
            return `${startDate.format('D MMM YY, HH:mm')} - ${endDate.format('HH:mm')}`;
        } else {
            // Different start and end days
            return `${startDate.format('D MMM YY, HH:mm')} - ${endDate.format('D MMM YY, HH:mm')}`;
        }
    };


    return (
        <>
            <AlertDialogSimpleComponent visible={errorMsg !== ""} primaryButtonClose={function (): void { setErrorMsg(""); }} title="Alert" message={errorMsg} primaryButtonTitle="OK"/>
            <Stack style={{ marginTop: pxToRem(8), paddingTop: pxToRem(8), marginBottom: pxToRem(12), justifyContent: 'space-between' }} direction={"row"}>
                <Breadcrumbs className={dashboardStyles.breadCrumbsLink}>
                    <Stack onClick={() => navigate(`/dashboard`)}><span className={styles.breadcrumbsLink}>Dashboard</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}`)}><span className={styles.breadcrumbsLink}>{orgName}</span></Stack>
                    <Stack onClick={() => navigate(`/dashboard/organisation/${orgId}/engagement`)}><span className={styles.breadcrumbsLink}>Engagement</span></Stack>
                    <Stack><span>{engagement?.title}</span></Stack>
                </Breadcrumbs>
                <BetaButton text={orgState as string} onClick={() => {if (orgState === 'REGULAR') {navigate('/pricing')} else {dispatch(betaStateAction.setBetaState(true))}}}/>
            </Stack>

            <Modal open={openEditEngagement} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <div className={styles.popupBackdrop}>
                    </div>
                    {loadingStatus ? (

                        <div className={styles.popup}>
                            <LoadingComponent isLoading={loadingStatus} loadingStr={loadingStatusStr} />
                        </div>
                    ) : (
                        <div className={styles.popup}>
                            <Stack className={styles.contentBox} direction={'column'}>
                                <Stack direction={"column"} style={{ alignItems: 'left', position: 'relative', width: '100%' }}>
                                    <h6 style={{ flexGrow: 1 }} className='light'>Edit Engagement</h6>
                                    <small className={`xssSpace ${styles["text--neutrals500"]}`}> {'Edit the details of your event or activity here'} </small>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                </Stack>

                                <Stack direction={"column"} width={'70%'} spacing={pxToRem(24)}>
                                    <Stack direction={"row"}>
                                        <Stack direction={"row"} style={{ alignItems: "center" }}>
                                            <small style={{ marginRight: pxToRem(10) }} >Date:</small>
                                            {!isAllDay && <small style={{ marginRight: pxToRem(10) }} >From</small>}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <ButtonDateTimePicker
                                                    label={startDate == null ? null : startDate.format('MM/DD/YYYY')}
                                                    value={startDate}
                                                    onChange={(newValue) => setStartDate(newValue)}
                                                />
                                            </LocalizationProvider>
                                            <small style={{ marginRight: pxToRem(10) }}>
                                                {formatDate(startDate)}
                                            </small>
                                        </Stack>
                                        {!isAllDay && (
                                            <Stack direction={"row"} style={{ alignItems: "center" }}>
                                                <small style={{ marginRight: pxToRem(10) }} >To</small>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <ButtonDateTimePicker
                                                        label={endDate == null ? null : endDate.format('MM/DD/YYYY')}
                                                        value={endDate}
                                                        onChange={(newValue) => setEndDate(newValue)}
                                                    />
                                                </LocalizationProvider>
                                                <small style={{ marginRight: pxToRem(10) }}>
                                                    {endDate ? formatDate(endDate) : null}
                                                </small>
                                            </Stack>
                                        )}
                                        <Stack direction={"row"} style={{ alignItems: "center" }}>
                                            <CustomizedCheckBox checked={isAllDay} onChange={(e) => setIsAllDay(e.target.checked)} />
                                            <small style={{ marginLeft: pxToRem(6) }} >All Day</small>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <Stack>
                                            <small style={{ marginRight: pxToRem(10) }} >Type of Engagement</small>
                                            <Typography className={styles.xssmallGreyText}>
                                                i.e. session, sport event, reunion etc
                                            </Typography>
                                        </Stack>

                                        <AutoComplete fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={type.value} label='Maximum 64 characters' inputValue={type.value} setValue={(value: string) => type.setValue(value)} options={organisationEngagementType ?? []} freeSolo forcePopupIcon error={type.hasError && type.hasTouched} helperText={<small>{type.getErrorMessage()}</small>} />

                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <small style={{ marginRight: pxToRem(10) }} >Engagement Title*</small>
                                        <InputTextField type={"text"} fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={title.value} containerStyle={{ flex: 1 }} label='Maximum 64 characters' value={title.value} onChange={(e) => title.setValue(e.target.value)} error={title.hasError && title.hasTouched} helperText={<small>{title.getErrorMessage()}</small>} />
                                    </Stack>
                                    <Stack direction={"column"} spacing={pxToRem(12)}>
                                        <small style={{ marginRight: pxToRem(10) }} >Location of Engagement*</small>
                                        <AutoComplete fontSize={constants.addAlumniFontSize} customHeight={constants.addAlumniInputHeight} valueCheck={location.value} label='' inputValue={location.value} setValue={(value: string) => location.setValue(value)} options={organisationEngagementLocation ?? []} freeSolo forcePopupIcon error={location.hasError && location.hasTouched} helperText={<small>{location.getErrorMessage()}</small>} />
                                    </Stack>
                                </Stack>

                                <Stack direction={"column"} width={'100%'}>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                    <Stack direction={"row"} justifyContent='space-between' width={'100%'}>
                                        <SecondaryButton onClick={handleCancelEdit} className={`${styles.button} regular`}> Cancel </SecondaryButton>
                                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                                            <PrimaryButton className={styles.button} onClick={handleSaveEditEngagement}>Save</PrimaryButton>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Stack>
                        </div>
                    )}
                </div>
            </Modal>

            <Modal open={openEditAgenda} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <div className={styles.popupBackdrop}>
                    </div>
                    {loadingStatus ? (

                        <div className={styles.popup}>
                            <LoadingComponent isLoading={loadingStatus} loadingStr={loadingStatusStr} />
                        </div>) : (
                        <div className={styles.popup}>
                            <Stack className={styles.contentBox} direction={'column'}>
                                <Stack direction={"column"} style={{ alignItems: 'left', position: 'relative', width: '100%' }}>
                                    <h6 style={{ flexGrow: 1 }} className='light'>Edit Agenda</h6>
                                    <small className={`xssSpace ${styles["text--neutrals500"]}`}> {'What was the agenda of this engagement?'} </small>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                </Stack>

                                <Stack direction={'column'} spacing={pxToRem(12)} width={'70%'}>
                                    <small>
                                        Agenda
                                    </small>
                                    <MultilineTextField fontSize={14} customHeight={36} value={agenda.value} placeholder="Add Agenda..." onChange={(event: { target: { value: SetStateAction<string>; }; }) => agenda.setValue(event.target.value)} multiline minRows={5} maxRows={14} inputProps={{ maxLength: 300 }} error={agenda.hasError && agenda.hasTouched} helperText={<small>{agenda.getErrorMessage()}</small>} />
                                </Stack>

                                <Stack direction={"column"} width={'100%'}>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                    <Stack direction={"row"} justifyContent='space-between' width={'100%'}>
                                        <SecondaryButton onClick={handleCancelEditAgenda} className={`${styles.button} regular`}> Cancel </SecondaryButton>
                                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                                            <PrimaryButton className={styles.button} onClick={handleSaveEditAgenda}>Save</PrimaryButton>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Stack>
                        </div>
                    )}
                </div>
            </Modal>

            <Modal open={openEditOutcome} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <div className={styles.popupBackdrop}>
                    </div>
                    {loadingStatus ? (

                        <div className={styles.popup}>
                            <LoadingComponent isLoading={loadingStatus} loadingStr={loadingStatusStr} />
                        </div>) : (
                        <div className={styles.popup}>
                            <Stack className={styles.contentBox} direction={'column'}>
                                <Stack direction={"column"} style={{ alignItems: 'left', position: 'relative', width: '100%' }}>
                                    <h6 style={{ flexGrow: 1 }} className='light'>Edit Outcome</h6>
                                    <small className={`xssSpace ${styles["text--neutrals500"]}`}> {'What was the outcome of this engagement?'} </small>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                </Stack>

                                <Stack direction={'column'} spacing={pxToRem(12)} width={'70%'}>
                                    <small>
                                        Outcome
                                    </small>
                                    <MultilineTextField fontSize={14} customHeight={36} value={outcome.value} placeholder="Add outcome..." onChange={(event: { target: { value: SetStateAction<string>; }; }) => outcome.setValue(event.target.value)} multiline minRows={5} maxRows={14} inputProps={{ maxLength: 300 }} error={outcome.hasError && outcome.hasTouched} helperText={<small>{outcome.getErrorMessage()}</small>}/>
                                </Stack>

                                <Stack direction={"column"} width={'100%'}>
                                    <div className={`${styles.divider} horizontalDivider`}></div>
                                    <Stack direction={"row"} justifyContent='space-between' width={'100%'}>
                                        <SecondaryButton onClick={handleCancelEditOutcome} className={`${styles.button} regular`}> Cancel </SecondaryButton>
                                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                                            <PrimaryButton className={styles.button} onClick={handleSaveEditOutcome}>Save</PrimaryButton>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Stack>
                        </div>
                    )}
                </div>
            </Modal>

            <CustomizedPaper className={dashboardStyles.content} cancelBoxShadow cancelHoriontalPadding cancelVerticalPadding >
                <Stack className={styles.content}>
                    <Stack direction="row" spacing={pxToRem(24)} justifyContent="space-between" sx={{ width: '100%' }}>
                        <Stack width="50%" direction="column">
                            <Stack direction={'row'} spacing={pxToRem(24)} width={'100%'}>
                                <Stack direction={'column'} style={{ alignItems: 'start' }}>
                                    <IconButton onClick={() => navigate(-1)}>
                                        <svg width={pxToRem(8)} height={pxToRem(14)} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 13L1 7L7 1" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 13L1 7L7 1" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M7 13L1 7L7 1" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </IconButton>
                                </Stack>

                                <Stack direction={"column"} style={{ alignItems: 'start', position: 'relative' }}>
                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'start', justifyContent: 'start', }}>
                                        <h6 style={{ flexGrow: 1 }} className='light'>{engagement?.title}</h6>
                                    </div>
                                    {(!engagementPermissions || engagementPermissions === "EDITOR") &&
                                    <Stack direction={'row'} style={{ alignItems: 'center', position: 'relative' }}>
                                        <IconButton onClick={() => setOpenEditEngagement(true)}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </IconButton>
                                        <small> Edit Engagement details </small>
                                    </Stack>}
                                </Stack>


                            </Stack>
                            <div className={`${styles.divider} horizontalDivider`}></div>
                            <Stack spacing={pxToRem(24)}>


                                <NumberIconBar
                                    onUpdate={handleUpdateStudentNum}
                                    initialCount={studentsNum} // Starting count
                                    label="Students Involved"
                                    disabled={!(!engagementPermissions || engagementPermissions === "EDITOR")}
                                    Icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99609 14C7.99609 14 9.49609 16 11.9961 16C14.4961 16 15.9961 14 15.9961 14M14.9961 9H15.0061M8.99609 9H9.00609M21.9961 12C21.9961 17.5228 17.5189 22 11.9961 22C6.47325 22 1.99609 17.5228 1.99609 12C1.99609 6.47715 6.47325 2 11.9961 2C17.5189 2 21.9961 6.47715 21.9961 12ZM15.4961 9C15.4961 9.27614 15.2722 9.5 14.9961 9.5C14.72 9.5 14.4961 9.27614 14.4961 9C14.4961 8.72386 14.72 8.5 14.9961 8.5C15.2722 8.5 15.4961 8.72386 15.4961 9ZM9.49609 9C9.49609 9.27614 9.27224 9.5 8.99609 9.5C8.71995 9.5 8.49609 9.27614 8.49609 9C8.49609 8.72386 8.71995 8.5 8.99609 8.5C9.27224 8.5 9.49609 8.72386 9.49609 9Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.99609 14C7.99609 14 9.49609 16 11.9961 16C14.4961 16 15.9961 14 15.9961 14M14.9961 9H15.0061M8.99609 9H9.00609M21.9961 12C21.9961 17.5228 17.5189 22 11.9961 22C6.47325 22 1.99609 17.5228 1.99609 12C1.99609 6.47715 6.47325 2 11.9961 2C17.5189 2 21.9961 6.47715 21.9961 12ZM15.4961 9C15.4961 9.27614 15.2722 9.5 14.9961 9.5C14.72 9.5 14.4961 9.27614 14.4961 9C14.4961 8.72386 14.72 8.5 14.9961 8.5C15.2722 8.5 15.4961 8.72386 15.4961 9ZM9.49609 9C9.49609 9.27614 9.27224 9.5 8.99609 9.5C8.71995 9.5 8.49609 9.27614 8.49609 9C8.49609 8.72386 8.71995 8.5 8.99609 8.5C9.27224 8.5 9.49609 8.72386 9.49609 9Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.99609 14C7.99609 14 9.49609 16 11.9961 16C14.4961 16 15.9961 14 15.9961 14M14.9961 9H15.0061M8.99609 9H9.00609M21.9961 12C21.9961 17.5228 17.5189 22 11.9961 22C6.47325 22 1.99609 17.5228 1.99609 12C1.99609 6.47715 6.47325 2 11.9961 2C17.5189 2 21.9961 6.47715 21.9961 12ZM15.4961 9C15.4961 9.27614 15.2722 9.5 14.9961 9.5C14.72 9.5 14.4961 9.27614 14.4961 9C14.4961 8.72386 14.72 8.5 14.9961 8.5C15.2722 8.5 15.4961 8.72386 15.4961 9ZM9.49609 9C9.49609 9.27614 9.27224 9.5 8.99609 9.5C8.71995 9.5 8.49609 9.27614 8.49609 9C8.49609 8.72386 8.71995 8.5 8.99609 8.5C9.27224 8.5 9.49609 8.72386 9.49609 9Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    }
                                />
                                <NumberIconBar
                                    onUpdate={handleUpdateInvitedNum}
                                    initialCount={invitedNum} // Starting count
                                    label="Invited"
                                    disabled={!(!engagementPermissions || engagementPermissions === "EDITOR")}
                                    Icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.7401 2.63346L21.2681 7.52667C21.5341 7.69957 21.6671 7.78602 21.7634 7.90134C21.8487 8.00342 21.9128 8.12149 21.9519 8.24865C21.9961 8.39229 21.9961 8.55092 21.9961 8.86818V16.1999C21.9961 17.88 21.9961 18.7201 21.6691 19.3619C21.3815 19.9263 20.9226 20.3853 20.3581 20.6729C19.7163 20.9999 18.8763 20.9999 17.1961 20.9999H6.79609C5.11594 20.9999 4.27586 20.9999 3.63412 20.6729C3.06964 20.3853 2.61069 19.9263 2.32307 19.3619C1.99609 18.7201 1.99609 17.88 1.99609 16.1999V8.86818C1.99609 8.55092 1.99609 8.39229 2.04026 8.24865C2.07936 8.12149 2.14344 8.00342 2.22874 7.90134C2.3251 7.78602 2.45811 7.69957 2.72411 7.52667L10.2521 2.63346M13.7401 2.63346C13.1088 2.22315 12.7932 2.01799 12.4531 1.93817C12.1525 1.86761 11.8397 1.86761 11.5391 1.93817C11.199 2.01799 10.8834 2.22315 10.2521 2.63346M13.7401 2.63346L19.9322 6.65837C20.6201 7.10547 20.964 7.32902 21.0831 7.61252C21.1872 7.86027 21.1872 8.13949 21.0831 8.38724C20.964 8.67074 20.6201 8.89429 19.9322 9.34139L13.7401 13.3663C13.1088 13.7766 12.7932 13.9818 12.4531 14.0616C12.1525 14.1321 11.8397 14.1321 11.5391 14.0616C11.199 13.9818 10.8834 13.7766 10.2521 13.3663L4.05996 9.34139C3.37211 8.89429 3.02818 8.67074 2.90907 8.38724C2.80497 8.13949 2.80497 7.86027 2.90907 7.61252C3.02818 7.32902 3.37211 7.10547 4.05996 6.65837L10.2521 2.63346M21.4961 18.9999L14.8533 12.9999M9.13892 12.9999L2.49609 18.9999" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    }
                                />
                                <NumberIconBar
                                    onUpdate={handleUpdatePlannedNum}
                                    initialCount={plannedNum} // Starting count
                                    label="Planned Attendees"
                                    disabled={!(!engagementPermissions || engagementPermissions === "EDITOR")}
                                    Icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.9961 18L17.9961 20L21.9961 16M11.9961 15H7.99609C6.13233 15 5.20044 15 4.46536 15.3045C3.48525 15.7105 2.70655 16.4892 2.30058 17.4693C1.99609 18.2044 1.99609 19.1362 1.99609 21M15.4961 3.29076C16.962 3.88415 17.9961 5.32131 17.9961 7C17.9961 8.67869 16.962 10.1159 15.4961 10.7092M13.4961 7C13.4961 9.20914 11.7052 11 9.49609 11C7.28695 11 5.49609 9.20914 5.49609 7C5.49609 4.79086 7.28695 3 9.49609 3C11.7052 3 13.4961 4.79086 13.4961 7Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.9961 18L17.9961 20L21.9961 16M11.9961 15H7.99609C6.13233 15 5.20044 15 4.46536 15.3045C3.48525 15.7105 2.70655 16.4892 2.30058 17.4693C1.99609 18.2044 1.99609 19.1362 1.99609 21M15.4961 3.29076C16.962 3.88415 17.9961 5.32131 17.9961 7C17.9961 8.67869 16.962 10.1159 15.4961 10.7092M13.4961 7C13.4961 9.20914 11.7052 11 9.49609 11C7.28695 11 5.49609 9.20914 5.49609 7C5.49609 4.79086 7.28695 3 9.49609 3C11.7052 3 13.4961 4.79086 13.4961 7Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.9961 18L17.9961 20L21.9961 16M11.9961 15H7.99609C6.13233 15 5.20044 15 4.46536 15.3045C3.48525 15.7105 2.70655 16.4892 2.30058 17.4693C1.99609 18.2044 1.99609 19.1362 1.99609 21M15.4961 3.29076C16.962 3.88415 17.9961 5.32131 17.9961 7C17.9961 8.67869 16.962 10.1159 15.4961 10.7092M13.4961 7C13.4961 9.20914 11.7052 11 9.49609 11C7.28695 11 5.49609 9.20914 5.49609 7C5.49609 4.79086 7.28695 3 9.49609 3C11.7052 3 13.4961 4.79086 13.4961 7Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    }
                                />
                            </Stack>
                        </Stack>
                        <Stack width="50%">
                            <Stack spacing={pxToRem(24)}>
                                <EngagementDetailsCard
                                    type={engagement?.type ? engagement.type as string : '-'}
                                    location={engagement?.location as string}
                                    date={transferDate(engagement?.start_time as number, engagement?.end_time as number)}
                                />
                                <Stack sx={{
                                    width: '100%',
                                    backgroundColor: '#447D75',
                                    padding: '24px 40px',
                                    borderRadius: '8px',
                                    color: 'white',
                                    fontFamily: 'Arial',
                                    display: 'flex',
                                    flexDirection: 'column',

                                    gap: '16px',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                                }}>
                                    <Stack direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}>


                                        <Typography variant="h6" sx={{
                                            fontSize: '18px',
                                            fontWeight: 'light'
                                        }}>
                                            Agenda
                                        </Typography>
                                        {(!engagementPermissions || engagementPermissions === "EDITOR") &&
                                        <IconButton onClick={() => setOpenEditAgenda(true)}>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </IconButton>}
                                    </Stack>
                                    <Typography sx={{
                                        fontSize: '16px',
                                        color: 'white',
                                        whiteSpace: 'pre-wrap',  // Preserves whitespace and wraps text
                                        overflowWrap: 'break-word', // Ensure the words do not overflow the container
                                        maxWidth: '100%' // Ensure it does not exceed the width of its parent

                                    }}>
                                        {engagement?.agenda ? engagement?.agenda : '-'}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack width="100%">
                        <Stack spacing={pxToRem(24)} marginTop={pxToRem(24)}>
                            <Stack sx={{
                                width: '100%',
                                backgroundColor: '#F9F9FA',
                                padding: '24px 40px',
                                borderRadius: '8px',
                                color: '#1F1F1F',
                                fontFamily: 'Arial',
                                display: 'flex',
                                flexDirection: 'column',

                                gap: '16px',
                            }}>
                                <Stack direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}>


                                    <Typography variant="h6" sx={{
                                        fontSize: '16px',
                                    }}>
                                        Outcome
                                    </Typography>
                                    {(!engagementPermissions || engagementPermissions === "EDITOR") &&
                                    <IconButton onClick={() => setOpenEditOutcome(true)}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M14.9961 8.12132H10.7961C9.11594 8.12132 8.27586 8.12132 7.63412 8.4483C7.06964 8.73592 6.61069 9.19486 6.32307 9.75935C5.99609 10.4011 5.99609 11.2412 5.99609 12.9213V21.3213C5.99609 23.0015 5.99609 23.8416 6.32307 24.4833C6.61069 25.0478 7.06964 25.5067 7.63412 25.7943C8.27586 26.1213 9.11594 26.1213 10.7961 26.1213H19.1961C20.8763 26.1213 21.7163 26.1213 22.3581 25.7943C22.9226 25.5067 23.3815 25.0478 23.6691 24.4833C23.9961 23.8416 23.9961 23.0015 23.9961 21.3213V17.1213M11.9961 20.1213H13.6706C14.1598 20.1213 14.4044 20.1213 14.6346 20.0661C14.8386 20.0171 15.0337 19.9363 15.2127 19.8266C15.4145 19.7029 15.5874 19.53 15.9334 19.1841L25.4961 9.62132C26.3245 8.79289 26.3245 7.44975 25.4961 6.62132C24.6677 5.79289 23.3245 5.79289 22.4961 6.62132L12.9333 16.1841C12.5874 16.53 12.4145 16.7029 12.2908 16.9048C12.1811 17.0837 12.1003 17.2788 12.0513 17.4829C11.9961 17.713 11.9961 17.9576 11.9961 18.4468V20.1213Z" stroke="black" strokeOpacity="0.2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </IconButton>}
                                </Stack>
                                <Typography sx={{
                                    fontSize: '16px',
                                    color: '#1F1F1F',
                                    overflowWrap: 'break-word', // Ensure the words do not overflow the container
                                    whiteSpace: 'pre-wrap',  // Preserves whitespace and wraps text
                                    maxWidth: '100%' // Ensure it does not exceed the width of its parent

                                }}>
                                    {engagement?.outcome ? engagement.outcome : '-'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack marginTop={pxToRem(24)}>
                        <AlumniSearchBar orgId={orgId as string} engId={params.engId as string} linkedAlumnis={relateAlumnis} engagement={engagement} ></AlumniSearchBar>
                    </Stack>

                </Stack>
            </CustomizedPaper>
        </>
    );
};
export default EngagementDetailPage;
