import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { memo, useEffect, useState } from "react";
import { AlumniDataItemInsideTable, AlumniTable, PAGINATION_PERPAGEROWS, RowSkeleton } from "..";
import styles from "../index.module.scss";
import { IconButton, Stack } from "@mui/material";
import { and, collection, collectionGroup, doc, getDoc, getDocs, or, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { Alumni, getAlumni, getEducation, getEmployment, getSchooling } from "../../../firebase/types-alumni";
import { joinStringWithComma, joinStringWithSpace } from "../../../helpers/utils";
import pxToRem from "../../../helpers/pxToRem";
import { PrimaryButton, PrimaryFlatButton, SecondaryButton } from "../../../components/Buttons/ButtonUI";
import InputTextField from "../../../components/Inputs/TextFields/InputTextField";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { PermissionState } from "../../../firebase/types-organisations";
import { LinkedAlumni } from "../../../firebase/types-engagement";

export interface ISearchAdvanceQuery {

    first_name: string;
    last_name: string;
    gender: string;
    pronouns: string;
    ATSI: boolean;

    subscribeEvent: boolean;
    subscribeNewsLetter: boolean;

    schoolType: string;
    schoolCampus: string;
    schoolHouse: string;

    peerYearMin: string;
    peerYearMax: string;

    jobTitle: string;
    nameOfCompany: string;
    employmentType: string;
    industry: string;

    educationType: string;
    degree: string;
    fieldOfStudy: string;
    institution: string;

    personalInterest: string;
    skills: string;

    engagementType: string,
    engagementInvolvement: string,
}
interface ISearchTable {
    orgName: string;
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    rowClick: (id: string) => void;
    orgId: string | undefined;
    searchInputQuery: ISearchAdvanceQuery | undefined;
    clearAllFilter: () => void;
    setOpenAddAlumni: (state: boolean) => void,
    setAdvanceSearchDialog: (state: boolean) => void,
    filterCount: number,
    addNewRow: () => JSX.Element;
}

const AdvanceSearchTable = ({ searchInput, setSearchInput, searchInputQuery, rowClick, orgId, clearAllFilter, setOpenAddAlumni, orgName, setAdvanceSearchDialog, filterCount, addNewRow }: ISearchTable) => {
    const [alumniDataRows, setAlumniDataRows] = useState<AlumniTable[]>([]);
    const columnHelper = createColumnHelper<AlumniTable>();
    const [isSearching, setIsSearching] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => { setSearchInput(event.target.value); };
    const alumniPermission = useSelector((state: RootState) => state.globalState.permissions?.alumni);
    const [csvData, setCsvData] = useState<string[][]>([]);
    const isAndMode = true;
    const columns = [
        columnHelper.accessor('objectID', { cell: info => info.getValue() }),
        columnHelper.accessor('name', { cell: info => info.getValue(), header: "Name" }),
        columnHelper.accessor('emailPreferred', { cell: info => info.getValue() }),
        columnHelper.accessor('currentEmployment', { cell: info => info.getValue(), header: "Employment" }),
        columnHelper.accessor('educationDegree', { cell: info => info.getValue(), header: "Further Education" }),
        columnHelper.accessor('living', { cell: info => info.getValue(), header: 'Living' }),
        columnHelper.accessor('peerYear', { cell: info => info.getValue(), header: "Peer Year" }),

        columnHelper.accessor('city', { cell: info => info.getValue() }),
        columnHelper.accessor('country', { cell: info => info.getValue() }),
        columnHelper.accessor('suburb', { cell: info => info.getValue() }),
    ];

    const table = useReactTable({
        state: { globalFilter: searchInput, columnVisibility: { objectID: false, emailPreferred: false, city: false, country: false, suburb: false } },
        initialState: { pagination: { pageIndex: 0, pageSize: PAGINATION_PERPAGEROWS } },
        data: alumniDataRows,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setSearchInput,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const intersectSets = (sets: Set<string>[], queryFlags: boolean[]): Set<string> => {
        // Ensure that there is a flag for each set to indicate whether it originated from a query
        if (sets.length === 0 || sets.length !== queryFlags.length) return new Set<string>();
        if (sets.length === 1) return sets[0];

        // Collect only those sets that either have items or are flagged as originating from a query
        const relevantSets = sets.filter((set, index) => set.size > 0 || queryFlags[index]);

        if (relevantSets.length === 0) return new Set<string>();  // If no relevant sets, return empty
        if (relevantSets.length === 1) return relevantSets[0];     // If one relevant set, return it as is

        // Start with the smallest set among relevant sets for efficiency
        const [smallestSet] = relevantSets.sort((a, b) => a.size - b.size);

        const result = new Set<string>();
        smallestSet.forEach(item => {
            if (relevantSets.every(set => set.has(item))) {
                result.add(item);
            }
        });

        return result;
    };

    function getQueryFlags(searchInputQuery: ISearchAdvanceQuery): boolean[] {
        const flags = [];

        // Check general alumni attributes
        flags.push(!!(searchInputQuery.first_name || searchInputQuery.last_name || searchInputQuery.gender || searchInputQuery.ATSI || searchInputQuery.pronouns || searchInputQuery.peerYearMin || searchInputQuery.peerYearMax || searchInputQuery.skills || searchInputQuery.personalInterest || searchInputQuery.subscribeEvent || searchInputQuery.subscribeNewsLetter));

        // Check schooling attributes
        flags.push(!!(searchInputQuery.schoolType || searchInputQuery.schoolCampus || searchInputQuery.schoolHouse));

        // Check employment attributes
        flags.push(!!(searchInputQuery.jobTitle || searchInputQuery.nameOfCompany || searchInputQuery.employmentType || searchInputQuery.industry));

        // Check education attributes
        flags.push(!!(searchInputQuery.educationType || searchInputQuery.degree || searchInputQuery.fieldOfStudy || searchInputQuery.institution));

        // Check engagement attributes
        flags.push(!!(searchInputQuery.engagementType || searchInputQuery.engagementInvolvement));

        return flags;
    }


    const fetchOrData = async () => {
        try {
            if (orgId && !isAndMode) {
                const alumniIds = new Set<string>();
                const alumniRows: AlumniTable[] = [];
                const doesIdExist = (id: string) => { return alumniRows.some(alumni => alumni.objectID === id); };
                const getAlumniTable = async (id: string): Promise<AlumniTable | null> => {
                    try {
                        const alumniSnap = await getDoc(doc(db, "organisations", orgId, "alumni", id));
                        if (alumniSnap.exists()) {
                            const alumni = getAlumni(alumniSnap.data());
                            const alumniTable: AlumniTable = {
                                objectID: alumniSnap.id,
                                title: alumni.title ?? "",
                                firstName: alumni.firstName ?? "",
                                lastName: alumni.lastName ?? "",
                                name: joinStringWithSpace(alumni.firstName ?? "", alumni.lastName ?? "") ?? "",
                                emailPreferred: alumni.emailPreferred ?? "",
                                currentEmployment: alumni.currentEmployment ? alumni.currentEmployment.map(item => item.jobTitle).join(", ") : "",
                                educationDegree: alumni.educationDegree ? alumni.educationDegree.map(item => item.degree).join(", ") : "",
                                peerYear: alumni.peerYear ?? -1,
                                living: joinStringWithComma(alumni.suburb ?? "", joinStringWithComma(alumni.city ?? "", alumni.country ?? "")) ?? "-",
                                photoUrl: alumni.photoUrl,
                            }
                            return alumniTable;
                        }
                        else return null;
                    }
                    catch (error) {
                        return null;
                    }
                };
                setIsSearching(true);
                if (searchInputQuery && (searchInputQuery.first_name || searchInputQuery.last_name || searchInputQuery.gender || searchInputQuery.ATSI || searchInputQuery.pronouns || searchInputQuery.peerYearMin || searchInputQuery.peerYearMax || searchInputQuery.skills || searchInputQuery.personalInterest || searchInputQuery.subscribeEvent || searchInputQuery.subscribeNewsLetter)) {
                    const conditions = [];
                    if (searchInputQuery.first_name) conditions.push(where("firstName", "==", searchInputQuery.first_name));
                    if (searchInputQuery.last_name) conditions.push(where("lastName", "==", searchInputQuery.last_name));
                    if (searchInputQuery.gender) conditions.push(where("gender", "==", searchInputQuery.gender));
                    if (searchInputQuery.pronouns) conditions.push(where("pronouns", "==", searchInputQuery.pronouns));
                    if (searchInputQuery.peerYearMin && searchInputQuery.peerYearMax) { conditions.push(and(where("peerYear", ">=", parseInt(searchInputQuery.peerYearMin)), where("peerYear", "<=", parseInt(searchInputQuery.peerYearMax)))); }
                    else if (searchInputQuery.peerYearMin) { conditions.push(where("peerYear", ">=", parseInt(searchInputQuery.peerYearMin))); }
                    else if (searchInputQuery.peerYearMax) { conditions.push(where("peerYear", "<=", parseInt(searchInputQuery.peerYearMax))); }

                    if (searchInputQuery.subscribeEvent) conditions.push(where("subscribeEvents", "==", true));
                    if (searchInputQuery.ATSI) conditions.push(where("ATSI", "==", true));
                    if (searchInputQuery.subscribeNewsLetter) conditions.push(where("subscribeNewsLetter", "==", true));

                    if (searchInputQuery.skills) conditions.push(where("skills", "array-contains", searchInputQuery.skills));
                    if (searchInputQuery.personalInterest) conditions.push(where("personalInterests", "array-contains", searchInputQuery.personalInterest));

                    if (conditions.length > 0) {
                        let docQuery = query(collection(db, "organisations", orgId, "alumni"), or(...conditions));
                        const querySnapshot = await getDocs(docQuery);
                        querySnapshot.forEach((doc) => {
                            if (doc.exists()) {
                                const id = doc.id;
                                const alumni = getAlumni(doc.data());

                                const alumniTable: AlumniTable = {
                                    objectID: id,
                                    title: alumni.title ?? "",
                                    firstName: alumni.firstName ?? "",
                                    lastName: alumni.lastName ?? "",
                                    name: joinStringWithSpace(alumni.firstName ?? "", alumni.lastName ?? "") ?? "",
                                    emailPreferred: alumni.emailPreferred ?? "",
                                    currentEmployment: alumni.currentEmployment ? alumni.currentEmployment.map(item => item.jobTitle).join(", ") : "",
                                    educationDegree: alumni.educationDegree ? alumni.educationDegree.map(item => item.degree).join(", ") : "",
                                    peerYear: alumni.peerYear ?? -1,
                                    living: joinStringWithComma(alumni.suburb ?? "", joinStringWithComma(alumni.city ?? "", alumni.country ?? "")) ?? "-",
                                    photoUrl: alumni.photoUrl,
                                }
                                if (!doesIdExist(id)) {
                                    alumniRows.push(alumniTable);
                                    alumniIds.add(id);
                                }
                            }
                        });
                    }
                }
                //Sub Collection
                if (searchInputQuery && (searchInputQuery.schoolType || searchInputQuery.schoolCampus || searchInputQuery.schoolHouse)) {
                    const conditions = [];
                    if (searchInputQuery.schoolType) conditions.push((where("type", "==", searchInputQuery.schoolType)));
                    if (searchInputQuery.schoolCampus) conditions.push((where("schoolCampus", "==", searchInputQuery.schoolCampus)));
                    if (searchInputQuery.schoolHouse) conditions.push((where("schoolHouse", "==", searchInputQuery.schoolHouse)));

                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "schooling"), and(where("orgId", "==", orgId), or(...conditions)));
                        const querySnapshot = await getDocs(docQuery);
                        querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const schooling = getSchooling(doc.data());
                                if (schooling && schooling.alumniId) alumniIds.add(schooling.alumniId);
                            }
                        });
                    }
                }
                if (searchInputQuery && (searchInputQuery.jobTitle || searchInputQuery.nameOfCompany || searchInputQuery.employmentType || searchInputQuery.industry)) {
                    const conditions = [];
                    if (searchInputQuery.jobTitle) conditions.push((where("jobTitle", "==", searchInputQuery.jobTitle)));
                    if (searchInputQuery.nameOfCompany) conditions.push((where("nameOfCompany", "==", searchInputQuery.nameOfCompany)));
                    if (searchInputQuery.employmentType) conditions.push((where("employmentType", "==", searchInputQuery.employmentType)));
                    if (searchInputQuery.industry) conditions.push((where("industry", "==", searchInputQuery.industry)));

                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "employment"), and(where("orgId", "==", orgId), or(...conditions)));
                        const querySnapshot = await getDocs(docQuery);

                        const alumniTablePromises = querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const employment = getEmployment(doc.data());
                                if (employment && employment.alumniId) alumniIds.add(employment.alumniId);
                            }
                        });
                        (await Promise.all(alumniTablePromises));
                    }
                }
                if (searchInputQuery && (searchInputQuery.educationType || searchInputQuery.degree || searchInputQuery.fieldOfStudy || searchInputQuery.institution)) {
                    const conditions = [];
                    if (searchInputQuery.educationType) conditions.push((where("type", "==", searchInputQuery.educationType)));
                    if (searchInputQuery.degree) conditions.push((where("degree", "==", searchInputQuery.degree)));
                    if (searchInputQuery.fieldOfStudy) conditions.push((where("fieldOfStudy", "array-contains", searchInputQuery.fieldOfStudy)));
                    if (searchInputQuery.institution) conditions.push((where("institution", "==", searchInputQuery.institution)));
                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "education"), and(where("orgId", "==", orgId), or(...conditions)));
                        const querySnapshot = await getDocs(docQuery);

                        const alumniTablePromises = querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const education = getEducation(doc.data());
                                if (education && education.alumniId) alumniIds.add(education.alumniId);
                            }
                        });
                        (await Promise.all(alumniTablePromises));
                    }
                }
                if (searchInputQuery && (searchInputQuery.engagementType || searchInputQuery.engagementInvolvement)) {
                    const conditions = [];
                    if (searchInputQuery.engagementType) {
                        conditions.push(where("type", "==", searchInputQuery.engagementType));
                    }

                    // Apply the initial conditions to the query
                    let engagementsQuery = conditions.length > 0 ? query(collection(db, "organisations", orgId, "engagements"), ...conditions) : query(collection(db, "organisations", orgId, "engagements"));

                    // Execute the query
                    const querySnapshot = await getDocs(engagementsQuery);
                    querySnapshot.forEach(doc => {
                        if (doc.exists()) {
                            const engagement = doc.data();
                            // Process linked alumni based on involvement if specified
                            if (searchInputQuery.engagementInvolvement) {
                                engagement.linkedAlumnis?.forEach((alumni: LinkedAlumni) => {
                                    if (alumni.involvement === searchInputQuery.engagementInvolvement) {
                                        alumniIds.add(alumni.alumniId);
                                    }
                                });
                            } else {
                                // Add all linked alumni if no specific involvement is required
                                engagement.linkedAlumnis?.forEach((alumni: LinkedAlumni) => {
                                    alumniIds.add(alumni.alumniId);
                                });
                            }
                        }
                    });
                }

                await Promise.all(Array.from(alumniIds).map(async id => { alumniRows.push(await getAlumniTable(id) as AlumniTable) }));
                setAlumniDataRows(alumniRows);
                setTotalRows(alumniRows.length);
                setIsSearching(false);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => { fetchOrData(); }, [orgId, searchInputQuery, isAndMode]);

    const fetchAndData = async () => {
        try {
            if (orgId && isAndMode) {
                const alumniIds = new Set<string>();
                const alumniSchoolIds = new Set<string>();
                const alumniEmploymentIds = new Set<string>();
                const alumniEducationIds = new Set<string>();
                const alumniEngagementIds = new Set<string>();
                const alumniRows: AlumniTable[] = [];
                const doesIdExist = (id: string) => { return alumniRows.some(alumni => alumni.objectID === id); };
                const getAlumniTable = async (id: string): Promise<AlumniTable | null> => {
                    try {
                        const alumniSnap = await getDoc(doc(db, "organisations", orgId, "alumni", id));
                        if (alumniSnap.exists()) {
                            const alumni = getAlumni(alumniSnap.data());
                            const alumniTable: AlumniTable = {
                                objectID: alumniSnap.id,
                                title: alumni.title ?? "",
                                firstName: alumni.firstName ?? "",
                                lastName: alumni.lastName ?? "",
                                name: joinStringWithSpace(alumni.firstName ?? "", alumni.lastName ?? "") ?? "",
                                emailPreferred: alumni.emailPreferred ?? "",
                                currentEmployment: alumni.currentEmployment ? alumni.currentEmployment.map(item => item.jobTitle).join(", ") : "",
                                educationDegree: alumni.educationDegree ? alumni.educationDegree.map(item => item.degree).join(", ") : "",
                                peerYear: alumni.peerYear ?? -1,
                                living: joinStringWithComma(alumni.suburb ?? "", joinStringWithComma(alumni.city ?? "", alumni.country ?? "")) ?? "-",
                                photoUrl: alumni.photoUrl,
                            }
                            return alumniTable;
                        }
                        else return null;
                    }
                    catch (error) {
                        return null;
                    }
                };
                setIsSearching(true);
                if (searchInputQuery && (searchInputQuery.first_name || searchInputQuery.last_name || searchInputQuery.gender || searchInputQuery.pronouns || searchInputQuery.ATSI || searchInputQuery.peerYearMin || searchInputQuery.peerYearMax || searchInputQuery.skills || searchInputQuery.personalInterest || searchInputQuery.subscribeEvent || searchInputQuery.subscribeNewsLetter)) {
                    const conditions = [];
                    if (searchInputQuery.first_name) conditions.push(where("firstName", "==", searchInputQuery.first_name));
                    if (searchInputQuery.last_name) conditions.push(where("lastName", "==", searchInputQuery.last_name));
                    if (searchInputQuery.gender) conditions.push(where("gender", "==", searchInputQuery.gender));
                    if (searchInputQuery.pronouns) conditions.push(where("pronouns", "==", searchInputQuery.pronouns));
                    if (searchInputQuery.peerYearMin && searchInputQuery.peerYearMax) { conditions.push(and(where("peerYear", ">=", parseInt(searchInputQuery.peerYearMin)), where("peerYear", "<=", parseInt(searchInputQuery.peerYearMax)))); }
                    else if (searchInputQuery.peerYearMin) { conditions.push(where("peerYear", ">=", parseInt(searchInputQuery.peerYearMin))); }
                    else if (searchInputQuery.peerYearMax) { conditions.push(where("peerYear", "<=", parseInt(searchInputQuery.peerYearMax))); }

                    if (searchInputQuery.subscribeEvent) conditions.push(where("subscribeEvents", "==", true));
                    if (searchInputQuery.ATSI) conditions.push(where("ATSI", "==", true));
                    if (searchInputQuery.subscribeNewsLetter) conditions.push(where("subscribeNewsLetter", "==", true));

                    if ((searchInputQuery.skills && searchInputQuery.personalInterest) || searchInputQuery.skills) {
                        conditions.push(where("skills", "array-contains", searchInputQuery.skills));
                    }
                    else if (searchInputQuery.personalInterest) {
                        conditions.push(where("personalInterests", "array-contains", searchInputQuery.personalInterest));
                    }
                    if (conditions.length > 0) {
                        let docQuery = query(collection(db, "organisations", orgId, "alumni"), and(...conditions));
                        if (docQuery) {
                            const querySnapshot = await getDocs(docQuery);
                            querySnapshot.forEach((doc) => {
                                if (doc.exists()) {
                                    const id = doc.id;
                                    const alumni = getAlumni(doc.data());
                                    const alumniTable: AlumniTable = {
                                        objectID: id,
                                        title: alumni.title ?? "",
                                        firstName: alumni.firstName ?? "",
                                        lastName: alumni.lastName ?? "",
                                        name: joinStringWithSpace(alumni.firstName ?? "", alumni.lastName ?? "") ?? "",
                                        emailPreferred: alumni.emailPreferred ?? "",
                                        currentEmployment: alumni.currentEmployment ? alumni.currentEmployment.map(item => item.jobTitle).join(", ") : "",
                                        educationDegree: alumni.educationDegree ? alumni.educationDegree.map(item => item.degree).join(", ") : "",
                                        peerYear: alumni.peerYear ?? -1,
                                        living: joinStringWithComma(alumni.suburb ?? "", joinStringWithComma(alumni.city ?? "", alumni.country ?? "")) ?? "-",
                                        photoUrl: alumni.photoUrl,
                                    }
                                    if (searchInputQuery.skills && searchInputQuery.personalInterest) {
                                        const pinterests = alumni.personalInterests as string[];
                                        if (alumni.personalInterests && pinterests.includes(searchInputQuery.personalInterest)) {
                                            if (!doesIdExist(id)) { alumniIds.add(id); }
                                        }
                                    }
                                    else {
                                        if (!doesIdExist(id)) { alumniIds.add(id); }
                                    }
                                }
                            });
                        }
                    }
                }
                if (searchInputQuery && (searchInputQuery.schoolType || searchInputQuery.schoolCampus || searchInputQuery.schoolHouse)) {
                    const conditions = [];
                    if (searchInputQuery.schoolType) conditions.push((where("type", "==", searchInputQuery.schoolType)));
                    if (searchInputQuery.schoolCampus) conditions.push((where("schoolCampus", "==", searchInputQuery.schoolCampus)));
                    if (searchInputQuery.schoolHouse) conditions.push((where("schoolHouse", "==", searchInputQuery.schoolHouse)));

                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "schooling"), and(where("orgId", "==", orgId), and(...conditions)));
                        const querySnapshot = await getDocs(docQuery);
                        const alumniTablePromises = querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const schooling = await getSchooling(doc.data());
                                if (schooling && schooling.alumniId) alumniSchoolIds.add(schooling.alumniId);
                                console.log(".....Exe: 1 ----<>")
                            }
                        });
                        (await Promise.all(alumniTablePromises));
                    }
                }
                if (searchInputQuery && (searchInputQuery.jobTitle || searchInputQuery.nameOfCompany || searchInputQuery.employmentType || searchInputQuery.industry)) {
                    const conditions = [];
                    if (searchInputQuery.jobTitle) conditions.push((where("jobTitle", "==", searchInputQuery.jobTitle)));
                    if (searchInputQuery.nameOfCompany) conditions.push((where("nameOfCompany", "==", searchInputQuery.nameOfCompany)));
                    if (searchInputQuery.employmentType) conditions.push((where("employmentType", "==", searchInputQuery.employmentType)));
                    if (searchInputQuery.industry) conditions.push((where("industry", "==", searchInputQuery.industry)));

                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "employment"), and(where("orgId", "==", orgId), and(...conditions)));
                        const querySnapshot = await getDocs(docQuery);

                        const alumniTablePromises = querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const employment = getEmployment(doc.data());
                                if (employment && employment.alumniId) alumniEmploymentIds.add(employment.alumniId);
                                console.log(".....Exe: 2 ----<>")
                            }
                        });
                        (await Promise.all(alumniTablePromises));
                    }
                }
                if (searchInputQuery && (searchInputQuery.educationType || searchInputQuery.degree || searchInputQuery.fieldOfStudy || searchInputQuery.institution)) {
                    const conditions = [];
                    if (searchInputQuery.educationType) conditions.push((where("type", "==", searchInputQuery.educationType)));
                    if (searchInputQuery.degree) conditions.push((where("degree", "==", searchInputQuery.degree)));
                    if (searchInputQuery.fieldOfStudy) conditions.push((where("fieldOfStudy", "array-contains", searchInputQuery.fieldOfStudy)));
                    if (searchInputQuery.institution) conditions.push((where("institution", "==", searchInputQuery.institution)));
                    if (conditions.length > 0) {
                        let docQuery = query(collectionGroup(db, "education"), and(where("orgId", "==", orgId), and(...conditions)));
                        const querySnapshot = await getDocs(docQuery);

                        const alumniTablePromises = querySnapshot.docs.map(async (doc) => {
                            if (doc.exists()) {
                                const education = getEducation(doc.data());
                                if (education && education.alumniId) alumniEducationIds.add(education.alumniId);
                                console.log(".....Exe: 3 ----<>")
                            }
                        });
                        (await Promise.all(alumniTablePromises));
                    }
                }
                if (searchInputQuery && (searchInputQuery.engagementType || searchInputQuery.engagementInvolvement)) {
                    const engagementsQuery = query(collection(db, "organisations", orgId, "engagements"));
                    const engagementsSnapshot = await getDocs(engagementsQuery);

                    engagementsSnapshot.forEach(doc => {
                        if (doc.exists()) {
                            const engagement = doc.data();

                            // Match for engagementType
                            const typeMatch = !searchInputQuery.engagementType || engagement.type === searchInputQuery.engagementType;

                            // Check for involvement within the fetched documents, only if typeMatch is true or engagementType wasn't specified
                            if (typeMatch) {
                                let involvementMatch = false; // Default to false, change to true if any alumni matches the involvement
                                engagement.linkedAlumnis?.forEach((alumni: LinkedAlumni) => {
                                    if (!searchInputQuery.engagementInvolvement || alumni.involvement === searchInputQuery.engagementInvolvement) {
                                        involvementMatch = true;
                                        alumniEngagementIds.add(alumni.alumniId);
                                    }
                                });

                                // If no specific involvement is specified and the type matches, add all linked alumni
                                if (!searchInputQuery.engagementInvolvement) {
                                    engagement.linkedAlumnis?.forEach((alumni: LinkedAlumni) => {
                                        alumniEngagementIds.add(alumni.alumniId);
                                    });
                                } else if (involvementMatch) {
                                    // Only add alumni that matched the specific involvement
                                    engagement.linkedAlumnis?.forEach((alumni: LinkedAlumni) => {
                                        if (alumni.involvement === searchInputQuery.engagementInvolvement) {
                                            alumniEngagementIds.add(alumni.alumniId);
                                        }
                                    });
                                }
                            }
                        }
                    });
                }


                if (searchInputQuery) {
                    // Filter out any empty sets to avoid affecting the intersection negatively
                    const allSets = [alumniIds, alumniSchoolIds, alumniEmploymentIds, alumniEducationIds, alumniEngagementIds];
                    const commonIdsSet = intersectSets(allSets, getQueryFlags(searchInputQuery));
                    await Promise.all(Array.from(commonIdsSet).map(async id => {
                        const alumniTable = await getAlumniTable(id);
                        if (alumniTable) { alumniRows.push(alumniTable); }
                    }));
                }

                setAlumniDataRows(alumniRows);
                setTotalRows(alumniRows.length);
                setIsSearching(false);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => { fetchAndData(); }, [orgId, searchInputQuery, isAndMode]);


    const CustomPaginationFooter = () => {
        const currentIndex = table.getState().pagination.pageIndex + 1;
        const totalPageCount = table.getPageCount();
        const pageSize = table.getState().pagination.pageSize;

        const startItem = currentIndex === 1 ? 1 : (currentIndex - 1) * pageSize + 1;
        const endItem = currentIndex === totalPageCount ? totalRows : currentIndex * pageSize;

        return (
            <Stack style={{ width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center', marginTop: pxToRem(20) }}>
                <Stack direction={"row"} style={{ padding: 4, alignItems: 'center' }}>
                    <Stack className={styles.datagrid_footer_text} alignItems={"center"}> {`${startItem} - ${endItem} of ${totalRows}`} </Stack>
                    <IconButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M15 18L9 12L15 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg> </IconButton>
                    <IconButton disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"> <path d="M9 18L15 12L9 6" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>
                </Stack>
            </Stack>
        );
    }

    const clearTable = () => {
        setAlumniDataRows([]);
        setIsSearching(false);
    }

    const csvExport = () => {
        const csvHeader = ["First Name", "Last Name", "Email", "Employment", "Living", "Peer Year", "Education"];
        const data = [
            csvHeader,
            ...alumniDataRows.map(data => [
                data.firstName ?? "-",
                data.lastName ?? "-",
                data.emailPreferred ?? "-",
                data.currentEmployment ?? "-",
                data.living ?? "-",
                data.peerYear === -1 ? "-" : data.peerYear + "" ?? "-",
                data.educationDegree ?? "-"
            ])
        ];
        setCsvData(data);
    }
    const currentLocation = useLocation();
    return (
        <>
            {/* <CSVLink style={{position:'relative'}} data={csvData} filename={`${moment().format("DD/MM/YYYY HH:mm:ss ")}_${orgName}_Alumni_Data`} asyncOnClick={true} onClick={(e,d) => { csvExport(); d(true);}}>
                <IconButton style={{ position: 'absolute', right: pxToRem(10), top: pxToRem(-48) }}> 
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"> <g id="Export"> <g id="share-03"> <path id="Icon" d="M27 15L27 9.00001M27 9.00001H21M27 9.00001L18 18M16 9H13.8C12.1198 9 11.2798 9 10.638 9.32698C10.0735 9.6146 9.6146 10.0735 9.32698 10.638C9 11.2798 9 12.1198 9 13.8V22.2C9 23.8802 9 24.7202 9.32698 25.362C9.6146 25.9265 10.0735 26.3854 10.638 26.673C11.2798 27 12.1198 27 13.8 27H22.2C23.8802 27 24.7202 27 25.362 26.673C25.9265 26.3854 26.3854 25.9265 26.673 25.362C27 24.7202 27 23.8802 27 22.2V20" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/> </g> </g> </svg> 
                </IconButton>
            </CSVLink> */}
            <Stack direction={"row"} className={styles.searchBarWrapper} spacing={2}>
                {
                    alumniPermission && alumniPermission === PermissionState.EDITOR &&
                    <Stack className={styles.createAlumni} onClick={() => { setOpenAddAlumni(true) }}>
                        <svg xmlns='http://www.w3.org/2000/svg' width={pxToRem(24)} height={pxToRem(24)} viewBox='0 0 24 25' fill='none' > <path d='M12 5.16797V19.168M5 12.168H19' stroke='white' strokeLinecap='round' strokeLinejoin='round' /> </svg>
                    </Stack>
                }
                <InputTextField
                    containerStyle={{ flex: 1 }}
                    value={searchInput}
                    placeholder="Start typing..."
                    onChange={handleInputChange}
                    adornment={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M21 21.168L15.0001 15.168M17 10.168C17 14.034 13.866 17.168 10 17.168C6.13401 17.168 3 14.034 3 10.168C3 6.30198 6.13401 3.16797 10 3.16797C13.866 3.16797 17 6.30198 17 10.168Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg>}
                />
                <SecondaryButton onClick={() => setAdvanceSearchDialog(true)} className={styles.searchAdvance}>
                    <div style={{ position: 'absolute', right: pxToRem(-16), top: pxToRem(-16), padding: pxToRem(4), width: pxToRem(32), height: pxToRem(32), background: '#447D75', borderRadius: pxToRem(100), color: 'white' }}>{filterCount}</div>
                    <small className={styles.shades100}>Advanced Search</small>
                </SecondaryButton>
            </Stack>
            <Stack className={styles.advanceSearchHeader} direction={"row"}>
                <label>Total Matches: {totalRows}</label>
                <PrimaryFlatButton onClick={() => { clearTable(); clearAllFilter(); }} className={styles.clearFilterButton}> Clear All Filters </PrimaryFlatButton>
            </Stack>
            <Stack spacing={4} className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} style={{ textAlign: 'left' }} >
                                        <Stack style={{ justifyContent: 'space-between' }} direction={"row"}>
                                            <Stack direction={"row"} style={{ alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                            </Stack>
                                            {
                                                header.id != "lastUpdated" ?
                                                    <Stack className={styles.sortButton} {...{ onClick: header.column.getToggleSortingHandler() }}>
                                                        <Stack className={`${{ desc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M18 15L12 9L6 15" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg></Stack>
                                                        <Stack className={`${{ asc: styles.sortButtonActive }[header.column.getIsSorted() as string]}`}><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(16)} height={pxToRem(16)} viewBox="0 0 24 24" fill="none"> <path d="M6 9L12 15L18 9" stroke="black" strokeLinecap="round" strokeLinejoin="round" /> </svg> </Stack>
                                                    </Stack>
                                                    :
                                                    null
                                            }
                                        </Stack>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            isSearching
                                ?
                                <RowSkeleton />
                                :
                                table.getRowModel().rows.map(row => (
                                    <tr key={row.id} onClick={() => { rowClick(row.original.objectID ?? "") }}>
                                        {
                                            row.getVisibleCells().map(cell => {
                                                let cellJSX: JSX.Element | undefined = undefined;
                                                if (cell.column.id === "name") {
                                                    cellJSX = <Stack key={cell.id} className={styles.alumni}>{AlumniDataItemInsideTable(row.original.title ?? "", row.original.firstName ?? "", row.original.lastName ?? "", row.original.emailPreferred ?? "", row.original.photoUrl ?? "")}</Stack>;
                                                }
                                                else if (cell.column.id === "peerYear") {
                                                    cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.peerYear === -1 ? "-" : row.original.peerYear ?? "-"}</Stack>;
                                                }
                                                else if (cell.column.id === "currentEmployment") {
                                                    cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.currentEmployment || "-"}</Stack>;
                                                }
                                                else if (cell.column.id === "living") {
                                                    cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.living || "-"}</Stack>;
                                                }
                                                else if (cell.column.id === "educationDegree") {
                                                    cellJSX = <Stack key={cell.id} className={styles.text}>{row.original.educationDegree || "-"}</Stack>;
                                                }
                                                // return( <td key={cell.id}> {cellJSX} </td> )
                                                return (
                                                    <td key={cell.id} style={{ padding: 0, flex: 1, height: pxToRem(52), justifyContent: 'center', alignItems: 'center' }}>
                                                        <Link to={`${currentLocation.pathname}/${row.original.objectID}`} className={styles['row-link']}>
                                                            {cellJSX}
                                                        </Link>
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                ))
                        }
                        {alumniDataRows.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'start', padding: '20px', height: pxToRem(52), color: '#A3A3A3' }}>

                                    No matches found, please try again.

                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Stack>
            {
                alumniDataRows.length <= 0 ?
                    addNewRow()
                    :
                    CustomPaginationFooter()
            }
        </>
    );
}

export default memo(AdvanceSearchTable);