// userAPI.js 
import { DocumentData, doc, getDoc } from "firebase/firestore";
import { auth, db } from "."; // Adjust this path as necessary
import { getUserNameById } from "./userApis";

/**
 * Get the current user's ID
 */
export const getCurrentUserId = () => {
  return auth.currentUser ? auth.currentUser.uid : null;
};

/**
 * Fetch user details from Firestore using user ID
 */
export const getUserDetails = async (userId: string) => {
  if (!userId) return null;

  try {
    const userDoc = doc(db, "users", userId);
    const docSnap = await getDoc(userDoc);

    if (docSnap.exists()) {
      return docSnap.data(); // Returns the entire user document
    } else {
      console.log("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};


/**
 * Fetches names of users associated with a given organization ID.
 * @param orgId The ID of the organization to fetch user names for.
 * @returns An array of objects with each containing a user's ID and name.
 */
export const getUsersByOrgId = async (orgId: string): Promise<Array<{ key: string; name: string | null }> | null> => {
  if (!orgId) return null;

  try {
    const orgDocRef = doc(db, "organisations", orgId);
    const orgDocSnap = await getDoc(orgDocRef);

    if (orgDocSnap.exists()) {
      const orgData = orgDocSnap.data();
      const userIds: string[] = orgData.users; // Assuming 'users' field contains an array of user IDs

      const userNamesPromises = userIds.map(async (userId) => {
        const res = await getUserNameById(userId);
        if (res.code === 200) {
          return { key: userId, name: res.data };
        } else {
          console.error(`Failed to fetch user name for ID ${userId}`, res.errorMsg);
          return { key: userId, name: null }; // Handle error case or non-existent user
        }
      });
      const userNames = await Promise.all(userNamesPromises);

      return userNames; // Returns an array of { key: userId, name: userName }
    } else {
      console.log("No such organisation document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching organisation users:", error);
    return null;
  }
};

/**
 * Fetches a list of user IDs associated with a given organization ID.
 * @param orgId The ID of the organization to fetch user IDs for.
 * @returns An array of user IDs or null if not found or on error.
 */
export const getUserListByOrgId = async (orgId: string): Promise<string[] | null> => {
  if (!orgId) {
    console.log("Organization ID is required.");
    return null;
  }

  try {
    const orgDocRef = doc(db, "organisations", orgId);
    const orgDocSnap = await getDoc(orgDocRef);

    if (orgDocSnap.exists()) {
      const orgData = orgDocSnap.data();
      // Assuming 'users' is the field name containing an array of user IDs in the organisation document.
      const userIds: string[] = orgData.users || [];

      return userIds; // Returns the list of user IDs
    } else {
      console.log("No such organisation document exists.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user list by organisation ID:", error);
    return null;
  }
};