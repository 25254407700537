import { DocumentData, FieldValue, Timestamp } from "firebase/firestore";

export type Registration = {
    [organisationUid: string]: {
        forms: {
            [formUid: string]: Form;
        };
        campuses?: string[];
        schoolHouses?: string[];
        logo?: string;
    }
}

export type Form = {
    formId?: string,
    formInfo: FormInfo;
    formName: string;
    responses: {
        [responseUid: string]: FormResponse;
    };
    totalCount: number;
    approvedCount: number;
    rejectCount: number;
    pendingApproval: number;
    isActive: boolean;
    createdAt?: FieldValue | number;
    closedAt?: FieldValue | number;
    lastEdited?: FieldValue | number;
    lastSubmission?: FieldValue | number;
}

export const getForm = (data: DocumentData): Form => {
    return {
        formInfo: data.formInfo,
        formName: data.formName,
        responses: data.responses,
        totalCount: data.totalCount,
        approvedCount: data.approvedCount,
        rejectCount: data.rejectCount,
        pendingApproval: data.pendingApproval,
        isActive: data.isActive,
        createdAt: data.createdAt?(data.createdAt as Timestamp).toMillis():undefined,
        closedAt: data.createdAt?(data.createdAt as Timestamp).toMillis():undefined,
        lastEdited: data.lastEdited?(data.lastEdited as Timestamp).toMillis():undefined,
        lastSubmission: data.lastSubmission?(data.lastSubmission as Timestamp).toMillis():undefined,
    }
}

export type FormResponse<FV = never> = {
    firstName?: string | FV;
    lastName?: string | FV;
    preferredName?: string | FV;
    email?: string | FV;
    contactNumber?: string | FV;
    linkedIn?: string | FV;
    communicationOpt?: boolean | FV;
    newsletterOpt?: boolean | FV;
    campus?: string | FV;
    schoolHouse?: string | FV;
    peerYear?: number | FV;
    yearEnrolled?: number | FV;

    pronouns?: string | FV;
    gender?: string | FV;
    title?: string | FV;
    personalInterests?: string[] | FV;
    skills?: string[] | FV;

    rejected: boolean | FV;
    submittedAt: FieldValue | number;
}

export type FormInfo<FV=null> = {
    header?: string | FV;
    description?: string | FV;
    closeStatement?: string | FV;
    defaultPeerYear?: number | FV;
}

export const getResponse = (data: DocumentData): FormResponse => {
    return {
        firstName: data.firstName,
        lastName: data.lastName,
        preferredName: data.preferredName,
        email: data.email,
        contactNumber: data.contactNumber,
        linkedIn: data.linkedIn,
        communicationOpt: data.communicationOpt,
        newsletterOpt: data.newsletterOpt,
        campus: data.campus,
        schoolHouse: data.schoolHouse,
        peerYear: data.peerYear,
        yearEnrolled: data.yearEnrolled,

        pronouns: data.pronouns,
        gender: data.gender,
        title: data.title,
        personalInterests: data.personalInterests,
        skills: data.skills,

        rejected: data.rejected,
        submittedAt: (data.submittedAt as Timestamp).toMillis(),
    }
}

export const getFormInfo = (data: DocumentData): FormInfo => {
    return {
        header: data.formInfo.header,
        description: data.formInfo.description,
        closeStatement: data.formInfo.closeStatement,
        defaultPeerYear: data.formInfo.defaultPeerYear,
    }
}