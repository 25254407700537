import styles from "./index.module.scss";
import Modal from "@mui/material/Modal";
import pxToRem from "../../helpers/pxToRem";
import globalStyles from "../../styles/_stylesParams.module.scss";
import Stack from "@mui/material/Stack";
import { Avatar, Divider } from "@mui/material";
import { PrimaryButton } from "../Buttons/ButtonUI";
import betaComingSoon_SVG from "../../assets/images/beta/beta-coming-soon.svg";
import { memo } from "react";

const style = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", boxShadow: `0px ${pxToRem(4)} ${pxToRem(4)} 0px rgba(0, 0, 0, 0.25)`, };
export type ModalType = "ERROR" | "DEFAULT";
export interface ICustomizedModal { title?:string; subTitle?:string; type?: ModalType; open: boolean; handleClose: () => void; children: JSX.Element; className?: string; isLoading?: boolean; loadingStr?: string; width?: number; orgState?: 'BETA' | 'EARLY ACCESS' | 'REGULAR';} // Adjusted to use orgState}
const CustomizedModal = ({ type, open, handleClose, children, className, isLoading = false, loadingStr, width }: ICustomizedModal) => (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' className={className ?? ""} >
        <Stack sx={[style, {width: width ? pxToRem(width): pxToRem(804)}]} className={styles.modalContentWrapper}>
            { isLoading? <div className={styles.loadingOverlay}> <Stack spacing={2} style={{alignItems:"center", justifyContent: "center"}}> <div style={{alignItems:"center"}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={pxToRem(64)} height={pxToRem(64)} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{ display: 'block', animationPlayState: 'paused' }} > <circle cx="50" cy="50" fill="none" stroke="#549189" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="matrix(1,0,0,1,0,0)" style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)', animationPlayState: 'paused' }} ></circle> </svg> </div> <span>{loadingStr}</span> </Stack> </div> : null }
            <div className={`${styles.colorBanner}`} style={{ background: type === "ERROR" ? globalStyles["secondaryPink600"] : globalStyles["primary500"], }} ></div>
            <div>{children}</div>
        </Stack>
    </Modal>
);


export const BetaModal = ({ open, handleClose, children, orgState }: ICustomizedModal) => {
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Stack sx={[style, { width: pxToRem(600) }]} className={styles.modalContentWrapper}>
                {orgState === 'BETA' && (
                    <Stack spacing={1.5}>
                        <big className={styles.betaModalHeader}>You're part of our Beta Program!</big>
                        <div style={{ backgroundColor: styles['neutrals100'], height: pxToRem(1) }} />
                        <small className={styles.betaModalSubtitle} style={{ textAlign: 'justify' }}>
                            Enjoy your free, unlimited access to Loop for the rest of 2024.
                            <br /><br />
                            We're continuously updating Loop's platform to improve your experience. If you have any comments or concerns please log it on our help desk.
                        </small>
                        <div style={{ backgroundColor: styles['neutrals100'], height: pxToRem(1), marginTop: pxToRem(28) }} />
                        <Stack style={{ direction: 'rtl' }}>
                            <PrimaryButton style={{ width: pxToRem(130), height: pxToRem(32) }} onClick={handleClose}>Close</PrimaryButton>
                        </Stack>
                    </Stack>
                )}
                {orgState === 'EARLY ACCESS' && (
                    <Stack spacing={1.5}>
                        <big className={styles.betaModalHeader}>You are an early access user!</big>
                        <div style={{ backgroundColor: styles['neutrals100'], height: pxToRem(1) }} />
                        <small className={styles.betaModalSubtitle} style={{ textAlign: 'justify' }}>
                            Enjoy your free, unlimited access to Loop until 1 July 2024.
                            <br /><br />
                            We're continuously updating Loop's platform to improve your experience. If you have any comments or concerns please log it on our help desk.
                        </small>
                        <div style={{ backgroundColor: styles['neutrals100'], height: pxToRem(1), marginTop: pxToRem(28) }} />
                        <Stack style={{ direction: 'rtl' }}>
                            <PrimaryButton style={{ width: pxToRem(130), height: pxToRem(32) }} onClick={handleClose}>Close</PrimaryButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Modal>
    );
};


export const BetaComingModal = ({title, subTitle, open, handleClose, children} : ICustomizedModal)=>{
    return(
        <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Stack sx={[style,{width:pxToRem(600)}]} className={styles.modalContentWrapper}>
               {/* <div className={`${styles.colorBanner}`} style={{ background: globalStyles["secondaryBlue500"] }} ></div> */}
                <Stack spacing={1.5}>
                    <Stack style={{ alignItems:'center', justifyContent:'center'}}><img src={betaComingSoon_SVG} style={{ width: pxToRem(400), padding: pxToRem(20)}}/></Stack>
                    <big className={styles.betaComingSoonModalTitle}>{title}</big>
                    <small className={styles.betaComingSoonModalSubTitle} style={{ textAlign: 'center'}}>{subTitle}</small>
                    {/* <Divider style={{backgroundColor: styles['neutrals100'], marginTop: pxToRem(28), height:pxToRem(1)}}/> */}
                    <div style={{backgroundColor:styles['neutrals100'], height:pxToRem(1),marginTop: pxToRem(28)}}/>
                    <Stack style={{ direction:'rtl'}}>
                        <PrimaryButton style={{width: pxToRem(132), height: pxToRem(32)}} onClick={handleClose}>Close</PrimaryButton>
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    )
}




export default memo(CustomizedModal);