import { Avatar, IconButton, Stack } from "@mui/material";
import pxToRem from "../../../helpers/pxToRem";
import styles from "../index.module.scss";

import { useSelector } from "react-redux";
import loopLogo from "../../../assets/images/shared/loopSquareLogo.png";
import { RootState } from "../../../redux";

interface FormPageProps {
    children?: React.ReactNode;
    subtitle: string;
    handleBackStep: (exactPage?:number)=>void;
    backDestination?: number;
}

const FormPage = ({children, subtitle, handleBackStep, backDestination}: FormPageProps) => {

    const logo = useSelector((state:RootState) => state.alumniReg.logo);

    return (
        <Stack className={styles.pageBackground} alignItems="center" justifyContent="space-between">
            <Stack className={styles.regContainer} alignItems="center">
                <Stack direction="row" alignItems="flex-start" style={{width:'100%'}}>
                    <Stack direction="row" alignItems="center" justifyContent="center" style={{height:"80px"}} spacing="">
                        <IconButton onClick={()=>handleBackStep(backDestination)}>
                            <svg width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13L1 7L7 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </IconButton>
                        <p className="regular-400-16" style={{color:'white'}}>Back</p>
                    </Stack>
                </Stack>
                {
                    logo ?
                        <Avatar src={logo} className={styles.regLogo}/>
                    : 
                        <Avatar sx={{backgroundColor: "white"}} className={styles.regLogoBg}>
                            <Avatar src={loopLogo} className={styles.regLogo}/>
                        </Avatar> 
                }
                <p className={styles.subtitle}>{subtitle}</p>
                <Stack className={styles.formBackground} alignItems="center" justifyContent="space-between">
                    {children}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default FormPage;