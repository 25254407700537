import { memo, useState } from "react";
import { Chip, IconButton, Stack, styled } from "@mui/material";
import CustomizedModal from "../../../components/CustomizedModal";
import styles from "./../index.module.scss";
import { PrimaryButton, SecondaryButton, WarningButton } from "../../../components/Buttons/ButtonUI";
import TagInput from '../../../components/TagComponent/index';
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { createPersonalInterest } from "../../../firebase/networkApis";
import Line from "../../../components/Line/Line";
import { ITabInterface } from "./TabInterfaces";
import pxToRem from "../../../helpers/pxToRem";
import AlertComponent from "../../../components/AlertComponent";
import { PermissionState } from "../../../firebase/types-organisations";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

export type TPersonalInterest = string;
const PersonalInterestTab = ({orgId, orgName, networkId, personalInterestList}: ITabInterface) => {
    console.log("PERSONAL INTEREST TAB RENDERNGINIGIGNINGIGN -= - -- - - - - - -- ")
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingStatusStr, setLoadingStatusStr] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isPersonalInterestModal, setIsPersonalInterestModal] = useState(false);
    const ListItem = styled('li')(({ theme }) => ({ margin: theme.spacing(0.5), }));
    const [tags, setTags] = useState<string[]>([]);

    const networkPermission = useSelector((state:RootState) => state.globalState.permissions?.network);

    const PersonalInterestChips = ({tPersonalInterestArr}: { tPersonalInterestArr: TPersonalInterest[] }) => {
        const sortedPersonalInterestArr = [...tPersonalInterestArr].sort();
        const onDelete = (interest:string) => { 
            deleteData(interest);
        }
        return (
            <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', listStyle: 'none', width:"100%" }}>
                {sortedPersonalInterestArr.map((interest, index) => {
                    return ( <ListItem style={{margin:0}} key={index}><Chip style={{borderRadius:pxToRem(100), margin:`${pxToRem(12)} ${pxToRem(12)} ${pxToRem(12)} 0`, paddingLeft:pxToRem(4), paddingRight:pxToRem(4), height:pxToRem(36)}} label={interest} onDelete={(!networkPermission || networkPermission === PermissionState.EDITOR) ? () => onDelete(interest) : undefined}/></ListItem> );
                })}
            </ul>
        );
    }

    const addData = async () => {
        if (!orgId || !networkId || tags.length === 0) return;
        const singleTag = tags.length === 1 ? tags[0] : null;
        if (singleTag && personalInterestList && personalInterestList.includes(singleTag)) {
          setErrorMsg("Item already exists in our database, please add new tags.");
          return;
        }
        setLoadingStatus(true);
        setLoadingStatusStr("Saving..");
        const res = await createPersonalInterest(orgId, networkId, tags);
        if (res.code === 500) { setErrorMsg("Error: Personal interest creation failed."); }
        setLoadingStatus(false);
        setLoadingStatusStr("");
        setIsPersonalInterestModal(false);
    };
      
    const deleteData = async(currentDeleteId: string) => {
        if (!orgId || !networkId || currentDeleteId === undefined) return;
        try{ 
            const docRef = doc(db, "organisations", orgId, "network", networkId);
            await updateDoc(docRef, { personalInterests: arrayRemove(currentDeleteId)});
        }
        catch(ex){ setErrorMsg("Errors: Personal intereset delete."); console.log(ex); }
    }

    return(
        <Stack>
            <AlertComponent visible={errorMsg !== ""} title="Error" message={errorMsg} handleClose={()=>setErrorMsg("")}/>
            <CustomizedModal width={660} open={isPersonalInterestModal} handleClose={() => {}} loadingStr={loadingStatusStr} isLoading={loadingStatus}>
                <Stack>
                    <Stack direction={"row"} spacing={1.5} style={{ marginTop: 20}} className={styles.network_details_modalHeaderTitleWrapper}>
                        <div className={styles.network_details_iconWithCircleWrapper}> <svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(40)} height={pxToRem(40)} viewBox="0 0 40 40" fill="none"> <path d="M20 12C18.6193 12 17.5 13.1193 17.5 14.5C17.5 15.8807 18.6193 17 20 17C21.3807 17 22.5 15.8807 22.5 14.5C22.5 13.1193 21.3807 12 20 12ZM20 12V10M29 22.9375C26.8012 25.4287 23.5841 27 20 27C16.4159 27 13.1988 25.4287 11 22.9375M18.7448 16.6617L11 30M21.2552 16.6617L29 30" stroke="#76A8A1" strokeLinecap="round" strokeLinejoin="round"/> </svg> </div>
                        <div style={{flexGrow: 1}}>
                            <label className={styles.network_details_modalHeaderTitle}>Add New Personal Interests</label> 
                        </div>
                    </Stack>
                    <Stack className={styles.popupContent} style={{ paddingTop: pxToRem(16)}}>
                        <Stack style={{marginTop:pxToRem(20)}}><TagInput title="Personal Interests" tags={tags} setTags={setTags}/></Stack>
                    </Stack>
                    <div className={`${styles.divider} horizontalDivider`} style={{marginBottom: pxToRem(12), marginTop:pxToRem(40)}}></div>
                    <Stack direction={"row"} justifyContent='space-between'>
                        <SecondaryButton className={styles.button} onClick={() => { setTags([]); setIsPersonalInterestModal(false);}}>Cancel</SecondaryButton>
                        <Stack direction={"row"} className={styles.submitButtonsStack}>
                            <PrimaryButton className={styles.button} onClick={addData}>Save & Close</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </CustomizedModal>            
            <Stack>
                <table className={styles.network_details_table}>
                    <thead>
                        <tr>
                        <td style={{ flex: 2, backgroundColor: "#F9F9FA", fontWeight: 400}}>Personal Interests</td>
                            <td style={{ flex: 1, backgroundColor: "#F9F9FA" }}>
                                <Stack style={{ flex: 1 }} direction="row-reverse" spacing={2}>
                                    {/* <IconButton><svg width="4" height="20" viewBox="0 0 4 20" fill="none"> <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)" fill="#737373" /> <circle cx="2" cy="10" r="2" transform="rotate(90 2 10)" fill="#737373" /> <circle cx="2" cy="18" r="2" transform="rotate(90 2 18)" fill="#737373" /> </svg></IconButton> */}
                                    {(!networkPermission || networkPermission === PermissionState.EDITOR) && <IconButton  className={styles.buttonHover} onClick={() => { setTags([]); setIsPersonalInterestModal(true);} }><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round" /> </svg></IconButton>}
                                </Stack>
                            </td>
                        </tr>
                    </thead>
                    { personalInterestList && personalInterestList.length > 0 &&  <tbody><tr><td colSpan={3} style={{ padding: `${pxToRem(12)} ${pxToRem(56)} ${pxToRem(12)} ${pxToRem(56)}`}}><PersonalInterestChips tPersonalInterestArr={personalInterestList}/></td></tr></tbody> }
                </table>
            </Stack>
            {
               personalInterestList && personalInterestList.length <= 0
                ?
                    (!networkPermission || networkPermission === PermissionState.EDITOR) ?
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">Add New</label>
                        <IconButton onClick={() => setIsPersonalInterestModal(true)}>
                            <svg width={pxToRem(20)} height={pxToRem(20)} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#1F1F1F" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </IconButton>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                    : 
                    <Stack className={styles.dataImportSection}>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                        <label className="regular-400-14">No Record</label>
                        <Line width={pxToRem(200)} height={2} lineColor="#F5F5F5"/>
                    </Stack>
                :
                null
            }
        </Stack>
    );
}

export default memo(PersonalInterestTab);