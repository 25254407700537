import React from 'react';
import styled from 'styled-components';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import constants from '../../../helpers/constants';
import pxToRem from '../../../helpers/pxToRem';
import globalStyles from '../../../styles/_stylesParams.module.scss';

// Styled TextField component
const InputTextFieldStyle = styled(TextField)({
  "& label.Mui-focused": {
    color: globalStyles.primary400,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: globalStyles.neutrals400,
    },
    "&:hover fieldset": {
      borderColor: globalStyles.primary400,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalStyles.primary400,
    },
  },
});

// InputTextField component props
interface IInputTextField extends StandardTextFieldProps {
  customHeight?: number,
  fontSize?: number,
  title?: string;
  titleColor?: string;
  label?: string;
  containerStyle?: React.CSSProperties;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  valueCheck?: any;
  shrinkLabelWidth?: number;
}

// InputTextField component
const PlannerInputTextField: React.FC<IInputTextField> = ({
  title, titleColor, label, startAdornment, endAdornment,
  shrinkLabelWidth, containerStyle, fontSize = 16, valueCheck, customHeight = 44, ...rest
}) => {
  const translateY = customHeight && fontSize ? ((customHeight ?? 0) / 2) - ((fontSize ?? 0) / 2) - constants.onboardingInputOffset : 1;

  const inputLabelProps = {
    sx: {
      "&:not(.Mui-focused)": { transform: valueCheck !== undefined && valueCheck.trim().length === 0 || valueCheck === undefined ? `translate(${pxToRem(14)}, ${pxToRem(translateY)})` : {} },
      "&.MuiFormLabel-root": { fontSize: pxToRem(fontSize) },
    }
  };

  const inputProps = {
    startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
    endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
    style: { height: pxToRem(customHeight ?? 0), fontSize: pxToRem(fontSize) }
  };

  return (
    <Stack style={containerStyle}>
      {title && <p className='smallMarginBottom' style={{ color: titleColor ? titleColor : undefined }}>{title}</p>}
      <InputTextFieldStyle
        autoComplete="off"
        sx={{ '& fieldset legend': { width: shrinkLabelWidth ? pxToRem(shrinkLabelWidth) : 'auto' } }}
        label={label}
        InputLabelProps={inputLabelProps}
        InputProps={inputProps}
        {...rest}
      />
    </Stack>
  );
};

export default PlannerInputTextField;
