import { DocumentData, FieldValue, Timestamp } from "firebase/firestore";
import { AccountType, Permission } from "./types-organisations";
import { TeamMember } from "../pages/TeamPage/helpers";
export const FAIL_VERIFY_PASSWORD = "fail to verify user password";
export type UserType = {
    firstName: string;
    lastName: string;
    email: string;
    contactNumber?: string;
    role?: string;
    profilePicture?: string;
    photoId?: string | null | undefined;
    onBoarding?: boolean;
    position?: string;
    state?: { value: "DELETED"; time: number; };
    beta?: boolean;
    organisations?: string[];
    betaPasswordUpdate?:boolean;
    termsconditions?: FieldValue;
    lastActivity?: number | FieldValue;
    created_at?: FieldValue;
    search_place_updated_at?: FieldValue | number;
    confirm_org_updated_at?: FieldValue | number;
    create_success_updated_at?: FieldValue | number;
    step?: string;
};
export type Organizations = { [organisationUid: string]: string; };

export type Users = { [userUid: string]: UserType; };

export type PermissionResult = { isAdmin: boolean; permissions: { [auth: string]: Permission; }; };

export type Invitation = {
    inviteId: string;
    orgId: string;
    orgName: string;
    host: string;
    inviteeEmail: string;
    accountType: AccountType;
    permissions: Permission;
    sentAt?: number;
}

export const getInvitation = (docId: string, data:DocumentData): Invitation => {
    return {
        inviteId: docId,
        orgId: data.orgId,
        orgName: data.orgName,
        host: data.host,
        inviteeEmail: data.inviteeEmail,
        accountType: data.accountType,
        permissions: data.permissions,
        // permissions: data.permissions.map((p: { feature: any; state: any; }): Permission => { return {feature: p.feature, state: p.state} }),
        sentAt: data.sentAt?(data.sentAt as Timestamp).toMillis():undefined
    }
}

export const getTeamMember = (userId: string, data: DocumentData): TeamMember => {
    return {
        userId: userId,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        email: data.email,
        photoUrl: data.photoUrl && data.photoUrl,
        lastActive: data.lastActivity && (data.lastActivity as Timestamp).toMillis(),

    }
}