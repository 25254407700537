import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { URL_PARAMS } from "../../../routers/shared";
import { FormsData } from "../helpers";
import styles from "../index.module.scss";
import FormMenu from "./tableParts/FormMenu";
import FormsTable from "./tableParts/FormsTable";
import IndeterminateCheckbox from "./tableParts/IndeterminateCheckbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { PermissionState } from "../../../firebase/types-organisations";


const ISACTIVE = false;

interface IClosedForms {
    handleDeleteForm: (id: string, name: string)=> void;
}

const ClosedForms = ({handleDeleteForm}: IClosedForms) => {
    const {orgId} = useParams();
    const navigate = useNavigate();
    const columnHelper = createColumnHelper<FormsData>();
    const formsPermission = useSelector((state: RootState) => state.globalState.permissions?.forms);

    const [totalForms, setTotalForms] = useState<number>();

    const rowClick = (id: string) => {
        if(id){ 
            navigate(
                `/${URL_PARAMS.DASHBOARD}/${URL_PARAMS.ORGANISATION}/${orgId}/${URL_PARAMS.SELF_REGISTRATION_FORMS}/${id}`,
                {
                    state: {
                        isActive: false
                    }
                }
            );
        }
    }


    const columns: ColumnDef<any, any>[]  = useMemo(()=> [ 
        ...formsPermission && formsPermission === PermissionState.EDITOR ? [
            columnHelper.display({
                id: 'select',
                cell: ({ row, cell }) => (
                    <div>
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: (e) => {
                                    e.stopPropagation();
                                    row.getIsSelected()?
                                        row.toggleSelected(false)
                                    :
                                        row.toggleSelected(true);
                                },
                                onClick: (e) => {
                                    e.stopPropagation();
                                },
                                labelClicked: (e: any) => {
                                    e.stopPropagation();
                                },
                                id: cell.id,
                                className: `${styles.round}`
                            }}
                        />
                    </div>
                ),
                header: ({table}) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                            id: 'closedHeader',
                            className: `${styles.round}`
                        }}
                />),
                size: 68,
            }),
        ]: [],
        columnHelper.accessor('name', { cell: info => <p style={{color: styles.shades100}}>{info.getValue()}</p>, header: "Form", size:400}), 
        columnHelper.accessor('createdAt', { cell: info => info.getValue(), header: "Created", size:99 }), 
        columnHelper.accessor('closedAt', { cell: info => info.getValue(), header: "Closed", size:99 }), 
        columnHelper.accessor('totalCount', { cell: info => info.getValue(), header: "Total Responses", size:100 }), 
        columnHelper.accessor('lastSubmission', { cell: info => info.getValue(), header: 'Last Submission', size:104 }), 
        ...formsPermission && formsPermission === PermissionState.EDITOR ? [
            columnHelper.display({
                id: 'options',
                cell: ({ row }) => (
                    <FormMenu
                        isActive={ISACTIVE}
                        handleDeleteForm={()=>{handleDeleteForm(row.original.id, row.original.name)}}
                    />
                ),
                size: 37,
            })
        ]: [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    return (
        <>
            <p className={styles.formTableTitle}>Archived QR Codes Total ({totalForms})</p>
            <FormsTable
                rowClick={rowClick}    
                handleDeleteForm={handleDeleteForm}
                isActive={ISACTIVE}
                columns={columns}
                countCallback={(count: number) => {setTotalForms(count)}}
            />
        </>
    );
}

export default ClosedForms;